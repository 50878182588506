import React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import {
  Circle,
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
//import * as turf from "@turf/turf";
import { polygon } from "@turf/turf";
import MarkerClusterGroup from "react-leaflet-markercluster";
import moment from "moment";
import { Badge, Button } from "reactstrap";
import { TrapsPageContext } from "../Contexts/trapsPageContext";
import { useContextSelector } from "use-context-selector";
import ReactTooltip from "react-tooltip";
import { Map } from "../../../../components/Maps/Map";
import Control from "react-leaflet-control";
import { MapContext } from "../Contexts/mapContext";
import useMapPoints from "../Hooks/useMapPoints";
import { getUserData } from "../../../../services/utils/auth";
import {
  GREEN_TRAP_STATUS,
  RED_TRAP_STATUS,
  trapBackgroundColorByStatusNumber,
  YELLOW_TRAP_STATUS,
} from "../../../../services/utils/globalFunctions";
import { toast } from "react-toastify";
import LoadingSpin from "react-loading-spin";
import { area } from "@turf/turf";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from "../../../../components/Accordion/Accordion";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import Draggable from "react-draggable";
import useMapOperatingRanges from "../Hooks/useMapOperatingRanges";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import useMap from "../Hooks/useMap";
import useLoading from "../Hooks/useLoading";
import MapLoadingSkeleton from "../../../../components/ui/Loading/MapLoadingSkeleton";
import useTrapsSituationsByColors from "../Hooks/useTrapsSituationsByColors";
import usePolygonsColorsPreferences from "../../../../hooks/usePolygonsColorsPreferences";
import { pointsWithinPolygon, point } from "@turf/turf";
import { GOOGLE_MAPS_API_KEY } from "../../../../constants/WebFormConstants";

const { BaseLayer, Overlay } = LayersControl;

const TrapsPageMapWrapperComponent = ({ groupPointsList }) => {
  const { territorializationsList } = useTerritorializationsList();
  const { trapTypesList } = useTrapTypesList();
  const { appliedFilters } = useAppliedFilters();
  const { filteredTrapPosition } = useMap();
  const { isTrapsListLoading } = useLoading();
  const {
    trapsSituationsByColors: { green, yellow, red, trapTypeName },
  } = useTrapsSituationsByColors();
  const [activeDrawedPolygonsData, setActiveDrawedPolygonsData] =
    React.useState([]);

  const handleActiveDrawedPolygonsChange = React.useCallback((polygonData) => {
    setActiveDrawedPolygonsData((previousValues) => {
      const newValues = [...previousValues];
      newValues.push(polygonData);

      return newValues;
    });
  }, []);

  const handleUpdateDrawedPolygonsChange = React.useCallback((polygonData, idPolygon) => {
    setActiveDrawedPolygonsData((previousValues) => {
      return previousValues.map((polygon) => {
        if(polygon.id === idPolygon) {
          const newPolygonData = { ...polygon, ...polygonData };

          console.log("newPolygonData", newPolygonData)

          return newPolygonData;
        }

        return polygon
      });
    });
  }, []);

  const handleDeleteDrawedPolygon = React.useCallback((idPolygon) => {
    setActiveDrawedPolygonsData((previousValues) => {
      const updatedPolygons = previousValues.filter((polygon) => polygon.id !== idPolygon);
  
      return updatedPolygons;
    });
  }, []);

  if (isTrapsListLoading)
    return (
      <div className="mb-4">
        <MapLoadingSkeleton heigth="47rem" />
      </div>
    );

  return (
    <section>
      <FloatingPolygonPopup
        appliedFilters={appliedFilters}
        groupPointsList={groupPointsList}
        trapTypesList={trapTypesList}
      />
      <Card className="mb-4">
        <CardHeader
          headerText={
            <span>
              Mapa de Armadilhas - {green + yellow + red} ativas (
              {ARMADILHA_INFO[trapTypeName]
                ? ARMADILHA_INFO[trapTypeName].apelido
                : ""}
              )
            </span>
          }
          showExpandButton={false}
          showDownloadDataButton={false}
          showDownloadImageButton={false}
        />
        <CardContent>
          <Map
            center={filteredTrapPosition}
            style={{ height: "55rem" }}
            handleActiveDrawedPolygonsChange={handleActiveDrawedPolygonsChange}
            handleUpdateDrawedPolygonsChange={handleUpdateDrawedPolygonsChange}
            handleDeleteDrawedPolygon={
              handleDeleteDrawedPolygon
            }
            setActiveDrawedPolygonsData={setActiveDrawedPolygonsData}
          >
            <MapPointsLayersControl
              appliedFilters={appliedFilters}
              groupPointsList={groupPointsList}
              layerTitle="Tipo de Armadilha"
              defaultSelectedPointsGroupName={appliedFilters.trapType.label}
            />
            <MapPolygonsLayersControl
              data={territorializationsList}
              defaultSelectedTypeLayerName="UVIS"
              layerTitle="Delimitador de Área"
            />
            <RangeMapTrapTools
              appliedFilters={appliedFilters}
              groupPointsList={groupPointsList}
            />
            <MapLegend />
            <FilterMapTrapTools
              trapTypesList={trapTypesList}
              activeDrawedPolygonsData={activeDrawedPolygonsData}
            />
            <TrapsOperatingRangeWrapper />
          </Map>
        </CardContent>
        <CardFooter>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </section>
  );
};

const MapLegend = () => {
  const {
    trapsSituationsByColors: { green, yellow, red, range, trapTypeName },
  } = useTrapsSituationsByColors();

  const styles = {
    badge: {
      height: "10px",
      width: "20px",
      marginLeft: "0px",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: ".1rem",
    },
  };

  if (!range) return null;

  return (
    <Control position="bottomright">
      <section
        style={{
          maxWidth: 250,
          maxHeight: 300,
          backgroundColor: "#fff",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          <span>
            Armadilhas ({ARMADILHA_INFO[trapTypeName].apelido}) ativas:{" "}
            {green + yellow + red}
          </span>
          {range.quantityRange === 2 ? (
            <>
              <div style={styles.container}>
                <span style={{ display: "block" }}>Verdes</span>
                <Badge
                  style={{
                    ...styles.badge,
                    backgroundColor: "#76ea79",
                  }}
                >
                  &nbsp;
                </Badge>
                :<span>{green}</span>
              </div>
              <div style={styles.container}>
                <span style={{ display: "block" }}>Vermelhas</span>
                <Badge
                  style={{
                    ...styles.badge,
                    backgroundColor: "#ff0000",
                  }}
                >
                  &nbsp;
                </Badge>
                :<span>{red}</span>
              </div>
            </>
          ) : range.quantityRange === 3 ? (
            <>
              <div style={styles.container}>
                <span style={{ display: "block" }}>Verdes</span>
                <Badge
                  style={{
                    ...styles.badge,
                    backgroundColor: "#76ea79",
                  }}
                >
                  &nbsp;
                </Badge>
                :<span>{green}</span>
              </div>
              <div style={styles.container}>
                <span style={{ display: "block" }}>Amarelas</span>
                <Badge
                  style={{
                    ...styles.badge,
                    backgroundColor: "#fff200",
                  }}
                >
                  &nbsp;
                </Badge>
                :<span>{yellow}</span>
              </div>
              <div style={styles.container}>
                <span style={{ display: "block" }}>Vermelhas</span>
                <Badge
                  style={{
                    ...styles.badge,
                    backgroundColor: "#ff0000",
                  }}
                >
                  &nbsp;
                </Badge>
                :<span>{red}</span>
              </div>
            </>
          ) : null}
        </div>
      </section>
    </Control>
  );
};

const MapPolygonsLayersControl = React.memo(
  ({ data, layerTitle, defaultSelectedTypeLayerName = "" }) => {
    const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);
    const { polygonsColorsPreferences } = usePolygonsColorsPreferences();

    const togglePolygonPopup = useContextSelector(
      MapContext,
      (context) => context.togglePolygonPopup
    );

    React.useEffect(() => {
      if (!data || data.length === 0) return;

      const typeLayersPolygonsGroup = [];

      data.forEach(({ name, territorializations, id }) => {
        const typeLayerData = {
          id,
          name,
          polygons: [],
        };

        territorializations.forEach((territorialization) => {
          const turfPolygon = polygon([
            territorialization.coordinates.map(({ x, y }) => [x, y]),
          ]);

          turfPolygon.name = territorialization.name;
          turfPolygon.territorialization = territorialization;
          turfPolygon.territorializationId = territorialization.id;
          turfPolygon.typeLayerId = id;

          typeLayerData.polygons.push(turfPolygon);
        });

        typeLayersPolygonsGroup.push(typeLayerData);
      });

      setTypeLayersPolygons(typeLayersPolygonsGroup);
    }, [data]);

    return (
      <LayersControl position="topright">
        {layerTitle !== "" && (
          <BaseLayer checked name={layerTitle}>
            <TileLayer attribution="" url="" />
          </BaseLayer>
        )}
        {typeLayersPolygons &&
          typeLayersPolygons.length > 0 &&
          typeLayersPolygons.map(({ name, polygons, id }, index) => {
            const typeLayerColorPreference = polygonsColorsPreferences.find(
              ({ typeLayerId }) => typeLayerId === id
            );

            const typeLayerColor = !typeLayerColorPreference
              ? {
                  border: "#000000",
                  interior: "transparent",
                }
              : {
                  border: typeLayerColorPreference.borderColor,
                  interior: typeLayerColorPreference.interiorColor,
                };

            return (
              <Overlay
                checked={defaultSelectedTypeLayerName === name}
                name={name}
                key={`${name}-overlay-${index}`}
              >
                <FeatureGroup
                  fillOpacity={0.3}
                  name={name}
                  key={`${name}-feature-${index}`}
                >
                  {polygons &&
                    polygons.length > 0 &&
                    polygons.map((polygon, index) => {
                      const { geometry, name, territorializationId } = polygon;

                      const coordinates = geometry.coordinates[0].map(
                        ([latitude, longitude]) => [longitude, latitude]
                      );

                      const polygonColorPreference =
                        polygonsColorsPreferences.find(
                          ({ polygonId }) => polygonId === territorializationId
                        );

                      const polygonColor = !polygonColorPreference
                        ? {
                            border: "#000000",
                            interior: "transparent",
                          }
                        : {
                            border: polygonColorPreference.borderColor,
                            interior: polygonColorPreference.interiorColor,
                          };

                      if(polygonColor.border !== "#000000") {
                        console.log("polygonColor.border", polygonColor.border)
                        console.log("polygonColor.interior", )
                      }
                      

                      return (
                        <Polygon
                          key={`${name}-polygon-${index}`}
                          color={
                            polygonColor.border !== "#000000" &&
                            polygonColor.border !== "transparent"
                              ? polygonColor.border
                              : typeLayerColor.border !== "#000000" &&
                                typeLayerColor.border !== "transparent"
                              ? typeLayerColor.border
                              : "#000000"
                          }
                          fillColor={
                            polygonColor.interior &&
                            polygonColor.interior !== "#000000" &&
                            polygonColor.interior !== "transparent" 
                              ? polygonColor.interior
                              : typeLayerColor.interior &&
                                typeLayerColor.interior !== "#000000" &&
                                typeLayerColor.interior !== "transparent"
                              ? typeLayerColor.interior
                              : "transparent"
                          }
                          opacity={1}
                          positions={coordinates}
                          onclick={() => togglePolygonPopup(polygon)}
                        >
                          {name ? <Tooltip sticky>{name}</Tooltip> : null}
                        </Polygon>
                      );
                    })}
                </FeatureGroup>
              </Overlay>
            );
          })}
      </LayersControl>
    );
  }
);

const MapPointsLayersControl = React.memo(
  ({ groupPointsList, layerTitle, defaultSelectedPointsGroupName = "" }) => {
    const setSelectedTrapNumber = useContextSelector(
      TrapsPageContext,
      (context) => context.setSelectedTrapNumber
    );
    const toggleEditTrapModal = useContextSelector(
      TrapsPageContext,
      (context) => context.toggleEditTrapModal
    );
    const {
      filteredGroupsPointsList: groupsPointsFromContext,
      removePointsCluster,
    } = useMapPoints();

    return (
      <LayersControl position="topright">
        <BaseLayer checked name={layerTitle}>
          <TileLayer attribution="" url="" />
        </BaseLayer>
        {groupsPointsFromContext && groupsPointsFromContext.length > 0
          ? groupsPointsFromContext.map(({ name, points }, index) => {
              return (
                <Overlay
                  key={index}
                  name={name}
                  checked={name === defaultSelectedPointsGroupName}
                >
                  <MarkerClusterGroup
                    removeOutsideVisibleBounds={true}
                    disableClusteringAtZoom={
                      removePointsCluster ? 1 : undefined
                    }
                  >
                    {points &&
                      points.length > 0 &&
                      points.map(
                        ({
                          id,
                          address,
                          lastInstallDate,
                          lastInsallUsername,
                          lastReadUsername,
                          lastReadDate,
                          latitude,
                          longitude,
                          number,
                          icon,
                        }) => {
                          return (
                            <Marker
                              key={id}
                              position={[latitude, longitude]}
                              icon={icon}
                            >
                              <TrapPopup
                                address={address}
                                lastInstallDate={lastInstallDate}
                                id={id}
                                lastInsallUsername={lastInsallUsername}
                                lastReadUsername={lastReadUsername}
                                lastReadDate={lastReadDate}
                                number={number}
                                setSelectedTrapNumber={setSelectedTrapNumber}
                                toggleEditTrapModal={toggleEditTrapModal}
                                latitude={latitude}
                                longitude={longitude}
                              />
                            </Marker>
                          );
                        }
                      )}
                  </MarkerClusterGroup>
                </Overlay>
              );
            })
          : groupPointsList && groupPointsList.length > 0
          ? groupPointsList.map(({ name, points }) => {
              return (
                <Overlay
                  key={`${name}-overlay-removePointsCluster-${removePointsCluster}`}
                  name={name}
                  checked={name === defaultSelectedPointsGroupName}
                >
                  {removePointsCluster ? (
                    <MarkerClusterGroup
                      key={`${name}-removePointsCluster-${removePointsCluster}`}
                      removeOutsideVisibleBounds={true}
                      disableClusteringAtZoom={
                        removePointsCluster ? 1 : undefined
                      }
                    >
                      {points &&
                        points.length > 0 &&
                        points.map(
                          ({
                            id,
                            address,
                            lastInstallDate,
                            lastReadDate,
                            lastInsallUsername,
                            lastReadUsername,
                            latitude,
                            longitude,
                            number,
                            icon,
                          }) => {
                            return (
                              <Marker
                                key={id}
                                position={[latitude, longitude]}
                                icon={icon}
                              >
                                <TrapPopup
                                  address={address}
                                  lastInstallDate={lastInstallDate}
                                  id={id}
                                  lastReadDate={lastReadDate}
                                  lastInsallUsername={lastInsallUsername}
                                  lastReadUsername={lastReadUsername}
                                  number={number}
                                  setSelectedTrapNumber={setSelectedTrapNumber}
                                  toggleEditTrapModal={toggleEditTrapModal}
                                  latitude={latitude}
                                  longitude={longitude}
                                />
                              </Marker>
                            );
                          }
                        )}
                    </MarkerClusterGroup>
                  ) : (
                    <MarkerClusterGroup
                      key={`${name}-${removePointsCluster}`}
                      removeOutsideVisibleBounds={true}
                    >
                      {points &&
                        points.length > 0 &&
                        points.map(
                          ({
                            id,
                            address,
                            lastInstallDate,
                            lastInsallUsername,
                            lastReadUsername,
                            lastReadDate,
                            latitude,
                            longitude,
                            number,
                            icon,
                          }) => {
                            return (
                              <Marker
                                key={id}
                                position={[latitude, longitude]}
                                icon={icon}
                              >
                                <TrapPopup
                                  address={address}
                                  lastInstallDate={lastInstallDate}
                                  id={id}
                                  lastReadDate={lastReadDate}
                                  lastInsallUsername={lastInsallUsername}
                                  lastReadUsername={lastReadUsername}
                                  number={number}
                                  setSelectedTrapNumber={setSelectedTrapNumber}
                                  toggleEditTrapModal={toggleEditTrapModal}
                                  latitude={latitude}
                                  longitude={longitude}
                                />
                              </Marker>
                            );
                          }
                        )}
                    </MarkerClusterGroup>
                  )}
                </Overlay>
              );
            })
          : null}
      </LayersControl>
    );
  }
);

const FilterMapTrapTools = ({ trapTypesList, activeDrawedPolygonsData }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = React.useState(false);
  const [filters, setFilters] = React.useState({
    trapNumber: "",
    address: "",
  });
  const { setFilteredTrapPosition } = useMap();
  const { fetchTrapsListData } = useMapPoints();

  const handleFiltersChange = (filterName, newValue) => {
    setFilters((previousValue) => {
      return {
        ...previousValue,
        [filterName]: newValue,
      };
    });
  };

  const handleAddressButtonClick = async () => {
    if (filters.address === "") return;

    setIsLoadingAddress(true);

    try {
      const adressRequest = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_API_KEY}&address=${filters.address}`
      );

      if (!adressRequest.ok) {
        toast.error(
          "Ocorreu um erro ao buscar pelo endereço. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe."
        );

        return;
      }

      const addressRequestResult = await adressRequest.json();

      if (addressRequestResult.results.length === 0) {
        toast.error(
          "Não foi possível encontrar o endereço informado. Verifique se o endereço está correto e tente novamente."
        );

        return;
      }

      const { lat, lng } = addressRequestResult.results[0].geometry.location;

      setFilteredTrapPosition([lat, lng]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  return (
    <Control
      position="topright"
      className="leaflet-control-layers leaflet-control"
    >
      <Button onClick={() => setIsOpen((previousValue) => !previousValue)}>
        <i className="fa fa-search fa-2x"></i>
      </Button>

      {isOpen && (
        <section style={{ padding: "10px" }}>
          <div className="mb-3">
            <MapDrawedPolygonsLegend
              activeDrawedPolygonsData={activeDrawedPolygonsData}
            />
            <div
              style={{
                display: "flex",
                alignItems: "end",
                gap: ".3rem",
                marginBottom: "1rem",
              }}
            >
              <div>
                <label htmlFor="trapNumber" className="h4">
                  Número da armadilha
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="trapNumber"
                  style={{ height: 0, padding: "0.8rem 0.3rem" }}
                  value={filters.trapNumber}
                  onChange={({ target: { value } }) =>
                    handleFiltersChange("trapNumber", value)
                  }
                />
              </div>
              <Button
                color="primary"
                style={{ width: "35px", padding: "2px" }}
                onClick={() => fetchTrapsListData(filters, trapTypesList)}
              >
                <i className="fa fa-filter"></i>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                gap: ".3rem",
                marginBottom: "1rem",
              }}
            >
              <div>
                <label htmlFor="address" className="h4">
                  Endereço
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="address"
                  style={{ height: 0, padding: "0.8rem 0.3rem" }}
                  value={filters.address}
                  onChange={({ target: { value } }) =>
                    handleFiltersChange("address", value)
                  }
                />
              </div>
              {isLoadingAddress ? (
                <Button
                  color="primary"
                  style={{ width: "35px", padding: "2px" }}
                  disabled={true}
                >
                  <LoadingSpin size={15} primaryColor={"#fff"} />
                </Button>
              ) : (
                <Button
                  color="primary"
                  style={{ width: "35px", padding: "2px" }}
                  onClick={() => handleAddressButtonClick()}
                >
                  <i className="fa fa-filter"></i>
                </Button>
              )}
            </div>
            <div>
              <Button
                style={{
                  padding: ".5rem",
                  backgroundColor: "#3b6bca",
                  color: "white",
                  border: "0px",
                }}
                onClick={() =>
                  fetchTrapsListData(
                    {
                      trapNumber: "",
                    },
                    trapTypesList
                  )
                }
              >
                Remover filtros
              </Button>
            </div>
          </div>
        </section>
      )}
    </Control>
  );
};

const MapDrawedPolygonsLegend = ({ activeDrawedPolygonsData }) => {
  if (!activeDrawedPolygonsData || activeDrawedPolygonsData.length === 0)
    return null;

  let totalAreaInSquareKilometers = 0;
  let totalAreaInSquareMeters = 0;
  let totalAreaInEquit = 0;

  activeDrawedPolygonsData.forEach(
    ({ areaInSquareKilometers, areaInSquareMeters, areaInEquit }) => {
      totalAreaInSquareKilometers += areaInSquareKilometers;
      totalAreaInSquareMeters += areaInSquareMeters;
      totalAreaInEquit += areaInEquit;
    }
  );

  return (
    <section className="mb-2">
      <label htmlFor="" className="h4">
        Polígonos desenhados
      </label>
      <table style={{ fontSize: ".75rem", width: "100%" }}>
        <thead>
          <tr style={{ border: "1px solid black", textAlign: "center" }}>
            <td style={{ border: "1px solid black" }}>Polígono</td>
            <td style={{ border: "1px solid black" }}>km²</td>
            <td style={{ border: "1px solid black" }}>m²</td>
            <td style={{ border: "1px solid black" }}>ha</td>
          </tr>
        </thead>
        <tbody>
          {activeDrawedPolygonsData.map(
            (
              {
                areaInSquareKilometers,
                areaInSquareMeters,
                areaInEquit,
                color,
                bgColor
              },
              index
            ) => (
              <tr
                style={{ border: "1px solid black", textAlign: "center" }}
                key={index}
              >
                
                <td
                  style={{
                    border: "1px solid black",
                    position: "relative",
                    color: "white",
                    overflow: "hidden",
                    // backgroundColor: color,
                  }}
                >
                  {
                    bgColor && bgColor !== "transparent"
                    ?
                    <>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "50%",
                          height: "100%",
                          backgroundColor: color,
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "50%",
                          height: "100%",
                          backgroundColor: bgColor,
                        }}
                      />
                    </>
                    :
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: color,
                      }}
                    />
                  }
                  </td>
                <td style={{ border: "1px solid black" }}>
                  {areaInSquareKilometers.toFixed(2)}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {areaInSquareMeters.toFixed(2)}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {areaInEquit.toFixed(2)}
                </td>
              </tr>
            )
          )}
          <tr style={{ border: "1px solid black", textAlign: "center" }}>
            <td style={{ border: "1px solid black" }}>TOTAL:</td>
            <td style={{ border: "1px solid black" }}>
              {totalAreaInSquareKilometers.toFixed(2)}
            </td>
            <td style={{ border: "1px solid black" }}>
              {totalAreaInSquareMeters.toFixed(2)}
            </td>
            <td style={{ border: "1px solid black" }}>
              {totalAreaInEquit.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

const RangeMapTrapTools = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [organizationId] = React.useState(getUserData("organizationId"));
  const { appliedFilters } = useAppliedFilters();
  const { removePointsCluster, setRemovePointsCluster } = useMapPoints();
  const {
    hadleTrapTypesRangesRadiusChange,
    trapTypesRangesRadius,
    setTrapsListToShowRange,
  } = useMapOperatingRanges();

  React.useEffect(() => {
    if (appliedFilters.territorializations.length !== 1) {
      setRemovePointsCluster(false);
    }
  }, [appliedFilters]);

  const DISABLED_ORGANIZATIONS_FROM_REMOVE_CLUSTERS = [
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8",
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30",
    "7479f4e9-1706-41d4-8402-0bae72ef71ec",
  ];

  const isPointsClusterCheckboxDisabled =
    DISABLED_ORGANIZATIONS_FROM_REMOVE_CLUSTERS.includes(organizationId) &&
    appliedFilters.territorializations.length === 0
      ? true
      : false;

  return (
    <Control
      position="topright"
      className="leaflet-control-layers leaflet-control"
    >
      <Button onClick={() => setIsOpen((previousValue) => !previousValue)}>
        <i className="fa fa-cog fa-2x"></i>
      </Button>

      {isOpen && (
        <section style={{ padding: "10px" }}>
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
          >
            <input
              type="checkbox"
              id="trapClusters"
              checked={removePointsCluster}
              onChange={() =>
                setRemovePointsCluster((previousValue) => !previousValue)
              }
              disabled={isPointsClusterCheckboxDisabled}
            />
            <label
              htmlFor="trapClusters"
              className="h4 mb-0"
              style={{ opacity: isPointsClusterCheckboxDisabled ? 0.5 : 1 }}
            >
              Desagrupar armadilhas
            </label>
          </div>
          <div>
            <label className="h4 mb-0">Raios de atuação</label>
            <ul style={{ listStyle: "none", padding: "0" }}>
              {Object.keys(trapTypesRangesRadius).map((trapTypeName) => {
                return (
                  <li style={{ padding: "0 1rem" }}>
                    <div>
                      <label className="h5" htmlFor={`${trapTypeName}Range`}>
                        {ARMADILHA_INFO[trapTypeName].apelido}:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {trapTypesRangesRadius[trapTypeName]} metros
                        </span>
                      </label>
                    </div>
                    <input
                      type="range"
                      name={`${trapTypeName}Range`}
                      id={`${trapTypeName}Range`}
                      style={{ width: "100%" }}
                      min={1}
                      max={1000}
                      value={trapTypesRangesRadius[trapTypeName]}
                      onChange={({ target: { value } }) =>
                        hadleTrapTypesRangesRadiusChange(trapTypeName, value)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <Button color="primary" onClick={() => setTrapsListToShowRange([])}>
              Desabilitar raios
            </Button>
          </div>
        </section>
      )}
    </Control>
  );
};

const TrapPopup = ({
  id,
  number,
  address,
  lastInsallUsername,
  lastReadUsername,
  lastInstallDate,
  lastReadDate,
  setSelectedTrapNumber,
  toggleEditTrapModal,
}) => {
  const activitiesInformations = {
    lastInstall: {
      date: moment(lastInstallDate).format("DD/MM/YYYY"),
      time: moment(lastInstallDate).format("HH:mm"),
      responsableUserName: lastInsallUsername,
    },
    lastRead: {
      date: lastReadDate ? moment(lastReadDate).format("DD/MM/YYYY") : "-",
      time: lastReadDate ? moment(lastReadDate).format("HH:mm") : "-",
      responsableUserName: lastReadUsername,
    },
  };

  return (
    <Popup key={`popup-${id}`}>
      <span>
        <span style={{ fontWeight: "bold" }}>Armadilha Nº: </span>
        {number}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Endereço: </span>
        {address}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última instalação: </span>
        {`${activitiesInformations.lastInstall.date} às ${activitiesInformations.lastInstall.time} por ${activitiesInformations.lastInstall.responsableUserName}`}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última manutenção: </span>
        {activitiesInformations.lastRead.date === "-"
          ? "-"
          : `${activitiesInformations.lastRead.date} às ${activitiesInformations.lastRead.time} por ${activitiesInformations.lastRead.responsableUserName}`}
      </span>
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <Button
          data-tip
          data-for="editTrapModal"
          color="primary"
          onClick={() => {
            setSelectedTrapNumber(number);
            toggleEditTrapModal();
          }}
          style={{ margin: "0px" }}
        >
          <i className="fa fa-pen"></i>
        </Button>
        <ReactTooltip effect="solid" type="info" id="editTrapModal">
          Editar
        </ReactTooltip>
      </div>
    </Popup>
  );
};

const TrapsOperatingRangeWrapper = () => {
  const { trapsListToShowRange, trapTypesRangesRadius } =
    useMapOperatingRanges();

  return (
    <>
      {trapsListToShowRange.map(
        ({
          id,
          trapTypeName,
          fillColor,
          borderColor,
          latitude,
          longitude,
          number,
        }) => {
          return (
            <Circle
              key={id}
              center={[latitude, longitude]}
              color={borderColor}
              fillColor={fillColor}
              radius={
                trapTypesRangesRadius[trapTypeName]
                  ? trapTypesRangesRadius[trapTypeName]
                  : 12
              }
            >
              <Popup>
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Número: </span>
                  {number}
                </span>
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Tipo: </span>
                  {ARMADILHA_INFO[trapTypeName].apelido}
                </span>
              </Popup>
            </Circle>
          );
        }
      )}
    </>
  );
};

const FloatingPolygonPopup = ({ appliedFilters, groupPointsList }) => {
  const [accordionsData, setAccordionsData] = React.useState([]);
  const [formatedPolygonData, setFormatedPolygonData] = React.useState(null);
  const isPolygonPopupOpen = useContextSelector(
    MapContext,
    (context) => context.isPolygonPopupOpen
  );
  const togglePolygonPopup = useContextSelector(
    MapContext,
    (context) => context.togglePolygonPopup
  );
  const polygonPopupData = useContextSelector(
    MapContext,
    (context) => context.polygonPopupData
  );
  const setPolygonPopupData = useContextSelector(
    MapContext,
    (context) => context.setPolygonPopupData
  );

  React.useEffect(() => {
    if (!isPolygonPopupOpen) {
      setFormatedPolygonData(null);
      setPolygonPopupData(null);
    }
  }, [isPolygonPopupOpen]);

  React.useEffect(() => {
    if (!polygonPopupData) return;

    const trapTypeGroupPoints = groupPointsList.find(
      ({ trapTypeName }) =>
        appliedFilters.trapType.trapTypeName === trapTypeName
    );

    const featureCollectionPoint = {
      type: "FeatureCollection",
      features: trapTypeGroupPoints.points.map(
        ({ latitude, longitude, ...rest }) =>
          point([longitude, latitude], {
            latitude,
            longitude,
            trapType: { name: appliedFilters.trapType.trapTypeName },
            ...rest,
          })
      ),
    };

    const trapPointsInsidePolygon = pointsWithinPolygon(
      featureCollectionPoint,
      polygonPopupData
    );

    calculatePolygonInformations(
      polygonPopupData,
      trapPointsInsidePolygon.features
    );
  }, [polygonPopupData]);

  const calculatePolygonInformations = async (polygon, trapsInsidePolygon) => {
    if (!polygon) return;

    const {
      territorialization: { id },
    } = polygon;

    console.log(polygon);

    const { name } = polygon;
    const polygonAreaInSquareMeters = area(polygon);
    const polygonAreaInSquareKilometers = polygonAreaInSquareMeters / 1000000;
    const polygonAreaInHectare = polygonAreaInSquareMeters / 10000;

    console.log("polygonAreaInSquareMeters", polygonAreaInSquareMeters);
    console.log("polygonAreaInSquareKilometers", polygonAreaInSquareKilometers);
    console.log("polygonAreaInHectare", polygonAreaInHectare);

    const trapsListByTrapTypeMap = {};

    for (let i = 0; i < trapsInsidePolygon.length; i++) {
      const { id, number, trapType, colorStatusCode, latitude, longitude } =
        trapsInsidePolygon[i].properties;

      if (!trapsListByTrapTypeMap[trapType.name]) {
        trapsListByTrapTypeMap[trapType.name] = {
          activeTrapsList: [],
          greenTrapsList: [],
          yellowTrapsList: [],
          redTrapsList: [],
        };
      }

      const reducedTrapData = {
        id,
        colorStatusCode,
        fillColor: trapBackgroundColorByStatusNumber[colorStatusCode],
        borderColor: trapBackgroundColorByStatusNumber[colorStatusCode],
        number,
        trapTypeName: trapType.name,
        latitude: latitude,
        longitude: longitude,
      };

      trapsListByTrapTypeMap[trapType.name].activeTrapsList.push(
        reducedTrapData
      );

      if (colorStatusCode === GREEN_TRAP_STATUS) {
        trapsListByTrapTypeMap[trapType.name].greenTrapsList.push(
          reducedTrapData
        );

        continue;
      }

      if (colorStatusCode === YELLOW_TRAP_STATUS) {
        trapsListByTrapTypeMap[trapType.name].yellowTrapsList.push(
          reducedTrapData
        );
        continue;
      }

      if (colorStatusCode === RED_TRAP_STATUS) {
        trapsListByTrapTypeMap[trapType.name].redTrapsList.push(
          reducedTrapData
        );
        continue;
      }
    }

    const trapsListByTrapTypeResult = Object.entries(
      trapsListByTrapTypeMap
    ).map(([trapTypeName, traps]) => {
      const { activeTrapsList, greenTrapsList, yellowTrapsList, redTrapsList } =
        traps;

      const activeTrapsNumber =
        greenTrapsList.length + yellowTrapsList.length + redTrapsList.length;

      const trapsByAreaDensityInSquareKilometers = (
        activeTrapsNumber / polygonAreaInSquareKilometers
      ).toFixed(2);

      const trapsByAreaDensityInHectare = (
        activeTrapsNumber / polygonAreaInHectare
      ).toFixed(2);

      const areaByTrapsDensityInSquareKilometers = (
        polygonAreaInSquareKilometers / activeTrapsNumber
      ).toFixed(2);

      const areaByTrapsDensityInHectare = (
        polygonAreaInHectare / activeTrapsNumber
      ).toFixed(2);

      const greenTrapsPercentage = (
        (greenTrapsList.length * 100) /
        activeTrapsNumber
      ).toFixed(2);

      const yellowTrapsPercentage = (
        (yellowTrapsList.length * 100) /
        activeTrapsNumber
      ).toFixed(2);

      const redTrapsPercentage = (
        (redTrapsList.length * 100) /
        activeTrapsNumber
      ).toFixed(2);

      return {
        activeTrapsList,
        activeTrapsNumber,
        greenTrapsList,
        greenTrapsPercentage,
        yellowTrapsList,
        yellowTrapsPercentage,
        redTrapsList,
        redTrapsPercentage,
        trapTypeName,
        trapsByAreaDensityInSquareKilometers,
        trapsByAreaDensityInHectare,
        areaByTrapsDensityInSquareKilometers,
        areaByTrapsDensityInHectare,
        isOpen: false,
        ...traps,
      };
    });

    const formatedPolygon = {
      id,
      typeLayerId: polygon.typeLayerId,
      name,
      areaInSquareKilometers: polygonAreaInSquareKilometers.toFixed(2),
      areaInHectare: (polygonAreaInSquareMeters / 10000).toFixed(2),
      trapsListByTrapType: trapsListByTrapTypeResult,
    };

    setAccordionsData(trapsListByTrapTypeResult);
    setFormatedPolygonData(formatedPolygon);
  };

  const handleAccordionChange = (accordionIndex, propertyName, newValue) => {
    setAccordionsData((previousValues) => {
      const newValues = [...previousValues];

      newValues[accordionIndex] = {
        ...newValues[accordionIndex],
        [propertyName]: newValue,
      };

      return newValues;
    });
  };

  const styles = {
    draggableContainer: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "300px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      zIndex: 999999,
    },
    polygonContentContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      gap: ".5rem",
    },
  };

  if (!isPolygonPopupOpen) return <></>;

  return (
    <Draggable>
      <div style={styles.draggableContainer}>
        {isPolygonPopupOpen && !formatedPolygonData ? (
          <div style={styles.polygonContentContainer}>
            <span>Carregando dados...</span>
            <LoadingSpin
              primaryColor="#3B6BCA"
              secondaryColor="#fff"
              width={5}
              size={15}
            />
          </div>
        ) : (
          <div>
            <PolygonStats
              togglePolygonPopup={togglePolygonPopup}
              data={formatedPolygonData}
            />
            <hr style={{ margin: 0 }} />
            <TrapTypesAccordionStats
              data={accordionsData}
              handleAccordionChange={handleAccordionChange}
            />
            <hr style={{ margin: 0 }} />
            <ColorsPreferenceSection data={formatedPolygonData} />
          </div>
        )}
      </div>
    </Draggable>
  );
};

const PolygonStats = ({ data, togglePolygonPopup }) => {
  const styles = {
    padding: "0 1.3rem",
  };

  return (
    <>
      <section
        className="mt-3"
        style={{
          ...styles,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <i
          className="fa fa-times"
          onClick={() => togglePolygonPopup(null)}
          style={{ cursor: "pointer" }}
        ></i>
      </section>
      <section className="mt-2 mb-2" style={styles}>
        <span style={{ display: "block" }}>
          <span className="h4">Nome:</span> {data.name}
        </span>
        <span style={{ display: "block" }}>
          <span className="h4">Área:</span> {data.areaInSquareKilometers} km² /{" "}
          {data.areaInHectare} ha
        </span>
      </section>
    </>
  );
};

const TrapTypesAccordionStats = ({ data, handleAccordionChange }) => {
  const { setTrapsListToShowRange } = useMapOperatingRanges();

  const styles = {
    colorPickerButton: {
      borderRadius: "100%",
      width: "0px",
      padding: "11px",
      boxShadow: "none",
      borderColor: "#646464",
    },

    trapsRangesButtons: {
      margin: 0,
      padding: "10px",
      border: 0,
    },
    badge: {
      width: "20px",
      marginLeft: "5px",
    },
    accordionContent: {
      padding: ".5rem 1.5rem",
      borderBottom: "1px solid #f0f1f3",
    },
    sectionContainer: {
      padding: "0 1.3rem",
    },
  };

  return (
    <section className="mt-2 mb-4" style={styles.sectionContainer}>
      <span className="h4" style={{ display: "block" }}>
        Armadilhas:
      </span>
      <Accordion>
        {data &&
          data.length > 0 &&
          data.map(
            (
              {
                activeTrapsList,
                activeTrapsNumber,
                greenTrapsPercentage,
                yellowTrapsPercentage,
                redTrapsPercentage,
                greenTrapsList,
                yellowTrapsList,
                redTrapsList,
                trapTypeName,
                trapsByAreaDensityInSquareKilometers,
                trapsByAreaDensityInHectare,
                isOpen,
              },
              index
            ) => {
              const trapRange = getTrapRange(trapTypeName);
              const trapTypeNickname = ARMADILHA_INFO[trapTypeName].apelido;

              const trapIcon = ARMADILHA_INFO[trapTypeName].principal_icon;

              return (
                <AccordionItem style={{ padding: "0px" }} key={index}>
                  <AccordionHeader
                    isOpen={isOpen}
                    onClick={() =>
                      handleAccordionChange(index, "isOpen", !isOpen)
                    }
                    style={{ padding: ".8rem" }}
                  >
                    <div>
                      <img
                        src={trapIcon}
                        alt="trapIcon"
                        style={{ width: "25px" }}
                      />
                      <span className="h4" style={{ marginBottom: 0 }}>
                        {trapTypeNickname}
                      </span>
                    </div>
                  </AccordionHeader>
                  <AccordionContent
                    isOpen={isOpen}
                    style={styles.accordionContent}
                  >
                    <section style={{ fontSize: ".8rem" }}>
                      <div>
                        <span style={{ display: "block" }}>
                          <span className="h4" style={{ fontSize: ".8rem" }}>
                            Armadilhas:
                          </span>
                        </span>
                        <ul style={{ paddingLeft: "1.5rem" }}>
                          <li>
                            <span>
                              Total instaladas
                              <Badge
                                style={{
                                  ...styles.badge,
                                  backgroundColor: "#3b6bca",
                                }}
                              >
                                &nbsp;
                              </Badge>{" "}
                              : {activeTrapsNumber}
                            </span>
                          </li>
                          <li>
                            <span>
                              Verdes
                              <Badge
                                style={{
                                  ...styles.badge,
                                  backgroundColor: "#76ea79",
                                }}
                              >
                                &nbsp;
                              </Badge>{" "}
                              : {greenTrapsList.length} / {greenTrapsPercentage}
                              %
                            </span>
                          </li>
                          {trapRange.quantityRange === 3 && (
                            <li>
                              <span>
                                Amarelas
                                <Badge
                                  style={{
                                    ...styles.badge,
                                    backgroundColor: "#fff200",
                                  }}
                                >
                                  &nbsp;
                                </Badge>{" "}
                                : {yellowTrapsList.length} /{" "}
                                {yellowTrapsPercentage}%
                              </span>
                            </li>
                          )}
                          <li>
                            <span>
                              Vermelhas
                              <Badge
                                style={{
                                  ...styles.badge,
                                  backgroundColor: "#ff0000",
                                }}
                              >
                                &nbsp;
                              </Badge>{" "}
                              : {redTrapsList.length} / {redTrapsPercentage}%
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <span style={{ display: "block" }}>
                          <span className="h4" style={{ fontSize: ".8rem" }}>
                            Densidade{" "}
                          </span>
                          (armadilhas / área):{" "}
                        </span>
                        <ul style={{ paddingLeft: "1.5rem" }}>
                          <li>
                            <span>
                              {trapsByAreaDensityInSquareKilometers}{" "}
                              {trapTypeNickname} / km²
                            </span>
                          </li>
                          <li>
                            <span>
                              {trapsByAreaDensityInHectare} {trapTypeNickname} /
                              ha
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                          marginBottom: ".8rem",
                        }}
                      >
                        <div>
                          <Button
                            data-tip
                            data-for={`${trapTypeName}-installedTraps`}
                            style={{
                              backgroundColor: "#3b6bca",
                              ...styles.trapsRangesButtons,
                            }}
                            onClick={() =>
                              setTrapsListToShowRange(activeTrapsList)
                            }
                          >
                            <img
                              src={trapIcon}
                              style={{ width: "25px" }}
                              alt="iconTrap"
                            />
                          </Button>
                          <ReactTooltip
                            effect="solid"
                            type="info"
                            id={`${trapTypeName}-installedTraps`}
                          >
                            Habilitar raios de todas as {trapTypeNickname}
                            's
                          </ReactTooltip>
                        </div>
                        <div>
                          <Button
                            data-tip
                            data-for={`${trapTypeName}-greenTraps`}
                            style={{
                              backgroundColor: "#76ea79",
                              ...styles.trapsRangesButtons,
                            }}
                            onClick={() =>
                              setTrapsListToShowRange(greenTrapsList)
                            }
                          >
                            <img
                              src={trapIcon}
                              style={{ width: "25px" }}
                              alt="iconTrap"
                            />
                          </Button>
                          <ReactTooltip
                            effect="solid"
                            type="info"
                            id={`${trapTypeName}-greenTraps`}
                          >
                            Habilitar raios das {trapTypeNickname}'s verdes
                          </ReactTooltip>
                        </div>
                        {trapRange.quantityRange === 3 && (
                          <div>
                            <Button
                              data-tip
                              data-for={`${trapTypeName}-yellowTraps`}
                              style={{
                                backgroundColor: "#fff200",
                                ...styles.trapsRangesButtons,
                              }}
                              onClick={() =>
                                setTrapsListToShowRange(yellowTrapsList)
                              }
                            >
                              <img
                                src={trapIcon}
                                style={{ width: "25px" }}
                                alt="iconTrap"
                              />
                            </Button>
                            <ReactTooltip
                              effect="solid"
                              type="info"
                              id={`${trapTypeName}-yellowTraps`}
                            >
                              Habilitar raios das {trapTypeNickname}'s amarelas
                            </ReactTooltip>
                          </div>
                        )}
                        <div>
                          <Button
                            data-tip
                            data-for={`${trapTypeName}-redTraps`}
                            style={{
                              backgroundColor: "#ff0000",
                              ...styles.trapsRangesButtons,
                            }}
                            onClick={() =>
                              setTrapsListToShowRange(redTrapsList)
                            }
                          >
                            <img
                              src={trapIcon}
                              style={{ width: "25px" }}
                              alt="iconTrap"
                            />
                          </Button>
                          <ReactTooltip
                            effect="solid"
                            type="info"
                            id={`${trapTypeName}-redTraps`}
                          >
                            Habilitar raios das {trapTypeNickname}'s vermelhas
                          </ReactTooltip>
                        </div>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          onClick={() => setTrapsListToShowRange([])}
                        >
                          Desabilitar raios
                        </Button>
                      </div>
                    </section>
                  </AccordionContent>
                </AccordionItem>
              );
            }
          )}
      </Accordion>
    </section>
  );
};

const ColorsPreferenceSection = ({ data }) => {
  const [isPolygonColorAccordionOpen, setIsPolygonColorAccordionOpen] =
    React.useState(false);
  const [isTypeLayerColorAccordionOpen, setIsTypeLayerColorAccordionOpen] =
    React.useState(false);

  const {
    handleAddPolygonPreferenceColor,
    handleDeletePolygonPreferenceColor,
    handleSavePolygonPreferenceColor,
  } = usePolygonsColorsPreferences();

  const polygonPossibleColors = [
    "#000000",
    "#808080",
    "#0000FF",
    "#00FF00",
    "#D2691E",
    "#A020F0",
    "#fc03d2",
    "#ff0000",
    "#ffff00",
  ];

  const polygonColorsFirstRow = polygonPossibleColors.slice(0, 4);
  const polygonColorsSecondRow = polygonPossibleColors.slice(4);

  const styles = {
    colorPickerButton: {
      borderRadius: "100%",
      width: "0px",
      padding: "11px",
      boxShadow: "none",
      borderColor: "#646464",
    },
    accordionContent: {
      padding: ".5rem 1.5rem",
      borderBottom: "1px solid #f0f1f3",
    },
    sectionContainer: {
      padding: "0 1.3rem",
    },
  };

  return (
    <section className="mt-2 mb-4" style={styles.sectionContainer}>
      <span className="h4" style={{ display: "block" }}>
        Cores:
      </span>
      <Accordion>
        <AccordionItem style={{ padding: "0px" }}>
          <AccordionHeader
            isOpen={isPolygonColorAccordionOpen}
            onClick={() =>
              setIsPolygonColorAccordionOpen((previousValue) => !previousValue)
            }
            style={{ padding: ".8rem" }}
          >
            <div>
              <i className="fas fa-pen"></i>{" "}
              <span className="h4">Polígono</span>
            </div>
          </AccordionHeader>
          <AccordionContent
            isOpen={isPolygonColorAccordionOpen}
            style={styles.accordionContent}
          >
            <div>
              <span
                className="h4"
                style={{ display: "block", textAlign: "center" }}
              >
                Borda do polígono:
              </span>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                  marginBottom: ".3rem",
                }}
              >
                {polygonColorsFirstRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: color,
                          interiorColor: "",
                          entity: "polygon",
                          polygonId: data.id,
                          typeLayerId: "00000000-0000-0000-0000-000000000000",
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                }}
              >
                {polygonColorsSecondRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: color,
                          interiorColor: "",
                          entity: "polygon",
                          polygonId: data.id,
                          typeLayerId: "00000000-0000-0000-0000-000000000000",
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
                <li>
                  <div
                    style={{
                      border: "1px solid #646464",
                      borderRadius: "100%",
                      cursor: "pointer",
                      marginLeft: "1px",
                    }}
                    onClick={() =>
                      handleDeletePolygonPreferenceColor({
                        deleteBorderColor: true,
                        deleteInteriorColor: false,
                        entity: "polygon",
                        polygonId: data.id,
                        typeLayerId: "00000000-0000-0000-0000-000000000000",
                        organizationId: getUserData("organizationId"),
                      })
                    }
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        padding: "6px",
                        fontSize: "15px",
                      }}
                    >
                      X
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <span
                className="h4"
                style={{ display: "block", textAlign: "center" }}
              >
                Interior do polígono:
              </span>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                  marginBottom: ".3rem",
                }}
              >
                {polygonColorsFirstRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: "",
                          interiorColor: color,
                          entity: "polygon",
                          polygonId: data.id,
                          typeLayerId: "00000000-0000-0000-0000-000000000000",
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                }}
              >
                {polygonColorsSecondRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: "",
                          interiorColor: color,
                          entity: "polygon",
                          polygonId: data.id,
                          typeLayerId: "00000000-0000-0000-0000-000000000000",
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
                <li>
                  <div
                    style={{
                      border: "1px solid #646464",
                      borderRadius: "100%",
                      cursor: "pointer",
                      marginLeft: "1px",
                    }}
                    onClick={() =>
                      handleDeletePolygonPreferenceColor({
                        deleteBorderColor: false,
                        deleteInteriorColor: true,
                        entity: "polygon",
                        polygonId: data.id,
                        typeLayerId: "00000000-0000-0000-0000-000000000000",
                        organizationId: getUserData("organizationId"),
                      })
                    }
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        padding: "6px",
                        fontSize: "15px",
                      }}
                    >
                      X
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <Button
              color="primary"
              onClick={() => {
                handleSavePolygonPreferenceColor();
                toast.success("Preferências salvas com sucesso");
              }}
            >
              Salvar preferências
            </Button>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem style={{ padding: "0px" }}>
          <AccordionHeader
            isOpen={isTypeLayerColorAccordionOpen}
            onClick={() =>
              setIsTypeLayerColorAccordionOpen(
                (previousValue) => !previousValue
              )
            }
            style={{ padding: ".8rem" }}
          >
            <div>
              <i className="fas fa-pen"></i> <span className="h4">Camada</span>
            </div>
          </AccordionHeader>
          <AccordionContent
            isOpen={isTypeLayerColorAccordionOpen}
            style={styles.accordionContent}
          >
            <div>
              <span style={{ fontSize: "11px" }}>
                A cor do polígono tem prefêrencia sobre a camada. Caso um
                polígono da camada possua cores, a cor da camada será ignorada.
              </span>
              <span
                className="h4"
                style={{ display: "block", textAlign: "center" }}
              >
                Borda da camada:
              </span>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                  marginBottom: ".3rem",
                }}
              >
                {polygonColorsFirstRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: color,
                          interiorColor: "",
                          entity: "typeLayer",
                          polygonId: "00000000-0000-0000-0000-000000000000",
                          typeLayerId: data.typeLayerId,
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                }}
              >
                {polygonColorsSecondRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: color,
                          interiorColor: "",
                          entity: "typeLayer",
                          polygonId: "00000000-0000-0000-0000-000000000000",
                          typeLayerId: data.typeLayerId,
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
                <li>
                  <div
                    style={{
                      border: "1px solid #646464",
                      borderRadius: "100%",
                      cursor: "pointer",
                      marginLeft: "1px",
                    }}
                    onClick={() =>
                      handleDeletePolygonPreferenceColor({
                        deleteBorderColor: true,
                        deleteInteriorColor: false,
                        entity: "typeLayer",
                        polygonId: "00000000-0000-0000-0000-000000000000",
                        typeLayerId: data.typeLayerId,
                        organizationId: getUserData("organizationId"),
                      })
                    }
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        padding: "6px",
                        fontSize: "15px",
                      }}
                    >
                      X
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <span
                className="h4"
                style={{ display: "block", textAlign: "center" }}
              >
                Interior da camada:
              </span>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                  marginBottom: ".3rem",
                }}
              >
                {polygonColorsFirstRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: "",
                          interiorColor: color,
                          entity: "typeLayer",
                          polygonId: "00000000-0000-0000-0000-000000000000",
                          typeLayerId: data.typeLayerId,
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                }}
              >
                {polygonColorsSecondRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() =>
                        handleAddPolygonPreferenceColor({
                          borderColor: "",
                          interiorColor: color,
                          entity: "typeLayer",
                          polygonId: "00000000-0000-0000-0000-000000000000",
                          typeLayerId: data.typeLayerId,
                          organizationId: getUserData("organizationId"),
                        })
                      }
                    ></Button>
                  </li>
                ))}
                <li>
                  <div
                    style={{
                      border: "1px solid #646464",
                      borderRadius: "100%",
                      cursor: "pointer",
                      marginLeft: "1px",
                    }}
                    onClick={() =>
                      handleDeletePolygonPreferenceColor({
                        deleteBorderColor: false,
                        deleteInteriorColor: true,
                        entity: "typeLayer",
                        polygonId: "00000000-0000-0000-0000-000000000000",
                        typeLayerId: data.typeLayerId,
                        organizationId: getUserData("organizationId"),
                      })
                    }
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        padding: "6px",
                        fontSize: "15px",
                      }}
                    >
                      X
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <Button
              color="primary"
              onClick={() => {
                handleSavePolygonPreferenceColor();
                toast.success("Preferências salvas com sucesso");
              }}
            >
              Salvar preferências
            </Button>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </section>
  );
};

export default React.memo(TrapsPageMapWrapperComponent);
