import React, { useEffect, useState } from "react";
import { withBasicListMap } from "../../../../HOC/Entomologico/Map/BasicListMap";
import CardMapPresentational from "../../../Presentational/CardMapPresentational";
import { point } from "@turf/turf";
import { Button } from "reactstrap";
import { daysDifferenceBetweenTodayAndDate } from "../../../../services/utils/TrapStatus/trapStatus";
import { createCloropleticGroups } from "../../../../services/utils/Maps/map";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import { getUserData } from "../../../../services/utils/auth";

const style = {
  cardHeaderText: {
    color: "black",
    fontWeight: "bold",
    fontSize: "0.97rem",
  },
};

const ActivesMapContainer = ({
  legend,
  trapMapInfo,
  territorializationsList,
  typeLayerSubtypeTerritorializationsList,
  territorializationFiltered,
  buildMapList,
  trapTypeName,
  trapToCenterOnMapPosition,
  setTrapToCenterOnMapPosition,
  trapToCenterNumber,
  setModal2,
  modal2,
  setTrapToUpdateData,
  trapToUpdateData,
}) => {
  const [mapList, setMapList] = useState([]);
  const [mapCloropleticGroups, setMapCloropleticGroups] = useState([]);
  const [
    mapTypeLayerSubtypeCloropleticGroups,
    setMapTypeLayerSubtypeCloropleticGroups,
  ] = useState([]);
  const [cluster, setCluster] = useState(20);
  const [trapsToPopUp, setTrapsToPopUp] = useState({});
  const [trapQuantity, setTrapQuantity] = useState(0);

  useEffect(() => {
    if (
      trapTypeName &&
      trapMapInfo &&
      territorializationsList &&
      territorializationsList.length > 0
    ) {
      var trapFeature = [];

      trapMapInfo.map((obj) =>
        obj.data.actives.map((trap) =>
          trapFeature.push(
            point([trap.longitude, trap.latitude], {
              name: obj.trapType,
              trapType: trap.trapTypeName,
              lastInstallDate: trap.dateInstalled,
              lastReadDate: trap.lastReadDate,
            })
          )
        )
      );

      var trapsByTrapType = trapMapInfo.filter(
        (t) => t.trapType == trapTypeName
      );
      if (trapsByTrapType.length > 0) {
        setTrapQuantity(trapsByTrapType[0].data.actives.length);
      }

      const traps = {
        type: "FeatureCollection",
        features: trapFeature,
      };

      setTrapsToPopUp(traps);

      const territorializationsCloropleticGroups = createCloropleticGroups(
        territorializationsList,
        territorializationFiltered
      );

      setMapCloropleticGroups(territorializationsCloropleticGroups);
    }
  }, [
    trapTypeName,
    trapMapInfo,
    territorializationsList,
    territorializationFiltered,
  ]);

  React.useEffect(() => {
    if (
      !typeLayerSubtypeTerritorializationsList ||
      typeLayerSubtypeTerritorializationsList.length === 0
    )
      return;

    const typeLayerSubtypeTerritorializationsCloropleticGroups =
      createCloropleticGroups(
        typeLayerSubtypeTerritorializationsList,
        territorializationFiltered
      );

    setMapTypeLayerSubtypeCloropleticGroups(
      typeLayerSubtypeTerritorializationsCloropleticGroups
    );
  }, [typeLayerSubtypeTerritorializationsList]);

  useEffect(() => {
    if (trapMapInfo && trapMapInfo.length > 0) {
      var mapListAux = [];
      trapMapInfo.map((objTraps) => {
        mapListAux.push(
          buildMapList(
            objTraps.data.actives,
            "basictrap",
            "black",
            objTraps.trapType,
            false,
            true,
            trapTypeName,
            true,
            objTraps.organizationId
          )
        );
      });

      setMapList(mapListAux);
    }
  }, [trapMapInfo]);

  const changeCluster = (e) => {
    setCluster(e.target.getZoom() >= 18 ? 3 : 20);
  };

  const buildPolygonPopup = (
    territorialization,
    checkedLayers = null,
    setTrapsToShowOperatingRange,
    handleClearAllOperatingRanges,
    trapsInsidePolygon
  ) => {
    var trapType = trapTypeName;
    if (checkedLayers) {
      trapType = "";
      checkedLayers.map((traps, index) => {
        if (index > 0) {
          trapType += ", ";
        }
        trapType += traps.name;
      });
    }

    const handleTrapsOperatingRangeButtonClick = (operatingRangeType) => {
      if (operatingRangeType === "instalation") {
        setTrapsToShowOperatingRange(trapsInsidePolygon);

        return;
      }

      if (operatingRangeType === "covered") {
        const filteredTrapsToShowOperatingRange =
          trapsInsidePolygon.features.filter(
            ({ properties: { trapType, lastInstallDate, lastReadDate } }) => {
              const trapStatusColor = getTrapColorStatus(
                trapType,
                lastInstallDate,
                lastReadDate
              );

              if (trapStatusColor === "red") return false;

              return true;
            }
          );

        const result = filteredTrapsToShowOperatingRange.map((feature) => {
          const { trapType, lastInstallDate, lastReadDate } =
            feature.properties;
          const trapStatusColor = getTrapColorStatus(
            trapType,
            lastInstallDate,
            lastReadDate
          );

          return {
            ...feature,
            properties: {
              ...feature.properties,
              situationColor: trapStatusColor,
            },
          };
        });

        const featureCollection = {
          type: "FeatureCollection",
          features: result,
        };

        setTrapsToShowOperatingRange(featureCollection);

        return;
      }

      if (operatingRangeType === "risk") {
        const filteredTrapsToShowOperatingRange =
          trapsInsidePolygon.features.filter(
            ({ properties: { trapType, lastInstallDate, lastReadDate } }) => {
              const trapStatusColor = getTrapColorStatus(
                trapType,
                lastInstallDate,
                lastReadDate
              );

              if (trapStatusColor !== "red") return false;

              return true;
            }
          );

        const result = filteredTrapsToShowOperatingRange.map((feature) => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              situationColor: "red",
            },
          };
        });

        const featureCollection = {
          type: "FeatureCollection",
          features: result,
        };

        setTrapsToShowOperatingRange(featureCollection);

        return;
      }
    };

    const getTrapColorStatus = (trapType, installDate, lastReadDate) => {
      let daysDifference;

      const daysDifferenceBetweenTodayAndInstallDate =
        daysDifferenceBetweenTodayAndDate(installDate);
      const daysDifferenceBetweenTodayAndLastReadDate =
        daysDifferenceBetweenTodayAndDate(lastReadDate);

      if (daysDifferenceBetweenTodayAndLastReadDate === null)
        daysDifference = daysDifferenceBetweenTodayAndInstallDate;
      else
        daysDifference = Math.min(
          daysDifferenceBetweenTodayAndInstallDate,
          daysDifferenceBetweenTodayAndLastReadDate
        );

      const trapTypeRange = getTrapRange(trapType);

      if (trapTypeRange.quantityRange === 2) {
        if (daysDifference <= trapTypeRange.data.end) return "green";

        return "red";
      }

      if (trapTypeRange.quantityRange === 3) {
        if (daysDifference <= trapTypeRange.data.middle) return "green";
        if (daysDifference <= trapTypeRange.data.end) return "yellow";

        return "red";
      }

      return "white";
    };

    const trapsStatusColors = trapsInsidePolygon.features.reduce(
      (acc, { properties: { trapType, lastInstallDate, lastReadDate } }) => {
        const trapStatusColor = getTrapColorStatus(
          trapType,
          lastInstallDate,
          lastReadDate
        );

        if (trapStatusColor === "green") {
          acc.green += 1;
        } else if (trapStatusColor === "yellow") {
          acc.yellow += 1;
        } else if (trapStatusColor === "red") {
          acc.red += 1;
        }

        return acc;
      },
      { green: 0, yellow: 0, red: 0 }
    );

    return (
      <div>
        <b>{territorialization.name}</b> <br />
        <br />
        {territorialization.data.length} Armadilhas {trapType}
        <br />
        <br />
        {trapsStatusColors.green + trapsStatusColors.yellow} Armadilhas áreas
        protegidas {trapType}
        <br />
        <br />
        {trapsStatusColors.red} Armadilhas áreas risco {trapType}
        <br />
        <br />
        Área:
        <ul>
          <li>{territorialization.areaHa.toFixed(2)} ha</li>
          <li>{territorialization.areaKm.toFixed(2)} km²</li>
        </ul>
        Densidade (armadilhas / área):
        <ul>
          <li>{territorialization.densityHa.toFixed(4)} armadilhas / ha</li>
          <li>{territorialization.densityKm.toFixed(4)} armadilhas / km²</li>
        </ul>
        Densidade (área / armadilhas):
        <ul>
          <li>{territorialization.trapDensityHa.toFixed(4)} ha / armadilhas</li>
          <li>
            {territorialization.trapDensitySquareMeters.toFixed(2)} m² /
            armadilhas
          </li>
        </ul>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            color="primary"
            style={{
              marginTop: "16px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginLeft: "0px",
              marginRight: "0px",
              justifyContent: "center",
            }}
            onClick={() => handleTrapsOperatingRangeButtonClick("instalation")}
          >
            <i className="ni ni-ruler-pencil" style={{ color: "white" }}></i>{" "}
            Áreas de instalação
          </Button>
          <Button
            color="primary"
            style={{
              marginTop: "16px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginLeft: "0px",
              marginRight: "0px",
              justifyContent: "center",
            }}
            onClick={() => handleTrapsOperatingRangeButtonClick("covered")}
          >
            <i className="ni ni-ruler-pencil" style={{ color: "white" }}></i>{" "}
            Áreas protegidas
          </Button>
          <Button
            color="primary"
            style={{
              marginTop: "16px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginLeft: "0px",
              marginRight: "0px",
              justifyContent: "center",
            }}
            onClick={() => handleTrapsOperatingRangeButtonClick("risk")}
          >
            <i className="ni ni-ruler-pencil" style={{ color: "white" }}></i>{" "}
            Áreas de risco
          </Button>
          <Button
            color="danger"
            style={{
              margin: "16px 0px 0px 0px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginLeft: "0px",
              marginRight: "0px",
              justifyContent: "center",
            }}
            onClick={() => handleClearAllOperatingRanges()}
          >
            <i className="ni ni-fat-remove" style={{ color: "white" }}></i>{" "}
            Limpar áreas
          </Button>
        </div>
      </div>
    );
  };

  const headerText =
    getUserData("organizationId") === "7479f4e9-1706-41d4-8402-0bae72ef71ec"
      ? "Armadilhas ativas"
      : trapTypeName !== undefined || trapQuantity > 0
      ? "Total de armadilhas ativas: " + trapQuantity
      : "Armadilhas ativas";

  return (
    <CardMapPresentational
      textCardHeader={headerText}
      style={style}
      mapList={mapList}
      buildPolygonPopup={buildPolygonPopup}
      dataToPopup={trapsToPopUp}
      territorializations={mapCloropleticGroups}
      typeLayerSubtypeTerritorializations={mapTypeLayerSubtypeCloropleticGroups}
      changeCluster={changeCluster}
      maxClusterRadius={cluster}
      titles={["Armadilhas"]}
      trapTypeName={trapTypeName}
      setTrapToCenterOnMapPosition={setTrapToCenterOnMapPosition}
      trapToCenterOnMapPosition={trapToCenterOnMapPosition}
      trapToCenterNumber={trapToCenterNumber}
      trapQuantity={trapQuantity}
      setModal2={setModal2}
      modal2={modal2}
      setTrapToUpdateData={setTrapToUpdateData}
      trapToUpdateData={trapToUpdateData}
    />
  );
};

export default withBasicListMap(ActivesMapContainer);
