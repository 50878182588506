import React from "react";

import useAppliedFilters from "./Hooks/useAppliedFilters";
import useGraphicsData from "./Hooks/useGraphicsData";
import { Col, Row } from "reactstrap";
import PositivityIndexMap from "./Maps/PositivityIndexMap";
import { IPLGraphic } from "./Graphics/IPL/IPLGraphic";
import { IPEGraphic } from "./Graphics/IPE/IPEGraphic";
import { groupPositiveTrapsEstimatesByWeek } from "./Utils/functions";
import { IPOGraphic } from "./Graphics/IPO/IPOGraphic";
import { IPAGraphic } from "./Graphics/IPA/IPAGraphic";
import useLoading from "./Hooks/useLoading";
import FieldResultsTable from "./Tables/FieldResultsTable";
import MapLoadingSkeleton from "../../../components/ui/Loading/MapLoadingSkeleton";
import TableLoadingSkeleton from "../../../components/ui/Loading/TableLoadingSkeleton";
import { getTodayEpidemiologicalWeek } from "../../../services/utils/todayEpidemiologicalWeek";
import moment from "moment";
import usePositivityIndex from "./Hooks/usePositivityIndex";

const useFieldResultsPageGraphicsWrapper = () => {
  const [estimatesGraphicsData, setEstimatesGraphicsData] = React.useState([]);
  const { positivityIndexData, setPositivityIndexData } = usePositivityIndex();
  const { appliedFilters } = useAppliedFilters();
  const { fieldResultsList, situationsByTerritorializations } =
    useGraphicsData();
  const { isFieldResultsLoading } = useLoading();

  React.useEffect(() => {
    if(!fieldResultsList) return;

    const territorializationsPositivityIndexGraphicData = [];
    const groupedPositivityIndexesByPeriods = [];
    const groupedEstimatesByTerritorializations = [];

    fieldResultsList.forEach(({ territorializationName, periods }) => {
      const formatedDataForPositivityIndexGraphics =
        formatDataForPositivityIndexGraphics(periods, territorializationName);

      territorializationsPositivityIndexGraphicData.push(
        ...formatedDataForPositivityIndexGraphics
      );

      const groupedPositiveTrapsByWeek = groupPositiveTrapsEstimatesByWeek(
        periods,
        appliedFilters.trapType.trapTypeName
      );

      const { aedesAegypti, egg, exuvia, larvaOrPupa } =
        formatPositiveTrapsForGraphics(groupedPositiveTrapsByWeek);

      const estimatesData = {
        territorializationName,
        results: {
          aedesAegypti,
          egg,
          exuvia,
          larvaOrPupa,
        },
      };

      groupedEstimatesByTerritorializations.push(estimatesData);
    });

    if (appliedFilters.beginDate !== "" && appliedFilters.endDate !== "") {
      const datePeriodLabel = `${moment(appliedFilters.beginDate).format(
        "DD/MM/YYYY"
      )} A ${moment(appliedFilters.endDate).format("DD/MM/YYYY")}`;

      const allTerritorializationsPeriodPositivityIndex =
        territorializationsPositivityIndexGraphicData.filter(
          ({ periodLabel }) => datePeriodLabel === periodLabel
        );

      const result = allTerritorializationsPeriodPositivityIndex.reduce(
        (acc, curr) => {
          acc.diagnosedTraps += curr.diagnosedTraps;
          acc.negativeTraps += curr.negativeTraps;
          acc.positiveForAedesAegypti += curr.positiveForAedesAegypti;
          acc.positiveForEgg += curr.positiveForEgg;
          acc.positiveForExuvia += curr.positiveForExuvia;
          acc.positiveForLarvaOrPupa += curr.positiveForLarvaOrPupa;

          acc = { ...acc, ...curr.indexes };

          acc.periodLabel = curr.periodLabel;

          return acc;
        },
        {
          diagnosedTraps: 0,
          negativeTraps: 0,
          positiveForAedesAegypti: 0,
          positiveForEgg: 0,
          positiveForExuvia: 0,
          positiveForLarvaOrPupa: 0,
          periodLabel: "",
        }
      );

      groupedPositivityIndexesByPeriods.push(result);

      setPositivityIndexData(groupedPositivityIndexesByPeriods);
      setEstimatesGraphicsData(groupedEstimatesByTerritorializations);

      return;
    }

    if (appliedFilters.datePeriodType === "week") {
      appliedFilters.epidemiologicalWeeks.forEach(({ label }) => {
        const allTerritorializationsPeriodPositivityIndex =
          territorializationsPositivityIndexGraphicData.filter(
            ({ periodLabel }) => label === periodLabel
          );

        const result = allTerritorializationsPeriodPositivityIndex.reduce(
          (acc, curr) => {
            acc.diagnosedTraps += curr.diagnosedTraps;
            acc.negativeTraps += curr.negativeTraps;
            acc.positiveForAedesAegypti += curr.positiveForAedesAegypti;
            acc.positiveForEgg += curr.positiveForEgg;
            acc.positiveForExuvia += curr.positiveForExuvia;
            acc.positiveForLarvaOrPupa += curr.positiveForLarvaOrPupa;

            acc = { ...acc, ...curr.indexes };

            acc.periodLabel = curr.periodLabel;

            return acc;
          },
          {
            diagnosedTraps: 0,
            negativeTraps: 0,
            positiveForAedesAegypti: 0,
            positiveForEgg: 0,
            positiveForExuvia: 0,
            positiveForLarvaOrPupa: 0,
            periodLabel: "",
          }
        );

        groupedPositivityIndexesByPeriods.push(result);
      });

      setPositivityIndexData(groupedPositivityIndexesByPeriods);
      setEstimatesGraphicsData(groupedEstimatesByTerritorializations);

      return;
    }

    if (appliedFilters.datePeriodType === "month") {
      appliedFilters.months.forEach(({ label }) => {
        const allTerritorializationsPeriodPositivityIndex =
          territorializationsPositivityIndexGraphicData.filter(
            ({ periodLabel }) => label === periodLabel
          );

        const result = allTerritorializationsPeriodPositivityIndex.reduce(
          (acc, curr) => {
            acc.diagnosedTraps += curr.diagnosedTraps;
            acc.negativeTraps += curr.negativeTraps;
            acc.positiveForAedesAegypti += curr.positiveForAedesAegypti;
            acc.positiveForEgg += curr.positiveForEgg;
            acc.positiveForExuvia += curr.positiveForExuvia;
            acc.positiveForLarvaOrPupa += curr.positiveForLarvaOrPupa;

            acc = { ...acc, ...curr.indexes };

            acc.periodLabel = curr.periodLabel;

            return acc;
          },
          {
            diagnosedTraps: 0,
            negativeTraps: 0,
            positiveForAedesAegypti: 0,
            positiveForEgg: 0,
            positiveForExuvia: 0,
            positiveForLarvaOrPupa: 0,
            periodLabel: "",
          }
        );

        groupedPositivityIndexesByPeriods.push(result);
      });

      setPositivityIndexData(groupedPositivityIndexesByPeriods);
      setEstimatesGraphicsData(groupedEstimatesByTerritorializations);

      return;
    }
  }, [fieldResultsList]);

  const formatDataForPositivityIndexGraphics = (
    fieldResultsList,
    territorializationName
  ) => {
    if (!fieldResultsList || fieldResultsList.length === 0) return [];

    const formatedDataForPositivityIndexGraphics = fieldResultsList.map(
      ({
        diagnosedTraps,
        periodName,
        positiveTraps,
        negativeTrapsForEggsLarvaesPupasOrMosquitoes,
      }) => {
        const ipaIndex = (
          (positiveTraps.forAedesAegypti.length / diagnosedTraps) *
          100
        ).toFixed(2);
        const ipeIndex = (
          (positiveTraps.forExuvia.length / diagnosedTraps) *
          100
        ).toFixed(2);
        const iplIndex = (
          (positiveTraps.forLarvaOrPupa.length / diagnosedTraps) *
          100
        ).toFixed(2);
        const ipoIndex = (
          (positiveTraps.forEgg.length / diagnosedTraps) *
          100
        ).toFixed(2);

        const result = {
          diagnosedTraps,
          negativeTraps: negativeTrapsForEggsLarvaesPupasOrMosquitoes.length,
          positiveForAedesAegypti: positiveTraps.forAedesAegypti.length,
          positiveForEgg: positiveTraps.forEgg.length,
          positiveForExuvia: positiveTraps.forExuvia.length,
          positiveForLarvaOrPupa: positiveTraps.forLarvaOrPupa.length,
          indexes: {
            [`${territorializationName}IPA`]:
              ipaIndex === "NaN" ? "0.00" : ipaIndex,
            [`${territorializationName}IPE`]:
              ipeIndex === "NaN" ? "0.00" : ipeIndex,
            [`${territorializationName}IPL`]:
              iplIndex === "NaN" ? "0.00" : iplIndex,
            [`${territorializationName}IPO`]:
              ipoIndex === "NaN" ? "0.00" : ipoIndex,
          },
          trapTypeName: appliedFilters.trapType.trapTypeName,
          periodLabel: periodName,
          territorializationName,
        };

        return result;
      }
    );

    return formatedDataForPositivityIndexGraphics;
  };

  const formatPositiveTrapsForGraphics = (groupedPositiveTraps) => {
    if (Object.keys(groupedPositiveTraps).length === 0)
      return {
        larvaOrPupa: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
        egg: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
        aedesAegypti: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
        exuvia: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
      };

    const {
      aedesAegyptiEstimatesByWeek,
      eggEstimatesByWeek,
      exuviaEstimatesByWeek,
      larvaOrPupaEstimatesByWeek,
    } = groupedPositiveTraps;

    const {
      larvaOrPupaAbosluteEstimatesValuesByWeek,
      larvaOrPupaPercentageEstimatesValuesByWeek,
      larvaOrPupaEstimatesCategories,
    } = getLarvaOrPupaEstimatesForGraphics(larvaOrPupaEstimatesByWeek);

    const {
      eggAbosluteEstimatesValuesByWeek,
      eggPercentageEstimatesValuesByWeek,
      eggEstimatesCategories,
    } = getEggEstimatesForGraphics(eggEstimatesByWeek);

    const {
      aedesAegyptiAbosluteEstimatesValuesByWeek,
      aedesAegyptiPercentageEstimatesValuesByWeek,
      aedesAegyptiEstimatesCategories,
    } = getAedesAegyptiEstimatesForGraphics(aedesAegyptiEstimatesByWeek);

    const {
      exuviaAbosluteEstimatesValuesByWeek,
      exuviaPercentageEstimatesValuesByWeek,
      exuviaEstimatesCategories,
    } = getExuviaEstimatesForGraphics(exuviaEstimatesByWeek);

    return {
      larvaOrPupa: {
        absoluteEstimatesValuesByWeek: larvaOrPupaAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek:
          larvaOrPupaPercentageEstimatesValuesByWeek,
        estimatesCategories: larvaOrPupaEstimatesCategories,
      },
      egg: {
        absoluteEstimatesValuesByWeek: eggAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek: eggPercentageEstimatesValuesByWeek,
        estimatesCategories: eggEstimatesCategories,
      },
      aedesAegypti: {
        absoluteEstimatesValuesByWeek:
          aedesAegyptiAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek:
          aedesAegyptiPercentageEstimatesValuesByWeek,
        estimatesCategories: aedesAegyptiEstimatesCategories,
      },
      exuvia: {
        absoluteEstimatesValuesByWeek: exuviaAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek: exuviaPercentageEstimatesValuesByWeek,
        estimatesCategories: exuviaEstimatesCategories,
      },
    };
  };

  const getLarvaOrPupaEstimatesForGraphics = (larvaOrPupaEstimatesByWeek) => {
    if (
      !larvaOrPupaEstimatesByWeek ||
      Object.keys(larvaOrPupaEstimatesByWeek).length === 0
    )
      return {
        larvaOrPupaAbosluteEstimatesValuesByWeek: [],
        larvaOrPupaPercentageEstimatesValuesByWeek: [],
        larvaOrPupaEstimatesCategories: [],
      };

    const larvaOrPupaAbosluteEstimatesValuesByWeek = Object.keys(
      larvaOrPupaEstimatesByWeek
    ).map((week) => {
      const estimates = larvaOrPupaEstimatesByWeek[week];

      const newEstimates = {
        "1 a 20":
          (estimates["6 a 10"] ? estimates["6 a 10"] : 0) +
          (estimates["1 a 20"] ? estimates["1 a 20"] : 0),
        "21 a 100":
          (estimates["21 a 100"] ? estimates["21 a 100"] : 0) +
          (estimates["21 a 50"] ? estimates["21 a 50"] : 0) +
          (estimates["51 a 100"] ? estimates["51 a 100"] : 0),
        "Mais de 100": estimates["Mais de 100"]
          ? estimates["Mais de 100"]
          : estimates["mais de 100"]
          ? estimates["mais de 100"]
          : 0,
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const larvaOrPupaPercentageEstimatesValuesByWeek =
      larvaOrPupaAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1 a 20"] + estimate["21 a 100"] + estimate["Mais de 100"];

        const firstPeriod = +(
          (estimate["1 a 20"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["21 a 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          "1 a 20": isNaN(firstPeriod) ? 0 : firstPeriod,
          "21 a 100": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 100": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const larvaOrPupaEstimatesCategories =
      !larvaOrPupaPercentageEstimatesValuesByWeek ||
      larvaOrPupaPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(larvaOrPupaPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      larvaOrPupaAbosluteEstimatesValuesByWeek,
      larvaOrPupaPercentageEstimatesValuesByWeek,
      larvaOrPupaEstimatesCategories,
    };
  };

  const getEggEstimatesForGraphics = (eggEstimatesByWeek) => {
    if (!eggEstimatesByWeek || Object.keys(eggEstimatesByWeek).length === 0)
      return {
        eggAbosluteEstimatesValuesByWeek: [],
        eggPercentageEstimatesValuesByWeek: [],
        eggEstimatesCategories: [],
      };

    const eggAbosluteEstimatesValuesByWeek = Object.keys(
      eggEstimatesByWeek
    ).map((week) => {
      const estimates = eggEstimatesByWeek[week];

      const newEstimates = {
        "1 a 100": estimates["1 a 100"] ? estimates["1 a 100"] : 0,
        "101 a 200": estimates["101 a 200"] ? estimates["101 a 200"] : 0,
        "Mais de 200": estimates["Mais de 200"],
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const eggPercentageEstimatesValuesByWeek =
      eggAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1 a 100"] + estimate["101 a 200"] + estimate["Mais de 200"];

        const firstPeriod = +(
          (estimate["1 a 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["101 a 200"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 200"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          "1 a 100": isNaN(firstPeriod) ? 0 : firstPeriod,
          "101 a 200": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 200": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const eggEstimatesCategories =
      !eggPercentageEstimatesValuesByWeek ||
      eggPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(eggPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      eggAbosluteEstimatesValuesByWeek,
      eggPercentageEstimatesValuesByWeek,
      eggEstimatesCategories,
    };
  };

  const getAedesAegyptiEstimatesForGraphics = (aedesAegyptiEstimatesByWeek) => {
    if (
      !aedesAegyptiEstimatesByWeek ||
      Object.keys(aedesAegyptiEstimatesByWeek).length === 0
    )
      return {
        aedesAegyptiAbosluteEstimatesValuesByWeek: [],
        aedesAegyptiPercentageEstimatesValuesByWeek: [],
        aedesAegyptiEstimatesCategories: [],
      };

    const aedesAegyptiAbosluteEstimatesValuesByWeek = Object.keys(
      aedesAegyptiEstimatesByWeek
    ).map((week) => {
      const estimates = aedesAegyptiEstimatesByWeek[week];

      const newEstimates = {
        1: estimates["1"] ? estimates["1"] : 0,
        "2 a 3": estimates["2 a 3"] ? estimates["2 a 3"] : 0,
        "Mais de 3": estimates["Mais de 3"] ? estimates["Mais de 3"] : 0,
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const aedesAegyptiPercentageEstimatesValuesByWeek =
      aedesAegyptiAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1"] + estimate["2 a 3"] + estimate["Mais de 3"];

        const firstPeriod = +(
          (estimate["1"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["2 a 3"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 3"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          1: isNaN(firstPeriod) ? 0 : firstPeriod,
          "2 a 3": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 3": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const aedesAegyptiEstimatesCategories =
      !aedesAegyptiPercentageEstimatesValuesByWeek ||
      aedesAegyptiPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(aedesAegyptiPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      aedesAegyptiAbosluteEstimatesValuesByWeek,
      aedesAegyptiPercentageEstimatesValuesByWeek,
      aedesAegyptiEstimatesCategories,
    };
  };

  const getExuviaEstimatesForGraphics = (exuviaEstimatesByWeek) => {
    if (
      !exuviaEstimatesByWeek ||
      Object.keys(exuviaEstimatesByWeek).length === 0
    )
      return {
        exuviaAbosluteEstimatesValuesByWeek: [],
        exuviaPercentageEstimatesValuesByWeek: [],
        exuviaEstimatesCategories: [],
      };

    const exuviaAbosluteEstimatesValuesByWeek = Object.keys(
      exuviaEstimatesByWeek
    ).map((week) => {
      const estimates = exuviaEstimatesByWeek[week];

      const newEstimates = {
        "1 a 20": estimates["1 a 20"] ? estimates["1 a 20"] : 0,
        "21 a 100": estimates["21 a 100"] ? estimates["21 a 100"] : 0,
        "Mais de 100": estimates["Mais de 100"],
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const exuviaPercentageEstimatesValuesByWeek =
      exuviaAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1 a 20"] + estimate["21 a 100"] + estimate["Mais de 100"];

        const firstPeriod = +(
          (estimate["1 a 20"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["21 a 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          "1 a 20": isNaN(firstPeriod) ? 0 : firstPeriod,
          "21 a 100": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 100": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const exuviaEstimatesCategories =
      !exuviaPercentageEstimatesValuesByWeek ||
      exuviaPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(exuviaPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      exuviaAbosluteEstimatesValuesByWeek,
      exuviaPercentageEstimatesValuesByWeek,
      exuviaEstimatesCategories,
    };
  };

  return {
    appliedFilters,
    estimatesGraphicsData,
    situationsByTerritorializations,
    isFieldResultsLoading,
    positivityIndexData,
  };
};

const FieldResultsPageGraphicsWrapper = () => {
  const {
    appliedFilters,
    estimatesGraphicsData,
    situationsByTerritorializations,
    isFieldResultsLoading,
    positivityIndexData,
  } = useFieldResultsPageGraphicsWrapper();

  if (isFieldResultsLoading)
    return (
      <section>
        {appliedFilters.trapType.trapTypeName ===
          "armadilhaDisseminadoraInseticida" ||
        appliedFilters.trapType.trapTypeName === "armadilhaMosquitoAdulto" ||
        appliedFilters.trapType.trapTypeName === "armadilhaMosquitoAdulto2" ? (
          <>
            <Row className="mb-5">
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl={12}>
                <TableLoadingSkeleton variant="simple" length={5} />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="mb-5">
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
              <Col xl={6}>
                <MapLoadingSkeleton heigth="30rem" />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl={12}>
                <TableLoadingSkeleton variant="simple" length={5} />
              </Col>
            </Row>
          </>
        )}
      </section>
    );

  return (
    <section>
      {appliedFilters.trapType.trapTypeName ===
      "armadilhaDisseminadoraInseticida" ? (
        <>
          <span
            className="h4 mb-4"
            style={{ textAlign: "center", display: "block" }}
          >
            Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
          </span>
          <Row className="mb-4">
            <Col xl={6}>
              <IPLGraphic
                estimatesData={estimatesGraphicsData}
                positivityIndex={positivityIndexData}
                situationsByTerritorializations={
                  situationsByTerritorializations
                }
              />
            </Col>
            <Col xl={6}>
              <PositivityIndexMap estimateObjectName="larvaOrPupaPointsEstimatesByWeek" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={6}>
              <IPEGraphic
                estimatesData={estimatesGraphicsData}
                positivityIndex={positivityIndexData}
                situationsByTerritorializations={
                  situationsByTerritorializations
                }
              />
            </Col>
            <Col xl={6}>
              <PositivityIndexMap estimateObjectName="exuviaPointsEstimatesByWeek" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={12}>
              <FieldResultsTable />
            </Col>
          </Row>
        </>
      ) : appliedFilters.trapType.trapTypeName === "armadilhaMosquitoAdulto" ||
        appliedFilters.trapType.trapTypeName === "armadilhaMosquitoAdulto2" ? (
        <>
          <span
            className="h4 mb-4"
            style={{ textAlign: "center", display: "block" }}
          >
            Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
          </span>
          <Row className="mb-4">
            <Col xl={6}>
              <IPLGraphic
                estimatesData={estimatesGraphicsData}
                positivityIndex={positivityIndexData}
                situationsByTerritorializations={
                  situationsByTerritorializations
                }
              />
            </Col>
            <Col xl={6}>
              <PositivityIndexMap estimateObjectName="larvaOrPupaPointsEstimatesByWeek" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={6}>
              <IPAGraphic
                estimatesData={estimatesGraphicsData}
                positivityIndex={positivityIndexData}
                situationsByTerritorializations={
                  situationsByTerritorializations
                }
              />
            </Col>
            <Col xl={6}>
              <PositivityIndexMap estimateObjectName="aedesAegyptiPointsEstimatesByWeek" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={12}>
              <FieldResultsTable />
            </Col>
          </Row>
        </>
      ) : appliedFilters.trapType.trapTypeName === "armadilhaOvos" ? (
        <>
          <span
            className="h4 mb-4"
            style={{ textAlign: "center", display: "block" }}
          >
            Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
          </span>
          <Row className="mb-4">
            <Col xl={6}>
              <IPOGraphic
                estimatesData={estimatesGraphicsData}
                positivityIndex={positivityIndexData}
                situationsByTerritorializations={
                  situationsByTerritorializations
                }
              />
            </Col>
            <Col xl={6}>
              <PositivityIndexMap estimateObjectName="eggPointsEstimatesByWeek" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={6}>
              <IPLGraphic
                estimatesData={estimatesGraphicsData}
                positivityIndex={positivityIndexData}
                situationsByTerritorializations={
                  situationsByTerritorializations
                }
              />
            </Col>
            <Col xl={6}>
              <PositivityIndexMap estimateObjectName="larvaOrPupaPointsEstimatesByWeek" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={6}>
              <IPEGraphic
                estimatesData={estimatesGraphicsData}
                positivityIndex={positivityIndexData}
                situationsByTerritorializations={
                  situationsByTerritorializations
                }
              />
            </Col>
            <Col xl={6}>
              <PositivityIndexMap estimateObjectName="exuviaPointsEstimatesByWeek" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xl={12}>
              <FieldResultsTable />
            </Col>
          </Row>
        </>
      ) : null}
    </section>
  );
};

export default FieldResultsPageGraphicsWrapper;
