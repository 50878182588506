import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import ReactTooltip from "react-tooltip";

const IdentificationResult = ({
    sampleIdentifications, 
    handleChangeIdentification,
    options, 
    isDisableOrganization,
    groupedInvertebratesOptions,
    groupedOptions,
    indexSample,
    QuantityDeadSoma,
    QuantityAliveSoma,
    QuantitySoma,
    removeIdentification,
    addIdentification
}) => {
    return (
        <Card style={{"marginTop": "2%", "marginBottom": "2%"}}>
            <CardHeader>
                <span className="h4"><i className='fa fa-microscope'></i> Identificação</span>
            </CardHeader>
            <CardBody>
                {sampleIdentifications.map((identification, indexIdentification) => {
                    return (                            
                    <div className='row' style={{'display' : identification.Show === false ? 'none' : '' }} >                              
                        <div className="col-3 responsive-form-field-result-full">
                            <span className="h5">Fase</span>
                            <Select
                                onChange={ (e) => handleChangeIdentification("Stage", e.value, indexIdentification, indexSample)} 
                                name="Stage" 
                                id=""                                    
                                value={options.filter(option => option.value == identification.Stage)}
                                options={isDisableOrganization?groupedInvertebratesOptions:groupedOptions} 
                            />
                        </div>
                        {identification.Stage==="Mosquito Adulto"?
                        <>
                        <div className="col-2 responsive-form-field-result-full">
                        <span className="h5">Sexo</span>
                        <select className="form-control" id="" onChange={(e) => handleChangeIdentification("Gender", e.target.value, indexIdentification, indexSample)}>
                            <option value="" selected={identification.Gender == ""}>Selecione</option>
                            <option value="Macho" selected={identification.Gender == "Macho"}>Macho</option>
                            <option value="Fêmea" selected={identification.Gender == "Fêmea"}>Fêmea</option>                                                                             
                        </select>
                        </div>

                        <div className="col-2 responsive-form-field-result-medium">
                            <span className="h5">Qtd Vivos</span>
                            <input className="form-control" value={identification.QuantityAlive} onChange={ (e) => handleChangeIdentification("QuantityAlive", e.target.value, indexIdentification,indexSample)}  type="number"  placeholder="0"/>
                        </div>

                        <div className="col-2 responsive-form-field-result-medium">
                        <span className="h5">Qtd Mortos</span>
                        <input className="form-control" value={identification.QuantityDead}  onChange={ (e) => handleChangeIdentification("QuantityDead", e.target.value, indexIdentification,indexSample)} type="number"  id=""placeholder="0"/>
                        </div> </>:""}
                        <div className="col-2 responsive-form-field-result-full">
                            <span className="h5">Qtd Total</span>
                            <input className="form-control" value={identification.Quantity} onChange={ (e) => handleChangeIdentification("Quantity", e.target.value, indexIdentification,indexSample)}  type="number" placeholder="0"/>
                        </div>
                        <div className="cfol-1 responsive-form-field-result-full">
                        <span className="h5">&nbsp;</span>
                        <button data-tip data-for="deleteResult" className="btn btn-warning responsive-button-result" onClick={() => removeIdentification(indexIdentification,indexSample)}><i className='fa fa-trash'></i></button>  
                        <ReactTooltip effect="solid" type="warning" id="deleteResult">
                            <span>Apagar resultado</span>
                        </ReactTooltip>
                        </div>                                
                    </div>                            
                    )
                })}
                {sampleIdentifications.filter(si => si.Show == true).length > 0 ?
                    
                    <>
                    <div className="row">
                        <div className="col-3 responsive-form-field-result-medium">
                            <span className="h3">Total</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 responsive-form-field-result-medium">
                            <span className="h5">Qtd Somada Vivos</span>
                            <input className="form-control" value={QuantityAliveSoma} disabled type="number" placeholder="0"/>
                        </div>

                        <div className="col-3 responsive-form-field-result-medium">
                        <span className="h5">Qtd Somada Mortos</span>
                        <input className="form-control" value={QuantityDeadSoma} disabled  type="number"  id=""placeholder="0"/>
                        </div>
                        <div className="col-3 responsive-form-field-result-full">
                            <span className="h5">Qtd Somada Total</span>
                            <input className="form-control" value={QuantitySoma} disabled  type="number" placeholder="0"/>
                        </div>   
                    </div>
                    </> 
                    :null}
                <div className="row mt-4 mb-4"> 
                    <div className={sampleIdentifications.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"} >
                        <button className="btn btn-info responsive-button-result" onClick={() => addIdentification(indexSample)}>+ Adicionar Identificação</button>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default IdentificationResult;