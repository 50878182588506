import React, { useEffect, useState } from "react";
import { withBasicListGraficMap } from "../../../../HOC/Entomologico/Map/BasicListGraficMap";
import { timeStampFormated } from "../../../../services/utils/format";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import CloropleticMap from "../../../../views/pages/Reports/CloropleticMap";
import {
  points as CreatePoints,
  polygon as CreatePolygon,
  pointsWithinPolygon,
} from "@turf/turf";
import moment from "moment";
import * as turf from "@turf/turf";

const PositivesMapContainer = ({
  buildGraficMapList,
  buildMapListSinan,
  data = undefined,
  labels = undefined,
  trapMapInfo,
  previousTrapMapInfo = undefined,
  territorializationList,
  territorializationFiltered,
  trapTypeName,
  dataGeoRef = null,
  dataGeoRefConfirmed = null,
}) => {
  const [DataSets, setDataSets] = useState("");
  const [pointsGroup, setPointsGroup] = useState(null);
  const [CloropleticPolygonGroups, setCloropleticPolygonGroups] =
    useState(null);
  const [HeatMapPoints, setHeatMapPoints] = useState(null);
  const [PointsHeatMap, setPointsHeatMap] = useState([]);
  const [lastIpo, setLastIpo] = useState();

  useEffect(() => {
    if (trapMapInfo && lastIpo != undefined && dataGeoRef) {
      checkData();
    }
  }, [dataGeoRef, trapMapInfo, territorializationList, previousTrapMapInfo, lastIpo]);

  useEffect(() => {
    if (data) {
      getDataSets(data, previousTrapMapInfo);
    }
  }, [data, previousTrapMapInfo]);

  const getDataSets = async (data, previousTrapMapInfo) => {
    let labelDayDataSet = "";
    let lastIpo = 0;
  
    for (const index in labels) {
      let lastIpoByTerritorialization = 0;
      for (const ano in data) {
        for (const territorialization in data[ano][index]) {
          const dataInformation = data[ano][index][territorialization];
          if (dataInformation) {
            const { positive: positives, examinated } = dataInformation;
            const ipo = examinated !== 0 ? (positives / examinated) * 100 : 0;
            lastIpoByTerritorialization += ipo;
          }
        }
      }
  
      lastIpo = lastIpoByTerritorialization;
      labelDayDataSet = labels[index];
  
      if (!lastIpo && previousTrapMapInfo && index != 0) {
        labelDayDataSet = labels[index - 1];
      }
    }

    setLastIpo(lastIpo);
    setDataSets(labelDayDataSet);
  
    return null;
  };

  const checkData = async () => {
    var entomologicPoints = await checkEntomologicData();
    var sinanPoints = await checkDataSinan();


    setPointsGroup([...entomologicPoints, ...sinanPoints])
  }

  const checkDataSinan = async () => {
    var pointsGroupAux = [];

    await buildMapListSinan(dataGeoRef, "Notificações").then((result) => {
      pointsGroupAux.push(result);
    });

    await buildMapListSinan(dataGeoRefConfirmed, "Confirmados").then((result) => {
      pointsGroupAux.push(result);
    });

    return pointsGroupAux;
  };

  const checkEntomologicData = async () => {
    const pointsGroupped = new Array();

    if (lastIpo == 0 && previousTrapMapInfo != undefined) {
      trapMapInfo.positives = previousTrapMapInfo.positives;
      trapMapInfo.actives = previousTrapMapInfo.actives;
      trapMapInfo.negatives = previousTrapMapInfo.negatives;
    }

    if (trapMapInfo && trapMapInfo.actives !== undefined) {
      const activeList = await buildGraficMapList(
        trapMapInfo.actives,
        "Armadilhas instaladas",
        "black"
      );
      pointsGroupped.push(activeList);
    }

    if (trapMapInfo && trapMapInfo.positives !== undefined) {
      const positiveList = await buildGraficMapList(
        trapMapInfo.positives,
        "Armadilhas positivas",
        "red"
      );
      if (positiveList.points.length > 0) {
        setHeatMapPoints(positiveList.points);
        setPointsHeatMap(positiveList.points);
      }

      pointsGroupped.push(positiveList);
    }

    if (trapMapInfo && trapMapInfo.negatives !== undefined) {
      const negativeList = await buildGraficMapList(
        trapMapInfo.negatives,
        "Armadilhas negativas",
        "green"
      );
      pointsGroupped.push(negativeList);
    }

    buildCloropleticInfo();
    return pointsGroupped;
  };

  const buildCloropleticInfo = () => {
    range = [19.99, 59.99];

    const cloropleticGroups = new Array();
    const allPositivesTraps = CreatePoints(
      trapMapInfo.positives.map(({ latitude, longitude }) => [
        longitude,
        latitude,
      ])
    );
    const allExaminatedTraps = CreatePoints(
      trapMapInfo.inspected.map(({ latitude, longitude }) => [
        longitude,
        latitude,
      ])
    );

    for (const group of territorializationList) {
      let list = [...group.territorializationList];
      if (territorializationFiltered.length > 0) {
        list = list.filter((territorialization) =>
          territorializationFiltered.some(
            ({ value }) => territorialization.id === value
          )
        );
      }

      const cloropleticGroup = {
        name: group.name,
        Polygons: new Array(),
      };

      let legends = [];

      for (const territorialization of list) {
        if (territorialization.geometryType == "POLYGON") {
          const turfPolygon = CreatePolygon([
            territorialization.coordinates.map(({ x, y }) => [x, y]),
          ]);
          const filteredExaminatedTraps = pointsWithinPolygon(
            allExaminatedTraps,
            turfPolygon
          ).features.length;

          if (filteredExaminatedTraps > 0) {
            const filteredPositivesTraps = pointsWithinPolygon(
              allPositivesTraps,
              turfPolygon
            ).features.length;
            const ipo =
              (filteredPositivesTraps / filteredExaminatedTraps) * 100;

            var range = [];

            switch (trapTypeName) {
              case "Ovitrampa":
                range = [19.99, 59.99];
                break;

              case "Adultrap":
                range = [9.99, 19.99];
                break;

              default:
                range = [19.99, 59.99];
                break;
            }

            if (ipo <= range[0]) {
              turfPolygon.color = "green";
            } else if (ipo <= range[1]) {
              turfPolygon.color = "yellow";
            } else {
              turfPolygon.color = "red";
            }
          } else {
            turfPolygon.color = "transparent";
          }
          cloropleticGroup.Polygons.push(turfPolygon);
        }
      }

      legends.push({
        firstItem: 4,
        lastItem: null,
        color: "red",
        text: range[1] + "+",
      });

      legends.push({
        firstItem: 1,
        lastItem: 3.99,
        color: "yellow",
        text: range[0] + " - " + range[1],
      });

      legends.push({
        firstItem: 0.0,
        lastItem: 0.99,
        color: "green",
        text: `0 - ` + range[0],
      });

      cloropleticGroup.legends = legends;

      cloropleticGroups.push(cloropleticGroup);
    }

    setCloropleticPolygonGroups(cloropleticGroups);
  };

  return (
    <div>
      <CloropleticMap
        PointsGroup={pointsGroup}
        PolygonsGroup={CloropleticPolygonGroups}
        HeatMap
        QuantityLegend={
          <div>
            Exibindo Dados da <br />
            Semana{" "}
            {typeof DataSets === "string"
              ? DataSets.replace("Semana", "")
              : DataSets.toString().replace("Semana", "")}
          </div>
        }
        showCloropletic
        PointsHeatMap={HeatMapPoints}
      />
    </div>
  );
};

export default withBasicListGraficMap(PositivesMapContainer);