import request from '../utils/request';
// import request from '../utils/requestTests/requestTestSample';
import { getUserData } from "../../services/utils/auth";
import moment from 'moment';
import { postContaOvos } from './ContaOvos';

export function fetchSamples(filter) {
   return request({
     url: '/Samples/GetAllSamples',
     method: 'post',
     data: filter,
  });
}
export function fetchSamplesByNumber(sampleNumber) {
  var OrganizationId = getUserData('organizationId');
  let sampleData ={
    number: sampleNumber,
    organizationId: OrganizationId,
  }
   return request({
     url: '/Samples/GetSampleByNumber',
     method: 'post',
     data: sampleData,
  });
}

export function fetchAllSamples(sampleNumber, sampleId = null) {
  var OrganizationId = getUserData('organizationId');

  let sampleData ={
    id: sampleId ? sampleId : "00000000-0000-0000-0000-000000000000",
    number: sampleNumber,
    organizationId: OrganizationId,
  }

  return request({
     url: '/Samples/GetAllAliquotSamples',
     method: 'post',
     data: sampleData,
  });
}

export function fetchSamplesAnalysis(filter) {
  return request({
    url: '/Samples/GetAnalysis',
    method: 'post',
    data: filter,
 });
}

export function fetchSamplesExcelData(filter) {
  return request({
    url: '/Samples/GetSampleExcelData',
    method: 'post',
    data: filter,
 });
}

export function postSample(SampleData) {

  let SampleSend = {
      number: SampleData.Number,
      typeMaterial: SampleData.TypeMaterial,
      demandId: SampleData.DemandId,
      OrganizationId: SampleData.OrganizationId,
      SampleExtraInformations: SampleData.SampleExtraInformations,
      VisitId: SampleData.VisitId,
      DateCollected: moment().format("YYYY-MM-DD"),
      CollectorId: SampleData.CollectorId
    };

  return request({
      url: '/Samples',
      method: 'post',
      data: SampleSend,
  });
}

export function postSampleFromForm(sampleData){
  return request({
    url: '/Samples',
    method: 'post',
    data: sampleData,
  });
}

export function postSampleGroups(sampleGroupData){
  if(sampleGroupData && sampleGroupData.length === 1 && sampleGroupData[0].typeMaterial === "Palhetas") {
    postContaOvos(sampleGroupData[0])
  }

  return request({
    url: '/Samples/PostSampleGroups',
    method: 'post',
    data: sampleGroupData,
  });
}

//pega os dados de uma amostra em específico
export function fetchSample(id) {
  return request({
    url: '/Samples/GetWithId/'+id,
    method: 'get',
  
  });
}
//pega os dados de uma amostra em específico
export function discardSample(id) {
  return request({
    url: '/Samples/DiscardSample/'+id,
    method: 'get'
  });
}

export function changeSample(sampleData){
  return request({
    url: '/Samples',
    method: 'put',
    data:sampleData
  })
}
