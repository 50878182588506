import { toast } from "react-toastify";
import { ARMADILHA_INFO } from "../../constants/ArmadilhaConstant";
import { getTrapsTypes } from "../api/Trap";
import {
  fetchTerritorializations,
  getAllTypeTerritorializations,
} from "../api/territorialization";
import { fetchDemands } from "../api/Demand";
import { getUserEmployeeDataByOrganization } from "../api/Users";
import { getTeam } from "../api/Team";
import { daysDifferenceBetweenTodayAndDate } from "./TrapStatus/trapStatus";
import { getTrapRange } from "../../constants/RangeTrapConstant";
import moment from "moment";
import { getProjects } from "../api/project";
import { getUserData } from "./auth";
import { ORGANIZATIONS_IDS_TO_CONSIDER_LAST_READ_HISTORY_LAT_LONG_ON_MAPS } from "../../views/pages/Traps/trapsPageConstants";
import { getActualEpidemiologicalWeek } from "./todayEpidemiologicalWeek";
import getWeeks from "./epidemiologicalWeek";
import { fetchConfigurations, insertConfigurations } from "../api/Organization";
const L = require("leaflet");

const ACTIVE_TRAP_STATUS = 1;
const INACTIVE_TRAP_STATUS = 2;

const GREEN_TRAP_STATUS = 1;
const YELLOW_TRAP_STATUS = 2;
const RED_TRAP_STATUS = 3;

const GREEN_TRAP_COLOR = "#76ea79";
const YELLOW_TRAP_COLOR = "#fff200";
const RED_TRAP_COLOR = "#ff0000";

const trapsStatusesOptionsList = [
  {
    label: "Instaladas",
    value: ACTIVE_TRAP_STATUS,
  },
  {
    label: "Desinstaladas",
    value: INACTIVE_TRAP_STATUS,
  },
];

const trapsColorStatusesOptionsList = [
  {
    label: "Verde",
    value: GREEN_TRAP_STATUS,
  },
  {
    label: "Amarelo",
    value: YELLOW_TRAP_STATUS,
  },
  {
    label: "Vermelho",
    value: RED_TRAP_STATUS,
  },
];

const trapColorStatusByName = {
  1: "green",
  2: "yellow",
  3: "red",
  4: "black",
};

const trapBackgroundColorByStatusNumber = {
  1: GREEN_TRAP_COLOR,
  2: YELLOW_TRAP_COLOR,
  3: RED_TRAP_COLOR,
};

const fetchTrapTypesData = async (setTrapTypesList) => {
  try {
    const { data, status } = await getTrapsTypes();

    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao buscar pelos dados de tipos de armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
      );

    const formatedTrapTypesOptions = data
      .map(({ id, name }) => ({
        label: ARMADILHA_INFO[name].apelido,
        trapTypeName: name,
        value: id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    if (setTrapTypesList) setTrapTypesList(formatedTrapTypesOptions);

    return formatedTrapTypesOptions;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }
};

const createDefaultConfigurations = async (organizationId) => {
  const DEFAULT_CONFIGURATIONS = [
    //SINAN
    {
      organizationId,
      moduleId: "85f27883-ca03-4e2a-b97e-6b2f89b555fc",
      name: "yearsToNotConsiderOnControlDiagram",
      value: JSON.stringify([]),
    },
    {
      organizationId,
      moduleId: "aa7842ab-f17b-4b77-a50a-1615f68c2646",
      name: "trapPositionOnMapShouldConsider",
      value: "lastInstallHistory",
    },
  ];

  try {
    const { data, status } = await insertConfigurations(DEFAULT_CONFIGURATIONS);

    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao criar as configurações bases. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
      );

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }
};

const fetchConfigurationsData = async (setConfigurations) => {
  try {
    const organizationId = getUserData("organizationId");

    const { data, status } = await fetchConfigurations(organizationId);

    let configurations = data;

    if (status === 204) {
      const defaultConfigurations = await createDefaultConfigurations(
        organizationId
      );

      configurations = defaultConfigurations;
    }

    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao buscar pelos dados de configurações. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
      );

    if (setConfigurations) setConfigurations(configurations);

    return configurations;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }
};

const fetchTerritorializationsData = async (
  setTerritorializationsList,
  setTypeLayersList
) => {
  try {
    const { data: typeTerritorializationsData, status } =
      await getAllTypeTerritorializations();
    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao buscar pelos dados de territorializações. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
      );

    const territorializations = [];

    for (const typeTerritorialization of typeTerritorializationsData) {
      if (typeTerritorialization.typeGeometry === "Polygon") {
        const { data } = await fetchTerritorializations(
          typeTerritorialization.id
        );

        territorializations.push({
          id: typeTerritorialization.id,
          name: typeTerritorialization.name,
          label: typeTerritorialization.name,
          territorializations: data,
          options: data.map((territorialization) => {
            return {
              label: territorialization.name,
              value: territorialization.id,
              typeLayerId: typeTerritorialization.id,
            };
          }),
        });
      }
    }

    const typeLayersList = territorializations.map(
      ({ label, name, territorializations, id }) => {
        const formatedTerritorializations = territorializations.map(
          ({ id, name }) => ({ label: name, value: id })
        );

        return {
          label,
          name,
          value: name,
          id,
          territorializations: formatedTerritorializations,
        };
      }
    );

    setTerritorializationsList(territorializations);
    if (setTypeLayersList) setTypeLayersList(typeLayersList);

    return { territorializations, typeLayersList };
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }
};

const fetchDemandsData = async (setDemandsList) => {
  try {
    const { data, status } = await fetchDemands();
    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao buscar pelos dados de demandas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
      );

    const formatedDemands = data.map(({ id, serviceOrder }) => {
      return {
        label: serviceOrder,
        value: id,
      };
    });

    setDemandsList(formatedDemands);
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }
};

const fetchEmployeesData = async (setEmployeesList) => {
  try {
    const { data, status } = await getUserEmployeeDataByOrganization();
    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao buscar pela lista de funcionários. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
      );

    const formatedEmployees = data
      .map(({ userId, employeeId, employeeName }) => {
        return {
          employeeId,
          employeeName,
          userId,
          label: employeeName,
          value: userId,
        };
      })
      .sort((a, b) => a.employeeName.localeCompare(b.employeeName));

    setEmployeesList(formatedEmployees);

    return formatedEmployees;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error);
      toast.error(error.message);
    }
  }
};

const fetchTeamsData = async (employeesData, setTeamsList) => {
  try {
    const { data, status } = await getTeam();
    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao buscar pela lista de equipes. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
      );

    const formatedTeams = data
      .map(({ id, members, name }) => {
        const formatedTeamMembers = members.map((member) => {
          const userData = employeesData.find(
            (employee) => employee.employeeId === member.employeeId
          );

          if (!userData)
            return {
              employeeId: "00000000-0000-0000-0000-000000000000",
              employeeName: "Usuário não encontrado",
              label: "Usuário não encontrado",
              userId: "00000000-0000-0000-0000-000000000000",
              uvis: null,
              value: "00000000-0000-0000-0000-000000000000",
            };

          return {
            ...member,
            userId: userData.userId
              ? userData.userId
              : "00000000-0000-0000-0000-000000000000",
            employeeId: userData.employeeId,
          };
        });

        return {
          value: id,
          label: name,
          members: formatedTeamMembers,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    setTeamsList(formatedTeams);

    return formatedTeams;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error);
      toast.error(error.message);
    }
  }
};

const fetchEmployeesAndTeamsData = async (setEmployeesList, setTeamsList) => {
  const employeesData = await fetchEmployeesData(setEmployeesList);
  await fetchTeamsData(employeesData, setTeamsList);
};

const fetchProjectsData = async (setProjectsList) => {
  try {
    const { data, status } = await getProjects();

    if (status !== 200)
      throw new Error(
        "Ocorreu um erro ao buscar pela lista de projetos. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
      );

    const formatedProjects = data.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });

    setProjectsList(formatedProjects);
  } catch (error) {
    if (error instanceof Error) {
      console.error(error);
      toast.error(error.message);
    }
  }
};

const getTrapSituationColor = (
  trapType,
  installDate,
  lastReadDate,
  trapWasRemoved
) => {
  if (trapWasRemoved) return "white";

  let daysDifference;

  const daysDifferenceBetweenTodayAndInstallDate =
    daysDifferenceBetweenTodayAndDate(installDate);
  const daysDifferenceBetweenTodayAndLastReadDate =
    daysDifferenceBetweenTodayAndDate(lastReadDate);

  if (daysDifferenceBetweenTodayAndLastReadDate === null)
    daysDifference = daysDifferenceBetweenTodayAndInstallDate;
  else
    daysDifference = Math.min(
      daysDifferenceBetweenTodayAndInstallDate,
      daysDifferenceBetweenTodayAndLastReadDate
    );

  const trapTypeRange = getTrapRange(trapType);

  let trapStatusColor = "white";

  if (trapTypeRange.quantityRange === 2) {
    if (daysDifference <= trapTypeRange.data.end) trapStatusColor = "green";
    else trapStatusColor = "red";
  }

  if (trapTypeRange.quantityRange === 3) {
    if (daysDifference <= trapTypeRange.data.middle) trapStatusColor = "green";
    else if (daysDifference <= trapTypeRange.data.end)
      trapStatusColor = "yellow";
    else trapStatusColor = "red";
  }

  return trapStatusColor;
};

const generateTrapLatitudeAndLongitude = (
  installHistory,
  readHistory,
  considerLastReadDate = false
) => {
  if (!considerLastReadDate)
    return {
      latitude: installHistory.latitude,
      longitude: installHistory.longitude,
      reference: "installation",
    };

  if (!readHistory)
    return {
      latitude: installHistory.latitude,
      longitude: installHistory.longitude,
      reference: "installation",
    };

  if (moment(installHistory) > moment(readHistory))
    return {
      latitude: installHistory.latitude,
      longitude: installHistory.longitude,
      reference: "installation",
    };

  return {
    latitude: readHistory.latitude,
    longitude: readHistory.longitude,
    reference: "maintenance",
  };
};

const generateTrapGroupsPointsList = (trapTypesList, activeTrapsList) => {
  if (
    !activeTrapsList ||
    activeTrapsList.length === 0 ||
    !trapTypesList ||
    trapTypesList.length === 0
  )
    return [];

  const ACTIVE_TRAP_STATUS = 1;
  const ORGANIZATION_ID = getUserData("organizationId");
  const CONSIDER_LAST_READ_DATE =
    ORGANIZATIONS_IDS_TO_CONSIDER_LAST_READ_HISTORY_LAT_LONG_ON_MAPS.includes(
      ORGANIZATION_ID
    );

  const result = [];

  trapTypesList.forEach((trapType) => {
    const trapsPoints = activeTrapsList
      .filter(
        (trap) =>
          trap.trapType.name === trapType.trapTypeName &&
          trap.status === ACTIVE_TRAP_STATUS
      )
      .map(
        ({
          address,
          colorStatus,
          id,
          lastInstallHistoryByStatusDate,
          lastReadHistoryByStatusDate,
          number,
          trapType,
          daysSinceLastActivity,
        }) => {
          const { date: lastInstallDate } = lastInstallHistoryByStatusDate;

          const { name } = trapType;

          const trapColorStatus = trapColorStatusByName[colorStatus];

          const { latitude, longitude } = generateTrapLatitudeAndLongitude(
            lastInstallHistoryByStatusDate,
            lastReadHistoryByStatusDate,
            CONSIDER_LAST_READ_DATE
          );

          return {
            address,
            lastInsallUsername:
              !lastInstallHistoryByStatusDate ||
              !lastInstallHistoryByStatusDate.user
                ? null
                : lastInstallHistoryByStatusDate.user.name,
            lastInstallDate,
            statusColor: trapColorStatus,
            colorStatusCode: colorStatus,
            icon: L.icon({
              iconUrl:
                ARMADILHA_INFO[name].icons[
                  !trapColorStatus || trapColorStatus === ""
                    ? "black"
                    : trapColorStatus
                ],
              iconSize: [16, 16],
            }),
            id,
            lastReadUsername:
              !lastReadHistoryByStatusDate || !lastReadHistoryByStatusDate.user
                ? null
                : lastReadHistoryByStatusDate.user.name,
            lastReadDate:
              !lastReadHistoryByStatusDate || !lastReadHistoryByStatusDate.date
                ? null
                : lastReadHistoryByStatusDate.date,
            latitude,
            longitude,
            number,
            daysSinceLastActivity,
          };
        }
      );

    const groupPoints = {
      name: ARMADILHA_INFO[trapType.trapTypeName].apelido,
      trapTypeName: trapType.trapTypeName,
      points: trapsPoints,
    };

    result.push(groupPoints);
  });

  return result;
};

const generateHeatTrapGroupsPointsList = (trapTypesList, activeTrapsList) => {
  if (
    !activeTrapsList ||
    activeTrapsList.length === 0 ||
    !trapTypesList ||
    trapTypesList.length === 0
  )
    return [];

  const result = [];

  const gradient = { 0.4: "#7E349A", 0.8: "#8C4FA6", 1.0: "#A76BCD" };
  const intensity = 1;

  trapTypesList.forEach((trapType) => {
    const ACTIVE_TRAP_STATUS = 1;

    const trapsPoints = activeTrapsList
      .filter(
        (trap) =>
          trap.trapType.name === trapType.trapTypeName &&
          trap.status === ACTIVE_TRAP_STATUS
      )
      .map(({ lastInstallHistoryByStatusDate }) => {
        const { latitude, longitude } = lastInstallHistoryByStatusDate;

        return {
          latitude,
          longitude,
          intensity,
        };
      });

    const groupPoints = {
      name: ARMADILHA_INFO[trapType.trapTypeName].apelido,
      trapTypeName: trapType.trapTypeName,
      points: trapsPoints,
      gradient,
    };

    result.push(groupPoints);
  });

  return result;
};

const generateCasesHeatGroupsPointsList = (trapTypesList, activeTrapsList) => {
  if (
    !activeTrapsList ||
    activeTrapsList.length === 0 ||
    !trapTypesList ||
    trapTypesList.length === 0
  )
    return [];

  const result = [];

  const gradient = { 0.4: "#7E349A", 0.8: "#8C4FA6", 1.0: "#A76BCD" };
  const intensity = 1;

  trapTypesList.forEach((trapType) => {
    const ACTIVE_TRAP_STATUS = 1;

    const trapsPoints = activeTrapsList
      .filter(
        (trap) =>
          trap.trapType.name === trapType.trapTypeName &&
          trap.status === ACTIVE_TRAP_STATUS
      )
      .map(({ lastInstallHistoryByStatusDate }) => {
        const { latitude, longitude } = lastInstallHistoryByStatusDate;

        return {
          latitude,
          longitude,
          intensity,
        };
      });

    const groupPoints = {
      name: ARMADILHA_INFO[trapType.trapTypeName].apelido,
      trapTypeName: trapType.trapTypeName,
      points: trapsPoints,
      gradient,
    };

    result.push(groupPoints);
  });

  return result;
};

const getTrapColorSituationByDaysSinceLastActivity = (
  trapTypeName,
  daysSinceLastActivity
) => {
  const trapTypeRange = getTrapRange(trapTypeName);

  if (trapTypeRange.quantityRange === 2) {
    if (daysSinceLastActivity <= trapTypeRange.data.end) return "green";

    return "red";
  }

  if (trapTypeRange.quantityRange === 3) {
    if (daysSinceLastActivity <= trapTypeRange.data.middle) return "green";
    if (daysSinceLastActivity <= trapTypeRange.data.end) return "yellow";

    return "red";
  }
};

const getPeriodsToSendOnRequest = (filters) => {
  return {
    beginDate: moment
      .utc(filters.beginDate)
      .set({ hour: 0, minute: 0, second: 0 })
      .toISOString(),
    finalDate: moment
      .utc(filters.finalDate)
      .set({ hour: 23, minute: 59, second: 59 })
      .toISOString(),
  };
};

const getEpidemiologicWeeksFromTheStartOfTheYearToToday = () => {
  const startOfTheYear = moment().startOf("year");
  const today = moment();

  const acutalYearEpidemiologicWeeks = getWeeks().find(
    ({ year }) => startOfTheYear.year() === +year
  );

  if (!acutalYearEpidemiologicWeeks) return [];

  const { weeks } = acutalYearEpidemiologicWeeks;

  const epidemiologicWeeksFromPeriod = weeks.filter(
    ({ beginDate, endDate }) => {
      const beginDateFormated = moment(beginDate, "DD/MM/YYYY");
      const endDateFormated = moment(endDate, "DD/MM/YYYY");

      return beginDateFormated <= today || endDateFormated <= today;
    }
  );

  return epidemiologicWeeksFromPeriod;
};

const getUsersIdsToSendOnRequest = (filters) => {
  const teamsUsersIds = getTeamUsersIdsToSendOnRequest(filters);
  const employeesUsersIds = filters.employees.map(({ value }) => value);

  return [...employeesUsersIds, ...teamsUsersIds];
};

const getTeamUsersIdsToSendOnRequest = (filters) => {
  if (filters.team.value === "" || filters.team.members.length === 0) return [];

  const usersIds = [];

  filters.team.members.forEach(({ userId }) => {
    if (
      userId &&
      userId !== "" &&
      userId !== "00000000-0000-0000-0000-000000000000"
    )
      usersIds.push(userId);
  });

  return usersIds;
};

const getTerritorializationsIdsToSendOnRequest = (filters) => {
  return filters.territorializations.length === 0
    ? []
    : filters.territorializations.map(({ value }) => value);
};

const getThreeLastEpidemiologicWeeksFromActualYear = () => {
  const epidemiologicWeeksFromActualYear = getWeeks().find(
    ({ year }) => year === String(moment().year())
  ).weeks;

  const actualEpidemiologicWeek = getActualEpidemiologicalWeek();

  if (actualEpidemiologicWeek.weeksArrayIndex < 3)
    return epidemiologicWeeksFromActualYear.slice(0, 3);

  return epidemiologicWeeksFromActualYear.slice(
    actualEpidemiologicWeek.weeksArrayIndex - 3,
    actualEpidemiologicWeek.weeksArrayIndex
  );
};

const monthsOptionsList = [
  { value: 1, label: "Janeiro" },
  { value: 2, label: "Fevereiro" },
  { value: 3, label: "Março" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Maio" },
  { value: 6, label: "Junho" },
  { value: 7, label: "Julho" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Setembro" },
  { value: 10, label: "Outubro" },
  { value: 11, label: "Novembro" },
  { value: 12, label: "Dezembro" },
];

const selectComponentStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: "2.90rem",
    borderRadius: "0.370rem",
  }),
  option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: "0.875rem",
    color: "#8898aa",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: "0.875rem",
    color: "#8898aa",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "0.875rem",
    color: "#8898aa",
  }),
  menuPortal: (styles) => ({ ...styles, zIndex: 99999 }),
};

const getColorByIndex = (index) => {
  const colors = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#865838",
    "#6d6966",
    "#8a8703",
    "#f700ff",
    "#4400ff",
    "#064f58",
    "#212525",
    "#9CF071",
    "#301D14",
    "#13CC37",
    "#D50563",
    "#E10522",
    "#0EEB05",
    "#E8F023",
    "#4C82D8",
    "#36FEA1",
    "#FBF31D",
    "#69CB66",
    "#E690B6",
    "#A6F0D7",
    "#A36B13",
    "#1D0AEF",
    "#7D5567",
    "#0D9224",
    "#82DA12",
    "#3E6AEC",
    "#06B79C",
    "#4C465E",
    "#5D04B1",
    "#BC6F7F",
    "#5D535F",
    "#25822A",
    "#D07F05",
    "#E5598D",
    "#AB6B0C",
    "#227479",
    "#339224",
    "#8FA4A4",
    "#B052E3",
    "#B573E8",
    "#51DA47",
    "#45E717",
  ];

  return colors[index % colors.length];
};

export {
  GREEN_TRAP_STATUS,
  YELLOW_TRAP_STATUS,
  RED_TRAP_STATUS,
  ACTIVE_TRAP_STATUS,
  INACTIVE_TRAP_STATUS,
  GREEN_TRAP_COLOR,
  YELLOW_TRAP_COLOR,
  RED_TRAP_COLOR,
  trapBackgroundColorByStatusNumber,
  monthsOptionsList,
  fetchConfigurationsData,
  getThreeLastEpidemiologicWeeksFromActualYear,
  fetchDemandsData,
  fetchEmployeesAndTeamsData,
  fetchEmployeesData,
  fetchProjectsData,
  fetchTeamsData,
  fetchTerritorializationsData,
  fetchTrapTypesData,
  generateTrapGroupsPointsList,
  generateTrapLatitudeAndLongitude,
  getPeriodsToSendOnRequest,
  getUsersIdsToSendOnRequest,
  getTeamUsersIdsToSendOnRequest,
  getTerritorializationsIdsToSendOnRequest,
  getTrapSituationColor,
  getTrapColorSituationByDaysSinceLastActivity,
  selectComponentStyles,
  trapColorStatusByName,
  trapsStatusesOptionsList,
  trapsColorStatusesOptionsList,
  getEpidemiologicWeeksFromTheStartOfTheYearToToday,
  getColorByIndex,
  generateHeatTrapGroupsPointsList,
  generateCasesHeatGroupsPointsList,
};
