import React from "react";
import { createContext } from "use-context-selector";
import useAppliedFilters from "../Hooks/useAppliedFilters";

const PositivityIndexMapContext = createContext(null);

const PositivityIndexMapContextProvider = ({ children }) => {
  const [positivityIndexGroupPointsList, setPositivityIndexGroupPointsList] =
    React.useState({});

  return (
    <PositivityIndexMapContext.Provider
      value={{
        positivityIndexGroupPointsList,
        setPositivityIndexGroupPointsList,
      }}
    >
      {children}
    </PositivityIndexMapContext.Provider>
  );
};

export { PositivityIndexMapContext, PositivityIndexMapContextProvider };
