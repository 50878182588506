import React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import { Map } from "../../../../components/Maps/Map";
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import moment from "moment";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { polygon } from "@turf/turf";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import { trapColorStatusByName } from "../../../../services/utils/globalFunctions";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";

const { BaseLayer, Overlay } = LayersControl;
const L = require("leaflet");

const useTrapsByAreaMap = () => {
  const [isClustured, setIsClustured] = React.useState(true);
  const { territorializationsList } = useTerritorializationsList();

  return { territorializationsList, isClustured, setIsClustured };
};

export const TrapsByAreaMap = ({ data }) => {
  const { territorializationsList, setIsClustured, isClustured } =
    useTrapsByAreaMap();

  return (
    <Card className="mb-4">
      <CardHeader
        headerText={<span>Mapa de Armadilhas</span>}
        showExpandButton={false}
        showDownloadDataButton={false}
        showDownloadImageButton={false}
      />
      <CardContent>
        <Map
          style={{ height: "35rem" }}
          oncontextmenu={() =>
            setIsClustured((previousValue) => !previousValue)
          }
        >
          <MapPolygonsLayersControl data={territorializationsList} />
          <MapPointsLayersControl data={data} isClustured={isClustured} />
        </Map>
      </CardContent>
      <CardFooter>
        <div>
          <i>
            <img
              src={require("../../../../assets/img/icons/interface/triangle-warning.png")}
              style={{ width: "13px", height: "13px" }}
              alt="icone"
            />
          </i>{" "}
          <span style={{ fontSize: "12px" }}>
            Clique com o botão direito do mouse sobre o mapa para desagrupar os
            pontos de armadilhas
          </span>
        </div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  );
};

const MapPointsLayersControl = ({ data, isClustured }) => {
  const [groupsPointsList, setGroupPointsList] = React.useState([]);

  React.useEffect(() => {
    if (!data) return;

    const groupsPoints = [
      {
        name: "Até 30 dias",
        points: generateGroupPoints(data.upToThirtyDaysTraps),
      },
      {
        name: "Entre 31 a 60 dias",
        points: generateGroupPoints(data.fromThirtyOneToSixtyDaysTraps),
      },
      {
        name: "Acima de 60 dias",
        points: generateGroupPoints(data.overSixtyDaysTraps),
      },
    ];

    setGroupPointsList(groupsPoints);
  }, [data]);

  const generateGroupPoints = (groupPoints) => {
    return groupPoints.map((trap) => {
      return {
        address: trap.address,
        lastInstallDate: trap.lastInstallHistoryByStatusDate.date,
        statusColor: trapColorStatusByName[trap.colorStatus],
        colorStatusCode: trap.colorStatus,
        icon: L.icon({
          iconUrl:
            ARMADILHA_INFO[trap.trapType.name].icons[
              !trapColorStatusByName[trap.colorStatus] ||
              trapColorStatusByName[trap.colorStatus] === ""
                ? "black"
                : trapColorStatusByName[trap.colorStatus]
            ],
          iconSize: [16, 16],
        }),
        id: trap.id,
        lastReadDate:
          !trap.lastReadHistoryByStatusDate ||
          !trap.lastReadHistoryByStatusDate.date
            ? null
            : trap.lastReadHistoryByStatusDate.date,
        latitude: trap.lastInstallHistoryByStatusDate.latitude,
        longitude: trap.lastInstallHistoryByStatusDate.longitude,
        number: trap.number,
      };
    });
  };

  return (
    <LayersControl position="topright">
      <BaseLayer checked={true} name={"Armadilhas ativas"}>
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {groupsPointsList.map(({ name, points }) => {
        return (
          <Overlay
            key={`${name}-trapByAreas-overlay-${Date.now()}`}
            name={name}
            checked={true}
          >
            {isClustured ? (
              <MarkerClusterGroup
                key={`${name}-trapByAreas-marker-cluster-group-${Date.now()}`}
                removeOutsideVisibleBounds={true}
              >
                {points &&
                  points.length > 0 &&
                  points.map(
                    ({
                      id,
                      address,
                      lastInstallDate,
                      lastReadDate,
                      latitude,
                      longitude,
                      number,
                      icon,
                    }) => {
                      return (
                        <Marker
                          key={id}
                          position={[latitude, longitude]}
                          icon={icon}
                        >
                          <TrapPopup
                            address={address}
                            lastInstallDate={lastInstallDate}
                            id={id}
                            lastReadDate={lastReadDate}
                            number={number}
                            latitude={latitude}
                            longitude={longitude}
                          />
                        </Marker>
                      );
                    }
                  )}
              </MarkerClusterGroup>
            ) : (
              <MarkerClusterGroup
                key={`${name}-trapByAreas-noClusters-marker-cluster-group-${Date.now()}`}
                removeOutsideVisibleBounds={true}
                disableClusteringAtZoom={1}
              >
                {points &&
                  points.length > 0 &&
                  points.map(
                    ({
                      id,
                      address,
                      lastInstallDate,
                      lastReadDate,
                      latitude,
                      longitude,
                      number,
                      icon,
                    }) => {
                      return (
                        <Marker
                          key={id}
                          position={[latitude, longitude]}
                          icon={icon}
                        >
                          <TrapPopup
                            address={address}
                            lastInstallDate={lastInstallDate}
                            id={id}
                            lastReadDate={lastReadDate}
                            number={number}
                            latitude={latitude}
                            longitude={longitude}
                          />
                        </Marker>
                      );
                    }
                  )}
              </MarkerClusterGroup>
            )}
          </Overlay>
        );
      })}
    </LayersControl>
  );
};

const MapPolygonsLayersControl = ({ data, isClustured }) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const typeLayersPolygonsGroup = [];

    data.forEach(({ name, territorializations, id }) => {
      const typeLayerData = {
        id,
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;
        turfPolygon.territorializationId = territorialization.id;
        turfPolygon.typeLayerId = id;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [data]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked name={"Delimitador de Áreas"}>
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons, id }, index) => {
          return (
            <Overlay name={name} key={`${name}-overlay-${index}`}>
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-feature-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map((polygon, index) => {
                    const { geometry, name } = polygon;

                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={`${name}-polygon-${index}`}
                        color={"#000000"}
                        fillColor={"transparent"}
                        opacity={1}
                        positions={coordinates}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const TrapPopup = ({ id, number, address, lastInstallDate, lastReadDate }) => {
  return (
    <Popup key={`popup-${id}`}>
      <span>
        <span style={{ fontWeight: "bold" }}>Armadilha Nº: </span>
        {number}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Endereço: </span>
        {address}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última instalação: </span>
        {moment(lastInstallDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última manutenção: </span>
        {!lastReadDate || lastReadDate === ""
          ? "-"
          : moment(lastReadDate).format("DD/MM/YYYY HH:mm")}
      </span>
    </Popup>
  );
};
