import React from "react";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import {
  ACTIVE_TRAP_STATUS,
  generateTrapLatitudeAndLongitude,
} from "../../../../services/utils/globalFunctions";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import useLoading from "../Hooks/useLoading";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import moment from "moment";
import xlsx from "json-as-xlsx";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import { TrapsByAreaMap } from "../Maps/TrapsByAreaMap";
import useEmployeesList from "../../../../hooks/useEmployeesList";
import {
  generateTableColumnsByOrganization,
  ORGANIZATIONS_IDS_TO_CONSIDER_LAST_READ_HISTORY_LAT_LONG_ON_MAPS,
} from "../trapsPageConstants";
import { getUserData } from "../../../../services/utils/auth";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";

const useTrapsHistogramByTerritorializationsTable = (trapsList) => {
  const [histogramByTerritorializations, setHistogramByTerritorializations] =
    React.useState([]);
  const [selectedArea, setSelectedArea] = React.useState(null);
  const [isAreaModalOpen, setIsAreaModalOpen] = React.useState(false);
  const { appliedFilters } = useAppliedFilters();
  const { isTrapsListLoading } = useLoading();
  const { employeesList } = useEmployeesList();

  React.useEffect(() => {
    if (
      appliedFilters.territorializations.length === 0 ||
      appliedFilters.trapType.trapTypeName !==
        "armadilhaDisseminadoraInseticida" ||
      trapsList.length === 0
    )
      return;

    const histogramByAreas = calculateHistogramByAreas(
      trapsList,
      appliedFilters.territorializations
    );

    const result = histogramByAreas.map(
      ({ area, upToThirtyDays, fromThirtyOneToSixtyDays, overSixtyDays }) => {
        const areaTotalTraps =
          upToThirtyDays.length +
          fromThirtyOneToSixtyDays.length +
          overSixtyDays.length;

        return {
          area,
          upToThirtyDaysTraps: upToThirtyDays,
          upToThirtyDaysQuantity: upToThirtyDays.length,
          upToThirtyDaysPercentage:
            upToThirtyDays.length === 0 || areaTotalTraps === 0
              ? "0.0"
              : ((upToThirtyDays.length / areaTotalTraps) * 100).toFixed(1),
          fromThirtyOneToSixtyDaysTraps: fromThirtyOneToSixtyDays,
          fromThirtyOneToSixtyDaysQuantity: fromThirtyOneToSixtyDays.length,
          fromThirtyOneToSixtyDaysPercentage:
            fromThirtyOneToSixtyDays.length === 0 || areaTotalTraps === 0
              ? "0.0"
              : (
                  (fromThirtyOneToSixtyDays.length / areaTotalTraps) *
                  100
                ).toFixed(1),
          overSixtyDaysTraps: overSixtyDays,
          overSixtyDaysQuantity: overSixtyDays.length,
          overSixtyDaysPercentage:
            overSixtyDays.length === 0 || areaTotalTraps === 0
              ? "0.0"
              : ((overSixtyDays.length / areaTotalTraps) * 100).toFixed(1),
          areaTotalTraps,
        };
      }
    );

    setHistogramByTerritorializations(result);
  }, [appliedFilters, trapsList]);

  const calculateHistogramByAreas = (trapsList, territorializationsList) => {
    const histogramByTerritorializations = {};

    territorializationsList.forEach(({ label }) => {
      histogramByTerritorializations[label] = {
        area: label,
        upToThirtyDays: [],
        fromThirtyOneToSixtyDays: [],
        overSixtyDays: [],
      };
    });

    for (let i = 0; i < trapsList.length; i++) {
      const {
        daysSinceLastActivity,
        territorializationName,
        status,
        trapType,
      } = trapsList[i];

      if (
        status !== ACTIVE_TRAP_STATUS ||
        trapType.name !== appliedFilters.trapType.trapTypeName
      )
        continue;

      if (histogramByTerritorializations[territorializationName]) {
        if (daysSinceLastActivity <= 30) {
          histogramByTerritorializations[
            territorializationName
          ].upToThirtyDays.push(trapsList[i]);
          continue;
        }
        if (daysSinceLastActivity <= 60) {
          histogramByTerritorializations[
            territorializationName
          ].fromThirtyOneToSixtyDays.push(trapsList[i]);
          continue;
        }
        if (daysSinceLastActivity > 60) {
          histogramByTerritorializations[
            territorializationName
          ].overSixtyDays.push(trapsList[i]);
          continue;
        }
      }
    }

    return Array.from(Object.values(histogramByTerritorializations));
  };

  const handleDownloadHistogramTable = () => {
    const xlsxFileSettings = {
      fileName: `armadilhas_situacoes_areasVITEC_${moment().format(
        "DD/MM/YYYY HH:mm"
      )}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false,
    };

    xlsx(
      [
        {
          sheet: "Armadilhas",
          columns: tableColumns.map(({ name, selector }) => ({
            label: name,
            value: selector,
          })),
          content: histogramByTerritorializations,
        },
      ],
      xlsxFileSettings
    );
  };

  const generateActionsButtons = ({
    area,
    upToThirtyDaysTraps,
    fromThirtyOneToSixtyDaysTraps,
    overSixtyDaysTraps,
  }) => {
    return (
      <Row>
        <Col style={{ display: "flex" }}>
          <div>
            <Button
              color="primary"
              data-tip
              data-for="areaDetails"
              onClick={() => {
                setSelectedArea({
                  area,
                  upToThirtyDaysTraps,
                  fromThirtyOneToSixtyDaysTraps,
                  overSixtyDaysTraps,
                });
                setIsAreaModalOpen(true);
              }}
            >
              <i className="fa fa-search"></i>
            </Button>
            <ReactTooltip effect="solid" type="info" id="areaDetails">
              Visualizar situação da área
            </ReactTooltip>
          </div>
        </Col>
      </Row>
    );
  };

  const toggleAreaModal = () => {
    setIsAreaModalOpen((previousValue) => {
      if (previousValue) setSelectedArea(null);

      return !previousValue;
    });
  };

  const getTrapInstallerOrTyperName = (
    installerId,
    invalidInstallerIdMessage
  ) => {
    if (
      installerId === "00000000-0000-0000-0000-000000000000" ||
      installerId === null ||
      installerId === undefined
    )
      return invalidInstallerIdMessage;

    let employeeObject;

    employeeObject = employeesList.filter(
      (employee) => employee.userId === installerId
    )[0];
    if (employeeObject) return employeeObject.label;

    employeeObject = employeesList.filter(
      (employee) => employee.employeeId === installerId
    )[0];
    if (employeeObject) return employeeObject.label;

    return invalidInstallerIdMessage;
  };

  const generateTrapSituationButton = (row) => {
    const INACTIVE_TRAP_STATUS = 2;

    if (row.status === INACTIVE_TRAP_STATUS) return null;

    const trapTypeRange = getTrapRange(row.trapType.name);

    let trapStatusColor = "white";

    if (trapTypeRange.quantityRange === 2) {
      if (row.daysSinceLastActivity <= trapTypeRange.data.end)
        trapStatusColor = "#76ea79";
      else trapStatusColor = "#ff0000";
    }

    if (trapTypeRange.quantityRange === 3) {
      if (row.daysSinceLastActivity <= trapTypeRange.data.middle)
        trapStatusColor = "#76ea79";
      else if (row.daysSinceLastActivity <= trapTypeRange.data.end)
        trapStatusColor = "#fff200";
      else trapStatusColor = "#ff0000";
    }

    const statusStyles = {
      width: "60px",
      margin: "5px",
      backgroundColor: trapStatusColor,
    };

    return (
      <button className="btn" style={statusStyles}>
        {row.daysSinceLastActivity}
      </button>
    );
  };

  const handleDownloadTrapsList = (traps) => {
    const formatedTrapsListToExport = formatTrapsToExport(traps);

    const baseColumns = [
      { label: "Tipo da armadilha", value: "trapTypeName" },
      { label: "Número da armadilha", value: "trapNumber" },
      { label: "Tipo de imóvel", value: "propertyType" },
      { label: "Estabelecimento", value: "establishment" },
      { label: "Situação", value: "situation" },
      { label: "Região 1", value: "insideTerritorialization" },
      { label: "Região 2", value: "insideTerritorialization2" },
      { label: "Endereço", value: "address" },
      { label: "Referência da Lat/Long", value: "latitudeLongitudeReference" },
      { label: "Latitude", value: "latitude" },
      { label: "Longitude", value: "longitude" },
      {
        label: "Responsável pela última instalação em campo",
        value: "userWhoLastInstalled",
      },
      {
        label: "Data da última instalação em campo",
        value: "lastInstallDate",
      },
      {
        label: "Responsável pela última manutenção em campo",
        value: "userWhoLastReaded",
      },
      {
        label: "Data da última manutenção em campo",
        value: "lastReadDate",
      },
      {
        label: "Responsável pela última desinstalação em campo",
        value: "userWhoLastUninstalled",
      },
      {
        label: "Data da última desinstalação em campo",
        value: "lastUninstallDate",
      },
      {
        label: "Dias desde a última instalação/manutenção até a data fim",
        value: "daysSinceLastActivity",
      },
    ];

    const xlsxFileSettings = {
      fileName: `armadilhas_VITEC_${moment().format("DD/MM/YYYY HH:mm")}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false,
    };

    xlsx(
      [
        {
          sheet: "Armadilhas",
          columns:
            appliedFilters.projects.length > 0
              ? [...baseColumns, { label: "Projetos", value: "projects" }]
              : baseColumns,
          content: formatedTrapsListToExport,
        },
      ],
      xlsxFileSettings
    );
  };

  const formatTrapsToExport = (trapsToExport) => {
    if (!trapsToExport || trapsToExport.length === 0) return [];

    const ORGANIZATION_ID = getUserData("organizationId");
    const CONSIDER_LAST_READ_DATE =
      ORGANIZATIONS_IDS_TO_CONSIDER_LAST_READ_HISTORY_LAT_LONG_ON_MAPS.includes(
        ORGANIZATION_ID
      );

    const formatedTrapsToExport = trapsToExport.map((trap) => {
      const { latitude, longitude, reference } =
        generateTrapLatitudeAndLongitude(
          trap.lastInstallHistoryByStatusDate,
          trap.lastReadHistoryByStatusDate,
          CONSIDER_LAST_READ_DATE
        );

      return {
        trapNumber: trap.number,
        trapTypeName: ARMADILHA_INFO[trap.trapType.name].apelido,
        propertyType: trap.propertyType,
        establishment: trap.establishment,
        projects:
          !trap.lastInstallHistoryByStatusDate ||
          !trap.lastInstallHistoryByStatusDate.projects ||
          trap.lastInstallHistoryByStatusDate.projects.length === 0
            ? ""
            : trap.lastInstallHistoryByStatusDate.projects
                .map((project) => project.name)
                .join(", "),
        situation: trap.status === 1 ? "Instalada" : "Desinstalada",
        insideTerritorialization: trap.insideTerritorialization,
        insideTerritorialization2: trap.insideTerritorialization2,
        address: trap.address,
        latitude,
        longitude,
        /* latitude: trap.lastInstallHistoryByStatusDate.latitude,
        longitude: trap.lastInstallHistoryByStatusDate.longitude, */
        latitudeLongitudeReference:
          reference === "installation"
            ? "Última instalação"
            : "Última manutenção",
        userWhoLastInstalled: getTrapInstallerOrTyperName(
          trap.lastInstallHistoryByStatusDate.userId,
          "Não encontrado"
        ),
        lastInstallDate: moment(trap.lastInstallHistoryByStatusDate.date)
          .subtract(3, "hours")
          .format("DD/MM/YYYY HH:mm"),
        userWhoLastReaded: getTrapInstallerOrTyperName(
          !trap.lastReadHistoryByStatusDate ||
            !trap.lastReadHistoryByStatusDate.userId
            ? null
            : trap.lastReadHistoryByStatusDate.userId,
          "Não encontrado"
        ),
        lastReadDate:
          !trap.lastReadHistoryByStatusDate ||
          !trap.lastReadHistoryByStatusDate.date
            ? "-"
            : moment(trap.lastReadHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY HH:mm"),
        lastUninstallDate:
          !trap.lastUninstallHistoryByStatusDate ||
          !trap.lastUninstallHistoryByStatusDate.date
            ? "-"
            : moment(trap.lastUninstallHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY HH:mm"),
        userWhoLastUninstalled: getTrapInstallerOrTyperName(
          !trap.lastUninstallHistoryByStatusDate ||
            !trap.lastUninstallHistoryByStatusDate.userId
            ? null
            : trap.lastUninstallHistoryByStatusDate.userId,
          "Não encontrado"
        ),
        daysSinceLastActivity: trap.daysSinceLastActivity,
      };
    });

    return formatedTrapsToExport;
  };

  const tableColumns = [
    {
      name: "Área",
      selector: "area",
      sortable: true,
    },
    {
      name: "Quantidade de armadilhas até 30 dias",
      selector: "upToThirtyDaysQuantity",
      sortable: true,
    },
    {
      name: "Porcentagem de armadilhas até 30 dias",
      selector: "upToThirtyDaysPercentage",
      sortable: true,
    },
    {
      name: "Quantidade de armadilhas entre 31 a 60 dias",
      selector: "fromThirtyOneToSixtyDaysQuantity",
      sortable: true,
    },
    {
      name: "Porcentagem de armadilhas entre 31 a 60 dias",
      selector: "fromThirtyOneToSixtyDaysPercentage",
      sortable: true,
    },
    {
      name: "Quantidade de armadilhas acima de 60 dias",
      selector: "overSixtyDaysQuantity",
      sortable: true,
    },
    {
      name: "Porcentagem de armadilhas acima de 60 dias",
      selector: "overSixtyDaysPercentage",
      sortable: true,
    },
    {
      name: "Total de armadilhas",
      selector: "areaTotalTraps",
      sortable: true,
    },
    {
      cell: (row) => generateActionsButtons(row),
      ignoreRowClick: true,
    },
  ];

  const trapsByAreaColumns = [
    ...generateTableColumnsByOrganization(
      getUserData("organizationId"),
      null,
      getTrapInstallerOrTyperName
    ),
    {
      name: (
        <span>
          Dias desde a última atividade
          <i className="fa fa-info" data-tip data-for="trapsDays"></i>
          <ReactTooltip effect="solid" type="info" id="trapsDays">
            Representa a diferença de dias entre a instalação ou manutenção mais
            recente até hoje
          </ReactTooltip>
        </span>
      ),
      sortable: true,
      sortFunction: (a, b) => {
        return a.daysSinceLastActivity - b.daysSinceLastActivity;
      },
      selector: "daysSinceLastActivity",
      width: "100px",
      cell: (row) => generateTrapSituationButton(row),
    },
  ];

  return {
    isAreaModalOpen,
    selectedArea,
    appliedFilters,
    tableColumns,
    trapsByAreaColumns,
    toggleAreaModal,
    histogramByTerritorializations,
    handleDownloadHistogramTable,
    handleDownloadTrapsList,
    isTrapsListLoading,
  };
};

export const TrapsHistogramByTerritorializationsTable = ({ trapsList }) => {
  const {
    isAreaModalOpen,
    selectedArea,
    appliedFilters,
    handleDownloadHistogramTable,
    handleDownloadTrapsList,
    toggleAreaModal,
    histogramByTerritorializations,
    isTrapsListLoading,
    tableColumns,
    trapsByAreaColumns,
  } = useTrapsHistogramByTerritorializationsTable(trapsList);

  if (
    appliedFilters.trapType.trapTypeName !==
      "armadilhaDisseminadoraInseticida" ||
    appliedFilters.territorializations.length === 0 ||
    appliedFilters.filtersTypes.territorializationsIds === 2
  )
    return null;

  if (isTrapsListLoading) return <TableLoadingSkeleton variant={"simple"} />;

  const areaTrapsList = !selectedArea
    ? []
    : [
        ...selectedArea.upToThirtyDaysTraps,
        ...selectedArea.fromThirtyOneToSixtyDaysTraps,
        ...selectedArea.overSixtyDaysTraps,
      ];

  return (
    <>
      {isAreaModalOpen && (
        <Modal
          isOpen={isAreaModalOpen}
          toggle={toggleAreaModal}
          style={{ maxWidth: "1000px" }}
        >
          <ModalHeader className="pb-4">
            Situação das armadilhas por área (
            <span style={{ fontWeight: "bold" }}>
              {selectedArea && selectedArea.area}
            </span>
            )
          </ModalHeader>
          <ModalBody className="pt-0 pb-4">
            <TrapsByAreaMap data={selectedArea} />
            <Card>
              <CardHeader
                headerText={
                  <span>
                    Tabela de armadilhas ({selectedArea && selectedArea.area})
                  </span>
                }
                showExpandButton={false}
                showButtonsTooltips={true}
                showDownloadDataButton={false}
                showDownloadImageButton={false}
              >
                <Button
                  onClick={() => handleDownloadTrapsList(areaTrapsList)}
                  color="primary"
                  data-tip
                  data-for="dataDownload"
                  style={{ width: "55px", marginRight: "0px" }}
                >
                  <i className="fa fa-download" />
                </Button>
                <ReactTooltip effect="solid" type="info" id="dataDownload">
                  Baixar dados
                </ReactTooltip>
              </CardHeader>
              <CardContent>
                <Row>
                  <Col>
                    <DataTable
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      responsive
                      columns={trapsByAreaColumns}
                      data={areaTrapsList}
                      noDataComponent={"Nenhum registro encontrado."}
                    />
                  </Col>
                </Row>
              </CardContent>
              <CardFooter>
                <div></div>
                <img
                  style={{ maxWidth: "100px", opacity: 0.5 }}
                  src="/static/media/vitec.9bd71d52.png"
                  alt="Logo do Vitec"
                />
              </CardFooter>
            </Card>
          </ModalBody>
          <ModalFooter style={{ borderTop: "1px solid #f0f1f3" }}>
            <Button
              style={{ width: "130px" }}
              color="secondary"
              onClick={toggleAreaModal}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Card className="mb-4">
        <CardHeader
          headerText={
            <span>
              Tabela de período em dias de instalação/manutenção de armadilhas
              por áreas (
              {ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].apelido})
            </span>
          }
          showExpandButton={false}
          showButtonsTooltips={true}
          showDownloadDataButton={false}
        >
          <Button
            onClick={() => handleDownloadHistogramTable()}
            color="primary"
            data-tip
            data-for="dataDownload"
            style={{ width: "55px", marginRight: "0px" }}
          >
            <i className="fa fa-download" />
          </Button>
          <ReactTooltip effect="solid" type="info" id="dataDownload">
            Baixar dados
          </ReactTooltip>
        </CardHeader>
        <CardContent>
          <Row>
            <Col>
              <DataTable
                noHeader
                defaultSortField="name"
                defaultSortAsc={true}
                pagination
                highlightOnHover
                responsive
                columns={tableColumns}
                data={histogramByTerritorializations}
                noDataComponent={"Nenhum registro encontrado."}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};
