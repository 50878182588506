import React from "react";

import { ToastContainer } from "react-toastify";
import { Container } from "reactstrap";
import styles from "./SinanPage.module.css";
import { SinanPageHeader } from "./SinanPageHeader";
import { SinanCasesCards } from "./Cards/SinanCasesCards";
import { SinanPageContextProvider } from "./Contexts/SinanContext";
import { SinanPageGraphics } from "./SinanPageGraphics";

export const NOTIFICATIONS = 1;
export const CONFIRMED = 2;
export const MAX_YEAR_EPIDEMIOLOGIC_WEEKS = 52;

export const SinanPage = () => {
  return (
    <SinanPageContextProvider>
      <main>
        <ToastContainer />
        <Container
          fluid
          style={{ paddingBottom: "2rem" }}
          className={styles.container}
        >
          <div style={{ paddingTop: "10px !important", color: "#6d6d6d" }}>
            &nbsp;
          </div>
          <SinanPageHeader />
        </Container>
        <Container fluid className="pb-2 mt-2">
          <SinanCasesCards />
          <SinanPageGraphics />
        </Container>
      </main>
    </SinanPageContextProvider>
  );
};
