import React from "react";

import { Button, Col, Row } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import LoadingSpin from "react-loading-spin";
import { ExtraFieldFilter } from "./ExtraFieldFilter";

const VisitsPageFilters = ({
  employeesList,
  demandsList,
  fetchAllComponentData,
  filtersValues,
  isVisitsDataLoading,
  handleFiltersChange,
  teamsList,
  situationsList,
}) => {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <>
      <Row className="mb-3">
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Equipe</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            value={filtersValues.team}
            options={teamsList}
            onChange={(e) =>
              handleFiltersChange(
                "team",
                !e
                  ? {
                      label: "Todas",
                      value: "00000000-0000-0000-0000-000000000000",
                    }
                  : e
              )
            }
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Data (Período)</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="beginDate"
                id="beginDate"
                className="form-control"
                value={filtersValues.beginDate}
                onChange={({ target }) =>
                  handleFiltersChange("beginDate", target.value)
                }
              />
            </div>
            <div
              className="h4 text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Até</span>
            </div>
            <div style={{ width: "45%" }}>
              <input
                type="date"
                name="endDate"
                id="endDate"
                className="form-control"
                value={filtersValues.finalDate}
                onChange={({ target }) =>
                  handleFiltersChange("finalDate", target.value)
                }
              />
            </div>
          </div>
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Funcionário</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={employeesList}
            value={filtersValues.employees}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFiltersChange("employees", !e || e.length === 0 ? [] : e)
            }
            menuPortalTarget={document.body}
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Situação</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={situationsList}
            value={filtersValues.situation}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFiltersChange(
                "situation",
                !e
                  ? {
                      label: "Todas",
                      value: "",
                    }
                  : e
              )
            }
            menuPortalTarget={document.body}
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <span className="h4 text-white">Demanda</span>
          <Select
            styles={selectStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={demandsList}
            value={filtersValues.demand}
            isMulti={false}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) =>
              handleFiltersChange(
                "demand",
                !e
                  ? {
                      label: "Todas",
                      value: "00000000-0000-0000-0000-000000000000",
                    }
                  : e
              )
            }
            menuPortalTarget={document.body}
          />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
            <ExtraFieldFilter 
              filtersValues={filtersValues}
              handleFiltersChange={handleFiltersChange}
              selectStyles={selectStyles}
            />
        </Col>
      </Row>
      <Row>
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col className="col-md-4 visit-responsive-filter">
          {isVisitsDataLoading ? (
            <Button color="primary" disabled={true}>
              <LoadingSpin primaryColor="#fff" size={17} />
            </Button>
          ) : (
            <Button color="primary" onClick={() => fetchAllComponentData()}>
              Filtrar
            </Button>
          )}
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
    </>
  );
};

export default VisitsPageFilters;
