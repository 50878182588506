import { useContextSelector } from "use-context-selector";
import { SinanMapContext } from "../Contexts/SinanMapContext";

export const useSinanMapClusters = () => {
  const removePointsCluster = useContextSelector(
    SinanMapContext,
    (context) => context.removePointsCluster
  );
  const setRemovePointsCluster = useContextSelector(
    SinanMapContext,
    (context) => context.setRemovePointsCluster
  );

  return {
    removePointsCluster,
    setRemovePointsCluster,
  };
}