// DiagnosisComponent.js

import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Select from 'react-select';

const DiagnosisResultNew = ({ 
  sampleGroupIndex,
  sampleResultIndex,
  handleChangeArrayData, 
  isPossibleDiagnosis,
  sampleDiagnosis,
  Laboratories,
  techniques,
  diagnosticsExams,
  selectedVirusType,
  virusTypes,
  selectedAnimalGenre
}) => {
  const handleDiagnosisData = (key, value) => {
    handleChangeArrayData(key, value, sampleGroupIndex, sampleResultIndex)
  }

  const addDiagnosisExam = (examIndex) => {
    let sampleDiagnosisAux = [...sampleDiagnosis];

    sampleDiagnosisAux[examIndex].exams.push({
      examDiagnosticId: "00000000-0000-0000-0000-000000000000",
      examId:"00000000-0000-0000-0000-000000000000",
      techniques:[],
      techniqueId:"00000000-0000-0000-0000-000000000000",
    })
    
    handleDiagnosisData("sampleDiagnosis",sampleDiagnosis)
  }

  const removeDiagnosisExam = (examIndex) => {
    let sampleDiagnosisAux = [...sampleDiagnosis];
    sampleDiagnosisAux[examIndex].exams.pop();

    handleDiagnosisData("sampleDiagnosis", sampleDiagnosisAux)
  }

  const addDiagnosis = () => {
    let diagnosis = [...sampleDiagnosis];

    diagnosis.push({
      SampleResultId:"00000000-0000-0000-0000-000000000000",
      ExternLaboratoryId:"00000000-0000-0000-0000-000000000000",
      externSampleNumber:"",
      description:"",
      exams:[{
        examDiagnosticId: "00000000-0000-0000-0000-000000000000",
        examId:"00000000-0000-0000-0000-000000000000",
        techniques:[],
        techniqueId:"00000000-0000-0000-0000-000000000000",
      }],
      diagnostic:"",
      result:false,
    })

    handleDiagnosisData("sampleDiagnosis",diagnosis)
  }

  const removeDiagnosis = () =>{
    var sampleDiagnosisAux = [...sampleDiagnosis];
    sampleDiagnosis.pop();

    handleDiagnosisData("sampleDiagnosis",sampleDiagnosisAux)
  }

  const handleChangeDiagnosis = (index, element, array = null, arrayIndex = null) => {
    let sampleDiagnosisAux = [...sampleDiagnosis];
    if(array !== null){
      sampleDiagnosisAux[index][array][arrayIndex][element.target.name] = element.target.value;

      if(element.target.name == 'examId'){
        let techniquesArray = techniques.filter((technique) => technique.examId == element.target.value);

        if(techniquesArray){
          sampleDiagnosisAux[index][array][arrayIndex].techniques = techniquesArray;
          sampleDiagnosisAux[index][array][arrayIndex].techniqueId = "00000000-0000-0000-0000-000000000000";
        }
      }
    }else{        
      sampleDiagnosisAux[index][element.target.name] = element.target.value;
    }

    handleDiagnosisData("sampleDiagnosis", sampleDiagnosisAux)
  }

  return (
    <Card style={{ marginTop: '2%', marginBottom: '2%' }}>
      <CardHeader>
        <span className="h4"><i className='fa fa-diagnoses'></i> Diagnóstico</span>
      </CardHeader>

      <CardBody>
        {sampleDiagnosis.map((diagnostic, diagnosticIndex) => (
          <div key={diagnosticIndex}>
            <div className="row">
              <div className="col-6 responsive-form-field-result-full">
                <span className="h5">Nome do laboratório credenciado</span>
                <Select
                  options={Laboratories && Laboratories}
                  key={diagnostic.laboratoryId}
                  name="laboratoryId"
                  onChange={(e) => { 
                    handleChangeDiagnosis(diagnosticIndex, e, null, null) 
                  }}
                  value={Laboratories && Laboratories.filter(laboratory => laboratory.value == diagnostic.laboratoryId)}
                />
              </div>
              <div className="col-6 responsive-form-field-result-full">
                <span className="h5">Número da amostra no laboratório externo</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Número amostra"
                  name="externSampleNumber"
                  value={diagnostic.externSampleNumber}
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null) }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="h5">Descrição de encaminhamento</span>
                <textarea
                  className="form-control"
                  placeholder="Motivo de encaminhamento"
                  name="description"
                  value={diagnostic.description}
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null) }}
                />
              </div>
            </div>
            {diagnostic.exams.map((exam, examIndex) => (
              <div className="row" key={examIndex}>
                <div className="col-6 responsive-form-field-result-full">
                  <span className="h5">Exame realizado</span>
                  <select
                    className="form-control"
                    name="examId"
                    defaultValue={exam.examId}
                    onChange={(e) => handleChangeDiagnosis(diagnosticIndex, e, "exams", examIndex)}
                  >
                    <option value="">Selecione</option>
                    {diagnosticsExams && diagnosticsExams.map((diagnosticsExam, id) => (
                      <option key={id} value={diagnosticsExam.id} selected={diagnosticsExam.id === exam.examId}>{diagnosticsExam.name}</option>
                    ))}
                  </select>
                </div>

                <div className="col-6 responsive-form-field-result-full">
                  <span className="h5">Técnica Utilizada</span>
                  <select
                    className="form-control"
                    name="techniqueId"
                    defaultValue={exam.techniqueId}
                    onChange={(e) => handleChangeDiagnosis(diagnosticIndex, e, "exams", examIndex)}
                  >
                    <option value="">Selecione</option>
                    {techniques && techniques.map((technique, id) => (
                      <option key={id} value={technique.id} selected={technique.id === exam.techniqueId}>{technique.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            ))}

            <div className="row mt-4 mb-4">
              <div className={diagnostic.exams.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"}>
                <button className="btn btn-info responsive-button-result" onClick={() => addDiagnosisExam(diagnosticIndex)}>+ Adicionar Exame e Técnica</button>
              </div>
              {diagnostic.exams.length > 1 &&
                <div className="col-6 responsive-form-field-result-full">
                  <button className="btn btn-warning responsive-button-result" onClick={() => removeDiagnosisExam(diagnosticIndex)}><i className='fa fa-trash'></i> Remover Exame e Técnica</button>
                </div>
              }
            </div>

            <div className="row mb-5">
              <div className="col-4 responsive-form-field-result-full">
                <span className="h5 text-black">Diagnóstico</span>
                <select
                  className="form-control"
                  name="diagnostic"
                  value={diagnostic.diagnostic}
                  onChange={(e) => { 
                    handleChangeDiagnosis(diagnosticIndex, e, null, null) 
                  }}
                >
                  <option value={false}>Selecione</option>
                  <option value={"a4620cb6-85da-4543-a399-12a35ee035a5"}>Aedes aegypti</option>
                </select>
              </div>

              <div className="col-4 responsive-form-field-result-full">
                <span className="h5 text-black">Resultado</span>
                <select
                  className="form-control"
                  name="result"
                  value={diagnostic.result}
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null) }}
                >
                  <option>Selecione</option>
                  <option>Negativo</option>
                  <option>Positivo</option>
                </select>
              </div>

              <div className="col-4 responsive-form-field-result-full">
                <span className="h5">Tipo de vírus</span>
                <Select
                  key={selectedVirusType}
                  options={virusTypes && virusTypes}
                  onChange={(e) => { 
                    handleChangeArrayData("selectedVirusType", e.value, sampleGroupIndex, sampleResultIndex)
                  }}
                  isClearable={true}
                  value={selectedVirusType !== '00000000-0000-0000-0000-000000000000' && virusTypes ? virusTypes.filter(obj => obj.value === selectedVirusType) : null}
                  isDisabled={selectedAnimalGenre === '00000000-0000-0000-0000-000000000000' || diagnostic.result !== "Positivo"}
                />
              </div>
            </div>

            <div className="row">
              <div className={sampleDiagnosis.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"}>
                <button className="btn btn-info responsive-button-result" disabled={!isPossibleDiagnosis} onClick={() => addDiagnosis()}>+ Adicionar Encaminhamento</button>
              </div>
              {sampleDiagnosis.length > 1 &&
                <div className="col-6 responsive-form-field-result-full">
                  <button className="btn btn-warning responsive-button-result" onClick={() => removeDiagnosis()}><i className='fa fa-trash'></i> Remover Encaminhamento</button>
                </div>
              }
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default DiagnosisResultNew;
