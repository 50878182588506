import React from "react";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  getTrapByNumber,
  putTrapByNumber,
} from "../../../../services/api/Trap";
import { toast } from "react-toastify";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { selectComponentStyles } from "../../../../services/utils/globalFunctions";
import {
  getProjects,
  postTrapHistoryProject,
} from "../../../../services/api/project";
import Leaflet from "leaflet";
import { Map } from "../../../../components/Maps/Map";
import { Marker } from "react-leaflet";
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../../components/Cards/Card";
import ReactTooltip from "react-tooltip";
import { getUserData } from "../../../../services/utils/auth";
import useTrapsPage from "../Hooks/useTrapsPage";

const useEditrapModal = () => {
  const [trapData, setTrapData] = React.useState(null);
  const [projectsList, setProjectsList] = React.useState([]);
  const { selectedTrapNumber, isEditTrapModalOpen, toggleEditTrapModal } =
    useTrapsPage();

  React.useEffect(() => {
    if (!isEditTrapModalOpen) return;

    fetchProjectsList();
  }, [isEditTrapModalOpen]);

  React.useEffect(() => {
    if (
      !isEditTrapModalOpen ||
      !selectedTrapNumber ||
      selectedTrapNumber === ""
    )
      return;

    fetchTrapByNumberData(selectedTrapNumber);
  }, [isEditTrapModalOpen, selectedTrapNumber]);

  const fetchProjectsList = async () => {
    try {
      const { data, status } = await getProjects();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos projetos. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      const formatedList = data.map(({ id, name, description }) => ({
        description,
        label: name,
        value: id,
      }));

      setProjectsList(formatedList);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const fetchTrapByNumberData = async (trapNumber) => {
    if (!trapNumber || trapNumber === "") return;

    try {
      const { data, status } = await getTrapByNumber(trapNumber);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados da armadilha. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      console.log("trapByNumberData", data);

      const {
        addressNumber,
        addressComplement,
        id,
        latitude,
        longitude,
        number,
        projects,
        publicArea,
        trapTypeId,
        whoInstallId,
      } = data;

      const formatedProjects =
        projects && projects.length > 0
          ? projects.map(({ id, name, description }) => ({
              description,
              label: name,
              value: id,
            }))
          : [];

      const formatedTrap = {
        addressNumber,
        addressComplement: !addressComplement ? "" : addressComplement,
        id,
        isMapOpen: false,
        number,
        updatedNumber: number,
        position: [latitude, longitude],
        projects: formatedProjects,
        publicArea,
        trapTypeId,
        whoInstallId,
      };

      setTrapData(formatedTrap);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const handleTrapChange = (fieldName, newValue) => {
    setTrapData((previousValues) => {
      const newValues = { ...previousValues };
      newValues[fieldName] = newValue;

      return newValues;
    });
  };

  const handleUpdateTrap = async () => {
    if (!trapData) return;

    const organizationId = getUserData("organizationId");

    const {
      addressNumber,
      addressComplement,
      projects,
      id,
      position,
      publicArea,
      number,
      whoInstallId,
      trapTypeId,
      updatedNumber,
    } = trapData;

    const projectsRequest = {
      organizationId,
      projectsId: projects.map(({ value }) => value),
      trapId: id,
    };

    console.log("trapData", trapData);

    const trapToUpdateRequest = {
      addressComplement: addressComplement.trim(),
      addressSubpremisse: publicArea.trim(),
      AddressNumber: addressNumber.trim(),
      georeferencedByAddress: false,
      insideTerritorialization: "",
      latitude: position[0],
      longitude: position[1],
      organizationId,
      trapNumber: number,
      trapNumberToUpdate: updatedNumber,
      trapTypeId,
      whoInstallId,
    };

    try {
      const { status: projectsStatus } = await postTrapHistoryProject(
        projectsRequest
      );

      const { status: trapToUpdateStatus } = await putTrapByNumber(
        trapToUpdateRequest
      );

      if (projectsStatus !== 200 || trapToUpdateStatus !== 200)
        throw new Error(
          "Ocorreu um erro ao atualizar alguns dados da armadilha. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      toast.success("Armadilha atualizada com sucesso!");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  return {
    handleTrapChange,
    handleUpdateTrap,
    isEditTrapModalOpen,
    projectsList,
    trapData,
    toggleEditTrapModal,
  };
};

const EditTrapModal = ({ employeesList, trapTypesList }) => {
  const {
    handleTrapChange,
    handleUpdateTrap,
    isEditTrapModalOpen,
    projectsList,
    trapData,
    toggleEditTrapModal,
  } = useEditrapModal();

  return (
    <Modal size="lg" isOpen={isEditTrapModalOpen} toggle={toggleEditTrapModal}>
      <ModalHeader className="pb-0">
        <span className="h2">
          Editar armadilha (
          <span style={{ color: "#3b6bca" }}>
            {trapData && trapData.number}
          </span>
          )
        </span>
        <p style={{ fontSize: "14px" }}>
          Visualize e altere informações sobre a armadilha.
        </p>
      </ModalHeader>
      <ModalBody className="pt-0 pb-4">
        {trapData && (
          <div>
            <form>
              <Row className="mb-2">
                <Col>
                  <span style={{ display: "block" }} className="h4 mb-0">
                    Atividades:
                  </span>
                  <Select
                    styles={selectComponentStyles}
                    placeholder={"Selecione"}
                    isClearable={true}
                    isMulti={true}
                    options={projectsList}
                    value={trapData.projects}
                    onChange={(e) => handleTrapChange("projects", e)}
                  />
                </Col>
                <Col>
                  <span className="h4 mb-0">Quem instalou:</span>
                  <Select
                    styles={selectComponentStyles}
                    placeholder={"Selecione uma opção"}
                    isClearable={false}
                    options={employeesList}
                    value={employeesList.find(
                      ({ value }) => value === trapData.whoInstallId
                    )}
                    onChange={(e) => handleTrapChange("whoInstallId", e.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <span style={{ display: "block" }} className="h4 mb-0">
                    Tipo:
                  </span>
                  <Select
                    styles={selectComponentStyles}
                    placeholder={"Selecione"}
                    isClearable={false}
                    options={trapTypesList}
                    value={trapTypesList.find(
                      ({ value }) => value === trapData.trapTypeId
                    )}
                    onChange={(e) => handleTrapChange("trapTypeId", e.value)}
                  />
                </Col>
                <Col>
                  <span className="h4 mb-0">Número da armadilha:</span>
                  <input
                    className="form-control"
                    type="text"
                    value={trapData.updatedNumber}
                    onChange={({ target: { value } }) =>
                      handleTrapChange("updatedNumber", value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <span className="h4 mb-0">Logradouro:</span>
                  <input
                    className="form-control"
                    type="text"
                    value={trapData.publicArea}
                    onChange={({ target: { value } }) =>
                      handleTrapChange("publicArea", value)
                    }
                  />
                </Col>
                <Col>
                  <span className="h4 mb-0">Complemento:</span>
                  <input
                    className="form-control"
                    type="text"
                    value={trapData.addressComplement}
                    onChange={({ target: { value } }) =>
                      handleTrapChange("addressComplement", value)
                    }
                  />
                </Col>
                <Col>
                  <span className="h4 mb-0">Número do endereço:</span>
                  <input
                    className="form-control"
                    type="text"
                    value={trapData.addressNumber}
                    onChange={({ target: { value } }) =>
                      handleTrapChange("addressNumber", value)
                    }
                  />
                </Col>
              </Row>
            </form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1.5rem",
              }}
            >
              {trapData.isMapOpen ? (
                <Button
                  color="primary"
                  onClick={() =>
                    handleTrapChange("isMapOpen", !trapData.isMapOpen)
                  }
                  style={{ width: "200px" }}
                >
                  <i className="fa fa-map-marker-alt"></i> Ocultar mapa
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() =>
                    handleTrapChange("isMapOpen", !trapData.isMapOpen)
                  }
                  style={{ width: "200px" }}
                >
                  <i className="fa fa-map-marker-alt"></i> Abrir mapa
                </Button>
              )}
            </div>
            {trapData.isMapOpen && (
              <Card>
                <CardHeader
                  headerText={
                    <span>
                      Editar posição da armadilha{" "}
                      <i
                        className="fa fa-info"
                        data-tip
                        data-for="trapPositionDisclaimer"
                      ></i>
                      <ReactTooltip
                        effect="solid"
                        type="info"
                        id="trapPositionDisclaimer"
                      >
                        Clique, arraste e solte o marcador para alterar a
                        posição da armadilha.
                      </ReactTooltip>
                    </span>
                  }
                  showDownloadDataButton={false}
                  showDownloadImageButton={false}
                  showExpandButton={false}
                />
                <CardContent>
                  <Map center={trapData.position} style={{ height: "27rem" }}>
                    <DraggableMarker
                      initalPosition={trapData.position}
                      setPosition={handleTrapChange}
                    />
                  </Map>
                </CardContent>
              </Card>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter style={{ borderTop: "1px solid #f0f1f3" }}>
        <Button
          style={{ width: "170px" }}
          color="primary"
          onClick={handleUpdateTrap}
        >
          Salvar alterações
        </Button>
        <Button
          style={{ width: "130px" }}
          color="secondary"
          onClick={toggleEditTrapModal}
        >
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const DraggableMarker = ({ initalPosition, setPosition }) => {
  const markerRef = React.useRef(null);

  const handleMarkerOnDragEnd = (target) => {
    if (!target) return;

    const newLatitude = +target._latlng.lat.toFixed(7);
    const newLongitude = +target._latlng.lng.toFixed(7);

    setPosition("position", [newLatitude, newLongitude]);
  };

  const trapIcon = Leaflet.icon({
    iconUrl: "https://i.ibb.co/q09c9H7/g-int2care.png",
    iconSize: [20, 20],
  });

  return (
    <Marker
      draggable={true}
      ondragend={({ target }) => handleMarkerOnDragEnd(target)}
      position={initalPosition}
      ref={markerRef}
      icon={trapIcon}
    />
  );
};

export default EditTrapModal;
