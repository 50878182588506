import moment from "moment";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";

export const ORGANIZATIONS_IDS_TO_CONSIDER_LAST_READ_HISTORY_LAT_LONG_ON_MAPS =
  [
    ,/* "559a16ab-5da8-41ba-96b8-6e9f55feddd8", */
    //São Paulo
    /* "ed13d1fc-5804-48f1-a695-cdacfdcbd2ad" */
    //Aids Vetor
  ];

export const generateTableColumnsByOrganization = (
  organizationId,
  activeTableColumns,
  getTrapInstallerOrTyperName
) => {
  const columnsByOrganizations = {
    default: [
      {
        name: "Tipo de armadilha",
        selector: "name",
        sortable: true,
        cell: (row) => ARMADILHA_INFO[row.trapType.name].apelido,
        width: "100px",
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Tipo de armadilha"]
          ? true
          : false,
      },
      {
        name: "Número",
        selector: "number",
        sortable: true,
        sortFunction: (a, b) => {
          const aValue = a.number.replace(/\D/g, "");
          const bValue = b.number.replace(/\D/g, "");

          return aValue - bValue;
        },
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Número"]
          ? true
          : false,
      },
      {
        name: "Situação",
        selector: "status",
        sortable: true,
        cell: (row) =>
          row.status === 1
            ? "Instalada"
            : row.status === 2
            ? "Desinstalada"
            : "Indefinido",
      },
      {
        name: "Endereço",
        selector: "address",
        sortable: true,
        sortFunction: (a, b) => a.address.localeCompare(b.address),
        cell: (row) => row.address,
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Endereço"]
          ? true
          : false,
      },
      {
        name: "Quem realizou a última instalação (campo)",
        selector: "lastInstallHistoryByStatusDate.userId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.lastInstallHistoryByStatusDate.userId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Funcionário que instalou (campo)"]
          ? true
          : false,
      },
      {
        name: "Data da última instalação (campo)",
        selector: "lastInstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastInstallHistoryByStatusDate ||
          !row.lastInstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastInstallHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Data de instalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da última instalação (campo)",
        selector: "lastInstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastInstallHistoryByStatusDate ||
          !row.lastInstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastInstallHistoryByStatusDate.date).format("HH:mm"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Hora de instalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Quem realizou a última manutenção (campo)",
        selector: "lastReadHistoryByStatusDate.userId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            !row.lastReadHistoryByStatusDate ||
              !row.lastReadHistoryByStatusDate.userId
              ? null
              : row.lastReadHistoryByStatusDate.userId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Funcionário que realizou a manutenção (campo)"]
          ? true
          : false,
      },
      {
        name: "Data da última manutenção (campo)",
        selector: "lastReadHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastReadHistoryByStatusDate ||
          !row.lastReadHistoryByStatusDate.date
            ? "-"
            : moment(row.lastReadHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Data da manutenção (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da última manutenção (campo)",
        selector: "lastReadHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastReadHistoryByStatusDate ||
          !row.lastReadHistoryByStatusDate.date
            ? "-"
            : moment(row.lastReadHistoryByStatusDate.date).format("HH:mm"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Hora da manutenção (campo)"]
          ? true
          : false,
      },
      {
        name: "Quem realizou a última desinstalação (campo)",
        selector: "lastUninstallHistoryByStatusDate.userId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            !row.lastUninstallHistoryByStatusDate ||
              !row.lastUninstallHistoryByStatusDate.userId
              ? null
              : row.lastUninstallHistoryByStatusDate.userId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns[
              "Funcionário que realizou a desinstalação (campo)"
            ]
          ? true
          : false,
      },
      {
        name: "Data da última desinstalação (campo)",
        selector: "lastUninstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastUninstallHistoryByStatusDate ||
          !row.lastUninstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastUninstallHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Data da desinstalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da última desinstalação (campo)",
        selector: "lastUninstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastUninstallHistoryByStatusDate ||
          !row.lastUninstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastUninstallHistoryByStatusDate.date).format("HH:mm"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Hora da desinstalação (campo)"]
          ? true
          : false,
      },
    ],
    // São Paulo
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": [
      {
        name: "Tipo de armadilha",
        selector: "name",
        sortable: true,
        cell: (row) => ARMADILHA_INFO[row.trapType.name].apelido,
        width: "100px",
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Tipo de armadilha"]
          ? true
          : false,
      },
      {
        name: "Número",
        selector: "number",
        sortable: true,
        sortFunction: (a, b) => {
          const aValue = a.number.replace(/\D/g, "");
          const bValue = b.number.replace(/\D/g, "");

          return aValue - bValue;
        },
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Número"]
          ? true
          : false,
      },
      {
        name: "Situação",
        selector: "status",
        sortable: true,
        cell: (row) =>
          row.status === 1
            ? "Instalada"
            : row.status === 2
            ? "Desinstalada"
            : "Indefinido",
      },
      {
        name: "Quadra SISCOZ",
        selector: "insideTerritorialization",
        sortable: true,
      },
      {
        name: "UVIS",
        selector: "insideTerritorialization2",
        sortable: true,
      },
      {
        name: "Endereço",
        selector: "address",
        sortable: true,
        sortFunction: (a, b) => a.address.localeCompare(b.address),
        cell: (row) => row.address,
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Endereço"]
          ? true
          : false,
      },
      {
        name: "Quem realizou a última instalação (campo)",
        selector: "lastInstallHistoryByStatusDate.userId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.lastInstallHistoryByStatusDate.userId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Funcionário que instalou (campo)"]
          ? true
          : false,
      },
      {
        name: "Data da última instalação (campo)",
        selector: "lastInstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastInstallHistoryByStatusDate ||
          !row.lastInstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastInstallHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Data de instalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da última instalação (campo)",
        selector: "lastInstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastInstallHistoryByStatusDate ||
          !row.lastInstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastInstallHistoryByStatusDate.date).format("HH:mm"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Hora de instalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Quem realizou a última manutenção (campo)",
        selector: "lastReadHistoryByStatusDate.userId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            !row.lastReadHistoryByStatusDate ||
              !row.lastReadHistoryByStatusDate.userId
              ? null
              : row.lastReadHistoryByStatusDate.userId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Funcionário que realizou a manutenção (campo)"]
          ? true
          : false,
      },
      {
        name: "Data da última manutenção (campo)",
        selector: "lastReadHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastReadHistoryByStatusDate ||
          !row.lastReadHistoryByStatusDate.date
            ? "-"
            : moment(row.lastReadHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Data da manutenção (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da última manutenção (campo)",
        selector: "lastReadHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastReadHistoryByStatusDate ||
          !row.lastReadHistoryByStatusDate.date
            ? "-"
            : moment(row.lastReadHistoryByStatusDate.date).format("HH:mm"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Hora da manutenção (campo)"]
          ? true
          : false,
      },
      {
        name: "Quem realizou a última desinstalação (campo)",
        selector: "lastUninstallHistoryByStatusDate.userId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            !row.lastUninstallHistoryByStatusDate ||
              !row.lastUninstallHistoryByStatusDate.userId
              ? null
              : row.lastUninstallHistoryByStatusDate.userId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns[
              "Funcionário que realizou a desinstalação (campo)"
            ]
          ? true
          : false,
      },
      {
        name: "Data da última desinstalação (campo)",
        selector: "lastUninstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastUninstallHistoryByStatusDate ||
          !row.lastUninstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastUninstallHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Data da desinstalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da última desinstalação (campo)",
        selector: "lastUninstallHistoryByStatusDate.date",
        sortable: true,
        cell: (row) =>
          !row.lastUninstallHistoryByStatusDate ||
          !row.lastUninstallHistoryByStatusDate.date
            ? "-"
            : moment(row.lastUninstallHistoryByStatusDate.date).format("HH:mm"),
        omit: !activeTableColumns
          ? false
          : !activeTableColumns["Hora da desinstalação (campo)"]
          ? true
          : false,
      },
    ],
  };

  if (columnsByOrganizations[organizationId])
    return columnsByOrganizations[organizationId];

  return columnsByOrganizations["default"];
};
