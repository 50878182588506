import React from "react";

import {
  Button,
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import ReactTooltip from "react-tooltip";
import { selectComponentStyles } from "../../../../services/utils/globalFunctions";

const TrapsPageTableFilters = ({
  employeesList,
  filters,
  handleFiltersChange,
  trapTypesList,
}) => {
  const trapColorStatusOptions = [
    {
      label: "Todos",
      value: "all",
    },
    {
      label: "Verde",
      value: "green",
    },
    {
      label: "Amarelo",
      value: "yellow",
    },
    {
      label: "Vermelho",
      value: "red",
    },
  ];

  const trapStatusOptions = [
    {
      label: "Todas",
      value: "all",
    },
    {
      label: "Instalada",
      value: "instaled",
    },
    {
      label: "Desinstalada",
      value: "removed",
    },
    {
      label: "Sem coordenadas",
      value: "notGeoreferenced",
    },
    {
      label: "Georreferenciada por endereço",
      value: "georeferencedByAddress",
    },
  ];

  return (
    <>
      <Button
        style={{ width: "55px", marginRight: "0px" }}
        id="trapsTableFiltersPopover"
        type="button"
        color="primary"
        data-tip
        data-for="trapsTableFiltersPopover"
      >
        <i className="fa fa-filter"></i>
      </Button>
      <ReactTooltip effect="solid" type="info" id="trapsTableFiltersPopover">
        Filtros
      </ReactTooltip>
      <TrapsPageTableFiltersPopover
        trapColorStatusOptions={trapColorStatusOptions}
        trapStatusOptions={trapStatusOptions}
        employeesList={employeesList}
        filters={filters}
        handleFiltersChange={handleFiltersChange}
        trapTypesList={trapTypesList}
        offset="-120, 8"
        placement="bottom"
        target="trapsTableFiltersPopover"
      />
    </>
  );
};

const TrapsPageTableFiltersPopover = ({
  employeesList,
  filters,
  handleFiltersChange,
  trapTypesList,
  trapColorStatusOptions,
  trapStatusOptions,
  target,
  offset,
  placement,
  ...props
}) => {
  return (
    <UncontrolledPopover
      style={{ width: "450px", zIndex: 100000 }}
      placement={placement}
      target={target}
      offset={offset}
      id="filtersPopover"
      {...props}
    >
      <PopoverHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Filtros
        </div>
      </PopoverHeader>
      <PopoverBody style={{ backgroundColor: "#ffffff" }}>
        <Row>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Número da armadilha</span>
            <input
              type="text"
              className="form-control"
              value={filters.number}
              onChange={({ target }) =>
                handleFiltersChange("number", target.value)
              }
            />
          </Col>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Status por cor</span>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              options={trapColorStatusOptions}
              value={filters.statusByColor}
              onChange={(e) => handleFiltersChange("statusByColor", e)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Tipo da armadilha</span>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              options={[
                {
                  label: "Todos",
                  value: "all",
                },
                ...trapTypesList,
              ]}
              value={filters.type}
              onChange={(e) => handleFiltersChange("type", e)}
            />
          </Col>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Quem instalou</span>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              options={[
                {
                  label: "Todos",
                  value: "all",
                },
                ...employeesList,
              ]}
              value={filters.installerId}
              onChange={(e) => handleFiltersChange("installerId", e)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Quem leu</span>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              options={[
                {
                  label: "Todos",
                  value: "all",
                },
                ...employeesList,
              ]}
              value={filters.readerId}
              onChange={(e) => handleFiltersChange("readerId", e)}
            />
          </Col>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Rua</span>
            <input
              type="text"
              className="form-control"
              value={filters.address}
              onChange={({ target }) =>
                handleFiltersChange("address", target.value)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Exibir armadilhas</span>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              options={trapStatusOptions}
              value={filters.status}
              onChange={(e) => handleFiltersChange("status", e)}
            />
          </Col>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Data de instalação</span>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "5%",
                }}
              >
                <div style={{ width: "45%" }}>
                  <input
                    type="date"
                    value={filters.beginInstallDate}
                    onChange={({ target }) =>
                      handleFiltersChange("beginInstallDate", target.value)
                    }
                    className="form-control"
                    name="beginInstallDate"
                    id="beginInstallDate"
                  />
                </div>
                <div
                  className="h4 text-white"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                    Até
                  </span>
                </div>
                <div style={{ width: "45%" }}>
                  <input
                    type="date"
                    value={filters.endInstallDate}
                    onChange={({ target }) =>
                      handleFiltersChange("endInstallDate", target.value)
                    }
                    className="form-control"
                    name="endInstallDate"
                    id="endInstallDate"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Data de manutenção</span>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "5%",
                }}
              >
                <div style={{ width: "45%" }}>
                  <input
                    type="date"
                    value={filters.beginReadDate}
                    onChange={({ target }) =>
                      handleFiltersChange("beginReadDate", target.value)
                    }
                    className="form-control"
                    name="beginReadDate"
                    id="beginReadDate"
                  />
                </div>
                <div
                  className="h4 text-white"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                    Até
                  </span>
                </div>
                <div style={{ width: "45%" }}>
                  <input
                    type="date"
                    value={filters.endReadDate}
                    onChange={({ target }) =>
                      handleFiltersChange("endReadDate", target.value)
                    }
                    className="form-control"
                    name="endReadDate"
                    id="endReadDate"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="mb-3">
            <span style={{ fontWeight: "bold" }}>Data de desinstalação</span>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "5%",
                }}
              >
                <div style={{ width: "45%" }}>
                  <input
                    type="date"
                    value={filters.beginUninstallDate}
                    onChange={({ target }) =>
                      handleFiltersChange("beginUninstallDate", target.value)
                    }
                    className="form-control"
                    name="beginUninstallDate"
                    id="beginUninstallDate"
                  />
                </div>
                <div
                  className="h4 text-white"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                    Até
                  </span>
                </div>
                <div style={{ width: "45%" }}>
                  <input
                    type="date"
                    value={filters.endUninstallDate}
                    onChange={({ target }) =>
                      handleFiltersChange("endUninstallDate", target.value)
                    }
                    className="form-control"
                    name="endUninstallDate"
                    id="endUninstallDate"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default TrapsPageTableFilters;