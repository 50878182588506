import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import { Map as MapComponent } from "../../../../components/Maps/Map";
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useGraphicsData from "../Hooks/useGraphicsData";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import Control from "react-leaflet-control";
import { polygon } from "@turf/turf";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import usePositivityIndexMap from "../Hooks/usePositivityIndexMap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { selectComponentStyles } from "../../../../services/utils/globalFunctions";
import useTrapsList from "../../../../hooks/useTrapsList";
import moment from "moment";
import useEmployeesList from "../../../../hooks/useEmployeesList";

const Leaflet = require("leaflet");
const { BaseLayer, Overlay } = LayersControl;

const usePositivityIndex = () => {
  const [periodsOptions, setPeriodsOptions] = React.useState([]);
  const [selectedPeriod, setSelectedPeriod] = React.useState(null);
  const [removePointsCluster, setRemovePointsCluster] = React.useState(false);
  const { appliedFilters } = useAppliedFilters();
  const { fieldResultsList } = useGraphicsData();

  const handlePointsClusterChange = React.useCallback(() => {
    setRemovePointsCluster((previousValue) => !previousValue);
  }, []);

  const handleSelectedPeriodChange = React.useCallback((newValue) => {
    setSelectedPeriod(newValue);
  }, []);

  React.useEffect(() => {
    if (appliedFilters.beginDate !== "" && appliedFilters.endDate !== "") {
      const beginDate = moment(appliedFilters.beginDate).format("DD/MM/YYYY");
      const endDate = moment(appliedFilters.endDate).format("DD/MM/YYYY");

      setPeriodsOptions([
        {
          label: `${beginDate} A ${endDate}`,
          value: `${beginDate} A ${endDate}`,
        },
      ]);

      setSelectedPeriod({
        label: `${beginDate} A ${endDate}`,
        value: `${beginDate} A ${endDate}`,
      });

      return;
    }

    if (appliedFilters.datePeriodType === "week") {
      setPeriodsOptions(appliedFilters.epidemiologicalWeeks);
      setSelectedPeriod(appliedFilters.epidemiologicalWeeks[0]);

      return;
    }

    setPeriodsOptions(appliedFilters.months);
    setSelectedPeriod(appliedFilters.months[0]);
  }, [appliedFilters]);

  return {
    fieldResultsList,
    removePointsCluster,
    periodsOptions,
    selectedPeriod,
    handleSelectedPeriodChange,
    handlePointsClusterChange,
  };
};

const PositivityIndexMap = ({ estimateObjectName }) => {
  const {
    fieldResultsList,
    periodsOptions,
    selectedPeriod,
    removePointsCluster,
    handlePointsClusterChange,
    handleSelectedPeriodChange,
  } = usePositivityIndex();

  if (!fieldResultsList || fieldResultsList.length === 0) return null;

  return (
    <Card className="mb-4">
      <CardHeader
        headerText={`Mapa de armadilhas`}
        showExpandButton={false}
        showDownloadDataButton={false}
        showDownloadImageButton={false}
      >
        <div style={{ width: "130px" }} id="filtersPopover">
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            value={selectedPeriod}
            options={periodsOptions}
            onChange={(e) => handleSelectedPeriodChange(e)}
          />
        </div>
        <Button
          color="primary"
          data-tip
          data-for="dataDownloadButton"
          style={{ width: "55px", marginRight: "0px" }}
        >
          <i className="fa fa-download" />
        </Button>
        <ReactTooltip effect="solid" type="info" id="dataDownloadButton">
          Baixar dados da semana
        </ReactTooltip>
      </CardHeader>
      <CardContent>
        <MapComponent style={{ height: "24.5rem" }}>
          <MapPointsLayersControl
            estimateObjectName={estimateObjectName}
            selectedPeriod={selectedPeriod}
            removePointsCluster={removePointsCluster}
          />
          <MapPolygonsLayersControl />
          <Legend
            selectedPeriod={selectedPeriod}
            estimateObjectName={estimateObjectName}
          />
          <RangeMapTrapTools
            handlePointsClusterChange={handlePointsClusterChange}
            removePointsCluster={removePointsCluster}
          />
        </MapComponent>
      </CardContent>
      <CardFooter>
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  );
};

const useMapPointsLayersControl = () => {
  const { positivityIndexGroupPointsList, setPositivityIndexGroupPointsList } =
    usePositivityIndexMap();
  const { appliedFilters } = useAppliedFilters();
  const { fieldResultsList } = useGraphicsData();
  const { trapsList } = useTrapsList();
  const { employeesList } = useEmployeesList();

  React.useEffect(() => {
    if (
      !fieldResultsList ||
      fieldResultsList.length === 0 ||
      !trapsList ||
      trapsList.length === 0
    )
      return;

    const positivePointsByWeek = groupPositiveTrapsPointsEstimatesByWeek(
      fieldResultsList,
      trapsList,
      appliedFilters.trapType.trapTypeName,
      employeesList
    );

    setPositivityIndexGroupPointsList(positivePointsByWeek);
  }, [fieldResultsList, trapsList]);

  const groupPositiveTrapsPointsEstimatesByWeek = (
    fieldResultsList,
    trapsList,
    trapTypeName,
    employeesList
  ) => {
    let aedesAegyptiPointsEstimatesByWeek = [];
    let eggPointsEstimatesByWeek = [];
    let exuviaPointsEstimatesByWeek = [];
    let larvaOrPupaPointsEstimatesByWeek = [];

    if (!fieldResultsList || fieldResultsList.length === 0) {
      return {
        aedesAegyptiPointsEstimatesByWeek: [],
        eggPointsEstimatesByWeek: [],
        exuviaPointsEstimatesByWeek: [],
        larvaOrPupaPointsEstimatesByWeek: [],
      };
    }

    const unitedTerritorializationsPeriodsData = new Map();

    fieldResultsList.forEach(({ periods }) => {
      periods.forEach(
        ({
          periodName,
          diagnosedTraps,
          negativeTrapsForEggsLarvaesPupasOrMosquitoes,
          positiveTraps,
        }) => {
          let periodData = unitedTerritorializationsPeriodsData.get(periodName);

          if (!periodData)
            unitedTerritorializationsPeriodsData.set(periodName, {
              diagnosedTraps: 0,
              negativeTrapsForEggsLarvaesPupasOrMosquitoes: [],
              periodName,
              positiveTraps: {
                forAedesAegypti: [],
                forEgg: [],
                forExuvia: [],
                forLarvaOrPupa: [],
              },
            });

          periodData = unitedTerritorializationsPeriodsData.get(periodName);

          periodData.diagnosedTraps += diagnosedTraps;
          periodData.negativeTrapsForEggsLarvaesPupasOrMosquitoes.push(
            ...negativeTrapsForEggsLarvaesPupasOrMosquitoes
          );
          periodData.positiveTraps.forAedesAegypti.push(
            ...positiveTraps.forAedesAegypti
          );
          periodData.positiveTraps.forEgg.push(...positiveTraps.forEgg);
          periodData.positiveTraps.forExuvia.push(...positiveTraps.forExuvia);
          periodData.positiveTraps.forLarvaOrPupa.push(
            ...positiveTraps.forLarvaOrPupa
          );
        }
      );
    });

    unitedTerritorializationsPeriodsData.forEach(
      ({ periodName, positiveTraps }) => {
        if (trapTypeName === "armadilhaDisseminadoraInseticida") {
          fillInPointsEstimatesByWeek(
            "Larvas",
            "forLarvaOrPupa",
            larvaOrPupaPointsEstimatesByWeek,
            positiveTraps,
            periodName,
            trapsList
          );

          fillInPointsEstimatesByWeek(
            "Exuvia",
            "forExuvia",
            exuviaPointsEstimatesByWeek,
            positiveTraps,
            periodName,
            trapsList
          );
        }

        if (
          trapTypeName === "armadilhaMosquitoAdulto" ||
          trapTypeName === "armadilhaMosquitoAdulto2"
        ) {
          fillInPointsEstimatesByWeek(
            "Larvas",
            "forLarvaOrPupa",
            larvaOrPupaPointsEstimatesByWeek,
            positiveTraps,
            periodName,
            trapsList
          );

          fillInPointsEstimatesByWeek(
            "Aedes Aegypti",
            "forAedesAegypti",
            aedesAegyptiPointsEstimatesByWeek,
            positiveTraps,
            periodName,
            trapsList
          );
        }

        if (trapTypeName === "armadilhaOvos") {
          fillInPointsEstimatesByWeek(
            "Larvas",
            "forLarvaOrPupa",
            larvaOrPupaPointsEstimatesByWeek,
            positiveTraps,
            periodName,
            trapsList
          );

          fillInPointsEstimatesByWeek(
            "Exuvia",
            "forExuvia",
            exuviaPointsEstimatesByWeek,
            positiveTraps,
            periodName,
            trapsList
          );

          fillInPointsEstimatesByWeek(
            "Ovos",
            "forEgg",
            eggPointsEstimatesByWeek,
            positiveTraps,
            periodName,
            trapsList
          );
        }
      }
    );

    return {
      aedesAegyptiPointsEstimatesByWeek: Object.entries(
        aedesAegyptiPointsEstimatesByWeek
      ).map(([week, ranges]) => {
        const pointsGroups = Object.entries(ranges).map(
          ([rangeName, points]) => ({
            name: `Positivas - ${rangeName}`,
            points: points,
          })
        );

        return {
          label: week,
          pointsGroups: pointsGroups,
        };
      }),
      eggPointsEstimatesByWeek: Object.entries(eggPointsEstimatesByWeek).map(
        ([week, ranges]) => {
          const pointsGroups = Object.entries(ranges).map(
            ([rangeName, points]) => ({
              name: `Positivas - ${rangeName}`,
              points: points,
            })
          );

          return {
            label: week,
            pointsGroups: pointsGroups,
          };
        }
      ),
      exuviaPointsEstimatesByWeek: Object.entries(
        exuviaPointsEstimatesByWeek
      ).map(([week, ranges]) => {
        const pointsGroups = Object.entries(ranges).map(
          ([rangeName, points]) => ({
            name: `Positivas - ${rangeName}`,
            points: points,
          })
        );

        return {
          label: week,
          pointsGroups: pointsGroups,
        };
      }),
      larvaOrPupaPointsEstimatesByWeek: Object.entries(
        larvaOrPupaPointsEstimatesByWeek
      ).map(([week, ranges]) => {
        const pointsGroups = Object.entries(ranges).map(
          ([rangeName, points]) => {
            return {
              name: `Positivas - ${rangeName}`,
              points: points,
            };
          }
        );

        return {
          label: week,
          pointsGroups: pointsGroups,
        };
      }),
    };
  };

  const fillInPointsEstimatesByWeek = (
    estimateTypeName,
    forWich,
    objectToFill,
    trapsList,
    label,
    traps
  ) => {
    const estimateNameIconColor = {
      "1 a 20": "green",
      "21 a 100": "yellow",
      "Mais de 100": "red",
      "1 a 100": "green",
      "101 a 200": "yellow",
      "Mais de 200": "red",
      1: "green",
      "2 a 3": "yellow",
      "Mais de 3": "red",
    };

    if (forWich === "forLarvaOrPupa" || forWich === "forExuvia") {
      if (!objectToFill[label]) objectToFill[label] = {};

      objectToFill[label]["1 a 20"] = [];
      objectToFill[label]["21 a 100"] = [];
      objectToFill[label]["Mais de 100"] = [];
    }

    if (forWich === "forEgg") {
      if (!objectToFill[label]) objectToFill[label] = {};

      objectToFill[label]["1 a 100"] = [];
      objectToFill[label]["101 a 200"] = [];
      objectToFill[label]["Mais de 200"] = [];
    }

    if (forWich === "forAedesAegypti") {
      if (!objectToFill[label]) objectToFill[label] = {};

      objectToFill[label]["1"] = [];
      objectToFill[label]["2 a 3"] = [];
      objectToFill[label]["Mais de 3"] = [];
    }

    if (trapsList[forWich].length === 0)
      return;

    trapsList[forWich].forEach(
      ({
        estimatives,
        latitude,
        longitude,
        number,
        visitDate,
        territorializationName,
        trapTypeName,
        collectorId,
      }) => {
        if (!objectToFill[label]) objectToFill[label] = {};

        const estimate = estimatives.find(
          ({ type }) => type === estimateTypeName
        );

        if (!objectToFill[label][estimate.name])
          objectToFill[label][estimate.name] = [];

        const trapData = traps.find((trap) => trap.number === number);
        const userData = employeesList.find(
          ({ employeeId, userId }) =>
            collectorId === userId || employeeId === collectorId
        );

        const trapPoint = {
          userResponsableForVisitName: !userData
            ? "Não encontrado"
            : userData.employeeName,
          trapTypeName: ARMADILHA_INFO[trapTypeName].apelido,
          territorializationName,
          visitDate: moment(visitDate)
            .add(3, "hours")
            .format("DD/MM/YYYY HH:mm"),
          address: !trapData ? "Não encontrado" : trapData.address,
          propertyType: !trapData ? "Não encontrado" : trapData.propertyType,
          lastInstallDate: !trapData
            ? "Não encontrado"
            : moment(trapData.lastInstallHistoryByStatusDate.date).format(
                "DD/MM/YYYY HH:mm"
              ),
          lastReadDate:
            !trapData || !trapData.lastReadHistoryByStatusDate
              ? "Não encontrado"
              : moment(trapData.lastReadHistoryByStatusDate.date).format(
                  "DD/MM/YYYY HH:mm"
                ),
          number,
          latitude,
          longitude,
          estimate: estimate.name,
          icon: Leaflet.icon({
            iconUrl:
              ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].icons[
                estimateNameIconColor[estimate.name]
              ],
            iconSize: [16, 16],
          }),
        };

        objectToFill[label][estimate.name].push(trapPoint);
      }
    );

    return objectToFill;
  };

  return {
    positivityIndexGroupPointsList,
  };
};

const RangeMapTrapTools = ({
  removePointsCluster,
  handlePointsClusterChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  /* const [organizationId] = React.useState(getUserData("organizationId"));
  const { appliedFilters } = useAppliedFilters(); */
  /* const {
    hadleTrapTypesRangesRadiusChange,
    trapTypesRangesRadius,
    setTrapsListToShowRange,
  } = useMapOperatingRanges(); */

  return (
    <Control
      position="bottomright"
      className="leaflet-control-layers leaflet-control"
    >
      <Button onClick={() => setIsOpen((previousValue) => !previousValue)}>
        <i className="fa fa-cog fa-2x"></i>
      </Button>

      {isOpen && (
        <section style={{ padding: "10px" }}>
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
          >
            <input
              type="checkbox"
              id="trapClusters"
              checked={removePointsCluster}
              onChange={() => handlePointsClusterChange()}
            />
            <label htmlFor="trapClusters" className="h4 mb-0">
              Desagrupar armadilhas
            </label>
          </div>
          {/* <div>
            <label className="h4 mb-0">Raios de atuação</label>
            <ul style={{ listStyle: "none", padding: "0" }}>
              {Object.keys(trapTypesRangesRadius).map((trapTypeName) => {
                return (
                  <li style={{ padding: "0 1rem" }}>
                    <div>
                      <label className="h5" htmlFor={`${trapTypeName}Range`}>
                        {ARMADILHA_INFO[trapTypeName].apelido}:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {trapTypesRangesRadius[trapTypeName]} metros
                        </span>
                      </label>
                    </div>
                    <input
                      type="range"
                      name={`${trapTypeName}Range`}
                      id={`${trapTypeName}Range`}
                      style={{ width: "100%" }}
                      min={1}
                      max={1000}
                      value={trapTypesRangesRadius[trapTypeName]}
                      onChange={({ target: { value } }) =>
                        hadleTrapTypesRangesRadiusChange(trapTypeName, value)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <Button color="primary" onClick={() => setTrapsListToShowRange([])}>
              Desabilitar raios
            </Button>
          </div> */}
        </section>
      )}
    </Control>
  );
};

const MapPointsLayersControl = ({
  estimateObjectName,
  selectedPeriod,
  removePointsCluster,
}) => {
  const { positivityIndexGroupPointsList } = useMapPointsLayersControl();

  if (
    !selectedPeriod ||
    !positivityIndexGroupPointsList ||
    !positivityIndexGroupPointsList[estimateObjectName] ||
    !positivityIndexGroupPointsList[estimateObjectName].find(
      ({ label }) => label === selectedPeriod.label
    )
  )
    return null;

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Armadilhas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {positivityIndexGroupPointsList[estimateObjectName]
        .find(({ label }) => label === selectedPeriod.label)
        .pointsGroups.map(({ name, points }) => {
          return (
            <Overlay
              key={`${name}-overlay-removePointsCluster-${removePointsCluster}-${selectedPeriod.label}`}
              name={name}
            >
              {removePointsCluster ? (
                <MarkerClusterGroup
                  key={`${moment()}-removePointsCluster-${removePointsCluster}`}
                  removeOutsideVisibleBounds={true}
                  disableClusteringAtZoom={1}
                >
                  {points &&
                    points.length > 0 &&
                    points.map(
                      (
                        {
                          latitude,
                          longitude,
                          number,
                          estimate,
                          icon,
                          propertyType,
                          address,
                          lastInstallDate,
                          lastReadDate,
                          visitDate,
                        },
                        index
                      ) => {
                        return (
                          <Marker
                            key={`${number}-${index}-marker`}
                            position={[latitude, longitude]}
                            icon={icon}
                          >
                            <Popup>
                              <span style={{ display: "block" }}>
                                <span className="h4">Armadilha Nº:</span>{" "}
                                {number}
                              </span>
                              <span style={{ display: "block" }}>
                                <span className="h4">Positiva para:</span>{" "}
                                {estimate}
                              </span>
                              <span style={{ display: "block" }}>
                                <span className="h4">
                                  Data da visita que positivou:
                                </span>{" "}
                                {visitDate}
                              </span>
                              <span style={{ display: "block" }}>
                                <span className="h4">Endereço:</span> {address}
                              </span>
                              <span style={{ display: "block" }}>
                                <span className="h4">Tipo de imóvel:</span>{" "}
                                {propertyType}
                              </span>
                              <span style={{ display: "block" }}>
                                <span className="h4">Última instalação:</span>{" "}
                                {lastInstallDate}
                              </span>
                              <span style={{ display: "block" }}>
                                <span className="h4">Última manutenção:</span>{" "}
                                {lastReadDate}
                              </span>
                            </Popup>
                          </Marker>
                        );
                      }
                    )}
                </MarkerClusterGroup>
              ) : (
                <MarkerClusterGroup
                  key={`${moment()}-${removePointsCluster}`}
                  removeOutsideVisibleBounds={true}
                >
                  {points &&
                    points.length > 0 &&
                    points.map(
                      (
                        {
                          latitude,
                          longitude,
                          number,
                          estimate,
                          icon,
                          propertyType,
                          address,
                          lastInstallDate,
                          lastReadDate,
                          visitDate,
                          territorializationName,
                          trapTypeName,
                          userResponsableForVisitName,
                        },
                        index
                      ) => {
                        return (
                          <Marker
                            key={`${number}-${index}-marker`}
                            position={[latitude, longitude]}
                            icon={icon}
                          >
                            <Popup>
                              <div style={{ fontSize: "12px" }}>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Tipo da armadilha:
                                  </span>{" "}
                                  {trapTypeName}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Armadilha Nº:
                                  </span>{" "}
                                  {number}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Tipo de imóvel:
                                  </span>{" "}
                                  {propertyType}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Área:
                                  </span>{" "}
                                  {territorializationName}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Endereço:
                                  </span>{" "}
                                  {address}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Responsável pela visita:
                                  </span>{" "}
                                  {userResponsableForVisitName}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Positiva para:
                                  </span>{" "}
                                  {estimate}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Data da visita que positivou:
                                  </span>{" "}
                                  {visitDate}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Data da última instalação:
                                  </span>{" "}
                                  {lastInstallDate}
                                </span>
                                <span style={{ display: "block" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Data da última manutenção:
                                  </span>{" "}
                                  {lastReadDate}
                                </span>
                              </div>
                            </Popup>
                          </Marker>
                        );
                      }
                    )}
                </MarkerClusterGroup>
              )}
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const Legend = ({ estimateObjectName, selectedPeriod }) => {
  const { appliedFilters } = useAppliedFilters();
  const { positivityIndexGroupPointsList } = useMapPointsLayersControl();

  return (
    <Control position={"bottomright"}>
      <div
        style={{
          maxWidth: 250,
          maxHeight: 300,
          backgroundColor: "#fff",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {!selectedPeriod ||
        !positivityIndexGroupPointsList ||
        !positivityIndexGroupPointsList[estimateObjectName] ? null : (
          <div style={{ width: "100%", textAlign: "center" }}>
            <span className="h4" style={{ fontSize: "0.75rem" }}>
              <span>
                Dados{" "}
                {appliedFilters.datePeriodType === "week" ? (
                  <span>da</span>
                ) : (
                  <span>de</span>
                )}{" "}
                {selectedPeriod.label}/{appliedFilters.year.value}
                {positivityIndexGroupPointsList &&
                  positivityIndexGroupPointsList[estimateObjectName] &&
                  positivityIndexGroupPointsList[estimateObjectName].find(
                    ({ label }) => label === selectedPeriod.label
                  ) &&
                  positivityIndexGroupPointsList[estimateObjectName].find(
                    ({ label }) => label === selectedPeriod.label
                  ).pointsGroups &&
                  positivityIndexGroupPointsList[estimateObjectName]
                    .find(({ label }) => label === selectedPeriod.label)
                    .pointsGroups.map(({ name, points }) => {
                      return (
                        <span style={{ display: "block", fontSize: "0.75rem" }}>
                          {name}: {points.length}
                        </span>
                      );
                    })}
              </span>
            </span>
          </div>
        )}
      </div>
    </Control>
  );
};

const MapPolygonsLayersControl = () => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  const { territorializationsList } = useTerritorializationsList();

  React.useEffect(() => {
    if (!territorializationsList || territorializationsList.length === 0)
      return;

    const typeLayersPolygonsGroup = [];

    territorializationsList.forEach(({ name, territorializations }) => {
      const typeLayerData = {
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [territorializationsList]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Delimitadores de Áreas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons }, index) => {
          return (
            <Overlay name={name} key={`${name}-overlay-${index}`}>
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-feature-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map((polygon, index) => {
                    const { geometry, name } = polygon;

                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={`${name}-polygon-${index}`}
                        color={"#000000"}
                        fillColor={"transparent"}
                        opacity={1}
                        positions={coordinates}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

export default PositivityIndexMap;
