import React from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components

import {
  Card,
  CardHeader,
  Container,
  CardBody,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import "../../../../assets/css/style.css";
import { getStocables,getLots, getInventoryPlaces, getInventoryItens   } from "../../../../services/api/Itens";
import { postMovementRecord } from "../../../../services/api/InventoryMovementRecord.js";
import { fetchEmployees } from "../../../../services/api/Employee";
import { getUserData } from "../../../../services/utils/auth";
import { checkPermissionComponent } from "../../../../services/utils/permission";
import "./InventoryMovement.css";
import checkQuantity from "../../../../services/utils/checkQuantityInventory.js";
import {checkShowBiovec} from "../../../../services/utils/Inventory/checkShowBiovec.js";
import { sortInventoryPlaces } from "../../../../services/utils/Inventory/checkShowBiovec.js";
import { filterInventoryPlaces } from "../../../../services/utils/Inventory/checkShowBiovec.js";
import './AddInventoryMovementRecord.css';

class AddInventoryItem extends React.Component {
  constructor (props) {
    super(props);
    /*if(!checkPermissionComponent("217c876b-5f50-4bbf-bce4-325b83ea7787", "create")){
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack(); 
    }*/
    this.state = {
        organizationId: getUserData('organizationId'),
        EmployeeId :"",
        showBiovec: false,
        ReturnDate: new Date().toISOString().split('T')[0],
        Returned: 0,
        UserId:getUserData('userId'),
        Stocables:[{
            StocableId : "",
            Lot : "Nenhum",
            hasExpiration:false,
            Quantity : 0,
            newLot:false,
            Expiration:null
        }]

    };
    this.handleChange = this.handleChange.bind(this);
    

  }


  componentDidMount() {
    var showBiovec = checkShowBiovec();
    this.setState({showBiovec})

    var requestInventoryPlaces = getInventoryPlaces();
    requestInventoryPlaces.then(response => {
        this.setState({inventoryPlacesList: response.data})
      })

    var requestStocables = getStocables();
    requestStocables.then(response => {
            this.setState({stocableList: response.data});
        })
    var requestEmployee = fetchEmployees();
    requestEmployee.then(response => {
        this.setState({employees: response.data});
    })

    var requestLots = getLots();
    requestLots.then(response => {
      this.setState({lots: response.data});
    })

    const requestInventory = getInventoryItens();
    requestInventory.then(response => {
          if(response) {
              return response.data
          }
          throw new Error("Erro ao contatar servidor");
      }).then(async data => {
        this.setState({inventoryItens : data});
      })
    
  }


  
 
  handleChange(event) {
    this.setState({[event.target.name]  : event.target.value });
  };



  createNewMovement = async () => {
    let errors = false;
    var status = "Pending";
    var Returned = true;
    var PlaceId = this.state.PlaceId;
    var OriginPlaceId = this.state.OriginPlaceId;

      
      if(this.state.Returned == 0) {
        Returned = false;
        if(await checkQuantity(this.state, this.state.inventoryPlacesList, this.state.stocableList, this.state.inventoryItens) == false) {
          return;
        } 
      } 
      if(!this.state.EmployeeId){
        this.state.EmployeeId = "00000000-0000-0000-0000-000000000000"
      }

      if(this.state.Returned != 2 && (!OriginPlaceId || OriginPlaceId == "00000000-0000-0000-0000-000000000000")){
        toast.error("Erro: é necessário selecionar um local de destino!");
        errors = true;
      }

      if(!PlaceId || PlaceId == "Nenhum" || PlaceId == "" || PlaceId == "00000000-0000-0000-0000-000000000000"){
        toast.error("Erro: é necessário selecionar um local de armazenamento!");
        errors = true;
      }

      if(!OriginPlaceId || (OriginPlaceId && (OriginPlaceId == "Nenhum" || OriginPlaceId == ""))) {
        OriginPlaceId = "00000000-0000-0000-0000-000000000000";
      }

      console.table({
        OriginPlaceId: OriginPlaceId,
        PlaceId: PlaceId
      })

      if(PlaceId == OriginPlaceId){
        toast.error("Erro: não é possível que o local de destino seja o mesmo que o de armazenamento!");
        errors = true;
      }

      this.state.PlaceId = PlaceId;
      this.state.OriginPlaceId = OriginPlaceId;

      this.state.Stocables.map((item)=>{
        if(item.hasExpiration && !item.Expiration) {
          toast.error("Erro: é necessário preencher uma data de validade!");
          errors = true;
        }
        if(item.newLot && !item.Lot) {
          toast.error("Erro: é necessário preencher um lote!");
          errors = true;
        }
        if(item.Lot == "" || item.Lot == "Nenhum") {
          item.Lot = "Nenhum"
        }
        if(!item.StocableId){
          toast.error("Erro: é necessário selecionar um estocável!");
          errors = true;
        }
        if(item.Quantity < 0){
            toast.error("Erro: é necessário usar uma quantidade válida!");
            errors = true;
        }
      })

      console.log("this.state", this.state)

    if(errors == false){
      var record = {...this.state, status}
      delete record.Returned;

      record.Returned = Returned;

      const requestMovementRecord = postMovementRecord(record);

      requestMovementRecord.then(response => {
        if(response) {
            if (response.data.id) {
                toast.success("Movimento cadastrado com sucesso!");
              } else {
                toast.error("Não foi possível cadastrar o movimento");
              }     
        }
    
    })
    }
  }
  handleChangeStocables(index, element){
    const target = element.target;
      let stocables = this.state.Stocables;
      if(element.target.name === "Quantity"){
        stocables[index][element.target.name] = parseInt(element.target.value,10);
      }else{
        const value = target.type === 'checkbox' ? target.checked : target.value;
        stocables[index][element.target.name] = value;
      }

      const name = target.name;
      if(name === "NameId"||name ==='newLot'){
        stocables[index]["Lot"] = "";
        this.setState({Lot:stocables});
      }

      this.setState({Stocables:stocables});
    }
  addStocable(){
    let stocables = this.state.Stocables;
    stocables.push({
      StocableId : "",
      Quantity : 0,
      Lot:"",
      hasExpiration:false,
      newLot:false,
      Expiration:null
    })

    this.setState({Stocables: stocables});
  }

  removeStocable = (stocableIndex = 0) => {
    var stocables = [...this.state.Stocables]; // Cópia do array para evitar mutação direta
    
    console.log("stocableIndex stocables", stocableIndex, stocables)

    if (stocableIndex >= 0 && stocableIndex < stocables.length) {
        stocables.splice(stocableIndex, 1); // Remove o elemento no índice especificado
    } else {
        console.warn("Índice inválido para remoção."); // Aviso no console se o índice for inválido
    }

    this.setState({ Stocables: stocables }); // Atualiza o estado com o novo array
};

  render() {

    return (
      <>
        <Header />

        <ToastContainer />

        <Container className="" fluid>
          <Row className="mt-12">
            <Col className="mb-12 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        <Link to="/admin/InventoryMovementRecord"> <i className=" ni ni-bold-left bnt-voltar" /></Link>
                        Novo Movimento</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Container fluid>
                  <div className="header-body">

                    <div className="row linha-form">
                      <div className="col-4 responsive-form-field-inventoryMovement-full">
                        <span className="h5 text-black">Tipo de Movimento</span>
                        <select className="form-control" name="Returned" onChange={this.handleChange}>
                          <option value={0}>Saída</option>                       
                          <option value={2}>Entrada</option>
                        </select>
                      </div>
                      <div className="col-4 responsive-form-field-inventoryMovement-full">
                          <span className="h5 text-black">Data da movimentação</span>
                          <input
                              className="form-control"
                              type="date"
                              name="ReturnDate"
                              value={this.state.ReturnDate}
                              onChange={(e) => {
                                  const { name, value } = e.target;

                                  console.log("name, value", name, value)
                                  this.setState({
                                      [name]: value, 
                                  });
                              }}
                          />
                      </div>
                      <div className="col-4 responsive-form-field-inventoryMovement-full">
                        <span className="h5 text-black">Local de armazenamento {this.state.Returned > 0 ? "(Entrada)" : "(Saída)"}</span>
                        <select className="form-control" name="inventoryPlacesList" value={this.state.PlaceId} onChange={e => {
                          if(e.target.value == "Nenhum") {
                            this.setState({PlaceId: null})
                          } else {
                            this.setState({PlaceId: e.target.value})
                          }
                          var array = [];
                        }}>
                          <option>Nenhum</option>
                          {this.state.inventoryPlacesList!=null? filterInventoryPlaces(sortInventoryPlaces(this.state.inventoryPlacesList), this.state.showBiovec):""}                           
                        </select>
                      </div>
                      
                        {
                          this.state.Returned != 2
                          ?
                            <div className="col-4 responsive-form-field-inventoryMovement-full">
                              <span className="h5 text-black">Local de {this.state.Returned == 1 ? "origem (Saída)" : "destino (Entrada)"}</span>
                              <select className="form-control" name="inventoryOriginPlacesList" value={this.state.OriginPlaceId} onChange={e => {
                                if(e.target.value == "Nenhum") {
                                  this.setState({OriginPlaceId: null})
                                } else {
                                  this.setState({OriginPlaceId: e.target.value})
                                }
                              }}>
                                <option>Nenhum</option>
                                  {this.state.inventoryPlacesList!=null? filterInventoryPlaces(sortInventoryPlaces(this.state.inventoryPlacesList), this.state.showBiovec):""}                           
                              </select>
                            </div>
                          :
                            <></>
                        }
                      
                      <div className="col-4 responsive-form-field-inventoryMovement-full">
                        <span className="h5 text-black">Funcionário</span>
                        <select className="form-control" name="EmployeeId" onChange={this.handleChange}>
                          <option value="">Nenhum</option>
                          {this.state.employees != null ? 
                            this.state.employees.sort((a, b) => a.name.localeCompare(b.name)).map((employee) => {
                                return (
                                    <option key={employee.id} value={employee.id}>{employee.name}</option>
                                );
                            })
                          : ""}
                        </select>
                      </div>
                    </div>
                    
                    <CardBody>
                      <span className="h5 text-black ">Estocavéis</span>
                      {this.state.Stocables.map((stocable, stocableIndex) => {
                        return (
                          <div className={this.state.Stocables.length > 1 ? "container-stocable" : ""}>
                            {this.state.Stocables.length > 1 ?
                              <button className="content-icon-stocable btn btn-warning" onClick={() => this.removeStocable(stocableIndex)}>
                                  <i className='fa fa-trash'></i>
                              </button>
                            :null}
                            <div>
                              <div className="row linha-form">
                                  <div className="col-4 responsive-form-field-inventoryMovement-full">
                                    <span className="h5 text-black">Nome</span>
                                    <select className="form-control" name="StocableId" onChange={e => this.handleChangeStocables(stocableIndex, e)}>
                                      <option>Nenhum</option>
                                        {this.state.stocableList!=null?this.state.stocableList.map((item) => {
                                            return (
                                                <option value={item.id}>{item.name}</option>
                                            )
                                        }):""}                           
                                    </select>
                                  </div>
                                  <div className="col-4 responsive-form-field-inventoryMovement-full center-div">
                                    {
                                      this.state.Returned == 2
                                      ?
                                      <>
                                        <input
                                          name="newLot"
                                          type="checkbox"
                                          onChange={e => this.handleChangeStocables(stocableIndex, e)} />Novo lote?
                                      </>
                                      :
                                      <></>
                                    }
                                    
                                  </div>
                                  {this.state.lots != null && !stocable.newLot ? (
                                      <>
                                        {this.state.lots.map((lotList) => {
                                          return (
                                            <>
                                              {lotList.stocableId === stocable.StocableId && (
                                                <>
                                                  <div className="col-4 responsive-form-field-inventoryMovement-full">
                                                    <span className="h5 text-black">Lote*</span>
                                                    <select
                                                      className="form-control"
                                                      name="Lot"
                                                      defaultValue="Nenhum"
                                                      onChange={(e) => this.handleChangeStocables(stocableIndex, e)}
                                                    >
                                                      {
                                                        Array.from(new Set(lotList.lotList)) != null && !Array.from(new Set(lotList.lotList)).some(lote => lote == "Nenhum")
                                                        ?
                                                        <option value="Nenhum">Nenhum</option>
                                                        :
                                                        <></>
                                                      }
                                                      {Array.from(new Set(lotList.lotList)).map((lote, index) => (
                                                        <option key={index} value={lote}>
                                                          {lote}
                                                        </option>
                                                      ))}
                                                    </select>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                      </>
                                  ) : (
                                    ""
                                  )}  
                                  {stocable.newLot?<>
                                    <div className="col-4 responsive-form-field-inventoryMovement-full">
                                      <span className="h5 text-black">Lote*</span>
                                      <input type="text" className="form-control" placeholder="Lote" name="Lot" value={stocable.Lot} onChange={e => this.handleChangeStocables(stocableIndex, e)} />
                                    </div>
                                  </>:""}
                              </div>
                              <div className="row linha-form">
                              
                                    <div className="col-4 responsive-form-field-inventoryMovement-full center-div">
                                      <span className="h5 text-black">Quantidade</span>
                                      <input type="number" className="form-control" placeholder="0" name="Quantity" value={stocable.Quantity} onChange={e => this.handleChangeStocables(stocableIndex, e)} />
                                    </div>
                                    {stocable.newLot?<>
                                      <div className="col-4 responsive-form-field-inventoryMovement-full center-div">
                                      <input
                                        name="hasExpiration"
                                        type="checkbox"
                                        checked={stocable.hasExpiration}
                                        onChange={e => this.handleChangeStocables(stocableIndex, e)} />Tem data de validade?
                                      </div>
                                  {stocable.hasExpiration?<>
                                  <div className="col-4 responsive-form-field-inventoryMovement-full">
                                    <span className="h5 text-black">Data de Validade*</span>
                                    <input type="date" className="form-control" name="Expiration" value={stocable.Expiration} onChange={e => this.handleChangeStocables(stocableIndex, e)} />
                                  </div>
                                  </>:""}
                              </>:""}
                                    
                              </div>

                              {
                                this.state.Returned == 2
                                ?
                                <div className="row linha-form">
                                  <div className="col-4 responsive-form-field-inventoryMovement-full center-div">
                                    <span className="h5 text-black">Nº Ordem de Fornecimento</span>
                                    <input type="text" className="form-control" name="OsNumber" onChange={e => this.handleChangeStocables(stocableIndex, e)} />
                                  </div>
                                </div>
                                :
                                <></>
                              }
                            </div>
                          </div>
                        )
                      })}

                      <div className="row">
                        <div className={this.state.Stocables.length > 1 ? "col-6 responsive-form-field-inventoryMovement-full" : "col-12 responsive-form-field-inventoryMovement-full"}>
                            <button className="btn btn-info" onClick={() => this.addStocable()}>+ Adicionar Estocável</button>
                        </div>
                      </div>
                  </CardBody>
                    <div className="row">
                      <div className="linha-form col-4 responsive-form-field-inventoryMovement-full">
                        <button onClick={this.createNewMovement} className="btn btn-primary">Realizar movimento</button>
                      </div>
                      
                    </div>
                  </div>
                </Container>

              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddInventoryItem;
