import { useContextSelector } from "use-context-selector";
import { TerritorializationsReportContext } from "../Contexts/TerritorializationsReportContext";

export const useTrapsByPeriodsList = () => {
  const trapsByPeriodsList = useContextSelector(
    TerritorializationsReportContext,
    (context) => context.trapsByPeriodsList
  );
  const setTrapsByPeriodsList = useContextSelector(
    TerritorializationsReportContext,
    (context) => context.setTrapsByPeriodsList
  );
  const isTrapsByPeriodsListLoading = useContextSelector(
    TerritorializationsReportContext,
    (context) => context.isTrapsByPeriodsListLoading
  );
  const setIsTrapsByPeriodsListLoading = useContextSelector(
    TerritorializationsReportContext,
    (context) => context.setIsTrapsByPeriodsListLoading
  );

  return {
    trapsByPeriodsList,
    setTrapsByPeriodsList,
    isTrapsByPeriodsListLoading,
    setIsTrapsByPeriodsListLoading
  };
}