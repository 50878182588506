import { useContextSelector } from "use-context-selector";
import { SinanPageContext } from "../Contexts/SinanContext";

export const useNotificationsList = () => {
  const notificationsList = useContextSelector(
    SinanPageContext,
    (context) => context.notificationsList
  );
  const cardsNotificationsList = useContextSelector(
    SinanPageContext,
    (context) => context.cardsNotificationsList
  );
  const isNotificationsListLoading = useContextSelector(
    SinanPageContext,
    (context) => context.isNotificationsListLoading
  );
  const setIsNotificationsListLoading = useContextSelector(
    SinanPageContext,
    (context) => context.setIsNotificationsListLoading
  );
  const setNotificationsList = useContextSelector(
    SinanPageContext,
    (context) => context.setNotificationsList
  );
  const setCardsNotificationsList = useContextSelector(
    SinanPageContext,
    (context) => context.setCardsNotificationsList
  );

  return {
    cardsNotificationsList,
    setCardsNotificationsList,
    isNotificationsListLoading,
    notificationsList,
    setIsNotificationsListLoading,
    setNotificationsList,
  };
};
