import React, { useEffect } from "react"
import LoadingSpin from "react-loading-spin"
import { Card, CardBody, CardHeader } from "reactstrap"

export const InformationBlockAddSampleResult = ({
    sampleNumber, 
    enableEditNumber, 
    sampleRelatedData, 
    firstSample, 
    setSampleNumber, 
    isLoadingSampleInformations,
    setEnableEditNumber
}) => {
    return (
        firstSample
        ?
        <Card style={{"marginTop": "2%", "marginBottom": "2%"}}> 
            <CardHeader style={{"border": "0.1px solid lightgrey"}}>
                <span className="sample-result-text">Detalhes da amostra</span>
            </CardHeader>
            <CardBody style={{"border": "0.1px solid lightgrey"}}>
                <div className="row border-grey" >
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">N. da Amostra: </span>
                    {!enableEditNumber ?
                    <>
                        <span className="sample-result-text">{sampleNumber} </span>
                        <i onClick={() => setEnableEditNumber(true)} className="fa fa-edit sample-result-text"></i>                                        
                    </>
                    : 
                        <input className="form-control" value={sampleNumber} onChange={(e) => {setSampleNumber(e.target.value)}}></input>
                    }
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">N. da OS: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : sampleRelatedData.demand ? sampleRelatedData.demand.serviceOrder : ""}</span>
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Responsável: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : sampleRelatedData.personName}</span>
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Depósito: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : firstSample.deposit}</span>
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Animal: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : firstSample.animal}</span>
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Material Coletado: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : firstSample.typeMaterial}</span>
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Quantidade: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : firstSample.quantity}</span>
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Quantidade de Vivos: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : firstSample.quantityLiveMaterial}</span>
                </div>
                <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Quantidade de Mortos: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : firstSample.quantityDeadMaterial}</span>
                </div>
                
                
                {sampleRelatedData.trap != null?
                <>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">N. da Armadilha: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : sampleRelatedData.trap.number}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                    <span className="sample-result-text">Tipo de armadilha: {isLoadingSampleInformations ? <LoadingSpin primaryColor="#fff" size={12} /> : sampleRelatedData.trap.trapTypeName}</span>
                    </div>
                </>
                :""}
                </div>
            </CardBody>
        </Card>
        : 
        <></>
    )
}