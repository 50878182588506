import React from "react";

import { createContext } from "use-context-selector";
import { DEFAULT_SINAN_PAGE_FILTERS } from "../Filters/SinanPageFilters";

export const SinanPageContext = createContext(null);

export const SinanPageContextProvider = ({ children }) => {
  const [appliedFilters, setAppliedFilters] = React.useState(
    DEFAULT_SINAN_PAGE_FILTERS
  );
  const [notificationsList, setNotificationsList] = React.useState({
    raw: [],
    formated: [],
  });
  const [isNotificationsListLoading, setIsNotificationsListLoading] =
    React.useState(false);
  const [cachedNotificationsList, setCachedNotificationsList] = React.useState(
    []
  );
  const [fieldResultsList, setFieldResultsList] = React.useState([]);
  const [positiveTrapsFromLabList, setPositiveTrapsFromLabList] =
    React.useState([]);
  const [cardsNotificationsList, setCardsNotificationsList] = React.useState(
    []
  );
  const [casesByAreas, setCasesByAreas] = React.useState([]);
  const [sorotypesByAreas, setSorotypesByAreas] = React.useState([]);

  return (
    <SinanPageContext.Provider
      value={{
        appliedFilters,
        cachedNotificationsList,
        isNotificationsListLoading,
        notificationsList,
        fieldResultsList,
        positiveTrapsFromLabList,
        cardsNotificationsList,
        casesByAreas,
        sorotypesByAreas,
        setCasesByAreas,
        setSorotypesByAreas,
        setAppliedFilters,
        setCachedNotificationsList,
        setIsNotificationsListLoading,
        setNotificationsList,
        setFieldResultsList,
        setPositiveTrapsFromLabList,
        setCardsNotificationsList,
      }}
    >
      {children}
    </SinanPageContext.Provider>
  );
};
