import React from "react";
import { getUserData } from "../../../../services/utils/auth";
import { fetchTerritorializations } from "../../../../services/api/territorialization";
import { Select } from "../../../../components/Wrappers/SelectAll";

export const ExtraFieldFilter = ({filtersValues, handleFiltersChange, selectStyles}) => {
    const [extraFieldLabel, setExtraFieldLabel] = React.useState("");
    const [organizationId, setOrganizationId] = React.useState();
    const [activeField, setActiveField] = React.useState(false);
    const [extraFieldList, setExtraFieldList] = React.useState(false);

    React.useEffect(() => {
        var organizationId = getUserData("organizationId");
        setOrganizationId(organizationId);

        if(organizationId == '559a16ab-5da8-41ba-96b8-6e9f55feddd8') {
            setExtraFieldLabel("UVIS");
            setActiveField(true);
            fetchTerritorializations('553b3f0a-7e72-4e07-b170-ad042f595bc2').then((response) => {
                setExtraFieldList(response.data.map((terr) => {return  {
                    label: terr.name,
                    value: terr.name
                }}));
            })
        } 
    }, [])

    return (
        <>
            {
                organizationId && activeField
                ?
                    <>
                        <span className="h4 text-white">{extraFieldLabel}</span>
                        <Select
                            styles={selectStyles}
                            placeholder={"Selecione"}
                            isClearable={true}
                            options={extraFieldList}
                            value={filtersValues.extraField1 ? filtersValues.extraField1 : ""}
                            isMulti={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            onChange={(e) =>
                                handleFiltersChange(
                                "extraField1",
                                !e
                                    ? {
                                        label: "Todas",
                                        value: null,
                                    }
                                    : e
                                )
                            }
                            menuPortalTarget={document.body}
                        />
                    </>
                :
                <></>
            }
        </>
    );
}