export function loadInformationsByTerritorializations({
    chartDataSets, 
    selectedTerritorializations, 
    setGraphicInformations = null,
    years = null, 
    type, 
    informationsToPull = null
}){
    if(chartDataSets) {
        var chartDataSetsProcessed = [];
        chartDataSets.map(item => {
            if(!selectedTerritorializations || (selectedTerritorializations && selectedTerritorializations.length == 0)) {  
                var graphicInformations  = [];    
                              
                chartDataSetsProcessedObj = {
                    label: item.label,
                }
                
                if(years) {
                    years.map((year) => {
                        graphicInformations.push({
                            key: year,
                            checked: true,
                            ano: year
                        })
                        chartDataSetsProcessedObj[year] = item.Todas[year];
                    })
                } else {
                    graphicInformations.push({
                        key: type,
                        checked: true
                    })
                    chartDataSetsProcessedObj[type] = item.Todas[type]
                }
    
                if(informationsToPull) {
                    informationsToPull.map((information) => {
                        chartDataSetsProcessedObj[`${information}`] = item.Todas[information];
                    })
                }

                chartDataSetsProcessed.push(chartDataSetsProcessedObj);
                console.log("graphicInformations 1", graphicInformations)
                setGraphicInformations && setGraphicInformations(graphicInformations);
            } else {
                var graphicInformations  = [];

                var chartDataSetsProcessedObj = {
                    label: item.label,
                }

                Object.keys(item).filter((key) => key != "label").map((key) => {
                    if(!item[key]) {
                        item[key] = {};
                    }

                    if(years) {
                        years.map((year) => {
                            graphicInformations.push({
                                key: `${year} ${key}`,
                                checked: true,
                                ano: year
                            })

                            chartDataSetsProcessedObj[`${year} ${key}`] = item[key][year];
                        })
                    } else {
                        graphicInformations.push({
                            key: `${type} ${key}`,
                            checked: true
                        })
                        
                        chartDataSetsProcessedObj[`${type} ${key}`] = item[key][type];
                    }
                    
                    if(informationsToPull) {
                        informationsToPull.map((information) => {
                            chartDataSetsProcessedObj[`${information} ${key}`] = item[key][information];
                        })
                    }
                })

                chartDataSetsProcessed.push(chartDataSetsProcessedObj)
                console.log("graphicInformations 2", graphicInformations)
                setGraphicInformations && setGraphicInformations(graphicInformations);
            }

        });

        return chartDataSetsProcessed;
    }
}