import React from "react";
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Surface, Symbols, Tooltip, XAxis, YAxis } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";
import CustomTooltip from "../../../../Utils/CustomTooltip";
import RenderCustomizedLegend, { RenderCustomizedLegend2, RenderCustomizedLegendByTerritorialization } from "../../../../Utils/RenderCustomizedLegend";
import loadGraphicInformations from "../../../../Utils/LoadGraphicInformations";
import { loadInformationsByTerritorializations } from "../../../../../services/utils/Entomologico/GraphsByTerritorialization";



const colors = ["#E60017", "#FC6D0D", "#B10FFF", "#0C28F2", "#D44D13","#29A329","#00C8E5","#FF33CC","#F2E205","#8A2BE2","#FFA500","#DC143C","#7FFF00","#FF4500","#008B8B","#FF1493"];
const EntomologicChartContainer = ({ chartDataSets, data, selectedTerritorializations, typeOfEntomologicChart, informationsToPullToGraph, textCardHeader, maxDomain = 100 }) => {
    const [getpng, { ref }] = useCurrentPng();
    const [chartDataSetsProcessed, setChartDataSetsProcessed] = React.useState();
    const [graphicInformations, setGraphicInformations] = React.useState();
    const [fullScreenMode, setFullScreenMode] = React.useState();
    const [footerContent, setFooterContent] = React.useState("");
    const [hasYear, setHasYear] = React.useState();

    const limitValue = 4;

    const handleIsModalOpen = (value) =>{
        setFullScreenMode(value);
    }

    React.useEffect(() => {
        if(graphicInformations && graphicInformations.length > limitValue) {
            setFooterContent("Os resultados foram limitados para não prejudicar a visualização. Clique no botão 'Expandir' para visualizar o gráfico completo.")
        }
    }, [graphicInformations])

    React.useEffect(() => {
        var hasYear = false;
        var years = [];
        
        if(data) {
            data.map((ano, index) => {
                if(ano.label) {
                    hasYear = true;
                    years.push(ano.label);
                }
            })
            
            setHasYear(hasYear);
        }

        if(chartDataSets) {
            var informationsByTerritorialization = loadInformationsByTerritorializations({
                chartDataSets,
                selectedTerritorializations,
                setGraphicInformations,
                years: hasYear ? years : null,
                type: typeOfEntomologicChart.slice(0, 2),
                informationsToPull: informationsToPullToGraph
            });

            setChartDataSetsProcessed(informationsByTerritorialization);
        }
    }, [chartDataSets,data])

    const buildChartElement = () =>  {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    id={"IpoChartContainerChart"}
                    data={chartDataSetsProcessed}
                    ref={ref}
                    margin={{ top: 30, right: 15 }}
                >
                    <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="16" fontWeight={"bolder"}>{typeOfEntomologicChart} </tspan>
                    </text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" style={{ fontSize: 12 }} />
                    <YAxis style={{ fontSize: 12 }} height={100} tickCount={101} domain={[0, maxDomain]}  />
                    <Tooltip content={<CustomTooltip type={typeOfEntomologicChart}/>} />
                    {
                        graphicInformations && graphicInformations.length > 0
                        ?
                            fullScreenMode
                            ?
                            <Legend 
                                align="center" 
                                verticalAlign="top" 
                                content={() => RenderCustomizedLegendByTerritorialization({
                                    colors: colors, 
                                    type: "line",
                                    fullScreenMode,
                                    setGraphicInformations,
                                    graphicInformations,
                                })}
                            />
                            :
                            <Legend 
                                align="center" 
                                verticalAlign="top" 
                                content={() => RenderCustomizedLegendByTerritorialization({
                                    colors: colors, 
                                    type: "line",
                                    graphicInformations,
                                    setGraphicInformations,
                                    hasLimitOnLegend: true,
                                    limitValue
                                })}
                            />
                        :
                        <Legend 
                            align="center" 
                            verticalAlign="top" 
                        />
                    }
                    
                    <Brush dataKey={"label"} />

                    {
                        graphicInformations && graphicInformations.slice(0, fullScreenMode ? graphicInformations.length : limitValue).map((item, index) => (
                            item.checked
                            ?
                            <Line 
                                key={`${item.key}-${item.checked}`} 
                                dataKey={item.key} 
                                stroke={colors[index]} 
                            />
                            :
                            <></>
                        ))
                    }

                </LineChart>
            </ResponsiveContainer>
        )
    }

    //construindo propriedades do presentational
    const style = {
        cardHeaderText: {
            color: "black",
            fontWeight: "bold",
            fontSize: "0.97rem"
        },
    }

    return (
        <CardPresentational
            CardId={"IpoChartContainer"}
            handleIsModalOpen={handleIsModalOpen}
            footerContent={footerContent}
            style={style}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}

export default EntomologicChartContainer;
