// DiagnosisComponent.js

import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Select from 'react-select';

const DiagnosisResult = ({ data, index, handleChangeDiagnosis, addDiagnosisExam, removeDiagnosisExam, addDiagnosis, removeDiagnosis, handleChangeArrayData, isPossibleDiagnosis }) => {
  return (
    <Card style={{ marginTop: '2%', marginBottom: '2%' }}>
      <CardHeader>
        <span className="h4"><i className='fa fa-diagnoses'></i> Diagnóstico</span>
      </CardHeader>

      <CardBody>
        {data[index].sampleDiagnosis.map((diagnostic, diagnosticIndex) => (
          <div key={diagnosticIndex}>
            <div className="row">
              <div className="col-6 responsive-form-field-result-full">
                <span className="h5">Nome do laboratório credenciado</span>
                <Select
                  options={data[index].Laboratories}
                  key={diagnostic.LaboratoryId}
                  name="ExternLaboratoryId"
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null, index) }}
                  value={data[index].Laboratories.filter(laboratory => laboratory.value == diagnostic.LaboratoryId)}
                />
              </div>
              <div className="col-6 responsive-form-field-result-full">
                <span className="h5">Número da amostra no laboratório externo</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Número amostra"
                  name="externSampleNumber"
                  value={diagnostic.externSampleNumber}
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null, index) }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span className="h5">Descrição de encaminhamento</span>
                <textarea
                  className="form-control"
                  placeholder="Motivo de encaminhamento"
                  name="description"
                  value={diagnostic.description}
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null, index) }}
                />
              </div>
            </div>
            {diagnostic.exams.map((exam, examIndex) => (
              <div className="row" key={examIndex}>
                <div className="col-6 responsive-form-field-result-full">
                  <span className="h5">Exame realizado</span>
                  <select
                    className="form-control"
                    name="examId"
                    defaultValue={exam.examId}
                    onChange={(e) => handleChangeDiagnosis(diagnosticIndex, e, "exams", examIndex, index)}
                  >
                    <option value="">Selecione</option>
                    {data[index].diagnosticsExams.map((diagnosticsExam, id) => (
                      <option key={id} value={diagnosticsExam.id} selected={diagnosticsExam.id === exam.examId}>{diagnosticsExam.name}</option>
                    ))}
                  </select>
                </div>

                <div className="col-6 responsive-form-field-result-full">
                  <span className="h5">Técnica Utilizada</span>
                  <select
                    className="form-control"
                    name="techniqueId"
                    defaultValue={exam.techniqueId}
                    onChange={(e) => handleChangeDiagnosis(diagnosticIndex, e, "exams", examIndex, index)}
                  >
                    <option value="">Selecione</option>
                    {data[index].techniques.map((technique, id) => (
                      <option key={id} value={technique.id} selected={technique.id === exam.techniqueId}>{technique.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            ))}

            <div className="row mt-4 mb-4">
              <div className={diagnostic.exams.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"}>
                <button className="btn btn-info responsive-button-result" onClick={() => addDiagnosisExam(diagnosticIndex, index)}>+ Adicionar Exame e Técnica</button>
              </div>
              {diagnostic.exams.length > 1 &&
                <div className="col-6 responsive-form-field-result-full">
                  <button className="btn btn-warning responsive-button-result" onClick={() => removeDiagnosisExam(diagnosticIndex, index)}><i className='fa fa-trash'></i> Remover Exame e Técnica</button>
                </div>
              }
            </div>

            <div className="row mb-5">
              <div className="col-4 responsive-form-field-result-full">
                <span className="h5 text-black">Diagnóstico</span>
                <select
                  className="form-control"
                  name="diagnostic"
                  value={diagnostic.diagnostic}
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null, index) }}
                >
                  <option value={false}>Selecione</option>
                  <option value={"a4620cb6-85da-4543-a399-12a35ee035a5"}>Aedes aegypti</option>
                </select>
              </div>

              <div className="col-4 responsive-form-field-result-full">
                <span className="h5 text-black">Resultado</span>
                <select
                  className="form-control"
                  name="result"
                  value={diagnostic.result}
                  onChange={(e) => { handleChangeDiagnosis(diagnosticIndex, e, null, null, index) }}
                >
                  <option>Selecione</option>
                  <option>Negativo</option>
                  <option>Positivo</option>
                </select>
              </div>

              <div className="col-4 responsive-form-field-result-full">
                <span className="h5">Tipo de vírus</span>
                <Select
                  key={data[index].selectedVirusType}
                  options={data[index].virusTypes}
                  onChange={(e) => { handleChangeArrayData("selectedVirusType", e !== null ? e.value : "00000000-0000-0000-0000-000000000000", index) }}
                  isClearable={true}
                  value={data[index].selectedVirusType !== '00000000-0000-0000-0000-000000000000' ? data[index].virusTypes.filter(obj => obj.value === data[index].selectedVirusType) : null}
                  isDisabled={data[index].selectedAnimalGenre === '00000000-0000-0000-0000-000000000000'}
                />
              </div>
            </div>

            <div className="row">
              <div className={data[index].sampleDiagnosis.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"}>
                <button className="btn btn-info responsive-button-result" disabled={!isPossibleDiagnosis} onClick={() => addDiagnosis(index)}>+ Adicionar Encaminhamento</button>
              </div>
              {data[index].sampleDiagnosis.length > 1 &&
                <div className="col-6 responsive-form-field-result-full">
                  <button className="btn btn-warning responsive-button-result" onClick={() => removeDiagnosis(index)}><i className='fa fa-trash'></i> Remover Encaminhamento</button>
                </div>
              }
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default DiagnosisResult;
