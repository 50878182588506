import React, { useState, useEffect, useCallback, useMemo } from "react";
import SampleList from "./SampleList";
import { Link } from "react-router-dom";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Button,
} from "reactstrap";
import DataTable from 'react-data-table-component';
import SelectedSampleModal from '../../../views/pages/Sample/SelectedSampleModal.jsx';
import DiscardSampleModal from '../../../views/pages/Sample/DiscardSampleModal.jsx';
import { toast, ToastContainer } from "react-toastify";
import memoize from 'memoize-one';
import ReactTooltip from 'react-tooltip';
import { getPermissionsToken, getUserData } from "../../../services/utils/auth";
import { timeStampFormated } from "../../../services/utils/format";
import { getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { fetchDemands } from "../../../services/api/Demand";
import xlsx from "json-as-xlsx";
import LoadingSpin from "react-loading-spin";
import { fetchSamplesAnalysis, fetchSamplesExcelData } from "../../../services/api/Sample.js";
import { useOrganizationContext } from "../../../contexts/organizationContext.jsx";
import moment from "moment";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant.js";

const SampleTable = ({ Samples, filterParams }) => {
    const [modal, setModal] = useState(false);
    const [modalDiscard, setModalDiscard] = useState(false);
    const [selectedSample, setSelectedSample] = useState(null);
    const [demands, setDemands] = useState({});
    const [employees, setEmployees] = useState({});
    const [isSamplesExcelDataLoading, setIsSamplesExcelDataLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const demandsRequest = await fetchDemands();
            const formattedDemands = demandsRequest.data.reduce((acc, obj) => {
                acc[obj.id] = { demandNumber: obj.serviceOrder };
                return acc;
            }, {});

            const userRequest = await getUserEmployeeDataByOrganization();
            const formattedEmployees = userRequest.data.reduce((acc, obj) => {
                acc[obj.userId] = { name: obj.employeeName };
                return acc;
            }, {});

            setDemands(formattedDemands);
            setEmployees(formattedEmployees);
        };

        fetchData();
    }, []);

    const toggleModal = () => setModal(!modal);
    const toggleDiscardModal = () => setModalDiscard(!modalDiscard);

    const handleButtonClick = (sample, addResult) => {
        setSelectedSample(sample);
        addResult ? toggleModal() : toggleDiscardModal();
    };

    const sampleNumber = (sample) => {
        let response = sample.number;
        const { principalSampleOfFractionated, sampleExtraInformations } = sample;

        if (principalSampleOfFractionated && principalSampleOfFractionated.sampleExtraInformations && principalSampleOfFractionated.sampleExtraInformations.extraNumber) {
            response += "." + principalSampleOfFractionated.sampleExtraInformations.extraNumber;
        }

        if (sampleExtraInformations) {
            if (sampleExtraInformations.isFractionated || sampleExtraInformations.isAnotherResult) {
                response += "." + sampleExtraInformations.extraNumber;
            }
        }

        return response;
    };

    const sampleType = (value) => {
        const { sampleExtraInformations } = value;
        if (sampleExtraInformations && sampleExtraInformations.isAnotherResult) return "Secundária";
        if (sampleExtraInformations && sampleExtraInformations.isFractionated) return "Fracionada";
        return "Principal";
    };

    const handleDownloadSamplesList = async () => {
        setIsSamplesExcelDataLoading(true);
        const orgId = await getUserData("organizationId");

        const updatedFilterParams = {
            ...filterParams,
            organizationId: orgId,
            endDate: moment().format("YYYY-MM-DD"),
            startDate: moment().subtract(3, "month").format("YYYY-MM-DD"),
        };
        
        const SamplesExcelResponse = await fetchSamplesExcelData(updatedFilterParams);

        const SamplesExcelData = SamplesExcelResponse.data;

        const xlsxDataset = SamplesExcelData.map(sample => {
            let status = "";

            switch(sample.status) {
                case "Analysed":
                    status = "Analisada"
                    break;
                case "Discarded":
                    status = "Descartada"
                    break;
                default:
                    status = "Criada"
            }

            return {
                ...sample,
                status: status,
                dateCollected: timeStampFormated(sample.dateCollected),
                sampleAnalysis: sample.sampleAnalysis.map(sai => 
                    sample.typeMaterial == "Mosquito Adulto"
                    ?
                    ` Fase: ${sai.stage}, Genero: ${sai.gender}, Quantidade Total: ${sai.quantity}, Quantidade viva: ${sai.quantityAlive}, Quantidade morta: ${sai.quantityDead}`
                    :
                    ` Fase: ${sai.stage}, Quantidade: ${sai.quantity}`
                ).join("; "),
                address: `${sample.addressSubpremisse}, ${sample.addressNumber}`,
                trapType: ARMADILHA_INFO[sample.trapType].apelido,
            }
        })

        const columns = [
            {label: "ID_SAMPLE", value: "sampleId"},
            {label: "ID_VISITA", value: "visitId"},
            {label: "NUMERO_ARMADILHA", value: "trapNumber"},
            {label: "LATITUDE", value: "latitude"},
            {label: "LONGITUDE", value: "longitude"},
            {label: "TIPO_ARMADILHA", value: "trapType"},
            {label: "REGIAO1", value: "insideTerritorialization"},
            {label: "REGIAO2", value: "insideTerritorialization2"},
            {label: "ENDERECO_ARMADILHA", value: "address"},
            {label: "ENDERECO_COMPLEMENTO_ARMADILHA", value: "addressComplement"},
            {label: "ENDERECO_QUADRA_ARMADILHA", value: "addressBlock"},
            {label: "DATA_COLETA", value: "dateCollected"},
            {label: "NOME_COLETOR", value: "collectorName"},
            {label: "NUMERO_DEMANDA", value: "demandNumber"},
            {label: "NUMERO_AMOSTRA", value: "sampleNumber"},
            {label: "TIPO_MATERIAL", value: "typeMaterial"},
            {label: "STATUS_AMOSTRA", value: "status"},
            {label: "QTD_TOTAL_MATERIAL_CAMPO", value: "quantity"},
            {label: "QTD_MATERIAL_VIVO_CAMPO", value: "quantityLive"},
            {label: "QTD_MATERIAL_MORTO_CAMPO", value: "quantityDead"},
            {label: "TIPO_CONSERVACAO", value: "conservationType"},
            {label: "GRUPO_ANIMAIS", value: "typeIdentificationGroup"},
            {label: "ANALISE_CLASSIFICACAO_GENERO", value: "identificationGenre"},
            {label: "ANALISE_CLASSIFICACAO_ESPECIE", value: "identificationSpecie"},
            {label: "ANALISE_IDENTIFICACAO", value: "sampleAnalysis"}
        ]
        
        const xlsxFileSettings = {
            fileName: "Informacoes_amostras_VITEC",
            extraLength: 3,
            writeMode: "writeFile",
            writeOptions: {},
            RTL: false,
        };
        
        xlsx(
            [
                {
                    sheet: "Informacoes_amostras",
                    columns,
                    content: xlsxDataset,
                },
            ],
            xlsxFileSettings
        );

        setIsSamplesExcelDataLoading(false);
    }

    const userPermissions = getPermissionsToken();

    const columns = useMemo(() => {
        return [
            { name: 'N. da Demanda', selector: d => demands[d.demandId] ? demands[d.demandId].demandNumber : "", sortable: true },
            { name: 'N. da Amostra', selector: sampleNumber, sortable: true },
            { name: 'Coletor', selector: d => employees[d.collectedBy] ? employees[d.collectedBy].name : "", sortable: true },
            { name: 'Dt. Coletada', selector: d => timeStampFormated(d.dateCollected), sortable: true },
            { name: 'Tipo do material', selector: 'typeMaterial', sortable: true },
            { name: 'Tipo da amostra', selector: sampleType, sortable: true },
            { name: 'Armadilha', selector: 'trapNumber', sortable: true },
            { name: 'Situação', selector: d => d.status === "Analyzed" ? "Análise realizada" : "Pendente de análise", sortable: true },
            { 
                name: 'Status',
                selector: d => d.status === "Analyzed" ? "Analisada" : d.status === "Created" ? "Criada" : d.status === "Discarded" ? "Descartada" : "Não passível de diagnóstico", 
                sortable: true
            },
            {
                cell: (row) => (
                    userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"] && userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"].create &&
                    (row.status === "Created" || row.status === "Analyzed") ? <>
                        <Link to={{ pathname: "/admin/AddSampleResultNew", data: row }}>
                            <button data-tip data-for="createResultTooltip"  className="btn btn-primary" style={{ width: '60px', margin: '5px' }}>
                                <i className="ni ni-ruler-pencil" />
                            </button>
                        </Link> 
                        <ReactTooltip effect="solid" id="createResultTooltip">
                            <span>Cadastrar Resultado</span>
                        </ReactTooltip>
                    </> : ""
                ),
                ignoreRowClick: true, allowOverflow: true, button: true,
            },
            {
                cell: (row) => (
                    userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"] && userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"].delete &&
                    row.status !== "Discarded" && row.status !== "Diagnostic not possible" && row.status !== null ? <>
                        <button data-tip data-for="discardSampleTooltip" className="btn btn-danger" style={{ width: '60px', margin: '5px' }} onClick={() => handleButtonClick(row, false)}>
                            <i className="fa fa-trash" />
                        </button> 
                        <ReactTooltip effect="solid" id="discardSampleTooltip">
                            <span>Descartar amostra</span>
                        </ReactTooltip>
                    </> : ""
                ),
                ignoreRowClick: true, allowOverflow: true, button: true,
            },
        ];
    }, [demands, employees, userPermissions]);

    return (
        <Container className="mt--7" fluid>
            <ToastContainer />
            <DiscardSampleModal sample={selectedSample} OpenSampleModal={modalDiscard} toggleCallback={toggleDiscardModal} />
            <SelectedSampleModal sample={selectedSample} OpenSampleModal={modal} toggleCallback={toggleModal} />
            <Row className="mt-12">
                <Col xl="12">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Coletadas</h3>
                                </div>
                                {/* Botão de download ocultado por enquanto
                                    <div style={{marginRight: "20px"}}>
                                        <Button
                                            onClick={() => handleDownloadSamplesList()}
                                            color="primary"
                                            data-tip
                                            data-for="dataDownload"
                                            style={{ width: "55px", marginRight: "0px" }}
                                        >
                                            {
                                                isSamplesExcelDataLoading
                                                ? <LoadingSpin size={17} />
                                                : <i className="fa fa-download" />
                                            }
                                        </Button>
                                        <ReactTooltip effect="solid" type="info" id="dataDownload">
                                            Baixar dados
                                        </ReactTooltip>
                                    </div> 
                                */}
                            </Row>
                        </CardHeader>
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={Samples}
                            noDataComponent={'Nenhum registro encontrado.'}
                        />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SampleTable;
