import React from "react";

import { Container } from "reactstrap";
import LoginReportPageFilters from "./Filters/LoginReportPageFilters";
import LoginReportPageTable from "./Tables/LoginReportPageTable";
import moment from "moment";
import {
  fetchTerritorializations,
  getAllTypeTerritorializations,
} from "../../../services/api/territorialization";
import { toast } from "react-toastify";
import {
  fetchLoginHistoriesByFilters,
  getUserEmployeeDataByOrganization,
} from "../../../services/api/Users";
import { getTeam } from "../../../services/api/Team";
import { getUserData } from "../../../services/utils/auth";
import LoginReportPageGraphicsWrapper from "./LoginReportPageGraphicsWrapper";
import SincronizationTable from "./Tables/SincronizationTable";

const LoginReportPage = () => {
  const [employeesList, setEmployeesList] = React.useState([]);
  const [isLoginHistoryLoading, setIsLoginHistoryLoading] =
    React.useState(false);
  const [loginHistoryList, setLoginHistoryList] = React.useState([]);
  const [pageFilters, setPageFilters] = React.useState({
    beginDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    employees: [],
    finalDate: moment().format("YYYY-MM-DD"),
    loginType: {
      label: "Todos",
      value: "00000000-0000-0000-0000-000000000000",
    },
    team: {
      label: "Todos",
      value: "00000000-0000-0000-0000-000000000000",
    },
    territorializations: [],
  });
  const [teamsList, setTeamsList] = React.useState([]);
  const [territorializationsList, setTerritorializationsList] = React.useState(
    []
  );

  React.useEffect(() => {
    fetchTerritorializationsData();
    fetchEmployeesAndTeamsData();
    fetchLoginHistoriesData();
  }, []);

  const fetchAllComponentsData = async () => {
    await Promise.all([fetchLoginHistoriesData()]);
  };

  const fetchEmployeesAndTeamsData = async () => {
    const employeesData = await fetchEmployeesData();
    const teamsData = await fetchTeamsData(employeesData);

    return [employeesData, teamsData];
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de funcionários. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedEmployees = data.map(
        ({ userId, employeeId, employeeName }) => {
          return {
            employeeId,
            employeeName,
            userId,
            label: employeeName,
            value: userId,
          };
        }
      );

      setEmployeesList(formatedEmployees);

      return formatedEmployees;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchLoginHistoriesData = async () => {
    const usersIdsToSend = [];

    if (pageFilters.team.label !== "Todos")
      pageFilters.team.members.forEach(({ userId }) =>
        usersIdsToSend.push(userId)
      );

    if (pageFilters.employees && pageFilters.employees.length > 0)
      pageFilters.employees.forEach(({ value }) => usersIdsToSend.push(value));

    const requestFilters = {
      periods: {
        beginDate: pageFilters.beginDate,
        finalDate: pageFilters.finalDate,
      },
      organizationId: getUserData("organizationId"),
      usersIds: usersIdsToSend,
      territorializationsIds: pageFilters.territorializations.map(
        ({ value }) => value
      ),
      considerWebLogins:
        pageFilters.loginType.value === "00000000-0000-0000-0000-000000000000"
          ? false
          : pageFilters.loginType.value === "web"
          ? true
          : false,
      considerAppLogins:
        pageFilters.loginType.value === "00000000-0000-0000-0000-000000000000"
          ? false
          : pageFilters.loginType.value === "app"
          ? true
          : false,
    };

    setIsLoginHistoryLoading(true);

    try {
      const { data, status } = await fetchLoginHistoriesByFilters(
        requestFilters
      );
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados de histórico de logins. Verifique sua conexão com a internet e caso persista entre em contato consco."
        );

      setLoginHistoryList(data);
    } catch (error) {
      console.error(error.message);
      toast.error(
        "Ocorreu um erro ao buscar pelos dados de histórico de logins. Verifique sua conexão com a internet e caso persista entre em contato consco."
      );
    } finally {
      setIsLoginHistoryLoading(false);
    }
  };

  const fetchTeamsData = async (employeesData) => {
    try {
      const { data, status } = await getTeam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pela lista de equipes. Verifique sua conexão com a internet e caso o erro persista entre em contato consco."
        );

      const formatedTeams = data.map(({ id, members, name }) => {
        const formatedTeamMembers = members.map((member) => {
          const userData = employeesData.find(
            (employee) => employee.employeeId === member.employeeId
          );

          if (!userData)
            return {
              employeeId: "00000000-0000-0000-0000-000000000000",
              employeeName: "Usuário não encontrado",
              label: "Usuário não encontrado",
              userId: "00000000-0000-0000-0000-000000000000",
              uvis: null,
              value: "00000000-0000-0000-0000-000000000000",
            };

          return {
            ...member,
            userId: userData.userId
              ? userData.userId
              : "00000000-0000-0000-0000-000000000000",
            employeeId: userData.employeeId,
          };
        });

        return {
          value: id,
          label: name,
          members: formatedTeamMembers,
        };
      });

      setTeamsList([
        {
          label: "Todos",
          value: "00000000-0000-0000-0000-000000000000",
        },
        ...formatedTeams,
      ]);

      return formatedTeams;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        toast.error(error.message);
      }
    }
  };

  const fetchTerritorializationsData = async () => {
    try {
      const { data: typeTerritorializationsData, status } =
        await getAllTypeTerritorializations();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados de territorializações. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      const territorializations = [];

      for (const typeTerritorialization of typeTerritorializationsData) {
        if (typeTerritorialization.typeGeometry === "Polygon") {
          const { data } = await fetchTerritorializations(
            typeTerritorialization.id
          );

          territorializations.push({
            name: typeTerritorialization.name,
            label: typeTerritorialization.name,
            territorializations: data,
            options: data.map((territorialization) => {
              return {
                label: territorialization.name,
                value: territorialization.id,
              };
            }),
          });
        }
      }

      setTerritorializationsList(territorializations);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...pageFilters };

    newFiltersValues[filterName] = newValue;

    setPageFilters(newFiltersValues);
  };

  const beginDateFormated =
    pageFilters &&
    pageFilters.beginDate &&
    moment(pageFilters.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    pageFilters &&
    pageFilters.finalDate &&
    moment(pageFilters.finalDate).format("DD/MM/YYYY");

  return (
    <main>
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important" }}>&nbsp;</div>
        <LoginReportPageFilters
          employeesList={employeesList}
          fetchAllComponentData={fetchAllComponentsData}
          filtersValues={pageFilters}
          handleFiltersChange={handleFiltersChange}
          isLoginHistoryLoading={isLoginHistoryLoading}
          teamsList={teamsList}
          territorializationsList={territorializationsList}
        />
      </Container>
      <Container fluid className="pb-2 mt-2">
        <LoginReportPageTable
          data={loginHistoryList}
          headerText={`Logins em ${beginDateFormated} a ${finalDateFormated}`}
        />
        <LoginReportPageGraphicsWrapper
          data={loginHistoryList}
          filtersValues={pageFilters}
        />
      </Container>
      <Container fluid className="pb-2 mt-2">
        <SincronizationTable
          headerText={`Sincronizados de ${beginDateFormated} a ${finalDateFormated}`}
          organizationId={getUserData("organizationId")}
          pageFiltersEmployees={pageFilters.employees}
          pageFiltersTeams={pageFilters.team}
          employeesList={employeesList}
          teamsList={teamsList}
          startDate={pageFilters.beginDate}
          endDate={pageFilters.finalDate}
          onDateChange={(newStartDate, newEndDate) => {
            handleFiltersChange("beginDate", newStartDate);
            handleFiltersChange("finalDate", newEndDate);
          }}
        />
        {/* <LoginReportPageGraphicsWrapper
          data={loginHistoryList}
          filtersValues={pageFilters}
        /> */}
      </Container>
    </main>
  );
};

export default LoginReportPage;
