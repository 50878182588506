import { useContextSelector } from "use-context-selector";
import { MapContext } from "../Contexts/mapContext";

const useMap = () => {
  const filteredTrapPosition = useContextSelector(
    MapContext,
    (context) => context.filteredTrapPosition
  );
  const setFilteredTrapPosition = useContextSelector(
    MapContext,
    (context) => context.setFilteredTrapPosition
  );

  return {
    filteredTrapPosition,
    setFilteredTrapPosition,
  };
};

export default useMap;
