import moment from "moment";
import React from "react";
import { createContext } from "use-context-selector";

const FieldResultsPageContext = createContext(null);

const DEFAULT_FILTERS = {
  beginDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
  demand: {
    label: "Todas",
    value: "",
  },
  epidemiologicalWeeks: [],
  epidemiologicalWeekBegin: {
    label: "Selecione",
    value: "",
  },
  epidemiologicalWeekEnd: {
    label: "Selecione",
    value: "",
  },
  employees: [],
  endDate: moment().format("YYYY-MM-DD"),
  team: {
    label: "Todos",
    members: [],
    value: "",
  },
  territorializations: [],
  trapType: {
    label: "Selecione",
    trapTypeName: "",
    value: "",
  },
  typeLayers: [],
  year: {
    label: "Selecione",
    value: "",
  },
};

const FieldResultsPageContextProvider = ({ children }) => {
  const [positivityIndexData, setPositivityIndexData] = React.useState([]);
  const [appliedFilters, setAppliedFilters] = React.useState(DEFAULT_FILTERS);
  const [fieldResultsList, setFieldResultsList] = React.useState([]);
  const [downloadableRawData, setDownloadableRawData] = React.useState([]);
  const [isFieldResultsLoading, setIsFieldResultsLoading] = React.useState(false);
  const [situationsByTerritorializations, setSituationsByTerritorializations] = React.useState([]);

  return (
    <FieldResultsPageContext.Provider
      value={{
        appliedFilters,
        fieldResultsList,
        isFieldResultsLoading,
        downloadableRawData,
        situationsByTerritorializations,
        positivityIndexData,
        setDownloadableRawData,
        setAppliedFilters,
        setFieldResultsList,
        setIsFieldResultsLoading,
        setSituationsByTerritorializations,
        setPositivityIndexData,
      }}
    >
      {children}
    </FieldResultsPageContext.Provider>
  );
};

export { FieldResultsPageContext, FieldResultsPageContextProvider };
