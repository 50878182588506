import React from "react";

import LineChartGraphic from "../../../../../components/Graphics/Line/LineChartGraphic";
import { Line } from "recharts";
import { useTrapsByPeriodsList } from "../../Hooks/useTrapsByPeriodsList";
import DefaultGraphicLoadingSkeleton from "../../../../../components/ui/Loading/DefaultGraphicLoadingSkeleton";
import { Select } from "../../../../../components/Wrappers/SelectAll";
import { selectComponentStyles } from "../../../../../services/utils/globalFunctions";

const useActivitiesByPeriodsLineGraphic = () => {
  const { isTrapsByPeriodsListLoading } = useTrapsByPeriodsList();
  const [selectedGraphicType, setSelectedGraphicType] = React.useState({
    label: "Todos",
    value: "all",
  });

  const graphicTypesList = [
    {
      label: "Todos",
      value: "all",
    },
    {
      label: "Aplicativo",
      value: "app",
    },
    {
      label: "Digitação",
      value: "typing",
    },
  ];

  const handleGraphicTypeChange = (selectedGraphicType) => {
    setSelectedGraphicType(selectedGraphicType);
  };

  return {
    graphicTypesList,
    handleGraphicTypeChange,
    isTrapsByPeriodsListLoading,
    selectedGraphicType,
  };
};

export const ActivitiesByPeriodsLineGraphic = ({
  appliedFilters,
  data,
  periodLegend,
}) => {
  const {
    graphicTypesList,
    handleGraphicTypeChange,
    isTrapsByPeriodsListLoading,
    selectedGraphicType,
  } = useActivitiesByPeriodsLineGraphic();

  if (isTrapsByPeriodsListLoading) return <DefaultGraphicLoadingSkeleton />;

  return data && data.length > 0 && selectedGraphicType.value === "all" ? (
    <LineChartGraphic
      data={data}
      brushBar={true}
      headerContent={
        <div style={{ width: "130px" }}>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            value={selectedGraphicType}
            options={graphicTypesList}
            onChange={(e) => handleGraphicTypeChange(e)}
          />
        </div>
      }
      headerText={
        <span>
          Total de atividades com {appliedFilters.trapType.label} <br />
          {periodLegend}
        </span>
      }
      lines={[
        <Line
          type="monotone"
          dataKey="totalAppInstallations"
          name="Instalações (App)"
          stroke="#0088FE"
        />,
        <Line
          type="monotone"
          dataKey="totalAppMaintenances"
          name="Manutenções (App)"
          stroke="#00C49F"
        />,
        <Line
          type="monotone"
          dataKey="totalAppUninstallations"
          name="Desinstalações (App)"
          stroke="#FFBB28"
        />,
        <Line
          type="monotone"
          dataKey="totalTypingInstallations"
          name="Instalações (Digitação)"
          stroke="#FF8042"
        />,
        <Line
          type="monotone"
          dataKey="totalTypingMaintenances"
          name="Manutenções (Digitação)"
          stroke="#064f58"
        />,
        <Line
          type="monotone"
          dataKey="totalTypingUninstallations"
          name="Desinstalações (Digitação)"
          stroke="#865838"
        />,
      ]}
      xAxisDataKey="periodName"
    />
  ) : data && data.length > 0 && selectedGraphicType.value === "app" ? (
    <LineChartGraphic
      data={data}
      brushBar={true}
      headerContent={
        <div style={{ width: "130px" }}>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            value={selectedGraphicType}
            options={graphicTypesList}
            onChange={(e) => handleGraphicTypeChange(e)}
          />
        </div>
      }
      headerText={
        <span>
          Total de atividades com {appliedFilters.trapType.label} <br />
          {periodLegend}
        </span>
      }
      lines={[
        <Line
          type="monotone"
          dataKey="totalAppInstallations"
          name="Instalações (App)"
          stroke="#0088FE"
        />,
        <Line
          type="monotone"
          dataKey="totalAppMaintenances"
          name="Manutenções (App)"
          stroke="#00C49F"
        />,
        <Line
          type="monotone"
          dataKey="totalAppUninstallations"
          name="Desinstalações (App)"
          stroke="#FFBB28"
        />,
      ]}
      xAxisDataKey="periodName"
    />
  ) : data && data.length > 0 && selectedGraphicType.value === "typing" ? (
    <LineChartGraphic
      data={data}
      brushBar={true}
      headerContent={
        <div style={{ width: "130px" }}>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            value={selectedGraphicType}
            options={graphicTypesList}
            onChange={(e) => handleGraphicTypeChange(e)}
          />
        </div>
      }
      headerText={
        <span>
          Total de atividades com {appliedFilters.trapType.label} <br />
          {periodLegend}
        </span>
      }
      lines={[
        <Line
          type="monotone"
          dataKey="totalTypingInstallations"
          name="Instalações (Digitação)"
          stroke="#FF8042"
        />,
        <Line
          type="monotone"
          dataKey="totalTypingMaintenances"
          name="Manutenções (Digitação)"
          stroke="#064f58"
        />,
        <Line
          type="monotone"
          dataKey="totalTypingUninstallations"
          name="Desinstalações (Digitação)"
          stroke="#865838"
        />,
      ]}
      xAxisDataKey="periodName"
    />
  ) : null;
};
