import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

export const useConfigurations = () => {
  const configurations = useContextSelector(
    OrganizationContext,
    (context) => context.configurations
  );
  const setConfigurations = useContextSelector(
    OrganizationContext,
    (context) => context.setConfigurations
  );

  return { configurations, setConfigurations };
};

