import React from "react";

import {
  Button,
  Col,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";
import { DateFilter } from "../../../../components/Filters/DateFilter";
import YearsFilter from "../../../../components/Filters/YearsFilter";
import { EpidemiologicWeekFilter } from "../../../../components/Filters/EpidemiologicWeekFilter";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTypeLayersList from "../../../../hooks/useTypeLayersList";
import {
  fetchConfigurationsData,
  fetchTerritorializationsData,
  fetchTrapTypesData,
  monthsOptionsList,
  selectComponentStyles,
} from "../../../../services/utils/globalFunctions";
import ManualEpidemiologicWeeksFilter from "../../../../components/Filters/ManualEpidemiologicWeeksFilter";
import { Select } from "../../../../components/Wrappers/SelectAll";
import TerritorializationsFilter from "../../../../components/Filters/TerritorializationsFilter";
import getWeeks from "../../../../services/utils/epidemiologicalWeek";
import { toast } from "react-toastify";
import {
  fetchSinanCases,
  getUnidadesNotificadoras,
  notificiationsByFilters,
} from "../../../../services/api/Sinan";
import { useAppliedFilters } from "../Hooks/useAppliedFilters";
import { getUserData } from "../../../../services/utils/auth";
import moment from "moment";
import { useNotificationsList } from "../Hooks/useNotificationsList";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import { fetchFilteredTraps } from "../../../../services/api/Trap";
import useTrapsList from "../../../../hooks/useTrapsList";
import LoadingSpin from "react-loading-spin";
import TypeLayersFilter from "../../../../components/Filters/TypeLayersFilter";
import { MonthWeekFilter } from "../../../../components/Filters/MonthWeekFilter";
import { ManualMonthsFilter } from "../../../../components/Filters/ManualMonthsFilter";
import { getTodayEpidemiologicalWeek } from "../../../../services/utils/todayEpidemiologicalWeek";
import { useCardNotificationsList } from "../Hooks/useCardNotificationsList";
import { useConfigurations } from "../../../../hooks/useConfigurations";

export const DEFAULT_SINAN_PAGE_FILTERS = {
  beginDate: "",
  casesReferenceDate: {
    label: "1º Sintoma",
    value: 1,
  },
  datePeriodType: "week",
  disease: {
    label: "Dengue",
    value: 1,
  },
  epidemiologicalWeeksFromYears: {},
  epidemiologicalWeeks: [],
  epidemiologicalWeekBegin: {
    label: "Selecione",
    value: "",
  },
  epidemiologicalWeekEnd: {
    label: "Selecione",
    value: "",
  },
  endDate: "",
  months: [],
  monthsFromYears: {},
  notifyingUnits: [],
  territorializations: [],
  typeLayers: [],
  years: [],
};

const useSinanPageFilters = () => {
  const [filters, setFilters] = React.useState(DEFAULT_SINAN_PAGE_FILTERS);
  const [notifyingUnitsList, setNotifyingUnitsList] = React.useState([]);
  const { setAppliedFilters } = useAppliedFilters();
  const {
    setNotificationsList,
    isNotificationsListLoading,
    setIsNotificationsListLoading,
  } = useNotificationsList();
  const { setCardsNotificationsList } = useCardNotificationsList();
  const { territorializationsList, setTerritorializationsList } =
    useTerritorializationsList();
  const { typeLayersList, setTypeLayersList } = useTypeLayersList();
  const { trapTypesList, setTrapTypesList } = useTrapTypesList();
  const { trapsList, setTrapsList } = useTrapsList();
  const { configurations, setConfigurations } = useConfigurations();

  React.useEffect(() => {
    if (territorializationsList.length === 0 || typeLayersList.length === 0)
      fetchTerritorializationsData(
        setTerritorializationsList,
        setTypeLayersList
      );
    if (!configurations) {
      fetchConfigurationsData(setConfigurations);
    }

    fetchNotifyingUnitsList();

    const newFiltersValues = {
      ...DEFAULT_SINAN_PAGE_FILTERS,
      beginDate: moment().subtract(15, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };

    setFilters(newFiltersValues);
    fetchAllComponentsData(newFiltersValues);

    if (trapsList.length === 0) fetchTrapsListData(newFiltersValues);
    if (trapTypesList.length === 0) fetchTrapTypesData(setTrapTypesList);
  }, []);

  const clearFilters = () => {
    setFilters(DEFAULT_SINAN_PAGE_FILTERS);
  };

  const fetchAllComponentsData = async (filtersToSend) => {
    const filtersToConsider = filtersToSend ? filtersToSend : filters;

    const { error, message } =
      validateFiltersBeforeSendingRequests(filtersToConsider);

    if (error) {
      toast.error(message);

      return;
    }

    if (
      filtersToConsider.datePeriodType === "week" &&
      filtersToConsider.epidemiologicalWeeks.length === 0 &&
      filtersToConsider.years.length > 0
    ) {
      const epidemiologicWeeks = getWeeks();

      filtersToConsider.epidemiologicalWeeks = epidemiologicWeeks
        .find(({ year }) => year === "2024")
        .weeks.map(({ beginDate, endDate, label }) => ({
          beginDate,
          endDate,
          label,
          value: endDate,
        }));

      const epidemiologicWeeksFromAllYears = {};

      filtersToConsider.years.forEach(({ value }) => {
        const epidemiologicWeeksFromYear = epidemiologicWeeks
          .find(({ year }) => year === String(value))
          .weeks.map(({ beginDate, endDate, label }) => ({
            beginDate,
            endDate,
            label,
            year: value,
            value: endDate,
          }));

        epidemiologicWeeksFromAllYears[value] = epidemiologicWeeksFromYear;
      });

      filtersToConsider.epidemiologicalWeeksFromYears =
        epidemiologicWeeksFromAllYears;
    }

    if (
      filtersToConsider.datePeriodType === "week" &&
      filtersToConsider.epidemiologicalWeeks.length > 0 &&
      filtersToConsider.years.length > 0
    ) {
      const epidemiologicWeeks = getWeeks();

      const epidemiologicWeeksFromAllYears = {};

      const labelsSet = new Set(
        filtersToConsider.epidemiologicalWeeks.map((item) => item.label)
      );

      filtersToConsider.years.forEach(({ value }) => {
        const epidemiologicWeeksFromYear = epidemiologicWeeks.find(
          ({ year }) => year === String(value)
        ).weeks;

        const filteredData = epidemiologicWeeksFromYear.filter((item) =>
          labelsSet.has(item.label)
        );

        epidemiologicWeeksFromAllYears[value] = filteredData;
      });

      filtersToConsider.epidemiologicalWeeksFromYears =
        epidemiologicWeeksFromAllYears;
    }

    if (
      filtersToConsider.datePeriodType === "month" &&
      filtersToConsider.months.length === 0 &&
      filtersToConsider.years.length > 0
    ) {
      filtersToConsider.months = monthsOptionsList;

      const monthscWeeksFromAllYears = {};

      filtersToConsider.years.forEach(({ value: year }) => {
        const monthsFromYears = monthsOptionsList.map(({ label, value }) => ({
          beginDate: moment({ year: year, month: value - 1 })
            .startOf("month")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment({ year: year, month: value - 1 })
            .endOf("month")
            .subtract(1, "day")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          periodName: label,
        }));

        monthscWeeksFromAllYears[year] = monthsFromYears;
      });

      filtersToConsider.monthsFromYears = monthscWeeksFromAllYears;
    }

    if (
      filtersToConsider.datePeriodType === "month" &&
      filtersToConsider.months.length > 0 &&
      filtersToConsider.years.length > 0
    ) {
      const monthscWeeksFromAllYears = {};

      filtersToConsider.years.forEach(({ value: year }) => {
        const monthsFromYears = filtersToConsider.months.map(
          ({ label, value }) => ({
            beginDate: moment({ year: year, month: value - 1 })
              .startOf("month")
              .utc()
              .set({ hour: 0, minute: 0, second: 0 })
              .toISOString(),
            endDate: moment({ year: year, month: value - 1 })
              .endOf("month")
              .subtract(1, "day")
              .utc()
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString(),
            periodName: label,
          })
        );

        monthscWeeksFromAllYears[year] = monthsFromYears;
      });

      filtersToConsider.monthsFromYears = monthscWeeksFromAllYears;
    }

    setAppliedFilters(filtersToConsider);
    setIsNotificationsListLoading(true);

    try {
      const allPromises = await Promise.all([
        fetchNotificationsList(filtersToConsider),
        fetchNotificationsForCards(filtersToConsider),
      ]);

      return allPromises;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error);
      }
    } finally {
      setIsNotificationsListLoading(false);
    }
  };

  const fetchTrapsListData = async (filters) => {
    const organizationId = getUserData("organizationId");

    const filtersToSend = {
      colorStatuses: [1, 2, 3],
      demandsIds: [],
      finalDate: moment()
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString(),
      organizationId,
      ranges: [],
      statuses: [1],
      territorializationsIds: filters.territorializations.map(
        ({ value }) => value
      ),
      trapsNumbers: [],
      trapTypesIds: [],
      usersIds: [],
    };

    try {
      const { data, status } = await fetchFilteredTraps(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelas armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      setTrapsList(data);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error);
      }
    }
  };

  const fetchNotificationsForCards = async (filters) => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao buscar pelas notificações para os cards. Verifique sua conexão com a internet e tente novamente. Caso o problema persista, entre em contato com a nossa equipe.";

    const yearPeriod = {
      beginDate: moment()
        .startOf("year")
        .utc()
        .set({ hour: 0, minute: 0, second: 0 })
        .toISOString(),
      endDate: moment()
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString(),
      periodName: `Ano atual`,
    };

    const lastThirtyDaysPeriod = {
      beginDate: moment()
        .subtract(30, "days")
        .utc()
        .set({ hour: 0, minute: 0, second: 0 })
        .toISOString(),
      endDate: moment()
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString(),
      periodName: `Últimos 30 dias`,
    };

    const lastSevenDaysPeriod = {
      beginDate: moment()
        .subtract(7, "days")
        .utc()
        .set({ hour: 0, minute: 0, second: 0 })
        .toISOString(),
      endDate: moment()
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString(),
      periodName: `Últimos 7 dias`,
    };

    const filtersToSend = {
      disease: filters.disease.value,
      includeCasesByPersons: true,
      notifyingUnitsIds: filters.notifyingUnits.map(({ value }) => value),
      organizationId: getUserData("organizationId"),
      periods: [yearPeriod, lastThirtyDaysPeriod, lastSevenDaysPeriod],
      referenceDate: filters.casesReferenceDate.value,
      territorializationsIds: filters.territorializations.map(
        ({ value }) => value
      ),
    };

    try {
      const { data, status } = await notificiationsByFilters(filtersToSend);

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      setCardsNotificationsList(data);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }
    }
  };

  const fetchNotifyingUnitsList = async () => {
    const DEFAUL_ERROR_MESSAGE =
      "Ocorreu um erro ao carregar a lista de Unidades Notificadoras. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com nossa equipe.";

    try {
      const { data, status } = await getUnidadesNotificadoras();

      if (status !== 200) {
        toast.error(DEFAUL_ERROR_MESSAGE);

        return;
      }

      const formatedNotifyingUnitsList = data
        .map(({ name, number }) => ({
          label: name,
          value: number,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setNotifyingUnitsList(formatedNotifyingUnitsList);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAUL_ERROR_MESSAGE);
        console.error(error);
      }
    }
  };

  const fetchNotificationsList = async (filters) => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao carregar a lista de Notificações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com nossa equipe.";

    const epidemiologicWeeksFromYearsKeys = Object.keys(
      filters.epidemiologicalWeeksFromYears
    );

    const monthsFromYearsKeys = Object.keys(filters.monthsFromYears);

    if (epidemiologicWeeksFromYearsKeys.length > 0) {
      try {
        const requests = epidemiologicWeeksFromYearsKeys.map((key) => {
          const periods = filters.epidemiologicalWeeksFromYears[key];

          const filtersToSend = {
            disease: filters.disease.value,
            includeCasesByPersons: true,
            IncludeCasesByNotifyingUnits: true,
            notifyingUnitsIds: filters.notifyingUnits.map(({ value }) => value),
            organizationId: getUserData("organizationId"),
            periods: periods.map(({ beginDate, endDate, label }) => ({
              beginDate: moment(beginDate, "DD/MM/YYYY")
                .utc()
                .set({ hour: 0, minute: 0, second: 0 })
                .toISOString(),
              endDate: moment(endDate, "DD/MM/YYYY")
                .utc()
                .set({ hour: 23, minute: 59, second: 59 })
                .toISOString(),
              periodName: label,
            })),
            referenceDate: filters.casesReferenceDate.value,
            territorializationsIds: filters.territorializations.map(
              ({ value }) => value
            ),
            year: key,
          };

          return fetchSinanCases(filtersToSend);
        });

        const responses = await Promise.all(requests);

        const successfulResponses = responses.filter(
          ({ status }) => status === 200
        );

        const successfulResponsesData = successfulResponses.map(
          ({ data }) => data
        );

        const groupedDataByTerritorializations = {};

        successfulResponsesData.forEach((area) => {
          area.forEach(
            ({
              resultsByPeriods,
              year,
              territorializationId,
              territorializationName,
            }) => {
              if (!groupedDataByTerritorializations[territorializationId])
                groupedDataByTerritorializations[territorializationId] = {
                  resultsByPeriods: [],
                  year,
                  territorializationId,
                  territorializationName,
                };

              const territorializationGroup =
                groupedDataByTerritorializations[territorializationId];

              resultsByPeriods.forEach(({ periodName, result }) => {
                const existingPeriod =
                  territorializationGroup.resultsByPeriods.find(
                    (p) => p.periodName === periodName
                  );

                if (!existingPeriod) {
                  territorializationGroup.resultsByPeriods.push({
                    periodName,
                    result: JSON.parse(JSON.stringify(result)),
                  });
                } else {
                  deepMergeResults(existingPeriod.result, result);
                }
              });
            }
          );
        });

        setNotificationsList({
          raw: successfulResponsesData,
          formated: Array.from(Object.values(groupedDataByTerritorializations)),
        });

        return;
      } catch (error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }
    }

    if (monthsFromYearsKeys.length > 0) {
      try {
        const requests = monthsFromYearsKeys.map((key) => {
          const periods = filters.monthsFromYears[key];

          const filtersToSend = {
            disease: filters.disease.value,
            includeCasesByPersons: true,
            IncludeCasesByNotifyingUnits: true,
            notifyingUnitsIds: filters.notifyingUnits.map(({ value }) => value),
            organizationId: getUserData("organizationId"),
            periods,
            referenceDate: filters.casesReferenceDate.value,
            territorializationsIds: filters.territorializations.map(
              ({ value }) => value
            ),
            year: key,
          };

          return fetchSinanCases(filtersToSend);
        });

        const responses = await Promise.all(requests);

        const successfulResponses = responses.filter(
          ({ status }) => status === 200
        );

        const successfulResponsesData = successfulResponses.map(
          ({ data }) => data
        );

        const groupedDataByTerritorializations = {};

        successfulResponsesData.forEach((area) => {
          area.forEach(
            ({
              resultsByPeriods,
              year,
              territorializationId,
              territorializationName,
            }) => {
              if (!groupedDataByTerritorializations[territorializationId])
                groupedDataByTerritorializations[territorializationId] = {
                  resultsByPeriods: [],
                  year,
                  territorializationId,
                  territorializationName,
                };

              const territorializationGroup =
                groupedDataByTerritorializations[territorializationId];

              resultsByPeriods.forEach(({ periodName, result }) => {
                const existingPeriod =
                  territorializationGroup.resultsByPeriods.find(
                    (p) => p.periodName === periodName
                  );

                if (!existingPeriod) {
                  territorializationGroup.resultsByPeriods.push({
                    periodName,
                    result: JSON.parse(JSON.stringify(result)),
                  });
                } else {
                  deepMergeResults(existingPeriod.result, result);
                }
              });
            }
          );
        });

        setNotificationsList({
          raw: successfulResponsesData,
          formated: Array.from(Object.values(groupedDataByTerritorializations)),
        });

        return;
      } catch (error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }
    }

    const filtersToSend = {
      disease: filters.disease.value,
      includeCasesByPersons: true,
      IncludeCasesByNotifyingUnits: true,
      notifyingUnitsIds: filters.notifyingUnits.map(({ value }) => value),
      organizationId: getUserData("organizationId"),
      periods: generatePeriodsToSend(filters),
      referenceDate: filters.casesReferenceDate.value,
      territorializationsIds: filters.territorializations.map(
        ({ value }) => value
      ),
      year: moment(filters.endDate).year(),
    };

    try {
      const { data, status } = await fetchSinanCases(filtersToSend);

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      setNotificationsList({
        raw: data,
        formated: data,
      });

      return;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }
    }
  };

  function deepMergeResults(target, source) {
    for (const key in source) {
      if (typeof source[key] === "object" && !Array.isArray(source[key])) {
        // Objeto aninhado: mesclar recursivamente
        if (!target[key]) target[key] = {};
        deepMergeResults(target[key], source[key]);
      } else if (Array.isArray(source[key])) {
        // Arrays: concatenar
        target[key] = [...(target[key] || []), ...source[key]];
      } else if (typeof source[key] === "number") {
        // Números: somar
        target[key] = (target[key] || 0) + source[key];
      }
    }
  }

  const generatePeriodsToSend = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") {
      const result = [
        {
          beginDate: moment(filters.beginDate)
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment(filters.endDate)
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          periodName: `${moment(filters.beginDate).format(
            "DD/MM/YYYY"
          )} à ${moment(filters.endDate).format("DD/MM/YYYY")}`,
        },
      ];

      return result;
    }

    if (filters.datePeriodType === "week") {
      const result = filters.epidemiologicalWeeks.map(
        ({ beginDate, endDate, label }) => ({
          beginDate: moment(beginDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment(endDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          periodName: label,
        })
      );

      return result;
    }

    if (filters.datePeriodType === "month") {
      const result = filters.months.map(({ label, value }) => ({
        beginDate: moment({ year: filters.year.value, month: value - 1 })
          .startOf("month")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString(),
        endDate: moment({ year: filters.year.value, month: value - 1 })
          .endOf("month")
          .subtract(1, "day")
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString(),
        periodName: label,
      }));

      return result;
    }
  };

  const handleFiltersChange = React.useCallback((filterName, filterValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };
      newValues[filterName] = filterValue;

      return newValues;
    });
  }, []);

  const handleDateChange = React.useCallback((filterName, newValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      newValues.year = {
        label: "Selecione",
        value: "",
      };
      newValues.epidemiologicalWeekBegin = {
        label: "Selecione",
        value: "",
      };
      newValues.epidemiologicalWeekEnd = {
        label: "Selecione",
        value: "",
      };
      newValues.months = [];
      newValues.epidemiologicalWeeks = [];

      newValues[filterName] = newValue;

      return newValues;
    });
  }, []);

  const handleEpidemiologicWeeksFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if (
          filterName === "epidemiologicalWeekBegin" &&
          newValue.value === ""
        ) {
          newValues.epidemiologicalWeekBegin = newValue;
          newValues.epidemiologicalWeekEnd = newValue;

          return newValues;
        }

        if (
          filterName === "epidemiologicalWeekEnd" &&
          newValue.value !== "" &&
          newValues.epidemiologicalWeekBegin.value !== ""
        ) {
          const beginWeekNumber = +newValues.epidemiologicalWeekBegin.label;
          const endWeekNumber = +newValue.label;

          if (endWeekNumber < beginWeekNumber) {
            toast.error(
              "A semana epidemiológica final deve ser igual ou maior que a inicial."
            );

            return newValues;
          }

          const epidemiologicalWeeksFromYear = getWeeks().find(
            ({ year }) => year === "2024"
          ).weeks;

          const weeksInBetween = epidemiologicalWeeksFromYear
            .slice(beginWeekNumber - 1, endWeekNumber)
            .map(({ endDate, ...rest }) => ({
              ...rest,
              endDate,
              value: endDate,
            }));

          newValues.epidemiologicalWeeks = weeksInBetween;
          newValues[filterName] = newValue;

          return newValues;
        }

        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleYearFilterChange = React.useCallback(
    (filterName, filterValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if (filterValue.find(({ value }) => value === "all")) {
          const epidemiologicalWeeks = getWeeks();

          const yearsList = epidemiologicalWeeks
            .map((week) => ({
              label: week.year,
              value: +week.year,
            }))
            .sort((a, b) => b.value - a.value);

          newValues[filterName] = yearsList;

          return newValues;
        }

        newValues[filterName] = filterValue;

        newValues.epidemiologicalWeeks = [];
        newValues.epidemiologicalWeekBegin = {
          label: "Selecione",
          value: "",
        };
        newValues.epidemiologicalWeekEnd = {
          label: "Selecione",
          value: "",
        };
        newValues.beginDate = "";
        newValues.endDate = "";

        return newValues;
      });
    },
    []
  );

  const handleTypeLayerChange = (value) => {
    handleFiltersChange(
      "typeLayers",
      !value || value.length === 0 ? [] : value
    );

    if (!value || value.length === 0)
      handleFiltersChange("territorializations", []);
    else
      handleFiltersChange("territorializations", value[0].territorializations);
  };

  const handleMonthFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if (filterName === "monthBegin" && newValue.value === "") {
          newValues.monthBegin = newValue;
          newValues.monthEnd = newValue;

          return newValues;
        }

        if (
          filterName === "monthEnd" &&
          newValue.value !== "" &&
          newValues.monthBegin.value !== ""
        ) {
          const beginMonthNumber = newValues.monthBegin.value;
          const endMonthNumber = newValue.value;

          if (endMonthNumber < beginMonthNumber) {
            toast.error("o mês final deve ser igual ou maior que o inicial.");

            return newValues;
          }

          const monthsInBetween = monthsOptionsList.filter(
            ({ value }) => value >= beginMonthNumber && value <= endMonthNumber
          );

          newValues.months = monthsInBetween;
          newValues[filterName] = newValue;

          return newValues;
        }

        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleDatePeriodChange = () => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      const datePeriodTypeValue =
        newValues.datePeriodType === "week" ? "month" : "week";

      newValues.datePeriodType = datePeriodTypeValue;

      return newValues;
    });
  };

  const validateFiltersBeforeSendingRequests = (filters) => {
    if (
      filters.beginDate !== "" &&
      filters.endDate !== "" &&
      moment(filters.beginDate) > moment(filters.endDate)
    )
      return {
        error: true,
        message: "A data inicial deve ser menor que a data final.",
      };

    if (
      filters.years.length === 0 &&
      filters.epidemiologicalWeeks.length === 0 &&
      (filters.beginDate === "" || filters.endDate === "")
    )
      return {
        error: true,
        message: "Selecione um período de data ou ano e meses/semanas.",
      };

    return { error: false, message: "" };
  };

  const caseReferencesDateList = [
    {
      label: "1º Sintoma",
      value: 1,
    },
    {
      label: "Digitação",
      value: 2,
    },
    {
      label: "Notificação",
      value: 3,
    },
  ];

  const diseaseList = [
    {
      label: "Dengue",
      value: 1,
    },
  ];

  return {
    caseReferencesDateList,
    handleDateChange,
    clearFilters,
    diseaseList,
    fetchAllComponentsData,
    filters,
    handleDatePeriodChange,
    handleMonthFilterChange,
    handleEpidemiologicWeeksFilterChange,
    handleFiltersChange,
    handleTypeLayerChange,
    handleYearFilterChange,
    isNotificationsListLoading,
    notifyingUnitsList,
    territorializationsList,
    typeLayersList,
  };
};

const usePopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const togglePopover = () =>
    setIsPopoverOpen((previousValue) => !previousValue);

  return {
    isPopoverOpen,
    togglePopover,
  };
};

export const SinanPageFiltersPopover = () => {
  const {
    handleDateChange,
    caseReferencesDateList,
    diseaseList,
    fetchAllComponentsData,
    filters,
    handleDatePeriodChange,
    handleMonthFilterChange,
    handleEpidemiologicWeeksFilterChange,
    handleFiltersChange,
    handleTypeLayerChange,
    handleYearFilterChange,
    isNotificationsListLoading,
    notifyingUnitsList,
    territorializationsList,
    typeLayersList,
  } = useSinanPageFilters();
  const { isPopoverOpen, togglePopover } = usePopover();

  const styles = {
    label: {
      fontWeight: "bold",
      width: "100%",
      display: "flex",
      gap: ".1rem",
    },
  };

  return (
    <div>
      <Button id="mainFiltersPopover" type="button" style={{ width: "150px" }}>
        <i className="fa fa-filter"></i> Filtros
      </Button>
      <Popover
        style={{ width: "500px" }}
        placement="bottom"
        target="mainFiltersPopover"
        id="filtersPopover"
        offset="-120, 8"
        isOpen={isPopoverOpen}
        toggle={togglePopover}
      >
        <PopoverHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Filtros
            <span style={{ fontSize: "12px", fontWeight: 400 }}>
              Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
            </span>
            <i
              className="fa fa-times"
              onClick={togglePopover}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        </PopoverHeader>
        <PopoverBody style={{ backgroundColor: "#ffffff" }}>
          <span
            style={{
              display: "block",
              textAlign: "right",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          >
            ( <span style={{ color: "red" }}>*</span> ) : Campo obrigatório
          </span>
          <Row className="mb-2">
            <Col>
              <label htmlFor="beginDate" style={{ fontWeight: "bolder" }}>
                De:
              </label>
              <DateFilter
                filterName="beginDate"
                handleFiltersChange={handleDateChange}
                id="beginDate"
                value={filters.beginDate}
                disabled={filters.years.length > 0}
              />
            </Col>
            <Col>
              <label htmlFor="endDate" style={{ fontWeight: "bolder" }}>
                Até:
              </label>
              <DateFilter
                filterName="endDate"
                handleFiltersChange={handleDateChange}
                id="endDate"
                value={filters.endDate}
                disabled={filters.years.length > 0}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label htmlFor="" style={{ fontWeight: "bolder" }}>
                Ano:
              </label>
              <YearsFilter
                filterName="years"
                handleFiltersChange={handleYearFilterChange}
                isMulti={true}
                additionalOptions={[{ label: "Todos", value: "all" }]}
                value={filters.years}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {filters.datePeriodType === "week" ? (
                <div>
                  <label htmlFor="" style={styles.label}>
                    <span style={{ color: "#3b6bca" }}>Semana</span> inicio:{" "}
                    <i onClick={handleDatePeriodChange}>
                      <ExchangeIcon />
                    </i>
                  </label>
                  <EpidemiologicWeekFilter
                    filterName="epidemiologicalWeekBegin"
                    handleFiltersChange={handleEpidemiologicWeeksFilterChange}
                    value={filters.epidemiologicalWeekBegin}
                    year={"2024"}
                    isDisabled={filters.years.length === 0}
                  />
                </div>
              ) : (
                <div>
                  <label htmlFor="" style={styles.label}>
                    <span style={{ color: "#3b6bca" }}>Mês</span> inicio:{" "}
                    <i onClick={handleDatePeriodChange}>
                      <ExchangeIcon />
                    </i>
                  </label>
                  <MonthWeekFilter
                    filterName="monthBegin"
                    handleFiltersChange={handleMonthFilterChange}
                    value={filters.monthBegin}
                    isDisabled={filters.years.length === 0}
                  />
                </div>
              )}
            </Col>
            <Col>
              {filters.datePeriodType === "week" ? (
                <div>
                  <label htmlFor="" style={styles.label}>
                    <span style={{ color: "#3b6bca" }}>Semana</span> fim:{" "}
                    <i onClick={handleDatePeriodChange}>
                      <ExchangeIcon />
                    </i>
                  </label>
                  <EpidemiologicWeekFilter
                    filterName="epidemiologicalWeekEnd"
                    handleFiltersChange={handleEpidemiologicWeeksFilterChange}
                    value={filters.epidemiologicalWeekEnd}
                    year={"2024"}
                    isDisabled={filters.years.length === 0}
                  />
                </div>
              ) : (
                <div>
                  <label htmlFor="" style={styles.label}>
                    <span style={{ color: "#3b6bca" }}>Mês</span> fim:{" "}
                    <i onClick={handleDatePeriodChange}>
                      <ExchangeIcon />
                    </i>
                  </label>
                  <MonthWeekFilter
                    filterName="monthEnd"
                    handleFiltersChange={handleMonthFilterChange}
                    value={filters.monthEnd}
                    isDisabled={filters.years.length === 0}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {filters.datePeriodType === "week" ? (
                <div>
                  <label htmlFor="" style={styles.label}>
                    Semanas (manual):
                  </label>
                  <ManualEpidemiologicWeeksFilter
                    filterName="epidemiologicalWeeks"
                    handleFiltersChange={handleFiltersChange}
                    value={filters.epidemiologicalWeeks}
                    year={"2024"}
                    isDisabled={filters.years.length === 0}
                  />
                </div>
              ) : (
                <div>
                  <label htmlFor="" style={styles.label}>
                    Meses (manual):
                  </label>
                  <ManualMonthsFilter
                    filterName="months"
                    handleFiltersChange={handleFiltersChange}
                    value={filters.months}
                    isDisabled={filters.years.length === 0}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label htmlFor="" style={{ fontWeight: "bolder" }}>
                Date de referência:
              </label>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isClearable={false}
                options={caseReferencesDateList}
                value={filters.casesReferenceDate}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={true}
                onChange={(e) =>
                  handleFiltersChange(
                    "casesReferenceDate",
                    !e
                      ? {
                          label: "Selecione",
                          value: "",
                        }
                      : e
                  )
                }
              />
            </Col>
            <Col>
              <label htmlFor="" style={{ fontWeight: "bolder" }}>
                Doença:
              </label>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isClearable={false}
                options={diseaseList}
                value={filters.disease}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={true}
                onChange={(e) =>
                  handleFiltersChange(
                    "disease",
                    !e
                      ? {
                          label: "Selecione",
                          value: "",
                        }
                      : e
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label htmlFor="" style={{ fontWeight: "bolder" }}>
                Delimitador de área:
              </label>
              <TypeLayersFilter
                handleTypeLayerChange={handleTypeLayerChange}
                typeLayersList={typeLayersList}
                value={filters.typeLayers}
                isDisabled={typeLayersList.length === 0}
              />
            </Col>
            <Col>
              <label htmlFor="" style={{ fontWeight: "bolder" }}>
                Territorializações:
              </label>
              <TerritorializationsFilter
                handleFiltersChange={handleFiltersChange}
                territorializationsList={territorializationsList}
                value={filters.territorializations}
                isDisabled={territorializationsList.length === 0}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <label htmlFor="" style={{ fontWeight: "bolder" }}>
                Unidade notificadora:
              </label>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isClearable={true}
                options={notifyingUnitsList}
                value={filters.notifyingUnits}
                isMulti={true}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                onChange={(e) =>
                  handleFiltersChange(
                    "notifyingUnits",
                    !e || e.length === 0 ? [] : e
                  )
                }
                isDisabled={notifyingUnitsList.length === 0}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {isNotificationsListLoading ? (
                <Button disabled={true} color="primary">
                  <LoadingSpin color="#fff" size={17} />
                </Button>
              ) : (
                <Button
                  onClick={() => fetchAllComponentsData()}
                  style={{
                    color: "white",
                  }}
                  color="primary"
                >
                  Aplicar filtros
                </Button>
              )}
            </Col>
            <Col>
              <Button
                onClick={togglePopover}
                style={{ backgroundColor: "#ffffff" }}
              >
                Fechar
              </Button>
            </Col>
          </Row>
        </PopoverBody>
      </Popover>
    </div>
  );
};

const ExchangeIcon = () => {
  return (
    <img
      src={require("../../../../assets/img/icons/interface/exchange-black.png")}
      style={{
        width: "12px",
        height: "12px",
        cursor: "pointer",
        marginLeft: "5px",
      }}
      alt="icone"
    />
  );
};
