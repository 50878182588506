import React from "react";

import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardText,
  Button,
  CardBody,
} from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";

const ReadedTrapsByUserTable = ({
  visitsData,
  employees,
  productivityFilters,
}) => {
  const [formatedVisits, setFormatedVisits] = React.useState([]);
  const [formatedVisitsToExport, setFormatedVisitsToExport] = React.useState(
    []
  );
  const [generatedAt, setGeneratedAt] = React.useState("");

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    if (!visitsData || visitsData.length === 0) return;

    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");
    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);

    const formatedVisitsToTable = getFormatedVisitsToTable(visitsData);
    const formatedVisitsToExport = getFormatedVisitsToExport(
      formatedVisitsToTable
    );

    setFormatedVisits(formatedVisitsToTable);
    setFormatedVisitsToExport(formatedVisitsToExport);
  }, [visitsData]);

  const calculateDailyAverageTrapsReaded = (visitsData) => {
    const dailyInstallations = {};

    visitsData.forEach(({ visitsByDate }) => {
      visitsByDate.forEach(({ activityDate, readedTraps }) => {
        const actualDate = moment(activityDate).format("DD/MM/YYYY");

        if (!dailyInstallations[actualDate]) {
          dailyInstallations[actualDate] = { total: 0, count: 0 };
        }
        if (readedTraps > 0) {
          dailyInstallations[actualDate].total += readedTraps;
          dailyInstallations[actualDate].count += 1;
        }
      });
    });

    const dailyAverages = {};
    for (const date in dailyInstallations) {
      if (dailyInstallations.hasOwnProperty(date)) {
        const { total, count } = dailyInstallations[date];
        dailyAverages[date] = count > 0 ? (total / count).toFixed(1) : 0;
      }
    }

    return dailyAverages;
  };

  const getFormatedVisitsToTable = (visitsData) => {
    if (!visitsData || visitsData.length === 0) return;

    const COLLECTOR_NOT_FOUND = -1;

    const startDate = moment(productivityFilters.beginDate, "YYYY-MM-DD");
    const endDate = moment(productivityFilters.finalDate, "YYYY-MM-DD");

    const datesInBetween = [];
    const visitsByDates = [];

    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "days")
    )
      datesInBetween.push(date.format("YYYY-MM-DD"));

    datesInBetween.forEach((date) => {
      visitsData.forEach(({ collectorId, visits }) => {
        const collectorName = getUserName(collectorId);

        const filteredVisitsByDate = visits.filter(
          ({ activityDate }) => activityDate === date
        );
        const collectorResultIndex = visitsByDates.findIndex(
          (result) => result.collectorId === collectorId
        );
        const collectorData = visitsByDates.find(
          (visit) => visit.collectorId === collectorId
        );
        let collectorPreviousResults = collectorData
          ? collectorData.visitsByDate
          : [];

        if (filteredVisitsByDate && filteredVisitsByDate.length > 0) {
          const formatedDateData = filteredVisitsByDate.reduce(
            (accumulator, current) => {
              accumulator.installedTraps = accumulator.installedTraps.concat(
                current.installedTraps
              );
              accumulator.readedTraps = accumulator.readedTraps.concat(
                current.readedTraps
              );
              accumulator.uninstalledTraps =
                accumulator.uninstalledTraps.concat(current.uninstalledTraps);
              return accumulator;
            },
            { installedTraps: [], readedTraps: [], uninstalledTraps: [] }
          );

          const visitData = {
            activityDate: date,
            installedTraps: formatedDateData.installedTraps.length,
            readedTraps: formatedDateData.readedTraps.length,
            uninstalledTraps: formatedDateData.uninstalledTraps.length,
          };

          if (collectorResultIndex !== COLLECTOR_NOT_FOUND)
            visitsByDates[collectorResultIndex].visitsByDate = [
              ...collectorPreviousResults,
              { ...visitData },
            ];
          else
            visitsByDates.push({
              collectorId,
              name: collectorName,
              visitsByDate: [{ ...visitData }],
            });
        } else {
          const visitData = {
            activityDate: date,
            installedTraps: 0,
            readedTraps: 0,
            uninstalledTraps: 0,
          };

          if (collectorResultIndex !== COLLECTOR_NOT_FOUND)
            visitsByDates[collectorResultIndex].visitsByDate = [
              ...collectorPreviousResults,
              { ...visitData },
            ];
          else
            visitsByDates.push({
              collectorId,
              name: collectorName,
              visitsByDate: [{ ...visitData }],
            });
        }
      });
    });

    return visitsByDates;
  };

  const getFormatedVisitsToExport = (visitsData) => {
    if (!visitsData || visitsData.length === 0) return;

    const startDate = moment(productivityFilters.beginDate, "YYYY-MM-DD");
    const endDate = moment(productivityFilters.finalDate, "YYYY-MM-DD");

    const dailyAvaragesTrapsReaded =
      calculateDailyAverageTrapsReaded(visitsData);
    const dailyAvaragesTrapsFormatedLine = {
      USUARIO: "MEDIA MANUTENCAO POR DIA",
      ...dailyAvaragesTrapsReaded,
    };

    const datesInBetween = [];

    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "days")
    )
      datesInBetween.push(date.format("YYYY-MM-DD"));

    const totalReadedTrapByDays = { USUARIO: "TOTAL GERAL" };

    let totalReadedTrapsOnPeriod = 0;

    const formatedVisits = visitsData.map(({ name, visitsByDate }) => {
      const transformedUser = {
        USUARIO: !name || name === "" ? "Usuário não encontrado" : name,
      };

      let totalReadedTrapsByUser = 0;
      let totalWorkedDaysByUser = 0;

      visitsByDate.forEach(({ activityDate, readedTraps }) => {
        const actualDate = moment(activityDate).format("DD/MM/YYYY");

        transformedUser[actualDate] = readedTraps;

        if (!totalReadedTrapByDays[actualDate])
          totalReadedTrapByDays[actualDate] = readedTraps;
        else
          totalReadedTrapByDays[actualDate] =
            totalReadedTrapByDays[actualDate] + readedTraps;

        totalReadedTrapsByUser += readedTraps;
        totalReadedTrapsOnPeriod += readedTraps;

        if (readedTraps && readedTraps > 0) totalWorkedDaysByUser += 1;
      });

      const result = {
        ...transformedUser,
        TOTAL_MANUTENCOES_USUARIO: totalReadedTrapsByUser,
        MEDIA_MANUTENCOES_USUARIO: (
          totalReadedTrapsByUser / totalWorkedDaysByUser
        ).toFixed(1),
      };

      return result;
    });

    const result = [
      ...formatedVisits,
      {
        ...dailyAvaragesTrapsFormatedLine,
      },
      {
        ...totalReadedTrapByDays,
        TOTAL_MANUTENCOES_USUARIO: totalReadedTrapsOnPeriod,
      },
    ];

    return result;
  };

  const generateTableColumns = () => {
    const startDate = moment(productivityFilters.beginDate, "YYYY-MM-DD");
    const endDate = moment(productivityFilters.finalDate, "YYYY-MM-DD");

    const datesInBetween = [];

    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "days")
    )
      datesInBetween.push(date.format("YYYY-MM-DD"));

    const tableColumns = [
      {
        name: "Usuário",
        selector: (row) => row.name,
        sortable: true,
        grow: 4,
      },
      ...datesInBetween.map((date) => ({
        name: moment(date).format("DD/MM/YYYY"),
        selector: (row) => {
          const visit = row.visitsByDate.find((v) => v.activityDate === date);
          return visit ? visit.readedTraps : "No data";
        },
        sortable: true,
      })),
    ];

    return tableColumns;
  };

  const getUserName = (id) => {
    if (
      id === "00000000-0000-0000-0000-000000000000" ||
      id === null ||
      id === undefined ||
      !employees ||
      employees.length === 0
    )
      return "Usuário não encontrado";

    if (id === "5a868c02-7a8d-47a0-b1b1-bb55383ad55d") return "Não informado";

    const userData = employees.find(
      (employee) => employee.employeeId === id || employee.userId === id
    );

    return userData ? userData.employeeName : "Usuário não encontrado";
  };

  const handleDownloadGraphicImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `VisitasGeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  const beginDateFormated =
    productivityFilters &&
    productivityFilters.beginDate &&
    moment(productivityFilters.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    productivityFilters &&
    productivityFilters.finalDate &&
    moment(productivityFilters.finalDate).format("DD/MM/YYYY");

  return (
    <div ref={contentToPrintRef}>
      <Row className="mt-12 mb-12" style={{marginBottom: "1.5rem"}}>
        <Col className="mb-12 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader>
              <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
                <div>
                  <span>
                    Manutenções por Usuário em {beginDateFormated} a {" "}
                    {finalDateFormated}
                  </span>
                </div>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                    Gerado em {generatedAt}
                  </span>
                  <span>
                    <CsvDownloadButton
                      className="btn btn-primary"
                      data={formatedVisitsToExport}
                      filename={`ManutençõesPorUsuárioGeneratedAt${generatedAt}`}
                      style={{ width: "55px", marginRight: "0px" }}
                      data-tip
                      data-for="dataDownload"
                    >
                      <i className="fa fa-download" />
                    </CsvDownloadButton>
                    <ReactTooltip effect="solid" type="info" id="dataDownload">
                      Baixar dados
                    </ReactTooltip>
                  </span>
                  <span>
                    <Button
                      onClick={() => handleDownloadGraphicImage()}
                      color="primary"
                      data-tip
                      data-for="viewDownload"
                      style={{ width: "55px", marginRight: "0px" }}
                    >
                      <i className={"fa fa-image"} />
                    </Button>
                    <ReactTooltip effect="solid" type="info" id="viewDownload">
                      Baixar visualização
                    </ReactTooltip>
                  </span>
                </div>
              </CardText>
            </CardHeader>
            <CardBody style={style.cardBody}>
              <Row>
                <Col>
                  <DataTable
                    noHeader
                    defaultSortField="name"
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    responsive
                    columns={generateTableColumns()}
                    data={formatedVisits}
                    noDataComponent={"Nenhum registro encontrado."}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReadedTrapsByUserTable;
