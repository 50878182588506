const groupPositiveTrapsEstimatesByWeek = (
  fieldResultsList,
  trapTypeName
) => {
  const aedesAegyptiEstimatesByWeek = {};
  const eggEstimatesByWeek = {};
  const exuviaEstimatesByWeek = {};
  const larvaOrPupaEstimatesByWeek = {};

  if (!fieldResultsList || fieldResultsList.length === 0) {
    return {
      aedesAegyptiEstimatesByWeek,
      eggEstimatesByWeek,
      exuviaEstimatesByWeek,
      larvaOrPupaEstimatesByWeek,
    };
  }

  fieldResultsList.forEach(({ positiveTraps, periodName }) => {
    if (trapTypeName === "armadilhaDisseminadoraInseticida") {
      fillInLarvaOrPupaEstimatesByWeek(
        larvaOrPupaEstimatesByWeek,
        positiveTraps,
        periodName
      );

      fillInExuviaEstimatesByWeek(
        exuviaEstimatesByWeek,
        positiveTraps,
        periodName
      );
    }

    if (
      trapTypeName === "armadilhaMosquitoAdulto" ||
      trapTypeName === "armadilhaMosquitoAdulto2"
    ) {
      fillInLarvaOrPupaEstimatesByWeek(
        larvaOrPupaEstimatesByWeek,
        positiveTraps,
        periodName
      );

      fillInAedesAegyptiEstimatesByWeek(
        aedesAegyptiEstimatesByWeek,
        positiveTraps,
        periodName
      );
    }

    if (trapTypeName === "armadilhaOvos") {
      fillInLarvaOrPupaEstimatesByWeek(
        larvaOrPupaEstimatesByWeek,
        positiveTraps,
        periodName
      );

      fillInExuviaEstimatesByWeek(
        exuviaEstimatesByWeek,
        positiveTraps,
        periodName
      );

      fillInEggEstimatesByWeek(eggEstimatesByWeek, positiveTraps, periodName);
    }
  });

  return {
    aedesAegyptiEstimatesByWeek,
    eggEstimatesByWeek,
    exuviaEstimatesByWeek,
    larvaOrPupaEstimatesByWeek,
  };
};

const fillInLarvaOrPupaEstimatesByWeek = (
  larvaOrPupaEstimatesByWeek,
  positiveTraps,
  label
) => {
  if (positiveTraps.forLarvaOrPupa.length === 0){
    if (!larvaOrPupaEstimatesByWeek[label])
      larvaOrPupaEstimatesByWeek[label] = {};

    larvaOrPupaEstimatesByWeek[label]["1 a 20"] = 0;
    larvaOrPupaEstimatesByWeek[label]["21 a 100"] = 0;
    larvaOrPupaEstimatesByWeek[label]["Mais de 100"] = 0;

    return;
  };

  positiveTraps.forLarvaOrPupa.forEach(({ estimatives }) => {
    if (!larvaOrPupaEstimatesByWeek[label])
      larvaOrPupaEstimatesByWeek[label] = {};

    const larvaEstimate = estimatives.find(({ type }) => type === "Larvas");

    if (!larvaOrPupaEstimatesByWeek[label][larvaEstimate.name])
      larvaOrPupaEstimatesByWeek[label][larvaEstimate.name] = 0;

    larvaOrPupaEstimatesByWeek[label][larvaEstimate.name]++;
  });
};

const fillInExuviaEstimatesByWeek = (
  exuviaEstimatesByWeek,
  positiveTraps,
  label
) => {
  if (positiveTraps.forExuvia.length === 0){
    if (!exuviaEstimatesByWeek[label])
      exuviaEstimatesByWeek[label] = {};

    exuviaEstimatesByWeek[label]["1 a 20"] = 0;
    exuviaEstimatesByWeek[label]["21 a 100"] = 0;
    exuviaEstimatesByWeek[label]["Mais de 100"] = 0;

    return;
  };

  positiveTraps.forExuvia.forEach(({ estimatives }) => {
    if (!exuviaEstimatesByWeek[label]) exuviaEstimatesByWeek[label] = {};

    const exuviaEstimate = estimatives.find(({ type }) => type === "Exuvia");

    if (!exuviaEstimatesByWeek[label][exuviaEstimate.name])
      exuviaEstimatesByWeek[label][exuviaEstimate.name] = 0;

    exuviaEstimatesByWeek[label][exuviaEstimate.name]++;
  });
};

const fillInAedesAegyptiEstimatesByWeek = (
  aedesAegyptiEstimatesByWeek,
  positiveTraps,
  label
) => {
  if (positiveTraps.forAedesAegypti.length === 0){
    if (!aedesAegyptiEstimatesByWeek[label])
      aedesAegyptiEstimatesByWeek[label] = {};

    aedesAegyptiEstimatesByWeek[label]["1"] = 0;
    aedesAegyptiEstimatesByWeek[label]["2 a 3"] = 0;
    aedesAegyptiEstimatesByWeek[label]["Mais de 3"] = 0;

    return;
  };

  positiveTraps.forAedesAegypti.forEach(({ estimatives }) => {
    if (!aedesAegyptiEstimatesByWeek[label])
      aedesAegyptiEstimatesByWeek[label] = {};

    const aedesAegyptiEstimate = estimatives.find(
      ({ type }) => type === "Aedes Aegypti"
    );

    if (!aedesAegyptiEstimatesByWeek[label][aedesAegyptiEstimate.name])
      aedesAegyptiEstimatesByWeek[label][aedesAegyptiEstimate.name] = 0;

    aedesAegyptiEstimatesByWeek[label][aedesAegyptiEstimate.name]++;
  });
};

const fillInEggEstimatesByWeek = (
  eggEstimatesByWeek,
  positiveTraps,
  label
) => {
  if (positiveTraps.forEgg.length === 0){
    if (!eggEstimatesByWeek[label])
      eggEstimatesByWeek[label] = {};

    eggEstimatesByWeek[label]["1 a 100"] = 0;
    eggEstimatesByWeek[label]["101 a 200"] = 0;
    eggEstimatesByWeek[label]["Mais de 200"] = 0;

    return;
  };

  positiveTraps.forEgg.forEach(({ estimatives }) => {
    if (!eggEstimatesByWeek[label]) eggEstimatesByWeek[label] = {};

    const eggEstimate = estimatives.find(({ type }) => type === "Ovos");

    if (!eggEstimatesByWeek[label][eggEstimate.name])
      eggEstimatesByWeek[label][eggEstimate.name] = 0;

    eggEstimatesByWeek[label][eggEstimate.name]++;
  });
};

export {
  groupPositiveTrapsEstimatesByWeek,  
}