import React from "react";

import DataTable from "react-data-table-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";

const VisitDetailsModal = ({ isOpen, selectedVisit, toggle }) => {
  const tableColumns = [
    {
      name: "Tipo",
      cell: (row) => {
        return (
          <>
            {row.trapType}
            <i>
              <img
                style={{ width: "30px" }}
                src={ARMADILHA_INFO[row.trapTypeName].principal_icon}
                alt=""
              />
            </i>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Número",
      selector: "number",
      sortable: true,
    },
    {
      name: "Geradas amostras",
      selector: "wasSamplesGenerated",
      sortable: true,
      cell: (v) => (v.wasSamplesGenerated ? "Sim" : "Não"),
    },
    {
      name: "Número amostras",
      selector: "generatedSamplesNumbers",
      sortable: true,
      cell: (v) =>
        v.generatedSamplesNumbers.length === 0
          ? "-"
          : v.generatedSamplesNumbers.join(", "),
    },
    {
      name: "Em condições de uso",
      selector: "isReadableCondition",
      sortable: true,
      cell: (v) => (v.isReadableCondition ? "Sim" : "Não"),
    },
  ];
  
  return (
    <Modal
      size="lg"
      fade
      isOpen={isOpen}
      toggle={() => toggle((previousValue) => !previousValue)}
    >
      <ModalHeader>
        <span>Detalhes da Visita</span>
      </ModalHeader>
      <ModalBody>
        {isOpen && selectedVisit ? (
          <>
            <span style={{ display: "block" }}>
              <span style={{ fontWeight: "bold" }}>Situação:</span>{" "}
              {selectedVisit.visitSituation}
            </span>
            <span style={{ display: "block" }}>
              <span style={{ fontWeight: "bold" }}>Usuário que realizou:</span>{" "}
              {selectedVisit.username}
            </span>
            <span style={{ display: "block" }}>
              <span style={{ fontWeight: "bold" }}>Endereço:</span>{" "}
              {selectedVisit.address}
            </span>
            <span style={{ display: "block" }}>
              <span style={{ fontWeight: "bold" }}>Data da atividade:</span>{" "}
              {selectedVisit.date}
            </span>
            {
              selectedVisit.residentName ?
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Responsável do imóvel:</span>{" "}
                  {selectedVisit.residentName}
                </span>
              : 
              <></>
              
            }
            <span style={{ display: "block" }}>
              <span style={{ fontWeight: "bold" }}>Hora de início:</span>{" "}
              {selectedVisit.initialTime}
            </span>
            <span style={{ display: "block" }}>
              <span style={{ fontWeight: "bold" }}>Hora de término:</span>{" "}
              {selectedVisit.finalTime}
            </span>
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "1rem",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Armadilhas instaladas:</span>
              <i className="fa fa-arrow-down" aria-hidden="true"></i>
            </div>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              highlightOnHover
              responsive
              columns={tableColumns}
              data={selectedVisit.installedTraps}
              noDataComponent={"Nenhum registro encontrado."}
            />
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "1rem",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Armadilhas lidas:</span>
              <i className="fa fa-arrow-down" aria-hidden="true"></i>
            </div>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              highlightOnHover
              responsive
              columns={tableColumns}
              data={selectedVisit.readedTraps}
              noDataComponent={"Nenhum registro encontrado."}
            />
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "1rem",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                Armadilhas desinstaladas:
              </span>
              <i className="fa fa-arrow-down" aria-hidden="true"></i>
            </div>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              highlightOnHover
              responsive
              columns={tableColumns}
              data={selectedVisit.uninstalledTraps}
              noDataComponent={"Nenhum registro encontrado."}
            />
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            {selectedVisit.midias && selectedVisit.midias.photos.length > 0 ? (
              <>
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Fotos:</span>
                </span>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "16px",
                  }}
                >
                  {selectedVisit.midias.photos.map(({ photoFile64 }, index) => {
                    return (
                      <div>
                        <div style={{ marginBottom: "16px" }}>
                          <img
                            src={photoFile64}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                        <Button
                          color="primary"
                          onClick={() => {
                            const link = document.createElement("a");
                            link.download = `imagem${index + 1}.png`;
                            link.href = photoFile64;
                            link.click();
                          }}
                        >
                          Baixar
                        </Button>
                      </div>
                    );
                  })}
                </div>
                <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              </>
            ) : null}
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <div className="row">
          <div className="col-6" style={{ maxWidth: "100%" }}>
            <Button
              color="primary"
              onClick={() => toggle((previousValue) => !previousValue)}
            >
              Fechar
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default VisitDetailsModal;
