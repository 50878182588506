import { useContextSelector } from "use-context-selector";
import { FieldResultsPageContext } from "../Contexts/FieldResultsPageContext";

const usePositivityIndex = () => {
  const positivityIndexData = useContextSelector(
    FieldResultsPageContext,
    (context) => context.positivityIndexData
  );
  const setPositivityIndexData = useContextSelector(
    FieldResultsPageContext,
    (context) => context.setPositivityIndexData
  );

  return {
    positivityIndexData,
    setPositivityIndexData,
  };
};

export default usePositivityIndex;
