import React from "react";
import LineChartGraphic from "../../../../../components/Graphics/Line/LineChartGraphic";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { Select } from "../../../../../components/Wrappers/SelectAll";
import getWeeks from "../../../../../services/utils/epidemiologicalWeek";
import {
  getColorByIndex,
  selectComponentStyles,
} from "../../../../../services/utils/globalFunctions";
import ReactTooltip from "react-tooltip";
import { checkPermissionComponent } from "../../../../../services/utils/permission";
import { toast } from "react-toastify";
import { getUserData } from "../../../../../services/utils/auth";
import { updateConfigurations } from "../../../../../services/api/Organization";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../../components/Cards/Card";
import { SorotypeHistoricalSeries } from "../SorotypeHistoricalSeries";
import { useAppliedFilters } from "../../Hooks/useAppliedFilters";
import {
  VitecCard,
  VitecCardDefaultView,
  VitecCardDefaultViewContent,
  VitecCardDefaultViewFooter,
  VitecCardDefaultViewHeader,
  VitecCardDownloadImageButton,
  VitecCardGeneratedAt,
  VitecCardModal,
  VitecCardModalContent,
  VitecCardModalHeader,
  VitecCardToggleModalButton,
} from "../../../../../components/Cards/VitecCard";
import moment from "moment";

export const NOTIFICATIONS = 1;
export const CONFIRMED = 2;
export const MAX_YEAR_EPIDEMIOLOGIC_WEEKS = 52;

const useCasesHistoricalSeries = (
  filteredHistoricalSeriesList,
  historicalSeriesList,
  caseReferenceType,
  configurations
) => {
  const [activeLines, setActiveLines] = React.useState({
    cases: false,
    average: false,
    superiorLimit: false,
    sorotypeOnePercentage: false,
    sorotypeTwoPercentage: false,
    sorotypeThreePercentage: false,
    sorotypeFourPercentage: false,
    ...getWeeks().map(({ year }) => ({
      [year]: false,
    })),
  });
  const [casesHistoricalSeriesByWeeks, setCasesHistoricalSeriesByWeeks] =
    React.useState([]);
  const [casesHistoricalSeriesByYears, setCasesHistoricalSeriesByYears] =
    React.useState([]);
  const [
    sorotypeCasesHistoricalSeriesByYears,
    setSorotypeCasesHistoricalSeriesByYears,
  ] = React.useState([]);
  const [yearsToNotShowControlDiagrams, setYearsToNotShowControlDiagrams] =
    React.useState([]);
  const [selectedGraphicsType, setSelectedGraphicsType] = React.useState({
    label: "Semanas",
    value: "weeks",
  });
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    if (!configurations || configurations.length === 0) return;

    const formatedYears = JSON.parse(configurations.value).map((year) => {
      return {
        label: String(year),
        value: String(year),
      };
    });

    setYearsToNotShowControlDiagrams(formatedYears);
  }, [configurations]);

  React.useEffect(() => {
    if (!historicalSeriesList || historicalSeriesList.length === 0) return;

    const diagramCasesByWeeks =
      calculateDiagramCasesByWeeks(historicalSeriesList);

    const casesSeriesByWeeks = formatCasesSeriesByWeeks(
      historicalSeriesList,
      diagramCasesByWeeks
    );
    const casesSeriesByYears = formatCasesSeriesByYears(
      historicalSeriesList,
      diagramCasesByWeeks
    );
    const sorotypeCasesSeriesByYears = formatSorotypeCasesSeriesByYears(
      historicalSeriesList,
      caseReferenceType
    );

    setCasesHistoricalSeriesByWeeks(casesSeriesByWeeks);
    setCasesHistoricalSeriesByYears(casesSeriesByYears);
    setSorotypeCasesHistoricalSeriesByYears(sorotypeCasesSeriesByYears);
  }, [historicalSeriesList, yearsToNotShowControlDiagrams]);

  const graphicsTypeOptions = [
    {
      label: "Semanas",
      value: "weeks",
    },
    {
      label: "Anos",
      value: "years",
    },
    {
      label: "Anos (Sorotipos)",
      value: "yearsSorotypes",
    },
  ];

  const yearsOptions = getWeeks().map(({ year }) => {
    return {
      value: year,
      label: year,
    };
  });

  const handleSaveDiagramConfiguration = async () => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao salvar as preferências do diagrama. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe.";

    try {
      const configuratioinsToSend = {
        organizationId: getUserData("organizationId"),
        configurationId: configurations.id,
        value: JSON.stringify(
          yearsToNotShowControlDiagrams.map(({ value }) => +value)
        ),
      };

      const { status } = await updateConfigurations([configuratioinsToSend]);

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        return;
      }

      toast.success("Preferências salvas com sucesso.");
    } catch (error) {
      console.error(error);
      toast.error(DEFAULT_ERROR_MESSAGE);
    }
  };

  const calculateDiagramCasesByWeeks = (data) => {
    let referenceData = data;

    if (yearsToNotShowControlDiagrams.length > 0) {
      referenceData = data.filter(({ label }) => {
        const actualYear = label.split("/")[1];

        return !yearsToNotShowControlDiagrams.find(
          ({ value }) => value === actualYear
        );
      });
    }

    const weekResults = [];

    for (let i = 1; i <= MAX_YEAR_EPIDEMIOLOGIC_WEEKS; i++) {
      const weekNumber = i;

      const sameWeekFromDifferentYears = referenceData.filter(
        ({ label }) => +label.split("/")[0] === weekNumber
      );

      if (sameWeekFromDifferentYears.length === 0) continue;

      const casesFromWeeks = sameWeekFromDifferentYears.map((week) =>
        caseReferenceType === NOTIFICATIONS
          ? week["Notificações"]
          : week["Confirmados"]
      );

      const average = calculateMovingAverage(weekNumber, referenceData);
      const standardDeviation = calculateStandardDeviation(
        casesFromWeeks,
        average
      );
      const superiorLimit = calculateSuperiorLimit(standardDeviation, average);

      const weekResult = {
        weekNumber,
        average,
        standardDeviation,
        superiorLimit,
      };

      weekResults.push(weekResult);
    }

    return weekResults;
  };

  const calculateMovingAverage = (referenceWeek, data) => {
    const calculateDiagramCasesBySpecificWeek = {
      1: () => {
        const week1DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 1)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week2DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 2)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week3DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 3)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );

        const result = calculateSimpleAverage([
          ...week1DataFromYears,
          ...week2DataFromYears,
          ...week3DataFromYears,
        ]);

        return result;
      },
      2: () => {
        const week1DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 1)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week2DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 2)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week3DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 3)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week4DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 4)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );

        const result = calculateSimpleAverage([
          ...week1DataFromYears,
          ...week2DataFromYears,
          ...week3DataFromYears,
          ...week4DataFromYears,
        ]);

        return result;
      },
      51: () => {
        const week49DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 49)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week50DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 50)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week51DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 51)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week52DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 52)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );

        const result = calculateSimpleAverage([
          ...week49DataFromYears,
          ...week50DataFromYears,
          ...week51DataFromYears,
          ...week52DataFromYears,
        ]);

        return result;
      },
      52: () => {
        const week50DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 50)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week51DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 51)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );
        const week52DataFromYears = data
          .filter(({ label }) => +label.split("/")[0] === 52)
          .map((week) =>
            caseReferenceType === NOTIFICATIONS
              ? week["Notificações"]
              : week["Confirmados"]
          );

        const result = calculateSimpleAverage([
          ...week50DataFromYears,
          ...week51DataFromYears,
          ...week52DataFromYears,
        ]);

        return result;
      },
    };

    const specificCase = calculateDiagramCasesBySpecificWeek[referenceWeek];

    if (specificCase) return specificCase();

    const firstPreviousWeekDataFromYears = data
      .filter(({ label }) => +label.split("/")[0] === referenceWeek - 1)
      .map((week) =>
        caseReferenceType === NOTIFICATIONS
          ? week["Notificações"]
          : week["Confirmados"]
      );
    const secondPreviousWeekDataFromYears = data
      .filter(({ label }) => +label.split("/")[0] === referenceWeek - 2)
      .map((week) =>
        caseReferenceType === NOTIFICATIONS
          ? week["Notificações"]
          : week["Confirmados"]
      );
    const actualWeekDataFromYears = data
      .filter(({ label }) => +label.split("/")[0] === referenceWeek)
      .map((week) =>
        caseReferenceType === NOTIFICATIONS
          ? week["Notificações"]
          : week["Confirmados"]
      );
    const firstAfterWeekDataFromYears = data
      .filter(({ label }) => +label.split("/")[0] === referenceWeek + 1)
      .map((week) =>
        caseReferenceType === NOTIFICATIONS
          ? week["Notificações"]
          : week["Confirmados"]
      );
    const secondAfterWeekDataFromYears = data
      .filter(({ label }) => +label.split("/")[0] === referenceWeek + 2)
      .map((week) =>
        caseReferenceType === NOTIFICATIONS
          ? week["Notificações"]
          : week["Confirmados"]
      );

    const result = calculateSimpleAverage([
      ...firstPreviousWeekDataFromYears,
      ...secondPreviousWeekDataFromYears,
      ...actualWeekDataFromYears,
      ...firstAfterWeekDataFromYears,
      ...secondAfterWeekDataFromYears,
    ]);

    return result;
  };

  const calculateSimpleAverage = (values) => {
    const sum = values.reduce((soma, valor) => soma + valor, 0);

    return +(sum / values.length).toFixed(2);
  };

  const calculateStandardDeviation = (values, simpleAvarage) => {
    const sumDiferrenceSquare = values.reduce((sum, value) => {
      const difference = value - simpleAvarage;

      return sum + difference ** 2;
    }, 0);

    const standardDeviation = sumDiferrenceSquare / (values.length - 1);

    return +Math.sqrt(standardDeviation).toFixed(2);
  };

  const calculateSuperiorLimit = (standardDeviation, simpleAverage) => {
    return +(1.96 * standardDeviation + simpleAverage).toFixed(2);
  };

  const formatCasesSeriesByYears = (data, diagramCasesByWeeks) => {
    const yearsResults = [];

    for (let i = 1; i <= MAX_YEAR_EPIDEMIOLOGIC_WEEKS; i++) {
      const weekNumber = i;

      const weekData = {};

      const sameWeekFromDifferentYears = data.filter(
        ({ label }) => +label.split("/")[0] === weekNumber
      );

      if (sameWeekFromDifferentYears.length === 0) continue;

      sameWeekFromDifferentYears.forEach((item) => {
        const year = item.label.split("/")[1];

        weekData[year] =
          caseReferenceType === NOTIFICATIONS
            ? item["Notificações"]
            : item["Confirmados"];
      });

      const diagramResults = diagramCasesByWeeks.find(
        (item) => item.weekNumber === weekNumber
      );

      const { average, standardDeviation, superiorLimit } = diagramResults;

      const weekResult = {
        label: weekNumber,
        ...weekData,
        average,
        standardDeviation,
        superiorLimit,
      };

      yearsResults.push(weekResult);
    }

    return yearsResults;
  };

  const formatSorotypeCasesSeriesByYears = (data, variant) => {
    const result = [];

    getWeeks()
      .map(({ year }) => year)
      .forEach((year) => {
        const epidemiologicWeeksFromActualYear = data.filter(
          ({ label }) => +label.split("/")[1] === +year
        );

        const weeksData = {
          allSorotypesSum: 0,
          sorotypeOne: 0,
          sorotypeTwo: 0,
          sorotypeThree: 0,
          sorotypeFour: 0,
          cases: 0,
        };

        epidemiologicWeeksFromActualYear.forEach((item) => {
          weeksData.allSorotypesSum +=
            item.sorotypeOne +
            item.sorotypeTwo +
            item.sorotypeThree +
            item.sorotypeFour;
          weeksData.sorotypeOne += item.sorotypeOne;
          weeksData.sorotypeTwo += item.sorotypeTwo;
          weeksData.sorotypeThree += item.sorotypeThree;
          weeksData.sorotypeFour += item.sorotypeFour;
          weeksData.cases +=
            variant === NOTIFICATIONS
              ? item["Notificações"]
              : item["Confirmados"];
        });

        function calculatePercentage(value, total) {
          if (value === 0 || total === 0) return 0;
          return Math.floor((value / total) * 1000) / 10;
        }

        const sorotypeOnePercentage = calculatePercentage(
          weeksData.sorotypeOne,
          weeksData.allSorotypesSum
        );
        const sorotypeTwoPercentage = calculatePercentage(
          weeksData.sorotypeTwo,
          weeksData.allSorotypesSum
        );
        const sorotypeThreePercentage = calculatePercentage(
          weeksData.sorotypeThree,
          weeksData.allSorotypesSum
        );
        const sorotypeFourPercentage = calculatePercentage(
          weeksData.sorotypeFour,
          weeksData.allSorotypesSum
        );

        const yearResult = {
          label: year,
          sorotypeOnePercentage,
          sorotypeTwoPercentage,
          sorotypeThreePercentage,
          sorotypeFourPercentage,
          cases: weeksData.cases,
        };

        result.push(yearResult);
      });

    return result;
  };

  const formatCasesSeriesByWeeks = (data, diagramCasesByWeeks) => {
    const result = data.map((item) => {
      const weekResult = diagramCasesByWeeks.find(
        ({ weekNumber }) => weekNumber === +item.label.split("/")[0]
      );

      return {
        label: item.label,
        cases:
          caseReferenceType === NOTIFICATIONS
            ? item["Notificações"]
            : item["Confirmados"],
        sorotypeOnePercentage: item["Sorotipo 1"],
        sorotypeTwoPercentage: item["Sorotipo 2"],
        sorotypeThreePercentage: item["Sorotipo 3"],
        sorotypeFourPercentage: item["Sorotipo 4"],
        average: weekResult && weekResult.average ? weekResult.average : 0,
        standardDeviation:
          weekResult && weekResult.standardDeviation
            ? weekResult.standardDeviation
            : 0,
        superiorLimit:
          weekResult && weekResult.superiorLimit ? weekResult.superiorLimit : 0,
      };
    });

    return result;
  };

  const handleLegendClick = (value) => {
    setActiveLines((previousValues) => ({
      ...previousValues,
      [value]: !previousValues[value],
    }));
  };

  const handleGraphicsTypeChange = (value) => {
    setSelectedGraphicsType(value);
  };

  const handleYearsChange = (value) => {
    setYearsToNotShowControlDiagrams(value);
  };

  return {
    appliedFilters,
    handleSaveDiagramConfiguration,
    activeLines,
    casesHistoricalSeriesByWeeks,
    casesHistoricalSeriesByYears,
    sorotypeCasesHistoricalSeriesByYears,
    handleLegendClick,
    handleGraphicsTypeChange,
    handleYearsChange,
    graphicsTypeOptions,
    selectedGraphicsType,
    yearsOptions,
    yearsToNotShowControlDiagrams,
  };
};

export const CasesHistoricalSeriesGraphic = ({
  configurations,
  filteredHistoricalSeriesList,
  historicalSeriesList,
  caseReferenceType,
  headerText,
  legend,
}) => {
  const {
    appliedFilters,
    handleSaveDiagramConfiguration,
    activeLines,
    casesHistoricalSeriesByWeeks,
    casesHistoricalSeriesByYears,
    sorotypeCasesHistoricalSeriesByYears,
    handleLegendClick,
    handleGraphicsTypeChange,
    handleYearsChange,
    graphicsTypeOptions,
    selectedGraphicsType,
    yearsOptions,
    yearsToNotShowControlDiagrams,
  } = useCasesHistoricalSeries(
    filteredHistoricalSeriesList,
    historicalSeriesList,
    caseReferenceType,
    configurations
  );

  if (
    appliedFilters.territorializations.length > 0 ||
    appliedFilters.epidemiologicalWeeks.length > 0 ||
    appliedFilters.months.length > 0
  )
    return (
      <VitecCard>
        <VitecCardModal>
          <VitecCardModalHeader>
            Visualização em tela cheia
          </VitecCardModalHeader>
          <VitecCardModalContent>
            <VitecCardDefaultView>
              <VitecCardDefaultViewHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold", color: "black" }}>
                  {caseReferenceType === NOTIFICATIONS
                    ? "Casos Notificados"
                    : "Casos Confirmados"}
                  <br />
                  {legend}
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".3rem",
                  }}
                >
                  <VitecCardDownloadImageButton
                    imageFileName={`SERIE_HISTORICA_SINAN_VITEC_${moment().format(
                      "DD/MM/YYYY HH:mm"
                    )}`}
                    color="primary"
                    style={{ width: "58px", height: "45px" }}
                  />
                  <VitecCardToggleModalButton
                    color="primary"
                    style={{ width: "58px", height: "45px" }}
                  />
                </div>
              </VitecCardDefaultViewHeader>
              <VitecCardDefaultViewContent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "25rem",
                }}
              >
                <HistoricalSeriesComposedGraphic
                  activeLines={activeLines}
                  areasNames={appliedFilters.territorializations.map(
                    ({ label }) => label
                  )}
                  data={filteredHistoricalSeriesList}
                  handleLegendClick={handleLegendClick}
                  variant={caseReferenceType}
                />
              </VitecCardDefaultViewContent>
              <VitecCardDefaultViewFooter>
                <VitecCardGeneratedAt />
              </VitecCardDefaultViewFooter>
            </VitecCardDefaultView>
          </VitecCardModalContent>
        </VitecCardModal>
        <VitecCardDefaultView>
          <VitecCardDefaultViewHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bold", color: "black" }}>
              {caseReferenceType === NOTIFICATIONS
                ? "Casos Notificados"
                : "Casos Confirmados"}
              <br />
              {legend}
            </span>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".3rem" }}
            >
              <VitecCardDownloadImageButton
                imageFileName={`SERIE_HISTORICA_SINAN_VITEC_${moment().format(
                  "DD/MM/YYYY HH:mm"
                )}`}
                color="primary"
                style={{ width: "58px", height: "45px" }}
              />
              <VitecCardToggleModalButton
                color="primary"
                style={{ width: "58px", height: "45px" }}
              />
            </div>
          </VitecCardDefaultViewHeader>
          <VitecCardDefaultViewContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25rem",
            }}
          >
            <HistoricalSeriesComposedGraphic
              activeLines={activeLines}
              areasNames={appliedFilters.territorializations.map(
                ({ label }) => label
              )}
              data={filteredHistoricalSeriesList}
              handleLegendClick={handleLegendClick}
              variant={caseReferenceType}
            />
          </VitecCardDefaultViewContent>
          <VitecCardDefaultViewFooter>
            <VitecCardGeneratedAt />
          </VitecCardDefaultViewFooter>
        </VitecCardDefaultView>
      </VitecCard>
    );

  if (selectedGraphicsType.value === "weeks")
    return (
      <ComposedChartGraphic
        activeLines={activeLines}
        caseReferenceType={caseReferenceType}
        data={casesHistoricalSeriesByWeeks}
        handleLegendClick={handleLegendClick}
        headerText={headerText}
        handleSaveDiagramConfiguration={handleSaveDiagramConfiguration}
        handleYearsChange={handleYearsChange}
        variant={selectedGraphicsType.value}
        yearsOptions={yearsOptions}
        yearsToNotShowControlDiagrams={yearsToNotShowControlDiagrams}
        graphicsTypeOptions={graphicsTypeOptions}
        handleGraphicsTypeChange={handleGraphicsTypeChange}
        selectedGraphicsType={selectedGraphicsType}
      />
    );

  if (selectedGraphicsType.value === "years")
    return (
      <LineChartGraphic
        brushBar={true}
        data={casesHistoricalSeriesByYears}
        xAxisDataKey="label"
        headerText={headerText}
        legend={<Legend onClick={(e) => handleLegendClick(e.dataKey)} />}
        lines={[
          ...getWeeks().map(({ year }, index) => (
            <Line
              key={year}
              type="monotone"
              dataKey={year}
              name={year}
              stroke={getColorByIndex(index)}
              hide={activeLines[+year]}
            />
          )),
          <Line
            type="monotone"
            dataKey="average"
            name="Média Móvel"
            stroke="#00a2ff"
            hide={activeLines.average}
          />,
          <Line
            type="monotone"
            dataKey="superiorLimit"
            name="Limite Superior"
            stroke="#ff0000"
            hide={activeLines.superiorLimit}
          />,
        ]}
        headerContent={
          <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
            <div>
              <ReactTooltip
                effect="solid"
                type="info"
                id={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopovesTooltip"
                    : "notificationsCasesPopovesTooltip"
                }
              >
                Ocultar anos do diagrama
              </ReactTooltip>
              <Button
                id={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopoves"
                    : "notificationsCasesPopoves"
                }
                color="primary"
                type="button"
                data-tip
                data-for={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopovesTooltip"
                    : "notificationsCasesPopovesTooltip"
                }
              >
                <i className="fa fa-filter"></i>
              </Button>
              <UncontrolledPopover
                placement="top"
                target={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopoves"
                    : "notificationsCasesPopoves"
                }
                offset="-120, 8"
                style={{ width: "300px" }}
              >
                <PopoverHeader>Filtros</PopoverHeader>
                <PopoverBody style={{ backgroundColor: "#ffffff" }}>
                  <Row className="mb-2">
                    <Col>
                      <label
                        htmlFor="beginDate"
                        style={{ fontWeight: "bolder" }}
                      >
                        Anos a ocultar do diagrama:
                      </label>
                      <Select
                        styles={selectComponentStyles}
                        placeholder={"Selecione"}
                        isMulti={true}
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        isClearable={true}
                        value={yearsToNotShowControlDiagrams}
                        options={yearsOptions.sort(
                          (a, b) => +b.value - +a.value
                        )}
                        onChange={(e) =>
                          handleYearsChange(!e || e.length === 0 ? [] : e)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      {checkPermissionComponent(
                        "85f27883-ca03-4e2a-b97e-6b2f89b555fc",
                        "update"
                      ) ? (
                        <Button
                          color="primary"
                          onClick={() => handleSaveDiagramConfiguration()}
                        >
                          Salvar alterações
                        </Button>
                      ) : (
                        <Button color="primary" disabled>
                          Sem permissão para salvar alterações
                        </Button>
                      )}
                    </Col>
                  </Row>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
            <div style={{ width: "130px" }}>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={selectedGraphicsType}
                options={graphicsTypeOptions}
                onChange={(e) => handleGraphicsTypeChange(e)}
              />
            </div>
          </div>
        }
        hideDownloadButton={true}
      />
    );

  if (selectedGraphicsType.value === "yearsSorotypes")
    return (
      <SorotypeHistoricalSeries
        activeLines={activeLines}
        caseReferenceType={caseReferenceType}
        data={sorotypeCasesHistoricalSeriesByYears}
        handleLegendClick={handleLegendClick}
        headerText={headerText}
        handleSaveDiagramConfiguration={handleSaveDiagramConfiguration}
        handleYearsChange={handleYearsChange}
        variant={selectedGraphicsType.value}
        yearsOptions={yearsOptions}
        yearsToNotShowControlDiagrams={yearsToNotShowControlDiagrams}
        graphicsTypeOptions={graphicsTypeOptions}
        handleGraphicsTypeChange={handleGraphicsTypeChange}
        selectedGraphicsType={selectedGraphicsType}
      />
    );
};

const ComposedChartGraphic = ({
  activeLines,
  caseReferenceType,
  data,
  graphicsTypeOptions,
  handleLegendClick,
  handleGraphicsTypeChange,
  handleSaveDiagramConfiguration,
  handleYearsChange,
  headerText,
  selectedGraphicsType,
  variant,
  yearsOptions,
  yearsToNotShowControlDiagrams,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggleModal()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              dataToDownload={data}
              headerText={headerText}
              showExpandButton={true}
              showButtonsTooltips={false}
              showDownloadDataButton={false}
              handleExpandButtonClick={toggleModal}
            />
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>
                  <ComposedGraphic
                    activeLines={activeLines}
                    caseReferenceType={caseReferenceType}
                    data={data}
                    handleLegendClick={handleLegendClick}
                    variant={variant}
                  />
                </Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div></div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader
          dataToDownload={data}
          headerText={headerText}
          showExpandButton={true}
          showButtonsTooltips={true}
          showDownloadDataButton={false}
          handleExpandButtonClick={toggleModal}
        >
          <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
            <div>
              <ReactTooltip
                effect="solid"
                type="info"
                id={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopovesTooltip"
                    : "notificationsCasesPopovesTooltip"
                }
              >
                Ocultar anos do diagrama
              </ReactTooltip>
              <Button
                id={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopoves"
                    : "notificationsCasesPopoves"
                }
                color="primary"
                type="button"
                data-tip
                data-for={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopovesTooltip"
                    : "notificationsCasesPopovesTooltip"
                }
              >
                <i className="fa fa-filter"></i>
              </Button>
              <UncontrolledPopover
                placement="top"
                target={
                  caseReferenceType === CONFIRMED
                    ? "confirmedCasesPopoves"
                    : "notificationsCasesPopoves"
                }
                offset="-120, 8"
                style={{ width: "300px" }}
              >
                <PopoverHeader>Filtros</PopoverHeader>
                <PopoverBody style={{ backgroundColor: "#ffffff" }}>
                  <Row className="mb-2">
                    <Col>
                      <label
                        htmlFor="beginDate"
                        style={{ fontWeight: "bolder" }}
                      >
                        Anos a ocultar do diagrama:
                      </label>
                      <Select
                        styles={selectComponentStyles}
                        placeholder={"Selecione"}
                        isMulti={true}
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        isClearable={true}
                        value={yearsToNotShowControlDiagrams}
                        options={yearsOptions.sort(
                          (a, b) => +b.value - +a.value
                        )}
                        onChange={(e) =>
                          handleYearsChange(!e || e.length === 0 ? [] : e)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      {checkPermissionComponent(
                        "85f27883-ca03-4e2a-b97e-6b2f89b555fc",
                        "update"
                      ) ? (
                        <Button
                          color="primary"
                          onClick={() => handleSaveDiagramConfiguration()}
                        >
                          Salvar alterações
                        </Button>
                      ) : (
                        <Button color="primary" disabled>
                          Sem permissão para salvar alterações
                        </Button>
                      )}
                    </Col>
                  </Row>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
            <div style={{ width: "130px" }}>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={selectedGraphicsType}
                options={graphicsTypeOptions}
                onChange={(e) => handleGraphicsTypeChange(e)}
              />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Row>
            <Col style={styles.col}>
              <ComposedGraphic
                activeLines={activeLines}
                caseReferenceType={caseReferenceType}
                data={data}
                handleLegendClick={handleLegendClick}
                variant={variant}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter style={styles.cardFooter}>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

const ComposedGraphic = ({
  activeLines,
  caseReferenceType,
  data,
  handleLegendClick,
  variant,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis
          yAxisId="left"
          tickFormatter={(value) => `${value}%`}
          domain={[0, 100]}
        />
        <YAxis yAxisId="right" orientation="right" />
        <Bar
          dataKey="sorotypeOnePercentage"
          name={"Sorotipo 1"}
          stackId="a"
          fill="#4C82D8"
          yAxisId={"left"}
          hide={activeLines.sorotypeOnePercentage}
        />
        <Bar
          dataKey="sorotypeTwoPercentage"
          name={"Sorotipo 2"}
          stackId="a"
          fill={getColorByIndex(1)}
          yAxisId={"left"}
          hide={activeLines.sorotypeTwoPercentage}
        />
        <Bar
          dataKey="sorotypeThreePercentage"
          name={"Sorotipo 3"}
          stackId="a"
          fill={getColorByIndex(2)}
          yAxisId={"left"}
          hide={activeLines.sorotypeThreePercentage}
        />
        <Bar
          dataKey="sorotypeFourPercentage"
          name={"Sorotipo 4"}
          stackId="a"
          fill={getColorByIndex(3)}
          yAxisId={"left"}
          hide={activeLines.sorotypeFourPercentage}
        />
        <Line
          type="monotone"
          dataKey="average"
          name="Média Móvel"
          stroke="#00a2ff"
          hide={activeLines.average}
          yAxisId={"right"}
          strokeWidth={variant === "weeks" ? null : 3}
        />
        <Line
          type="monotone"
          dataKey="superiorLimit"
          name="Limite Superior"
          stroke="#ff0000"
          hide={activeLines.superiorLimit}
          yAxisId={"right"}
          strokeWidth={variant === "weeks" ? null : 3}
        />
        {variant === "weeks" ? (
          <Line
            type="monotone"
            dataKey="cases"
            name={
              caseReferenceType === NOTIFICATIONS
                ? "Notificações"
                : "Confirmados"
            }
            stroke="#000000"
            yAxisId={"right"}
            hide={activeLines.cases}
          />
        ) : (
          getWeeks().map(({ year }, index) => (
            <Line
              key={year}
              type="monotone"
              dataKey={year}
              name={year}
              stroke={getColorByIndex(index)}
              hide={activeLines[+year]}
              yAxisId={"right"}
            />
          ))
        )}
        <Tooltip />
        <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
        <Brush startIndex={Math.round(data.length / 4)} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const HistoricalSeriesComposedGraphic = ({
  activeLines,
  areasNames,
  data,
  handleLegendClick,
  variant,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis
          yAxisId="left"
          tickFormatter={(value) => `${value}%`}
          domain={[0, 100]}
        />
        <YAxis yAxisId="right" orientation="right" />
        <Bar
          dataKey="sorotypeOnePercentage"
          name={"Sorotipo 1"}
          stackId="a"
          fill="#4C82D8"
          yAxisId={"left"}
          hide={activeLines.sorotypeOnePercentage}
        />
        <Bar
          dataKey="sorotypeTwoPercentage"
          name={"Sorotipo 2"}
          stackId="a"
          fill={getColorByIndex(1)}
          yAxisId={"left"}
          hide={activeLines.sorotypeTwoPercentage}
        />
        <Bar
          dataKey="sorotypeThreePercentage"
          name={"Sorotipo 3"}
          stackId="a"
          fill={getColorByIndex(2)}
          yAxisId={"left"}
          hide={activeLines.sorotypeThreePercentage}
        />
        <Bar
          dataKey="sorotypeFourPercentage"
          name={"Sorotipo 4"}
          stackId="a"
          fill={getColorByIndex(3)}
          yAxisId={"left"}
          hide={activeLines.sorotypeFourPercentage}
        />
        {areasNames.length === 0 ? (
          <Line
            key={"Município"}
            type="monotone"
            dataKey={
              variant === NOTIFICATIONS
                ? "MunicípioNotifiedCases"
                : "MunicípioConfirmedCases"
            }
            name={variant === NOTIFICATIONS ? "Notificações" : "Confirmados"}
            stroke={"#000000"}
            yAxisId={"right"}
          />
        ) : (
          areasNames.map((name, index) => {
            return (
              <Line
                key={name}
                type="monotone"
                dataKey={
                  variant === NOTIFICATIONS
                    ? `${name}NotifiedCases`
                    : `${name}ConfirmedCases`
                }
                name={name}
                stroke={getColorByIndex(index + 4)}
                yAxisId={"right"}
              />
            );
          })
        )}
        <Tooltip />
        <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
        <Brush sta />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
