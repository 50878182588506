import React from 'react'
import { Select } from '../Wrappers/SelectAll'
import { monthsOptionsList, selectComponentStyles } from '../../services/utils/globalFunctions'

export const MonthWeekFilter = ({
  filterName,
  handleFiltersChange,
  value,
  ...props
}) => {
  return (
    <div>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isMulti={false}
        isClearable={true}
        value={value}
        options={monthsOptionsList}
        onChange={(e) =>
          handleFiltersChange(
            filterName,
            !e
              ? {
                  label: "Selecione",
                  value: "",
                }
              : e
          )
        }
        {...props}
      />
    </div>
  )
}
