import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Skeleton from "../Skeleton";

const VerticalBarGraphicLoadingSkeleton = () => {
  return (
    <Card>
      <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton variant="rectangle" height="1rem" width="100px" />
        <Skeleton variant="rectangle" height="1rem" width="50px" />
      </CardHeader>
      <CardBody className="pb-0 pt-0 pl-0 pr-0">
        <div
          style={{
            height: "430px",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          <Skeleton variant="rectangle" height="14rem" width="5rem" />
          <Skeleton variant="rectangle" height="17rem" width="5rem" />
          <Skeleton variant="rectangle" height="20rem" width="5rem" />
        </div>
      </CardBody>
    </Card>
  );
};

export default VerticalBarGraphicLoadingSkeleton;
