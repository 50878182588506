import React from "react";

import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";
import getWeeks from "../../services/utils/epidemiologicalWeek";

const ManualEpidemiologicWeeksFilter = ({
  filterName,
  handleFiltersChange,
  value,
  year,
  ...props
}) => {
  const [epidemiologicalWeeksOptionsList, setEpidemiologicalWeeksOptionsList] =
    React.useState([]);

  React.useEffect(() => {
    if (year === "") return;

    const epidemiologicalWeeksFromYear = getWeeks().find(
      (week) => week.year === year
    );

    if (!epidemiologicalWeeksFromYear) return;

    const epidemiologicalWeeksList = epidemiologicalWeeksFromYear.weeks.map(
      ({ beginDate, endDate, label }) => ({
        beginDate,
        endDate,
        label,
        value: endDate,
      })
    );

    setEpidemiologicalWeeksOptionsList(epidemiologicalWeeksList);
  }, [year]);

  return (
    <div>
      {/* <span className="h4 text-white">Semanas (manual)</span> */}
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isMulti={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        isClearable={true}
        value={value}
        options={epidemiologicalWeeksOptionsList}
        onChange={(e) =>
          handleFiltersChange(filterName, !e || e.length === 0 ? [] : e)
        }
        {...props}
      />
    </div>
  );
};

export default ManualEpidemiologicWeeksFilter;
