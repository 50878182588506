import React from "react";

import { createContext } from "use-context-selector";
import { DEFAULT_TERRITORIALIZATIONS_REPORT_FILTERS } from "../Components/Filters/TerritorializationsReportFilters";

export const TerritorializationsReportContext = createContext(null);

export const TerritorializationsReportContextProvider = ({ children }) => {
  const [appliedFilters, setAppliedFilters] = React.useState(
    DEFAULT_TERRITORIALIZATIONS_REPORT_FILTERS
  );
  const [downloadableData, setDownloadableData] = React.useState({
    rawActivitiesByPeriodsFromPolygons: [],
    rawActivitiesByUsers: [],
  });
  const [trapsByPeriodsList, setTrapsByPeriodsList] = React.useState([]);
  const [isTrapsByPeriodsListLoading, setIsTrapsByPeriodsListLoading] = React.useState(false);

  return (
    <TerritorializationsReportContext.Provider
      value={{
        appliedFilters,
        downloadableData,
        trapsByPeriodsList,
        isTrapsByPeriodsListLoading,
        setAppliedFilters,
        setDownloadableData,
        setTrapsByPeriodsList,
        setIsTrapsByPeriodsListLoading,
      }}
    >
      {children}
    </TerritorializationsReportContext.Provider>
  );
};
