import React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import { Select } from "../../../../components/Wrappers/SelectAll";
import {
  getColorByIndex,
  selectComponentStyles,
} from "../../../../services/utils/globalFunctions";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { NOTIFICATIONS } from "../SinanPage";

export const SorotypeHistoricalSeries = ({
  activeLines,
  caseReferenceType,
  data,
  graphicsTypeOptions,
  handleLegendClick,
  handleGraphicsTypeChange,
  headerText,
  selectedGraphicsType,
  variant,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggleModal()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              dataToDownload={data}
              headerText={headerText}
              showExpandButton={true}
              showButtonsTooltips={false}
              showDownloadDataButton={false}
              handleExpandButtonClick={toggleModal}
            />
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>
                  <ComposedGraphic
                    activeLines={activeLines}
                    caseReferenceType={caseReferenceType}
                    data={data}
                    handleLegendClick={handleLegendClick}
                    variant={variant}
                  />
                </Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div></div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader
          dataToDownload={data}
          headerText={headerText}
          showExpandButton={true}
          showButtonsTooltips={true}
          showDownloadDataButton={false}
          handleExpandButtonClick={toggleModal}
        >
          <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
            <div style={{ width: "130px" }}>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={selectedGraphicsType}
                options={graphicsTypeOptions}
                onChange={(e) => handleGraphicsTypeChange(e)}
              />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Row>
            <Col style={styles.col}>
              <ComposedGraphic
                activeLines={activeLines}
                caseReferenceType={caseReferenceType}
                data={data}
                handleLegendClick={handleLegendClick}
                variant={variant}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter style={styles.cardFooter}>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

const ComposedGraphic = ({
  activeLines,
  caseReferenceType,
  data,
  handleLegendClick,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis
          yAxisId="left"
          tickFormatter={(value) => `${value}%`}
          domain={[0, 100]}
        />
        <YAxis yAxisId="right" orientation="right" />
        <Bar
          dataKey="sorotypeOnePercentage"
          name={"Sorotipo 1"}
          stackId="a"
          fill="#4C82D8"
          yAxisId={"left"}
          hide={activeLines.sorotypeOnePercentage}
        />
        <Bar
          dataKey="sorotypeTwoPercentage"
          name={"Sorotipo 2"}
          stackId="a"
          fill={getColorByIndex(1)}
          yAxisId={"left"}
          hide={activeLines.sorotypeTwoPercentage}
        />
        <Bar
          dataKey="sorotypeThreePercentage"
          name={"Sorotipo 3"}
          stackId="a"
          fill={getColorByIndex(2)}
          yAxisId={"left"}
          hide={activeLines.sorotypeThreePercentage}
        />
        <Bar
          dataKey="sorotypeFourPercentage"
          name={"Sorotipo 4"}
          stackId="a"
          fill={getColorByIndex(3)}
          yAxisId={"left"}
          hide={activeLines.sorotypeFourPercentage}
        />
        <Line
          type="monotone"
          dataKey="cases"
          name={
            caseReferenceType === NOTIFICATIONS ? "Notificações" : "Confirmados"
          }
          stroke="#ff0000"
          strokeWidth={3}
          yAxisId={"right"}
          hide={activeLines.cases}
        />
        <Tooltip />
        <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
        <Brush />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
