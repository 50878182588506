import React, { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import { fetchDemands } from "../../../services/api/Demand";
import {
  getAllVigilanciasByFilter,
  getVisitsByDemandId,
  getVisitsForReport,
  getVisitsVigilanciaByDemandId,
} from "../../../services/api/Visits";
import DataTable from "react-data-table-component";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  timeStampFormated,
  timeStampFormatedMongo,
} from "../../../services/utils/format";
import { getUserData } from "../../../services/utils/auth";
import LoadingSpin from "react-loading-spin";
import { getVersion } from "../../../constants/VersionConstant";
import { checkPermissionComponent } from "../../../services/utils/permission";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { fetchEmployeeData } from "../../../services/api/Employee";
import {
  getUser,
  getUserEmployeeDataByOrganization,
} from "../../../services/api/Users";
import "./Visits.css";
import { Select } from "../../../components/Wrappers/SelectAll";

const Visits = (React.FC = () => {
  const [visits, setVisits] = useState([]);
  const [mainVisits, setMainVisits] = useState([]);
  const [loadingVisits, setLoadingVisits] = useState(true);
  const [beginDate, setBeginDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [dateChanged, setDateChanged] = useState(false);
  const [modalOpen, setModalOpen] = useState("");
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isOpenInformations, setIsOpenInformations] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [visitSituationOptions, setVisitSituationOptions] = useState([
    { label: "Fechado", value: "Fechado" },
    { label: "Aberto", value: "Aberto" },
    { label: "Recuperação", value: "Recuperação" },
    { label: "Orientação", value: "Orientação" },
    { label: "Endereço não encontrado", value: "Endereço não encontrado" },
  ]);
  const [selectedVisitSituation, setSelectedVisitSituation] = useState("");
  const [visitTypeOptions, setVisitTypeOptions] = useState([
    { label: "Instalação", value: "Instalação" },
    { label: "Vistoria", value: "Vistoria" },
    { label: "Remoção", value: "Remoção" },
  ]);
  const [selectedVisitType, setSelectedVisitType] = useState("");

  const organizationId = getUserData("organizationId");
  const webVersion = getVersion(organizationId);

  const formatAddress = (address) => {
    return `${address.localDoImovel} ${address.logradouro}, ${address.numero}`;
  };
  const employeeColumn = (collectorId) => {
    var employeeByUserId = employeeList.filter(
      (employee) => employee.value == collectorId
    );
    var employeeName = "";
    if (employeeByUserId.length > 0) {
      employeeName = employeeByUserId[0].employeeName;
    } else {
      var employeeByEmployeeId = employeeList.filter(
        (employee) => employee.employeeId == collectorId
      );
      employeeName = employeeByEmployeeId[0].employeeName;
    }
    return employeeName;
  };

  const employee = async (id) => {
    setEmployeeName(null);
    try {
      const responseEmployeeId = await getUser(id);

      const response = await fetchEmployeeData(
        responseEmployeeId.data.employeeId
      );

      setEmployeeName(response.data.name);
    } catch (error) {
      console.error("Erro ao obter dados do funcionário:", error);
    }
  };

  const toggleRow = (index) => {
    const newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows.splice(newExpandedRows.indexOf(index), 1);
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleItemClick = (index, key) => {
    if (expandedIndex == `${index}-${key}`) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(`${index}-${key}`);
    }
  };

  const selectSituation = (situation) => {
    if (situation == "Aberto" && webVersion == "samp_version") {
      return "Trabalhado";
    } else {
      return situation;
    }
  };

  const selectVisitType = (traps) => {
    let visitType = "";
    let armadilhaRetirada = false;
    let armadilhaLidaNaVisita = false;
    let armadilhaInstaladaNaVisita = false;

    Object.keys(traps).forEach((key) => {
      if (
        ![
          "id",
          "outra",
          "createAt",
          "updateAt",
          "armadilhas",
          "armadilhaParaMosquito",
        ].includes(key) &&
        traps[key]
      ) {
        traps[key].forEach((trap) => {
          if (trap.armadilhaRetirada) {
            armadilhaRetirada = true;
          }
          if (trap.armadilhaLidaNaVisita) {
            armadilhaLidaNaVisita = true;
          }
          if (trap.armadilhaInstaladaNaVisita) {
            armadilhaInstaladaNaVisita = true;
          }
        });
      }
    });

    if (armadilhaRetirada) {
      visitType =
        visitType.length === 0
          ? "Desinstalação"
          : `${visitType}, Desinstalação`;
    }
    if (armadilhaLidaNaVisita) {
      visitType =
        visitType.length === 0 ? "Vistoria" : `${visitType}, Vistoria`;
    }
    if (armadilhaInstaladaNaVisita) {
      visitType =
        visitType.length === 0 ? "Instalação" : `${visitType}, Instalação`;
    }

    return visitType;
  };

  const buildButtons = (visit) => {
    return (
      <>
        <div
          className="column"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <div className="column">
            {checkPermissionComponent(
              "2d31ebee-53e3-4297-b498-4730e9b9e656",
              "read"
            ) && (
              <>
                <button
                  data-tip
                  data-for="validateDemandTooltip"
                  style={{ width: "60px", margin: "5px" }}
                  className="btn btn-primary"
                  data-clipboard-text="check-bold"
                  id="tooltip204269497"
                  onClick={() => {
                    setModalOpen(true);
                    setSelectedVisit(visit);
                    employee(visit.collectorId);
                  }}
                  type="button"
                >
                  <i className="fa fa-search" />
                </button>
                <ReactTooltip
                  effect="solid"
                  type="success"
                  id="validateDemandTooltip"
                >
                  <span>Visualizar visita</span>
                </ReactTooltip>
              </>
            )}
            {checkPermissionComponent(
              "2d31ebee-53e3-4297-b498-4730e9b9e656",
              "update"
            ) && (
              <>
                <Link to={{ pathname: "/admin/EditVisit" }}>
                  <button
                    data-for="editDemandTooltip"
                    style={{ width: "60px", margin: "5px" }}
                    className="btn btn-primary"
                    data-clipboard-text="ni ni-fat-edit"
                    id="tooltip204269497"
                    onClick={() =>
                      localStorage.setItem("VisitItem", JSON.stringify(visit))
                    }
                    data-tip="Alterar demanda"
                    type="button"
                  >
                    <div>
                      <i className="ni ni-ruler-pencil" />
                    </div>
                  </button>
                </Link>
                <ReactTooltip effect="solid" id="editDemandTooltip">
                  <span>Editar visita</span>
                </ReactTooltip>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      name: "Situação",
      selector: "visitSituation",
      sortable: false,
      cell: (v) => selectSituation(v.visitSituation),
    },
    {
      name: "Tipo de visita",
      selector: "armadilha",
      sortable: false,
      cell: (v) => selectVisitType(v.armadilha),
    },
    {
      name: "Endereço",
      selector: "address",
      cell: (v) => formatAddress(v.address),
    },
    {
      name: "Data da Visita",
      selector: "activityDate",
      cell: (v) => timeStampFormated(v.activityDate),
    },
    {
      name: "Funcionário",
      selector: "collectorId",
      cell: (v) => employeeColumn(v.collectorId),
    },
    {
      name: "Formulário",
      selector: "typeForm",
    },
    {
      cell: (row) => buildButtons(row),
      ignoreRowClick: true,
    },
  ];

  const filterByVisitType = (visits, selectedVisitType) => {
    let visitsResponse = [];
    visits.map((visit) => {
      Object.keys(visit.armadilha).forEach((key) => {
        if (
          ![
            "id",
            "outra",
            "createAt",
            "updateAt",
            "armadilhas",
            "armadilhaParaMosquito",
          ].includes(key) &&
          visit.armadilha[key]
        ) {
          visit.armadilha[key].forEach((trap) => {
            if (trap.armadilhaRetirada && selectedVisitType == "Remoção") {
              visitsResponse.push(visit);
            }
            if (trap.armadilhaLidaNaVisita && selectedVisitType == "Vistoria") {
              visitsResponse.push(visit);
            }
            if (
              trap.armadilhaInstaladaNaVisita &&
              selectedVisitType == "Instalação"
            ) {
              visitsResponse.push(visit);
            }
          });
        }
      });
    });
    return visitsResponse;
  };

  const fetchAllEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pela lista de funcionários");

      const formatedEmployeesData = data.map(
        ({ userId, employeeId, employeeName }) => {
          return {
            employeeId,
            employeeName,
            userId,
            label: employeeName,
            value: userId,
          };
        }
      );

      return [
        {
          value: "00000000-0000-0000-0000-000000000000",
          label: "Selecione",
        },
        ...formatedEmployeesData,
      ];
    } catch (error) {
      console.error(error);
    }
  };

  function renderDepositosPositivos(selectedVisit) {
    var hasPositiveDeposits = false;
    var component = Object.keys(selectedVisit.mosquitos.depositosPositivos).map(
      (key, index) => {
        if (selectedVisit.mosquitos.depositosPositivos[key]) {
          hasPositiveDeposits = true;

          return <li key={index}>{key}</li>;
        }
      }
    );

    return (
      <>
        {hasPositiveDeposits ? (
          <>
            <hr className="hr-modal" />

            <h4>Depósito(s) Positivo(s): </h4>

            <ul>{component}</ul>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  const applyFilter = () => {
    setLoadingVisits(true);

    var finalActivityDate = new Date(finalDate);

    finalActivityDate.setUTCDate(finalActivityDate.getUTCDate() + 1);

    var ano = finalActivityDate.getUTCFullYear();
    var mes = ("0" + (finalActivityDate.getUTCMonth() + 1)).slice(-2);
    var dia = ("0" + finalActivityDate.getUTCDate()).slice(-2);

    var stringData_final = ano + "-" + mes + "-" + dia;

    var filter = {
      periods: {
        beginDate: beginDate,
        finalDate: stringData_final,
      },
      organizationId: organizationId,
    };

    if (dateChanged) {
      getAllVigilanciasByFilter(filter).then((response) => {
        var responseFunc = response.data;
        setMainVisits(responseFunc);

        if (responseFunc && responseFunc.length > 0) {
          if (selectedVisitSituation && selectedVisitSituation != "") {
            responseFunc = responseFunc.filter(
              (v) => v.visitSituation == selectedVisitSituation
            );
          }

          if (employeeId && employeeId != "") {
            responseFunc = responseFunc.filter(
              (v) => v.collectorId == employeeId
            );
          }

          if (selectedVisitType && selectedVisitType != "") {
            responseFunc = filterByVisitType(responseFunc, selectedVisitType);
          }
        }

        setVisits(responseFunc);
        setLoadingVisits(false);
      });
    } else {
      var wasChanged =
        (employeeId && employeeId != "") ||
        (selectedVisitSituation && selectedVisitSituation != "") ||
        (selectedVisitType && selectedVisitType != "");
      if (wasChanged) {
        var visitsAux = [...mainVisits];

        if (employeeId && employeeId != "") {
          visitsAux = visitsAux.filter((v) => v.collectorId == employeeId);
        }

        if (selectedVisitSituation && selectedVisitSituation != "") {
          visitsAux = visitsAux.filter(
            (v) => v.visitSituation == selectedVisitSituation
          );
        }

        if (selectedVisitType && selectedVisitType != "") {
          visitsAux = filterByVisitType(visitsAux, selectedVisitType);
        }

        setVisits(visitsAux);
        setLoadingVisits(false);
      } else {
        setVisits(mainVisits);
        setLoadingVisits(false);
      }
    }

    setDateChanged(false);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      var employeesData = await fetchAllEmployeesData();
      setEmployeeList(employeesData);
    };

    fetchEmployees();

    var activityDate = new Date();
    activityDate.setDate(activityDate.getDate() - 19);
    var ano = activityDate.getFullYear();
    var mes = ("0" + (activityDate.getMonth() + 1)).slice(-2);
    var dia = ("0" + activityDate.getDate()).slice(-2);

    var stringData_begin = ano + "-" + mes + "-" + dia;

    setBeginDate(stringData_begin);

    var finalActivityDate = new Date();
    finalActivityDate.setUTCDate(finalActivityDate.getUTCDate() + 1);
    var ano = finalActivityDate.getUTCFullYear();
    var mes = ("0" + (finalActivityDate.getUTCMonth() + 1)).slice(-2);
    var dia = ("0" + finalActivityDate.getUTCDate()).slice(-2);

    var stringData_final = ano + "-" + mes + "-" + dia;

    setFinalDate(stringData_final);

    var filter = {
      periods: {
        beginDate: stringData_begin,
        finalDate: stringData_final,
      },
      organizationId: organizationId,
    };
    getAllVigilanciasByFilter(filter).then((response) => {
      setVisits(response.data);
      setMainVisits(response.data);

      setLoadingVisits(false);
    });
  }, []);
  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      {checkPermissionComponent(
        "2d31ebee-53e3-4297-b498-4730e9b9e656",
        "read"
      ) ? (
        <div style={{ margin: 30 }}>
          <Modal
            size="lg"
            fade="true"
            isOpen={modalOpen}
            className="modal-scrollable"
            toggle={() => setModalOpen(false)}
          >
            <ModalHeader>Visualização Visita</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-12">
                  {selectedVisit ? (
                    <div>
                      <h4>Data Atividade:</h4>
                      <h5>{timeStampFormated(selectedVisit.activityDate)}</h5>
                      <hr className="hr-modal" />
                      <h4>Situação</h4>
                      <h5>{selectSituation(selectedVisit.visitSituation)}</h5>
                      <hr className="hr-modal" />
                      <h4>Quem Fez a Visita:</h4>
                      <h5>
                        {employeeName ? (
                          employeeName
                        ) : (
                          <>
                            <LoadingSpin primaryColor="#fff" size={28} />
                          </>
                        )}
                      </h5>
                      <hr className="hr-modal" />
                      <h4>Endereço:</h4>
                      <h5>{formatAddress(selectedVisit.address)}</h5>
                      {selectedVisit.armadilha.armadilhaParaMosquito ? (
                        <>
                          <hr className="hr-modal" />
                          <h4>Armadilha(s) Instalada(s): </h4>
                        </>
                      ) : (
                        <></>
                      )}

                      {Object.keys(selectedVisit.armadilha).map((key) => {
                        if (
                          key != "id" &&
                          key != "outra" &&
                          key != "createAt" &&
                          key != "updateAt" &&
                          key != "armadilhas" &&
                          key != "armadilhaParaMosquito"
                        ) {
                          var quantity = 0;
                          var icon;
                          var trapList = [];
                          var typeTrap = "";

                          if (selectedVisit.armadilha[key]) {
                            selectedVisit.armadilha[key].map((trap) => {
                              if (
                                trap.numeroArmadilha &&
                                trap.numeroArmadilha != ""
                              ) {
                                quantity++;
                                typeTrap = key;
                                icon = (
                                  <img
                                    width="100px"
                                    src={ARMADILHA_INFO[key].principal_icon}
                                  />
                                );
                                trapList.push({
                                  numeroArmadilha: trap.numeroArmadilha,
                                  armadilhaRetirada: trap.armadilhaRetirada,
                                  armadilhaLidaNaVisita:
                                    trap.armadilhaLidaNaVisita,
                                  armadilhaInstaladaNaVisita:
                                    trap.armadilhaInstaladaNaVisita,
                                  manutencao:
                                    trap.emergenciaMosquitoArmadilha
                                      .emergenciaMosquitoArmadilha,
                                  emregencia: trap.manutencao.manutencao,
                                  createAt: timeStampFormatedMongo(
                                    trap.createAt
                                  ),
                                  amostras: trap.amostras,
                                  amostrasColetadas: trap.amostrasColetadas,
                                });
                              }
                            });
                          }

                          if (quantity > 0) {
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    if (isOpenInformations == typeTrap) {
                                      setIsOpenInformations(null);
                                    } else {
                                      setIsOpenInformations(typeTrap);
                                    }
                                  }}
                                >
                                  <h4>{quantity}x</h4> {icon}
                                </div>
                                {isOpenInformations &&
                                  isOpenInformations === typeTrap && (
                                    <table className="styled-table">
                                      <thead>
                                        <tr>
                                          <th className="styled-table-header">
                                            Numero
                                            <br />
                                            Armadilha
                                          </th>
                                          <th className="styled-table-header">
                                            Amostra
                                            <br />
                                            Colhetada
                                          </th>
                                          <th className="styled-table-header">
                                            Teve
                                            <br />
                                            Manutenção
                                          </th>
                                          <th className="styled-table-header">
                                            Teve Emergência
                                            <br />
                                            de Mosquito
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {trapList.map((trap, index) => (
                                          <>
                                            <tr
                                              key={index}
                                              onClick={() => toggleRow(index)}
                                            >
                                              <td className="styled-table-columns">
                                                {trap.numeroArmadilha}
                                              </td>
                                              <td className="styled-table-columns">
                                                {trap.amostras ? "Sim" : "Não"}
                                              </td>
                                              <td className="styled-table-columns">
                                                {trap.manutencao
                                                  ? "Sim"
                                                  : "Não"}
                                              </td>
                                              <td className="styled-table-columns">
                                                {trap.emergenciaMosquitoArmadilha
                                                  ? "Sim"
                                                  : "Não"}
                                              </td>
                                            </tr>
                                            {expandedRows.includes(index) && (
                                              <tr className="expanded-row">
                                                <td colSpan={4}>
                                                  <div className="dropdown-content p-2">
                                                    {selectSituation(
                                                      selectedVisit.visitSituation
                                                    ) == "Aberto" ||
                                                    selectSituation(
                                                      selectedVisit.visitSituation
                                                    ) == "Recuperação" ? (
                                                      <>
                                                        <h5>
                                                          Instalou Armadilha:{" "}
                                                          {trap.armadilhaInstaladaNaVisita
                                                            ? "Sim"
                                                            : "Não"}
                                                        </h5>
                                                        <h5>
                                                          Vistoriou Armadilha:{" "}
                                                          {trap.armadilhaLidaNaVisita
                                                            ? "Sim"
                                                            : "Não"}
                                                        </h5>
                                                        <h5>
                                                          Retirou Armadilha:{" "}
                                                          {trap.armadilhaRetirada
                                                            ? "Sim"
                                                            : "Não"}
                                                        </h5>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <h5>
                                                          Instalaria Armadilha:{" "}
                                                          {trap.armadilhaInstaladaNaVisita
                                                            ? "Sim"
                                                            : "Não"}
                                                        </h5>
                                                        <h5>
                                                          Vistoriaria Armadilha:{" "}
                                                          {trap.armadilhaLidaNaVisita
                                                            ? "Sim"
                                                            : "Não"}
                                                        </h5>
                                                        <h5>
                                                          Retiraria Armadilha:{" "}
                                                          {trap.armadilhaRetirada
                                                            ? "Sim"
                                                            : "Não"}
                                                        </h5>
                                                      </>
                                                    )}

                                                    {trap.amostras ? (
                                                      <>
                                                        <hr className="hr-modal" />
                                                        <h5>
                                                          Amostras Colhetadas:
                                                        </h5>
                                                        <table className="styled-table">
                                                          <thead>
                                                            <tr>
                                                              <th className="styled-table-header">
                                                                Número da
                                                                amostra
                                                              </th>
                                                              <th className="styled-table-header">
                                                                Quantidade
                                                              </th>
                                                              <th className="styled-table-header">
                                                                Tipo de amostra
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {trap.amostrasColetadas.map(
                                                              (amostra) => (
                                                                <>
                                                                  <td className="styled-table-columns">
                                                                    {
                                                                      amostra.numeroDaAmostra
                                                                    }
                                                                  </td>
                                                                  <td className="styled-table-columns">
                                                                    {
                                                                      amostra.quantidade
                                                                    }
                                                                  </td>
                                                                  <td className="styled-table-columns">
                                                                    {
                                                                      amostra.tipoDeAmostra
                                                                    }
                                                                  </td>
                                                                </>
                                                              )
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <h5>
                                                          Nenhuma amostra
                                                          colhetada
                                                        </h5>
                                                      </>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        ))}
                                      </tbody>
                                    </table>
                                  )}
                              </>
                            );
                          }
                        }
                      })}
                      <hr className="hr-modal" />
                      {selectedVisit.midias &&
                      selectedVisit.midias.photos.length > 0 ? (
                        <>
                          <h4>Fotos:</h4>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gap: "16px",
                            }}
                          >
                            {selectedVisit.midias.photos.map(
                              ({ photoFile64 }, index) => {
                                return (
                                  <div>
                                    <div style={{ marginBottom: "16px" }}>
                                      <img
                                        src={photoFile64}
                                        alt=""
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        const link =
                                          document.createElement("a");
                                        link.download = `imagem${
                                          index + 1
                                        }.png`;
                                        link.href = photoFile64;
                                        link.click();
                                      }}
                                    >
                                      Baixar
                                    </Button>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : null}
                      {webVersion == "normal_version" ? (
                        <>{renderDepositosPositivos(selectedVisit)}</>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <h4>Visita não encontrada</h4>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="row">
                <div className="col-12">
                  <button
                    className="form-control"
                    onClick={() => setModalOpen(false)}
                  >
                    FECHAR
                  </button>
                </div>
              </div>
            </ModalFooter>
          </Modal>
          <Row>
            <Col className="col-3 linha-form col-md-3 geo-responsive-filter ">
              <span className="h4 text-white">Data início</span>
              <input
                value={beginDate}
                onChange={(e) => {
                  setBeginDate(e.target.value);
                  setDateChanged(true);
                }}
                type="date"
                className="form-control"
              />
            </Col>
            <Col className="col-3 linha-form col-md-3 geo-responsive-filter ">
              <span className="h4 text-white">Data Fim</span>
              <input
                value={finalDate}
                onChange={(e) => {
                  setFinalDate(e.target.value);
                  setDateChanged(true);
                }}
                type="date"
                className="form-control"
              />
            </Col>
            <Col className="col-3 linha-form col-md-3 geo-responsive-filter ">
              <span className="h4 text-white">Funcionário</span>
              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    minHeight: "2.90rem",
                    borderRadius: "0.370rem",
                  }),
                  option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
                  valueContainer: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                }}
                placeholder={"Selecione"}
                isClearable={true}
                options={employeeList}
                value={employeeList.filter(({ value }) => value == employeeId)}
                onChange={(e) => {
                  e ? setEmployeeId(e.value) : setEmployeeId(null);
                }}
              />
            </Col>

            <Col className="col-3 linha-form col-md-3 geo-responsive-filter ">
              <span className="h4 text-white">Situação</span>
              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    minHeight: "2.90rem",
                    borderRadius: "0.370rem",
                  }),
                  option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
                  valueContainer: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                }}
                placeholder={"Selecione"}
                isClearable={true}
                options={visitSituationOptions}
                value={visitSituationOptions.filter(
                  ({ value }) => value == selectedVisitSituation
                )}
                onChange={(e) => {
                  e
                    ? setSelectedVisitSituation(e.value)
                    : setSelectedVisitSituation(null);
                }}
              />
            </Col>

            <Col className="col-3 linha-form col-md-3 geo-responsive-filter ">
              <span className="h4 text-white">Tipo de visita</span>
              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    minHeight: "2.90rem",
                    borderRadius: "0.370rem",
                  }),
                  option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
                  valueContainer: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontSize: "0.875rem",
                    color: "#8898aa",
                  }),
                }}
                placeholder={"Selecione"}
                isClearable={true}
                options={visitTypeOptions}
                value={visitTypeOptions.filter(
                  ({ value }) => value == selectedVisitType
                )}
                onChange={(e) => {
                  e
                    ? setSelectedVisitType(e.value)
                    : setSelectedVisitType(null);
                }}
              />
            </Col>

            <Col
              className="col-3 linha-form col-md-3 geo-responsive-filter "
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                Filtrar
              </button>
            </Col>
          </Row>
          {loadingVisits ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingSpin primaryColor="#fff" size={28} />
            </div>
          ) : (
            <Row className="mt-12">
              <Col className="mb-12 mb-xl-0" xl="12">
                <DataTable
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                  columns={columns}
                  data={visits}
                  keyField="id"
                  // onRowClicked={row => this.selectTrap(row)}
                />
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <div
          style={{
            margin: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <i
            class="fa fa-exclamation-triangle"
            style={{ color: "#ffffff", fontSize: "100px" }}
          ></i>
          <p className="h3 text-white">
            Atenção! Você não tem permissão para ler os dados nesta tela!
          </p>
        </div>
      )}
    </div>
  );
});

export default Visits;
