import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { monthsOptionsList, selectComponentStyles } from "../../services/utils/globalFunctions";

export const ManualMonthsFilter = ({
  filterName,
  handleFiltersChange,
  value,
  ...props
}) => {
  return (
    <div>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isMulti={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        isClearable={true}
        value={value}
        options={monthsOptionsList}
        onChange={(e) =>
          handleFiltersChange(filterName, !e || e.length === 0 ? [] : e)
        }
        {...props}
      />
    </div>
  );
};
