import React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import { Map as MapWrapper } from "../../../../components/Maps/Map";
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import {
  area,
  centroid,
  point,
  pointsWithinPolygon,
  polygon,
} from "@turf/turf";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import MarkerClusterGroup from "react-leaflet-markercluster";
import moment from "moment";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import useTrapsList from "../../../../hooks/useTrapsList";
import {
  generateHeatTrapGroupsPointsList,
  generateTrapGroupsPointsList,
  getColorByIndex,
} from "../../../../services/utils/globalFunctions";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import Control from "react-leaflet-control";
import { useNotificationsList } from "../Hooks/useNotificationsList";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import {
  Button,
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { useSinanMapClusters } from "../Hooks/useSinanMapClusters";
import { useContextSelector } from "use-context-selector";
import { SinanMapContext } from "../Contexts/SinanMapContext";
import useTypeLayersList from "../../../../hooks/useTypeLayersList";
import { getUserData } from "../../../../services/utils/auth";
import { useAppliedFilters } from "../Hooks/useAppliedFilters";
import {
  fetchCasesCloropleticByAreas,
  fetchSinanCases,
} from "../../../../services/api/Sinan";
import LoadingSpin from "react-loading-spin";
import { useCasesCloropletic } from "../Hooks/useCasesCloropletic";
import { toast } from "react-toastify";
import { useCasesByAreas } from "../Hooks/useCasesByAreas";
import { useSorotypesByAreas } from "../Hooks/useSorotypesByAreas";
import L from "leaflet";
import ReactTooltip from "react-tooltip";
import { getEntomologicFieldResultsByFilters } from "../../../../services/api/VigilanciaEntomologica";
import { fetchPositiveTrapsFromLab } from "../../../../services/api/Trap";
import { useFieldResultsList } from "../Hooks/useFieldResultsList";
import { usePositiveTrapsFromLabList } from "../Hooks/usePositiveTrapsFromLabList";
import getWeeks from "../../../../services/utils/epidemiologicalWeek";
import { MessageCircleWarning } from "lucide-react";

const { BaseLayer, Overlay } = LayersControl;

export const SinanMap = ({
  fieldPositiveTrapsGroups,
  labPositiveTrapsGroups,
  legend,
  variant = "notifications",
}) => {
  const [activeDrawedPolygonsData, setActiveDrawedPolygonsData] =
      React.useState([]);
  
  const handleActiveDrawedPolygonsChange = React.useCallback((polygonData) => {
    setActiveDrawedPolygonsData((previousValues) => {
      const newValues = [...previousValues];
      newValues.push(polygonData);

      return newValues;
    });
  }, []);

  const handleUpdateDrawedPolygonsChange = React.useCallback((polygonData, idPolygon) => {
    setActiveDrawedPolygonsData((previousValues) => {
      return previousValues.map((polygon) => {
        if(polygon.id === idPolygon) {
          const newPolygonData = { ...polygon, ...polygonData };

          console.log("newPolygonData", newPolygonData)

          return newPolygonData;
        }

        return polygon
      });
    });
  }, []);

  const handleDeleteDrawedPolygon = React.useCallback((idPolygon) => {
    setActiveDrawedPolygonsData((previousValues) => {
      const updatedPolygons = previousValues.filter((polygon) => polygon.id !== idPolygon);
  
      return updatedPolygons;
    });
  }, []);

  return (
    <Card style={{ borderColor: "#dfdfdf" }}>
      <CardHeader
        headerText={
          variant === "notifications" ? (
            <span>
              Mapa de Notificações <br /> {legend}
            </span>
          ) : variant === "confirmed" ? (
            <span>
              Mapa de Confirmados <br /> {legend}
            </span>
          ) : (
            ""
          )
        }
        showDownloadDataButton={false}
        showDownloadImageButton={false}
        showExpandButton={false}
        style={{ borderBottom: "0px", paddingBottom: "0px" }}
      >
        <SinanMapHeaderPopover variant={variant} />
      </CardHeader>
      <CardContent>
        <MapWrapper 
          style={{ height: "50rem" }}
          handleActiveDrawedPolygonsChange={handleActiveDrawedPolygonsChange}
          handleUpdateDrawedPolygonsChange={handleUpdateDrawedPolygonsChange}
          handleDeleteDrawedPolygon={handleDeleteDrawedPolygon}
        >
          <MapToolsControl variant={variant} />
          <MapPolygonsLayersControl variant={variant} />
          <MapActiveTrapsPointsLayersControl />
          <MapTrapsHeatLayersControl />
          <MapFieldPositiveTrapsPointsLayersControl
            fieldPositiveTrapsGroups={fieldPositiveTrapsGroups}
          />
          <MapLabPositiveTrapsPointsLayersControl
            labPositiveTrapsGroups={labPositiveTrapsGroups}
          />
          <MapCasesHeatLayersControl variant={variant} />
          <MapLabPositiveTrapsHeatLayersControl
            labPositiveTrapsGroups={labPositiveTrapsGroups}
          />
          <MapFieldPositiveTrapsHeatLayersControl
            fieldPositiveTrapsGroups={fieldPositiveTrapsGroups}
          />
          <MapCasesLegend variant={variant} />
          <MapCasesCloropleticLegend variant={variant} />
          <MapSorotypesMarkers />
          <MapSorotypesLegend />
        </MapWrapper>
      </CardContent>
      <CardFooter
        style={{
          borderTop: "0px",
          paddingTop: "0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  );
};

const SinanMapHeaderPopover = ({ variant }) => {
  const [isLoadingCasesCloropletic, setIsLoadingCasesCloropletic] =
    React.useState(false);
  const [isLoadingSorotypesForTypeLayer, setIsLoadingSorotypesForTypeLayer] =
    React.useState(false);
  const [selectedTypeLayerId, setSelectedTypeLayerId] = React.useState("");
  const [selectedTypeLayerIdForSorotypes, setSelectedTypeLayerIdForSorotypes] =
    React.useState("");
  const [selectedYear, setSelectedYear] = React.useState("");
  const [isLoadingPositiveTrapsFromLab, setIsLoadingPositiveTrapsFromLab] =
    React.useState(false);
  const [isLoadingFieldPositiveTraps, setisLoadingFieldPositiveTraps] =
    React.useState(false);
  const { typeLayersList } = useTypeLayersList();
  const { appliedFilters } = useAppliedFilters();
  const { trapTypesList } = useTrapTypesList();
  const { casesCloropleticByAreas, setCasesCloropleticByAreas } =
    useCasesCloropletic();
  const { setCasesByAreas } = useCasesByAreas();
  const { setSorotypesByAreas } = useSorotypesByAreas();
  const { territorializationsList } = useTerritorializationsList();
  const { setFieldResultsList } = useFieldResultsList();
  const { setPositiveTrapsFromLabList } = usePositiveTrapsFromLabList();

  React.useEffect(() => {
    if (!casesCloropleticByAreas) return;

    const notifiedGroupIntervals = +(
      (casesCloropleticByAreas.greatestNotifiedCasesCloropleticValue -
        casesCloropleticByAreas.lowestNotifiedCasesCloropleticValue) /
      CASES_CLOROPLETIC_COLORS.length
    ).toFixed(2);

    const confirmedGroupIntervals = +(
      (casesCloropleticByAreas.greatestConfirmedCasesCloropleticValue -
        casesCloropleticByAreas.lowestConfirmedCasesCloropleticValue) /
      CASES_CLOROPLETIC_COLORS.length
    ).toFixed(2);

    const notifiedCloropleticGroupLegend = [];
    const confirmedCloropleticGroupLegend = [];

    let notifiedPreviousColorValue =
      casesCloropleticByAreas.lowestNotifiedCasesCloropleticValue;
    let confirmedPreviousColorValue =
      casesCloropleticByAreas.lowestConfirmedCasesCloropleticValue;

    for (let i = 0; i < CASES_CLOROPLETIC_COLORS.length; i++) {
      const acutalColor = CASES_CLOROPLETIC_COLORS[i];

      notifiedPreviousColorValue += 0.01;

      const minimalValue = notifiedPreviousColorValue;
      const maximalValue = minimalValue + notifiedGroupIntervals;

      notifiedPreviousColorValue = maximalValue;

      const colorResult = {
        color: acutalColor,
        minimalValue: +minimalValue.toFixed(2),
        maximalValue: +maximalValue.toFixed(2),
        label: `${minimalValue.toFixed(2)} - ${maximalValue.toFixed(2)}`,
      };

      notifiedCloropleticGroupLegend.push(colorResult);
    }

    for (let i = 0; i < CASES_CLOROPLETIC_COLORS.length; i++) {
      const acutalColor = CASES_CLOROPLETIC_COLORS[i];

      confirmedPreviousColorValue += 0.01;

      const minimalValue = confirmedPreviousColorValue;
      const maximalValue = minimalValue + confirmedGroupIntervals;

      confirmedPreviousColorValue = maximalValue;

      const colorResult = {
        color: acutalColor,
        minimalValue: +minimalValue.toFixed(2),
        maximalValue: +maximalValue.toFixed(2),
        label: `${minimalValue.toFixed(2)} - ${maximalValue.toFixed(2)}`,
      };

      confirmedCloropleticGroupLegend.push(colorResult);
    }
  }, [casesCloropleticByAreas]);

  const fetchPositiveTrapsFromFieldData = async (filters, trapTypes) => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro as armadilhas positivas de campo. Verifique sua conexão com a internet e tente novamente. Caso o problema persista, entre em contato com a nossa equipe.";

    const result = [];

    setisLoadingFieldPositiveTraps(true);

    for (const trapType of trapTypes) {
      const filtersToSend = {
        demandsIds: [],
        organizationId: getUserData("organizationId"),
        periods: generatePeriodsToSend(filters).map(
          ({ periodName, endDate, ...rest }) => ({
            ...rest,
            finalDate: endDate,
            label: periodName,
          })
        ),
        territorializationsIds: filters.territorializations.map(
          ({ value }) => value
        ),
        trapTypeName: trapType.trapTypeName,
        usersIds: [],
      };

      try {
        const { data, status } = await getEntomologicFieldResultsByFilters(
          filtersToSend
        );

        if (status !== 200) {
          toast.error(DEFAULT_ERROR_MESSAGE);

          continue;
        }

        result.push({
          trapType: trapType.trapTypeName,
          data,
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      } finally {
        setisLoadingFieldPositiveTraps(false);
      }
    }

    setFieldResultsList(result);
  };

  const fetchPositiveTrapsFromLabData = async (filters, trapTypes) => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro as armadilhas positivas do laboratório. Verifique sua conexão com a internet e tente novamente. Caso o problema persista, entre em contato com a nossa equipe.";

    const result = [];

    setIsLoadingPositiveTrapsFromLab(true);

    for (const trapType of trapTypes) {
      const filtersToSend = {
        demandsIds: [],
        organizationId: getUserData("organizationId"),
        periods: generatePeriodsToSend(filters).map(
          ({ periodName, ...rest }) => ({ ...rest, name: periodName })
        ),
        territorializationsIds: filters.territorializations.map(
          ({ value }) => value
        ),
        trapTypeId: trapType.value,
        usersIds: [],
      };

      try {
        const { data, status } = await fetchPositiveTrapsFromLab(filtersToSend);

        if (status !== 200) {
          toast.error(DEFAULT_ERROR_MESSAGE);

          continue;
        }

        result.push({
          trapTypeName: trapType.trapTypeName,
          data,
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      } finally {
        setIsLoadingPositiveTrapsFromLab(false);
      }
    }

    setPositiveTrapsFromLabList(result);
  };

  const handleCalculateCasesCloropleticClick = async () => {
    if (selectedTypeLayerId === "") return;

    const filtersToSend = {
      organizationId: getUserData("organizationId"),
      territorializationsIds: typeLayersList
        .find(({ id }) => id === selectedTypeLayerId)
        .territorializations.map(({ value }) => value),
      periods: generatePeriodsToSend(appliedFilters),
      year: +selectedYear,
      referenceDate: appliedFilters.casesReferenceDate.value,
      IncludePolygonGeo: true,
    };

    setIsLoadingCasesCloropletic(true);

    try {
      const { data, status } = await fetchCasesCloropleticByAreas(
        filtersToSend
      );

      if (status !== 200) {
        throw new Error("Erro ao buscar os casos por áreas");
      }

      setCasesCloropleticByAreas(calculateCasesCloropletic(data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCasesCloropletic(false);
    }
  };

  const handleCalculateSorotypesForTypeLayer = async () => {
    if (selectedTypeLayerIdForSorotypes === "") return;

    const filtersToSend = {
      organizationId: getUserData("organizationId"),
      territorializationsIds: typeLayersList
        .find(({ id }) => id === selectedTypeLayerIdForSorotypes)
        .territorializations.map(({ value }) => value),
      periods: generatePeriodsToSend(appliedFilters, true),
      referenceDate: appliedFilters.casesReferenceDate.value,
      disease: appliedFilters.disease.value,
      includeCasesByPersons: false,
      notifyingUnitsIds: appliedFilters.notifyingUnits.map(
        ({ value }) => value
      ),
      includeCasesByNotifyingUnits: false,
      year: +selectedYear,
    };

    const casesList = await fetchCasesList(filtersToSend);

    const result = [];

    casesList.forEach(
      ({ resultsByPeriods, territorializationId, territorializationName }) => {
        const sorotypes = resultsByPeriods[0].result.sorotype;

        const allSorotypesSum =
          sorotypes.one + sorotypes.two + sorotypes.three + sorotypes.for;
        const sorotypeOnePercentage =
          sorotypes.one === 0 || allSorotypesSum === 0
            ? 0
            : +((sorotypes.one / allSorotypesSum) * 100).toFixed(1);
        const sorotypeTwoPercentage =
          sorotypes.two === 0 || allSorotypesSum === 0
            ? 0
            : +((sorotypes.two / allSorotypesSum) * 100).toFixed(1);
        const sorotypethreePercentage =
          sorotypes.three === 0 || allSorotypesSum === 0
            ? 0
            : +((sorotypes.three / allSorotypesSum) * 100).toFixed(1);
        const sorotypefourPercentage =
          sorotypes.for === 0 || allSorotypesSum === 0
            ? 0
            : +((sorotypes.for / allSorotypesSum) * 100).toFixed(1);

        const typeLayerPolygons = territorializationsList.find(
          ({ id }) => id === selectedTypeLayerIdForSorotypes
        );

        const polyon = typeLayerPolygons.territorializations.find(
          ({ id }) => id === territorializationId
        );

        const turfPolygon = polygon([
          polyon.coordinates.map(({ x, y }) => [x, y]),
        ]);

        const polygonCentroidCoordinates =
          centroid(turfPolygon).geometry.coordinates.reverse();

        const areaSorotypeResult = {
          territorializationId,
          territorializationName,
          polygonCentroidCoordinates,
          sorotypes: [
            {
              name: "Sorotipo 1",
              value: sorotypeOnePercentage,
            },
            {
              name: "Sorotipo 2",
              value: sorotypeTwoPercentage,
            },
            {
              name: "Sorotipo 3",
              value: sorotypethreePercentage,
            },
            {
              name: "Sorotipo 4",
              value: sorotypefourPercentage,
            },
          ],
          sorotypesSum: allSorotypesSum,
        };

        result.push(areaSorotypeResult);
      }
    );

    setCasesByAreas(result);
  };

  const fetchCasesList = async (filters) => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao carregar a lista de Notificações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com nossa equipe.";

    setIsLoadingSorotypesForTypeLayer(true);

    try {
      const { data, status } = await fetchSinanCases(filters);

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      return data;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }
    } finally {
      setIsLoadingSorotypesForTypeLayer(false);
    }
  };

  const calculateCasesCloropletic = (data) => {
    const result = {
      greatestNotifiedCasesCloropleticValue: 0,
      lowestNotifiedCasesCloropleticValue: Infinity,
      greatestConfirmedCasesCloropleticValue: 0,
      lowestConfirmedCasesCloropleticValue: Infinity,
    };

    const areasResult = {};

    data.forEach(({ territorializationId, periods, polygonGeo }) => {
      const territorializationResult = {
        notifiedCases: 0,
        confirmedCases: 0,
        areaInSquareKilometers: 0,
        notifiedCasesCloropleticValue: 0,
        confirmedCasesCloropleticValue: 0,
      };

      periods.forEach(({ cases }) => {
        territorializationResult.notifiedCases += cases.notified;
        territorializationResult.confirmedCases += cases.confirmed;
      });

      const territorializationPolygon = polygon([
        polygonGeo.coordinates.map(({ x, y }) => [x, y]),
      ]);

      const areaInSquareKilometers = area(territorializationPolygon) / 1000000;
      const notifiedCasesCloropleticValue =
        territorializationResult.notifiedCases === 0
          ? 0.0
          : +(
              territorializationResult.notifiedCases / areaInSquareKilometers
            ).toFixed(2);
      const confirmedCasesCloropleticValue =
        territorializationResult.confirmedCases === 0
          ? 0.0
          : +(
              territorializationResult.confirmedCases / areaInSquareKilometers
            ).toFixed(2);

      territorializationResult.areaInSquareKilometers = areaInSquareKilometers;

      territorializationResult.notifiedCasesCloropleticValue =
        notifiedCasesCloropleticValue;
      territorializationResult.confirmedCasesCloropleticValue =
        confirmedCasesCloropleticValue;

      if (
        notifiedCasesCloropleticValue >
        result.greatestNotifiedCasesCloropleticValue
      )
        result.greatestNotifiedCasesCloropleticValue =
          notifiedCasesCloropleticValue;

      if (
        notifiedCasesCloropleticValue <
        result.lowestNotifiedCasesCloropleticValue
      )
        result.lowestNotifiedCasesCloropleticValue =
          notifiedCasesCloropleticValue;

      if (
        confirmedCasesCloropleticValue >
        result.greatestConfirmedCasesCloropleticValue
      )
        result.greatestConfirmedCasesCloropleticValue =
          confirmedCasesCloropleticValue;

      if (
        confirmedCasesCloropleticValue <
        result.lowestConfirmedCasesCloropleticValue
      )
        result.lowestConfirmedCasesCloropleticValue =
          confirmedCasesCloropleticValue;

      areasResult[territorializationId] = territorializationResult;
    });

    result["areasResult"] = areasResult;

    return result;
  };

  const generatePeriodsToSend = (filters, optimizePeriods = false) => {
    if (filters.beginDate !== "" && filters.endDate !== "") {
      const result = [
        {
          beginDate: moment(filters.beginDate)
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment(filters.endDate)
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          periodName: `${moment(filters.beginDate).format(
            "DD/MM/YYYY"
          )} A ${moment(filters.endDate).format("DD/MM/YYYY")}`,
        },
      ];

      return result;
    }

    if (filters.datePeriodType === "week") {
      const newFilters = { ...filters };

      const labelsSet = new Set(
        newFilters.epidemiologicalWeeks.map((item) => item.label)
      );

      const allEpidemiologicWeeksFromYear = getWeeks().find(
        ({ year }) => year === String(selectedYear)
      ).weeks;

      const filteredEpidemiologicWeeksFromYear =
        allEpidemiologicWeeksFromYear.filter((item) =>
          labelsSet.has(item.label)
        );

      if (optimizePeriods) {
        const orderedWeeks = filteredEpidemiologicWeeksFromYear.sort((a, b) => {
          const aDate = moment(a.beginDate, "DD/MM/YYYY");
          const bDate = moment(b.beginDate, "DD/MM/YYYY");

          return aDate - bDate;
        });

        const beginDate = moment(orderedWeeks[0].beginDate, "DD/MM/YYYY")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString();

        const endDate = moment(
          orderedWeeks[orderedWeeks.length - 1].endDate,
          "DD/MM/YYYY"
        )
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString();

        const periodName = `${orderedWeeks[0].beginDate} à ${
          orderedWeeks[orderedWeeks.length - 1].endDate
        }`;

        return [
          {
            beginDate,
            endDate,
            periodName,
          },
        ];
      }

      const result = filteredEpidemiologicWeeksFromYear.map(
        ({ beginDate, endDate, label }) => ({
          beginDate: moment(beginDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment(endDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          periodName: label,
        })
      );

      return result;
    }

    if (filters.datePeriodType === "month") {
      if (optimizePeriods) {
        const newFilters = { ...filters };

        const orderedMonths = newFilters.months.sort((a, b) => {
          return a.value - b.value;
        });

        const beginDate = moment({
          year: +selectedYear,
          month: orderedMonths[0].value - 1,
        })
          .startOf("month")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString();

        const endDate = moment({
          year: +selectedYear,
          month: orderedMonths[orderedMonths.length - 1].value - 1,
        })
          .endOf("month")
          .subtract(1, "day")
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString();

        const periodName = `${orderedMonths[0].label} à ${
          orderedMonths[orderedMonths.length - 1].label
        }`;

        return [
          {
            beginDate,
            endDate,
            periodName,
          },
        ];
      }

      const result = filters.months.map(({ label, value }) => ({
        beginDate: moment({ year: +selectedYear, month: value - 1 })
          .startOf("month")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString(),
        endDate: moment({ year: +selectedYear, month: value - 1 })
          .endOf("month")
          .subtract(1, "day")
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString(),
        periodName: label,
      }));

      return result;
    }
  };

  return (
    <div>
      <ReactTooltip effect="solid" type="info" id={`map-adds-${variant}`}>
        Adicionar ao mapa
      </ReactTooltip>
      <Button
        id={`map-adds-popover-${variant}`}
        color="primary"
        type="button"
        data-tip
        data-for={`map-adds-${variant}`}
      >
        <i className="fa fa-plus"></i>
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target={`map-adds-popover-${variant}`}
        offset="-120, 8"
        style={{ width: "400px" }}
      >
        <PopoverHeader>Adicionar ao mapa</PopoverHeader>
        <PopoverBody style={{ backgroundColor: "#ffffff" }}>
          <Row className="mb-2">
            <Col>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Área e ano para cloroplético (casos):
              </span>
              <div style={{ display: "flex", gap: ".5rem" }}>
                <select
                  name="typeLayers"
                  id="typeLayers"
                  style={{ padding: "6px", width: "70%" }}
                  value={selectedTypeLayerId}
                  onChange={({ target: { value } }) =>
                    setSelectedTypeLayerId(value)
                  }
                >
                  <option key={"empty"} value={""}>
                    Selecione
                  </option>
                  {typeLayersList
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((typeLayer) => {
                      return (
                        <option key={typeLayer.id} value={typeLayer.id}>
                          {typeLayer.label}
                        </option>
                      );
                    })}
                </select>
                <select
                  name="typeLayersYear"
                  id="typeLayersYear"
                  style={{ padding: "6px", width: "50%" }}
                  value={selectedYear}
                  onChange={({ target: { value } }) => setSelectedYear(value)}
                >
                  <option key={"empty"} value={""}>
                    Selecione
                  </option>
                  {getWeeks()
                    .map((week) => ({
                      label: week.year,
                      value: +week.year,
                    }))
                    .sort((a, b) => b.value - a.value)
                    .map((week) => {
                      return (
                        <option key={week.label} value={week.label}>
                          {week.label}
                        </option>
                      );
                    })}
                </select>
                {isLoadingCasesCloropletic ? (
                  <Button color="primary" disabled>
                    <LoadingSpin primaryColor={"#fff"} size={16} />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => handleCalculateCasesCloropleticClick()}
                  >
                    Calcular
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Área e ano para sorotipos:
              </span>
              <div style={{ display: "flex", gap: ".5rem" }}>
                <select
                  name="typeLayersSorotype"
                  id="typeLayersSorotype"
                  style={{ padding: "6px", width: "70%" }}
                  value={selectedTypeLayerIdForSorotypes}
                  onChange={({ target: { value } }) =>
                    setSelectedTypeLayerIdForSorotypes(value)
                  }
                >
                  <option key={"empty"} value={""}>
                    Selecione
                  </option>
                  {typeLayersList
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((typeLayer) => {
                      return (
                        <option key={typeLayer.id} value={typeLayer.id}>
                          {typeLayer.label}
                        </option>
                      );
                    })}
                </select>
                <select
                  name="typeLayersSorotypeYear"
                  id="typeLayersSorotypeYear"
                  style={{ padding: "6px", width: "50%" }}
                  value={selectedYear}
                  onChange={({ target: { value } }) => setSelectedYear(value)}
                >
                  <option key={"empty"} value={""}>
                    Selecione
                  </option>
                  {getWeeks()
                    .map((week) => ({
                      label: week.year,
                      value: +week.year,
                    }))
                    .sort((a, b) => b.value - a.value)
                    .map((week) => {
                      return (
                        <option key={week.label} value={week.label}>
                          {week.label}
                        </option>
                      );
                    })}
                </select>
                {isLoadingSorotypesForTypeLayer ? (
                  <Button color="primary" disabled style={{ width: "37%" }}>
                    <LoadingSpin primaryColor={"#fff"} size={16} />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => handleCalculateSorotypesForTypeLayer()}
                    style={{ marginRight: "0px", width: "37%" }}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </Button>
                )}
                <Button
                  color="danger"
                  style={{ marginLeft: "0px", width: "37%" }}
                  onClick={() => setSorotypesByAreas([])}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Armadilhas positivas (Laboratório):
              </span>
              <div style={{ display: "flex", gap: ".5rem" }}>
                <select
                  name="labPositiveYear"
                  id="labPositiveYear"
                  style={{ padding: "6px", width: "50%" }}
                  value={selectedYear}
                  onChange={({ target: { value } }) => setSelectedYear(value)}
                >
                  <option key={"empty"} value={""}>
                    Selecione
                  </option>
                  {getWeeks()
                    .map((week) => ({
                      label: week.year,
                      value: +week.year,
                    }))
                    .sort((a, b) => b.value - a.value)
                    .map((week) => {
                      return (
                        <option key={week.label} value={week.label}>
                          {week.label}
                        </option>
                      );
                    })}
                </select>
                {isLoadingPositiveTrapsFromLab ? (
                  <Button color="primary" disabled>
                    <LoadingSpin primaryColor={"#fff"} size={16} />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() =>
                      fetchPositiveTrapsFromLabData(
                        appliedFilters,
                        trapTypesList
                      )
                    }
                  >
                    Calcular
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <span style={{ fontWeight: "bold", color: "black" }}>
                Armadilhas positivas (Campo):
              </span>
              <div style={{ display: "flex", gap: ".5rem" }}>
                <select
                  name="labPositiveYear"
                  id="labPositiveYear"
                  style={{ padding: "6px", width: "50%" }}
                  value={selectedYear}
                  onChange={({ target: { value } }) => setSelectedYear(value)}
                >
                  <option key={"empty"} value={""}>
                    Selecione
                  </option>
                  {getWeeks()
                    .map((week) => ({
                      label: week.year,
                      value: +week.year,
                    }))
                    .sort((a, b) => b.value - a.value)
                    .map((week) => {
                      return (
                        <option key={week.label} value={week.label}>
                          {week.label}
                        </option>
                      );
                    })}
                </select>
                {isLoadingFieldPositiveTraps ? (
                  <Button color="primary" disabled>
                    <LoadingSpin primaryColor={"#fff"} size={16} />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() =>
                      fetchPositiveTrapsFromFieldData(
                        appliedFilters,
                        trapTypesList
                      )
                    }
                  >
                    Calcular
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <MessageCircleWarning size={17} /> O período a ser utilizado
              refere-se ao mesmo em "Filtros" no topo da tela.
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

const MapPolygonsLayersControl = React.memo(({ variant }) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);
  const { territorializationsList } = useTerritorializationsList();
  const { casesCloropleticByAreas, casesCloropleticLegends } =
    useCasesCloropletic();
  const setSelectedPolygon = useContextSelector(
    SinanMapContext,
    (context) => context.setSelectedPolygon
  );

  React.useEffect(() => {
    if (!territorializationsList || territorializationsList.length === 0)
      return;

    const typeLayersPolygonsGroup = [];

    territorializationsList.forEach(({ name, territorializations }) => {
      const typeLayerData = {
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.interiorColor = "transparent";
        turfPolygon.borderColor = "#000000";

        turfPolygon.name = territorialization.name;
        turfPolygon.id = territorialization.id;
        turfPolygon.territorialization = territorialization;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons([...typeLayersPolygonsGroup]);
  }, [territorializationsList]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Delimitadores de Áreas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {typeLayersPolygons.map(({ name, polygons }, index) => {
        return (
          <Overlay name={name} key={`${name}-overlay-${index}`}>
            <FeatureGroup
              fillOpacity={0.3}
              name={name}
              key={`${name}-feature-${index}`}
            >
              {polygons &&
                polygons.length > 0 &&
                polygons.map((polygon, index) => {
                  const { geometry, name, id } = polygon;

                  let { interiorColor, borderColor } = polygon;

                  const coordinates = geometry.coordinates[0].map(
                    ([latitude, longitude]) => [longitude, latitude]
                  );

                  if (
                    casesCloropleticByAreas &&
                    casesCloropleticLegends &&
                    casesCloropleticByAreas.areasResult &&
                    casesCloropleticByAreas.areasResult[id]
                  ) {
                    const polygonData = casesCloropleticByAreas.areasResult[id];

                    if (variant === "notifications") {
                      const foundCloropleticInterval =
                        casesCloropleticLegends.notified.find(
                          ({ minimalValue, maximalValue }) =>
                            polygonData.notifiedCasesCloropleticValue >=
                              minimalValue &&
                            polygonData.notifiedCasesCloropleticValue <=
                              maximalValue
                        );

                      if (!foundCloropleticInterval) {
                        interiorColor =
                          casesCloropleticLegends.notified[0].color;
                        borderColor = casesCloropleticLegends.notified[0].color;
                      } else {
                        interiorColor = foundCloropleticInterval.color;
                        borderColor = foundCloropleticInterval.color;
                      }
                    }

                    if (variant === "confirmed") {
                      const foundCloropleticInterval =
                        casesCloropleticLegends.confirmed.find(
                          ({ minimalValue, maximalValue }) =>
                            polygonData.confirmedCasesCloropleticValue >=
                              minimalValue &&
                            polygonData.confirmedCasesCloropleticValue <=
                              maximalValue
                        );

                      if (!foundCloropleticInterval) {
                        interiorColor =
                          casesCloropleticLegends.confirmed[0].color;
                        borderColor =
                          casesCloropleticLegends.confirmed[0].color;
                      } else {
                        interiorColor = foundCloropleticInterval.color;
                        borderColor = foundCloropleticInterval.color;
                      }
                    }
                  }

                  return (
                    <Polygon
                      key={`${name}-polygon-${index}-${Date.now()}`}
                      color={borderColor}
                      fillColor={interiorColor}
                      fillOpacity={0.7}
                      opacity={1}
                      positions={coordinates}
                    >
                      {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      <Popup
                        onClose={() => setSelectedPolygon(null)}
                        onOpen={() => setSelectedPolygon(polygon)}
                        minWidth={300}
                      >
                        <MapPolygonPopup variant={variant} />
                      </Popup>
                    </Polygon>
                  );
                })}
            </FeatureGroup>
          </Overlay>
        );
      })}
    </LayersControl>
  );
});

const MapPolygonPopup = ({ variant }) => {
  const [casesInsidePolygon, setCasesInsidePolygon] = React.useState([]);
  const [polygonInformations, setPolygonInformations] = React.useState(null);
  const { notificationsList } = useNotificationsList();
  const selectedPolygon = useContextSelector(
    SinanMapContext,
    (context) => context.selectedPolygon
  );

  React.useEffect(() => {
    if (
      !notificationsList.formated ||
      notificationsList.formated.length === 0 ||
      !selectedPolygon
    )
      return;

    const casesByPersonsPoints = [];

    notificationsList.formated.forEach(({ resultsByPeriods }) => {
      resultsByPeriods.forEach(({ result: { confirmed, notifications } }) => {
        casesByPersonsPoints.push(...confirmed.casesByPersons);
        casesByPersonsPoints.push(...notifications.casesByPersons);
      });
    });

    const casesFeatureCollection = {
      type: "FeatureCollection",
      features: casesByPersonsPoints.map(({ latitude, longitude, ...rest }) =>
        point([longitude, latitude], {
          latitude,
          longitude,
          ...rest,
        })
      ),
    };

    const casesPointsInsidePolygon = pointsWithinPolygon(
      casesFeatureCollection,
      selectedPolygon
    );

    const polygonAreaInSquareMeters = area(selectedPolygon);
    const polygonAreaInSquareKilometers = polygonAreaInSquareMeters / 1000000;
    const polygonAreaInHectare = polygonAreaInSquareMeters / 10000;

    setPolygonInformations({
      polygonAreaInSquareMeters,
      polygonAreaInSquareKilometers,
      polygonAreaInHectare,
    });
    setCasesInsidePolygon(casesPointsInsidePolygon.features);
  }, [notificationsList, selectedPolygon]);

  const styles = {
    padding: "0 1.3rem",
  };

  if (!selectedPolygon || !polygonInformations) return null;

  return (
    <>
      <section className="mt-2 mb-2" style={{ ...styles, width: "300px" }}>
        <span style={{ display: "block" }}>
          <span className="h4">Nome:</span>{" "}
          {selectedPolygon.territorialization.name}
        </span>
        <span style={{ display: "block" }}>
          <span className="h4">Área:</span>{" "}
          {polygonInformations.polygonAreaInSquareKilometers.toFixed(2)} km² /{" "}
          {polygonInformations.polygonAreaInHectare.toFixed(2)} ha
        </span>
        <span style={{ display: "block" }}>
          {variant === "notifications" ? (
            <span className="h4">Notificações:</span>
          ) : (
            <span className="h4">Confirmados:</span>
          )}{" "}
          {casesInsidePolygon.length}
        </span>
      </section>
    </>
  );
};

const MapActiveTrapsPointsLayersControl = React.memo(() => {
  const [trapsGroupsPointsList, setTrapsGroupsPointsList] = React.useState([]);
  const { trapTypesList } = useTrapTypesList();
  const { trapsList } = useTrapsList();
  const { removePointsCluster } = useSinanMapClusters();

  React.useEffect(() => {
    if (
      !trapsList ||
      trapsList.length === 0 ||
      !trapTypesList ||
      trapTypesList.length === 0
    )
      return;

    const groupsList = generateTrapGroupsPointsList(trapTypesList, trapsList);

    setTrapsGroupsPointsList(groupsList);
  }, [trapsList, trapTypesList]);

  if (removePointsCluster)
    return (
      <LayersControl position="topright">
        <BaseLayer checked name="Pontos de Armadilhas Ativas">
          <TileLayer attribution="" url="" />
        </BaseLayer>
        {trapsGroupsPointsList &&
          trapsGroupsPointsList.length > 0 &&
          trapsGroupsPointsList.map(({ name, points }) => {
            return (
              <Overlay
                key={`${name}-cluster-active-removeClusters`}
                name={name}
              >
                <MarkerClusterGroup
                  key={`${Date.now()}-MarkerClusterGroup`}
                  removeOutsideVisibleBounds={true}
                  disableClusteringAtZoom={1}
                >
                  {points &&
                    points.length > 0 &&
                    points.map(
                      ({
                        id,
                        address,
                        lastInstallDate,
                        lastReadDate,
                        latitude,
                        longitude,
                        number,
                        icon,
                      }) => {
                        return (
                          <Marker
                            key={id}
                            position={[latitude, longitude]}
                            icon={icon}
                          >
                            <TrapPopup
                              address={address}
                              id={id}
                              lastInstallDate={lastInstallDate}
                              lastReadDate={lastReadDate}
                              number={number}
                            />
                          </Marker>
                        );
                      }
                    )}
                </MarkerClusterGroup>
              </Overlay>
            );
          })}
      </LayersControl>
    );

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Pontos de Armadilhas Ativas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {trapsGroupsPointsList &&
        trapsGroupsPointsList.length > 0 &&
        trapsGroupsPointsList.map(({ name, points }) => {
          return (
            <Overlay
              key={`${name}-cluster-active-notRemoveClusters`}
              name={name}
            >
              <MarkerClusterGroup
                key={`MarkerClusterGroup`}
                removeOutsideVisibleBounds={true}
              >
                {points &&
                  points.length > 0 &&
                  points.map(
                    ({
                      id,
                      address,
                      lastInstallDate,
                      lastReadDate,
                      latitude,
                      longitude,
                      number,
                      icon,
                    }) => {
                      return (
                        <Marker
                          key={id}
                          position={[latitude, longitude]}
                          icon={icon}
                        >
                          <TrapPopup
                            address={address}
                            id={id}
                            lastInstallDate={lastInstallDate}
                            lastReadDate={lastReadDate}
                            number={number}
                          />
                        </Marker>
                      );
                    }
                  )}
              </MarkerClusterGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
});

const MapFieldPositiveTrapsPointsLayersControl = ({
  fieldPositiveTrapsGroups,
}) => {
  const { removePointsCluster } = useSinanMapClusters();
  const { trapsList } = useTrapsList();

  if (!fieldPositiveTrapsGroups || fieldPositiveTrapsGroups.length === 0)
    return null;

  if (removePointsCluster)
    return (
      <LayersControl position="topright">
        <BaseLayer checked name="Pontos de Armadilhas Positivas (Campo)">
          <TileLayer attribution="" url="" />
        </BaseLayer>
        {trapsList.length > 0 &&
          fieldPositiveTrapsGroups.map(({ name, points }) => {
            return (
              <Overlay
                key={`${name}-overlay-field-positive-removeClusters`}
                name={name}
              >
                <MarkerClusterGroup
                  key={`${name}-cluster-field-positive-removeClusters`}
                  removeOutsideVisibleBounds={true}
                  disableClusteringAtZoom={1}
                >
                  {points.map(
                    (
                      {
                        latitude,
                        longitude,
                        number,
                        icon,
                        visitDate,
                        trapTypeName,
                        estimatives,
                      },
                      index
                    ) => {
                      const trapData = trapsList.find(
                        (trap) => trap.number === number
                      );

                      return (
                        <Marker
                          key={`${number}${index}`}
                          position={[latitude, longitude]}
                          icon={icon}
                        >
                          <FieldPositiveTrapPopup
                            address={
                              !trapData ? "Não encontrado" : trapData.address
                            }
                            estimatives={estimatives}
                            id={!trapData ? "Não encontrado" : trapData.id}
                            lastInstallDate={
                              !trapData
                                ? null
                                : trapData.lastInstallHistoryByStatusDate.date
                            }
                            lastReadDate={
                              !trapData || !trapData.lastReadHistoryByStatusDate
                                ? null
                                : trapData.lastReadHistoryByStatusDate.date
                            }
                            number={number}
                            trapTypeName={ARMADILHA_INFO[trapTypeName].apelido}
                            visitDate={visitDate}
                          />
                        </Marker>
                      );
                    }
                  )}
                </MarkerClusterGroup>
              </Overlay>
            );
          })}
      </LayersControl>
    );

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Pontos de Armadilhas Positivas (Campo)">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {fieldPositiveTrapsGroups.map(({ name, points }) => {
        return (
          <Overlay
            key={`${name}-overlay-field-positive-notRemoveClusters`}
            name={name}
          >
            <MarkerClusterGroup
              key={`${name}-cluster-field-positive-notRemoveClusters`}
              removeOutsideVisibleBounds={true}
            >
              {points.map(
                (
                  {
                    latitude,
                    longitude,
                    number,
                    icon,
                    visitDate,
                    trapTypeName,
                    estimatives,
                  },
                  index
                ) => {
                  const trapData = trapsList.find(
                    (trap) => trap.number === number
                  );

                  return (
                    <Marker
                      key={`${number}${index}`}
                      position={[latitude, longitude]}
                      icon={icon}
                    >
                      <FieldPositiveTrapPopup
                        address={
                          !trapData ? "Não encontrado" : trapData.address
                        }
                        estimatives={estimatives}
                        id={!trapData ? "Não encontrado" : trapData.id}
                        lastInstallDate={
                          !trapData
                            ? null
                            : trapData.lastInstallHistoryByStatusDate.date
                        }
                        lastReadDate={
                          !trapData || !trapData.lastReadHistoryByStatusDate
                            ? null
                            : trapData.lastReadHistoryByStatusDate.date
                        }
                        number={number}
                        trapTypeName={ARMADILHA_INFO[trapTypeName].apelido}
                        visitDate={visitDate}
                      />
                    </Marker>
                  );
                }
              )}
            </MarkerClusterGroup>
          </Overlay>
        );
      })}
    </LayersControl>
  );
};

const MapLabPositiveTrapsPointsLayersControl = React.memo(
  ({ labPositiveTrapsGroups }) => {
    const { trapsList } = useTrapsList();
    const { removePointsCluster } = useSinanMapClusters();

    if (!labPositiveTrapsGroups || labPositiveTrapsGroups.length === 0)
      return null;

    if (removePointsCluster)
      return (
        <LayersControl position="topright">
          <BaseLayer
            checked
            name="Pontos de Armadilhas Positivas (Laboratório)"
          >
            <TileLayer attribution="" url="" />
          </BaseLayer>
          {trapsList.length > 0 &&
            labPositiveTrapsGroups.map(({ name, points }) => {
              return (
                <Overlay
                  key={`${name}-overlay-lab-positive-removeClusters`}
                  name={name}
                >
                  <MarkerClusterGroup
                    key={`${name}-cluster-lab-positive-removeClusters`}
                    removeOutsideVisibleBounds={true}
                    disableClusteringAtZoom={
                      removePointsCluster ? 1 : undefined
                    }
                  >
                    {points.map(
                      (
                        {
                          latitude,
                          longitude,
                          number,
                          icon,
                          trapTypeName,
                          sampleCollectionDate,
                          positiveFor,
                        },
                        index
                      ) => {
                        const trapData = trapsList.find(
                          (trap) => trap.number === number
                        );

                        return (
                          <Marker
                            key={`${number}${index}`}
                            position={[latitude, longitude]}
                            icon={icon}
                          >
                            <LabPositiveTrapPopup
                              address={
                                !trapData ? "Não encontrado" : trapData.address
                              }
                              collectDate={sampleCollectionDate}
                              eggs={positiveFor.eggs.quantity}
                              id={!trapData ? "Não encontrado" : trapData.id}
                              lastInstallDate={
                                !trapData
                                  ? null
                                  : trapData.lastInstallHistoryByStatusDate.date
                              }
                              lastReadDate={
                                !trapData ||
                                !trapData.lastReadHistoryByStatusDate
                                  ? null
                                  : trapData.lastReadHistoryByStatusDate.date
                              }
                              number={number}
                              trapTypeName={
                                ARMADILHA_INFO[trapTypeName].apelido
                              }
                            />
                          </Marker>
                        );
                      }
                    )}
                  </MarkerClusterGroup>
                </Overlay>
              );
            })}
        </LayersControl>
      );

    return (
      <LayersControl position="topright">
        <BaseLayer checked name="Pontos de Armadilhas Positivas (Laboratório)">
          <TileLayer attribution="" url="" />
        </BaseLayer>
        {labPositiveTrapsGroups.map(({ name, points }) => {
          return (
            <Overlay
              key={`${name}-overlay-lab-positive-notRemoveClusters`}
              name={name}
            >
              <MarkerClusterGroup
                key={`${name}-cluster-lab-positive-notRemoveClusters`}
                removeOutsideVisibleBounds={true}
              >
                {points.map(
                  (
                    {
                      latitude,
                      longitude,
                      number,
                      icon,
                      trapTypeName,
                      sampleCollectionDate,
                      positiveFor,
                    },
                    index
                  ) => {
                    const trapData = trapsList.find(
                      (trap) => trap.number === number
                    );

                    return (
                      <Marker
                        key={`${number}${index}`}
                        position={[latitude, longitude]}
                        icon={icon}
                      >
                        <LabPositiveTrapPopup
                          address={
                            !trapData ? "Não encontrado" : trapData.address
                          }
                          collectDate={sampleCollectionDate}
                          eggs={positiveFor.eggs.quantity}
                          id={!trapData ? "Não encontrado" : trapData.id}
                          lastInstallDate={
                            !trapData
                              ? null
                              : trapData.lastInstallHistoryByStatusDate.date
                          }
                          lastReadDate={
                            !trapData || !trapData.lastReadHistoryByStatusDate
                              ? null
                              : trapData.lastReadHistoryByStatusDate.date
                          }
                          number={number}
                          trapTypeName={ARMADILHA_INFO[trapTypeName].apelido}
                        />
                      </Marker>
                    );
                  }
                )}
              </MarkerClusterGroup>
            </Overlay>
          );
        })}
      </LayersControl>
    );
  }
);

const MapTrapsHeatLayersControl = React.memo(() => {
  const [heatGroupsList, setHeatGroupsList] = React.useState([]);
  const { trapTypesList } = useTrapTypesList();
  const { trapsList } = useTrapsList();

  React.useEffect(() => {
    if (
      !trapsList ||
      trapsList.length === 0 ||
      !trapTypesList ||
      trapTypesList.length === 0
    )
      return;

    const heatGroupsList = generateHeatTrapGroupsPointsList(
      trapTypesList,
      trapsList
    );

    setHeatGroupsList(heatGroupsList);
  }, [trapsList, trapTypesList]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Calor de Armadilhas Ativas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {heatGroupsList &&
        heatGroupsList.length > 0 &&
        heatGroupsList.map(({ gradient, name, points }) => {
          return (
            <Overlay key={`${name}-traps-overlay-heat`} name={name}>
              <HeatmapLayer
                points={points}
                longitudeExtractor={(m) => m.longitude}
                latitudeExtractor={(m) => m.latitude}
                intensityExtractor={(m) => parseFloat(m.intensity)}
                gradient={gradient}
              />
            </Overlay>
          );
        })}
    </LayersControl>
  );
});

const MapFieldPositiveTrapsHeatLayersControl = ({
  fieldPositiveTrapsGroups,
}) => {
  const [heatGroupsList, setHeatGroupsList] = React.useState([]);

  React.useEffect(() => {
    if (!fieldPositiveTrapsGroups || fieldPositiveTrapsGroups.length === 0)
      return;

    const gradient = { 0.4: "#1abc9c", 0.8: "#f1c40f", 1.0: "#6d214f" };

    const formatedGroups = fieldPositiveTrapsGroups.map(({ name, points }) => {
      const formatedPoints = points.map(({ latitude, longitude }) => {
        return {
          latitude,
          longitude,
          intensity: 1,
        };
      });

      return {
        name,
        points: formatedPoints,
        gradient,
      };
    });

    setHeatGroupsList(formatedGroups);
  }, [fieldPositiveTrapsGroups]);

  if (
    !fieldPositiveTrapsGroups ||
    fieldPositiveTrapsGroups.length === 0 ||
    !heatGroupsList ||
    heatGroupsList.length === 0
  )
    return null;

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Calor de Armadilhas Positivas (Campo)">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {heatGroupsList &&
        heatGroupsList.length > 0 &&
        heatGroupsList.map(({ gradient, name, points }) => {
          return (
            <Overlay key={`${name}-fieldPositives-overlay-heat`} name={name}>
              <HeatmapLayer
                points={points}
                longitudeExtractor={(m) => m.longitude}
                latitudeExtractor={(m) => m.latitude}
                intensityExtractor={(m) => parseFloat(m.intensity)}
                gradient={gradient}
              />
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const MapLabPositiveTrapsHeatLayersControl = React.memo(
  ({ labPositiveTrapsGroups }) => {
    const [heatGroupsList, setHeatGroupsList] = React.useState([]);

    React.useEffect(() => {
      if (!labPositiveTrapsGroups || labPositiveTrapsGroups.length === 0)
        return;

      const gradient = { 0.4: "#00bcd4", 0.8: "#e91e63", 1.0: "#b71c1c" };

      const formatedGroups = labPositiveTrapsGroups.map(({ name, points }) => {
        const formatedPoints = points.map(({ latitude, longitude }) => {
          return {
            latitude,
            longitude,
            intensity: 1,
          };
        });

        return {
          name,
          points: formatedPoints,
          gradient,
        };
      });

      setHeatGroupsList(formatedGroups);
    }, [labPositiveTrapsGroups]);

    if (
      !labPositiveTrapsGroups ||
      labPositiveTrapsGroups.length === 0 ||
      !heatGroupsList ||
      heatGroupsList.length === 0
    )
      return null;

    return (
      <LayersControl position="topright">
        <BaseLayer checked name="Calor de Armadilhas Positivas (Laboratório)">
          <TileLayer attribution="" url="" />
        </BaseLayer>
        {heatGroupsList &&
          heatGroupsList.length > 0 &&
          heatGroupsList.map(({ gradient, name, points }) => {
            return (
              <Overlay key={`${name}-fieldPositives-overlay-heat`} name={name}>
                <HeatmapLayer
                  points={points}
                  longitudeExtractor={(m) => m.longitude}
                  latitudeExtractor={(m) => m.latitude}
                  intensityExtractor={(m) => parseFloat(m.intensity)}
                  gradient={gradient}
                />
              </Overlay>
            );
          })}
      </LayersControl>
    );
  }
);

const MapCasesHeatLayersControl = ({ variant }) => {
  const [casesHeatGroupList, setCasesHeatGroupList] = React.useState([]);
  const { notificationsList } = useNotificationsList();

  React.useEffect(() => {
    if (!notificationsList.formated || notificationsList.formated.length === 0)
      return;

    const notificationsPoints = [];

    notificationsList.formated.forEach(({ resultsByPeriods }) => {
      resultsByPeriods.forEach(({ result: { confirmed, notifications } }) => {
        if (variant === "notifications") {
          notifications.casesByPersons.forEach(({ latitude, longitude }) => {
            notificationsPoints.push({
              latitude,
              longitude,
            });
          });
        }

        if (variant === "confirmed") {
          confirmed.casesByPersons.forEach(({ latitude, longitude }) => {
            notificationsPoints.push({
              latitude,
              longitude,
            });
          });
        }
      });
    });

    const groupPoints = [
      {
        name:
          variant === "notifications"
            ? "Notificações"
            : variant === "confirmed"
            ? "Confirmados"
            : "",
        points: notificationsPoints,
      },
    ];

    setCasesHeatGroupList(groupPoints);
  }, [notificationsList]);

  return (
    <LayersControl position="topright">
      <BaseLayer
        checked
        name={
          variant === "notifications"
            ? "Calor de Notificações"
            : variant === "confirmed"
            ? "Calor de Casos Confirmados"
            : ""
        }
      >
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {casesHeatGroupList &&
        casesHeatGroupList.length > 0 &&
        casesHeatGroupList.map(({ name, points }) => {
          return (
            <Overlay
              key={`${name}-overlay-heatcases-${variant}`}
              name={name}
              checked={true}
            >
              <HeatmapLayer
                points={points}
                longitudeExtractor={(m) => m.longitude}
                latitudeExtractor={(m) => m.latitude}
                intensityExtractor={(m) => parseFloat(1)}
                gradient={null}
              />
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const MapCasesLegend = ({ variant }) => {
  const [cases, setCases] = React.useState({
    notifications: 0,
    confirmed: 0,
  });
  const { notificationsList } = useNotificationsList();

  React.useEffect(() => {
    if (!notificationsList.formated || notificationsList.formated.length === 0)
      return;

    let totalConfirmedCases = 0;
    let totalNotificatedCases = 0;

    notificationsList.formated.forEach(({ resultsByPeriods }) => {
      resultsByPeriods.forEach(({ result: { confirmed, notifications } }) => {
        totalConfirmedCases += confirmed.cases;
        totalNotificatedCases += notifications.cases;
      });
    });

    setCases({
      notifications: totalNotificatedCases,
      confirmed: totalConfirmedCases,
    });
  }, [notificationsList]);

  return (
    <Control position={"bottomright"}>
      <div
        style={{
          maxWidth: 250,
          maxHeight: 300,
          backgroundColor: "#fff",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
          <span style={{ fontSize: "17px" }}>
            {variant === "notifications"
              ? `Casos Notificados: ${cases.notifications}`
              : variant === "confirmed"
              ? `Casos Confirmados: ${cases.confirmed}`
              : ""}
          </span>
        </div>
      </div>
    </Control>
  );
};

const MapCasesCloropleticLegend = ({ variant }) => {
  const {
    casesCloropleticByAreas,
    casesCloropleticLegends,
    setCasesCloropleticLegends,
  } = useCasesCloropletic();

  React.useEffect(() => {
    if (!casesCloropleticByAreas) return;

    const notifiedGroupIntervals = +(
      (casesCloropleticByAreas.greatestNotifiedCasesCloropleticValue -
        casesCloropleticByAreas.lowestNotifiedCasesCloropleticValue) /
      CASES_CLOROPLETIC_COLORS.length
    ).toFixed(2);

    const confirmedGroupIntervals = +(
      (casesCloropleticByAreas.greatestConfirmedCasesCloropleticValue -
        casesCloropleticByAreas.lowestConfirmedCasesCloropleticValue) /
      CASES_CLOROPLETIC_COLORS.length
    ).toFixed(2);

    const notifiedCloropleticGroupLegend = [];
    const confirmedCloropleticGroupLegend = [];

    let notifiedPreviousColorValue =
      casesCloropleticByAreas.lowestNotifiedCasesCloropleticValue;
    let confirmedPreviousColorValue =
      casesCloropleticByAreas.lowestConfirmedCasesCloropleticValue;

    for (let i = 0; i < CASES_CLOROPLETIC_COLORS.length; i++) {
      const acutalColor = CASES_CLOROPLETIC_COLORS[i];

      notifiedPreviousColorValue += 0.01;

      const minimalValue = notifiedPreviousColorValue;
      const maximalValue = minimalValue + notifiedGroupIntervals;

      notifiedPreviousColorValue = maximalValue;

      const colorResult = {
        color: acutalColor,
        minimalValue: +minimalValue.toFixed(2),
        maximalValue: +maximalValue.toFixed(2),
        label: `${minimalValue.toFixed(2)} - ${maximalValue.toFixed(2)}`,
      };

      notifiedCloropleticGroupLegend.push(colorResult);
    }

    for (let i = 0; i < CASES_CLOROPLETIC_COLORS.length; i++) {
      const acutalColor = CASES_CLOROPLETIC_COLORS[i];

      confirmedPreviousColorValue += 0.01;

      const minimalValue = confirmedPreviousColorValue;
      const maximalValue = minimalValue + confirmedGroupIntervals;

      confirmedPreviousColorValue = maximalValue;

      const colorResult = {
        color: acutalColor,
        minimalValue: +minimalValue.toFixed(2),
        maximalValue: +maximalValue.toFixed(2),
        label: `${minimalValue.toFixed(2)} - ${maximalValue.toFixed(2)}`,
      };

      confirmedCloropleticGroupLegend.push(colorResult);
    }

    setCasesCloropleticLegends({
      notified: notifiedCloropleticGroupLegend,
      confirmed: confirmedCloropleticGroupLegend,
    });
  }, [casesCloropleticByAreas]);

  if (!casesCloropleticLegends) return null;

  if (variant === "notifications")
    return (
      <Control position={"bottomleft"}>
        <div
          style={{
            maxWidth: 250,
            maxHeight: 300,
            backgroundColor: "#fff",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            <span>Casos / Km²</span>
            {casesCloropleticLegends.notified.map(
              ({ color, minimalValue, maximalValue, label }) => {
                return (
                  <div
                    key={`${color}-${minimalValue}-${maximalValue}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: "0px",
                    }}
                  >
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        backgroundColor: color,
                        marginRight: 5,
                      }}
                    ></div>
                    <div>
                      <span>{label}</span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </Control>
    );

  if (variant === "confirmed")
    return (
      <Control position={"bottomleft"}>
        <div
          style={{
            maxWidth: 250,
            maxHeight: 300,
            backgroundColor: "#fff",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            <span>Casos / Km²</span>
            {casesCloropleticLegends.confirmed.map(
              ({ color, minimalValue, maximalValue, label }) => {
                return (
                  <div
                    key={`${color}-${minimalValue}-${maximalValue}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: "0px",
                    }}
                  >
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        backgroundColor: color,
                        marginRight: 5,
                      }}
                    ></div>
                    <div>
                      <span>{label}</span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </Control>
    );

  return null;
};

const MapSorotypesMarkers = () => {
  const { sorotypesByAreas } = useSorotypesByAreas();

  if (!sorotypesByAreas || sorotypesByAreas.length === 0) return null;

  return (
    <>
      {sorotypesByAreas.map(
        ({
          territorializationId,
          polygonCentroidCoordinates,
          image,
          iconSize,
        }) => {
          return (
            <Marker
              key={`sorotype-${territorializationId}`}
              position={polygonCentroidCoordinates}
              icon={L.icon({
                iconUrl: image,
                iconSize: [+iconSize, +iconSize],
              })}
            ></Marker>
          );
        }
      )}
    </>
  );
};

const MapSorotypesLegend = () => {
  const { sorotypesByAreas } = useSorotypesByAreas();

  if (!sorotypesByAreas || sorotypesByAreas.length === 0) return null;

  return (
    <Control position={"bottomleft"}>
      <div
        style={{
          maxWidth: 250,
          maxHeight: 300,
          backgroundColor: "#fff",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
          <span style={{ fontSize: "17px" }}>Sorotipos</span>
          <div>
            {Array.from({ length: 4 }).map((_, index) => {
              return (
                <div style={{ display: "flex", gap: ".5rem" }} key={index}>
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: getColorByIndex(index),
                      borderRadius: "100%",
                    }}
                  ></div>
                  <span>Sorotipo {index + 1}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Control>
  );
};

const MapToolsControl = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { removePointsCluster, setRemovePointsCluster } = useSinanMapClusters();

  return (
    <Control
      position="topright"
      className="leaflet-control-layers leaflet-control"
    >
      <Button onClick={() => setIsOpen((previousValue) => !previousValue)}>
        <i className="fa fa-cog fa-2x"></i>
      </Button>

      {isOpen && (
        <section style={{ padding: "10px" }}>
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
          >
            <input
              type="checkbox"
              id="trapClusters"
              checked={removePointsCluster}
              onChange={() =>
                setRemovePointsCluster((previousValue) => !previousValue)
              }
            />
            <label
              htmlFor="trapClusters"
              className="mb-0"
              style={{ color: "black" }}
            >
              Desagrupar armadilhas
            </label>
          </div>
        </section>
      )}
    </Control>
  );
};

const FieldPositiveTrapPopup = ({
  id,
  estimatives,
  number,
  address,
  lastInstallDate,
  lastReadDate,
  trapTypeName,
  visitDate,
}) => {
  return (
    <Popup key={`popup-${id}`}>
      <span>
        <span style={{ fontWeight: "bold" }}>Tipo de armadilha: </span>
        {trapTypeName}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Armadilha Nº: </span>
        {number}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Endereço: </span>
        {address}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>
          Data da visita que positivou:{" "}
        </span>
        {moment(visitDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última instalação: </span>
        {moment(lastInstallDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última manutenção: </span>
        {!lastReadDate || lastReadDate === ""
          ? "-"
          : moment(lastReadDate).format("DD/MM/YYYY HH:mm")}
      </span>
      {estimatives &&
        estimatives.length > 0 &&
        estimatives.map(({ name, estimateType }) => {
          return (
            <>
              <br />
              <span>
                <span style={{ fontWeight: "bold" }}>
                  Estimativa de {estimateType}:{" "}
                </span>
                {name}
              </span>
            </>
          );
        })}
    </Popup>
  );
};

const LabPositiveTrapPopup = ({
  id,
  number,
  address,
  lastInstallDate,
  lastReadDate,
  trapTypeName,
  collectDate,
  eggs = 0,
}) => {
  return (
    <Popup key={`popup-${id}`}>
      <span>
        <span style={{ fontWeight: "bold" }}>Tipo de armadilha: </span>
        {trapTypeName}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Armadilha Nº: </span>
        {number}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Endereço: </span>
        {address}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Quantidade total de ovos: </span>
        {eggs}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>
          Data da primeira coleta que positivou:{" "}
        </span>
        {!collectDate ? "-" : moment(collectDate).format("DD/MM/YYYY")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última instalação: </span>
        {!lastInstallDate
          ? "-"
          : moment(lastInstallDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última manutenção: </span>
        {!lastReadDate ? "-" : moment(lastReadDate).format("DD/MM/YYYY HH:mm")}
      </span>
    </Popup>
  );
};

const TrapPopup = ({ id, number, address, lastInstallDate, lastReadDate }) => {
  return (
    <Popup key={`popup-${id}`}>
      <span>
        <span style={{ fontWeight: "bold" }}>Armadilha Nº: </span>
        {number}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Endereço: </span>
        {address}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última instalação: </span>
        {moment(lastInstallDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última manutenção: </span>
        {!lastReadDate || lastReadDate === ""
          ? "-"
          : moment(lastReadDate).format("DD/MM/YYYY HH:mm")}
      </span>
    </Popup>
  );
};

const CASES_CLOROPLETIC_COLORS = [
  "#1b953e",
  "#54b54c",
  "#92d466",
  "#c2e885",
  "#ecf6b7",
  "#fcefa9",
  "#ffca7e",
  "#f3a054",
  "#ee5a3e",
  "#d61a18",
];
