import React from "react";

import { PositivityIndexLineByWeekGraphic } from "../Line/PositivityIndexLineByWeekGraphic";
import { PositivityIndexLinePercentageEstimatesByWeekGraphic } from "../Line/PositivityIndexLinePercentageEstimatesByWeekGraphic";
import { PositiveBarPercentageEstimatesGraphic } from "../Bar/PositiveBarPercentageEstimatesGraphic";
import { ARMADILHA_INFO } from "../../../../../constants/ArmadilhaConstant";
import useAppliedFilters from "../../Hooks/useAppliedFilters";
import { PositiveBarTotalizationEstimatesGraphic } from "../Bar/PositiveBarTotalizationEstimatesGraphic";

const useIPEGraphic = () => {
  const [graphicType, setGraphicType] = React.useState("default");
  const [selectedTerritorialization, setSelectedTerritorialization] =
    React.useState(null);
  const [territorializationsOptions, setTerritorializationsOptions] =
    React.useState([]);
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    setTerritorializationsOptions(appliedFilters.territorializations);
    setSelectedTerritorialization(appliedFilters.territorializations[0]);
  }, [appliedFilters]);

  const handleTerritorializationChange = React.useCallback((newValue) => {
    setSelectedTerritorialization(newValue);
  }, []);

  return {
    appliedFilters,
    graphicType,
    selectedTerritorialization,
    territorializationsOptions,
    setGraphicType,
    handleTerritorializationChange,
  };
};

export const IPEGraphic = ({
  estimatesData,
  positivityIndex,
  situationsByTerritorializations,
}) => {
  const {
    appliedFilters,
    graphicType,
    handleTerritorializationChange,
    selectedTerritorialization,
    setGraphicType,
    territorializationsOptions,
  } = useIPEGraphic();

  if (
    !estimatesData ||
    estimatesData.length === 0 ||
    !selectedTerritorialization
  )
    return null;

  const selectedTerritorializationData = estimatesData.find(
    ({ territorializationName }) =>
      territorializationName === selectedTerritorialization.label
  ).results;

  return (
    <div>
      {graphicType === "default" && (
        <PositivityIndexLineByWeekGraphic
          data={positivityIndex}
          headerText={"Índice de Positividade de Exúvias"}
          positiveFor="exúvias"
          positivityIndexName="IPE"
          setGraphicType={setGraphicType}
          tooltipPositivityPropertyName="positiveForExuvia"
          trapTypeName={appliedFilters.trapType.trapTypeName}
          territorializationsOptions={territorializationsOptions}
        />
      )}
      {graphicType === "line" && (
        <PositivityIndexLinePercentageEstimatesByWeekGraphic
          headerText={`Estimativas de ${
            ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].apelido
          }'s positivas para exúvias`}
          data={
            selectedTerritorializationData.exuvia.absoluteEstimatesValuesByWeek
          }
          selectedTerritorialization={selectedTerritorialization}
          territorializationsOptions={territorializationsOptions}
          handleTerritorializationChange={handleTerritorializationChange}
          setGraphicType={setGraphicType}
          estimatesCategoriesList={
            selectedTerritorializationData.exuvia.estimatesCategories
          }
        />
      )}
      {graphicType === "bar" && (
        <PositiveBarPercentageEstimatesGraphic
          data={
            selectedTerritorializationData.exuvia
              .percentageEstimatesValuesByWeek
          }
          estimatesList={
            selectedTerritorializationData.exuvia.estimatesCategories
          }
          setGraphicType={setGraphicType}
          selectedTerritorialization={selectedTerritorialization}
          territorializationsOptions={territorializationsOptions}
          handleTerritorializationChange={handleTerritorializationChange}
          headerText={`Estimativas em porcentagem de ${
            ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].apelido
          }'s positivas para exúvias`}
        />
      )}
      {graphicType === "totalization" && (
        <PositiveBarTotalizationEstimatesGraphic
          data={situationsByTerritorializations.map(
            ({
              exuviaPositiveTrapsFrom1to20Percentage,
              exuviaPositiveTrapsFrom21to100Percentage,
              exuviaPositiveTrapsFromMoreThan100Percentage,
              territorializationName,
            }) => ({
              territorializationName,
              "1 a 20": exuviaPositiveTrapsFrom1to20Percentage,
              "21 a 100": exuviaPositiveTrapsFrom21to100Percentage,
              "Mais de 100": exuviaPositiveTrapsFromMoreThan100Percentage,
            })
          )}
          estimatesList={
            selectedTerritorializationData.exuvia.estimatesCategories
          }
          setGraphicType={setGraphicType}
          selectedTerritorialization={selectedTerritorialization}
          territorializationsOptions={territorializationsOptions}
          handleTerritorializationChange={handleTerritorializationChange}
          headerText={
            <span>
              Estimativas totalizadas em porcentagem <br /> de{" "}
              {ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].apelido}'s
              positivas para exúvias por áreas
            </span>
          }
        />
      )}
    </div>
  );
};
