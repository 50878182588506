import React from "react";

import { PositiveBarPercentageEstimatesGraphic } from "../Bar/PositiveBarPercentageEstimatesGraphic";
import { PositivityIndexLineByWeekGraphic } from "../Line/PositivityIndexLineByWeekGraphic";
import { PositivityIndexLinePercentageEstimatesByWeekGraphic } from "../Line/PositivityIndexLinePercentageEstimatesByWeekGraphic";
import { ARMADILHA_INFO } from "../../../../../constants/ArmadilhaConstant";
import useAppliedFilters from "../../Hooks/useAppliedFilters";
import { PositiveBarTotalizationEstimatesGraphic } from "../Bar/PositiveBarTotalizationEstimatesGraphic";

const useIPAGraphic = () => {
  const [graphicType, setGraphicType] = React.useState("default");
  const [selectedTerritorialization, setSelectedTerritorialization] =
    React.useState(null);
  const [territorializationsOptions, setTerritorializationsOptions] =
    React.useState([]);
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    setTerritorializationsOptions(appliedFilters.territorializations);
    setSelectedTerritorialization(appliedFilters.territorializations[0]);
  }, [appliedFilters]);

  const handleTerritorializationChange = React.useCallback((newValue) => {
    setSelectedTerritorialization(newValue);
  }, []);

  return {
    appliedFilters,
    graphicType,
    selectedTerritorialization,
    territorializationsOptions,
    setGraphicType,
    handleTerritorializationChange,
  };
};

export const IPAGraphic = ({
  estimatesData,
  positivityIndex,
  situationsByTerritorializations,
}) => {
  const {
    appliedFilters,
    graphicType,
    handleTerritorializationChange,
    selectedTerritorialization,
    setGraphicType,
    territorializationsOptions,
  } = useIPAGraphic();

  if (
    !estimatesData ||
    estimatesData.length === 0 ||
    !selectedTerritorialization
  )
    return null;

  const selectedTerritorializationData = estimatesData.find(
    ({ territorializationName }) =>
      territorializationName === selectedTerritorialization.label
  ).results;

  return (
    <div>
      {graphicType === "default" && (
        <PositivityIndexLineByWeekGraphic
          data={positivityIndex}
          headerText={"Índice de Positividade de Adultos"}
          positiveFor="Aedes Aegypti"
          positivityIndexName="IPA"
          setGraphicType={setGraphicType}
          tooltipPositivityPropertyName="positiveForAedesAegypti"
          territorializationsOptions={territorializationsOptions}
          trapTypeName={appliedFilters.trapType.trapTypeName}
        />
      )}
      {graphicType === "line" && (
        <PositivityIndexLinePercentageEstimatesByWeekGraphic
          headerText={`Estimativas de ${
            ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].apelido
          }'s positivas para adultos`}
          data={
            selectedTerritorializationData.aedesAegypti
              .absoluteEstimatesValuesByWeek
          }
          selectedTerritorialization={selectedTerritorialization}
          territorializationsOptions={territorializationsOptions}
          handleTerritorializationChange={handleTerritorializationChange}
          setGraphicType={setGraphicType}
          estimatesCategoriesList={
            selectedTerritorializationData.aedesAegypti.estimatesCategories
          }
        />
      )}
      {graphicType === "bar" && (
        <PositiveBarPercentageEstimatesGraphic
          data={
            selectedTerritorializationData.aedesAegypti
              .percentageEstimatesValuesByWeek
          }
          estimatesList={
            selectedTerritorializationData.aedesAegypti.estimatesCategories
          }
          setGraphicType={setGraphicType}
          selectedTerritorialization={selectedTerritorialization}
          territorializationsOptions={territorializationsOptions}
          handleTerritorializationChange={handleTerritorializationChange}
          headerText={`Estimativas em porcentagem de ${
            ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].apelido
          }'s positivas para adultos`}
        />
      )}
      {graphicType === "totalization" && (
        <PositiveBarTotalizationEstimatesGraphic
          data={situationsByTerritorializations.map(
            ({
              aedesAegyptiPositiveTrapsWith1Percentage,
              aedesAegyptiPositiveTrapsFrom2to3Percentage,
              aedesAegyptiPositiveTrapsFromMoreThan3Percentage,
              territorializationName,
            }) => ({
              territorializationName,
              "1": aedesAegyptiPositiveTrapsWith1Percentage,
              "2 a 3": aedesAegyptiPositiveTrapsFrom2to3Percentage,
              "Mais de 3": aedesAegyptiPositiveTrapsFromMoreThan3Percentage,
            })
          )}
          estimatesList={
            selectedTerritorializationData.aedesAegypti.estimatesCategories
          }
          setGraphicType={setGraphicType}
          selectedTerritorialization={selectedTerritorialization}
          territorializationsOptions={territorializationsOptions}
          handleTerritorializationChange={handleTerritorializationChange}
          headerText={
            <span>
              Estimativas totalizadas em porcentagem <br /> de{" "}
              {ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].apelido}'s
              positivas para adultos por áreas
            </span>
          }
        />
      )}
    </div>
  );
};
