import React from "react";

import DataTable from "react-data-table-component";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../../components/Cards/Card";
import { Col, Row } from "reactstrap";
import TableLoadingSkeleton from "../../../../../components/ui/Loading/TableLoadingSkeleton";
import { useTrapsByPeriodsList } from "../../Hooks/useTrapsByPeriodsList";

const useActivitiesByPeriodsTable = () => {
  const { isTrapsByPeriodsListLoading } = useTrapsByPeriodsList();

  const tableColumns = [
    {
      name: "Período",
      selector: "periodName",
      sortable: true,
    },
    {
      name: "Total de Instalações (App)",
      selector: "totalAppInstallations",
      sortable: true,
    },
    {
      name: "Total de Manutenções (App)",
      selector: "totalAppMaintenances",
      sortable: true,
    },
    {
      name: "Total de Desinstalações (App)",
      selector: "totalAppUninstallations",
      sortable: true,
    },
    {
      name: "Total de Instalações (Digitação)",
      selector: "totalTypingInstallations",
      sortable: true,
    },
    {
      name: "Total de Manutenções (Digitação)",
      selector: "totalTypingMaintenances",
      sortable: true,
    },
    {
      name: "Total de Desinstalações (Digitação)",
      selector: "totalTypingUninstallations",
      sortable: true,
    },
  ];

  return { isTrapsByPeriodsListLoading, tableColumns };
};

export const ActivitiesByPeriodsTable = ({
  appliedFilters,
  data,
  periodLegend,
}) => {
  const { isTrapsByPeriodsListLoading, tableColumns } =
    useActivitiesByPeriodsTable();

  if (isTrapsByPeriodsListLoading)
    return (
      <TableLoadingSkeleton
        length={
          appliedFilters.epidemiologicalWeeks.length <= 10
            ? appliedFilters.epidemiologicalWeeks.length + 1
            : 11
        }
        variant="simple"
        tableColumns={6}
        tableColumnWidth="70px"
      />
    );

  return data && data.length > 0 ? (
    <Card>
      <CardHeader
        dataToDownload={data}
        headerText={
          <span>
            Total de atividades com {appliedFilters.trapType.label} <br />
            {periodLegend}
          </span>
        }
        showExpandButton={false}
        showButtonsTooltips={true}
      />
      <CardContent>
        <Row>
          <Col>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive
              columns={tableColumns}
              data={data}
              noDataComponent={"Nenhum registro encontrado."}
            />
          </Col>
        </Row>
      </CardContent>
      <CardFooter
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  ) : null;
};
