import React, { useState } from "react";
import { Surface, Symbols } from "recharts";

export default function RenderCustomizedLegend ({
    elementData, 
    colors, 
    setElementData, 
    type = "circle"
}) {
    var centerStyle = {display: "flex", justifyContent: "center", alignItems: "center"};

    return (
        <div style={centerStyle}>
            {
                elementData && elementData.map((elementContent, index) => {    
                    const color = colors[index];
                    const active = elementContent.checked;
                    const style = {
                        marginRight: 10,
                        color: active ? "#000" : "#AAA",
                        cursor: "pointer",
                        gap: "10px",
                        ...centerStyle
                    };

                    return (
                        <div

                            className="legend-item"
                            onClick={() => {
                                var elementDataAux = [...elementData];

                                elementDataAux.map((valueElementContent, indexElementContent) => {
                                    if(indexElementContent == index) {
                                        valueElementContent.checked = !valueElementContent.checked;
                                    }
                                })

                                setElementData(elementDataAux);
                            }}
                            style={style}
                        >
                            {
                                type  == "line"
                                ?
                                    <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32">
                                        <title></title>
                                        <desc></desc>
                                        <path
                                        stroke-width="4"
                                        fill={active ? color : "white"}
                                        stroke={color}
                                        d="M0,16h10.666666666666666
                                            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                                            H32M21.333333333333332,16
                                            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                                        />
                                    </svg>
                                :
                                    <Surface width={10} height={10} viewBox="0 0 10 10">
                                    <Symbols cx={5} cy={5} type={type} size={50} fill={color} />
                                    {!active && (
                                        <Symbols
                                            cx={5}
                                            cy={5}
                                            type={type}
                                            size={25}
                                            fill={"#FFF"}
                                        />
                                    )}
                                    </Surface>
                            }
                            <span>{elementContent.label}</span>
                        </div>
                    );        
                })
            }
        </div>
    )
};

export const RenderCustomizedLegendByTerritorialization  = ({
    colors, 
    type = "circle",
    hasLimitOnLegend = false,
    limitValue = 4,
    graphicInformations,
    setGraphicInformations,
    orientation = null
}) => {
    const rightOrientation = orientation && orientation == "right";

    
    var rightStyle = {width: "160px", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"};
    var centerStyle = {display: "flex", justifyContent: "center", alignItems: "center"};

    var divStyle = rightOrientation ? rightStyle : centerStyle;

    return (
        <div style={divStyle}>
            {graphicInformations && graphicInformations.slice(0, hasLimitOnLegend ? limitValue : graphicInformations.length).map((item, index) => {    
                const checked =  item.checked;
                const color = colors[index];
                const style = {
                    marginRight: 10,
                    color: checked ? "#000" : "#AAA",
                    cursor: "pointer",
                    gap: "10px",
                    ...centerStyle
                };

                if(rightOrientation) {
                    style.marginRight = 0;
                    style.marginLeft = 20;
                    style.marginBottom = 10;
                }

                return (
                    <div
                        className="legend-item"
                        onClick={() => {
                            if(graphicInformations) {
                                var graphicInformationsAux = [...graphicInformations];
            
                                graphicInformationsAux[index].checked =  !graphicInformationsAux[index].checked;
                                
                                setGraphicInformations(graphicInformationsAux);
                            }
                        }}
                        style={style}
                    >
                        {
                            type  == "line"
                            ?
                                <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32">
                                    <title></title>
                                    <desc></desc>
                                    <path
                                    stroke-width="4"
                                    fill={checked ? color : "white"}
                                    stroke={color}
                                    d="M0,16h10.666666666666666
                                        A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                                        H32M21.333333333333332,16
                                        A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                                    />
                                </svg>
                            :
                                <Surface width={10} height={10} viewBox="0 0 10 10">
                                <Symbols cx={5} cy={5} type={type} size={50} fill={color} />
                                {!checked && (
                                    <Symbols
                                        cx={5}
                                        cy={5}
                                        type={type}
                                        size={25}
                                        fill={"#FFF"}
                                    />
                                )}
                                </Surface>
                        }
                        <span style={{textAlign: "center", color}}>{item.key}</span>
                    </div>
                );        
            })}
        </div>
    )
};
