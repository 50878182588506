import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
  } from "reactstrap";
import { timeStampFormated } from "../../../../services/utils/format";
import ReactTooltip from "react-tooltip";
import { postPendingInventoryRecord, postReverseInventory} from "../../../../services/api/InventoryMovementRecord";
import Employee from "../../Employee/Employee";
import { fetchEmployees } from "../../../../services/api/Employee";
import { getUserData } from "../../../../services/utils/auth";
import checkQuantity from "../../../../services/utils/checkQuantityInventory";
import LoadingSpin from "react-loading-spin";
import CsvDownloadButton from 'react-json-to-csv'
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import "./InventoryMovementRecordTable.css";
import {checkShowBiovec} from "../../../../services/utils/Inventory/checkShowBiovec";

class InventoryMovementTable extends React.Component {
    state = {
        Movements:this.props.Movements,
        modal: false,
        modalCancel: false,
        modalReverse: false,
        lastValueMovement: {},
        movement: {},
        buttonLoading: false,
    }
    constructor (props){
        
        super(props);
    }

    componentDidMount(){
        var showBiovec = checkShowBiovec();

        this.setState({showBiovec})

        var requestEmployee = fetchEmployees();
        requestEmployee.then(response => {
            this.setState({employees: response.data});
        })

        this.setState({ Movements:this.props.Movements});
    }

    deepCopy(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj; 
        }
    
        const copy = Array.isArray(obj) ? [] : {};

        
    
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                copy[key] = this.deepCopy(obj[key]);
            }
        }
    
        return copy;
    }

    objectToastStyle = {
        className: 'custom-toast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000000, 
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    changePending(e, toCancel = false) {
        var UserWhoReceiveId = getUserData("userId");
        var recordMovement = {...e, UserWhoReceiveId: UserWhoReceiveId};
        if(toCancel) {
            recordMovement.status = "Canceled";
        }
        postPendingInventoryRecord(recordMovement).then((response) => {
            if(response.data == false) {
                toast.error("Não foi possível atualizar a movimentação", this.objectToastStyle)
            } else {
                toast.success("Movimentação atualizada com sucesso!", this.objectToastStyle);
            }
        });
    }

    RetweetComponent = ({e}) => (
        <>
            <button
                data-tip data-for="reverseMovement"
                style={{ 'width': '60px', 'margin': '0 25px' }}
                className="btn btn-warning"
                type="button"
                onClick={async () => {
                    if (!this.state.buttonLoading) {
                        this.setState({ buttonLoading: true , movement: e});
                        this.toggleReverse(true);
                        this.setState({ buttonLoading: false });
                    }
                }}
            >
                {
                    this.state.buttonLoading
                        ? <LoadingSpin primaryColor="#fff" size={10} />
                        : <i className="fa fa-retweet" />
                }
            </button>
            <ReactTooltip effect="solid" type="warning" id="reverseMovement">
                <span>Desfazer movimentação</span>
            </ReactTooltip>
        </>
    );

    buildPendingButton(e) {
        if(e.status == "Pending") {
            return (
                <>
                    <button
                        data-tip data-for="validateMovement"
                        style={{'width':'60px', 'margin' : '5px'}}
                        className="btn btn-primary"
                        type="button"
                        onClick={async () => {
                            if(!this.state.buttonLoading){
                                this.setState({buttonLoading: true})
                                var check = true;
                                if(!e.returned) {
                                    check = await checkQuantity(e);
                                }
                                if(check) {
                                    this.toggle(true);
                                    this.setState({lastValueMovement: this.deepCopy(e)})
                                    this.setState({movement: e})
                                }
                                this.setState({buttonLoading: false})
                            }
                        }}
                    >
                        {
                            this.state.buttonLoading
                            ?
                            <LoadingSpin primaryColor="#fff" size={10} />
                            :
                            <i className="fa fa-check" />                                
                        }
                    </button>
                    <ReactTooltip effect="solid" type="success" id="validateMovement">
                        <span>Validar movimentação</span>
                    </ReactTooltip>
                    <button
                        data-tip data-for="unvalidateMovement"
                        style={{'width':'60px', 'margin' : '5px'}}
                        className="btn btn-warning"
                        type="button"
                        onClick={async () => {
                            if(!this.state.buttonLoading){
                                this.setState({buttonLoading: true})
                                this.toggleCancel(true);
                                this.setState({movement: e})
                                this.setState({buttonLoading: false})
                            }
                        }}
                    >
                        {
                            this.state.buttonLoading
                            ?
                            <LoadingSpin primaryColor="#fff" size={10} />
                            :
                            <i className="fa fa-times" />                                
                        }
                    </button>
                    <ReactTooltip effect="solid" type="error" id="unvalidateMovement">
                        <span>Cancelar movimentação</span>
                    </ReactTooltip>
                </>
            )
        } else if (e.status == "Updated") {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <i className="fa fa-check" />
                    <this.RetweetComponent e={e} />
                </div>
            );
        } else if (e.status == "Canceled") {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <i className="fa fa-times" />
                </div>
            );
        } else if (e.status == "Reversed") {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <i className="fa fa-retweet" />
                </div>
            );
        }
    }

    buildPlace(movement){
        if (movement.place && movement.place.name && movement.originPlace && movement.originPlace.name) {
            if(movement.returned) {
                return `De ${movement.originPlace.name} Para ${movement.place.name}`;
            } else {
                return `De ${movement.place.name} Para ${movement.originPlace.name}`;
            }
        } else if (movement.place && movement.place.name) {
            return `${movement.place.name}`;
        }
        
    }

    toggle(boolean){
        this.setState({modal: boolean})
    }
    
    toggleCancel(boolean){
        this.setState({modalCancel: boolean})
    }
    
    toggleReverse(boolean){
        this.setState({modalReverse: boolean})
    }

    handleChangeStocableRecord(field, value, index) {
        const {movement} = this.state;

        const foundItem = this.state.movement.stocables[index];
        foundItem[field] = value;

        this.setState({movement});
    }

    closeModal() {
        const {movement} = this.state;

        movement.stocables.map((stocable, index) => {
            var value = this.state.lastValueMovement.stocables[index].quantity;
            this.handleChangeStocableRecord("quantity", value, index)
        })

        this.toggle(false);
    }

    withoutParenteses(string) {
        // const stringModificada = string.replace(/[()]/g, ''); //string.replace(/\s*\(.*?\)\s*/g, '');

        // return stringModificada;
        return string;
    }

    insideParenteses(string) {
        const regex = /\((.*?)\)/g;
        const conteudoEntreParenteses = [];
        let match;
    
        while ((match = regex.exec(string)) !== null) {
            conteudoEntreParenteses.push(match[1]);
        }
    
        return conteudoEntreParenteses;
    }

    formatMovements(movimentArray) {
        var movimentCsv = [];


        movimentArray.map((movimentObj) => {
            var status = "Realizado";
            if(movimentObj.status == "Pending"){
                status = "Pendente";
            } else if (movimentObj.status == "Updated"){
                status = "Realizado";
            } else if(movimentObj.status == "Canceled"){
                status = "Cancelado";
            }

            var stocableString = "";
            movimentObj.stocables.map((stocable) => {
                stocableString += `Lote ${stocable.lot} (${this.withoutParenteses(stocable.name)}): ${stocable.quantity}; `
            })

            movimentCsv.push({
                "Tipo de movimento": movimentObj.returned ? "Entrada" : "Saída",
                "Status do movimento": status,
                "Local de Armazenamento" : movimentObj.place ? movimentObj.place.name : "",
                "Local de destino" : movimentObj.originPlace ? movimentObj.originPlace.name : "",
                "Estocáveis": stocableString
            })
        })


        return movimentCsv;
    }

    dateFormated(){
        let date = new Date();

        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); 
        let day = String(date.getDate()).padStart(2, '0');

        let dateFormated = `${day}_${month}_${year}`;

        return dateFormated
    }

    
    render() {
        var columsCondicional = this.props.columsCondicional;

        let columns = [];
        if(columsCondicional && columsCondicional.length > 0) {
            if(columsCondicional.some(objeto => objeto.value === "Data")) {
                columns.push({
                    name:'Data',
                    selector: 'returnDate',
                    cell: (i => timeStampFormated(i.returnDate)),
                    sortable:true,
                })
            }
            if(columsCondicional.some(objeto => objeto.value === "Tipo de movimentação")) {
                columns.push({
                    name: 'Tipo de movimentação',
                    selector: (i => {
                        var response = i.returned ? "Entrada" : "Saída";
                        return response
                    }),
                    sortable: true,
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Local de armazenamento")) {
                columns.push({
                    name: 'Local de armazenamento',
                    selector: (l => l.place ? l.place.name : null),
                    wrap: true
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Local de destino")) {
                columns.push({
                    name: 'Local de destino',
                    selector: (l => l.originPlace ? l.originPlace.name : null),
                    wrap: true
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Nome")) {
                columns.push({
                    name: 'Nome',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{this.withoutParenteses(stocable.name)}</div>))
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Tipo")) {
                columns.push({
                    name: 'Tipo',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{this.insideParenteses(stocable.name)}</div>))
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Lote")) {
                columns.push({
                    name: 'Lote',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{stocable.lot}</div>))
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Quantidade")) {
                columns.push({
                    name: 'Quantidade',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{stocable.quantity}</div>))
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Ordem de fornecimento")) {
                columns.push({
                    name: 'Ordem de fornecimento',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{stocable.osNumber ? stocable.osNumber : ""}</div>))
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Registrador")) {
                columns.push({
                    name: 'Registrador',
                    selector: 'WhoModified',
                    sortable: true, 
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Funcionário")) {
                columns.push({
                    name: 'Funcionário',
                    selector: 'WhoMoved',
                    sortable: true, 
                });
            }
            if(columsCondicional.some(objeto => objeto.value === "Pendência")) {
                columns.push({
                    name: 'Pendência',
                    selector: 'status',
                    sortable: true, 
                    cell: e => this.buildPendingButton(e)
                });
            }

        } else {
            columns = [
                {
                    name:'Data',
                    selector: 'returnDate',
                    cell: (i => timeStampFormated(i.returnDate)),
                    sortable:true,
                    
                },
                {
                    name: 'Tipo de movimentação',
                    selector: (i => {
                        var response = i.returned ? "Entrada" : "Saída";
                        return response
                    }),
                    sortable: true,
                },
                {
                    name: 'Local de armazenamento',
                    selector: (l => l.place ? l.place.name : null),
                    wrap: true
                },
                {
                    name: 'Local de destino',
                    selector: (l => l.originPlace ? l.originPlace.name : null),
                    wrap: true
                },
                {
                    name: 'Nome',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{this.withoutParenteses(stocable.name)}</div>))
                }, 
                {
                    name: 'Tipo',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{this.insideParenteses(stocable.name)}</div>))
                }, 
                {
                    name: 'Lote',
                    selector: (i => {

                        return i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{stocable.lot}</div>)
                    })
                }, 
                {
                    name: 'Quantidade',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{stocable.quantity}</div>))
                },
                {
                    name: 'Ordem de fornecimento',
                    selector: (i => i.stocables && i.stocables.map(stocable => <div style={{marginTop: '5px', marginBottom: '5px'}}>{stocable.osNumber ? stocable.osNumber : ""}</div>))
                },
                {
                    name: 'Registrador',
                    selector: 'WhoModified',
                    sortable: true, 
                },
                {
                    name: 'Funcionário',
                    selector: 'WhoMoved',
                    sortable: true, 
                },
                {
                    name: 'Pendência',
                    selector: 'status',
                    sortable: true, 
                    cell: e => this.buildPendingButton(e)
                }
            ];
        }

        const styles = {
            row: {
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            },
            yellowBox: {
              margin: '14px 10px',
              display: 'inline-block',
              width: '40px',
              height: '10px',
              background: 'yellow',
              padding: '10px',
            },
            whiteBox: {
              margin: '14px 10px',
              display: 'inline-block',
              width: '40px',
              height: '10px',
              background: 'white',
              border: '1px solid black',
              padding: '10px',
            },
          };

        const conditionalRowStyles = [
            {
                when: row => row.status == "Reversed",
                style: {
                    backgroundColor: 'yellow',
                    color: 'black',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            },
        ];

        
        
        return (
            <Container className="mt--7" fluid>
                <Modal size="lg" isOpen={this.state.modal} fade="true"  toggle={() => {
                    this.closeModal();
                }}>
                    <ModalHeader toggle={() => this.closeModal()}>Confirmação de Movimentação</ModalHeader>
                    <ModalBody>
                        <div className="row"> 
                                    <div className="col-5">
                                        <span className="h2 text-black">Quantidade:</span>
                                    </div>
                        </div>
                        {this.state.movement.stocables && this.state.movement.stocables.map((stocable, index) => (
                            <>
                                <div className="row" style={{margin: "1rem"}}> 
                                    <div className="col-5" style={{display: "flex",alignItems: "center"}}>      
                                        <span className="h4 text-black">{this.withoutParenteses(stocable.name)} - Lote {stocable.lot}</span>
                                    </div>
                                    <div className="col-7">
                                        
                                        <input type="number" className="form-control" name="Quantity" value={stocable.quantity} 
                                        onChange={(e) => {
                                            
                                            this.handleChangeStocableRecord("quantity", parseInt(e.target.value), index)
                                        }} 
                                        />
                                    </div>
                                </div>
                            </>
                        ))}   
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" style={{width: "50%"}}>                          
                        
                            <div className="col-6">
                                <button onClick={() => this.closeModal()} className="btn">CANCELAR</button>
                            </div>
                            <div className="col-6">
                                <button onClick={async () => {
                                    if(!this.state.buttonLoading){
                                        this.setState({buttonLoading: true})
                                        var check = true;
                                        if(!this.state.movement.returned) {
                                            check = await checkQuantity(this.state.movement);
                                        }
                                        if(check) {
                                            this.changePending(this.state.movement);
                                            this.toggle(false);
                                        }
                                        this.setState({buttonLoading: false})
                                    }
                                }} className="btn btn-primary">
                                    {this.state.buttonLoading
                                    ?
                                    <LoadingSpin primaryColor="#fff" size={10} />
                                    :
                                    "OK"
                                    }
                                </button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* modal de remoção */}
                <Modal size="lg" isOpen={this.state.modalCancel} fade="true"  toggle={() => this.toggleCancel(false)}>
                    <ModalHeader toggle={() => this.toggleCancel(false)}>Confirmação de Movimentação</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-5">
                                <span className="h2 text-black">Deseja cancelar a movimentação?</span>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" style={{width: "50%"}}>                          
                        
                            <div className="col-6">
                                <button onClick={() => this.toggleCancel(false)} className="btn">NÃO</button>
                            </div>
                            <div className="col-6">
                                <button onClick={() => {
                                    this.toggleCancel(false);
                                    this.changePending(this.state.movement, true);
                                }} className="btn btn-primary">SIM</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* modal de estorno */}
                <Modal size="lg" isOpen={this.state.modalReverse} fade="true"  toggle={() => this.toggleReverse(false)}>
                    <ModalHeader toggle={() => this.toggleReverse(false)}>Confirmação estorno</ModalHeader>
                    <ModalBody>           
                        <div className="row"> 
                            <div className="col-12">
                                <span className="h2 text-black">Desfazer movimentação? <br/><br/><i className="fa fa-exclamation-triangle" style={{marginRight: 10}} />Ao desfazer a movimentação, será feito o estorno do material para o estoque da unidade de armazenamento de saída.</span>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row" style={{width: "50%"}}>                          
                        
                            <div className="col-6">
                                <button onClick={() => this.toggleReverse(false)} className="btn">NÃO</button>
                            </div>
                            <div className="col-6">
                            <button 
                                onClick={async () => {
                                    this.toggleReverse(false);

                                    // Get the user ID
                                    const UserWhoReceiveId = getUserData("userId");
                                    if (!UserWhoReceiveId) {
                                        toast.error("User ID is missing", this.objectToastStyle);
                                        return;
                                    }

                                    // Create the recordMovement object
                                    const recordMovement = {
                                        ...this.state.movement,
                                        UserWhoReceiveId: UserWhoReceiveId,
                                        status: "Reversed"
                                    };


                                    try {
                                        const response = await postReverseInventory(recordMovement);
                                        if (response.data === false) {
                                            toast.error("Não foi possível atualizar a movimentação", this.objectToastStyle);
                                        } else {
                                            toast.success("Movimentação atualizada com sucesso!", this.objectToastStyle);
                                        }
                                    } catch (error) {
                                        console.error("Error updating movement", error);
                                        toast.error("Erro ao atualizar movimentação", this.objectToastStyle);
                                    }
                                }} 
                                className="btn btn-primary">
                                SIM
                            </button>

                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
                <ToastContainer />
                {
                    this.props.loaded
                    ?
                    <Row className="mt-12">
                        <Col className="mb-12 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                        <h3 className="mb-0">Movimentos</h3>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col">
                                            <CsvDownloadButton className="btn btn-primary" data={this.formatMovements(this.props.Movements)} filename={'movimentacoes_arquivo_' + this.dateFormated()}> <i className="fa fa-download" /> Baixar CSV de movimentações</CsvDownloadButton>
                                        </div>
                                    </Row>
                                    <Row>
                                    <div className="col">
                                        <div style={styles.row}>
                                            <div style={styles.yellowBox}></div>
                                            <span>Estorno</span>
                                        </div>
                                        <div style={styles.row}>
                                            <div style={styles.whiteBox}></div>
                                            <span>Movimentação normal</span>
                                        </div>
                                    </div>
                                    </Row>
                                </CardHeader>
                                <DataTable
                                    noHeader
                                    defaultSortField="returnDate"
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                    columns={columns}
                                    data={this.props.Movements && this.props.Movements.filter(m => m.place && ((m.place.name != "BIOVEC" && (!m.originPlace || (m.originPlace && m.originPlace.name != "BIOVEC")) && !this.state.showBiovec) || this.state.showBiovec))}
                                    noDataComponent={'Nenhum registro encontrado.'}
                                    conditionalRowStyles={conditionalRowStyles}
                                />
                            </Card>
                        </Col>
                    </Row>
                    :
                    <TableLoadingSkeleton length={4}/>
                }
                
            </Container>
        )
    }
}

export default InventoryMovementTable