import React from "react";
import { NotificationsAgeGroupGraphic } from "./NotificationsAgeGroupGraphic";
import { ConfirmedAgeGroupGraphic } from "./ConfirmedAgeGroupGraphic";
import { DeathsAgeGroupGraphic } from "./DeathsAgeGroupGraphic";
import { RecoveredAgeGroupGraphic } from "./RecoveredAgeGroupGraphic";

const useAgeGroupsGraphicsWrapper = () => {
  const [graphicType, setGraphicType] = React.useState({
    label: "Notificações",
    value: "notifications",
  });

  const handleGraphicTypeChange = (graphicType) => {
    setGraphicType(graphicType);
  };

  const graphicTypeOptions = [
    { label: "Notificações", value: "notifications" },
    { label: "Confirmados", value: "confirmed" },
    { label: "Óbitos", value: "deaths" },
    { label: "Curas", value: "recovered" },
  ];

  return {
    graphicType,
    handleGraphicTypeChange,
    graphicTypeOptions,
  };
};

export const AgeGroupsGraphicsWrapper = ({
  notificationsList,
  periodLegend,
}) => {
  const { graphicType, handleGraphicTypeChange, graphicTypeOptions } =
    useAgeGroupsGraphicsWrapper();

  if (graphicType.value === "notifications") {
    return (
      <NotificationsAgeGroupGraphic
        graphicType={graphicType}
        graphicTypeOptions={graphicTypeOptions}
        handleGraphicTypeChange={handleGraphicTypeChange}
        notificationsList={notificationsList}
        periodLegend={periodLegend}
      />
    );
  }

  if (graphicType.value === "confirmed") {
    return (
      <ConfirmedAgeGroupGraphic
        graphicType={graphicType}
        graphicTypeOptions={graphicTypeOptions}
        handleGraphicTypeChange={handleGraphicTypeChange}
        notificationsList={notificationsList}
        periodLegend={periodLegend}
      />
    );
  }

  if (graphicType.value === "deaths") {
    return (
      <DeathsAgeGroupGraphic
        graphicType={graphicType}
        graphicTypeOptions={graphicTypeOptions}
        handleGraphicTypeChange={handleGraphicTypeChange}
        notificationsList={notificationsList}
        periodLegend={periodLegend}
      />
    );
  }

  if (graphicType.value === "recovered") {
    return (
      <RecoveredAgeGroupGraphic
        graphicType={graphicType}
        graphicTypeOptions={graphicTypeOptions}
        handleGraphicTypeChange={handleGraphicTypeChange}
        notificationsList={notificationsList}
        periodLegend={periodLegend}
      />
    );
  }

  return null;
};
