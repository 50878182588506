import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import ReactTooltip from "react-tooltip";

const IdentificationResultNew = ({
    sampleIdentifications, 
    options, 
    sampleGroupIndex,
    sampleResultIndex,
    isDisableOrganization,
    groupedInvertebratesOptions,
    groupedOptions,
    indexSample,
    handleChangeArrayData
}) => {
    const [quantities, setQuantities] = React.useState({
        quantitySoma: 0,
        quantityAliveSoma: 0,
        quantityDeadSoma: 0    
    })

    React.useEffect(() => {
        if(sampleIdentifications && sampleIdentifications.length > 0) {
            var quantitiesAux = {  
                quantitySoma: 0,
                quantityAliveSoma: 0,
                quantityDeadSoma: 0
            };

            sampleIdentifications.map((sampleIdent) => {
                quantitiesAux.quantitySoma += sampleIdent["quantity"];
                quantitiesAux.quantityAliveSoma += sampleIdent["quantityAlive"];
                quantitiesAux.quantityDeadSoma += sampleIdent["quantityDead"];
            })

            setQuantities(quantitiesAux);
        }
    }, [sampleIdentifications])

    const addIdentification = () => {
        let sampleIdentificationsAux = [...sampleIdentifications];

        sampleIdentificationsAux.push({
            sampleIdentificationId:"00000000-0000-0000-0000-000000000000",
            gender:"",
            stage:"",
            quantity:0,
            quantityAlive:0,
            quantityDead:0,
            show: true
        });

        handleChangeArrayData("sampleIdentifications", sampleIdentificationsAux, sampleGroupIndex, sampleResultIndex)
    }

    const removeIdentification = (indexIdentification) => {
        let sampleIdentificationsAux = [...sampleIdentifications];

        sampleIdentificationsAux[indexIdentification].quantity = 0;
        sampleIdentificationsAux[indexIdentification].quantityAlive = 0;
        sampleIdentificationsAux[indexIdentification].quantityDead= 0;

        sampleIdentificationsAux[indexIdentification].Show = false;

        handleChangeArrayData("sampleIdentifications", sampleIdentificationsAux, sampleGroupIndex, sampleResultIndex)
    }

    const handleChangeIdentification = (identification, valueInput, index) => {
        let sampleIdentificationsAux = [...sampleIdentifications];

        if(identification == 'quantity' || identification == 'quantityAlive' || identification == 'quantityDead'){
            let value = valueInput ? parseInt(valueInput) : 0;

            sampleIdentificationsAux[index][identification] = value >= 0 || value < 0 ? value : 0; //prevenindo bug
            
            if(identification !== 'quantity'){
                const quantityAlive = sampleIdentificationsAux[index]["quantityAlive"] || 0;
                const quantityDead = sampleIdentificationsAux[index]["quantityDead"] || 0;

                sampleIdentificationsAux[index]["quantity"] = quantityAlive +  quantityDead;
            } else {
                sampleIdentificationsAux[index]["quantityAlive"] = 0;
                sampleIdentificationsAux[index]["quantityDead"] = 0;
            }
        }else{
            sampleIdentificationsAux[index][identification] = valueInput;  
        }
        
        handleChangeArrayData("sampleIdentifications", sampleIdentificationsAux, sampleGroupIndex, sampleResultIndex)
    }

    return (
        <Card style={{"marginTop": "2%", "marginBottom": "2%"}}>
            <CardHeader>
                <span className="h4"><i className='fa fa-microscope'></i> Identificação</span>
            </CardHeader>
            <CardBody>
                {sampleIdentifications.map((identification, indexIdentification) => {
                    return (                            
                    <div className='row' style={{'display' : identification.Show == false ? 'none' : '' }} >                              
                        <div className="col-3 responsive-form-field-result-full">
                            <span className="h5">Fase</span>

                            <Select
                                onChange={ (e) => handleChangeIdentification("stage", e.value, indexIdentification)} 
                                name="stage" 
                                id=""                                    
                                value={options.filter(option => option.value == identification.stage)}
                                options={isDisableOrganization?groupedInvertebratesOptions:groupedOptions} 
                            />
                        </div>

                        {identification.stage =="Mosquito Adulto"?
                        <>
                            <div className="col-2 responsive-form-field-result-full">
                            <span className="h5">Sexo</span>
                            <select className="form-control" value={identification.gender} id="" onChange={(e) => handleChangeIdentification("gender", e.target.value, indexIdentification)}>
                                <option value="" selected={identification.gender == ""}>Selecione</option>
                                <option value="Macho" selected={identification.gender == "Macho"}>Macho</option>
                                <option value="Fêmea" selected={identification.gender == "Fêmea"}>Fêmea</option>                                                                             
                            </select>
                            </div>

                            <div className="col-2 responsive-form-field-result-medium">
                                <span className="h5">Qtd Vivos</span>
                                <input className="form-control" value={identification.quantityAlive || ""} onChange={ (e) => handleChangeIdentification("quantityAlive", e.target.value, indexIdentification)}  type="number"  placeholder="0"/>
                            </div>

                            <div className="col-2 responsive-form-field-result-medium">
                            <span className="h5">Qtd Mortos</span>
                            <input className="form-control" value={identification.quantityDead || ""}  onChange={ (e) => handleChangeIdentification("quantityDead", e.target.value, indexIdentification)} type="number"  id=""placeholder="0"/>
                            </div> 
                        </> : ""}

                        <div className="col-2 responsive-form-field-result-full">
                            <span className="h5">Qtd Total</span>
                            <input disabled={identification.stage == "Mosquito Adulto"} className="form-control" value={identification.quantity} onChange={ (e) => handleChangeIdentification("quantity", e.target.value, indexIdentification)}  type="number" placeholder="0"/>
                        </div>
                        <div className="cfol-1 responsive-form-field-result-full">
                        <span className="h5">&nbsp;</span>
                        <button data-tip data-for="deleteResult" className="btn btn-warning responsive-button-result" onClick={() => removeIdentification(indexIdentification)}><i className='fa fa-trash'></i></button>  
                        <ReactTooltip effect="solid" type="warning" id="deleteResult">
                            <span>Apagar resultado</span>
                        </ReactTooltip>
                        </div>                                
                    </div>                            
                    )
                })}
                {sampleIdentifications.filter(si => si.Show == true).length > 0 ?
                    <>
                        <div className="row">
                            <div className="col-3 responsive-form-field-result-medium">
                                <span className="h3">Total</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 responsive-form-field-result-medium">
                                <span className="h5">Qtd Somada Vivos</span>
                                <input className="form-control" value={quantities.quantityAliveSoma} disabled type="number" placeholder="0"/>
                            </div>

                            <div className="col-3 responsive-form-field-result-medium">
                            <span className="h5">Qtd Somada Mortos</span>
                            <input className="form-control" value={quantities.quantityDeadSoma} disabled  type="number"  id=""placeholder="0"/>
                            </div>
                            <div className="col-3 responsive-form-field-result-full">
                                <span className="h5">Qtd Somada Total</span>
                                <input className="form-control" value={quantities.quantitySoma} disabled  type="number" placeholder="0"/>
                            </div>   
                        </div>
                    </> 
                :null}
                <div className="row mt-4 mb-4"> 
                    <div className={sampleIdentifications.length > 1 ? "col-6 responsive-form-field-result-full" : "col-12 responsive-form-field-result-full"} >
                        <button className="btn btn-info responsive-button-result" onClick={() => addIdentification()}>+ Adicionar Identificação</button>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default IdentificationResultNew;