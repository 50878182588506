// sampleResultHelper.js
import { toast } from 'react-toastify';
import moment from 'moment';
import { changeSample, postSample } from "../../../services/api/Sample";

export const SendSampleResult = async (state, setState, saveAndContinue, index = 0) => {
  if (state.wasSaved == true) {
    toast.info("Resultado cadastrado anteriormente.");
    return;
  }

  if (state.data[index].sampleDataNumber == '') {
    toast.error("Erro: Número da amostra em branco");
    return;
  }
  if (state.isPossibleDiagnosis) {
    const { data } = state;
    
    var sampleData = data[index].sampleData;

    sampleData.sampleExtraInformations = null;
    sampleData.genderIdentified = data[index].selectedAnimalGenre;
    sampleData.virusTypeIdentified = data[index].selectedVirusType;
    sampleData.speciesIdentified = data[index].selectedAnimalSpecie;
    sampleData.typeAnimalGroupIdentified = data[index].selectedAnimalTypeGroup;
    sampleData.animalIdentified = data[index].selectedAnimalGroup;
    sampleData.identificationSampleHistory = data[index].sampleClassification;
    sampleData.sampleDiagnosticHistory = data[index].sampleDiagnosis;
    console.log("data[index].sampleIdentifications teste send", data[index].sampleIdentifications)
    sampleData.sampleAnalysisIdentification = data[index].sampleIdentifications.filter((si) => si.Show == true);
    sampleData.ConservationTypeId = data[index].sampleConservationType;
    if (data[index].sampleExtraInformations) {
      sampleData.sampleExtraInformationsId = data[index].sampleExtraInformations.id;
    } else {
      if (data[index].isFractionated) {
        var indexOfPrincipalSample = data[index].indexOfPrincipalSample;
        sampleData.sampleExtraInformations = {};
        console.log("indexOfPrincipalSample teste post, fracionado, data[indexOfPrincipalSample]", indexOfPrincipalSample, data[indexOfPrincipalSample]);
        sampleData.sampleExtraInformations.isFractionated = data[index].isFractionated;
        sampleData.sampleExtraInformations.fracionedBySampleId = data[indexOfPrincipalSample].sampleData.id;
        sampleData.sampleExtraInformations.isAnotherResult = false;
        sampleData.sampleExtraInformations.anotherResultBySampleId = "00000000-0000-0000-0000-000000000000";
        sampleData.sampleExtraInformations.extraNumber = typeof data[index].extraNumber == "number" ? String(data[index].extraNumber) : data[index].extraNumber;
      } else if (data[index].isAnotherResult) {
        var indexOfPrincipalSample = data[index].indexOfPrincipalSample;
        sampleData.sampleExtraInformations = {};
        console.log("indexOfPrincipalSample teste anotherResult post, data[indexOfPrincipalSample]", indexOfPrincipalSample, data[indexOfPrincipalSample]);
        sampleData.sampleExtraInformations.isFractionated = false;
        sampleData.sampleExtraInformations.fracionedBySampleId = "00000000-0000-0000-0000-000000000000";
        sampleData.sampleExtraInformations.isAnotherResult = data[index].isAnotherResult;
        sampleData.sampleExtraInformations.anotherResultBySampleId = data[indexOfPrincipalSample].sampleData.id;
        sampleData.sampleExtraInformations.extraNumber = typeof data[index].extraNumber == "number" ? String(data[index].extraNumber) : data[index].extraNumber;
      }
    }
    sampleData.Number = data[index].sampleDataNumber;
    sampleData.status = "Analyzed";

    if (state.data[index].post) {
      var postSampleData = {
        Number: data[index].sampleDataNumber,
        TypeMaterial: data[0].sampleData.typeMaterial,
        DemandId: data[0].sampleData.demandId,
        OrganizationId: data[0].sampleData.organizationId,
        SampleExtraInformations: sampleData.sampleExtraInformations,
        VisitId: data[0].sampleData.visitId, 
        DateCollected: moment().format("YYYY-MM-DD"),
        CollectorId: data[0].sampleData.collectedBy,
      };

      console.log("postSampleData", postSampleData);

      let response = await postSample(postSampleData);
      console.log("response data[index] ", response, data[index].sampleData);
      data[index].sampleData.id = response.data.id;
      data[index].sampleData.quantity = 0;
      data[index].sampleData.typeAnimalGroupIdentified = "00000000-0000-0000-0000-000000000000";
      if (response.data.sampleExtraInformations) {
        data[index].sampleData.sampleExtraInformationsId = response.data.sampleExtraInformations.id;
      }
      let responseChangeSample = await changeSample(data[index].sampleData);
      console.log("response", responseChangeSample);

      if (responseChangeSample.data.id) {
        if (index > 0) {
          toast.success(`${index+1}º Resultado cadastrado com sucesso!`);
        } else {
          toast.success("Resultado cadastrado com sucesso!");
        }
        if (saveAndContinue == true) {
          if (state.data[index].sampleData.visitId != "" || state.data[index].sampleData.visitId != null)
            localStorage.setItem("visitId-", state.data[index].sampleData.visitId);                
        }
      }
    } else {
      let response = await changeSample(data[index].sampleData);
      if (response.data.id) {
        if (index > 0) {
          toast.success(`${index+1}º Resultado cadastrado com sucesso!`);
        } else {
          toast.success("Resultado cadastrado com sucesso!");
        }
        if (saveAndContinue == true) {
          if (state.data[index].sampleData.visitId != "" || state.data[index].sampleData.visitId != null)
            localStorage.setItem("visitId", state.data[index].sampleData.visitId);                
        }
      }
    }

  } else {
    state.data[index].sampleData.status = "Diagnostic not possible";
    state.data[index].sampleData.ListJustificationsDisposalTypes = state.data[index].sampleJustificationDisposalTypes;
    let response = await changeSample(state.data[index].sampleData);
    if (response.data.id) {
      toast.success("Resultado cadastrado com sucesso!");
    }
  }

  return true; 
};
