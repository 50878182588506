import React from "react";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  GREEN_TRAP_COLOR,
  RED_TRAP_COLOR,
} from "../../../../services/utils/globalFunctions";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Rectangle } from "@react-google-maps/api";
import moment from "moment";
import useLoading from "../Hooks/useLoading";
import VerticalBarGraphicLoadingSkeleton from "../../../../components/ui/Loading/VerticalBarGraphicLoadingSkeleton";
import {
  VitecCard,
  VitecCardDefaultView,
  VitecCardDefaultViewContent,
  VitecCardDefaultViewFooter,
  VitecCardDefaultViewHeader,
  VitecCardDownloadImageButton,
  VitecCardGeneratedAt,
  VitecCardSwitchButton,
} from "../../../../components/Cards/VitecCard";
import { Map as MapIcon, MessageCircleWarning } from "lucide-react";
import ReactTooltip from "react-tooltip";
import { Map } from "../../../../components/Maps/Map";
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from "react-leaflet";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { polygon } from "@turf/turf";
const { BaseLayer, Overlay } = LayersControl;

const useHistogramTrapsSituationsByColorsGraphics = (groupPoints) => {
  const [trapsSituationsByColors, setTrapsSituationsByColors] = React.useState({
    green: [],
    yellow: [],
    red: [],
  });
  const [
    considerGreatestSituationByColor,
    setConsiderGreatestSituationByColor,
  ] = React.useState(true);
  const [isMapsClustured, setIsMapsClustured] = React.useState(true);
  const [isHistogramTrapsMapOpen, setIsHistogramTrapsMapOpen] =
    React.useState(false);
  const [histogramTraps, setHistogramTraps] = React.useState([]);
  const { appliedFilters } = useAppliedFilters();
  const { isTrapsListLoading } = useLoading();
  const { territorializationsList } = useTerritorializationsList();

  React.useEffect(() => {
    if (
      !groupPoints ||
      groupPoints.length === 0 ||
      appliedFilters.trapType.trapTypeName !==
        "armadilhaDisseminadoraInseticida"
    )
      return;

    const trapsSituationsByColors = [
      {
        situation: "Verde",
        "0 a 10": [],
        "11 a 20": [],
        "21 a 30": [],
      },
      {
        situation: "Amarelo",
        "31 a 40": [],
        "41 a 50": [],
        "51 a 60": [],
      },
      {
        situation: "Vermelho",
        "61 a 90": [],
        "91 a 120": [],
        "Mais de 120": [],
      },
    ];

    const greenTraps = trapsSituationsByColors[0];
    const yellowTraps = trapsSituationsByColors[1];
    const redTraps = trapsSituationsByColors[2];

    const adiGroupPoints = groupPoints.find(
      (groupPoint) =>
        groupPoint.trapTypeName === "armadilhaDisseminadoraInseticida"
    ).points;

    for (let i = 0; i < adiGroupPoints.length; i++) {
      const trap = adiGroupPoints[i];

      if (trap.daysSinceLastActivity <= 10) {
        greenTraps["0 a 10"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity <= 20) {
        greenTraps["11 a 20"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity <= 30) {
        greenTraps["21 a 30"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity <= 40) {
        yellowTraps["31 a 40"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity <= 50) {
        yellowTraps["41 a 50"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity <= 60) {
        yellowTraps["51 a 60"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity <= 90) {
        redTraps["61 a 90"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity <= 120) {
        redTraps["91 a 120"].push(trap);
        continue;
      }

      if (trap.daysSinceLastActivity > 120) {
        redTraps["Mais de 120"].push(trap);
        continue;
      }
    }

    const greenHistogramData = Object.keys(greenTraps)
      .filter((key) => key !== "situation")
      .map((key) => ({
        situation: key,
        value: greenTraps[key].length,
      }));

    const yellowHistogramData = Object.keys(yellowTraps)
      .filter((key) => key !== "situation")
      .map((key) => ({
        situation: key,
        value: yellowTraps[key].length,
      }));

    const redHistogramData = Object.keys(redTraps)
      .filter((key) => key !== "situation")
      .map((key) => ({
        situation: key,
        value: redTraps[key].length,
      }));

    let greatestSituationByColor = 0;

    greenHistogramData.forEach((item) => {
      if (item.value > greatestSituationByColor) {
        greatestSituationByColor = item.value;
      }
    });

    yellowHistogramData.forEach((item) => {
      if (item.value > greatestSituationByColor) {
        greatestSituationByColor = item.value;
      }
    });

    redHistogramData.forEach((item) => {
      if (item.value > greatestSituationByColor) {
        greatestSituationByColor = item.value;
      }
    });

    const result = {
      greenTraps,
      yellowTraps,
      redTraps,
      greenHistogram: greenHistogramData,
      yellowHistogram: yellowHistogramData,
      redHistogram: redHistogramData,
      greatestSituationByColor,
    };

    setTrapsSituationsByColors(result);
  }, [groupPoints]);

  const toggleMapHistogram = (histogramTraps) => {
    setIsHistogramTrapsMapOpen((previousValue) => {
      if (previousValue) {
        setHistogramTraps([]);
      } else {
        setHistogramTraps(histogramTraps);
      }

      return !previousValue;
    });
  };

  const handleConsiderGreatestSituationByColorChange = () => {
    setConsiderGreatestSituationByColor((previousValue) => !previousValue);
  };

  return {
    territorializationsList,
    isMapsClustured,
    setIsMapsClustured,
    toggleMapHistogram,
    isHistogramTrapsMapOpen,
    histogramTraps,
    appliedFilters,
    considerGreatestSituationByColor,
    isTrapsListLoading,
    trapsSituationsByColors,
    handleConsiderGreatestSituationByColorChange,
  };
};

export const HistogramTrapsSituationsByColorsGraphics = ({ groupPoints }) => {
  const {
    territorializationsList,
    isMapsClustured,
    setIsMapsClustured,
    toggleMapHistogram,
    isHistogramTrapsMapOpen,
    histogramTraps,
    appliedFilters,
    isTrapsListLoading,
    trapsSituationsByColors,
    considerGreatestSituationByColor,
    handleConsiderGreatestSituationByColorChange,
  } = useHistogramTrapsSituationsByColorsGraphics(groupPoints);

  if (
    appliedFilters.trapType.trapTypeName !== "armadilhaDisseminadoraInseticida"
  )
    return null;

  if (
    isTrapsListLoading ||
    (trapsSituationsByColors.greenTraps.length === 0 &&
      trapsSituationsByColors.yellowTraps.length === 0 &&
      trapsSituationsByColors.redTraps.length === 0)
  )
    return (
      <Row className="mb-4">
        <Col xl={4}>
          <VerticalBarGraphicLoadingSkeleton />
        </Col>
        <Col xl={4}>
          <VerticalBarGraphicLoadingSkeleton />
        </Col>
        <Col xl={4}>
          <VerticalBarGraphicLoadingSkeleton />
        </Col>
      </Row>
    );

  const periodLegend = moment(appliedFilters.endDate).format("DD/MM/YYYY");

  return (
    <section>
      <Modal
        isOpen={isHistogramTrapsMapOpen}
        toggle={toggleMapHistogram}
        style={{ maxWidth: "1000px" }}
      >
        <ModalHeader>Armadilhas por histograma</ModalHeader>
        <ModalBody>
          <HistogramMap
            histogramTraps={histogramTraps}
            isMapsClustured={isMapsClustured}
            isHistogramTrapsMapOpen={isHistogramTrapsMapOpen}
            setIsMapsClustured={setIsMapsClustured}
            territorializationsList={territorializationsList}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ width: "130px" }}
            color="secondary"
            onClick={toggleMapHistogram}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Row className="mb-4">
        <Col xl={4}>
          <HistogramGraphic
            considerGreatestSituationByColor={considerGreatestSituationByColor}
            data={trapsSituationsByColors.greenHistogram}
            greatestSituationByColor={
              trapsSituationsByColors.greatestSituationByColor
            }
            handleConsiderGreatestSituationByColorChange={
              handleConsiderGreatestSituationByColorChange
            }
            mapHistogramData={trapsSituationsByColors.greenTraps}
            toggleMapHistogram={toggleMapHistogram}
            legend={periodLegend}
            variant="green"
          />
        </Col>
        <Col xl={4}>
          <HistogramGraphic
            considerGreatestSituationByColor={considerGreatestSituationByColor}
            data={trapsSituationsByColors.yellowHistogram}
            greatestSituationByColor={
              trapsSituationsByColors.greatestSituationByColor
            }
            handleConsiderGreatestSituationByColorChange={
              handleConsiderGreatestSituationByColorChange
            }
            mapHistogramData={trapsSituationsByColors.yellowTraps}
            toggleMapHistogram={toggleMapHistogram}
            legend={periodLegend}
            variant="yellow"
          />
        </Col>
        <Col xl={4}>
          <HistogramGraphic
            considerGreatestSituationByColor={considerGreatestSituationByColor}
            data={trapsSituationsByColors.redHistogram}
            greatestSituationByColor={
              trapsSituationsByColors.greatestSituationByColor
            }
            handleConsiderGreatestSituationByColorChange={
              handleConsiderGreatestSituationByColorChange
            }
            legend={periodLegend}
            mapHistogramData={trapsSituationsByColors.redTraps}
            toggleMapHistogram={toggleMapHistogram}
            variant="red"
          />
        </Col>
      </Row>
    </section>
  );
};

const HistogramGraphic = ({
  considerGreatestSituationByColor,
  data,
  mapHistogramData,
  toggleMapHistogram,
  greatestSituationByColor,
  handleConsiderGreatestSituationByColorChange,
  legend,
  variant,
}) => {
  const variantsInformations = {
    green: {
      title: (
        <span>
          Histograma <br /> das armadilhas <br /> até 30 dias
        </span>
      ),
      imageDownloadFileName: "histograma_armadilhas_verdes_VITEC",
      barColor: GREEN_TRAP_COLOR,
    },
    yellow: {
      title: (
        <span>
          Histograma <br /> das armadilhas <br /> entre 31 à 60 dias
        </span>
      ),
      imageDownloadFileName: "histograma_armadilhas_amarelas_VITEC",
      barColor: "#ccc20a",
    },
    red: {
      title: (
        <span>
          Histograma <br /> das armadilhas <br /> acima de 60 dias
        </span>
      ),
      imageDownloadFileName: "histograma_armadilhas_vermelhas_VITEC",
      barColor: RED_TRAP_COLOR,
    },
  };

  return (
    <VitecCard>
      <VitecCardDefaultView>
        <VitecCardDefaultViewHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", color: "black" }}>
            {variantsInformations[variant].title}
            <br /> em {legend}
          </span>
          <div style={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
            <VitecCardGeneratedAt />
            <VitecCardDownloadImageButton
              imageFileName={`${
                variantsInformations[variant].imageDownloadFileName
              }_${moment().format("DD/MM/YYYY HH:mm")}`}
              color="primary"
              style={{ width: "58px", height: "45px" }}
            />
            <VitecCardSwitchButton
              color="primary"
              tooltipText="Trocar limite superior"
              style={{ width: "58px", height: "45px" }}
              onClick={handleConsiderGreatestSituationByColorChange}
            />
            <ReactTooltip
              effect="solid"
              type="info"
              id={variantsInformations[variant].barColor}
            >
              Visualizar em mapa
            </ReactTooltip>
            <Button
              color="primary"
              style={{
                width: "58px",
                height: "45px",
                marginLeft: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              data-tip
              data-for={variantsInformations[variant].barColor}
              onClick={() => toggleMapHistogram(mapHistogramData)}
            >
              <MapIcon size={17} />
            </Button>
          </div>
        </VitecCardDefaultViewHeader>
        <VitecCardDefaultViewContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "25rem",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="situation"
                label={{
                  value: "Dias desde a última instalação/manutenção",
                  position: "insideBottom",
                  offset: -5,
                  style: { fontSize: "12px" },
                }}
              />
              <YAxis
                domain={
                  considerGreatestSituationByColor
                    ? [0, greatestSituationByColor]
                    : undefined
                }
                label={{
                  value: "Quantidade",
                  angle: -90,
                  position: "insideLeft",
                  offset: -5,
                  style: { fontSize: "12px" },
                }}
              />
              <Tooltip />
              <Bar
                dataKey="value"
                name="Quantidade"
                fill={variantsInformations[variant].barColor}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </VitecCardDefaultViewContent>
        <VitecCardDefaultViewFooter />
      </VitecCardDefaultView>
    </VitecCard>
  );
};

const HistogramMap = ({
  histogramTraps,
  territorializationsList,
  isMapsClustured,
  isHistogramTrapsMapOpen,
  setIsMapsClustured,
}) => {
  if (!isHistogramTrapsMapOpen) return null;

  return (
    <VitecCard>
      <VitecCardDefaultView>
        <VitecCardDefaultViewHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", color: "black" }}>
            Mapa de armadilhas por histograma
          </span>
          <div style={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
            <VitecCardGeneratedAt />
          </div>
        </VitecCardDefaultViewHeader>
        <VitecCardDefaultViewContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Map
            style={{ height: "35rem" }}
            oncontextmenu={() =>
              setIsMapsClustured((previousValue) => !previousValue)
            }
          >
            <MapPolygonsLayersControl data={territorializationsList} />
            <MapPointsLayersControl
              data={histogramTraps}
              isClustured={isMapsClustured}
            />
          </Map>
        </VitecCardDefaultViewContent>
        <VitecCardDefaultViewFooter>
          <div>
            <MessageCircleWarning size={20} style={{ marginRight: "0.2rem" }} />
            <span style={{ fontSize: "12px" }}>
              Clique com o botão direito do mouse sobre o mapa para desagrupar
              os pontos de armadilhas
            </span>
          </div>
        </VitecCardDefaultViewFooter>
      </VitecCardDefaultView>
    </VitecCard>
  );
};

const MapPolygonsLayersControl = ({ data }) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const typeLayersPolygonsGroup = [];

    data.forEach(({ name, territorializations, id }) => {
      const typeLayerData = {
        id,
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;
        turfPolygon.territorializationId = territorialization.id;
        turfPolygon.typeLayerId = id;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [data]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked name={"Delimitador de Áreas"}>
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons, id }, index) => {
          return (
            <Overlay name={name} key={`${name}-overlay-${index}`}>
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-feature-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map((polygon, index) => {
                    const { geometry, name } = polygon;

                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={`${name}-polygon-${index}`}
                        color={"#000000"}
                        fillColor={"transparent"}
                        opacity={1}
                        positions={coordinates}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const MapPointsLayersControl = ({ data, isClustured }) => {
  const [groupsPointsList, setGroupPointsList] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const groupsPoints = Object.keys(data)
      .filter((key) => key !== "situation")
      .map((key) => {
        return {
          name: key,
          points: data[key],
        };
      });

    setGroupPointsList(groupsPoints);
  }, [data]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked={true} name={"Armadilhas ativas"}>
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {groupsPointsList.map(({ name, points }) => {
        return (
          <Overlay
            key={`${name}-traps-histogram-overlay-${Date.now()}`}
            name={name}
            checked={true}
          >
            {isClustured ? (
              <MarkerClusterGroup
                key={`${name}-traps-histogram-marker-cluster-group-${Date.now()}`}
                removeOutsideVisibleBounds={true}
              >
                {points &&
                  points.length > 0 &&
                  points.map(
                    ({
                      id,
                      address,
                      lastInstallDate,
                      lastReadDate,
                      latitude,
                      longitude,
                      number,
                      icon,
                    }) => {
                      return (
                        <Marker
                          key={id}
                          position={[latitude, longitude]}
                          icon={icon}
                        >
                          <TrapPopup
                            address={address}
                            lastInstallDate={lastInstallDate}
                            id={id}
                            lastReadDate={lastReadDate}
                            number={number}
                            latitude={latitude}
                            longitude={longitude}
                          />
                        </Marker>
                      );
                    }
                  )}
              </MarkerClusterGroup>
            ) : (
              <MarkerClusterGroup
                key={`${name}-traps-histogram-noClusters-marker-cluster-group-${Date.now()}`}
                removeOutsideVisibleBounds={true}
                disableClusteringAtZoom={1}
              >
                {points &&
                  points.length > 0 &&
                  points.map(
                    ({
                      id,
                      address,
                      lastInstallDate,
                      lastReadDate,
                      latitude,
                      longitude,
                      number,
                      icon,
                    }) => {
                      return (
                        <Marker
                          key={id}
                          position={[latitude, longitude]}
                          icon={icon}
                        >
                          <TrapPopup
                            address={address}
                            lastInstallDate={lastInstallDate}
                            id={id}
                            lastReadDate={lastReadDate}
                            number={number}
                            latitude={latitude}
                            longitude={longitude}
                          />
                        </Marker>
                      );
                    }
                  )}
              </MarkerClusterGroup>
            )}
          </Overlay>
        );
      })}
    </LayersControl>
  );
};

const TrapPopup = ({ id, number, address, lastInstallDate, lastReadDate }) => {
  return (
    <Popup key={`popup-${id}`}>
      <span>
        <span style={{ fontWeight: "bold" }}>Armadilha Nº: </span>
        {number}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Endereço: </span>
        {address}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última instalação: </span>
        {moment(lastInstallDate).format("DD/MM/YYYY HH:mm")}
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>Data última manutenção: </span>
        {!lastReadDate || lastReadDate === ""
          ? "-"
          : moment(lastReadDate).format("DD/MM/YYYY HH:mm")}
      </span>
    </Popup>
  );
};