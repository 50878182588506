import React from "react";

import { Col, Row } from "reactstrap";
import { ActivitiesByPeriodsTable } from "../Tables/ActivitiesByPeriodsTable";
import { ActivitiesByPeriodsFromPolygonsTable } from "../Tables/ActivitiesByPeriodsFromPolygonsTable";
import { useTrapsByPeriodsList } from "../../Hooks/useTrapsByPeriodsList";
import { useAppliedFilters } from "../../Hooks/useAppliedFilters";
import moment from "moment";
import { ActivitiesByPeriodsLineGraphic } from "./ActivitiesByPeriodsLineGraphic";
import { AcitivitiesByPeriodsFromUsersTable } from "../Tables/AcitivitiesByPeriodsFromUsersTable";
import { useDownloadableData } from "../../Hooks/useDownloadableData";
import { ARMADILHA_INFO } from "../../../../../constants/ArmadilhaConstant";

const useTerritorializationsReportPageGraphics = () => {
  const [activitiesByPeriods, setActivitiesByPeriods] = React.useState([]);
  const [activitiesByPeriodsFromPolygons, setActivitiesByPeriodsFromPolygons] =
    React.useState([]);
  const [activitiesByPeriodsFromUsers, setActivitiesByPeriodsFromUsers] = React.useState([]);
  const [periodLegend, setPeriodLegend] = React.useState("");
  const { trapsByPeriodsList } = useTrapsByPeriodsList();
  const { setDownloadableData } = useDownloadableData();
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    if (!trapsByPeriodsList || trapsByPeriodsList.length === 0) return;

    const groupedValuesByPeriodResult =
      groupValuesByPeriods(trapsByPeriodsList);
    const groupedValuesByPolygonResult =
      groupValuesByPolygons(trapsByPeriodsList);
    const groupedValuesByUsersResult = groupValuesByUsers(trapsByPeriodsList);
    const groupedValuesByPeriodsFromUsersResult = groupValuesByPeriodFromUsers(trapsByPeriodsList);

    setActivitiesByPeriods(groupedValuesByPeriodResult);
    setActivitiesByPeriodsFromPolygons(groupedValuesByPolygonResult);
    setActivitiesByPeriodsFromUsers(groupedValuesByPeriodsFromUsersResult)
    setDownloadableData((previousValues) => {
      const newValues = { ...previousValues };
      newValues.rawActivitiesByUsers = groupedValuesByUsersResult;

      return newValues;
    });
  }, [trapsByPeriodsList]);

  React.useEffect(() => {
    if (appliedFilters.beginDate !== "" && appliedFilters.endDate !== "") {
      const formatedBeginDate = moment(appliedFilters.beginDate).format(
        "DD/MM/YYYY"
      );
      const formatedEndDate = moment(appliedFilters.endDate).format(
        "DD/MM/YYYY"
      );

      setPeriodLegend(` de ${formatedBeginDate} a ${formatedEndDate}`);

      return;
    }

    if (
      appliedFilters.year.value !== "" &&
      appliedFilters.epidemiologicalWeeks.length === 0
    ) {
      setPeriodLegend(` em ${appliedFilters.year.value}`);

      return;
    }

    if (appliedFilters.epidemiologicalWeeks.length > 0) {
      const orderedEpidemiologicWeeksByBeginDate =
        appliedFilters.epidemiologicalWeeks.sort((a, b) => {
          const aBeginDate = moment(a.beginDate, "DD/MM/YYYY");
          const bBeginDate = moment(b.beginDate, "DD/MM/YYYY");

          return aBeginDate - bBeginDate;
        });

      const beginEpidemiologicWeek = orderedEpidemiologicWeeksByBeginDate[0];
      const endEpidemiologicWeek =
        orderedEpidemiologicWeeksByBeginDate[
          orderedEpidemiologicWeeksByBeginDate.length - 1
        ];

      setPeriodLegend(
        ` da ${beginEpidemiologicWeek.label} a ${endEpidemiologicWeek.label} em ${appliedFilters.year.value}`
      );
    }
  }, [appliedFilters]);

  const groupValuesByPeriods = (data) => {
    const groupedValuesByPeriod = {};

    data.forEach(({ periods }) => {
      periods.forEach(({ periodName, app, typing }) => {
        if (!groupedValuesByPeriod[periodName]) {
          groupedValuesByPeriod[periodName] = {
            periodName,
            totalAppInstallations: 0,
            totalAppMaintenances: 0,
            totalAppUninstallations: 0,
            totalTypingInstallations: 0,
            totalTypingMaintenances: 0,
            totalTypingUninstallations: 0,
          };
        }

        groupedValuesByPeriod[periodName].totalAppInstallations +=
          app.installed.length;
        groupedValuesByPeriod[periodName].totalAppMaintenances +=
          app.readed.length;
        groupedValuesByPeriod[periodName].totalAppUninstallations +=
          app.uninstalled.length;

        groupedValuesByPeriod[periodName].totalTypingInstallations +=
          typing.installed.length;
        groupedValuesByPeriod[periodName].totalTypingMaintenances +=
          typing.readed.length;
        groupedValuesByPeriod[periodName].totalTypingUninstallations +=
          typing.uninstalled.length;
      });
    });

    const result = Object.values(groupedValuesByPeriod);

    return result;
  };

  const groupValuesByPolygons = (data) => {
    const groupedValuesByPolygon = {};

    data.forEach(({ periods, territorializationName }) => {
      groupedValuesByPolygon[territorializationName] = {};

      periods.forEach(({ periodName, app, typing }) => {
        if (!groupedValuesByPolygon[territorializationName][periodName]) {
          groupedValuesByPolygon[territorializationName][periodName] = {
            territorializationName,
            periodName,
            totalAppInstallations: 0,
            totalAppMaintenances: 0,
            totalAppUninstallations: 0,
            totalTypingInstallations: 0,
            totalTypingMaintenances: 0,
            totalTypingUninstallations: 0,
          };
        }

        groupedValuesByPolygon[territorializationName][
          periodName
        ].totalAppInstallations += app.installed.length;
        groupedValuesByPolygon[territorializationName][
          periodName
        ].totalAppMaintenances += app.readed.length;
        groupedValuesByPolygon[territorializationName][
          periodName
        ].totalAppUninstallations += app.uninstalled.length;

        groupedValuesByPolygon[territorializationName][
          periodName
        ].totalTypingInstallations += typing.installed.length;
        groupedValuesByPolygon[territorializationName][
          periodName
        ].totalTypingMaintenances += typing.readed.length;
        groupedValuesByPolygon[territorializationName][
          periodName
        ].totalTypingUninstallations += typing.uninstalled.length;
      });
    });
    const result = Object.values(groupedValuesByPolygon);

    return result;
  };

  const groupValuesByUsers = (data) => {
    const groupedValuesByUsers = [];

    data.forEach(({ periods, territorializationName }) => {
      periods.forEach(({ periodName, app, typing }) => {
        groupedValuesByUsers.push(
          ...formatHistoriesResult(
            app,
            periodName,
            territorializationName,
            "installed",
            "app"
          )
        );
        groupedValuesByUsers.push(
          ...formatHistoriesResult(
            app,
            periodName,
            territorializationName,
            "readed",
            "app"
          )
        );
        groupedValuesByUsers.push(
          ...formatHistoriesResult(
            app,
            periodName,
            territorializationName,
            "uninstalled",
            "app"
          )
        );
        groupedValuesByUsers.push(
          ...formatHistoriesResult(
            typing,
            periodName,
            territorializationName,
            "installed",
            "typing"
          )
        );
        groupedValuesByUsers.push(
          ...formatHistoriesResult(
            typing,
            periodName,
            territorializationName,
            "readed",
            "typing"
          )
        );
        groupedValuesByUsers.push(
          ...formatHistoriesResult(
            typing,
            periodName,
            territorializationName,
            "uninstalled",
            "typing"
          )
        );
      });
    });

    return groupedValuesByUsers;
  };

  const groupValuesByPeriodFromUsers = (data) => {
    const groupedValuesByPeriod = {};

    data.forEach(({ periods }) => {
      periods.forEach(({ periodName, app, typing }) => {
        if (!groupedValuesByPeriod[periodName]) {
          groupedValuesByPeriod[periodName] = {};
        }

        app.installed.forEach((trap) => {
          if(!groupedValuesByPeriod[periodName][trap.userId]){
            groupedValuesByPeriod[periodName][trap.userId] = {
              userId: trap.userId,
              userName: trap.userName,
              totalInstallations: 0,
              totalMaintenances: 0,
              totalUninstallations: 0,
              periodName,
            }
          }

          groupedValuesByPeriod[periodName][trap.userId].totalInstallations += 1;
        });

        app.readed.forEach((trap) => {
          if(!groupedValuesByPeriod[periodName][trap.userId]){
            groupedValuesByPeriod[periodName][trap.userId] = {
              userId: trap.userId,
              userName: trap.userName,
              totalInstallations: 0,
              totalMaintenances: 0,
              totalUninstallations: 0,
              periodName,
            }
          }

          groupedValuesByPeriod[periodName][trap.userId].totalMaintenances += 1;
        });

        app.uninstalled.forEach((trap) => {
          if(!groupedValuesByPeriod[periodName][trap.userId]){
            groupedValuesByPeriod[periodName][trap.userId] = {
              userId: trap.userId,
              userName: trap.userName,
              totalInstallations: 0,
              totalMaintenances: 0,
              totalUninstallations: 0,
              periodName,
            }
          }

          groupedValuesByPeriod[periodName][trap.userId].uninstalled += 1;
        });

        typing.installed.forEach((trap) => {
          if(!groupedValuesByPeriod[periodName][trap.userId]){
            groupedValuesByPeriod[periodName][trap.userId] = {
              userId: trap.userId,
              userName: trap.userName,
              totalInstallations: 0,
              totalMaintenances: 0,
              totalUninstallations: 0,
              periodName,
            }
          }

          groupedValuesByPeriod[periodName][trap.userId].totalInstallations += 1;
        });

        typing.readed.forEach((trap) => {
          if(!groupedValuesByPeriod[periodName][trap.userId]){
            groupedValuesByPeriod[periodName][trap.userId] = {
              userId: trap.userId,
              userName: trap.userName,
              totalInstallations: 0,
              totalMaintenances: 0,
              totalUninstallations: 0,
              periodName,
            }
          }

          groupedValuesByPeriod[periodName][trap.userId].totalMaintenances += 1;
        });

        typing.uninstalled.forEach((trap) => {
          if(!groupedValuesByPeriod[periodName][trap.userId]){
            groupedValuesByPeriod[periodName][trap.userId] = {
              userId: trap.userId,
              userName: trap.userName,
              totalInstallations: 0,
              totalMaintenances: 0,
              totalUninstallations: 0,
              periodName,
            }
          }

          groupedValuesByPeriod[periodName][trap.userId].totalUninstallations += 1;
        });
      });
    });

    const result = Object.entries(groupedValuesByPeriod).map(([periodName, users]) => {
      return {
        periodName,
        usersActivities: Object.values(users).map(user => ({
          userId: user.userId,
          userName: user.userName,
          totalInstallations: user.totalInstallations,
          totalMaintenances: user.totalMaintenances,
          totalUninstallations: user.totalUninstallations,
        })),
      };
    });

    return result;
  }

  const formatHistoriesResult = (
    data,
    periodName,
    territorializationName,
    activityType,
    variant
  ) => {
    const result = [];

    data[activityType].forEach(
      ({
        trapType,
        number,
        statusDate,
        userName,
        wasInstalled,
        wasReaded,
        wasUninstalled,
        latitude,
        longitude,
      }) => {
        result.push({
          TIPO_ARMADILHA: ARMADILHA_INFO[trapType.name].apelido,
          NUMERO_ARMADILHA: number,
          DATA_ATIVIDADE: moment(statusDate).format("DD/MM/YYYY"),
          PERIODO: periodName,
          NOME_AREA: territorializationName,
          ATIVIDADE_FEITA_PELO_APP: variant === "app" ? "Sim" : "Não",
          ATIVIDADE_FEITA_POR_DIGITACAO: variant === "typing" ? "Sim" : "Não",
          USUARIO_RESPONSAVEL_PELA_VISITA_EM_CAMPO: userName,
          PASSOU_POR_INSTALACAO: wasInstalled,
          PASSOU_POR_MANUTENCAO: wasReaded,
          PASSOU_POR_DESINSTALACAO: wasUninstalled,
          LATITUDE: latitude,
          LONGITUDE: longitude,
        });
      }
    );

    return result;
  };

  return {
    activitiesByPeriods,
    activitiesByPeriodsFromPolygons,
    activitiesByPeriodsFromUsers,
    appliedFilters,
    periodLegend,
  };
};

export const TerritorializationsReportPageGraphics = () => {
  const {
    activitiesByPeriods,
    activitiesByPeriodsFromPolygons,
    activitiesByPeriodsFromUsers,
    appliedFilters,
    periodLegend,
  } = useTerritorializationsReportPageGraphics();

  return (
    <section>
      <Row className="mb-4">
        <Col xl={6}>
          <ActivitiesByPeriodsTable
            appliedFilters={appliedFilters}
            data={activitiesByPeriods}
            periodLegend={periodLegend}
          />
        </Col>
        <Col xl={6}>
          <ActivitiesByPeriodsFromPolygonsTable
            appliedFilters={appliedFilters}
            data={activitiesByPeriodsFromPolygons}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AcitivitiesByPeriodsFromUsersTable
            appliedFilters={appliedFilters}
            data={activitiesByPeriodsFromUsers}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={12}>
          <ActivitiesByPeriodsLineGraphic
            appliedFilters={appliedFilters}
            data={activitiesByPeriods}
            periodLegend={periodLegend}
          />
        </Col>
      </Row>
    </section>
  );
};
