import React, { Component } from "react";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { timeStampFormated } from "../../../services/utils/format";
import moment from "moment";

export function withBasicListGraficMap(WrappedComponent) {
  return class extends Component {
    buildGraficMapList = async (traps, key, colorIcon, selectedSpecie = null) => {
      const trapList = new Array();

      for(let i = 0; i < traps.length; i++){
          const position = [traps[i].latitude, traps[i].longitude];
          
          var name = `${ARMADILHA_INFO[traps[i].trapTypeName].apelido} Nº ${traps[i].number ? traps[i].number : ""} \n${timeStampFormated(traps[i].dateInstalled)}`;
          var intensity = 0.5;
          var typeQtt = null;

          typeQtt = ARMADILHA_INFO[traps[i].trapTypeName].principalColetant.toLowerCase();
          name += `\nQuantidade de ${typeQtt}:` + traps[i].eggs;

          if(selectedSpecie) {
            intensity += (traps[i].eggs * 0.05);
          } else {
            intensity += (traps[i].eggs * 0.01);
          }

          var nameWithoutFormat = {
            trapType: ARMADILHA_INFO[traps[i].trapTypeName].apelido,
            number: traps[i].number ? traps[i].number : "",
            dateInstalled :timeStampFormated(traps[i].dateInstalled),
            qtt:  traps[i].eggs,
            typeQtt: typeQtt
          };
          const type = "basictrap";
          const icon = ARMADILHA_INFO[traps[i].trapTypeName].icons[colorIcon];
          const trapListObject = {
              name,
              position,
              type,
              intensity,
              icon,
              nameWithoutFormat
          }
          
          trapList.push(trapListObject);
      }

      const mapListObject = {
          name: key,
          checked: false,
          points: trapList,
      }

      return mapListObject;
    }

    buildMapListSinan = async (register, key) => {
      const mapList = new Array();
      const heatMapTitle = `Mapa de Calor de ${key}`;
  
      for (let i = 0; i < register.length; i++) {
        const name = `Data da Notificação: ${moment(
          register[i].dt_notific
        ).format("DD/MM/YYYY")}`;
        const position = [register[i].latitude, register[i].longitude];
        const icon = require("../../../assets/img/icons/visita.png");
        const intensity = 1;
  
        const mapListObject = {
          name,
          position,
          intensity,
          icon,
        };
  
        mapList.push(mapListObject);
      }
  
      const pointsGroupObject = {
        name: key,
        heatMapTitle,
        points: mapList,
      };
  
      return pointsGroupObject;
    };

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          buildGraficMapList={this.buildGraficMapList} 
          buildMapListSinan={this.buildMapListSinan}
        />
      );
    }
  };
}
