import React from "react";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import Skeleton from "../Skeleton";

const DefaultGraphicLoadingSkeleton = () => {
  return (
    <Card>
      <CardHeader>
        <Row
          className="align-items-center"
          style={{
            marginLeft: "2px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Skeleton
              variant="rectangle"
              height="1rem"
              width="200px"
              padding={"0px"}
            />
          </div>
          <div style={{ display: "flex", gap: ",5rem" }}>
            <Skeleton variant="rectangle" height="1rem" width="100px" />
            <Skeleton variant="rectangle" height="1rem" width="50px" />
            <Skeleton variant="rectangle" height="1rem" width="50px" />
          </div>
        </Row>
      </CardHeader>
      <CardBody className="pb-0 pt-0 pl-0 pr-0">
        <Skeleton variant="rectangle" height="27rem" width="100%" />
        <Row
          className="align-items-center"
          style={{
            marginTop: "1rem",
            marginRight: "1rem",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rectangle" height="1rem" width="100px" />
        </Row>
      </CardBody>
    </Card>
  );
};

export default DefaultGraphicLoadingSkeleton;
