import { useContextSelector } from "use-context-selector";
import { FieldResultsPageContext } from "../Contexts/FieldResultsPageContext";

const useLoading = () => {
  const isFieldResultsLoading = useContextSelector(
    FieldResultsPageContext,
    (context) => context.isFieldResultsLoading
  );
  const setIsFieldResultsLoading = useContextSelector(
    FieldResultsPageContext,
    (context) => context.setIsFieldResultsLoading
  );

  return {
    isFieldResultsLoading,
    setIsFieldResultsLoading,
  };
};

export default useLoading;
