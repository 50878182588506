import React, { useEffect, useState } from "react";
import { getTrapByNumber } from "../services/api/Trap";
import { getNotificationByTerritorialization } from "../services/api/Sinan";
import { checkPermissionComponent } from "../services/utils/permission";
import LoadingSpin from "react-loading-spin";
import { Button } from "reactstrap";
import ReactTooltip from "react-tooltip";

const PopUpInformationsComponent = ({
  paragrafo,
  number,
  territorializationId,
  typeSinan,
  filter,
  polygonPopup,
  nameWithoutFormat = null,
  organizationId,
  changeLocation,
  saveLocation,
  draggableTrap, 
  setModal2,
  modal2,
  setTrapToUpdateData,
  trapToUpdateData
}) => {
  const [data, setData] = useState(null);  

  useEffect(() => {
    if (filter && territorializationId) {
      var filterResponse = filter[0];
      filterResponse.territorializationId = territorializationId;

      getNotificationByTerritorialization(filterResponse).then((response) => {
        setData(response.data[0]);
      });
    }
  }, [territorializationId]);

  useEffect(() => {
    const formatTrapTime = (date) => {
      let dateTime = new Date(date);
      dateTime.setHours(dateTime.getHours() - 6);

      return dateTime.toISOString().substr(11, 5);
    };

    if (number) {
      if (!number || number == "") {
        return;
      }
      try {
        getTrapByNumber(number, organizationId).then((trap) => {
          console.log("pegando trap", trap)
          trap = trap.data;

          var popUp = {};
          if (trap.dateInstalled) {
            let formattedDateTime = formatTrapTime(trap.dateInstalled);

            popUp.dateInstalled = formatTrapDateAndTime(trap.dateInstalled);
            popUp.timeInstalled = formattedDateTime;
            popUp.lastReadDate = trap.lastReadDate
              ? `${formatTrapDateAndTime(trap.lastReadDate)} por ${
                  trap.whoLastReadName
                }`
              : "A armadilha não passou por manutenção";
          }

          if (trap.whoInstallName) {
            popUp.whoInstallName = trap.whoInstallName;
          }
          if (trap.address) {
            popUp.address = trap.address;
          }
          setData(popUp);
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [number]);

  const toggleUpdateTrapPositionModal = () => {
    setModal2(!modal2);
  };

  const openUpdateTrapPositionModal = (trapNumber) => {
    toggleUpdateTrapPositionModal();
    getTrapByNumber(trapNumber).then(response => {
      setTrapToUpdateData(response.data);
    });
  };

  const formatTrapDateAndTime = (date) => {
    const installDate = new Date(date);
    const dateFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "America/Sao_Paulo",
    };

    let dateFormated = installDate.toLocaleString("pt-BR", dateFormatOptions);

    dateFormated = dateFormated.replace(" ", "").split(",");

    dateFormated[1].includes("00:00") || dateFormated[1].includes("03:00")
      ? (dateFormated = dateFormated[0])
      : (dateFormated = `${dateFormated[0]} às ${dateFormated[1]}`);

    return dateFormated;
  };

  var quantidade = 0;
  if (typeSinan && typeSinan == "Notificações") {
    quantidade = data && data.quantity;
  } else if (typeSinan && typeSinan == "Confirmados") {
    quantidade = data && data.quantityConfirmations;
  }

  return (
    <>
      {polygonPopup ? (
        <div>{polygonPopup}</div>
      ) : territorializationId ? (
        data ? (
          <div>
            <b>{data.name}</b> <br />
            <br />
            {quantidade} {typeSinan ? typeSinan : <></>}
            <br />
            <br />
            Área:
            <ul>
              <li>{data.areaHectares.toFixed(2)} ha</li>
              <li>{data.area.toFixed(2)} km²</li>
            </ul>
            Densidade:
            <ul>
              <li>
                {quantidade / data.areaHectares} {typeSinan ? typeSinan : <></>}{" "}
                / ha
              </li>
              <li>
                {quantidade / data.area} {typeSinan ? typeSinan : <></>} / km²
              </li>
            </ul>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingSpin primaryColor="#fff" size={17} /> Calculando
          </div>
        )
      ) : data ? (
        <>
          <strong>{paragrafo ? paragrafo[0] : null}</strong>
          <br />
          <strong>Endereço:</strong> {data.address} <br />
          <strong>Data última instalação:</strong> {data.dateInstalled} por{" "}
          {data.whoInstallName}
          <br />
          <strong>Data última manutenção:</strong> {data.lastReadDate}
          {checkPermissionComponent(
            "bb5b6faa-ba61-4e67-87d9-bb30dfe5c8ab",
            "update"
          ) ? (
            draggableTrap ? (
              <Button
                color="primary"
                style={{
                  marginTop: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  justifyContent: "center",
                }}
                onClick={saveLocation}
              >
                Salvar localização
              </Button>
            ) : (
              <div style={{display: "flex"}}>
                <Button
                  data-tip
                  data-for="changeLocation"
                  color="primary"
                  style={{
                    margin: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={changeLocation}
                >
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </Button>
                <ReactTooltip effect="solid" type="info" id="changeLocation">
                  Mudar localização
                </ReactTooltip>
                <Button
                  data-tip
                  data-for="editTrap"
                  className="btn btn-primary"
                  style={{
                    margin: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  id="tooltip204269497"
                  onClick={() => openUpdateTrapPositionModal(number)}
                  type="button"
                >
                  <i className="fa fa-pen" aria-hidden="true"></i>
                </Button>
                <ReactTooltip effect="solid" type="info" id="editTrap">
                  Editar armadilha
                </ReactTooltip>
              </div>
            )
          ) : null}
        </>
      ) : (
        <>
          {!nameWithoutFormat && paragrafo
            ? paragrafo.map((line, index) => (
                <>
                  <span>{line}</span> <br />
                </>
              ))
            : null}

          {nameWithoutFormat && (
            <>
              <span style={{ fontWeight: "bolder" }}>
                {nameWithoutFormat.trapType} Nº {nameWithoutFormat.number}
              </span>
              <br />
              <span style={{ fontWeight: "bolder" }}>Data visita:</span>{" "}
              {nameWithoutFormat.dateInstalled}
              {nameWithoutFormat.qtt && nameWithoutFormat.typeQtt ? (
                <>
                  <br />
                  <span style={{ fontWeight: "bolder" }}>
                    Quantidade de {nameWithoutFormat.typeQtt}:
                  </span>
                  {nameWithoutFormat.qtt}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PopUpInformationsComponent;