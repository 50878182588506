import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const TypeLayersFilter = ({
  value,
  handleTypeLayerChange,
  typeLayersList,
  ...props
}) => {
  return (
    <div>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isClearable={true}
        options={typeLayersList}
        value={value}
        isMulti={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onChange={(e) => handleTypeLayerChange(e)}
        {...props}
      />
    </div>
  );
};

export default TypeLayersFilter;
