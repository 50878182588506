import React from "react";

import { Button } from "reactstrap";
import { Bar, Rectangle } from "recharts";
import VerticalChartGraphic from "../../../../../components/Graphics/Composed/VerticalChartGraphic";
import { getColorByIndex, selectComponentStyles } from "../../../../../services/utils/globalFunctions";
import ReactTooltip from "react-tooltip";
import { Select } from "../../../../../components/Wrappers/SelectAll";

export const PositiveBarPercentageEstimatesGraphic = ({
  data,
  estimatesList,
  setGraphicType,
  headerText,
  selectedTerritorialization,
  territorializationsOptions,
  handleTerritorializationChange,
}) => {
  return (
    <VerticalChartGraphic
      data={data}
      considerLimits={false}
      chartElements={estimatesList.map((category, index) => (
        <Bar
          key={category}
          dataKey={category}
          fill={getColorByIndex(index)}
          activeBar={<Rectangle fill="pink" stroke="blue" />}
          stackId="a"
          barSize={20}
        />
      ))}
      headerContent={
        <>
          <Button
            onClick={() => setGraphicType("totalization")}
            color="primary"
            data-tip
            data-for="exchangeGraphicType3"
            style={{ width: "55px", marginRight: "0px" }}
          >
            <i>
              <img
                src={require("../../../../../assets/img/icons/interface/exchange-alt.png")}
                style={{ width: "13px", height: "13px" }}
                alt="icone"
              />
            </i>
          </Button>
          <ReactTooltip
            id="exchangeGraphicType3"
            type="info"
            place="bottom"
            effect="solid"
          >
            Trocar visualização
          </ReactTooltip>
          <div style={{ width: "130px" }}>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              value={selectedTerritorialization}
              options={territorializationsOptions}
              onChange={(e) => handleTerritorializationChange(e)}
            />
          </div>
        </>
      }
      headerText={headerText}
      hideDownloadButton={true}
      xAxisTickFormatter="%"
      xAxisDomain={[0, 100]}
      yAxisDataKey="week"
    />
  );
};
