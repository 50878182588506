import { useContextSelector } from "use-context-selector";
import { SinanPageContext } from "../Contexts/SinanContext";

export const useSorotypesByAreas = () => {
  const sorotypesByAreas = useContextSelector(
    SinanPageContext,
    (context) => context.sorotypesByAreas
  );
  const setSorotypesByAreas = useContextSelector(
    SinanPageContext,
    (context) => context.setSorotypesByAreas
  );

  return {
    sorotypesByAreas,
    setSorotypesByAreas,
  };
};
