import React from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import Skeleton from "../Skeleton";
import InputLoadingSkeleton from "./InputLoadingSkeleton";

const TableLoadingSkeleton = ({
  length = 10,
  tableColumns = 5,
  tableColumnWidth = "100px",
  variant,
}) => {
  if (variant === "simple")
    return (
      <Row className="mt-12 mb-4">
        <Col className="mb-12 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0 mb-0" style={{ paddingBottom: "0px" }}>
              <Row
                className="align-items-center"
                style={{
                  marginLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <Skeleton variant="rectangle" height="1rem" width="200px" />
                </div>
                <div style={{ display: "flex", gap: ",5rem" }}>
                  <Skeleton variant="rectangle" height="1rem" width="100px" />
                  <Skeleton variant="rectangle" height="1rem" width="50px" />
                  <Skeleton variant="rectangle" height="1rem" width="50px" />
                </div>
              </Row>
            </CardHeader>
            {Array.from({ length: length }).map((_, index) => (
              <Row
                key={index}
                className="align-items-center"
                style={{
                  margin: "0px 10px",
                  padding: "10px",
                }}
              >
                {Array.from({ length: tableColumns }).map((_, index) => (
                  <Col key={index}>
                    <Skeleton
                      variant="rectangle"
                      height="1rem"
                      width={tableColumnWidth}
                    />
                  </Col>
                ))}
              </Row>
            ))}
            <Row
              className="align-items-center"
              style={{
                marginTop: "1rem",
                marginRight: "1rem",
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rectangle" height="1rem" width="100px" />
            </Row>
          </Card>
        </Col>
      </Row>
    );

  return (
    <Row className="mt-12">
      <Col className="mb-12 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
            <Row
              className="align-items-center"
              style={{ marginLeft: "2px", marginBottom: "1rem" }}
            >
              <Skeleton variant="rectangle" height="1rem" width="200px" />
            </Row>
          </CardHeader>
          <Row
            className="align-items-center"
            style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
          >
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="120px" inputWidth="200px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="100px" inputWidth="200px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="170px" inputWidth="200px" />
            </Col>
          </Row>
          <Row
            className="align-items-center"
            style={{ marginBottom: "2rem", padding: "0px 1.5rem" }}
          >
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="90px" inputWidth="200px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="150px" inputWidth="200px" />
            </Col>
            <Col xl="4">
              <InputLoadingSkeleton labelWidth="120px" inputWidth="200px" />
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginBottom: "2rem" }}>
            <Col
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Skeleton variant="rectangle" height="1rem" width="300px" />
            </Col>
          </Row>
          {Array.from({ length: length }).map((_, index) => (
            <Row
              key={index}
              className="align-items-center"
              style={{
                margin: "0px 10px",
                padding: "10px",
                backgroundColor: index % 2 === 0 ? "white" : "#f3f3f3",
              }}
            >
              <Col>
                <Skeleton
                  variant="rectangle"
                  height="1rem"
                  width={index % 2 === 0 ? "100px" : "200px"}
                />
              </Col>
              <Col>
                <Skeleton
                  variant="rectangle"
                  height="1rem"
                  width={index % 2 === 0 ? "100px" : "200px"}
                />
              </Col>
              <Col>
                <Skeleton
                  variant="rectangle"
                  height="1rem"
                  width={index % 2 === 0 ? "100px" : "200px"}
                />
              </Col>
              <Col>
                <Skeleton
                  variant="rectangle"
                  height="1rem"
                  width={index % 2 === 0 ? "100px" : "200px"}
                />
              </Col>
              <Col>
                <Skeleton
                  variant="rectangle"
                  height="1rem"
                  width={index % 2 === 0 ? "100px" : "200px"}
                />
              </Col>
              <Col>
                <Skeleton
                  variant="rectangle"
                  height="1rem"
                  width={index % 2 === 0 ? "100px" : "200px"}
                />
              </Col>
            </Row>
          ))}
        </Card>
      </Col>
    </Row>
  );
};

export default TableLoadingSkeleton;
