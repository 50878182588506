import { useContextSelector } from "use-context-selector";
import { TerritorializationsReportContext } from "../Contexts/TerritorializationsReportContext";

export const useDownloadableData = () => {
  const downloadableData = useContextSelector(
    TerritorializationsReportContext,
    (context) => context.downloadableData
  );
  const setDownloadableData = useContextSelector(
    TerritorializationsReportContext,
    (context) => context.setDownloadableData
  );

  return {
    downloadableData,
    setDownloadableData,
  };
}