import request from '../utils/request';
//import request from '../utils/requestTests/requestTestInventory';

import { getUserData } from '../utils/auth';

export function getInventoryRecord() {

    var OrganizationId = getUserData('organizationId');
  
    return request({
      url: '/InventoryRecords/Organization/' + OrganizationId,
      method: 'get',
    });
  }


  
export function postMovementRecord(Movement) {
  return request({
    url: '/InventoryRecords',
    method: 'post',
    data:Movement,
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}

export function postPendingInventoryRecord(Movement) {
  return request({
    url: '/InventoryRecords/PendingInventory',
    method: 'post',
    data:Movement,
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}

export function postReverseInventory(Movement) {
  return request({
    url: '/InventoryRecords/ReverseInventory',
    method: 'post',
    data:Movement,
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
}
