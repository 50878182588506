import React from "react";
import { Button } from "reactstrap";
import { SinanPageFiltersPopover } from "./Filters/SinanPageFilters";
import { useAppliedFilters } from "./Hooks/useAppliedFilters";
import { getUserData } from "../../../services/utils/auth";
import moment from "moment";
import {
  getUploadHistory,
  rawNotificiationsByFilters,
} from "../../../services/api/Sinan";
import LoadingSpin from "react-loading-spin";
import xlsx from "json-as-xlsx";
import ReactTooltip from "react-tooltip";

const useSinanPageHeader = () => {
  const { appliedFilters } = useAppliedFilters();
  const [
    isLoadingDownloadableNotifications,
    setIsLoadingDownloadableNotifications,
  ] = React.useState(false);
  const [actualYearUploadHistory, setActualYearUploadHistory] =
    React.useState(null);

  React.useEffect(() => {
    getActualYearUploadHistory();
  }, []);

  const getActualYearUploadHistory = async () => {
    try {
      const { data, status } = await getUploadHistory(
        "DENGUE",
        moment().year()
      );

      if (status !== 200) return;

      const formatedUploadDate = moment(
        data[0].uploadDate,
        "MM/DD/YYYY HH:mm:ss"
      );
      const daysDifferenceFromUploadToToday = moment().diff(
        formatedUploadDate,
        "days"
      );
      const uploadStatus = data[0].status;

      setActualYearUploadHistory({
        date: formatedUploadDate
          .subtract(3, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
        status: uploadStatus,
        daysDifferenceFromUploadToToday,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const generatePeriodsToSend = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") {
      const result = [
        {
          beginDate: moment(filters.beginDate)
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment(filters.endDate)
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          periodName: `${moment(filters.beginDate).format(
            "DD/MM/YYYY"
          )} A ${moment(filters.endDate).format("DD/MM/YYYY")}`,
        },
      ];

      return result;
    }

    if (filters.datePeriodType === "week") {
      const result = filters.epidemiologicalWeeks.map(
        ({ beginDate, endDate, label }) => ({
          beginDate: moment(beginDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment(endDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          periodName: label,
        })
      );

      return result;
    }

    if (filters.datePeriodType === "month") {
      const result = filters.months.map(({ label, value }) => ({
        beginDate: moment({ year: filters.year.value, month: value - 1 })
          .startOf("month")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString(),
        endDate: moment({ year: filters.year.value, month: value - 1 })
          .endOf("month")
          .subtract(1, "day")
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString(),
        periodName: label,
      }));

      return result;
    }
  };

  const handleDownloadCases = async () => {
    setIsLoadingDownloadableNotifications(true);

    const filtersToSend = {
      disease: appliedFilters.disease.value,
      includeCasesByPersons: true,
      notifyingUnitsIds: appliedFilters.notifyingUnits.map(
        ({ value }) => value
      ),
      organizationId: getUserData("organizationId"),
      periods: generatePeriodsToSend(appliedFilters),
      referenceDate: appliedFilters.casesReferenceDate.value,
      territorializationsIds: appliedFilters.territorializations.map(
        ({ value }) => value
      ),
    };

    try {
      const { data, status } = await rawNotificiationsByFilters(filtersToSend);

      if (status !== 200) {
        return;
      }

      const xlsxFileSettings = {
        fileName: `casos_SINAN_VITEC_${moment().format("DD/MM/YYYY HH:mm")}`,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
        RTL: false,
      };

      xlsx(
        [
          {
            sheet: "Casos",
            columns: [
              { label: "CS_SEXO", value: "cS_SEXO" },
              { label: "CLASSI_FIN", value: "classI_FIN" },
              { label: "DT_DIGITA", value: "dT_DIGITA" },
              { label: "DT_NASC", value: "dT_NASC" },
              { label: "DT_NOTIFIC", value: "dT_NOTIFIC" },
              { label: "DT_SIN_PRI", value: "dT_SIN_PRI" },
              { label: "ID_UNIDADE", value: "iD_UNIDADE" },
              { label: "LATITUDE", value: "latitude" },
              { label: "LONGITUDE", value: "longitude" },
              { label: "NM_BAIRRO", value: "nM_BAIRRO" },
              { label: "NM_LOGRADO", value: "nM_LOGRADO" },
              { label: "NU_CEP", value: "nU_CEP" },
              { label: "NU_NOTIFIC", value: "nU_NOTIFIC" },
              { label: "NU_NUMERO", value: "nU_NUMERO" },
              { label: "RES_CHIKS1", value: "reS_CHIKS1" },
              { label: "RES_CHIKS2", value: "reS_CHIKS2" },
              { label: "RESUL_NS1", value: "resuL_NS1" },
              { label: "RESUL_PCR", value: "resuL_PCR_" },
              { label: "RESUL_PRNT", value: "resuL_PRNT" },
              { label: "RESUL_SORO", value: "resuL_SORO" },
              { label: "RESUL_VI_N", value: "resuL_VI_N" },
              { label: "SOROTIPO", value: "sorotipo" },
            ],
            content: data,
          },
        ],
        xlsxFileSettings
      );
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
      }
    } finally {
      setIsLoadingDownloadableNotifications(false);
    }
  };

  return {
    actualYearUploadHistory,
    handleDownloadCases,
    isLoadingDownloadableNotifications,
  };
};

export const SinanPageHeader = () => {
  const {
    actualYearUploadHistory,
    handleDownloadCases,
    isLoadingDownloadableNotifications,
  } = useSinanPageHeader();

  const uploadStyles =
    actualYearUploadHistory &&
    actualYearUploadHistory.daysDifferenceFromUploadToToday < 1
      ? { color: "#35fd35", fontWeight: "bold" }
      : actualYearUploadHistory &&
        actualYearUploadHistory.daysDifferenceFromUploadToToday < 3
      ? { color: "yellow", fontWeight: "bold" }
      : { color: "red", fontWeight: "bold" };

  return (
    <section
      style={{
        display: "flex",
        gap: ".5rem",
        justifyContent: "space-between",
        paddingTop: "15px",
      }}
    >
      <div style={{ color: "white" }}>
        <span data-tip data-for={"upload-attention"}>
          Data do último envio:{" "}
          <span style={uploadStyles}>
            {actualYearUploadHistory && actualYearUploadHistory.date}
          </span>
        </span>
        <br />
        <span>
          Status:{" "}
          <span style={uploadStyles}>
            {actualYearUploadHistory && actualYearUploadHistory.status}
          </span>
        </span>
      </div>
      <ReactTooltip effect="solid" type="info" id={`upload-attention`}>
        {actualYearUploadHistory &&
          actualYearUploadHistory.daysDifferenceFromUploadToToday}{" "}
        dia(s) sem atualização
      </ReactTooltip>
      <div style={{ display: "flex", gap: ".3rem" }}>
        <SinanPageFiltersPopover />
        <div>
          {isLoadingDownloadableNotifications ? (
            <Button id="" type="button" disabled>
              <LoadingSpin size={15} primaryColor="#fff" />
            </Button>
          ) : (
            <Button id="" type="button" onClick={() => handleDownloadCases()}>
              <i>
                <img
                  src={require("../../../assets/img/icons/interface/upload.png")}
                  style={{
                    width: "14px",
                    height: "14px",
                  }}
                  alt="icone"
                />
              </i>{" "}
              Baixar casos brutos
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};
