import React from "react";

import { createContext } from "use-context-selector";

const OrganizationContext = createContext(null);

const useOrganizationContext = () => {
  const context = React.useContext(OrganizationContext);

  if (!context)
    throw new Error("useContext de Organization deve estar dentro do Provider");

  return context;
};

const OrganizationContextProvider = ({ children }) => {
  const [configurations, setConfigurations] = React.useState(null); 
  const [demandsList, setDemandsList] = React.useState([]);
  const [employeesList, setEmployeesList] = React.useState([]);
  const [teamsList, setTeamsList] = React.useState([]);
  const [territorializationsList, setTerritorializationsList] = React.useState(
    []
  );
  const [trapsList, setTrapsList] = React.useState([]);
  const [trapTypesList, setTrapTypesList] = React.useState([]);
  const [typeLayersList, setTypeLayersList] = React.useState([]);
  const [projectsList, setProjectsList] = React.useState([]);
  const [polygonsColorsPreferences, setPolygonsColorsPreferences] =
    React.useState([]);

  React.useEffect(() => {
    const polygonsColorsPreferences = localStorage.getItem(
      "polygonsColorsPreferences"
    )
      ? JSON.parse(localStorage.getItem("polygonsColorsPreferences"))
      : null;

    if (!polygonsColorsPreferences) {
      const emptyPreferences = [];

      setPolygonsColorsPreferences(emptyPreferences);
      localStorage.setItem(
        "polygonsColorsPreferences",
        JSON.stringify(emptyPreferences)
      );

      return;
    }

    setPolygonsColorsPreferences(polygonsColorsPreferences);
  }, []);

  const handleAddPolygonPreferenceColor = React.useCallback(
    (preference) => {
      const NOT_FOUND = -1;

      let previousPreferenceIndex = NOT_FOUND;

      preference.entity === "polygon"
        ? (previousPreferenceIndex = polygonsColorsPreferences.findIndex(
            ({ polygonId }) => polygonId === preference.polygonId
          ))
        : (previousPreferenceIndex = polygonsColorsPreferences.findIndex(
            ({ typeLayerId }) => typeLayerId === preference.typeLayerId
          ));

      if (previousPreferenceIndex === NOT_FOUND) {
        setPolygonsColorsPreferences((previousValues) => {
          const newPolygonsPreferences = [...previousValues, preference];

          return newPolygonsPreferences;
        });

        return;
      }

      setPolygonsColorsPreferences((previousValues) => {
        const newPolygonsPreferences = [...previousValues];

        if (preference.borderColor !== "") {
          newPolygonsPreferences[previousPreferenceIndex].borderColor =
            preference.borderColor;

          return newPolygonsPreferences;
        }

        if (preference.interiorColor !== "") {
          newPolygonsPreferences[previousPreferenceIndex].interiorColor =
            preference.interiorColor;

          return newPolygonsPreferences;
        }

        return newPolygonsPreferences;
      });
    },
    [polygonsColorsPreferences]
  );

  const handleSavePolygonPreferenceColor = React.useCallback(() => {
    localStorage.setItem(
      "polygonsColorsPreferences",
      JSON.stringify(polygonsColorsPreferences)
    );
  }, [polygonsColorsPreferences]);

  const handleDeletePolygonPreferenceColor = React.useCallback(
    (preference) => {
      const NOT_FOUND = -1;

      let previousPreferenceIndex = NOT_FOUND;

      preference.entity === "polygon"
        ? (previousPreferenceIndex = polygonsColorsPreferences.findIndex(
            ({ polygonId }) => polygonId === preference.polygonId
          ))
        : (previousPreferenceIndex = polygonsColorsPreferences.findIndex(
            ({ typeLayerId }) => typeLayerId === preference.typeLayerId
          ));

      if (previousPreferenceIndex === NOT_FOUND) return;

      const newPreferences = [...polygonsColorsPreferences];

      if (preference.deleteBorderColor) {
        newPreferences[previousPreferenceIndex].borderColor = "";
      }

      if (preference.deleteInteriorColor) {
        newPreferences[previousPreferenceIndex].interiorColor = "";
      }

      if (
        newPreferences[previousPreferenceIndex].interiorColor === "" &&
        newPreferences[previousPreferenceIndex].borderColor === ""
      ) {
        newPreferences.splice(previousPreferenceIndex, 1);
      }

      setPolygonsColorsPreferences(newPreferences);
    },
    [polygonsColorsPreferences]
  );

  return (
    <OrganizationContext.Provider
      value={{
        configurations,
        demandsList,
        employeesList,
        teamsList,
        territorializationsList,
        trapsList,
        trapTypesList,
        typeLayersList,
        projectsList,
        polygonsColorsPreferences,
        setConfigurations,
        handleAddPolygonPreferenceColor,
        handleDeletePolygonPreferenceColor,
        handleSavePolygonPreferenceColor,
        setDemandsList,
        setEmployeesList,
        setTeamsList,
        setTerritorializationsList,
        setTrapsList,
        setTrapTypesList,
        setTypeLayersList,
        setProjectsList,
        setPolygonsColorsPreferences,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export {
  OrganizationContextProvider,
  useOrganizationContext,
  OrganizationContext,
};