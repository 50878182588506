import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { fetchAnimalSpecies, fetchGenreVirusType } from "../../../../services/api/AnimalGroups";

const ClassificationResultNew = ({
    firstSample,
    sampleClassification,
    identificationsExams,
    diagnosticsExams,
    data,
    selectedAnimalGenre,
    selectedAnimalGroup,
    animalSpecies,
    selectedAnimalSpecie,
    Laboratories,
    techniques,
    sampleGroupIndex, 
    sampleResultIndex,
    handleChangeArrayData,
}) => {
    const handleClassificationData = (key, value) => {
        handleChangeArrayData(key, value, sampleGroupIndex, sampleResultIndex);
    }

    const handleFowardedSample = (classification,index, array = null, arrayIndex = null) => {
        let sampleFowardClassification = [...sampleClassification];

        if(array !== null){
          sampleFowardClassification[index][array][arrayIndex][classification.target.name] = classification.target.value;
    
          if(classification.target.name == 'examId'){
            // não estamos filtrando no momento
            // let techniquesArray = techniques.filter((technique) => technique.examId == classification.target.value);
            let techniquesArray = techniques;

            if(techniquesArray){
              sampleFowardClassification[index][array][arrayIndex].techniques = techniquesArray;
              sampleFowardClassification[index][array][arrayIndex].techniqueId = "00000000-0000-0000-0000-000000000000";
            }
          }
    
        }else{
          sampleFowardClassification[index][classification.target.name] = classification.target.value;
        }
    
        handleClassificationData("sampleClassification", sampleFowardClassification)
    }

    const addLabForward = () =>{
        let classifications = [...sampleClassification];

        classifications.push({
            sampleClassificationId:"00000000-0000-0000-0000-000000000000",
            laboratoryId:"00000000-0000-0000-0000-000000000000",
            externSampleNumber:"",
            description:"",
            exams:[{
            identificationSampleHistoryId: "00000000-0000-0000-0000-000000000000",
            examId:"00000000-0000-0000-0000-000000000000",
            techniques:[],
            techniqueId:"00000000-0000-0000-0000-000000000000",
            }],
        })

        handleClassificationData("sampleClassification",classifications)
    }

    const removeLabForward = () =>{
        var sampleClassificationAux = [...sampleClassification];
        sampleClassificationAux.pop();

        handleClassificationData("sampleClassification",sampleClassificationAux)   
    }

    const selectedGenre = async (genre) =>{
        try{
            const specieResponse = await fetchAnimalSpecies(genre);
            const species = specieResponse.data.map(({name, id}) => ({label: name, value: id}));
        
            const virusResponse = await fetchGenreVirusType(genre);
            const viruses = virusResponse.data.map(({name, id}) => ({label: name, value: id}));

            handleClassificationData("selectedAnimalGenre", genre);
            handleClassificationData("selectedAnimalSpecie", '00000000-0000-0000-0000-000000000000');
            handleClassificationData("selectedVirusType", '00000000-0000-0000-0000-000000000000');
            handleClassificationData("animalSpecies", species);
            handleClassificationData("virusTypes", viruses);
        }catch(error){
            console.error("Erro ao pegar o genero do tipo de amostra.")
        }
    }

    const addClassificationExam = (index) => {
        let sampleClassificationAux = [...sampleClassification];

        sampleClassificationAux[index].exams.push({
          identificationSampleHistoryId: "00000000-0000-0000-0000-000000000000",
          examId:"00000000-0000-0000-0000-000000000000",
          techniques:[],
          techniqueId:"00000000-0000-0000-0000-000000000000",
        })

        handleClassificationData("sampleClassification", sampleClassificationAux)
    }

    const removeClassificationExam = (index) => {
        let sampleClassificationAux = [...sampleClassification] ;
        sampleClassificationAux[index].exams.pop();

        handleClassificationData("sampleClassification",sampleClassificationAux)
    }

    return (
        <Card style={{"marginTop": "2%", "marginBottom": "2%"}}>
            <CardHeader>
                <span className="h4"><i className='fa fa-vials'></i> Classificação</span>
            </CardHeader>
            <CardBody>
                <div className="row">
                    <div className={`col-6 responsive-form-field-result-full`}>
                    <span className="h5">Gênero</span>
                    <Select 
                        options={firstSample.animalGenres} 
                        onChange={(e) => 
                        {
                            if(e !== null)
                            { 
                                selectedGenre(e.value)
                            } else{
                                selectedGenre("00000000-0000-0000-0000-000000000000")
                            }
                        }
                        }
                        isClearable={true}
                        value={selectedAnimalGenre && selectedAnimalGenre != '00000000-0000-0000-0000-000000000000' ? firstSample.animalGenres.filter(obj => obj.value === selectedAnimalGenre)[0] : null }
                        isDisabled={!firstSample.selectedAnimalGroup || firstSample.selectedAnimalGroup == "00000000-0000-0000-0000-000000000000"}
                    />
                    </div>

                    <div className={`col-6 responsive-form-field-result-full`}>
                    <span className="h5">Espécie</span>
                    <Select 
                        key={selectedAnimalSpecie}
                        options={animalSpecies} 
                        onChange={(e) => {
                            if(e !== null) {
                                handleClassificationData("selectedAnimalSpecie",e.value)
                            }else{
                                handleClassificationData("selectedAnimalSpecie","00000000-0000-0000-0000-000000000000")
                            }
                        }}
                        isClearable={true}
                        value={selectedAnimalSpecie && selectedAnimalSpecie != '00000000-0000-0000-0000-000000000000' ? animalSpecies.filter(obj => obj.value === selectedAnimalSpecie)[0] : null }
                        isDisabled={!selectedAnimalGenre || selectedAnimalGenre == '00000000-0000-0000-0000-000000000000'}
                    />
                    </div>                          
                </div>
                {sampleClassification.map((classification,indexClassification)=>{
                    return(
                    <>
                        <div className="row">
                        <div className="col-6 responsive-form-field-result-full">
                            <span className="h5">Nome do laboratório credenciado</span>
                            <Select 
                                key ={classification.laboratoryId}
                                value={Laboratories ? Laboratories.filter(l => l.value == classification.laboratoryId) : null}
                                options={Laboratories ? Laboratories : []} 
                                name={"laboratoryId"}
                                onChange={(e) => handleFowardedSample(e, indexClassification, null, null)}
                            />
                        </div>

                        <div className="col-6  responsive-form-field-result-full">
                            <span className="h5">Número da amostra no laboratório externo</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Número da amostra" 
                                name="externSampleNumber" 
                                value={classification.externSampleNumber} 
                                onChange={(e) => {
                                    handleFowardedSample(e, indexClassification, null, null)
                                }}
                            />
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-12 responsive-form-field-result-full">
                            <span className="h5">Descrição de encaminhamento</span>
                            <textarea className="form-control" placeholder="Motivo de encaminhamento" name="description" value={classification.description} onChange={(e) => {handleFowardedSample(e, indexClassification, null, null)}}/>
                        </div>
                        </div>
                        {classification && classification.exams.map((exam, examIndex) => {
                            return (
                                <>
                                <div className="row">
                                <div className="col-6 responsive-form-field-result-full">
                                    <span className="h5">Exame realizado</span>
                                    <select
                                    className="form-control"
                                    name="examId"
                                    id=""
                                    value={exam.examId}
                                    onChange={(e) => handleFowardedSample(e, indexClassification, "exams", examIndex)}
                                    >
                                    <option value="">Selecione</option>
                                    {identificationsExams && identificationsExams.map((identExam, id) => {
                                        const isSelected = identExam.id == exam.examId;

                                        return (
                                        <option key={id} value={identExam.id} selected={isSelected}>
                                            {identExam.name}
                                        </option>
                                        );
                                    })}
                                    </select>

                                </div>

                                <div className="col-6 responsive-form-field-result-full">
                                    <span className="h5">Técnica Utilizada</span>
                                    <select className="form-control" name="techniqueId" id="" defaultValue={exam.techniqueId} onChange={(e) =>handleFowardedSample(e, indexClassification, "exams", examIndex)}>
                                        <option value="">Selecione</option>
                                        {techniques && techniques.map((technique, id) =>{
                                                const isSelected = technique.id == exam.techniqueId;

                                                return (
                                                    <option key={id} value={technique.id} selected={isSelected}>{technique.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                </div>
                                
                                </>
                            )
                        })}
                        
                        <div className="row mt-4 mb-4">
                        <div className={classification.exams.length > 1 ? "col-6  responsive-form-field-result-full" : "col-12  responsive-form-field-result-full"}>
                            <button className="btn btn-info responsive-button-result" onClick={() => addClassificationExam(indexClassification)}>+ Adicionar Exame</button>
                        </div>
                        {classification.exams.length > 1 ?
                            <div className="col-6 responsive-form-field-result-full">
                            <button className="btn btn-warning responsive-button-result" onClick={() => removeClassificationExam(indexClassification)}><i className='fa fa-trash'></i> Remover Exame</button>
                            </div>    
                        :null}
                        </div>
                    </>
                    )
                })}
                
                <div className="row">
                    <div className={sampleClassification.length > 1 ? "col-6  responsive-form-field-result-full" : "col-12  responsive-form-field-result-full"}>
                        <button className="btn btn-info responsive-button-result" onClick={() => addLabForward()}>
                        + Adicionar Encaminhamento
                        </button>
                    </div>                                          
                    {sampleClassification.length > 1 ?
                    <div className="col-6 responsive-form-field-full">
                        <button className="btn btn-warning responsive-button-result" onClick={() => removeLabForward()}><i className='fa fa-trash'></i> Remover Encaminhamento</button>
                    </div>    
                    :null}
                </div>
            </CardBody>  
        </Card>
    )
}

export default ClassificationResultNew;
