import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { getUserData } from "../../services/utils/auth";
import useDemandsList from "../../hooks/useDemandsList";
import useEmployeesList from "../../hooks/useEmployeesList";
import useTrapsList from "../../hooks/useTrapsList";
import useTrapTypesList from "../../hooks/useTrapTypesList";
import useProjectsList from "../../hooks/useProjectsList";
import useTeamsList from "../../hooks/useTeamsList";
import useTerritorializationsList from "../../hooks/useTerritorializationsList";
import useTypeLayersList from "../../hooks/useTypeLayersList";

const AdminNavbar = (props) => {
  const [username, setUsername] = useState("");
  const { setDemandsList } = useDemandsList();
  const { setEmployeesList } = useEmployeesList();
  const { setProjectsList } = useProjectsList();
  const { setTeamsList } = useTeamsList();
  const { setTerritorializationsList } = useTerritorializationsList();
  const { setTrapsList } = useTrapsList();
  const { setTrapTypesList } = useTrapTypesList();
  const { setTypeLayersList } = useTypeLayersList();

  const handleLogout = () => {
    setDemandsList([]);
    setEmployeesList([]);
    setProjectsList([]);
    setTeamsList([]);
    setTerritorializationsList([]);
    setTrapsList([]);
    setTrapTypesList([]);
    setTypeLayersList([]);
  };

  useEffect(() => {
    setUsername(getUserData("name"));
  }, []);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to={props.redirectRoute}
          >
            {props.brandText}
          </Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {username}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem vindo(a)!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/pgs" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Minha conta</span>
                </DropdownItem>
                <DropdownItem to="/admin/configurations" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Configurações</span>
                </DropdownItem>
                <DropdownItem to="/admin/contactUs" tag={Link}>
                  <i className="ni ni-chat-round" />
                  <span>Fale conosco</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  to="/admin/logout"
                  tag={Link}
                  onClick={() => handleLogout()}
                >
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
