import axios from 'axios';
import { getTrapByNumber } from './Trap';
import { getVisitById } from './Visits';

const request = axios.create({
  baseURL: 'https://contaovos.com/pt-br/api',
  timeout: 1000000, 
});

export async function postContaOvos(sample, trapNumber) {
  const quantity = sample.sampleIdentifications.reduce((acc, obj) => {
    if (obj.stage === "Ovo") {
      acc += obj.quantity;
    }

    return acc; 
  }, 0); 

  const date = sample.dateCollected.slice(0, 10);

  const response = await getTrapByNumber(trapNumber);

  const allInformationsTrap = response.data;

  var newRegister = new FormData();

  newRegister.append('ovitrap_group_id', trapNumber);
  newRegister.append('ovitrap_responsable', allInformationsTrap.whoInstallName);
  newRegister.append('ovitrap_address_street', allInformationsTrap.publicArea);
  newRegister.append('ovitrap_address_number', allInformationsTrap.addressNumber);

  if(allInformationsTrap.addressComplement) {
    newRegister.append('ovitrap_address_complement', allInformationsTrap.addressComplement);
  }

  if(allInformationsTrap.addressBlock) {
    newRegister.append('ovitrap_block_id', allInformationsTrap.addressBlock);
  }

  newRegister.append('ovitrap_lat', allInformationsTrap.latitude);
  newRegister.append('ovitrap_lng', allInformationsTrap.longitude);
  newRegister.append('date', date);
  newRegister.append('counting_observation_id', 1);
  newRegister.append('counting_eggs', quantity);

  return request({
    url: '/postcounting',
    method: 'post',
    data: newRegister,
    params: {
      'key': 'zrn32n5crwuc571fbs5xgdvetc3cti'
    },
    headers: new Headers({
      "Content-Type": "multipart/form-data",
    }),
  });
}
