import React from "react";

import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Button,
  CardFooter,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Card, CardContent, CardHeader } from "../../Cards/Card";
import { Select } from "../../Wrappers/SelectAll";
import { selectComponentStyles } from "../../../services/utils/globalFunctions";

const MultipleIndexisGraphicModal = ({
  appliedFilters,
  customLegend,
  customTooltip,
  customYAxisDomain,
  data,
  footerText,
  headerContent,
  headerText,
  legend,
  lines,
  xAxisDataKey,
  brushBar,
  hideDownloadButton = false,
  isOpen,
  toggle,
}) => {
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [indexisTypesOptions, setIndexisTypesOptions] = React.useState([]);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (!appliedFilters.trapType || appliedFilters.trapType.trapTypeName === "")
      return;

    const indexisTypesOptions =
      appliedFilters.trapType.trapTypeName ===
      "armadilhaDisseminadoraInseticida"
        ? [
            { label: "IPL", value: "IPL" },
            { label: "IPE", value: "IPE" },
          ]
        : appliedFilters.trapType.trapTypeName === "armadilhaMosquitoAdulto" ||
          appliedFilters.trapType.trapTypeName === "armadilhaMosquitoAdulto2"
        ? [
            { label: "IPL", value: "IPL" },
            { label: "IPA", value: "IPA" },
          ]
        : appliedFilters.trapType.trapTypeName === "armadilhaOvos"
        ? [
            { label: "IPO", value: "IPO" },
            { label: "IPL", value: "IPL" },
            { label: "IPE", value: "IPE" },
          ]
        : [];

    setIndexisTypesOptions(indexisTypesOptions);
    setSelectedIndex(indexisTypesOptions[0]);
  }, [appliedFilters]);

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
      borderTop: "0px",
      paddingTop: "0px",
    },
  };

  const territorializations = appliedFilters.territorializations.map(
    ({ label }, index) => {
      return {
        label: label,
        value: index,
      };
    }
  );

  if (
    !isOpen ||
    !data ||
    data.length === 0 ||
    !territorializations ||
    territorializations.length === 0
  )
    return null;

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => toggle()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggle()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              dataToDownload={data}
              headerText={headerText}
              showExpandButton={true}
              showDownloadDataButton={!hideDownloadButton}
              showButtonsTooltips={false}
              handleExpandButtonClick={toggle}
            >
              <div style={{ width: "130px" }}>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isClearable={false}
                  value={selectedIndex}
                  options={indexisTypesOptions}
                  onChange={(e) => setSelectedIndex(e)}
                />
              </div>
            </CardHeader>
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(5, 1fr)",
                      gap: "10px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {territorializations.map(({ label }) => {
                      return (
                        <LineGraphic
                          customLegend={customLegend}
                          customYAxisDomain={[0, 100]}
                          data={data}
                          lines={[
                            <Line
                              type="monotone"
                              dataKey={`${label}${selectedIndex.value}`}
                              stroke={"#000000"}
                              name={`${label} ${selectedIndex.value}`}
                            />,
                          ]}
                          xAxisDataKey={"periodLabel"}
                          brushBar={brushBar}
                          legend={legend}
                        />
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div>{footerText}</div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const LineGraphic = ({
  brushBar = false,
  customLegend,
  customTooltip,
  customYAxisDomain,
  data,
  lines,
  legend,
  xAxisDataKey,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisDataKey} />
        <YAxis domain={customYAxisDomain ? customYAxisDomain : null} />
        <Tooltip content={customTooltip ? customTooltip : null} />
        {legend ? legend : <Legend content={customLegend} />}
        {!lines || lines.length === 0 ? null : lines.map((bar) => bar)}
        {brushBar ? <Brush /> : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MultipleIndexisGraphicModal;
