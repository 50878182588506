import React from "react";
import styles from "../ConfigurationsPage.module.css";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { selectComponentStyles } from "../../../../services/utils/globalFunctions";
import { useTranslation } from "react-i18next";

const useMyAccount = () => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(null);

  const { t, i18n } = useTranslation("configurationsPage");

  React.useEffect(() => {
    const languageOnLocalStorage = localStorage.getItem("i18nextLng");

    if (!languageOnLocalStorage) return;

    const language = supportedLanguages.find(
      ({ value }) => value === languageOnLocalStorage
    );

    if (!language) return;

    setSelectedLanguage(language);
  }, []);

  const handleLanguageChange = (newValue) => {
    setSelectedLanguage(newValue);

    i18n.changeLanguage(newValue.value);
  };

  const supportedLanguages = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Español",
      value: "es",
    },
    {
      label: "Português",
      value: "pt-BR",
    },
  ];

  return {
    selectedLanguage,
    handleLanguageChange,
    supportedLanguages,
    t,
  };
};

export const MyAccount = () => {
  const {
    handleLanguageChange,
    selectedLanguage,
    supportedLanguages,

    t,
  } = useMyAccount();

  const myAccountTranslationsPath = "modules.account.pages.my_account";

  return (
    <section>
      <span className={styles.configurationTitle} style={{ color: "black" }}>
        {t(`${myAccountTranslationsPath}.sections.my_configurations.title`)}
      </span>
      <hr style={{ margin: "1rem 0px" }} />
      <ul className={styles.list}>
        <li className={styles.configurationListItem}>
          <div>
            <span className={styles.configurationSectionTitle}>
              {t(
                `${myAccountTranslationsPath}.sections.my_configurations.language.title`
              )}
            </span>
            <span className={styles.secondaryText}>
              {t(
                `${myAccountTranslationsPath}.sections.my_configurations.language.description`
              )}
            </span>
          </div>
          <div style={{ width: "150px" }}>
            <Select
              styles={selectComponentStyles}
              placeholder={"Selecione"}
              isClearable={false}
              value={selectedLanguage}
              options={supportedLanguages}
              style={{ width: "250px" }}
              onChange={(e) => handleLanguageChange(e)}
            />
          </div>
        </li>
      </ul>
    </section>
  );
};
