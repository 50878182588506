import { useContextSelector } from "use-context-selector";
import { PositivityIndexMapContext } from "../Contexts/PositivityIndexMapContext";

const usePositivityIndexMap = () => {
  const positivityIndexGroupPointsList = useContextSelector(
    PositivityIndexMapContext,
    (context) => context.positivityIndexGroupPointsList
  );
  const setPositivityIndexGroupPointsList = useContextSelector(
    PositivityIndexMapContext,
    (context) => context.setPositivityIndexGroupPointsList
  );

  return {
    positivityIndexGroupPointsList,
    setPositivityIndexGroupPointsList,
  };
};

export default usePositivityIndexMap;
