import React, {Component, useEffect, useState} from "react";
import { Bar, Brush, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Scatter, Surface, Symbols, Tooltip, XAxis, YAxis } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { ARMADILHA_INFO } from "../../../../../constants/ArmadilhaConstant";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";
import { forEach } from "jszip";

const colors = ["#E60017", "#FC6D0D", "#B10FFF", "#0C28F2", "#D44D13"];
const colors2 = ["#C99779", "#F7D35E", "#FF9900", "#E04000", "#795548"];
const colors3 = ["A3CD95", "#D1F2C2", "#FFC107", "#F58220", "#667C5C"];

const centerStyle = {display: "flex", justifyContent: "center", alignItems: "center"};

const OvosLineContainer = ({data, labels, trapTypeName}) => {
    const [chartDataSets, setChartDataSets] = useState();
    const [barChartDataSets, setBarChartDataSets] = useState();
    const [maximumEggs, setMaximumEggs] = useState(0);
    const [maximumSamplePick, setMaximumSamplePick] = useState(0);
    const [maximumSample, setMaximumSample] = useState(0);
    const [getpng, {ref}] = useCurrentPng();
    const [elementData, setElementData] = useState();

    const SymbolsAndLegend = ({index, name, type, elementContent, typeGeometry = "circle"}) => {
        
        
        let color = colors[index];       
        let active = elementContent.checked;
        if(type == "Pick") {
            color = colors2[index];       
            active =  elementContent.checkedPick;
        } else if(type == "Traps") {
            color = colors2[index];       
            active =  elementContent.checkedTraps;
        } else if(type == "Sample") {
            color = colors3[index];       
            active =  elementContent.checkedSample;
        } 

        const style = {
            marginRight: 10,
            color: active ? "#000" : "#AAA",
            cursor: "pointer",
            gap: "10px",
            ...centerStyle
        };

        return (
            <div
                className="legend-item"
                onClick={() => {
                    var elementDataAux = [...elementData];

                    elementDataAux.map((valueElementContent, indexElementContent) => {
                        if(indexElementContent == index) {
                            if(type == "Default") {
                                valueElementContent.checked = !valueElementContent.checked;
                            } else if(type == "Pick") {
                                valueElementContent.checkedPick = !valueElementContent.checkedPick;
                            } else if(type == "Traps") {
                                valueElementContent.checkedTraps = !valueElementContent.checkedTraps;
                            } else if(type == "Sample") {
                                valueElementContent.checkedSample = !valueElementContent.checkedSample;
                            } 
                                
                        }
                    })

                    setElementData(elementDataAux);
                }}
                style={style}
            >
                {
                    typeGeometry  == "line"
                    ?
                        <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32">
                            <title></title>
                            <desc></desc>
                            <path
                            stroke-width="4"
                            fill={active ? color : "white"}
                            stroke={color}
                            d="M0,16h10.666666666666666
                                A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                                H32M21.333333333333332,16
                                A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                            />
                        </svg>
                    :
                        <Surface width={10} height={10} viewBox="0 0 10 10">
                            <Symbols cx={5} cy={5} type={typeGeometry} size={50} fill={color} />
                            {!active && (
                                <Symbols
                                cx={5}
                                cy={5}
                                type={typeGeometry}
                                size={25}
                                fill={"#FFF"}
                                />
                            )}
                        </Surface>
                }
                <span>
                    {name} {elementContent.label}
                </span>
            </div>
        )
    }

    const renderCustomizedLegend  = () => {
        return (
            <div style={centerStyle}>
                {elementData && elementData.map((elementContent, index) => {

                    return (
                        ARMADILHA_INFO[trapTypeName].principalColetant == "Ovos"
                        ?
                        <>
                            <SymbolsAndLegend index={index} name={ARMADILHA_INFO[trapTypeName].principalColetant} type={"Default"} elementContent={elementContent} typeGeometry="line"/>
                            <SymbolsAndLegend index={index} name={"Palhetas"} type={"Pick"} elementContent={elementContent} typeGeometry="square"/>
                        </>
                        :
                        <>
                            <SymbolsAndLegend index={index} name={ARMADILHA_INFO[trapTypeName].principalColetant} type={"Default"} elementContent={elementContent} typeGeometry="line"/>
                            <SymbolsAndLegend index={index} name={"Armadilhas"} type={"Traps"} elementContent={elementContent} />
                            <SymbolsAndLegend index={index} name={"Amostras"} type={"Sample"} elementContent={elementContent} typeGeometry="square"/>
                        </>
                    );
                })}
            </div>
        )
    };
    useEffect(() => {
        if(data && data.length > 0) {
            var elementData = [...data];
            var hasYear = false;


            elementData.map((contentElement) => {
                contentElement.checked = true;
                contentElement.checkedPick = true;
                contentElement.checkedSample = true;
                contentElement.checkedTraps = true;
                if(contentElement.label) {
                    hasYear = true;
                }
            })

            setElementData(elementData);
        }
    }, [chartDataSets,data])

    useEffect(() => {
        if(data){
            getDataSets(data);
        }
    }, [data])

    const getDataSets = async (data) => {
        const dataset = [];
        const arrKey = ARMADILHA_INFO[trapTypeName].principalColetant; 

        var maximumEggs = 0;
        var maximumSamplePick = 0;
        var maximumSample = 0;

        for(const index in labels){
            const dataSetObject = {
                label: labels[index]
            }


            
            for(let ano in data){
                if(data[ano][index]){
                    var eggs = 0;
                    var samplePick = 0;
                    var samplesAnalyzed = 0;
                    var trapsExaminated = 0;

                    data[ano][index].forEach((dataTerr) => {

                        eggs += dataTerr.ovos;
                        samplePick += dataTerr.pickAnalyzed;
                        samplesAnalyzed += dataTerr.samplesAnalyzed;
                        trapsExaminated += dataTerr.examinated;

                        if(eggs > maximumEggs) {
                            maximumEggs = eggs;
                        }

                        if(samplePick > maximumSamplePick) {
                            maximumSamplePick = samplePick;
                        }

                        if(samplesAnalyzed > maximumSample) {
                            maximumSample = samplesAnalyzed;
                        }
                    })

                    if(data[ano].label){
                        dataSetObject[`${arrKey} ${data[ano].label}`] = eggs;

                        dataSetObject["Palhetas " + data[ano].label] = samplePick;
                        dataSetObject["Amostras " + data[ano].label] = samplesAnalyzed;
                        dataSetObject["Armadilhas " + data[ano].label] = trapsExaminated;
                    }else{
                        dataSetObject[arrKey] = eggs;
                        
                        dataSetObject["Palhetas"] = samplePick;
                        dataSetObject["Amostras"] = samplesAnalyzed;
                        dataSetObject["Armadilhas"] = trapsExaminated;
                    }
                }
            }

            

            dataset.push(dataSetObject);
        }

        setMaximumEggs(maximumEggs);
        setMaximumSamplePick(maximumSamplePick);
        setMaximumSample(maximumSample);

        setChartDataSets(dataset);
        
    }

    const buildChartElement = () => {
        var hasYear = false;
        data.map((ano, index) => {
            if(ano.label) {
                hasYear = true;
            }
        })
        return (
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    id={"OvosChartContainerChart"}
                    data={chartDataSets}
                    ref={ref}
                    margin={{top:30, right:15}}
                >
                    <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="16" fontWeight={"bolder"}>{`Total de ${ARMADILHA_INFO[trapTypeName].principalColetant}`}</tspan>
                    </text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" style={{fontSize:12}}/>
                    <YAxis label={{ value: ARMADILHA_INFO[trapTypeName].principalColetant, angle: -90, position: 'insideLeft' }} allowDecimals={false} allowDataOverflow={true} domain={[0, maximumEggs]}  yAxisId="line-axis" style={{fontSize:12}} tickCount={101}/>
                    {
                        ARMADILHA_INFO[trapTypeName] && ARMADILHA_INFO[trapTypeName].principalColetant == "Ovos" 
                        ?
                            <YAxis label={{ value: 'Palhetas', angle: 90, position: 'insideRight' }} allowDataOverflow={true} amplitude={10} allowDecimals={false} yAxisId="bar-axis" style={{fontSize:12}} tickCount={101}
                                orientation="right" domain={[0, maximumSamplePick]} />
                        : 
                        ARMADILHA_INFO[trapTypeName] && ARMADILHA_INFO[trapTypeName].principalColetant == "Mosquitos" 
                        ?
                        <YAxis label={{ value: 'Armadilhas / Amostras', angle: 90, position: 'insideRight' }} allowDataOverflow={true} amplitude={10}  allowDecimals={false} yAxisId="bar-axis" style={{fontSize:12}} tickCount={101}
                                orientation="right" domain={[0, maximumSample]} />
                        :
                        <></>
                    }
                    <Tooltip/>
                    {
                        hasYear
                        ?
                            <Legend 
                                align="center" 
                                verticalAlign="top"
                                content={() => renderCustomizedLegend()}
                            />
                        :
                        <Legend align="center" verticalAlign="top" />
                    }
                    <Brush dataKey={"label"}/>
                    {elementData && elementData.map((ano, index) => (
                        <>
                            {
                                ARMADILHA_INFO[trapTypeName] && ARMADILHA_INFO[trapTypeName].principalColetant == "Ovos" ?
                                
                                    !hasYear  || (hasYear && ano.checkedPick)
                                    ?
                                    <Bar yAxisId="bar-axis" dataKey={ano.label ? `Palhetas ${ano.label}` : "Palhetas"} barSize={20} fill={colors2[index]} />
                                    :
                                    <></>
                                :
                                ARMADILHA_INFO[trapTypeName] && ARMADILHA_INFO[trapTypeName].principalColetant == "Mosquitos" ?
                                <>
                                    {
                                        !hasYear  || (hasYear && ano.checkedSample)
                                        ?
                                        <Bar yAxisId="bar-axis" dataKey={ano.label ? `Amostras ${ano.label}` : "Amostras"} barSize={20} fill={colors2[index]} />
                                        :
                                        <></>
                                    }
                                    {
                                        !hasYear  || (hasYear && ano.checkedTraps)
                                        ?
                                        <Scatter yAxisId="bar-axis" dataKey={ano.label ? `Armadilhas ${ano.label}` : "Armadilhas"} barSize={20} fill={colors3[index]} />
                                        :
                                        <></>
                                    }
                                </>
                                :
                                <></>
                            }
                            {
                                !hasYear  || (hasYear && ano.checked)
                                ?
                                <Line  yAxisId="line-axis" dataKey={ano.label ? `${ARMADILHA_INFO[trapTypeName].principalColetant} ${ano.label}` : ARMADILHA_INFO[trapTypeName].principalColetant} stroke={colors[index]} />
                                :
                                <></>
                            }
                        </>
                    ))}
                </ComposedChart>
            </ResponsiveContainer>
        )
    }

    //construindo propriedades do presentational

    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const textCardHeader = `Total de ${ARMADILHA_INFO[trapTypeName].principalColetant}`;
  
    return(
        <CardPresentational
            CardId = {"OvosChartContainer"}
            style={style}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}

export default OvosLineContainer;