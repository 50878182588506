import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";
// reactstrap components
import {
    Container,
} from "reactstrap";
import { getPermissionsToken } from "../../services/utils/auth";
import { fetchEmployees } from "../../services/api/Employee";
import {Select} from '../Wrappers/SelectAll';
import "./FiltroAmostras.css";
import { fetchDemands } from "../../services/api/Demand";
import { fetchPrograms } from "../../services/api/Program";
class FiltroAmostras extends React.Component {
    state = {

        filterSampleNumber:"",
        filterDateEnd:"",
        filterDateStart:"",
        filterStatus:"all",
        employees: [],
        Employee:null,
        Demands:[],
        programs:[],

    }
    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };
      resetFilter = () => { 
        this.props.clearFilterParams();
      }
    testStatus(status){
        if(this.state.filterStatus == "all"){
            return true;
        }
        if(this.state.filterStatus == status){
            return true;
        }else{
            return false;
        }

    }


    componentDidMount() {

        var requestPrograms = fetchPrograms();
        requestPrograms.then(response => {
            this.setState({programs: response.data});
        })
        
        var requestEmployees = fetchEmployees();
            requestEmployees.then(response => {
              let employeesOpt = response.data;
              var employeesSelect = [];
    
              employeesOpt.forEach(element => {
                let employee = {
                  label: element.name,
                  value: element.id,
                  EmployeeId: element.id,     
                  Hierarchy: element.function != null ? element.function.hierarchy: null,     
                }
                employeesSelect.push(employee);
              });
    
              this.setState({employees: employeesSelect});
            });

        var requestDemands = fetchDemands();
            requestDemands.then(response => {
              let demandsOpt = response.data;
              var demandsSelect = [];
    
              demandsOpt.forEach(element => {
                let demand = {
                  label: element.serviceOrder,
                  value: element.id,
                  DemandId: element.id     
                }
                demandsSelect.push(demand);
              });
    
              this.setState({Demands: demandsSelect});
            });    
      }
      handleChangeExecutor = Employee => {
        this.setState(
          { Employee },
          () => console.log('Option selected:', this.state.Executioner)
        );
      };
    render() {
        const userPermissions = getPermissionsToken();
        const {filterParams} = this.props;
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">

                        <div className="row linha-form">
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                                <input type="text" value={filterParams.sampleNumber} name={"sampleNumber"} onChange={(e) => {this.props.changeFilterParams(e.target.name, e.target.value)}} className="form-control" placeholder="N&uacute;mero Amostra" />
                            </div>
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                                <Select
                                    value={this.state.Demands.filter(e => e.value == filterParams.demandId)}
                                    onChange={(e) => this.props.changeFilterParams("demandId", e.value)}
                                    options={this.state.Demands}
                                    placeholder="Demanda"
                                    styles={{
                                        control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                                    }}
                                />
                            </div>
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                                <input type="text" className="form-control" placeholder="Protocolo externo" />
                            </div>
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                            <Select
                                value={this.state.employees.filter(e => e.value == filterParams.employeeId)}
                                onChange={(e) => this.props.changeFilterParams("employeeId", e.value)}
                                options={this.state.employees}
                                placeholder="Funcionário"
                                styles={{
                                    control: styles =>({...styles, minHeight:"2.90rem", borderRadius:"0.370rem"}),
                                }}
                            />
                            </div>
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                                <select value={filterParams.programId} onChange={(e) => this.props.changeFilterParams("programId", e.target.value)} name={"filterProgram"} className="form-control">
                                    <option value={"00000000-0000-0000-0000-000000000000"}>Todos os programas</option>
                                    {this.state.programs.map((program) => {
                                        return (
                                            <option value={program.id}>{program.name}</option>
                                        )
                                    })} 
                                </select>
                            </div>
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                                <select name={"status"} value={filterParams.status} onChange={(e) => {this.props.changeFilterParams(e.target.name, e.target.value)}} className="form-control">
                                    <option value="all">Todas</option>
                                    <option value="Created">Criada</option>
                                    <option value="Analyzed">Analisada</option>
                                    <option value="Discarded">Descartada</option>       
                                    <option value="Diagnostic not possible">Não passível de análise</option>                                
                                </select>
                            </div>
                        
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                                <input value={filterParams.startDate} name={"startDate"} onChange={(e) => {this.props.changeFilterParams(e.target.name, e.target.value)}} type="date" className="form-control" />
                            </div>
                            <div className="linha-form col-4 responsive-filter-field-sample-medium">
                                <input value={filterParams.endDate} name={"endDate"} onChange={(e) => {this.props.changeFilterParams(e.target.name, e.target.value)}} type="date" className="form-control" />
                            </div>
                            
                            
                            <div className="col-4 responsive-filter-field-sample-medium">
                                <button onClick={()=> this.props.parentCallback()} className="btn btn-primary"> <i className="fa fa-search" /> Pesquisar</button>
                            </div>
                            <div className="col-4 responsive-filter-field-sample-medium">
                                <button onClick={()=>this.resetFilter()} className="form-control"> <i className="fa fa-eraser" /> Limpar filtro</button>
                            </div>

                            {userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"] && userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"].create ? 
                                <div className="col-4 responsive-filter-field-sample-full">
                                    <Link to="/admin/AddSampleResultNew">
                                        <button className="float-right btn btn-default"> <i className="fa fa-atom" /> Cadastrar Resultado</button>
                                    </Link>
                                </div>
                            :null}
                            
                            {userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"] && userPermissions["7d306cb1-0df5-4fdc-a658-8f7492292172"].create ? 
                                <div className="col-4 responsive-filter-field-sample-full">
                                    <Link to="/admin/AddSampleCovid">
                                        <button className="float-right btn btn-default"> <i className="fa fa-vial" /> Cadastrar Amostra</button>
                                    </Link>
                                </div>
                            :null}
                            
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FiltroAmostras;