import React, { useState, useEffect } from "react";
import FiltroAmostras from "../components/Navbars/FiltroAmostras";
import { fetchSamples } from "../services/api/Sample";
import Header from "components/Headers/Header.jsx";
import "../assets/css/style.css";
import SampleTable from "../components/Lists/Sample/SampleTable";
import { checkPermissionComponent } from "../services/utils/permission";
import moment from "moment";
import { getUserData } from "../services/utils/auth";
import { getUserEmployeeDataByOrganization } from "../services/api/Users";
import TableLoadingSkeleton from "../components/ui/Loading/TableLoadingSkeleton";

const AmostrasColetadas = (props) => {
  const [samples, setSamples] = useState([]);
  const [sampleFilter, setSampleFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({
    organizationId: "",
    employeeId: "",
    userId: "00000000-0000-0000-0000-000000000000",
    sampleNumber: "",
    startDate: "",
    status: "all",
    endDate: "",
    demandId: "00000000-0000-0000-0000-000000000000",
    programId: "00000000-0000-0000-0000-000000000000",
  });

  useEffect(() => {
    if (!checkPermissionComponent("7d306cb1-0df5-4fdc-a658-8f7492292172", "read")) {
      alert("Você não tem permissão para acessar essa funcionalidade!");
      props.history.goBack();
      return;
    }

    const fetchData = async () => {
      try {
        const orgId = await getUserData("organizationId");
        const updatedFilterParams = {
          ...filterParams,
          organizationId: orgId,
          endDate: moment().format("YYYY-MM-DD"),
          startDate: moment().subtract(3, "month").format("YYYY-MM-DD"),
        };
        setFilterParams(updatedFilterParams);
      
        setLoading(true)
        const response = await fetchSamples(updatedFilterParams);
        setSamples(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar amostras", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const changeFilterParams = (field, value) => {
    setFilterParams((prev) => ({ ...prev, [field]: value }));
  };

  const clearFilterParams = async () => {
    const defaultParams = {
      organizationId: "",
      employeeId: "",
      userId: "00000000-0000-0000-0000-000000000000",
      sampleNumber: "",
      startDate: "",
      status: "all",
      endDate: "",
      demandId: "00000000-0000-0000-0000-000000000000",
      programId: "00000000-0000-0000-0000-000000000000",
    };

    const orgId = await getUserData("organizationId");
    setFilterParams({
      ...defaultParams,
      organizationId: orgId,
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().subtract(3, "month").format("YYYY-MM-DD"),
    });
  };

  const callbackFunction = async () => {
    try {
      const userRequest = await getUserEmployeeDataByOrganization();
      const employees = userRequest.data.reduce((acc, obj) => {
        acc[obj["employeeId"]] = { userId: obj["userId"] };
        return acc;
      }, {});

      const updatedFilterParams = { ...filterParams };
      if (filterParams.employeeId !== "") {
        updatedFilterParams.userId = employees[filterParams.employeeId] ? employees[filterParams.employeeId].userId : "00000000-0000-0000-0000-000000000000";
      }

      setLoading(true)
      const response = await fetchSamples(updatedFilterParams);
      const filteredSamples = response.data.filter((sample) =>
        filterParams.status === "all" || sample.status === filterParams.status
      );

      setSamples(filteredSamples);
      setLoading(false)
    } catch (error) {
      console.error("Erro ao buscar dados", error);
      setLoading(false)
    }
  };

  return (
    <>
      <Header />
      <FiltroAmostras
        Samples={samples}
        filterParams={filterParams}
        parentCallback={callbackFunction}
        changeFilterParams={changeFilterParams}
        clearFilterParams={clearFilterParams}
      />

      {
        loading
        ?
        <div className="mt--7" style={{padding: "0 40px"}}>
          <TableLoadingSkeleton tableColumns={10} length={10} tableColumnWidth="100px" variant={"simple"} />
        </div>
        :
        <SampleTable Samples={samples} filterParams={filterParams} />
      }
    </>
  );
};

export default AmostrasColetadas;
