import React from "react";

import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import { getTodayEpidemiologicalWeek } from "../../../../services/utils/todayEpidemiologicalWeek";
import { useNotificationsList } from "../Hooks/useNotificationsList";
import { useCardNotificationsList } from "../Hooks/useCardNotificationsList";
import CardLoadingSkeleton from "../../../../components/ui/Loading/CardLoadingSkeleton";

const useSinanCasesCards = () => {
  const [cases, setCases] = React.useState(null);
  const { cardsNotificationsList } = useCardNotificationsList();
  const { isNotificationsListLoading } = useNotificationsList();

  React.useEffect(() => {
    if(!cardsNotificationsList || cardsNotificationsList.length === 0) return;

    const yearCases = cardsNotificationsList.find(
      ({ periodName }) => periodName === "Ano atual"
    );
    const lastThirtyDaysCases = cardsNotificationsList.find(
      ({ periodName }) => periodName === "Últimos 30 dias"
    );
    const lastSevenDaysCases = cardsNotificationsList.find(
      ({ periodName }) => periodName === "Últimos 7 dias"
    );

    setCases({
      notificationsFromYear: yearCases.cases.notifications.cases,
      notificationsFromlastThirtyDays:
        lastThirtyDaysCases.cases.notifications.cases,
      notificationsFromlastSevenDays:
        lastSevenDaysCases.cases.notifications.cases,
      confirmedNotificationsFromYear: yearCases.cases.confirmed.cases,
      confirmedNotificationsFromlastThirtyDays:
        lastThirtyDaysCases.cases.confirmed.cases,
      confirmedNotificationsFromlastSevenDays:
        lastSevenDaysCases.cases.confirmed.cases,
    });
  }, [cardsNotificationsList]);

  return { cases, isNotificationsListLoading };
};

export const SinanCasesCards = () => {
  const { cases, isNotificationsListLoading } = useSinanCasesCards();

  if (isNotificationsListLoading)
    return (
      <>
        <Row className="mb-4">
          <Col>
            <CardLoadingSkeleton />
          </Col>
          <Col>
            <CardLoadingSkeleton />
          </Col>
          <Col>
            <CardLoadingSkeleton />
          </Col>
        </Row>
      </>
    );

  if(!cases)
    return null;

  return (
    <section>
      <Row className="mb-4">
        <Col>
          <SinanCard>
            <SinanCardHeader>
              Casos Notificados / Confirmados no ano
            </SinanCardHeader>
            <SinanCardContent>
              <div>
                <span style={{ fontWeight: 600, fontSize: "1.5rem" }}>
                  {cases.notificationsFromYear} / {cases.confirmedNotificationsFromYear}
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
              ></div>
            </SinanCardContent>
            <SinanCardFooter>
              {moment().startOf("year").format("D MMM, YYYY")} -{" "}
              {moment().format("D MMM, YYYY")}
            </SinanCardFooter>
          </SinanCard>
        </Col>
        <Col>
          <SinanCard>
            <SinanCardHeader>
              Casos Notificados / Confirmados últimos 30 dias
            </SinanCardHeader>
            <SinanCardContent>
              <div>
                <span style={{ fontWeight: 600, fontSize: "1.5rem" }}>
                  {cases.notificationsFromlastThirtyDays} /{" "}
                  {cases.confirmedNotificationsFromlastThirtyDays}
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
              ></div>
            </SinanCardContent>
            <SinanCardFooter>
              {moment().subtract(30, "days").format("D MMM, YYYY")} -{" "}
              {moment().format("D MMM, YYYY")}
            </SinanCardFooter>
          </SinanCard>
        </Col>
        <Col>
          <SinanCard>
            <SinanCardHeader>
              Casos Notificados / Confirmados últimos 7 dias
            </SinanCardHeader>
            <SinanCardContent>
              <div>
                <span style={{ fontWeight: 600, fontSize: "1.5rem" }}>
                  {cases.notificationsFromlastSevenDays} /{" "}
                  {cases.confirmedNotificationsFromlastSevenDays}
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
              ></div>
            </SinanCardContent>
            <SinanCardFooter>
              {moment().subtract(6, "days").format("D MMM, YYYY")} -{" "}
              {moment().format("D MMM, YYYY")}
            </SinanCardFooter>
          </SinanCard>
        </Col>
      </Row>
      <span
        className="h4 mb-4"
        style={{ textAlign: "center", display: "block" }}
      >
        Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
      </span>
    </section>
  );
};

const SinanCard = ({ children, props }) => {
  return (
    <Card style={{ borderColor: "#dfdfdf" }} {...props}>
      {children}
    </Card>
  );
};

const SinanCardHeader = ({ children, props }) => {
  return (
    <CardHeader
      style={{
        borderBottom: "0px",
        fontWeight: 600,
        paddingBottom: "0px",
      }}
      {...props}
    >
      {children}
    </CardHeader>
  );
};

const SinanCardContent = ({ children, props }) => {
  return (
    <CardBody
      style={{
        paddingTop: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      {...props}
    >
      {children}
    </CardBody>
  );
};

const SinanCardFooter = ({ children, props }) => {
  return (
    <CardFooter
      style={{ backgroundColor: "#f7f7f7", borderTop: "0px" }}
      {...props}
    >
      {children}
    </CardFooter>
  );
};
