import React from "react";
import FieldResultsPage from "./FieldResultsPage";
import { FieldResultsPageContextProvider } from "./Contexts/FieldResultsPageContext";
import useDemandsList from "../../../hooks/useDemandsList";
import useEmployeesList from "../../../hooks/useEmployeesList";
import useTeamsList from "../../../hooks/useTeamsList";
import useTerritorializationsList from "../../../hooks/useTerritorializationsList";
import useTrapTypesList from "../../../hooks/useTrapTypesList";
import useTypeLayersList from "../../../hooks/useTypeLayersList";
import {
  fetchDemandsData,
  fetchEmployeesAndTeamsData,
  fetchTerritorializationsData,
  fetchTrapTypesData,
} from "../../../services/utils/globalFunctions";
import { PositivityIndexMapContextProvider } from "./Contexts/PositivityIndexMapContext";

const useFieldsResultsPageWrapper = () => {
  const { demandsList, setDemandsList } = useDemandsList();
  const { employeesList, setEmployeesList } = useEmployeesList();
  const { teamsList, setTeamsList } = useTeamsList();
  const { territorializationsList, setTerritorializationsList } =
    useTerritorializationsList();
  const { trapTypesList, setTrapTypesList } = useTrapTypesList();
  const { typeLayersList, setTypeLayersList } = useTypeLayersList();

  React.useEffect(() => {
    if (demandsList.length === 0) fetchDemandsData(setDemandsList);

    if (territorializationsList.length === 0 || typeLayersList.length === 0)
      fetchTerritorializationsData(
        setTerritorializationsList,
        setTypeLayersList
      );

    if (trapTypesList.length === 0) fetchTrapTypesData(setTrapTypesList);

    if (teamsList.length === 0 || employeesList.length === 0)
      fetchEmployeesAndTeamsData(setEmployeesList, setTeamsList);
  }, []);

  return {};
};

const FieldResultsPageWrapper = () => {
  useFieldsResultsPageWrapper();

  return (
    <div>
      <PositivityIndexMapContextProvider>
        <FieldResultsPageContextProvider>
          <FieldResultsPage />
        </FieldResultsPageContextProvider>
      </PositivityIndexMapContextProvider>
    </div>
  );
};

export default FieldResultsPageWrapper;
