import React from "react";
import { ToastContainer } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const ContactUs = () => {

  const { t } = useTranslation("contactUs");

  return (
    <div>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
        <Row>
          <Col>
            <h1 style={{color: "#f75c5c"}}>{t('contact_instructions.title')}</h1>
            <ul style={{color: "#ffffff"}}>
              <li>{t('contact_instructions.steps.step1')}</li>
              <li>{t('contact_instructions.steps.step2')}</li>
              <li>{t('contact_instructions.steps.step3')}</li>
              <li>{t('contact_instructions.steps.step4')}</li>
              <li>{t('contact_instructions.steps.step5')}</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt--7">
        <Row>
          <Col>
            <iframe
              title="tomticket"
              src="https://vitec.tomticket.com/formulario?id=EP64691&account=4140224P26012024113116"
              style={{width: "100%", height: "1000px", borderWidth: "0px", overflow: "auto", marginTop: "55px"}}
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
