import React from "react";
import { getUserData } from "../../../../../services/utils/auth";
import { getEpidemiologicWeeksFromTheStartOfTheYearToToday } from "../../../../../services/utils/globalFunctions";
import moment from "moment";
import { notificiationsByFilters } from "../../../../../services/api/Sinan";
import { toast } from "react-toastify";
import { useAppliedFilters } from "../../Hooks/useAppliedFilters";
import { Col, Row } from "reactstrap";
import { CasesHistoricalSeriesGraphic } from "./CasesHistoricalSeriesGraphic";
import { CONFIRMED, NOTIFICATIONS } from "../../SinanPage";
import LoadingSpin from "react-loading-spin";

const useCasesHistoricalSeriesGraphicWrapper = (
  notificationsList,
  configurations
) => {
  const [historicalSeriesList, setHistoricalSeriesList] = React.useState([]);
  const [filteredHistoricalSeriesList, setFilteredHistoricalSeriesList] =
    React.useState([]);
  const [cachedNotifications, setCachedNotifications] = React.useState([]);
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    const organizationId = getUserData("organizationId");
    const sinanCacheStorage = localStorage.getItem("SinanPageCache");
    const sinanCacheStorageParsed = JSON.parse(sinanCacheStorage);

    const cachedNotifications = [];

    if (
      sinanCacheStorageParsed &&
      sinanCacheStorageParsed[organizationId] &&
      sinanCacheStorageParsed[organizationId].length > 0
    ) {
      sinanCacheStorageParsed[organizationId].forEach((year) => {
        year.forEach((week) => {
          cachedNotifications.push(week);
        });
      });
    }

    setCachedNotifications(cachedNotifications);
  }, []);

  React.useEffect(() => {
    if (
      !notificationsList ||
      notificationsList.length === 0 ||
      cachedNotifications.length === 0
    )
      return;

    const periodsMap = {};

    if (!Array.isArray(notificationsList[0])) {
      notificationsList.forEach(
        ({ resultsByPeriods, territorializationName }) => {
          resultsByPeriods.forEach(
            ({
              periodName,
              result: { confirmed, notifications, sorotype },
            }) => {
              if (!periodsMap[periodName])
                periodsMap[periodName] = {
                  label: periodName,
                  [`${territorializationName}ConfirmedCases`]: 0,
                  [`${territorializationName}NotifiedCases`]: 0,
                  sorotypeOneCases: 0,
                  sorotypeTwoCases: 0,
                  sorotypeThreeCases: 0,
                  sorotypeFourCases: 0,
                };

              if (
                !periodsMap[periodName][
                  `${territorializationName}NotifiedCases`
                ]
              ) {
                periodsMap[periodName][
                  `${territorializationName}ConfirmedCases`
                ] = 0;
                periodsMap[periodName][
                  `${territorializationName}NotifiedCases`
                ] = 0;
              }

              periodsMap[periodName][
                `${territorializationName}ConfirmedCases`
              ] += confirmed.cases;
              periodsMap[periodName][
                `${territorializationName}NotifiedCases`
              ] += notifications.cases;
              periodsMap[periodName].sorotypeOneCases += sorotype.one;
              periodsMap[periodName].sorotypeTwoCases += sorotype.two;
              periodsMap[periodName].sorotypeThreeCases += sorotype.three;
              periodsMap[periodName].sorotypeFourCases += sorotype.for;
            }
          );
        }
      );
    } else {
      notificationsList.forEach((period) => {
        period.forEach(({ resultsByPeriods, territorializationName, year }) => {
          resultsByPeriods.forEach(
            ({
              periodName,
              result: { confirmed, notifications, sorotype },
            }) => {
              if (!periodsMap[`${periodName}/${year}`])
                periodsMap[`${periodName}/${year}`] = {
                  label: `${periodName.replace("Semana ", "")}/${year}`,
                  [`${territorializationName}ConfirmedCases`]: 0,
                  [`${territorializationName}NotifiedCases`]: 0,
                  sorotypeOneCases: 0,
                  sorotypeTwoCases: 0,
                  sorotypeThreeCases: 0,
                  sorotypeFourCases: 0,
                };

              if (
                !periodsMap[`${periodName}/${year}`][
                  `${territorializationName}NotifiedCases`
                ]
              ) {
                periodsMap[`${periodName}/${year}`][
                  `${territorializationName}ConfirmedCases`
                ] = 0;
                periodsMap[`${periodName}/${year}`][
                  `${territorializationName}NotifiedCases`
                ] = 0;
              }

              periodsMap[`${periodName}/${year}`][
                `${territorializationName}ConfirmedCases`
              ] += confirmed.cases;
              periodsMap[`${periodName}/${year}`][
                `${territorializationName}NotifiedCases`
              ] += notifications.cases;
              periodsMap[`${periodName}/${year}`].sorotypeOneCases +=
                sorotype.one;
              periodsMap[`${periodName}/${year}`].sorotypeTwoCases +=
                sorotype.two;
              periodsMap[`${periodName}/${year}`].sorotypeThreeCases +=
                sorotype.three;
              periodsMap[`${periodName}/${year}`].sorotypeFourCases +=
                sorotype.for;
            }
          );
        });
      });
    }

    const periodsMapArray = Array.from(Object.values(periodsMap));

    const periodsMapWithSorotypesPercentage = periodsMapArray.map(
      ({
        sorotypeOneCases,
        sorotypeTwoCases,
        sorotypeThreeCases,
        sorotypeFourCases,
        ...rest
      }) => {
        const totalSorotypesSum =
          sorotypeOneCases +
          sorotypeTwoCases +
          sorotypeThreeCases +
          sorotypeFourCases;

        const sorotypeOnePercentage = calculatePercentage(
          sorotypeOneCases,
          totalSorotypesSum
        );
        const sorotypeTwoPercentage = calculatePercentage(
          sorotypeTwoCases,
          totalSorotypesSum
        );
        const sorotypeThreePercentage = calculatePercentage(
          sorotypeThreeCases,
          totalSorotypesSum
        );
        const sorotypeFourPercentage = calculatePercentage(
          sorotypeFourCases,
          totalSorotypesSum
        );

        return {
          ...rest,
          sorotypeOnePercentage,
          sorotypeTwoPercentage,
          sorotypeThreePercentage,
          sorotypeFourPercentage,
        };
      }
    );

    setFilteredHistoricalSeriesList(periodsMapWithSorotypesPercentage);
    formatCasesHistoricalSeries(cachedNotifications);
  }, [notificationsList, cachedNotifications, configurations]);

  const calculatePercentage = (value, total) => {
    if (value === 0 || total === 0) return 0;

    return Math.floor((value / total) * 1000) / 10;
  };

  const fetchNotificationsListFromActualYear = async () => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao carregar a lista de Notificações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com nossa equipe.";

    const epidemiologicWeeksFromTheStartOfTheYearToToday =
      getEpidemiologicWeeksFromTheStartOfTheYearToToday();

    const actualYear = moment().year();

    const filtersToSend = {
      disease: appliedFilters.disease.value,
      includeCasesByPersons: true,
      notifyingUnitsIds: appliedFilters.notifyingUnits.map(
        ({ value }) => value
      ),
      organizationId: getUserData("organizationId"),
      periods: epidemiologicWeeksFromTheStartOfTheYearToToday.map(
        ({ beginDate, endDate, label }) => {
          const weekNumber = label.replace("Semana ", "");

          return {
            beginDate: moment(beginDate, "DD/MM/YYYY")
              .utc()
              .set({ hour: 0, minute: 0, second: 0 })
              .toISOString(),
            endDate: moment(endDate, "DD/MM/YYYY")
              .utc()
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString(),
            periodName: `${weekNumber}/${actualYear}`,
          };
        }
      ),
      referenceDate: appliedFilters.casesReferenceDate.value,
      territorializationsIds: [],
    };

    try {
      const { data, status } = await notificiationsByFilters(filtersToSend);

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      return formatCasesForCache(data);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }
    }
  };

  const formatCasesForCache = (data) => {
    const formatedCases = data.map(({ periodName, cases }) => {
      const result = {
        label: periodName,
        criterion: cases.criterion,
        evolution: cases.evolution,
        finalClassification: cases.finalClassification,
        Notificações: cases.notifications.cases,
        Confirmados: cases.confirmed.cases,
        "Sorotipo 1": cases.sorotype.one,
        "Sorotipo 2": cases.sorotype.two,
        "Sorotipo 3": cases.sorotype.three,
        "Sorotipo 4": cases.sorotype.for,
        PCR: cases.examsTypes.pcr,
        NS1: cases.examsTypes.nS1,
        Soro: cases.examsTypes.serum,
        Isolamento: cases.examsTypes.isolation,
      };

      return result;
    });

    return formatedCases;
  };

  const formatCasesHistoricalSeries = async (cachedNotifications) => {
    const casesFromActualYear = await fetchNotificationsListFromActualYear();

    const historicalSeries = [
      ...cachedNotifications,
      ...casesFromActualYear,
    ].map((item) => {
      const sorotypeOne = item["Sorotipo 1"];
      const sorotypeTwo = item["Sorotipo 2"];
      const sorotypeThree = item["Sorotipo 3"];
      const sorotypeFour = item["Sorotipo 4"];
      const allSorotypesSum =
        sorotypeOne + sorotypeTwo + sorotypeThree + sorotypeFour;

      const sorotypeOnePercentage = calculatePercentage(
        sorotypeOne,
        allSorotypesSum
      );
      const sorotypeTwoPercentage = calculatePercentage(
        sorotypeTwo,
        allSorotypesSum
      );
      const sorotypeThreePercentage = calculatePercentage(
        sorotypeThree,
        allSorotypesSum
      );
      const sorotypeFourPercentage = calculatePercentage(
        sorotypeFour,
        allSorotypesSum
      );

      return {
        label: item.label,
        Notificações: item["Notificações"],
        Confirmados: item["Confirmados"],
        sorotypeOne,
        sorotypeTwo,
        sorotypeThree,
        sorotypeFour,
        "Sorotipo 1": sorotypeOnePercentage,
        "Sorotipo 2": sorotypeTwoPercentage,
        "Sorotipo 3": sorotypeThreePercentage,
        "Sorotipo 4": sorotypeFourPercentage,
      };
    });

    setHistoricalSeriesList(historicalSeries);
  };

  return {
    filteredHistoricalSeriesList,
    historicalSeriesList,
  };
};

export const CasesHistoricalSeriesGraphicWrapper = ({
  isLoadingHistoricalSeriesCache,
  notificationsList,
  configurations,
  periodLegend,
}) => {
  const { filteredHistoricalSeriesList, historicalSeriesList } =
    useCasesHistoricalSeriesGraphicWrapper(notificationsList, configurations);

  const cardHeaderText = {
    notifications: (
      <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
        <span>Série Histórica de Casos Notificados</span>{" "}
        {isLoadingHistoricalSeriesCache ? (
          <span>
            / <LoadingSpin size={17} primaryColor={"#fff"} /> Carregando Série
            Histórica de Casos
          </span>
        ) : null}
      </div>
    ),
    confirmed: (
      <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
        <span>Série Histórica de Casos Confirmados</span>{" "}
        {isLoadingHistoricalSeriesCache ? (
          <span>
            / <LoadingSpin size={17} primaryColor={"#fff"} /> Carregando Série
            Histórica de Casos
          </span>
        ) : null}
      </div>
    ),
  };

  return (
    <section>
      <Row className="mb-4">
        <Col xl={12}>
          <CasesHistoricalSeriesGraphic
            configurations={configurations}
            filteredHistoricalSeriesList={filteredHistoricalSeriesList}
            historicalSeriesList={historicalSeriesList}
            caseReferenceType={NOTIFICATIONS}
            legend={periodLegend}
            headerText={cardHeaderText["notifications"]}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={12}>
          <CasesHistoricalSeriesGraphic
            configurations={configurations}
            filteredHistoricalSeriesList={filteredHistoricalSeriesList}
            historicalSeriesList={historicalSeriesList}
            caseReferenceType={CONFIRMED}
            legend={periodLegend}
            headerText={cardHeaderText["confirmed"]}
          />
        </Col>
      </Row>
    </section>
  );
};
