import React from "react";
import { Container, Row, Col } from "reactstrap";
import { fetchOrganizations } from "../../../services/api/Organization";
import { getTrapsTypes } from "../../../services/api/Trap";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";

const FilterTicket = ({setShowImage, setFormatedOrganizationName, selectedTrapType, setSelectedTrapType, selectedOrganization, setSelectedOrganization}) => {
  const [organizations, setOrganizations] = React.useState();
  const [trapTypes, setTrapTypes] = React.useState();

  React.useEffect(() => {
    var organizations = fetchOrganizations();
    var trapTypes = getTrapsTypes();

    trapTypes.then((response) => {
      setTrapTypes(response.data);
    })

    organizations.then((response) => {
      setOrganizations(response.data);
    })
  }, [])  

  const sanitizeString = (str) => {
    const accentsMap = {
      'á': 'a', 'à': 'a', 'ã': 'a', 'â': 'a', 'ä': 'a',
      'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e',
      'í': 'i', 'ì': 'i', 'î': 'i', 'ï': 'i',
      'ó': 'o', 'ò': 'o', 'õ': 'o', 'ô': 'o', 'ö': 'o',
      'ú': 'u', 'ù': 'u', 'û': 'u', 'ü': 'u',
      'ç': 'c',
      'ñ': 'n'
    };
  
    const removeAccents = (char) => accentsMap[char] || char;
  
    return str
      .toLowerCase()                               
      .split('')                                   
      .map(removeAccents)                          
      .join('')                                    
      .replace(/[^a-z0-9]/g, '');                  
  }

  const formatOrganizationName = () => {
    var formatedOrganizationName = sanitizeString(selectedOrganization);

    setFormatedOrganizationName(formatedOrganizationName);
  }

  return (
    <>
      <Container fluid>
      <Row>
          <Col xl={4}>
            <span className="h4 text-white">
              Selecione o município:
            </span>
            <select value={selectedOrganization} name="" className="form-control"  id="" onChange={(e) => setSelectedOrganization(e.target.value)}>
              {
                organizations && organizations.sort((a, b) => a.name.localeCompare(b.name)).map((organization) => (
                  <option value={organization.name}>{organization.name}</option>
                ))
              }
            </select>
          </Col>
          <Col xl={4}>
            <span className="h4 text-white">
              Selecione o Tipo de armadilha:
            </span>
            <select name="" className="form-control" value={selectedTrapType} onChange={(e) => setSelectedTrapType(e.target.value)}>
              {
                trapTypes && trapTypes.sort((a, b) => a.name.localeCompare(b.name)).map((trapType) => (
                  <option value={trapType.name}>{ARMADILHA_INFO[trapType.name].apelido}</option>
                ))
              }
            </select>
          </Col>
          <Col xl={4} style={{display: "flex", alignItems: "flex-end"}}>
            <button className="btn btn-primary" onClick={() => {formatOrganizationName(); setShowImage(true);}}>
              OK
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FilterTicket;
