import { useContextSelector } from "use-context-selector";
import { FieldResultsPageContext } from "../Contexts/FieldResultsPageContext";

const useGraphicsData = () => {
  const fieldResultsList = useContextSelector(
    FieldResultsPageContext,
    (context) => context.fieldResultsList
  );
  
  const setFieldResultsList = useContextSelector(
    FieldResultsPageContext,
    (context) => context.setFieldResultsList
  );
  
  const situationsByTerritorializations = useContextSelector(
    FieldResultsPageContext,
    (context) => context.situationsByTerritorializations
  );

  const setSituationsByTerritorializations = useContextSelector(
    FieldResultsPageContext,
    (context) => context.setSituationsByTerritorializations
  );

  return {
    fieldResultsList,
    setFieldResultsList,
    situationsByTerritorializations,
    setSituationsByTerritorializations
  };
};

export default useGraphicsData;
