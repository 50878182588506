import React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../../components/Cards/Card";
import { Row } from "react-bootstrap";
import { Col } from "reactstrap";
import DataTable from "react-data-table-component";
import { useTrapsByPeriodsList } from "../../Hooks/useTrapsByPeriodsList";
import { Select } from "../../../../../components/Wrappers/SelectAll";
import { monthsOptionsList, selectComponentStyles } from "../../../../../services/utils/globalFunctions";
import TableLoadingSkeleton from "../../../../../components/ui/Loading/TableLoadingSkeleton";
import moment from "moment";
import { getAllWeeksFromYear } from "../../../../../services/utils/todayEpidemiologicalWeek";

const useAcitivitiesByPeriodsFromUsersTable = (appliedFilters, data) => {
  const [periodOptionsList, setPeriodOptionsList] = React.useState([]);
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    label: "Selecione",
    value: "",
  });
  const [selectedPeriodData, setSelectedPeriodData] = React.useState([]);

  const { isTrapsByPeriodsListLoading } = useTrapsByPeriodsList();

  React.useEffect(() => {
    if (appliedFilters.year.value === "") return;

    const periodsOptions = [];
    const orderedPeriodsByBeginDate = [];

    if (appliedFilters.datePeriodType === "week") {
      if (appliedFilters.epidemiologicalWeeks.length === 0) {
        const allEpidemiologicalWeeks = getAllWeeksFromYear(
          String(appliedFilters.year.value)
        ).map(({ beginDate, endDate, label }) => {
          const formatedBeginDate = moment(beginDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString();

          const formatedEndDate = moment(endDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString();

          return {
            beginDate: formatedBeginDate,
            endDate: formatedEndDate,
            label: `${label}`,
            value: endDate,
          };
        });

        periodsOptions.push(...allEpidemiologicalWeeks);
        orderedPeriodsByBeginDate.push(...allEpidemiologicalWeeks);
      } else {
        const orderedPeriods = appliedFilters.epidemiologicalWeeks.sort(
          (a, b) => {
            const aBeginDate = moment(a.beginDate, "DD/MM/YYYY");
            const bBeginDate = moment(b.beginDate, "DD/MM/YYYY");

            return aBeginDate - bBeginDate;
          }
        );

        periodsOptions.push(...appliedFilters.epidemiologicalWeeks);
        orderedPeriodsByBeginDate.push(...orderedPeriods);
      }
    }

    if (appliedFilters.datePeriodType === "month") {
      if (appliedFilters.months.length === 0){
        periodsOptions.push(...monthsOptionsList);
        orderedPeriodsByBeginDate.push(...monthsOptionsList);
      }
      else {
        const orderedPeriods = appliedFilters.months.sort((a, b) => {
          const aMonth = a.value;
          const bMonth = b.value;

          return aMonth - bMonth;
        });

        periodsOptions.push(...appliedFilters.months);
        orderedPeriodsByBeginDate.push(...orderedPeriods);
      }
    }

    setPeriodOptionsList(periodsOptions);
    setSelectedPeriod(orderedPeriodsByBeginDate[0]);
  }, [appliedFilters]);

  React.useEffect(() => {
    if (selectedPeriod && selectedPeriod.value === "") return;

    const periodData = data.find(
      ({ periodName }) => periodName === selectedPeriod.label
    );

    if (!periodData) return;

    setSelectedPeriodData(periodData.usersActivities);
  }, [selectedPeriod, appliedFilters, data]);

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const tableColumns = [
    {
      name: "Usuário",
      selector: "userName",
      sortable: true,
    },
    {
      name: "Total de Instalações",
      selector: "totalInstallations",
      sortable: true,
    },
    {
      name: "Total de Manutenções",
      selector: "totalMaintenances",
      sortable: true,
    },
    {
      name: "Total de Desinstalações",
      selector: "totalUninstallations",
      sortable: true,
    },
  ];

  return {
    handlePeriodChange,
    isTrapsByPeriodsListLoading,
    periodOptionsList,
    selectedPeriod,
    selectedPeriodData,
    tableColumns,
  };
};

export const AcitivitiesByPeriodsFromUsersTable = ({
  appliedFilters,
  data,
}) => {
  const {
    handlePeriodChange,
    isTrapsByPeriodsListLoading,
    periodOptionsList,
    selectedPeriod,
    selectedPeriodData,
    tableColumns,
  } = useAcitivitiesByPeriodsFromUsersTable(appliedFilters, data);

  if (isTrapsByPeriodsListLoading)
    return (
      <TableLoadingSkeleton
        length={
          appliedFilters.epidemiologicalWeeks.length <= 10
            ? appliedFilters.epidemiologicalWeeks.length + 1
            : 11
        }
        variant="simple"
        tableColumns={6}
        tableColumnWidth="70px"
      />
    );

  return data && data.length > 0 ? (
    <Card>
      <CardHeader
        dataToDownload={data}
        headerText={
          <span>
            Total de atividades por usuários com {appliedFilters.trapType.label}{" "}
            <br />
            {appliedFilters.datePeriodType === "week" ? "na" : "em"}{" "}
            {selectedPeriod.label} em {appliedFilters.year.value}
          </span>
        }
        showExpandButton={false}
        showButtonsTooltips={true}
      >
        <div style={{ width: "130px" }}>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={false}
            value={selectedPeriod}
            options={periodOptionsList}
            onChange={(e) => handlePeriodChange(e)}
          />
        </div>
      </CardHeader>
      <CardContent>
        <Row>
          <Col>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive
              columns={tableColumns}
              data={selectedPeriodData}
              noDataComponent={"Nenhum registro encontrado."}
            />
          </Col>
        </Row>
      </CardContent>
      <CardFooter
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  ) : null;
};
