import React from "react";

import { Button, Col, Row } from "reactstrap";
import { SinanMap } from "./Maps/SinanMap";
import { useNotificationsList } from "./Hooks/useNotificationsList";
import { useAppliedFilters } from "./Hooks/useAppliedFilters";
import moment from "moment";
import { getUserData } from "../../../services/utils/auth";
import { notificiationsByFilters } from "../../../services/api/Sinan";
import { getAllWeeksFromYear } from "../../../services/utils/todayEpidemiologicalWeek";
import { toast } from "react-toastify";
import getWeeks from "../../../services/utils/epidemiologicalWeek";
import MapLoadingSkeleton from "../../../components/ui/Loading/MapLoadingSkeleton";
import DonutGraphicLoadingSkeleton from "../../../components/ui/Loading/DonutGraphicLoadingSkeleton";
import PyramidGraphicLoadingSkeleton from "../../../components/ui/Loading/PyramidGraphicLoadingSkeleton";
import { usePositiveTrapsFromLabList } from "./Hooks/usePositiveTrapsFromLabList";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { useFieldResultsList } from "./Hooks/useFieldResultsList";
import { useConfigurations } from "../../../hooks/useConfigurations";
import { CasesTotalizationTable } from "./Tables/CasesTotalizationTable";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { getColorByIndex } from "../../../services/utils/globalFunctions";
import { toPng } from "html-to-image";
import { useCasesByAreas } from "./Hooks/useCasesByAreas";
import { SinanMapContextProvider } from "./Contexts/SinanMapContext";
import { useSorotypesByAreas } from "./Hooks/useSorotypesByAreas";
import LoadingSpin from "react-loading-spin";

import { ExamTypesGraphic } from "./Graphics/ExamTypes/ExamTypesGraphic";
import { SorotypesGraphic } from "./Graphics/Sorotypes/SorotypesGraphic";
import { EvolutionGraphic } from "./Graphics/Evolution/EvolutionGraphic";
import { ClassificationGraphic } from "./Graphics/Classification/ClassificationGraphic";
import { GendersGraphic } from "./Graphics/Genders/GendersGraphic";
import { GeorreferencedGraphic } from "./Graphics/Georreferenced/GeorreferencedGraphic";
import { CasesHistoricalSeriesGraphicWrapper } from "./Graphics/CasesHistoricalSeries/CasesHistoricalSeriesGraphicWrapper";
import { ConfirmationCriteriaGraphic } from "./Graphics/ConfirmationCriteria/ConfirmationCriteriaGraphic";
import { AgeGroupsGraphicsWrapper } from "./Graphics/AgeGroups/AgeGroupsGraphicsWrapper";
import { CasesByNotifyingUnitsGraphic } from "./Graphics/CasesByNotifyingUnits/CasesByNotifyingUnitsGraphic";

const L = require("leaflet");

const useSinanPageGraphics = () => {
  const [periodLegend, setPeriodLegend] = React.useState("");
  const [labPositiveTrapsGroups, setLabPositiveTrapsGroups] = React.useState(
    []
  );
  const [fieldPositiveTrapsGroups, setFieldPositiveTrapsGroups] =
    React.useState([]);
  const [sinanPageConfigurations, setSinanPageConfigurations] = React.useState(
    []
  );
  const [isLoadingHistoricalSeriesCache, setIsLoadingHistoricalSeriesCache] =
    React.useState(false);
  const [isLoadingSorotypesOnMap, setIsLoadingSorotypesOnMap] =
    React.useState(false);
  const { appliedFilters } = useAppliedFilters();
  const { notificationsList, isNotificationsListLoading } =
    useNotificationsList();
  const { positiveTrapsFromLabList } = usePositiveTrapsFromLabList();
  const { fieldResultsList } = useFieldResultsList();
  const { configurations } = useConfigurations();
  const { casesByAreas } = useCasesByAreas();
  const { setSorotypesByAreas } = useSorotypesByAreas();

  const organizationId = getUserData("organizationId");

  React.useEffect(() => {
    if (!configurations) return;

    const SINAN_MODULE_ID = "85f27883-ca03-4e2a-b97e-6b2f89b555fc";

    const sinansConfigurations = configurations.configurations.filter(
      ({ module: { id } }) => id === SINAN_MODULE_ID
    );

    setSinanPageConfigurations(sinansConfigurations);
  }, [configurations]);

  React.useEffect(() => {
    let sinanCacheStorage = localStorage.getItem("SinanPageCache");

    if (!sinanCacheStorage)
      localStorage.setItem(
        "SinanPageCache",
        JSON.stringify({
          [organizationId]: [],
        })
      );

    sinanCacheStorage = localStorage.getItem("SinanPageCache");
    let sinanCacheStorageParsed = JSON.parse(sinanCacheStorage);

    if (!sinanCacheStorageParsed[organizationId]) {
      localStorage.setItem(
        "SinanPageCache",
        JSON.stringify({
          ...sinanCacheStorageParsed,
          [organizationId]: [],
        })
      );
    }

    sinanCacheStorage = localStorage.getItem("SinanPageCache");
    sinanCacheStorageParsed = JSON.parse(sinanCacheStorage);

    if (sinanCacheStorageParsed[organizationId].length === 0)
      fetchNotificationsCache();
  }, []);

  React.useEffect(() => {
    if (!positiveTrapsFromLabList || positiveTrapsFromLabList.length === 0)
      return;

    const labPositiveTrapsPointsGroups = generateLabPositiveTrapsGroups(
      positiveTrapsFromLabList
    );

    setLabPositiveTrapsGroups(labPositiveTrapsPointsGroups);
  }, [positiveTrapsFromLabList]);

  React.useEffect(() => {
    if (!fieldResultsList || fieldResultsList.length === 0) return;

    const fieldPositiveTrapsPointsGroups =
      generateFieldPositiveTrapsGroups(fieldResultsList);

    setFieldPositiveTrapsGroups(fieldPositiveTrapsPointsGroups);
  }, [fieldResultsList]);

  React.useEffect(() => {
    const legend = generatePeriodLegend(appliedFilters);
    setPeriodLegend(legend);
  }, [appliedFilters]);

  const fetchNotificationsCache = async () => {
    const results = [];
    const sinanCacheStorage = localStorage.getItem("SinanPageCache");
    const sinanCacheStorageParsed = JSON.parse(sinanCacheStorage);

    const previousYears = getWeeks().filter(
      ({ year }) => year !== String(new Date().getFullYear())
    );

    try {
      setIsLoadingHistoricalSeriesCache(true);

      for (const yearWeeks of previousYears) {
        const yearNotificationsList = await fetchNotificationsList(
          yearWeeks.year
        );

        const casesForGraphics = formatCasesForCache(yearNotificationsList);

        results.push(casesForGraphics);
      }

      const cachedNotifications = (sinanCacheStorageParsed[organizationId] =
        results);

      if (results.length > 0)
        toast.success(
          "Dados da série histórica carregados com sucesso. Recarregue a página para visualizá-los."
        );

      localStorage.setItem(
        "SinanPageCache",
        JSON.stringify({
          ...sinanCacheStorageParsed,
          [organizationId]: cachedNotifications,
        })
      );
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao carregar a série histórica de casos. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe."
      );
      console.error(error);
    } finally {
      setIsLoadingHistoricalSeriesCache(false);
    }
  };

  const fetchNotificationsList = async (year) => {
    const DEFAULT_ERROR_MESSAGE = "Ocorreu um erro ao buscar os dados de cache";

    const periods = getAllWeeksFromYear(String(year)).map(
      ({ beginDate, endDate, label }) => {
        const formatedBeginDate = moment(beginDate, "DD/MM/YYYY")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString();

        const formatedEndDate = moment(endDate, "DD/MM/YYYY")
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString();

        const periodLabel = `${label.replace("Semana ", "")}/${year}`;

        return {
          beginDate: formatedBeginDate,
          endDate: formatedEndDate,
          periodName: periodLabel,
        };
      }
    );

    const filtersToSend = {
      disease: 1,
      includeCasesByPersons: false,
      notifyingUnitsIds: [],
      organizationId,
      periods,
      referenceDate: 1,
      territorializationsIds: [],
    };

    try {
      const { data, status } = await notificiationsByFilters(filtersToSend);

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      return data;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }
    }
  };

  const formatCasesForCache = (data) => {
    const formatedCases = data.map(({ periodName, cases }) => {
      const result = {
        label: periodName,
        criterion: cases.criterion,
        evolution: cases.evolution,
        finalClassification: cases.finalClassification,
        Notificações: cases.notifications.cases,
        Confirmados: cases.confirmed.cases,
        "Sorotipo 1": cases.sorotype.one,
        "Sorotipo 2": cases.sorotype.two,
        "Sorotipo 3": cases.sorotype.three,
        "Sorotipo 4": cases.sorotype.for,
        PCR: cases.examsTypes.pcr,
        NS1: cases.examsTypes.nS1,
        Soro: cases.examsTypes.serum,
        Isolamento: cases.examsTypes.isolation,
      };

      return result;
    });

    return formatedCases;
  };

  const generateLabPositiveTrapsGroups = (positiveTrapsFromLabList) => {
    const result = [];

    positiveTrapsFromLabList.forEach(({ data, trapTypeName }) => {
      const groupPoints = {
        name: ARMADILHA_INFO[trapTypeName].apelido,
        points: new Map(),
      };

      data.forEach(({ trapsByPeriods }) => {
        trapsByPeriods.forEach(({ traps }) => {
          traps.forEach((trap) => {
            if (!groupPoints.points.has(trap.number)) {
              const trapPoint = {
                ...trap,
                icon: L.icon({
                  iconUrl: ARMADILHA_INFO[trapTypeName].icons["red"],
                  iconSize: [16, 16],
                }),
              };

              groupPoints.points.set(trap.number, trapPoint);
            }

            const previousTrap = groupPoints.points.get(trap.number);

            previousTrap.positiveFor.eggs.quantity +=
              trap.positiveFor.eggs.quantity;

            groupPoints.points.set(trap.number, previousTrap);
          });
        });
      });

      groupPoints.points = Array.from(groupPoints.points.values());

      result.push(groupPoints);
    });

    return result;
  };

  const generateFieldPositiveTrapsGroups = (fieldResultsList) => {
    const result = [];

    fieldResultsList.forEach(({ data, trapType }) => {
      const groupPoints = {
        name: ARMADILHA_INFO[trapType].apelido,
        trapTypeName: trapType,
        points: [],
      };

      data.forEach(({ periods }) => {
        periods.forEach(({ positiveTraps }) => {
          if (trapType === "armadilhaDisseminadoraInseticida") {
            const trapsPoints = positiveTraps["forLarvaOrPupa"].map((trap) => {
              return {
                ...trap,
                icon: L.icon({
                  iconUrl:
                    ARMADILHA_INFO["armadilhaDisseminadoraInseticida"].icons[
                      "red"
                    ],
                  iconSize: [16, 16],
                }),
              };
            });

            groupPoints.points.push(...trapsPoints);
          }

          if (trapType === "armadilhaMosquitoAdulto") {
            const trapsPoints = positiveTraps["forAedesAegypti"].map((trap) => {
              return {
                ...trap,
                icon: L.icon({
                  iconUrl:
                    ARMADILHA_INFO["armadilhaMosquitoAdulto"].icons["red"],
                  iconSize: [16, 16],
                }),
              };
            });

            groupPoints.points.push(...trapsPoints);
          }

          if (trapType === "armadilhaMosquitoAdulto2") {
            const trapsPoints = positiveTraps["forAedesAegypti"].map((trap) => {
              return {
                ...trap,
                icon: L.icon({
                  iconUrl:
                    ARMADILHA_INFO["armadilhaMosquitoAdulto2"].icons["red"],
                  iconSize: [16, 16],
                }),
              };
            });

            groupPoints.points.push(...trapsPoints);
          }

          if (trapType === "armadilhaOvos") {
            const trapsPoints = positiveTraps["forEgg"].map((trap) => {
              return {
                ...trap,
                icon: L.icon({
                  iconUrl: ARMADILHA_INFO["armadilhaOvos"].icons["red"],
                  iconSize: [16, 16],
                }),
              };
            });

            groupPoints.points.push(...trapsPoints);
          }
        });
      });

      result.push(groupPoints);
    });

    return result;
  };

  const generatePeriodLegend = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") {
      const result = `de ${moment(filters.beginDate).format(
        "DD/MM/YYYY"
      )} à ${moment(filters.endDate).format("DD/MM/YYYY")}`;

      return result;
    }

    const years = filters.years.map((year) => year.label);

    if (
      filters.datePeriodType === "week" &&
      filters.epidemiologicalWeeks.length > 0
    ) {
      if (filters.epidemiologicalWeeks.length === 1)
        return (
          <span>
            na {filters.epidemiologicalWeeks[0].label} <br />{" "}
            {years.length === 1 ? "em" : "nos anos"} {years.join(", ")}
          </span>
        );

      const sortedWeeksByBeginDate = filters.epidemiologicalWeeks.sort(
        (a, b) => {
          const aDate = moment(a.beginDate, "DD/MM/YYYY");
          const bDate = moment(b.beginDate, "DD/MM/YYYY");

          return aDate.isBefore(bDate) ? -1 : 1;
        }
      );

      const firstWeekByBeginDate = sortedWeeksByBeginDate[0];
      const lastWeekByBeginDate =
        sortedWeeksByBeginDate[sortedWeeksByBeginDate.length - 1];

      return (
        <span>
          da {firstWeekByBeginDate.label} à {lastWeekByBeginDate.label} <br />{" "}
          {years.length === 1 ? "em" : "nos anos"} {years.join(", ")}
        </span>
      );
    }

    if (filters.datePeriodType === "month" && filters.months.length > 0) {
      if (filters.months.length === 1)
        return (
          <span>
            em {filters.months[0].label}{" "}
            {years.length === 1 ? "de" : "nos anos"} {years.join(", ")}
          </span>
        );

      const sortedMonthsByAsc = filters.months.sort((a, b) => {
        return a.value - b.value;
      });

      const firstMonthByAsc = sortedMonthsByAsc[0];
      const lastMonthByAsc = sortedMonthsByAsc[sortedMonthsByAsc.length - 1];

      return (
        <span>
          de {firstMonthByAsc.label} à {lastMonthByAsc.label} <br />
          {years.length === 1 ? "em" : "nos anos"} {years.join(", ")}
        </span>
      );
    }

    return "";
  };

  return {
    notificationsList,
    sinanPageConfigurations,
    fieldPositiveTrapsGroups,
    isNotificationsListLoading,
    isLoadingHistoricalSeriesCache,
    labPositiveTrapsGroups,
    periodLegend,
    casesByAreas,
    setSorotypesByAreas,
    isLoadingSorotypesOnMap,
    setIsLoadingSorotypesOnMap,
  };
};

export const SinanPageGraphics = () => {
  const {
    casesByAreas,
    fieldPositiveTrapsGroups,
    isLoadingSorotypesOnMap,
    isLoadingHistoricalSeriesCache,
    isNotificationsListLoading,
    labPositiveTrapsGroups,
    notificationsList,
    periodLegend,
    setIsLoadingSorotypesOnMap,
    setSorotypesByAreas,
    sinanPageConfigurations,
  } = useSinanPageGraphics();

  if (isNotificationsListLoading)
    return (
      <section>
        <Row className="mb-4">
          <Col>
            <MapLoadingSkeleton heigth="45rem" />
          </Col>
          <Col>
            <MapLoadingSkeleton heigth="45rem" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <MapLoadingSkeleton />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <MapLoadingSkeleton />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
          <Col>
            <DonutGraphicLoadingSkeleton height="33rem" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xl={12}>
            <PyramidGraphicLoadingSkeleton />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <MapLoadingSkeleton />
          </Col>
        </Row>
      </section>
    );

  const scaleIconSize = (
    sorotypesSum,
    minimalSorotypeValue,
    maximalSorotypeValue
  ) => {
    const minimalIconSize = 25;
    const maximalIconSize = 80;

    if (minimalSorotypeValue === maximalSorotypeValue) {
      // Se todos os valores forem iguais, mantém o tamanho mínimo para evitar divisão por zero
      return minimalIconSize;
    }

    if (sorotypesSum === minimalSorotypeValue) return minimalIconSize;
    if (sorotypesSum === maximalSorotypeValue) return maximalIconSize;

    return Math.round(
      minimalIconSize +
        ((sorotypesSum - minimalSorotypeValue) /
          (maximalSorotypeValue - minimalSorotypeValue)) *
          (maximalIconSize - minimalIconSize)
    );
  };

  const handleGenerateGraphicsImage = async () => {
    setIsLoadingSorotypesOnMap(true);
    try {
      const allGraphics = Array.from(
        document.querySelectorAll(".sorotype-pie-chart")
      );

      const graphicsImages = await Promise.all(
        allGraphics.map((graphic) => {
          return toPng(graphic, {
            quality: 0.2,
            pixelRatio: 1,
          });
        })
      );

      let minimalSorotypeValue = Infinity;
      let maximalSorotypeValue = 0;

      casesByAreas.forEach(({ sorotypesSum }) => {
        if (sorotypesSum > 0 && sorotypesSum < minimalSorotypeValue) {
          minimalSorotypeValue = sorotypesSum;
        }

        if (sorotypesSum > maximalSorotypeValue) {
          maximalSorotypeValue = sorotypesSum;
        }
      });

      if (minimalSorotypeValue === Infinity) minimalSorotypeValue = 0;

      const result = casesByAreas.map((cases, index) => {
        return {
          ...cases,
          image: graphicsImages[index],
          iconSize: scaleIconSize(
            cases.sorotypesSum,
            minimalSorotypeValue,
            maximalSorotypeValue
          ),
        };
      });

      console.log("result", result);

      setSorotypesByAreas(result);
    } catch (error) {
      toast.error("Ocorreu um erro ao gerar os gráficos para as áreas");
      console.error(error);
    } finally {
      setIsLoadingSorotypesOnMap(false);
    }
  };

  return (
    <section>
      <SinanMapContextProvider>
        <Row className="mb-4">
          <Col>
            <SinanMap
              fieldPositiveTrapsGroups={fieldPositiveTrapsGroups}
              labPositiveTrapsGroups={labPositiveTrapsGroups}
              legend={periodLegend}
              variant="notifications"
            />
          </Col>
          <Col>
            <SinanMap
              fieldPositiveTrapsGroups={fieldPositiveTrapsGroups}
              labPositiveTrapsGroups={labPositiveTrapsGroups}
              legend={periodLegend}
              variant="confirmed"
            />
          </Col>
        </Row>
      </SinanMapContextProvider>
      {casesByAreas && casesByAreas.length > 0 && (
        <section>
          {console.log("casesByAreas", casesByAreas)}
          <Row className="mb-4">
            {casesByAreas.map(({ territorializationName, sorotypes }) => {
              return (
                <Col>
                  <div style={{ width: "100px", height: "100px" }}>
                    <span style={{ display: "block", textAlign: "center" }}>
                      {territorializationName}
                    </span>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart
                        width={80}
                        height={80}
                        className="sorotype-pie-chart"
                      >
                        <Pie
                          data={sorotypes}
                          cx="50%"
                          cy="50%"
                          outerRadius={35}
                          paddingAngle={2}
                          dataKey="value"
                        >
                          {sorotypes.map((_, index) => (
                            <Cell key={index} fill={getColorByIndex(index)} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="mb-4">
            <Col style={{ textAlign: "center" }}>
              {isLoadingSorotypesOnMap ? (
                <Button color="primary" disabled style={{ width: "50%" }}>
                  <LoadingSpin size={16} primaryColor={"#fff"} />
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => handleGenerateGraphicsImage()}
                  style={{ width: "50%" }}
                >
                  Visualizar no mapa os sorotipos
                </Button>
              )}
            </Col>
          </Row>
        </section>
      )}
      <CasesHistoricalSeriesGraphicWrapper
        isLoadingHistoricalSeriesCache={isLoadingHistoricalSeriesCache}
        configurations={sinanPageConfigurations.find(
          ({ name }) => name === "yearsToNotConsiderOnControlDiagram"
        )}
        notificationsList={notificationsList.raw}
        periodLegend={periodLegend}
      />
      <Row className="mb-4">
        <Col xl={6}>
          <GendersGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
            variant="notifications"
          />
        </Col>
        <Col xl={6}>
          <GendersGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
            variant="confirmed"
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={4}>
          <ConfirmationCriteriaGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
          />
        </Col>
        <Col xl={4}>
          <ExamTypesGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
          />
        </Col>
        <Col xl={4}>
          <SorotypesGraphic
            data={notificationsList.formated}
            periodLegend={periodLegend}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={6}>
          <EvolutionGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
          />
        </Col>
        <Col xl={6}>
          <ClassificationGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={6}>
          <GeorreferencedGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
            variant="notifications"
          />
        </Col>
        <Col xl={6}>
          <GeorreferencedGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
            variant="confirmed"
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={12}>
          <AgeGroupsGraphicsWrapper
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={12}>
          <CasesByNotifyingUnitsGraphic
            notificationsList={notificationsList.formated}
            periodLegend={periodLegend}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xl={12}>
          <CasesTotalizationTable />
        </Col>
      </Row>
    </section>
  );
};
