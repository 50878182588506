import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import EntomologicoFilterPresentational from "../components/Presentational/Entomologico/EntomologicoFilterPresentational";
import ArmadilhasInstaladasContainer from "../components/Containers/Entomologico/Cards/ArmadilhasInstaladasContainer";
import { getNotificationsGeoRef } from "../services/api/Sinan";
import { getTrapEggNumbersFromLab, getTrapStatusNumbers, getTrapsToMap, getTrapsTypes, getActiveTrapsToMap, getActiveTraps } from "../services/api/Trap";
import VistoriasArmadilhasContainer from "../components/Containers/Entomologico/Cards/VistoriasArmadilhasContainer";
import { getUserData, setOrganization } from "../services/utils/auth";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import OvosLineContainer from "../components/Containers/Entomologico/Charts/Line/OvosLineContainer";
import TotalOvosColetadosContainer from "../components/Containers/Entomologico/Cards/TotalOvosColetadosContainer";
import ArmadilhasPositivasContainer from "../components/Containers/Entomologico/Cards/ArmadilhasPositivasContainer";
import MediaOvosContainer from "../components/Containers/Entomologico/Cards/MediaOvosContainer";
import PalhetasExaminadasContainer from "../components/Containers/Entomologico/Cards/PalhetasExaminadasContainer";
import { buildEpidemiologicalLabels } from "../services/utils/Entomologico/Labels";
import { getEpidemiologicalPeriod } from "../services/utils/Entomologico/Period";
import PositivesMapContainer from "../components/Containers/Entomologico/Maps/PositivesMapContainer";
import IDOMapContainer from "../components/Containers/Entomologico/Maps/IDOMapContainer";
import ActiveMapContainer from "../components/Containers/Entomologico/Maps/ActiveMapContainer";
import MainPeriodComponent from "../components/Inputs/MainPeriodComponent";
import PendentsVistoriedContainer from "../components/Containers/Entomologico/Charts/Line/PendentsVistoriedContainer";
import PendentsVistoriedMapContainer from "../components/Containers/Entomologico/Maps/PendentsVistoriedMapContainer";
import getWeeks from "../services/utils/epidemiologicalWeek";
import ArmadilhasVistoriadasContainer from "../components/Containers/Entomologico/Cards/ArmadilhasVistoriadasContainer";
import LoadingSpin from "react-loading-spin";
import VisitOperacionalFilterPresentational from "../components/Presentational/Operacional/VisitOperacionalFilterPresentational";
import GeographicFilterPresentational from "../components/Presentational/Filters/GeographicFilterPresentational";
import { ARMADILHA_INFO } from "../constants/ArmadilhaConstant";
import { getVersion } from "../constants/VersionConstant";
import { fetchAnimalGenres, fetchAnimalSpecies } from "../services/api/AnimalGroups";
import "./DashboardEntomologico.css";
import IMOMapContainer from "../components/Containers/Entomologico/Maps/IMOMapContainer";
import { getTodayEpidemiologicalWeek } from "../services/utils/todayEpidemiologicalWeek";
import TodayEpidemiologicalWeekComponent from "../components/Containers/Entomologico/todayEpidemiologicalWeek/TodayEpidemiologicalWeekComponent";
import { Select } from "../components/Wrappers/SelectAll";
import { getProjects } from "../services/api/project";
import CardLoadingSkeleton from "../components/ui/Loading/CardLoadingSkeleton";
import MapLoadingSkeleton from "../components/ui/Loading/MapLoadingSkeleton";
import EntomologicChartContainer from "../components/Containers/Entomologico/Charts/Line/EntomologicChartContainer";
import xlsx from "json-as-xlsx";

const DashboardEntomologico = ({ demandList, employeeList, teamList, territorializationList }) => {
  const [activeNav, setActiveNav] = useState(1);
  const [project, setProject] = React.useState(null);
  const [projectOptions, setProjectOptions] = React.useState("");
  const [isTrapStatusLoading, setTrapStatusLoading] = useState(false);
  const [isTrapMapLoading, setTrapMapLoading] = useState(false);
  const [isActiveTrapMapLoading, setActiveTrapMapLoading] = useState(false);
  const [isTrapLabLoading, setTrapLabLoading] = useState(false);
  const [trapStatusNumbers, setTrapStatusNumbers] = useState([]);
  const [dataGeoRef, setDataGeoRef] = useState([]);
  const [dataGeoRefLoading, setDataGeoRefLoading] = useState([]);
  const [trapSampleNumbers, setTrapSampleNumbers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [webVersion, setWebVersion] = useState("normal_version");
  const [endDate, setEndDate] = useState("");
  const [trapTypeAUX, setTrapTypeAUX] = useState({ trapTypeId: "00000000-0000-0000-0000-000000000000", trapTypeName: "Ovitrampa", trapName: "armadilhaOvos" });
  const [trapType, setTrapType] = useState({ trapTypeId: "00000000-0000-0000-0000-000000000000", trapTypeName: "Ovitrampa", trapName: "armadilhaOvos" });
  const [trapTypes, setTrapTypes] = useState([{ trapTypeId: "00000000-0000-0000-0000-000000000000", trapTypeName: "Ovitrampa", trapName: "armadilhaOvos" }]);
  const [trapTypeOptions, setTrapTypeOptions] = useState([]);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [selectedSpecie, setSelectedSpecie] = useState(null)
  const [TrapMapInfo, setTrapMapInfo] = useState(null);
  const [ActiveTrapMapInfo, setActiveTrapMapInfo] = useState(null);
  const [PreviousTrapMapInfo, setPreviousTrapMapInfo] = useState(null);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartPeriods, setChartPeriods] = useState([]);
  const [isFilterVisible, setFilterVisibility] = useState(true);
  const [months, setFilterMonths] = useState([]);
  const [year, setFilterYear] = useState([]);
  const [weeks, setFilterWeeks] = useState([]);
  const [weekValue1, setWeekValue1] = useState([]);
  const [weekValue2, setWeekValue2] = useState([]);
  const [demand, setFilterDemand] = useState("00000000-0000-0000-0000-000000000000");
  const [employee, setFilterEmployee] = useState("00000000-0000-0000-0000-000000000000");
  const [team, setFilterTeam] = useState("00000000-0000-0000-0000-000000000000");
  const [selectedTerritorializations, setSelectedTerritorializations] = useState([]);
  const teamEmployees = useRef([]);
  const [todayEpidemiologicalWeek, setTodayEpidemiologicalWeek] = useState("1");

  // ChartDatasets
  const [maxDomainImo, setMaxDomainImo] = useState();
  const [chartDataSetsPendentsVistoried, setChartDataSetsPendentsVistoried] = useState();
  const [entomologicInformations, setEntomologicInformations] = useState();
  const [chartDataSetsImo, setChartDataSetsImo] = useState();
  const [chartDataSetsIdo, setChartDataSetsIdo] = useState();
  const [chartDataSetsIpo, setChartDataSetsIpo] = useState();
  const [chartDataSetsIpa, setChartDataSetsIpa] = useState();
  const [isGraphsLoading, setIsGraphsLoading] = useState(false);
  const [graphicType, setGraphicType] = useState({
    IP: "IPO",
    ID: "IDO",
    IM: "IMO"
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const getStartDate = () => {
    const date = new Date();
  
    date.setDate(date.getDate() - 30);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };  

  const getEndDate = () => {
    const date = new Date();
    const endDateString = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

    return endDateString;
  }

  

  const handleDownloadEntomologicInformations = (comparativeTerritorialization = true) => {
    var xlsxDataset = entomologicInformations;

    const xlsxFileSettings = {
      fileName: "Informacoes_entomologicas_VITEC",
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false,
    };

    if(!comparativeTerritorialization) {
      const groupedByWeek = entomologicInformations.reduce((acc, curr) => {
        const week = curr.label;
      
        if (!acc[week]) {
          acc[week] = {
            label: week,
            actives: 0,
            eggs: 0,
            examinated: 0,
            vistories: 0,
            positives: 0,
            diagnosticPercent: 0,
            vistoryToChart: 0,
            IM: 0,
            ID: 0,
            IP: 0,
          };
        }
      
        // Acumular os valores
        acc[week].actives += curr.actives;
        acc[week].eggs += curr.eggs;
        acc[week].examinated += curr.examinated;
        acc[week].vistories += curr.vistories;
        acc[week].positives += curr.positives;
      
        return acc;
      }, {});

      // const ipo = examinated !== 0 ? ((positives / examinated) * 100).toFixed(1) : 0;
      // const ipa = vistories !== 0 ? ((positives / vistories) * 100).toFixed(1) : 0;
      // const ido = positives !== 0 ? (eggs / positives).toFixed(1) : 0;
      // const imo = examinated !== 0 ? (eggs / examinated).toFixed(1) : 0;
      // const vistoryToChart = actives > 0 ? parseFloat(((vistories / actives) * 100).toFixed(1)) : 0;
      
      const result = Object.values(groupedByWeek).map(week => {
        return {
          ...week,
          vistoryToChart: week.actives ? parseFloat(((week.vistories / week.actives) * 100).toFixed(1)) : 0,
          diagnosticPercent: week.examinated ? parseFloat(((week.examinated / week.vistories) * 100).toFixed(2)) : 0,
          IPA: week.vistories !== 0 ? ((week.positives / week.vistories) * 100).toFixed(1) : 0,
          IP: week.examinated ? parseFloat(((week.positives / week.examinated) * 100).toFixed(1)) : 0,
          ID: week.positives ? parseFloat((week.eggs / week.positives).toFixed(2)) : 0,
          IM: week.examinated ? parseFloat((week.eggs / week.examinated).toFixed(2)) : 0
        };
      });

      xlsxDataset = result;
      
      console.log("entomologicInformations result 2", result);
      
    }

    // datasetEntomologicInformations.push({
    //   "territorializationName": territorializationName,
    //   "label": chartLabels[index],
    //   "actives": actives,
    //   "diagnosticPercent": vistories ? (examinated / vistories) * 100 : 0,
    //   "vistoryToChart": vistoryToChart,
    //   "eggs": eggs,
    //   "examinated": examinated,
    //   "vistories": vistories,
    //   "positives": positives,
    //   "ano": trapSampleNumbers[ano].label ? trapSampleNumbers[ano].label : null,
    //   "IM": parseFloat(imo),
    //   "ID": parseFloat(ido),
    //   "IP": parseFloat(ipo)
    // });

    var columns = [
      { label: "Semana epidemiológica trabalhada", value: "label" },
      { label: "Nº de armadilhas instaladas", value: "actives" },
      { label: "Nº de armadilhas vistoriadas", value: "vistories" },
      { label: "Armadilhas diagnosticadas", value: "examinated" },
      { label: "Armadilhas diagnosticadas positivas", value: "positives" }
    ];

    if(trapType.trapTypeName == "Adultrap") {
      columns.push({ label: "Quantidade de mosquitos", value: "eggs" })
      columns.push({ label: "% de armadilhas vistoriadas", value: "vistoryToChart" })
      columns.push({ label: "IPA % mosquitos adultos positivos", value: "IPA" })
      columns.push({ label: "IDA (Num. de mosquitos por armadilha positiva)", value: "ID" })
      columns.push({ label: "IMA", value: "IM" })
    } else {
      columns.push({ label: "Quantidade de ovos", value: "eggs" })
      columns.push({ label: "% de armadilhas vistoriadas", value: "vistoryToChart" })
      columns.push({ label: "% de amostras diagnosticadas (palhetas)", value: "diagnosticPercent" })
      columns.push({ label: "IPO % Ovitrampas positivas", value: "IP" })
      columns.push({ label: "IDO (Num. de ovos por armadilha positiva)", value: "ID" })
      columns.push({ label: "IMO", value: "IM" })
    }


    if(comparativeTerritorialization) {
      columns.unshift({ label: "Territorialização", value: "territorializationName" });
    }

    xlsx(
      [
        {
          sheet: "Informacoes_entomologicas",
          columns,
          content: xlsxDataset,
        },
      ],
      xlsxFileSettings
    );
  };

  useEffect(() => {
    var orgId = getUserData("organizationId");
    setOrganizationId(orgId);

    var version = getVersion(orgId);
    setWebVersion(version);

    setStartDate(getStartDate());

    setTodayEpidemiologicalWeek(getTodayEpidemiologicalWeek());

    setEndDate(getEndDate());

    var projects = getProjects();

    projects.then(response => {
      var projectOptionsFormated = [];
      if(response.data) {
        response.data.map((project) => {
          projectOptionsFormated.push({
            value: project.id,
            label: project.name
          })
        })
      }
      setProjectOptions(projectOptionsFormated);
    })    

    const reqTrapTypes = getTrapsTypes();
    reqTrapTypes.then(response => {
      setTrapTypeOptions(response.data);
      if (response.data.length > 0) {
        let index;

        if (getUserData("organizationId") === "24d125ef-15e6-4bd9-9111-c0b3d50a8009")
          index = response.data.findIndex(tpType => tpType.name === 'armadilhaDisseminadoraInseticida');
        else 
          index = response.data.findIndex(tpType => tpType.name === 'armadilhaOvos');

        var trap = { trapTypeId: response.data[index].id, trapTypeName: ARMADILHA_INFO[response.data[index].name].apelido, trapName: response.data[index].name };
        setTrapType(trap)

        let traps = [];
        response.data.map((trap) => {
          if(trap.name){
            traps.push({trapTypeId: trap.id, trapTypeName: ARMADILHA_INFO[trap.name].apelido, trapName: trap.name })
          }
        })
        setTrapTypes(traps)
        
      }

      applyFilter();
    });

    

    const species = [];
    fetchAnimalGenres("178886c4-a41c-4d15-ac3c-a58806bb8df9").then(async (response) => {
      for (const genre of response.data) {
        let speciesRequest = await fetchAnimalSpecies(genre.id);
        for (const specie of speciesRequest.data) {
          species.push({
            label: specie.name,
            value: specie.id
          })
        }
      }

      setSpeciesOptions(species)
    })

  }, [])

  useEffect(() => {
    if (
      trapSampleNumbers.length > 0 &&
      trapStatusNumbers.length > 0 &&
      !isTrapLabLoading &&
      !isTrapStatusLoading
    ) {
      const datasetImo = [];
      const datasetIdo = [];
      const datasetIpo = [];
      const datasetIpa = [];
      const datasetEntomologicInformations = [];
      const datasetPendentsVistoried = [];
      var maiorValor = 100;
  
      for (const index in chartLabels) {
        const dataSetObjectImo = { label: chartLabels[index] };
        const dataSetObjectIdo = { label: chartLabels[index] };
        const dataSetObjectIpo = { label: chartLabels[index] };
        const dataSetObjectIpa = { label: chartLabels[index] };
        const dataSetObjectPendentsVistoried = { label: chartLabels[index] };
  
        for (let ano in trapSampleNumbers) {
          for (let anoPerTerritorialization in trapSampleNumbers[ano][index]) {
            if (
              trapSampleNumbers[ano][index][anoPerTerritorialization] &&
              trapStatusNumbers[ano][index][anoPerTerritorialization]
            ) {
              let { ovos: eggs, positive: positives, examinated, territorializationName } =
                trapSampleNumbers[ano][index][anoPerTerritorialization];
              const { actives, inspections, vistories } =
                trapStatusNumbers[ano][index][anoPerTerritorialization];
  
              const vistoryToChart = actives > 0 ? parseFloat(((vistories / actives) * 100).toFixed(1)) : 0;

              if (!dataSetObjectPendentsVistoried[territorializationName]) {
                dataSetObjectPendentsVistoried[territorializationName] = {};
              }
              
              if(dataSetObjectPendentsVistoried["vistories"]) {
                dataSetObjectPendentsVistoried["vistories"] += vistories;
              } else {
                dataSetObjectPendentsVistoried["vistories"] = vistories;
              }

              if(dataSetObjectPendentsVistoried["actives"] ) {
                dataSetObjectPendentsVistoried["actives"]  += actives;
              } else {
                dataSetObjectPendentsVistoried["actives"]  = actives;
              }

              if (trapStatusNumbers[ano].label) {
                dataSetObjectPendentsVistoried[trapStatusNumbers[ano].label] = vistoryToChart;
              } else {
                dataSetObjectPendentsVistoried["Vistoriadas"] = vistoryToChart;
              }
  
              const ipo = examinated !== 0 ? ((positives / examinated) * 100).toFixed(1) : 0;
              const ipa = vistories !== 0 ? ((positives / vistories) * 100).toFixed(1) : 0;
              const ido = positives !== 0 ? (eggs / positives).toFixed(1) : 0;
              const imo = examinated !== 0 ? (eggs / examinated).toFixed(1) : 0;
  
              if (parseFloat(imo) > parseFloat(maiorValor)) {
                maiorValor = imo;
              }

              datasetEntomologicInformations.push({
                "territorializationName": territorializationName,
                "label": chartLabels[index],
                "actives": actives,
                "diagnosticPercent": vistories ? (examinated / vistories) * 100 : 0,
                "vistoryToChart": vistoryToChart,
                "eggs": eggs,
                "examinated": examinated,
                "vistories": vistories,
                "positives": positives,
                "ano": trapSampleNumbers[ano].label ? trapSampleNumbers[ano].label : null,
                "IM": parseFloat(imo),
                "ID": parseFloat(ido),
                "IP": parseFloat(ipo),
                "IPA": parseFloat(ipa)
              });
  
              if (!dataSetObjectImo[territorializationName]) {
                dataSetObjectImo[territorializationName] = {
                  examinated,
                  eggs,
                  [trapSampleNumbers[ano].label || "IM"]: imo,
                };
              } else {
                dataSetObjectImo[territorializationName][trapSampleNumbers[ano].label || "IM"] = imo;
              }

              if (!dataSetObjectIdo[territorializationName]) {
                dataSetObjectIdo[territorializationName] = {
                  eggs,
                  positives,
                  [trapSampleNumbers[ano].label || "ID"]: parseFloat(ido),
                };
              } else {
                dataSetObjectIdo[territorializationName][trapSampleNumbers[ano].label || "ID"] = parseFloat(ido);
              }

              if (!dataSetObjectIpo[territorializationName]) {
                dataSetObjectIpo[territorializationName] = {
                  positives,
                  examinated,
                  [trapSampleNumbers[ano].label || "IP"]: ipo,
                };
              } else {
                dataSetObjectIpo[territorializationName][trapSampleNumbers[ano].label || "IP"] = ipo;
              }

              if (!dataSetObjectIpa[territorializationName]) {
                dataSetObjectIpa[territorializationName] = {
                  positives,
                  vistories,
                  [trapSampleNumbers[ano].label || "IP"]: ipa,
                };
              } else {
                dataSetObjectIpa[territorializationName][trapSampleNumbers[ano].label || "IP"] = ipa;
              } 

            }
          }
        }

        datasetPendentsVistoried.push(dataSetObjectPendentsVistoried);
        datasetIdo.push(dataSetObjectIdo);
        datasetImo.push(dataSetObjectImo);
        datasetIpo.push(dataSetObjectIpo);
        datasetIpa.push(dataSetObjectIpa);
      }
      maiorValor = parseInt(maiorValor);
      setMaxDomainImo(maiorValor);
      setEntomologicInformations(datasetEntomologicInformations);
      setChartDataSetsImo(datasetImo);
      setChartDataSetsPendentsVistoried(datasetPendentsVistoried);
      setChartDataSetsIdo(datasetIdo);
      setChartDataSetsIpo(datasetIpo);
      setChartDataSetsIpa(datasetIpa);
  
      setIsGraphsLoading(false);
    }
  }, [isTrapLabLoading, isTrapStatusLoading]);
                

  useEffect(() => {
    if (chartLabels.length > 0) {
      fetchData();
    }
  }, [chartLabels]);

  useEffect(() => {
    if(trapType.trapTypeName == "ADI") {
      setGraphicType({
        IP: "IPL",
        ID: "IDL",
        IM: "IML"
      })
    } else {
      setGraphicType({
        IP: "IPO",
        ID: "IDO",
        IM: "IMO"        
      })
    }
  }, [trapType])

  async function applyFilter() {
    if (year.length > 0) {
      setTrapLabLoading(true);
      setTrapStatusLoading(true);
      setTrapMapLoading(true);
      setActiveTrapMapLoading(true);
      buildYearsLabelsAndPeriods(year);
    } else {
      setTrapLabLoading(true);
      setTrapStatusLoading(true);
      setTrapMapLoading(true);
      setActiveTrapMapLoading(true);
      var startDateAux = getStartDate();
      var endDateAux = getEndDate();
      buildLabelsAndPeriods(startDateAux, endDateAux);
    }
    
    
  }

  function changePeriod(e, field) {
    switch (field) {
      case 'Year':
        if (e === null) {
          setFilterYear([]);
          setFilterMonths([]);
          setFilterWeeks([]);
          break;
        } else {
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterYear(e !== null ? e : []);
        break;

      case 'Month':
        if (e !== null) {
          setFilterWeeks([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterMonths(e !== null ? e : []);
        break;

      case 'EpiWeek':
        if (e !== null) {
          setFilterMonths([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterWeeks(e !== null ? e : []);
        break;

      case 'Week1':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue1(e !== null ? e : []);
        break;

      case 'Week2':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue2(e !== null ? e : []);
        break;
    }
  }

  function handleChangeFilter(field, value) {
    switch (field) {
      case "startDate":
        if (moment(value).isAfter(endDate)) {
          toast.error("Período de data inválido!");
        } else {
          setStartDate(value);
        }
        break;
      case "endDate":
        if (moment(value).isBefore(startDate)) {
          toast.error("Período de data inválido!");
        } else {
          setEndDate(value);
        }
        break;
      case "trapType":
        const selectedTrapType = trapTypeOptions.filter(tp => tp.id == value);

        //setando a especie para aedes aegypti se marcar adultrap
        if (ARMADILHA_INFO[selectedTrapType[0].name].apelido == "Adultrap" || ARMADILHA_INFO[selectedTrapType[0].name].apelido == "BG-GAT" || ARMADILHA_INFO[selectedTrapType[0].name].apelido == "ADI") {
          setSelectedSpecie('3ce3a48f-9555-4b58-8bb9-18c8a4c7549c')
        } else {
          setSelectedSpecie(null)
        }

        setTrapTypeAUX({ trapTypeId: selectedTrapType[0].id, trapTypeName: ARMADILHA_INFO[selectedTrapType[0].name].apelido, trapName: selectedTrapType[0].name });
        toast.info("Tipo de armadilha alterado! Clique no botão para filtrar novamente.");
        break;
      case "demandId":
        if (value) {
          setFilterDemand(value.value);
        } else {
          setFilterDemand("00000000-0000-0000-0000-000000000000")
        }
        break;
      case "teamId":
        if (value) {
          let employeeUserIds = new Array();
          for (const member of value.members) {
            if (member.userId) {
              employeeUserIds.push(member.userId);
            }
          }
          teamEmployees.current = employeeUserIds;
        } else {
          teamEmployees.current = new Array();
        }

        setFilterTeam(value);
        break;
      case "employeeId":
        if (value) {
          setFilterEmployee(value.value);
        } else {
          setFilterEmployee("00000000-0000-0000-0000-000000000000")
        }
        break;
      case "territorialization":
        if (value !== null) {
          //colocando as territorializações para ficarem selecionadas no filtro.
          // [
          //   { "label": "051", "value": "cd147761-39ee-49ac-b13a-f6f9edd08392" },
          //   { "label": "055", "value": "381d3a1a-4d4a-4de0-8b25-a465f4658858" },
          //   { "label": "039", "value": "02322dc3-3b3f-46e0-bc91-47548530a674" },
          //   { "label": "056", "value": "f8cabb8c-020f-406a-9a3d-289522bb4d9d" },
          //   { "label": "067", "value": "a383b2d1-4ffe-4c8c-9639-4e3417137939" },
          //   { "label": "069", "value": "0a04367c-784e-44ed-b6e1-0e8e220ed104" },
          //   { "label": "057", "value": "81348091-9595-4e16-b6b9-cc03f5547171" },
          //   { "label": "058", "value": "4b0382b4-c2dd-4d7a-b98b-e51eb33e299b" },
          //   { "label": "034", "value": "d0756da4-59f5-4a4f-947b-1d0ca82f5a0e" },
          //   { "label": "059", "value": "34980eab-8d62-461d-b68d-80c86fe619ae" },
          //   { "label": "071", "value": "46fe8dca-3afb-4786-a8bb-7dd982c02234" },
          //   { "label": "068", "value": "a31a5f0f-8560-4b48-b112-d90586a4db4c" },
          //   { "label": "019", "value": "efab4ea1-723c-451f-b6df-3fa94b4e5051" },
          //   { "label": "076", "value": "abaaa7cd-6864-44eb-8775-3440db869a4f" },
          //   { "label": "065", "value": "5780304f-2f94-4f42-b594-da22401e5a87" },
          //   { "label": "079", "value": "0dff7a19-d981-4540-ac67-6622a407d373" },
          //   { "label": "081", "value": "0618112a-1eae-43e2-b742-14a86652f06a" },
          //   { "label": "013", "value": "d9f3c41d-3e25-4760-ab3b-929bd434261c" },
          //   { "label": "085", "value": "78cc10c5-5e88-450e-b6e8-fcb935eb1552" },
          //   { "label": "027", "value": "2686bae1-497f-4593-9657-658c0014b03c" },
          //   { "label": "017", "value": "0bf49700-fa78-4779-a69e-986c02ec3984" },
          //   { "label": "038", "value": "7f0fa83b-cdb7-45b3-920d-03e514ef4109" },
          //   { "label": "078", "value": "1888b05e-afd3-4fb3-8c9d-6b0d7f67bcdb" },
          //   { "label": "083", "value": "c5cd409f-df2b-4c74-bd61-b0aa8ae7e4d3" },
          //   { "label": "088", "value": "d21ea9dc-a11e-475d-9e04-6324bdbb7f54" },
          //   { "label": "AR2", "value": "54f982cf-6ae0-49ba-88e5-80eafe931f5d" },
          //   { "label": "007", "value": "ef309db8-29ac-48e1-82d8-94096c4c93fa" },
          //   { "label": "AR3", "value": "b894a2d2-3b37-4208-97ca-5fe3563749fe" },
          //   { "label": "042", "value": "208636c6-aeb6-4034-8d99-134e89ebfdd8" },
          //   { "label": "102", "value": "81c94d89-736f-4f79-b293-c85bccf4d10d" },
          //   { "label": "089", "value": "d763dd63-1f4b-479a-a3ca-293b11c8bd01" },
          //   { "label": "100", "value": "c7567735-ec74-43a6-9603-4aadc5e56de6" },
          //   { "label": "AR1", "value": "5a8355ce-8404-41b8-80ba-7c7ebe1150af" },
          //   { "label": "045", "value": "1c75e94d-c730-458f-8d9d-0403b82b2a74" },
          //   { "label": "002", "value": "a22fe3f4-9345-42bb-8e1f-25a08f17a333" },
          //   { "label": "AR5", "value": "67421f4e-db67-4980-a3b4-3780c562f32f" },
          //   { "label": "053", "value": "d9249a54-8d9f-49b0-baee-06f891d89a97" },
          //   { "label": "032", "value": "a9314073-f83c-4a88-802d-10b12bc968a0" },
          //   { "label": "026", "value": "604401b4-28b1-417c-bec2-b4284ad13c7f" },
          //   { "label": "072", "value": "afcd5e59-48ad-4397-b25d-8ee4d8e5dcf1" },
          //   { "label": "049", "value": "983a5652-199a-46fd-9359-d1fe17462ae3" },
          //   { "label": "025", "value": "8f3d4f8a-3f29-4e84-bd04-f803b27e82df" },
          //   { "label": "012", "value": "97a9cf96-d83d-4702-8436-022670e268c6" },
          //   { "label": "101", "value": "6eda3f6d-3393-4590-b767-d875aa453e31" },
          //   { "label": "029", "value": "5f18be56-80ee-4c6f-b076-9dbf133a8d7d" },
          //   { "label": "050", "value": "5569f5b0-c820-4505-bf3b-5bda452a1a64" },
          //   { "label": "077", "value": "ab2b1bbf-0c1e-47c9-914a-214b7d80aa62" },
          //   { "label": "073", "value": "76951321-82c2-4518-aa91-ce0d51ba8d45" },
          //   { "label": "060", "value": "e48704dc-11b3-4a53-a6e0-d65ce43914bc" },
          //   { "label": "018", "value": "bf7978e3-6b92-4c19-8ceb-8c7f55b3cce3" },
          //   { "label": "080", "value": "2ef3f2db-4334-4496-96f2-fa973831e515" },
          //   { "label": "048", "value": "c962396c-030e-4812-9c0a-199a92ac9538" },
          //   { "label": "035", "value": "cdcc0619-5eb6-4699-aab6-84ba716ffa12" },
          //   { "label": "014", "value": "c61b0c6d-e77d-4ef2-9512-6a8779ecf70b" },
          //   { "label": "061", "value": "faf28610-901e-4277-acd3-e26c16ec3645" },
          //   { "label": "011", "value": "ce2e4bd9-cbae-4e48-b17a-0d4ef1cf7fe5" },
          //   { "label": "040", "value": "5f4b04e9-c340-4030-bb84-0866ad9f9199" },
          //   { "label": "046", "value": "702e3edf-59fb-41c4-83e2-474bb217c9a9" },
          //   { "label": "043", "value": "ffba5345-61f1-48ff-beb7-17eda8656dda" },
          //   { "label": "AR4", "value": "15d73f75-8e9c-4d14-adda-3dad4202df2f" },
          //   { "label": "003", "value": "f1e950d0-8f73-4f38-b98d-66a45b9477a3" },
          //   { "label": "009", "value": "33401100-b606-46c3-956c-6fec9de63333" },
          //   { "label": "015", "value": "3915a366-2e86-4d33-b047-6b9e8f2f03f9" },
          //   { "label": "023", "value": "9e4275fa-73fd-44d8-879a-230bc0fb2467" },
          //   { "label": "006", "value": "f43f1f00-3284-4d71-a2f7-3bf3c37e2cbf" },
          //   { "label": "016", "value": "dcc7c271-47c3-4ac4-9e49-b9bc8507e347" },
          //   { "label": "022", "value": "9bf78b2b-b221-4cb3-8ed2-aabd49b8f4c4" },
          //   { "label": "044", "value": "3e336d5e-aff7-448f-9afe-7139bb5aac5d" },
          //   { "label": "052", "value": "2cd30610-32ea-4a51-a769-7e450e1b3ee0" },
          //   { "label": "030", "value": "d133ecc6-dd9a-4068-87f4-c0186c7e7630" },
          //   { "label": "024", "value": "ed703aad-ed15-40d9-a910-d9525aaea02f" },
          //   { "label": "037", "value": "7f3c1ad7-f862-497b-904c-ede071068d7e" },
          //   { "label": "041", "value": "b5a26fd1-cd57-408d-bab2-917c368e8319" },
          //   { "label": "028", "value": "2ac72018-2d9f-4d8a-ae8d-2f8c8437ead4" },
          //   { "label": "031", "value": "4dcebf30-209c-40d7-b20c-42e751a62986" },
          //   { "label": "001", "value": "9272f5d0-1aa8-44d9-9dd9-36e8ae14751a" },
          //   { "label": "047", "value": "dadb2d21-0025-4dc8-aece-493854a82c66" },
          //   { "label": "054", "value": "baa4d90e-b609-4b2e-bc90-a5d814c858c0" }
          // ]
          setSelectedTerritorializations(value);
        } else {
          setSelectedTerritorializations([]);
        }
        break;
      case "specie":
        setSelectedSpecie(value)
        break;
    }
  }

  async function fetchTrapToMap(employees){
    const organizationId = await getUserData("organizationId");
    let filterPeriod = null;
    let previousFilterPeriod = null;

    const currentDate = moment();

    var arrayProjects = await getProjectsId();

    for (let i = chartPeriods.length - 1; i >= 0; i--) {
      if (filterPeriod === null) {
        const periods = chartPeriods[i].periods;
        for (let j = periods.length - 1; j >= 0; j--) {
          if (currentDate.isBetween(moment(periods[j].beginDate), moment(periods[j].finalDate)) || currentDate.isSameOrAfter(moment(periods[j].finalDate))) {
            filterPeriod = periods[j];
            previousFilterPeriod = periods[j - 1];
            break;
          }
        }
      } else {
        break;
      }

      if (filterPeriod === null && i === 0) {
        filterPeriod = { beginDate: currentDate.format("YYYY-MM-DD"), finalDate: currentDate.format("YYYY-MM-DD") + "T23:59:59"}
        break;
      }
    }

    const previousFilterTrapToMap = {
      ProjectsId: arrayProjects,
      organizationId,
      period: previousFilterPeriod,
      trapTypeId: trapType.trapTypeId,
      usersIds: employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees,
      demandId: demand,
      territorializations: selectedTerritorializations.map(({ value }) => value),
      specieId: selectedSpecie && selectedSpecie != "" ? selectedSpecie : null,
    }

    const filterTrapToMap = {
      ProjectsId: arrayProjects,
      organizationId,
      period: filterPeriod,
      trapTypeId: trapType.trapTypeId,
      usersIds: employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees,
      demandId: demand,
      territorializations: selectedTerritorializations.map(({ value }) => value),
      specieId: selectedSpecie && selectedSpecie != "" ? selectedSpecie : null,
    }

    const [trapsToMap, previousTrapsToMap] = await Promise.all([
      getTrapsToMap(filterTrapToMap), 
      getTrapsToMap(previousFilterTrapToMap),
    ]);

    setTrapMapInfo(trapsToMap.data);
    setPreviousTrapMapInfo(previousTrapsToMap.data);
  
    return true;
  }

  async function fetchActiveTrapToMap(employees) {
    let filterPeriod = null;
    let previousFilterPeriod = null;

    const currentDate = moment();

    for (let i = chartPeriods.length - 1; i >= 0; i--) {
      if (filterPeriod === null) {
        const periods = chartPeriods[i].periods;
        for (let j = periods.length - 1; j >= 0; j--) {
          if (currentDate.isBetween(moment(periods[j].beginDate), moment(periods[j].finalDate)) || currentDate.isSameOrAfter(moment(periods[j].finalDate))) {
            filterPeriod = periods[j];
            previousFilterPeriod = periods[j - 1];
            break;
          }
        }
      } else {
        break;
      }

      if (filterPeriod === null && i === 0) {
        filterPeriod = { beginDate: currentDate.format("YYYY-MM-DD"), finalDate: currentDate.format("YYYY-MM-DD") + "T23:59:59" }
        break;
      }
    }


    const activeTrapPromises = trapTypes.map(async (thisTrapType) => {
      let arrayProjects = await getProjectsId();

      const filterMap = {
        ProjectsId: arrayProjects,
        organizationId,
        period: filterPeriod,
        trapTypeId: thisTrapType.trapTypeId,
        usersIds: employees,
        demandId: demand,
        territorializations: selectedTerritorializations.map(({ value }) => value),
      };
  
      try {
        const response = await getActiveTrapsToMap(filterMap);
        return {data: response.data, trapType: thisTrapType.trapTypeName};
      } catch (error) {
        console.error(error);
        throw error;
      }
    });
  
    const activeTrap = await Promise.all(activeTrapPromises);

    setActiveTrapMapInfo(activeTrap);
    setActiveTrapMapLoading(false);

    // let objActiveTraps = {};

    // activeTrap.forEach((value, index) => {
    //   if (index > 0) {
    //     objActiveTraps.data.actives = objActiveTraps.actives.concat(value.data.actives);
    //   } else {
    //     objActiveTraps = value;
    //   }
    // });
  }

  async function getProjectsId() {
    var arrayProjects = [];

    if(project && project.length > 0) {
      project.map((objProject) => {
        arrayProjects.push(objProject.value)
      })
    }

    return arrayProjects;
  }

  async function fetchTrapEggNumbers(employees) {
    let arrayProjects = await getProjectsId();    

    const { trapTypeId } = trapType;
    for (const chartPeriod of chartPeriods) {
      chartPeriod.ProjectsId = arrayProjects;
      chartPeriod.organizationId = organizationId;
      chartPeriod.trapTypeId = trapTypeId;
      chartPeriod.usersIds = employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees;
      chartPeriod.demandId = demand;
      chartPeriod.territorializations = selectedTerritorializations.map(({ value }) => value);
    }

    const trapSampleRequest = await getTrapEggNumbersFromLab(chartPeriods);
    const trapSampleNumbers = trapSampleRequest.data;

    for (const index in trapSampleNumbers) {
      trapSampleNumbers[index].label = chartPeriods[index].year;
    }

    return trapSampleNumbers;
  }

  async function fetchDataGeoRef(){    
    const filter = [];

    for (const chartPeriod of chartPeriods) {
      filter.push({
        "year": chartPeriod.year,
        "periods": chartPeriod.periods,
        "years" : [
          chartPeriod.year
        ],
        "typeDateFilter": "DT_SIN_PRI",
        "organizationId": organizationId,
        "desease": "DENGUE",
        "ageInterval": 5,
        "ageMaximum": 85,
        "territorializations": selectedTerritorializations.map(({ value }) => value)
      })
    }

    const dataGeoRequest = await getNotificationsGeoRef(filter)

    return dataGeoRequest;
  }

  async function fetchTrapStatusNumbers(employees) {
    var arrayProjects = await getProjectsId();

    for (const chartPeriod of chartPeriods) {
      chartPeriod.ProjectsId = arrayProjects;
      chartPeriod.organizationId = organizationId;
      chartPeriod.trapTypeId = trapType.trapTypeId;
      chartPeriod.usersIds = employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees;
      chartPeriod.demandId = demand;
      chartPeriod.territorializations = selectedTerritorializations.map(({ value }) => value);
      chartPeriod.specieId = selectedSpecie
    }

    const trapStatusRequest = await getTrapStatusNumbers(chartPeriods)

    const trapStatusNumbers = trapStatusRequest.data;

    for (const index in trapStatusNumbers) {
      trapStatusNumbers[index].label = chartPeriods[index].year;
    }

    return trapStatusNumbers;
  }
  
  async function fetchData() {
    setIsGraphsLoading(true);

    const employees = teamEmployees.current;
    
    if (employee != "00000000-0000-0000-0000-000000000000" && !employees.includes(employee)) {
      employees.push(employee)
    }

    try {
      await fetchActiveTrapToMap(employees);
    
      const fetchTrapEggNumbersPromise = fetchTrapEggNumbers(employees);
      const fetchTrapStatusNumbersPromise = fetchTrapStatusNumbers(employees);
      const fetchDataGeoRefPromise = fetchDataGeoRef();
      const fetchTrapToMapPromise = fetchTrapToMap(employees);

      const results = await Promise.allSettled([
        fetchTrapEggNumbersPromise,
        fetchTrapStatusNumbersPromise,
        fetchDataGeoRefPromise,
        fetchTrapToMapPromise
      ]);

      results.forEach((result, index) => { 
        if (result.status === 'fulfilled') {
          const value = result.value;
  
          switch (index) {
            case 0:
              setTrapSampleNumbers(value);
              setTrapLabLoading(false);
              break;
            case 1:
              setTrapStatusNumbers(value);
              setTrapStatusLoading(false);
              break;
            case 2:
              setDataGeoRef(value.data);
              setDataGeoRefLoading(false);
              break;
            case 3:
              // TrapsToMap
              setTrapMapLoading(false);
              break;
            default:
              break;
          }
        } else {
          const reason = result.reason;
          console.error(`A Promise ${index} foi rejeitada por:`, reason);
        }
      });

    } catch (error) {
      console.error(error);
      setIsGraphsLoading(false);
    } finally {
      setIsGraphsLoading(false);
    }
  }

  async function buildLabelsAndPeriods(startDate,endDate) {
    const labels = await buildEpidemiologicalLabels({ startDate, endDate }); //semana epidemiológica
    const periods = new Array({ year: "", periods: [] });
    let labelsToCharts = new Array();
    for (let i = 0; i < labels.length; i++) {
      for (let labelIndex = 0; labelIndex < labels[i].labels.length; labelIndex++) {
        const { finalDate, beginDate } = await getEpidemiologicalPeriod({ labels: labels[i].labels, labelIndex, startDate, endDate, year: labels[i].year, lastYear: labels[labels.length - 1].year, firstYear: labels[0].year }); //semana epidemiológica
        labelsToCharts.push(labels[i].labels[labelIndex] + "/" + labels[i].year);
        periods[0].periods.push({ finalDate, beginDate });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labelsToCharts);
  }

  async function buildYearsLabelsAndPeriods(years = new Array()) {
    const labels = new Array();
    const periods = new Array();

    if (months.length === 0 && weeks.length === 0) {
      for (let i = 1; i <= 53; i++) {
        labels.push(i);
      }

      for (let i = 0; i < years.length; i++) {
        const epidemiologicalWeek = getWeeks().filter(ew => ew.year === years[i].value);
        periods.push({ year: years[i].value, periods: epidemiologicalWeek[0].weeks.map((ew) => { return { finalDate: moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") + "T23:59:59", beginDate: moment(ew.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD") } }) });
      }
    } else if (weeks.length === 0) {
      months.forEach(month => {
        labels.push(month.label);
      })

      for (let i = 0; i < years.length; i++) {
        const monthPeriods = [];

        for (let j = 0; j < months.length; j++) {
          const beginDate = moment(new Date(years[i].value, months[j].value, 1)).format("YYYY-MM-DD");
          const finalDate = moment(beginDate).add(1, 'month').subtract(1, 'day').format("YYYY-MM-DD") + "T23:59:59";
          monthPeriods.push({ beginDate, finalDate });
        }

        periods.push({ year: years[i].value, periods: monthPeriods });
      }
    } else {
      weeks.forEach(weeks => {
        labels.push(weeks.label);
      })

      for (let i = 0; i < years.length; i++) {
        const weeksPeriods = [];
        const epiWeeks = getWeeks().filter(ew => ew.year === years[i].value);

        for (let j = 0; j < weeks.length; j++) {
          if (epiWeeks[0].weeks[weeks[j].value] !== undefined) {
            const beginDate = moment(epiWeeks[0].weeks[weeks[j].value].beginDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            const finalDate = moment(epiWeeks[0].weeks[weeks[j].value].endDate, "DD/MM/YYYY").format("YYYY-MM-DD") + "T23:59:59";
            weeksPeriods.push({ beginDate, finalDate });
          }
        }
        periods.push({ year: years[i].value, periods: weeksPeriods });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labels);
  }

  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0
    },
    row: {
      marginLeft: 0,
      marginRight: 0
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw"
    }
  };

  return (
    <>
      {/* Page content */}

      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
        <Container className="filter-container">

          <button className="btn btn-primary hide-btn" onClick={() => { setFilterVisibility(!isFilterVisible) }}>{isFilterVisible ? "Esconder Filtro" : "Mostrar Filtro"}</button>

          {isFilterVisible ?
            <button className="btn btn-primary filter-btn" onClick={() => {
              if (trapTypeAUX.trapTypeId != "00000000-0000-0000-0000-000000000000") {
                setTrapType(trapTypeAUX);
                applyFilter();
              }
              else {
                applyFilter()
              }
            }}>

              Filtrar
            </button>
            : null}
        </Container>

        {isFilterVisible ?
          <>
            <VisitOperacionalFilterPresentational
              handleChangeFilter={handleChangeFilter}
              employeeId={employee}
              employeeIdList={employeeList}
              demandId={demand}
              demandIdList={demandList}
              teamId={team}
              teamIdList={teamList}
            />
            <Row style={{ display: "flex", gapTop: "5px" }}>
              <MainPeriodComponent
                months={months}
                years={year}
                weeks={weeks}
                weekValue1={weekValue1}
                weekValue2={weekValue2}
                showWeekToWeek
                changePeriod={changePeriod}
              />

              <EntomologicoFilterPresentational
                startDate={startDate}
                endDate={endDate}
                trapTypeId={trapType.trapTypeId}
                trapTypeIdAUX={trapTypeAUX.trapTypeId}
                trapTypeOptions={trapTypeOptions}
                handleChangeFilter={handleChangeFilter}
                speciesOptions={speciesOptions}
                selectedSpecie={selectedSpecie}
                applyFilter={applyFilter}
              />

              <GeographicFilterPresentational
                Territorializations={territorializationList}
                handleChangeFilter={handleChangeFilter}
                SelectedTerritorializations={selectedTerritorializations}
              />
              <Col className="linha-form col-md-4 geo-responsive-filter project-filter"> 
                <span className="h4 text-white">Atividade: </span>
                <Select
                    styles={{
                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        menuPortal: styles => ({ ...styles, zIndex: 9999 }),
                    }}
                    value={project}
                    isMulti={true}
                    placeholder={"Selecione"}
                    isClearable={true}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    onChange={(e) => setProject(e)}
                    options={projectOptions}
                />
              </Col>
              <Col  className="linha-form col-md-4" style={{alignContent: "flex-end"}}>
                <button className="btn btn-primary" onClick={handleDownloadEntomologicInformations}>
                <i className="fa fa-download text-white" /> Download das Informações Entomológicas
                </button>
              </Col>
            </Row>
            <Row>
                <Col className="col-4 col-md-4 col">
                </Col>
                <TodayEpidemiologicalWeekComponent 
                    todayEpidemiologicalWeek={todayEpidemiologicalWeek}
                />
                <Col className="col-4 col-md-4 col">
                </Col>
            </Row>
            <br />
          </>
          : null}
      </Container>
      <Container fluid className="mt--7">
        {/* Aqui 1 */}
        <Row className="mb-5" >
          <Col xl="12" className="mb-5 mb-xl-0">
            {isActiveTrapMapLoading ?
              <div className="mb-4">
                <MapLoadingSkeleton heigth="26rem"/>
              </div>
              :
              <ActiveMapContainer
                trapMapInfo={ActiveTrapMapInfo}
                territorializationsList={territorializationList}
                territorializationFiltered={selectedTerritorializations}
                trapTypeName={trapType.trapTypeName}
              />
            }
          </Col>
        </Row>
        {(trapType.trapTypeName === 'Ovitrampa' || trapType.trapTypeName === 'Adultrap' || trapType.trapTypeName === 'BG-GAT' || trapType.trapTypeName === 'Monitor' || trapType.trapTypeName === 'ADI') ?
          <>
            <Row className="mb-5" >
              <Col xl="6" className="mb-5 mb-xl-0">
                {isGraphsLoading ?
                  <div className="mb-4">
                    <MapLoadingSkeleton heigth="26rem" />
                  </div>
                  :
                  <>                        
                    {trapType.trapName !== 'armadilhaMosquitoAdulto' &&  trapType.trapName !== 'armadilhaMosquitoAdulto2'?
                      <EntomologicChartContainer
                        chartDataSets={chartDataSetsIpo}
                        selectedTerritorializations={selectedTerritorializations}
                        data={trapSampleNumbers}
                        textCardHeader={"Índice de Positividade"}
                        typeOfEntomologicChart={graphicType.IP}
                        informationsToPullToGraph={[
                          "positives",
                          "examinated"
                        ]}
                      /> : <></>
                    }

                    {(trapType.trapName === 'armadilhaMosquitoAdulto' || trapType.trapName === 'armadilhaMosquitoAdulto2') && isTrapStatusLoading == false ?
                      <EntomologicChartContainer
                        chartDataSets={chartDataSetsIpa}
                        selectedTerritorializations={selectedTerritorializations}
                        data={trapSampleNumbers}
                        textCardHeader={"Índice de Positividade"}
                        typeOfEntomologicChart="IPA"
                        informationsToPullToGraph={[
                          "positives",
                          "vistories"
                        ]}
                      /> : <></>
                    }
                  </>
                }
              </Col>
              <Col xl="6" className="mb-5 mb-xl-0">
                {isTrapMapLoading || dataGeoRefLoading ?
                  <div className="mb-4" >
                    <MapLoadingSkeleton heigth="26rem"/>
                  </div>
                  :
                  <PositivesMapContainer
                    data={trapSampleNumbers}
                    dataGeoRef={dataGeoRef}
                    dataGeoRefConfirmed={dataGeoRef.filter(t => t.confirmed == true)}
                    labels={chartLabels}
                    trapMapInfo={TrapMapInfo}
                    previousTrapMapInfo={PreviousTrapMapInfo}
                    territorializationList={territorializationList}
                    territorializationFiltered={selectedTerritorializations}
                    trapTypeName={trapType.trapTypeName}
                  />
                }
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl="6" className="mb-5 mb-xl-0">
                {isGraphsLoading ?
                  <div className="mb-4">
                    <MapLoadingSkeleton heigth="26rem" />
                  </div>
                  :
                  <>
                    {trapType.trapName !== 'armadilhaMosquitoAdulto' && trapType.trapName !== 'armadilhaMosquitoAdulto2' ?
                      <EntomologicChartContainer
                        chartDataSets={chartDataSetsIdo}
                        selectedTerritorializations={selectedTerritorializations}
                        data={trapSampleNumbers}
                        textCardHeader={"Índice de Densidade"}
                        typeOfEntomologicChart={graphicType.ID}
                        informationsToPullToGraph={[
                          "positives",
                          "eggs"
                        ]}
                      /> 
                      :
                      <>
                        {isTrapStatusLoading ?
                          <div className="mb-4" >
                            <MapLoadingSkeleton heigth="26rem"/>
                          </div>
                          :
                          <EntomologicChartContainer
                            chartDataSets={chartDataSetsIdo}
                            selectedTerritorializations={selectedTerritorializations}
                            data={trapSampleNumbers}
                            textCardHeader={"Índice de Densidade"}
                            typeOfEntomologicChart="IDA"
                            informationsToPullToGraph={[
                              "positives",
                              "eggs"
                            ]}
                          /> 
                        }
                      </>
                    }
                  </>
                }
              </Col>
              <Col xl="6" className="mb-5 mb-xl-0">
                {isTrapMapLoading ?
                  <div className="mb-4" >
                    <MapLoadingSkeleton heigth="26rem"/>
                  </div>
                  :
                  <IDOMapContainer
                    data={trapSampleNumbers}
                    labels={chartLabels}
                    trapMapInfo={TrapMapInfo}
                    previousTrapMapInfo={PreviousTrapMapInfo}
                    territorializationList={territorializationList}
                    trapTypeName={trapType.trapTypeName}
                    dataGeoRef={dataGeoRef}
                    dataGeoRefConfirmed={dataGeoRef.filter(t => t.confirmed == true)}
                  />
                }
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl="6" className="mb-5 mb-xl-0">
                {isGraphsLoading ?
                  <div className="mb-4">
                    <MapLoadingSkeleton heigth="26rem" />
                  </div>
                  :
                  <>
                    {trapType.trapName !== 'armadilhaMosquitoAdulto' && trapType.trapName !== 'armadilhaMosquitoAdulto2' ?
                      <EntomologicChartContainer
                        chartDataSets={chartDataSetsImo}
                        selectedTerritorializations={selectedTerritorializations}
                        data={trapSampleNumbers}
                        maxDomain={maxDomainImo}
                        textCardHeader={"Índice Médio de Ovos"}
                        typeOfEntomologicChart={graphicType.IM}
                        informationsToPullToGraph={[
                          "examinated",
                          "eggs"
                        ]}
                      /> 
                      :
                      <>
                        {isTrapStatusLoading ?
                            <div className="mb-4" >
                              <MapLoadingSkeleton heigth="26rem"/>
                            </div>
                          :
                            <EntomologicChartContainer
                              chartDataSets={chartDataSetsImo}
                              selectedTerritorializations={selectedTerritorializations}
                              data={trapSampleNumbers}
                              maxDomain={maxDomainImo}
                              textCardHeader={"Índice Médio de Mosquitos"}
                              typeOfEntomologicChart="IMA"
                              informationsToPullToGraph={[
                                "examinated",
                                "eggs"
                              ]}
                            /> 
                        }
                      </>
                    }
                  </>
                }
              </Col>
              <Col xl="6" className="mb-5 mb-xl-0">
                {isTrapMapLoading ?
                  <div className="mb-4" >
                    <MapLoadingSkeleton heigth="26rem"/>
                  </div>
                  :
                  <IMOMapContainer
                    data={trapSampleNumbers}
                    labels={chartLabels}
                    trapMapInfo={TrapMapInfo}
                    trapData={trapSampleNumbers}
                    previousTrapMapInfo={PreviousTrapMapInfo}
                    territorializationList={territorializationList}
                    territorializationFiltered={selectedTerritorializations}
                    dataGeoRef={dataGeoRef}
                    dataGeoRefConfirmed={dataGeoRef.filter(t => t.confirmed == true)}
                  />
                }
              </Col>
            </Row>
          </>
          : null}
        {/* Aqui 2 */}
        <Row className="mb-5" >
          <Col xl="6" className="mb-5 mb-xl-0">
            {isGraphsLoading ?
              <div className="mb-4">
                <MapLoadingSkeleton heigth="26rem" />  
              </div>
              :
                <PendentsVistoriedContainer
                  chartDataSets={chartDataSetsPendentsVistoried}
                  data={trapStatusNumbers}
                  selectedTerritorializations={selectedTerritorializations}
                />
                
            }
          </Col>
          <Col xl="6" className="mb-5 mb-xl-0">
            {isTrapMapLoading ?
              <div className="mb-4" >
                <MapLoadingSkeleton heigth="26rem"/>
              </div>
              :
                <PendentsVistoriedMapContainer
                  data={trapSampleNumbers}
                  labels={chartLabels}
                  trapMapInfo={TrapMapInfo}
                  trapData={trapSampleNumbers}
                  previousTrapMapInfo={PreviousTrapMapInfo}
                  territorializationList={territorializationList}
                  territorializationFiltered={selectedTerritorializations}
                />
            }
          </Col>
        </Row>

        {trapType.trapTypeName === 'Ovitrampa' || trapType.trapTypeName === 'Adultrap'  || trapType.trapTypeName === 'BG-GAT' || trapType.trapTypeName === 'Monitor'  || trapType.trapTypeName === 'ADI' ?
          <Row className="mb-5" >
            <Col xl="12" className="mb-5 mb-xl-0">
              {isTrapLabLoading ?
                <div className="mb-4" >
                  <MapLoadingSkeleton heigth="26rem"/>
                </div>
                :
                  <OvosLineContainer
                    data={trapSampleNumbers}
                    trapTypeName={trapType.trapName}
                    labels={chartLabels}
                  />
              }
            </Col>
          </Row>
          : null}
      </Container>
    </>
  );
};

export default DashboardEntomologico;
