import React from "react";

import { ToastContainer } from "react-toastify";
import { Container } from "reactstrap";
import { TerritorializationsReportContextProvider } from "./Contexts/TerritorializationsReportContext";
import { TerritorializationsReportFilters } from "./Components/Filters/TerritorializationsReportFilters";
import { TerritorializationsReportPageGraphics } from "./Components/Graphics/TerritorializationsReportPageGraphics";

import styles from "./TerritorializationsReportPage.module.css";

export const TerritorializationsReportPage = () => {
  return (
    <TerritorializationsReportContextProvider>
      <main>
        <ToastContainer />
        <Container
          fluid
          style={{ paddingBottom: "2rem" }}
          className={styles.container}
        >
          <div style={{ paddingTop: "10px !important", color: "#6d6d6d" }}>
            &nbsp;
          </div>
          <TerritorializationsReportFilters />
        </Container>
        <Container fluid className="pb-2 mt-2">
          <TerritorializationsReportPageGraphics />
        </Container>
      </main>
    </TerritorializationsReportContextProvider>
  );
};
