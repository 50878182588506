import React from "react";

import usePositivityIndexMap from "../Hooks/usePositivityIndexMap";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import { Button, Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import useGraphicsData from "../Hooks/useGraphicsData";
import ReactTooltip from "react-tooltip";
import xlsx from "json-as-xlsx";
import moment from "moment";

const useFieldResultsTable = () => {
  const { positivityIndexGroupPointsList } = usePositivityIndexMap();
  const { appliedFilters } = useAppliedFilters();
  const {
    situationsByTerritorializations,
    setSituationsByTerritorializations,
  } = useGraphicsData();

  const tableColumnsByTrapType = {
    armadilhaDisseminadoraInseticida: [
      {
        name: "Área",
        selector: "territorializationName",
        sortable: true,
      },
      {
        name: "Total de armadilhas positivas",
        selector: "totalPositiveTraps",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para larvas ou pupas",
        selector: "totalPositiveTrapsForLarvaeOrPupas",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para exúvias",
        selector: "totalPositiveTrapsForExuvia",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20",
        selector: "larvaOrPupaPositiveTrapsFrom1to20",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom1to20Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100",
        selector: "larvaOrPupaPositiveTrapsFrom21to100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom21to100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 1 a 20",
        selector: "exuviaPositiveTrapsFrom1to20",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 1 a 20 (%)",
        selector: "exuviaPositiveTrapsFrom1to20Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 21 a 100",
        selector: "exuviaPositiveTrapsFrom21to100",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 21 a 100 (%)",
        selector: "exuviaPositiveTrapsFrom21to100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias mais de 100",
        selector: "exuviaPositiveTrapsFromMoreThan100",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias mais de 100 (%)",
        selector: "exuviaPositiveTrapsFromMoreThan100Percentage",
        sortable: true,
      },
    ],
    armadilhaMosquitoAdulto: [
      {
        name: "Área",
        selector: "territorializationName",
        sortable: true,
      },
      {
        name: "Total de armadilhas positivas",
        selector: "totalPositiveTraps",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para larvas ou pupas",
        selector: "totalPositiveTrapsForLarvaeOrPupas",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para adultos",
        selector: "totalPositiveTrapsForAedesAegypti",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20",
        selector: "larvaOrPupaPositiveTrapsFrom1to20",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom1to20Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100",
        selector: "larvaOrPupaPositiveTrapsFrom21to100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom21to100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 1",
        selector: "aedesAegyptiPositiveTrapsWith1",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 1 (%)",
        selector: "aedesAegyptiPositiveTrapsWith1Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 2 a 3",
        selector: "aedesAegyptiPositiveTrapsFrom2to3",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 2 a 3 (%)",
        selector: "aedesAegyptiPositiveTrapsFrom2to3Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos mais de 3",
        selector: "aedesAegyptiPositiveTrapsFromMoreThan3",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos mais de 3 (%)",
        selector: "aedesAegyptiPositiveTrapsFromMoreThan3Percentage",
        sortable: true,
      },
    ],
    armadilhaMosquitoAdulto2: [
      {
        name: "Área",
        selector: "territorializationName",
        sortable: true,
      },
      {
        name: "Total de armadilhas positivas",
        selector: "totalPositiveTraps",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para larvas ou pupas",
        selector: "totalPositiveTrapsForLarvaeOrPupas",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para adultos",
        selector: "totalPositiveTrapsForAedesAegypti",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20",
        selector: "larvaOrPupaPositiveTrapsFrom1to20",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom1to20Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100",
        selector: "larvaOrPupaPositiveTrapsFrom21to100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom21to100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 1",
        selector: "aedesAegyptiPositiveTrapsWith1",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 1 (%)",
        selector: "aedesAegyptiPositiveTrapsWith1Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 2 a 3",
        selector: "aedesAegyptiPositiveTrapsFrom2to3",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos de 2 a 3 (%)",
        selector: "aedesAegyptiPositiveTrapsFrom2to3Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos mais de 3",
        selector: "aedesAegyptiPositiveTrapsFromMoreThan3",
        sortable: true,
      },
      {
        name: "Armadilhas com adultos mais de 3 (%)",
        selector: "aedesAegyptiPositiveTrapsFromMoreThan3Percentage",
        sortable: true,
      },
    ],
    armadilhaOvos: [
      {
        name: "Área",
        selector: "territorializationName",
        sortable: true,
      },
      {
        name: "Total de armadilhas positivas",
        selector: "totalPositiveTraps",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para ovos",
        selector: "totalPositiveTrapsForEggs",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para larvas ou pupas",
        selector: "totalPositiveTrapsForLarvaeOrPupas",
        sortable: true,
      },
      {
        name: "Armadilhas positivas para exúvias",
        selector: "totalPositiveTrapsForExuvia",
        sortable: true,
      },
      {
        name: "Armadilhas com ovos de 1 a 100",
        selector: "eggPositiveTrapsFrom1to100",
        sortable: true,
      },
      {
        name: "Armadilhas com ovos de 1 a 100 (%)",
        selector: "eggPositiveTrapsFrom101to200Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com ovos de 101 a 200",
        selector: "eggPositiveTrapsFrom101to200",
        sortable: true,
      },
      {
        name: "Armadilhas com ovos de 101 a 200 (%)",
        selector: "eggPositiveTrapsFrom101to200Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com ovos mais de 200",
        selector: "eggPositiveTrapsFromMoreThan200",
        sortable: true,
      },
      {
        name: "Armadilhas com ovos mais de 200 (%)",
        selector: "eggPositiveTrapsFromMoreThan200Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20",
        selector: "larvaOrPupaPositiveTrapsFrom1to20",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 1 a 20 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom1to20Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100",
        selector: "larvaOrPupaPositiveTrapsFrom21to100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas de 21 a 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFrom21to100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100",
        sortable: true,
      },
      {
        name: "Armadilhas com larvas ou pupas mais de 100 (%)",
        selector: "larvaOrPupaPositiveTrapsFromMoreThan100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 1 a 20",
        selector: "exuviaPositiveTrapsFrom1to20",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 1 a 20 (%)",
        selector: "exuviaPositiveTrapsFrom1to20Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 21 a 100",
        selector: "exuviaPositiveTrapsFrom21to100",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias de 21 a 100 (%)",
        selector: "exuviaPositiveTrapsFrom21to100Percentage",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias mais de 100",
        selector: "exuviaPositiveTrapsFromMoreThan100",
        sortable: true,
      },
      {
        name: "Armadilhas com exúvias mais de 100 (%)",
        selector: "exuviaPositiveTrapsFromMoreThan100Percentage",
        sortable: true,
      },
    ],
  };

  const tableColumns =
    tableColumnsByTrapType[appliedFilters.trapType.trapTypeName];

  React.useEffect(() => {
    if (
      !positivityIndexGroupPointsList ||
      Object.keys(positivityIndexGroupPointsList).length === 0 ||
      appliedFilters.territorializations.length === 0
    )
      return;

    const formatTrapTypeDataForTableFunctions = {
      armadilhaDisseminadoraInseticida: formatAdiPositiveTrapsForTable,
      armadilhaMosquitoAdulto: formatAdultrapPositiveTrapsForTable,
      armadilhaMosquitoAdulto2: formatBgGatPositiveTrapsForTable,
      armadilhaOvos: formatOvitrampaPositiveTrapsForTable,
    };

    const groupedPositiveTraps = groupPositiveTraps(
      positivityIndexGroupPointsList
    );

    const formatedDataForTable = [];

    appliedFilters.territorializations.forEach(({ label }) => {
      const territorializationPositiveTraps = groupedPositiveTraps.filter(
        ({ territorializationName }) => territorializationName === label
      );

      const formatedData = formatTrapTypeDataForTableFunctions[
        appliedFilters.trapType.trapTypeName
      ](territorializationPositiveTraps, label);

      formatedDataForTable.push(formatedData);
    });

    setSituationsByTerritorializations(formatedDataForTable);
  }, [positivityIndexGroupPointsList, appliedFilters]);

  const calculatePercentage = (numerator, denominator) =>
    denominator > 0 ? ((numerator / denominator) * 100).toFixed(1) : "0.0";

  const groupPositiveTraps = (positivityIndexGroupPointsList) => {
    const groupedPositiveTraps = [];

    positivityIndexGroupPointsList.aedesAegyptiPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            groupedPositiveTraps.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Adultos",
            });
          });
        });
      }
    );

    positivityIndexGroupPointsList.eggPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            groupedPositiveTraps.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Ovos",
            });
          });
        });
      }
    );

    positivityIndexGroupPointsList.exuviaPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            groupedPositiveTraps.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Exúvia",
            });
          });
        });
      }
    );

    positivityIndexGroupPointsList.larvaOrPupaPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            groupedPositiveTraps.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Larva ou Pupa",
            });
          });
        });
      }
    );

    return groupedPositiveTraps;
  };

  const formatAdiPositiveTrapsForTable = (traps, territorializationName) => {
    let exuviaPositiveTrapsFrom1to20 = 0;
    let exuviaPositiveTrapsFrom21to100 = 0;
    let exuviaPositiveTrapsFromMoreThan100 = 0;

    let larvaOrPupaPositiveTrapsFrom1to20 = 0;
    let larvaOrPupaPositiveTrapsFrom21to100 = 0;
    let larvaOrPupaPositiveTrapsFromMoreThan100 = 0;

    traps.forEach(({ positiveFor, estimate }) => {
      if (positiveFor === "Larva ou Pupa") {
        if (estimate === "1 a 20") larvaOrPupaPositiveTrapsFrom1to20++;
        else if (estimate === "21 a 100") larvaOrPupaPositiveTrapsFrom21to100++;
        else if (estimate === "Mais de 100")
          larvaOrPupaPositiveTrapsFromMoreThan100++;
      }

      if (positiveFor === "Exúvia") {
        if (estimate === "1 a 20") exuviaPositiveTrapsFrom1to20++;
        else if (estimate === "21 a 100") exuviaPositiveTrapsFrom21to100++;
        else if (estimate === "Mais de 100")
          exuviaPositiveTrapsFromMoreThan100++;
      }
    });

    const totalPositiveTrapsForLarvaeOrPupas =
      larvaOrPupaPositiveTrapsFrom1to20 +
      larvaOrPupaPositiveTrapsFrom21to100 +
      larvaOrPupaPositiveTrapsFromMoreThan100;

    const totalPositiveTrapsForExuvia =
      exuviaPositiveTrapsFrom1to20 +
      exuviaPositiveTrapsFrom21to100 +
      exuviaPositiveTrapsFromMoreThan100;

    const formatedData = {
      territorializationName: territorializationName,
      totalPositiveTraps: traps.length,
      totalPositiveTrapsForLarvaeOrPupas,
      totalPositiveTrapsForExuvia,

      larvaOrPupaPositiveTrapsFrom1to20,
      larvaOrPupaPositiveTrapsFrom1to20Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFrom1to20,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      larvaOrPupaPositiveTrapsFrom21to100,
      larvaOrPupaPositiveTrapsFrom21to100Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFrom21to100,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      larvaOrPupaPositiveTrapsFromMoreThan100,
      larvaOrPupaPositiveTrapsFromMoreThan100Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFromMoreThan100,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      exuviaPositiveTrapsFrom1to20,
      exuviaPositiveTrapsFrom1to20Percentage: calculatePercentage(
        exuviaPositiveTrapsFrom1to20,
        totalPositiveTrapsForExuvia
      ),
      exuviaPositiveTrapsFrom21to100,
      exuviaPositiveTrapsFrom21to100Percentage: calculatePercentage(
        exuviaPositiveTrapsFrom21to100,
        totalPositiveTrapsForExuvia
      ),
      exuviaPositiveTrapsFromMoreThan100,
      exuviaPositiveTrapsFromMoreThan100Percentage: calculatePercentage(
        exuviaPositiveTrapsFromMoreThan100,
        totalPositiveTrapsForExuvia
      ),
    };

    return formatedData;
  };

  const formatBgGatPositiveTrapsForTable = (traps, territorializationName) => {
    let larvaOrPupaPositiveTrapsFrom1to20 = 0;
    let larvaOrPupaPositiveTrapsFrom21to100 = 0;
    let larvaOrPupaPositiveTrapsFromMoreThan100 = 0;

    let aedesAegyptiPositiveTrapsWith1 = 0;
    let aedesAegyptiPositiveTrapsFrom2to3 = 0;
    let aedesAegyptiPositiveTrapsFromMoreThan3 = 0;

    traps.forEach(({ positiveFor, estimate }) => {
      if (positiveFor === "Larva ou Pupa") {
        if (estimate === "1 a 20") larvaOrPupaPositiveTrapsFrom1to20++;
        else if (estimate === "21 a 100") larvaOrPupaPositiveTrapsFrom21to100++;
        else if (estimate === "Mais de 100")
          larvaOrPupaPositiveTrapsFromMoreThan100++;
      }

      if (positiveFor === "Adultos") {
        if (estimate === "1") aedesAegyptiPositiveTrapsWith1++;
        else if (estimate === "2 a 3") aedesAegyptiPositiveTrapsFrom2to3++;
        else if (estimate === "Mais de 3")
          aedesAegyptiPositiveTrapsFromMoreThan3++;
      }
    });

    const totalPositiveTrapsForLarvaeOrPupas =
      larvaOrPupaPositiveTrapsFrom1to20 +
      larvaOrPupaPositiveTrapsFrom21to100 +
      larvaOrPupaPositiveTrapsFromMoreThan100;

    const totalPositiveTrapsForAedesAegypti =
      aedesAegyptiPositiveTrapsWith1 +
      aedesAegyptiPositiveTrapsFrom2to3 +
      aedesAegyptiPositiveTrapsFromMoreThan3;

    const formatedData = {
      territorializationName: territorializationName,
      totalPositiveTraps: traps.length,
      totalPositiveTrapsForLarvaeOrPupas,
      totalPositiveTrapsForAedesAegypti,
      larvaOrPupaPositiveTrapsFrom1to20,
      larvaOrPupaPositiveTrapsFrom1to20Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFrom1to20,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      larvaOrPupaPositiveTrapsFrom21to100,
      larvaOrPupaPositiveTrapsFrom21to100Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFrom21to100,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      larvaOrPupaPositiveTrapsFromMoreThan100,
      larvaOrPupaPositiveTrapsFromMoreThan100Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFromMoreThan100,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      aedesAegyptiPositiveTrapsWith1,
      aedesAegyptiPositiveTrapsWith1Percentage: calculatePercentage(
        aedesAegyptiPositiveTrapsWith1,
        totalPositiveTrapsForAedesAegypti
      ),
      aedesAegyptiPositiveTrapsFrom2to3,
      aedesAegyptiPositiveTrapsFrom2to3Percentage: calculatePercentage(
        aedesAegyptiPositiveTrapsFrom2to3,
        totalPositiveTrapsForAedesAegypti
      ),
      aedesAegyptiPositiveTrapsFromMoreThan3,
      aedesAegyptiPositiveTrapsFromMoreThan3Percentage: calculatePercentage(
        aedesAegyptiPositiveTrapsFromMoreThan3,
        totalPositiveTrapsForAedesAegypti
      ),
    };

    return formatedData;
  };

  const formatAdultrapPositiveTrapsForTable = (
    traps,
    territorializationName
  ) => {
    return formatBgGatPositiveTrapsForTable(traps, territorializationName);
  };

  const formatOvitrampaPositiveTrapsForTable = (
    traps,
    territorializationName
  ) => {
    let exuviaPositiveTrapsFrom1to20 = 0;
    let exuviaPositiveTrapsFrom21to100 = 0;
    let exuviaPositiveTrapsFromMoreThan100 = 0;

    let larvaOrPupaPositiveTrapsFrom1to20 = 0;
    let larvaOrPupaPositiveTrapsFrom21to100 = 0;
    let larvaOrPupaPositiveTrapsFromMoreThan100 = 0;

    let eggPositiveTrapsFrom1to100 = 0;
    let eggPositiveTrapsFrom101to200 = 0;
    let eggPositiveTrapsFromMoreThan200 = 0;

    traps.forEach(({ positiveFor, estimate }) => {
      if (positiveFor === "Ovos") {
        if (estimate === "1 a 100") eggPositiveTrapsFrom1to100++;
        else if (estimate === "101 a 200") eggPositiveTrapsFrom101to200++;
        else if (estimate === "Mais de 200") eggPositiveTrapsFromMoreThan200++;
      }

      if (positiveFor === "Larva ou Pupa") {
        if (estimate === "1 a 20") larvaOrPupaPositiveTrapsFrom1to20++;
        else if (estimate === "21 a 100") larvaOrPupaPositiveTrapsFrom21to100++;
        else if (estimate === "Mais de 100")
          larvaOrPupaPositiveTrapsFromMoreThan100++;
      }

      if (positiveFor === "Exúvia") {
        if (estimate === "1 a 20") exuviaPositiveTrapsFrom1to20++;
        else if (estimate === "21 a 100") exuviaPositiveTrapsFrom21to100++;
        else if (estimate === "Mais de 100")
          exuviaPositiveTrapsFromMoreThan100++;
      }
    });

    const totalPositiveTrapsForLarvaeOrPupas =
      larvaOrPupaPositiveTrapsFrom1to20 +
      larvaOrPupaPositiveTrapsFrom21to100 +
      larvaOrPupaPositiveTrapsFromMoreThan100;

    const totalPositiveTrapsForExuvia =
      exuviaPositiveTrapsFrom1to20 +
      exuviaPositiveTrapsFrom21to100 +
      exuviaPositiveTrapsFromMoreThan100;

    const totalPositiveTrapsForEggs =
      eggPositiveTrapsFrom1to100 +
      eggPositiveTrapsFrom101to200 +
      eggPositiveTrapsFromMoreThan200;

    const formatedData = {
      territorializationName: territorializationName,
      totalPositiveTraps: traps.length,
      totalPositiveTrapsForEggs,
      totalPositiveTrapsForLarvaeOrPupas,
      totalPositiveTrapsForExuvia,
      eggPositiveTrapsFrom1to100,
      eggPositiveTrapsFrom1to100Percentage: calculatePercentage(
        eggPositiveTrapsFrom1to100,
        totalPositiveTrapsForEggs
      ),
      eggPositiveTrapsFrom101to200,
      eggPositiveTrapsFrom101to200Percentage: calculatePercentage(
        eggPositiveTrapsFrom101to200,
        totalPositiveTrapsForEggs
      ),
      eggPositiveTrapsFromMoreThan200,
      eggPositiveTrapsFromMoreThan200Percentage: calculatePercentage(
        eggPositiveTrapsFromMoreThan200,
        totalPositiveTrapsForEggs
      ),
      larvaOrPupaPositiveTrapsFrom1to20,
      larvaOrPupaPositiveTrapsFrom1to20Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFrom1to20,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      larvaOrPupaPositiveTrapsFrom21to100,
      larvaOrPupaPositiveTrapsFrom21to100Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFrom21to100,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      larvaOrPupaPositiveTrapsFromMoreThan100,
      larvaOrPupaPositiveTrapsFromMoreThan100Percentage: calculatePercentage(
        larvaOrPupaPositiveTrapsFromMoreThan100,
        totalPositiveTrapsForLarvaeOrPupas
      ),
      exuviaPositiveTrapsFrom1to20,
      exuviaPositiveTrapsFrom1to20Percentage: calculatePercentage(
        exuviaPositiveTrapsFrom1to20,
        totalPositiveTrapsForExuvia
      ),
      exuviaPositiveTrapsFrom21to100,
      exuviaPositiveTrapsFrom21to100Percentage: calculatePercentage(
        exuviaPositiveTrapsFrom21to100,
        totalPositiveTrapsForExuvia
      ),
      exuviaPositiveTrapsFromMoreThan100,
      exuviaPositiveTrapsFromMoreThan100Percentage: calculatePercentage(
        exuviaPositiveTrapsFromMoreThan100,
        totalPositiveTrapsForExuvia
      ),
    };

    return formatedData;
  };

  const handleDownloadData = () => {
    const xlsxFileSettings = {
      fileName: `Resultados_de_campo_situacoes_areas_VITEC_${moment().format(
        "DD/MM/YYYY HH:mm"
      )}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false,
    };

    xlsx(
      [
        {
          sheet: "Resultados de campo",
          columns: tableColumns.map(({ name, selector }) => ({
            label: name,
            value: selector,
          })),
          content: situationsByTerritorializations,
        },
      ],
      xlsxFileSettings
    );
  };

  return { situationsByTerritorializations, tableColumns, handleDownloadData };
};

const FieldResultsTable = () => {
  const { situationsByTerritorializations, tableColumns, handleDownloadData } =
    useFieldResultsTable();

  return (
    <Card>
      <CardHeader
        headerText={"Tabela de situações por áreas"}
        showExpandButton={false}
        showButtonsTooltips={true}
        showDownloadDataButton={false}
      >
        <Button
          color="primary"
          data-tip
          data-for="dataTableDownloadButton"
          onClick={handleDownloadData}
          style={{ width: "55px", marginRight: "0px" }}
        >
          <i className="fa fa-download" />
        </Button>
        <ReactTooltip effect="solid" type="info" id="dataTableDownloadButton">
          Baixar dados
        </ReactTooltip>
      </CardHeader>
      <CardContent>
        <Row>
          <Col style={{ marginTop: "0.7rem" }}>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive
              columns={tableColumns}
              data={situationsByTerritorializations}
              noDataComponent={"Nenhum registro encontrado."}
            />
          </Col>
        </Row>
      </CardContent>
      <CardFooter>
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  );
};

export default FieldResultsTable;
