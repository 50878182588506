import React from "react";
import { Button, Col, Row } from "reactstrap";
import getWeeks from "../../../../services/utils/epidemiologicalWeek";
import { getUserData } from "../../../../services/utils/auth";
import { notificiationsByFilters } from "../../../../services/api/Sinan";
import { toast } from "react-toastify";
import { useAppliedFilters } from "../Hooks/useAppliedFilters";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import moment from "moment";
import xlsx from "json-as-xlsx";

const useCasesTotalizationTable = () => {
  const [isLoadingCases, setIsLoadingCases] = React.useState(false);
  const [formatedCasesByYears, setFormatedCasesByYears] = React.useState([]);
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    setFormatedCasesByYears([]);
  }, [appliedFilters]);

  const handleCalculateCasesByYears = async () => {
    const casesFromYears = await fetchCasesFromAllYears();

    setFormatedCasesByYears(casesFromYears);
  };

  const handleDonwloadData = () => {
    const xlsxFileSettings = {
      fileName: `totalizacao_casos_SINAN_VITEC_${moment().format(
        "DD/MM/YYYY HH:mm"
      )}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false,
    };

    xlsx(
      [
        {
          sheet: "Armadilhas",
          columns: tableColumns.map((column) => ({
            label: column.name,
            value: column.selector,
          })),
          content: formatedCasesByYears,
        },
      ],
      xlsxFileSettings
    );
  };

  const fetchCasesFromAllYears = async () => {
    const result = [];
    const years = getWeeks().map(({ year }) => year);

    setIsLoadingCases(true);

    try {
      for (const year of years) {
        const yearNotificationsList = await fetchNotificationsList(year);

        const formatedCases = formatCasesFromYear(yearNotificationsList);

        result.push(formatedCases);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoadingCases(false);
    }

    return result;
  };

  const fetchNotificationsList = async (year) => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao buscar os dados de casos por anos.";

    const periods = [
      {
        beginDate: `${year}-01-01 00:00`,
        endDate: `${year}-12-31 23:59`,
        periodName: year,
      },
    ];

    const filtersToSend = {
      disease: 1,
      includeCasesByPersons: false,
      notifyingUnitsIds: appliedFilters.notifyingUnits.map(
        ({ value }) => value
      ),
      organizationId: getUserData("organizationId"),
      periods,
      referenceDate: appliedFilters.casesReferenceDate.value,
      territorializationsIds: appliedFilters.territorializations.map(
        ({ value }) => value
      ),
    };

    try {
      const { data, status } = await notificiationsByFilters(filtersToSend);

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      return data[0];
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error);
      }
    }
  };

  const formatCasesFromYear = (data) => {
    const result = {
      year: data.periodName,
      notifiedCases: data.cases.notifications.cases,
      discardedCases:
        data.cases.notifications.cases - data.cases.confirmed.cases,
      dengueConfirmedCases: data.cases.confirmed.cases,
      deathByDengue: data.cases.evolution.deathByDengue,
      clinicalEpidemiologicalDiagnosisTotal:
        data.cases.criterion.epidemiologicClinic,
      clinicalEpidemiologicalDiagnosisDengueConfirmedCasesPercentage:
        data.cases.criterion.epidemiologicClinicConfirmedCases === 0 ||
        data.cases.criterion.epidemiologicClinic === 0
          ? 0
          : +(
              (data.cases.criterion.epidemiologicClinicConfirmedCases /
                data.cases.criterion.epidemiologicClinic) *
              100
            ).toFixed(1),
      clinicalEpidemiologicalDiagnosisDengueConfirmedCases:
        data.cases.criterion.epidemiologicClinicConfirmedCases,
      clinicalEpidemiologicalDiagnosisDengueNegativeCasesPercentage:
        data.cases.criterion.epidemiologicClinicNegativeCases === 0 ||
        data.cases.criterion.epidemiologicClinic === 0
          ? 0
          : +(
              (data.cases.criterion.epidemiologicClinicNegativeCases /
                data.cases.criterion.epidemiologicClinic) *
              100
            ).toFixed(1),
      clinicalEpidemiologicalDiagnosisDengueNegativeCases:
        data.cases.criterion.epidemiologicClinicNegativeCases,
      clinicalEpidemiologicalDiagnosisDeathCases:
        data.cases.criterion.epidemiologicClinicDeathCases,
      clinicalEpidemiologicalDiagnosisDeathCasesPercentage:
        data.cases.criterion.epidemiologicClinicDeathCases === 0 ||
        data.cases.evolution.deathByDengue === 0
          ? 0
          : +(
              (data.cases.criterion.epidemiologicClinicDeathCases /
                data.cases.evolution.deathByDengue) *
              100
            ).toFixed(1),
      laboratorylDiagnosisTotal: data.cases.criterion.laboratory,
      laboratorylDiagnosisDengueConfirmedCasesPercentage:
        data.cases.criterion.laboratoryConfirmedCases === 0 ||
        data.cases.criterion.laboratory === 0
          ? 0
          : +(
              (data.cases.criterion.laboratoryConfirmedCases /
                data.cases.criterion.laboratory) *
              100
            ).toFixed(1),
      laboratorylDiagnosisDengueConfirmedCases:
        data.cases.criterion.laboratoryConfirmedCases,
      laboratorylDiagnosisDengueNegativeCasesPercentage:
        data.cases.criterion.laboratoryNegativeCases === 0 ||
        data.cases.criterion.laboratory === 0
          ? 0
          : +(
              (data.cases.criterion.laboratoryNegativeCases /
                data.cases.criterion.laboratory) *
              100
            ).toFixed(1),
      laboratorylDiagnosisDengueNegativeCases:
        data.cases.criterion.laboratoryNegativeCases,
      laboratorylDiagnosisDeathCases: data.cases.criterion.laboratoryDeathCases,
      laboratorylDiagnosisDeathCasesPercentage:
        data.cases.criterion.laboratoryDeathCases === 0 ||
        data.cases.evolution.deathByDengue === 0
          ? 0
          : +(
              (data.cases.criterion.laboratoryDeathCases /
                data.cases.evolution.deathByDengue) *
              100
            ).toFixed(1),
      finalClassificationDengue: data.cases.finalClassification.dengue,
      finalClassificationDengueWithWarningSigns:
        data.cases.finalClassification.dengueWithWarningSigns,
      finalClassificationSevereDengue:
        data.cases.finalClassification.severeDengue,
      finalClassificationHealed: data.cases.evolution.recovered,
      finalClassificationDeath: data.cases.evolution.deathByDengue,
    };

    return result;
  };

  const tableColumns = [
    {
      name: "Ano",
      selector: "year",
      sortable: true,
    },
    {
      name: "Casos Notificados",
      selector: "notifiedCases",
      sortable: true,
    },
    {
      name: "Casos Descartados",
      selector: "discardedCases",
      sortable: true,
    },
    {
      name: "Dengue Confirmado",
      selector: "dengueConfirmedCases",
      sortable: true,
    },
    {
      name: "Óbitos",
      selector: "deathByDengue",
      sortable: true,
    },

    {
      name: "Total de diagnóstico Clínicos Epidemiológico",
      selector: "clinicalEpidemiologicalDiagnosisTotal",
      sortable: true,
    },
    {
      name: "Dengue Positivo (Clínico-Epidemiológico)",
      selector: "clinicalEpidemiologicalDiagnosisDengueConfirmedCases",
      sortable: true,
    },
    {
      name: "% Dengue Positivo (Clínico-Epidemiológico)",
      selector:
        "clinicalEpidemiologicalDiagnosisDengueConfirmedCasesPercentage",
      sortable: true,
    },
    {
      name: "Dengue Negativo (Clínico-Epidemiológico)",
      selector: "clinicalEpidemiologicalDiagnosisDengueNegativeCases",
      sortable: true,
    },
    {
      name: "% Dengue Negativo (Clínico-Epidemiológico)",
      selector: "clinicalEpidemiologicalDiagnosisDengueNegativeCasesPercentage",
      sortable: true,
    },
    {
      name: "Óbitos (Clínico-Epidemiológico)",
      selector: "clinicalEpidemiologicalDiagnosisDeathCases",
      sortable: true,
    },
    {
      name: "% Óbitos (Clínico-Epidemiológico)",
      selector: "clinicalEpidemiologicalDiagnosisDeathCasesPercentage",
      sortable: true,
    },
    {
      name: "Total de diagnóstico Laboratorial",
      selector: "laboratorylDiagnosisTotal",
      sortable: true,
    },
    {
      name: "Dengue Positivo (Laboratorial)",
      selector: "laboratorylDiagnosisDengueConfirmedCases",
      sortable: true,
    },
    {
      name: "% Dengue Positivo (Laboratorial)",
      selector: "laboratorylDiagnosisDengueConfirmedCasesPercentage",
      sortable: true,
    },
    {
      name: "Dengue Negativo (Laboratorial)",
      selector: "laboratorylDiagnosisDengueNegativeCases",
      sortable: true,
    },
    {
      name: "% Dengue Negativo (Laboratorial)",
      selector: "laboratorylDiagnosisDengueNegativeCasesPercentage",
      sortable: true,
    },
    {
      name: "Óbitos (Laboratorial)",
      selector: "laboratorylDiagnosisDeathCases",
      sortable: true,
    },
    {
      name: "% Óbitos (Laboratorial)",
      selector: "laboratorylDiagnosisDeathCasesPercentage",
      sortable: true,
    },
    {
      name: "Dengue",
      selector: "finalClassificationDengue",
      sortable: true,
    },
    {
      name: "Dengue com Sinais de Alarme",
      selector: "finalClassificationDengueWithWarningSigns",
      sortable: true,
    },
    {
      name: "Dengue Grave",
      selector: "finalClassificationSevereDengue",
      sortable: true,
    },
    {
      name: "Cura",
      selector: "finalClassificationHealed",
      sortable: true,
    },
    {
      name: "Óbito",
      selector: "finalClassificationDeath",
      sortable: true,
    },
  ];

  return {
    formatedCasesByYears,
    handleCalculateCasesByYears,
    handleDonwloadData,
    isLoadingCases,
    tableColumns,
  };
};

export const CasesTotalizationTable = () => {
  const {
    formatedCasesByYears,
    handleCalculateCasesByYears,
    handleDonwloadData,
    isLoadingCases,
    tableColumns,
  } = useCasesTotalizationTable();

  if (isLoadingCases) return <TableLoadingSkeleton variant={"simple"} />;

  if (formatedCasesByYears.length === 0)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          style={{ width: "30%" }}
          onClick={() => handleCalculateCasesByYears()}
        >
          Gerar tabela de totalização por anos
        </Button>
      </div>
    );

  return (
    <Card>
      <CardHeader
        headerText={"Tabela de totalização dos casos por anos"}
        showExpandButton={false}
        showButtonsTooltips={true}
        showDownloadDataButton={false}
      >
        <Button
          onClick={() => handleDonwloadData()}
          color="primary"
          data-tip
          data-for="dataTotalizationDownload"
          style={{ width: "55px", marginRight: "0px" }}
        >
          <i className="fa fa-download" />
        </Button>
        <ReactTooltip effect="solid" type="info" id="dataTotalizationDownload">
          Baixar dados
        </ReactTooltip>
      </CardHeader>
      <CardContent>
        <Row>
          <Col>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive
              columns={tableColumns}
              data={formatedCasesByYears}
              noDataComponent={"Nenhum registro encontrado."}
            />
          </Col>
        </Row>
      </CardContent>
      <CardFooter>
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  );
};
