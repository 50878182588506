import React from "react";

import {
  VitecCard,
  VitecCardDefaultView,
  VitecCardDefaultViewContent,
  VitecCardDefaultViewFooter,
  VitecCardDefaultViewHeader,
  VitecCardDownloadImageButton,
  VitecCardGeneratedAt,
  VitecCardModal,
  VitecCardModalContent,
  VitecCardModalFooter,
  VitecCardModalHeader,
  VitecCardToggleModalButton,
} from "../../../../../components/Cards/VitecCard";
import moment from "moment";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import {
  getColorByIndex,
  selectComponentStyles,
} from "../../../../../services/utils/globalFunctions";
import { Select } from "../../../../../components/Wrappers/SelectAll";
import { useAppliedFilters } from "../../Hooks/useAppliedFilters";

const useConfirmationCriteriaGraphic = (notificationsList, variant) => {
  const [formatedEvolutionData, setFormatedEvolutionData] = React.useState([]);
  const [filters, setFilters] = React.useState({
    polygon: null,
    period: null,
    compareSamePeriodFromDifferentAreas: null,
  });
  const [filteredPolygonsList, setFilteredPolygonsList] = React.useState([]);
  const [filteredPeriodsList, setFilteredPeriodsList] = React.useState([]);
  const [polygonsDataFromSamePeriod, setPolygonsDataFromSamePeriod] =
    React.useState([]);
  const [dateFilterType, setDateFilterType] = React.useState("date");
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    const polygonsList = getSelectedPolygonsList(appliedFilters);
    const periodsList = getSelectedPeriodsList(appliedFilters);

    setFilteredPolygonsList(polygonsList);
    setFilteredPeriodsList(periodsList);

    setFilters({
      polygon: polygonsList[0],
      period: periodsList[0],
      compareSamePeriodFromDifferentAreas: {
        label: "Não",
        value: false,
      },
    });
    setDateFilterType(getAppliedDateFilterType(appliedFilters));
  }, [appliedFilters]);

  React.useEffect(() => {
    if (
      !filters ||
      !filters.compareSamePeriodFromDifferentAreas ||
      !filters.compareSamePeriodFromDifferentAreas.value
    )
      return;

    const result = [];

    notificationsList.forEach((area) => {
      const periodData = area.confirmationCriteriaByPeriods.find(
        ({ periodName }) => periodName === filters.period.value
      );

      if (periodData) {
        result.push(periodData.result);
      }
    });

    setPolygonsDataFromSamePeriod(result);
  }, [filters]);

  React.useEffect(() => {
    if (notificationsList.length === 0) return;

    const result =
      generateConfirmationCriteriaFromAreasByPeriodsDataObject(
        notificationsList
      );

    setFormatedEvolutionData(result);
  }, [notificationsList]);

  const calculatePercentage = (value, total) => {
    if (value === 0 || total === 0) return 0;

    return Math.floor((value / total) * 1000) / 10;
  };

  const createConfirmationCriteriaPeriodDataObject = (
    area,
    genderName,
    label,
    value = 0,
    percentage = 0
  ) => {
    return {
      area,
      name: genderName,
      label: label,
      value,
      percentage,
    };
  };

  const createConfirmationCriteriaPeriodResultDataObject = (
    area,
    periodName,
    laboratoryCases,
    epidemiologicClinicCases,
    underInvestigationCases
  ) => {
    return {
      periodName,
      result: [
        createConfirmationCriteriaPeriodDataObject(
          area,
          "laboratory",
          "Laboratorial",
          laboratoryCases,
          calculatePercentage(
            laboratoryCases,
            laboratoryCases + epidemiologicClinicCases + underInvestigationCases
          )
        ),
        createConfirmationCriteriaPeriodDataObject(
          area,
          "epidemiologicClinicCases",
          "Clínico-epidemiológico",
          epidemiologicClinicCases,
          calculatePercentage(
            epidemiologicClinicCases,
            laboratoryCases + epidemiologicClinicCases + underInvestigationCases
          )
        ),
        createConfirmationCriteriaPeriodDataObject(
          area,
          "underInvestigation",
          "Sob Investigação",
          underInvestigationCases,
          calculatePercentage(
            underInvestigationCases,
            laboratoryCases + epidemiologicClinicCases + underInvestigationCases
          )
        ),
      ],
    };
  };

  const createAllAreasConfirmationCriteriaDataObject = (
    confirmationCriteriaFromAreasByPeriods
  ) => {
    const finalResult = {
      territorializationId: "Todas as áreas",
      territorializationName: "Todas as áreas",
      confirmationCriteriaByPeriods: [],
    };

    confirmationCriteriaFromAreasByPeriods.forEach(
      ({ confirmationCriteriaByPeriods }) => {
        confirmationCriteriaByPeriods.forEach(({ periodName, result }) => {
          let periodIndexOnFinalResult =
            finalResult.confirmationCriteriaByPeriods.findIndex(
              (period) => period.periodName === periodName
            );

          if (periodIndexOnFinalResult === -1)
            finalResult.confirmationCriteriaByPeriods.push(
              createConfirmationCriteriaPeriodResultDataObject(
                "Todas as áreas",
                periodName,
                0,
                0,
                0
              )
            );

          periodIndexOnFinalResult =
            finalResult.confirmationCriteriaByPeriods.findIndex(
              (period) => period.periodName === periodName
            );

          const actualResult =
            finalResult.confirmationCriteriaByPeriods[periodIndexOnFinalResult];

          if (actualResult) {
            actualResult.result[0].value += result[0].value;
            actualResult.result[1].value += result[1].value;
            actualResult.result[2].value += result[2].value;
          }
        });
      }
    );

    finalResult.confirmationCriteriaByPeriods.forEach((period) => {
      period.result[0].percentage = calculatePercentage(
        period.result[0].value,
        period.result[0].value + period.result[1].value + period.result[2].value
      );
      period.result[1].percentage = calculatePercentage(
        period.result[1].value,
        period.result[0].value + period.result[1].value + period.result[2].value
      );
      period.result[2].percentage = calculatePercentage(
        period.result[2].value,
        period.result[0].value + period.result[1].value + period.result[2].value
      );
    });

    return finalResult;
  };

  const generateConfirmationCriteriaFromAreasByPeriodsDataObject = (
    notificationsList
  ) => {
    let confirmationCriteriaFromAreasByPeriods = {};

    notificationsList.forEach(
      ({ resultsByPeriods, territorializationId, territorializationName }) => {
        if (!confirmationCriteriaFromAreasByPeriods[territorializationId])
          confirmationCriteriaFromAreasByPeriods[territorializationId] = {
            territorializationId,
            territorializationName,
            confirmationCriteriaByPeriods: [],
          };

        const actualAreaData =
          confirmationCriteriaFromAreasByPeriods[territorializationId];

        const areaConfirmationCriteriaTotalization = {
          laboratory: createConfirmationCriteriaPeriodDataObject(
            territorializationName,
            "laboratory",
            "Laboratorial"
          ),
          epidemiologicClinic: createConfirmationCriteriaPeriodDataObject(
            territorializationName,
            "epidemiologicClinic",
            "Clínico-epidemiológico"
          ),
          underInvestigation: createConfirmationCriteriaPeriodDataObject(
            territorializationName,
            "underInvestigation",
            "Sob Investigação"
          ),
        };

        resultsByPeriods.forEach(({ periodName, result: { criterion } }) => {
          const totalLaboratoryCases = criterion.laboratory;
          const totalEpidemiologicClinicCases = criterion.epidemiologicClinic;
          const totalUnderInvestigationCases = criterion.underInvestigation;

          areaConfirmationCriteriaTotalization.laboratory.value +=
            totalLaboratoryCases;
          areaConfirmationCriteriaTotalization.epidemiologicClinic.value +=
            totalEpidemiologicClinicCases;
          areaConfirmationCriteriaTotalization.underInvestigation.value +=
            totalUnderInvestigationCases;

          const confirmationCriteriaResult =
            createConfirmationCriteriaPeriodResultDataObject(
              territorializationName,
              periodName,
              totalLaboratoryCases,
              totalEpidemiologicClinicCases,
              totalUnderInvestigationCases
            );

          actualAreaData.confirmationCriteriaByPeriods.push(
            confirmationCriteriaResult
          );
        });

        const confirmationCriteriaTotalizationResult =
          createConfirmationCriteriaPeriodResultDataObject(
            territorializationName,
            "Todos os períodos",
            areaConfirmationCriteriaTotalization.laboratory.value,
            areaConfirmationCriteriaTotalization.epidemiologicClinic.value,
            areaConfirmationCriteriaTotalization.underInvestigation.value
          );

        actualAreaData.confirmationCriteriaByPeriods.push(
          confirmationCriteriaTotalizationResult
        );
      }
    );

    confirmationCriteriaFromAreasByPeriods = Array.from(
      Object.values(confirmationCriteriaFromAreasByPeriods)
    );

    const allPeriodsData = createAllAreasConfirmationCriteriaDataObject(
      confirmationCriteriaFromAreasByPeriods
    );

    confirmationCriteriaFromAreasByPeriods.push(allPeriodsData);

    return confirmationCriteriaFromAreasByPeriods;
  };

  const getSelectedPolygonsList = (filters) => {
    const polygonsList = filters.territorializations.map(
      ({ label, value }) => ({
        label: label,
        value: value,
      })
    );

    return [
      {
        label: "Todas as áreas",
        value: "Todas as áreas",
      },
      ...polygonsList,
    ];
  };

  const getAppliedDateFilterType = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") return "date";

    if (filters.datePeriodType === "week") return "week";

    if (filters.datePeriodType === "month") return "month";

    return "";
  };

  const getSelectedPeriodsList = (filters) => {
    const filterType = getAppliedDateFilterType(filters);

    if (filterType === "date") {
      const periodLabel = `${moment(filters.beginDate).format(
        "DD/MM/YYYY"
      )} à ${moment(filters.endDate).format("DD/MM/YYYY")}`;

      return [
        {
          label: "Todos os períodos",
          value: "Todos os períodos",
        },
        {
          label: periodLabel,
          value: periodLabel,
        },
      ];
    }

    if (filterType === "week") {
      const result = filters.epidemiologicalWeeks.map(({ label }) => ({
        label: label,
        value: label,
      }));

      return [
        {
          label: "Todos os períodos",
          value: "Todos os períodos",
        },
        ...result,
      ];
    }

    if (filterType === "month") {
      const result = filters.months.map(({ label }) => ({
        label: label,
        value: label,
      }));

      return [
        {
          label: "Todos os períodos",
          value: "Todos os períodos",
        },
        ...result,
      ];
    }

    return [];
  };

  const handleFiltersChange = (filterName, value) => {
    setFilters((previousValues) => ({
      ...previousValues,
      [filterName]: value,
    }));
  };

  return {
    appliedFilters,
    dateFilterType,
    filters,
    filteredPeriodsList,
    filteredPolygonsList,
    formatedEvolutionData,
    handleFiltersChange,
    polygonsDataFromSamePeriod,
  };
};

export const ConfirmationCriteriaGraphic = ({
  notificationsList,
  periodLegend,
  variant,
}) => {
  const {
    appliedFilters,
    dateFilterType,
    filteredPeriodsList,
    filteredPolygonsList,
    filters,
    formatedEvolutionData,
    handleFiltersChange,
    polygonsDataFromSamePeriod,
  } = useConfirmationCriteriaGraphic(notificationsList, variant);

  return (
    <ConfirmationCriteriaPieGraphic
      appliedFilters={appliedFilters}
      data={formatedEvolutionData}
      dateFilterType={dateFilterType}
      filters={filters}
      filteredPeriodsList={filteredPeriodsList}
      filteredPolygonsList={filteredPolygonsList}
      handleFiltersChange={handleFiltersChange}
      periodLegend={periodLegend}
      polygonsDataFromSamePeriod={polygonsDataFromSamePeriod}
      variant={variant}
    />
  );
};

const ConfirmationCriteriaPieGraphic = ({
  appliedFilters,
  data,
  dateFilterType,
  filters,
  filteredPeriodsList,
  filteredPolygonsList,
  handleFiltersChange,
  periodLegend,
  polygonsDataFromSamePeriod,
  variant,
}) => {
  const [legend, setLegend] = React.useState("");
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (!filters.polygon && !filters.period) return;

    if (dateFilterType === "date") {
      setLegend(
        <span>
          Critério de Confirmação <br />
          {periodLegend}
          <br /> na área {filters.polygon.label}
        </span>
      );

      return;
    }

    if (dateFilterType === "week") {
      if (filters.period.label === "Todos os períodos") {
        setLegend(
          <span>
            Critério de Confirmação <br />
            {periodLegend}
            <br /> na área {filters.polygon.label}
          </span>
        );

        return;
      }

      setLegend(
        <span>
          Critério de Confirmação <br />
          na {filters.period.label} de {appliedFilters.year.value}
          <br /> na área {filters.polygon.label}
        </span>
      );
    }

    if (dateFilterType === "month") {
      if (filters.period.label === "Todos os períodos") {
        setLegend(
          <span>
            Critério de Confirmação <br />
            {periodLegend}
            <br /> na área {filters.polygon.label}
          </span>
        );

        return;
      }

      setLegend(
        <span>
          Critério de Confirmação <br />
          em {filters.period.label} de {appliedFilters.year.value}
          <br /> na área {filters.polygon.label}
        </span>
      );
    }
  }, [filters, dateFilterType]);

  const dataFromPolygon =
    data &&
    filters.polygon &&
    filters.period &&
    data.find(
      ({ territorializationId }) =>
        territorializationId === filters.polygon.value
    );

  const dataFromPolygonByPeriod =
    dataFromPolygon &&
    dataFromPolygon.confirmationCriteriaByPeriods.find(
      ({ periodName }) => periodName === filters.period.value
    );

  return (
    <VitecCard>
      <VitecCardModal>
        <VitecCardModalHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Visualização em tela cheia
        </VitecCardModalHeader>
        <VitecCardModalContent style={{ height: undefined }}>
          <VitecCard>
            <VitecCardDefaultView>
              <VitecCardDefaultViewHeader
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="bold">{legend}</span>
                <div style={{ display: "flex", gap: ".3rem" }}>
                  <Button
                    style={{
                      marginRight: "0px",
                      marginLeft: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "58px",
                      height: "45px",
                    }}
                    color="primary"
                    id={`evolution-filters-popover-modal`}
                  >
                    <i className="fa fa-filter"></i>
                  </Button>
                  <UncontrolledPopover
                    style={{ width: "250px" }}
                    id="filtersPopover"
                    placement="top"
                    target={`evolution-filters-popover-modal`}
                  >
                    <PopoverHeader>Filtros</PopoverHeader>
                    <PopoverBody style={{ backgroundColor: "#ffffff" }}>
                      <div>
                        <div className="mb-2">
                          <label className="bold">Área:</label>
                          <Select
                            styles={selectComponentStyles}
                            placeholder={"Selecione"}
                            isMulti={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={filters.polygon}
                            options={filteredPolygonsList}
                            onChange={(e) => handleFiltersChange("polygon", e)}
                          />
                        </div>
                        <div className="mb-2">
                          <label className="bold">
                            Comparar período entre áreas:
                          </label>
                          <Select
                            styles={selectComponentStyles}
                            placeholder={"Selecione"}
                            isMulti={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={filters.compareSamePeriodFromDifferentAreas}
                            options={[
                              {
                                label: "Sim",
                                value: true,
                              },
                              {
                                label: "Não",
                                value: false,
                              },
                            ]}
                            onChange={(e) =>
                              handleFiltersChange(
                                "compareSamePeriodFromDifferentAreas",
                                e
                              )
                            }
                          />
                        </div>

                        <div className="mb-2">
                          <label className="bold">Período:</label>
                          <Select
                            styles={selectComponentStyles}
                            placeholder={"Selecione"}
                            isMulti={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={filters.period}
                            options={filteredPeriodsList}
                            onChange={(e) => handleFiltersChange("period", e)}
                            isDisabled={
                              filters.compareSamePeriodFromDifferentAreas &&
                              !filters.compareSamePeriodFromDifferentAreas.value
                            }
                          />
                        </div>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <VitecCardDownloadImageButton
                    imageFileName={`CASOS_POR_SEXO_SINAN_VITEC_${moment().format(
                      "DD/MM/YYYY HH:mm"
                    )}`}
                    color="primary"
                    style={{ width: "58px", height: "45px" }}
                    showTooltip={false}
                  />
                </div>
              </VitecCardDefaultViewHeader>
              <VitecCardDefaultViewContent
                style={{
                  minHeight: "80vh",
                  height: "auto",
                }}
              >
                {filters &&
                filters.compareSamePeriodFromDifferentAreas &&
                !filters.compareSamePeriodFromDifferentAreas.value &&
                dataFromPolygon &&
                dataFromPolygon.confirmationCriteriaByPeriods ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        screenWidth > 1600
                          ? "repeat(5, 1fr)"
                          : "repeat(3, 1fr)",
                      gap: "10px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {dataFromPolygon.confirmationCriteriaByPeriods.map(
                      (period) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "1rem",
                                display: "block",
                                textAlign: "center",
                              }}
                            >
                              <span
                                className="bold"
                                style={{ display: "block", lineHeight: 1 }}
                              >
                                {period.periodName}
                              </span>
                              <span style={{ fontSize: ".8rem" }}>
                                <span
                                  style={{
                                    color: getColorByIndex(0),
                                    lineHeight: 1,
                                    display: "block",
                                  }}
                                >
                                  Laboratorial: {period.result[0].value} (
                                  {period.result[0].percentage}
                                  %)
                                </span>
                                <span
                                  style={{
                                    color: getColorByIndex(1),
                                    lineHeight: 1,
                                    display: "block",
                                  }}
                                >
                                  Clínico-epidemiológico:{" "}
                                  {period.result[1].value} (
                                  {period.result[1].percentage}
                                  %)
                                </span>
                                <span
                                  style={{
                                    color: getColorByIndex(2),
                                    lineHeight: 1,
                                    display: "block",
                                  }}
                                >
                                  Sob Investigação: {period.result[2].value} (
                                  {period.result[2].percentage}
                                  %)
                                </span>
                              </span>
                            </span>
                            {period.result[0].value === 0 &&
                            period.result[1].value === 0 &&
                            period.result[2].value === 0 ? (
                              <ResponsiveContainer width="100%" minHeight={250}>
                                <PieChart width={300} height={250}></PieChart>
                              </ResponsiveContainer>
                            ) : (
                              <ResponsiveContainer width="100%" minHeight={250}>
                                <PieChart width={300} height={250}>
                                  <Pie
                                    data={period.result}
                                    dataKey="value"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                  >
                                    {period.result.map((_, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={getColorByIndex(index)}
                                      />
                                    ))}
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : filters &&
                  filters.compareSamePeriodFromDifferentAreas &&
                  filters.compareSamePeriodFromDifferentAreas.value ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(5, 1fr)",
                      gap: "10px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {polygonsDataFromSamePeriod.map((period) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "1rem",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="bold"
                              style={{ display: "block", lineHeight: 1 }}
                            >
                              {period[0].area}
                            </span>
                            <span style={{ fontSize: ".8rem" }}>
                              <span
                                style={{
                                  color: getColorByIndex(0),
                                  lineHeight: 1,
                                  display: "block",
                                }}
                              >
                                Laboratorial: {period[0].value} (
                                {period[0].percentage}
                                %)
                              </span>
                              <span
                                style={{
                                  color: getColorByIndex(1),
                                  lineHeight: 1,
                                  display: "block",
                                }}
                              >
                                Clínico-epidemiológico: {period[1].value} (
                                {period[1].percentage}
                                %)
                              </span>
                              <span
                                style={{
                                  color: getColorByIndex(2),
                                  lineHeight: 1,
                                  display: "block",
                                }}
                              >
                                Sob Investigação: {period[2].value} (
                                {period[2].percentage}
                                %)
                              </span>
                            </span>
                          </span>
                          {period[0].value === 0 &&
                          period[1].value &&
                          period[2].value === 0 ? (
                            <ResponsiveContainer width="100%" minHeight={250}>
                              <PieChart width={300} height={250}></PieChart>
                            </ResponsiveContainer>
                          ) : (
                            <ResponsiveContainer width="100%" minHeight={250}>
                              <PieChart width={300} height={250}>
                                <Pie
                                  data={period}
                                  dataKey="value"
                                  cx="50%"
                                  cy="50%"
                                  outerRadius={80}
                                  fill="#8884d8"
                                >
                                  {period.map((_, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={getColorByIndex(index)}
                                    />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </VitecCardDefaultViewContent>
              <VitecCardDefaultViewFooter>
                <VitecCardGeneratedAt />
              </VitecCardDefaultViewFooter>
            </VitecCardDefaultView>
          </VitecCard>
        </VitecCardModalContent>
        <VitecCardModalFooter />
      </VitecCardModal>
      <VitecCardDefaultView>
        <VitecCardDefaultViewHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", color: "black" }}>{legend}</span>
          <div style={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
            <GraphicFiltersPopover
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              filteredPeriodsList={filteredPeriodsList}
              filteredPolygonsList={filteredPolygonsList}
              variant={variant}
            />
            <VitecCardToggleModalButton
              color="primary"
              style={{ width: "58px", height: "45px" }}
            />
            <VitecCardDownloadImageButton
              imageFileName={`CASOS_POR_SEXO_SINAN_VITEC_${moment().format(
                "DD/MM/YYYY HH:mm"
              )}`}
              color="primary"
              style={{ width: "58px", height: "45px" }}
            />
          </div>
        </VitecCardDefaultViewHeader>
        <VitecCardDefaultViewContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "25rem",
          }}
        >
          <div style={{ width: "50%" }}>
            <ul>
              {dataFromPolygonByPeriod &&
                dataFromPolygonByPeriod.result.map(
                  ({ label, percentage, value }, index) => {
                    return (
                      <li
                        key={`${label}-${percentage}-${value}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "14px",
                            height: "10px",
                            backgroundColor: getColorByIndex(index),
                          }}
                        ></div>
                        <span style={{ color: getColorByIndex(index) }}>
                          {label}:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            ({value}) {percentage}%
                          </span>
                        </span>
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
          {dataFromPolygonByPeriod && (
            <ResponsiveContainer width="50%" minHeight={250}>
              <PieChart width={900} height={900}>
                <Pie
                  data={dataFromPolygonByPeriod.result}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey={"value"}
                >
                  {dataFromPolygonByPeriod.result.map(({ label }, index) => (
                    <Cell
                      key={`gender-cell-${index}`}
                      fill={getColorByIndex(index)}
                      name={label}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          )}
        </VitecCardDefaultViewContent>
        <VitecCardDefaultViewFooter>
          <VitecCardGeneratedAt />
        </VitecCardDefaultViewFooter>
      </VitecCardDefaultView>
    </VitecCard>
  );
};

const GraphicFiltersPopover = ({
  filters,
  filteredPeriodsList,
  filteredPolygonsList,
  handleFiltersChange,
  variant,
}) => {
  return (
    <>
      <ReactTooltip effect="solid" type="info" id={`evolution-tooltip`}>
        Filtros
      </ReactTooltip>
      <Button
        data-tip
        data-for={`evolution-tooltip`}
        style={{
          marginRight: "0px",
          marginLeft: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "58px",
          height: "45px",
        }}
        id={`evolution-filters-popover`}
        color="primary"
        type="button"
      >
        <i className="fa fa-filter"></i>
      </Button>
      <UncontrolledPopover
        style={{ width: "300px" }}
        id="filtersPopover"
        placement="bottom"
        target={`evolution-filters-popover`}
      >
        <PopoverHeader>Filtros</PopoverHeader>
        <PopoverBody style={{ backgroundColor: "#ffffff" }}>
          <div>
            <div className="mb-2">
              <label className="bold">Área:</label>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={filters.polygon}
                options={filteredPolygonsList}
                onChange={(e) => handleFiltersChange("polygon", e)}
              />
            </div>
            <div className="mb-2">
              <label className="bold">Período:</label>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={filters.period}
                options={filteredPeriodsList}
                onChange={(e) => handleFiltersChange("period", e)}
              />
            </div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
