import React from "react";

import {
  VitecCard,
  VitecCardDefaultView,
  VitecCardDefaultViewContent,
  VitecCardDefaultViewFooter,
  VitecCardDefaultViewHeader,
  VitecCardDownloadImageButton,
  VitecCardGeneratedAt,
  VitecCardModal,
  VitecCardModalContent,
  VitecCardModalFooter,
  VitecCardModalHeader,
  VitecCardToggleModalButton,
} from "../../../../../components/Cards/VitecCard";
import moment from "moment";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getColorByIndex,
  selectComponentStyles,
} from "../../../../../services/utils/globalFunctions";
import { Select } from "../../../../../components/Wrappers/SelectAll";
import { useAppliedFilters } from "../../Hooks/useAppliedFilters";

const AGE_GROUP_INTERVALS = [
  { ageRange: "85+", male: 0, female: 0, minAge: 85, maxAge: Infinity },
  { ageRange: "80-84", male: 0, female: 0, minAge: 80, maxAge: 84 },
  { ageRange: "75-79", male: 0, female: 0, minAge: 75, maxAge: 79 },
  { ageRange: "70-74", male: 0, female: 0, minAge: 70, maxAge: 74 },
  { ageRange: "65-69", male: 0, female: 0, minAge: 65, maxAge: 69 },
  { ageRange: "60-64", male: 0, female: 0, minAge: 60, maxAge: 64 },
  { ageRange: "55-59", male: 0, female: 0, minAge: 55, maxAge: 59 },
  { ageRange: "50-54", male: 0, female: 0, minAge: 50, maxAge: 54 },
  { ageRange: "45-49", male: 0, female: 0, minAge: 45, maxAge: 49 },
  { ageRange: "40-44", male: 0, female: 0, minAge: 40, maxAge: 44 },
  { ageRange: "35-39", male: 0, female: 0, minAge: 35, maxAge: 39 },
  { ageRange: "30-34", male: 0, female: 0, minAge: 30, maxAge: 34 },
  { ageRange: "25-29", male: 0, female: 0, minAge: 25, maxAge: 29 },
  { ageRange: "20-24", male: 0, female: 0, minAge: 20, maxAge: 24 },
  { ageRange: "15-19", male: 0, female: 0, minAge: 15, maxAge: 19 },
  { ageRange: "10-14", male: 0, female: 0, minAge: 10, maxAge: 14 },
  { ageRange: "5-9", male: 0, female: 0, minAge: 5, maxAge: 9 },
  { ageRange: "0-4", male: 0, female: 0, minAge: 0, maxAge: 4 },
];

const useDeathsAgeGroupGraphic = (notificationsList) => {
  const [formatedDeathsAgeGroupData, setFormatedDeathsAgeGroupData] =
    React.useState([]);
  const [filters, setFilters] = React.useState({
    polygon: null,
    period: null,
    compareSamePeriodFromDifferentAreas: null,
  });
  const [filteredPolygonsList, setFilteredPolygonsList] = React.useState([]);
  const [filteredPeriodsList, setFilteredPeriodsList] = React.useState([]);
  const [polygonsDataFromSamePeriod, setPolygonsDataFromSamePeriod] =
    React.useState([]);
  const [dateFilterType, setDateFilterType] = React.useState("date");
  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    const polygonsList = getSelectedPolygonsList(appliedFilters);
    const periodsList = getSelectedPeriodsList(appliedFilters);

    setFilteredPolygonsList(polygonsList);
    setFilteredPeriodsList(periodsList);

    setFilters({
      polygon: polygonsList[0],
      period: periodsList[0],
      compareSamePeriodFromDifferentAreas: {
        label: "Não",
        value: false,
      },
    });
    setDateFilterType(getAppliedDateFilterType(appliedFilters));
  }, [appliedFilters]);

  React.useEffect(() => {
    if (
      !filters ||
      !filters.compareSamePeriodFromDifferentAreas ||
      !filters.compareSamePeriodFromDifferentAreas.value
    )
      return;

    const result = [];

    formatedDeathsAgeGroupData.forEach((area) => {
      const periodData = area.deathsAgeGroupsByPeriods.find(
        ({ periodName }) => periodName === filters.period.value
      );

      if (periodData) {
        result.push(periodData.result);
      }
    });

    setPolygonsDataFromSamePeriod(result);
  }, [filters]);

  React.useEffect(() => {
    if (notificationsList.length === 0) return;

    const result =
      generateDeathsAgeGroupsFromAreasByPeriodsDataObject(notificationsList);

    setFormatedDeathsAgeGroupData(result);
  }, [notificationsList]);

  const createDeathsAgeGroupsPeriodDataObject = (
    area,
    ageGroups = JSON.parse(JSON.stringify(AGE_GROUP_INTERVALS))
  ) => {
    return {
      area,
      ageGroups,
    };
  };

  const createDeathsAgeGroupPeriodResultDataObject = (
    area,
    periodName,
    ageGroups
  ) => {
    return {
      periodName,
      result: [createDeathsAgeGroupsPeriodDataObject(area, ageGroups)],
    };
  };

  const generateDeathsAgeGroupsFromAreasByPeriodsDataObject = (
    notificationsList
  ) => {
    let deathsAgeGroupFromAreasByPeriods = {};

    notificationsList.forEach(
      ({ resultsByPeriods, territorializationId, territorializationName }) => {
        if (!deathsAgeGroupFromAreasByPeriods[territorializationId])
          deathsAgeGroupFromAreasByPeriods[territorializationId] = {
            territorializationId,
            territorializationName,
            deathsAgeGroupsByPeriods: [],
          };

        const actualAreaData =
          deathsAgeGroupFromAreasByPeriods[territorializationId];

        const areaDeathsAgeGroupTotalization =
          createDeathsAgeGroupsPeriodDataObject(territorializationName);

        resultsByPeriods.forEach(
          ({ periodName, result: { confirmed, notifications } }) => {
            const areaResult = JSON.parse(JSON.stringify(AGE_GROUP_INTERVALS));

            const casesByPersons = [
              ...confirmed.casesByPersons,
              ...notifications.casesByPersons,
            ];

            casesByPersons.forEach(({ personAge, sex, evolution }) => {
              const ageGroupArrayIndex = areaResult.findIndex(
                ({ minAge, maxAge }) =>
                  personAge >= minAge && personAge <= maxAge
              );

              if (ageGroupArrayIndex !== -1) {
                if (sex === "F" && evolution === 2) {
                  areaResult[ageGroupArrayIndex].female += 1;
                  areaDeathsAgeGroupTotalization.ageGroups[
                    ageGroupArrayIndex
                  ].female += 1;
                }

                if (sex === "M" && evolution === 2) {
                  areaResult[ageGroupArrayIndex].male += 1;
                  areaDeathsAgeGroupTotalization.ageGroups[
                    ageGroupArrayIndex
                  ].male += 1;
                }
              }
            });

            const confirmationCriteriaResult =
              createDeathsAgeGroupPeriodResultDataObject(
                territorializationName,
                periodName,
                areaResult
              );

            actualAreaData.deathsAgeGroupsByPeriods.push(
              confirmationCriteriaResult
            );
          }
        );

        const confirmationCriteriaTotalizationResult =
          createDeathsAgeGroupPeriodResultDataObject(
            territorializationName,
            "Todos os períodos",
            areaDeathsAgeGroupTotalization.ageGroups
          );

        actualAreaData.deathsAgeGroupsByPeriods.push(
          confirmationCriteriaTotalizationResult
        );
      }
    );

    deathsAgeGroupFromAreasByPeriods = Array.from(
      Object.values(deathsAgeGroupFromAreasByPeriods)
    );

    const allPeriodsData = createAllAreasDeathsAgeGroupaDataObject(
      deathsAgeGroupFromAreasByPeriods
    );

    deathsAgeGroupFromAreasByPeriods.push(allPeriodsData);

    console.log(
      "deathsAgeGroupFromAreasByPeriods",
      deathsAgeGroupFromAreasByPeriods
    );

    return deathsAgeGroupFromAreasByPeriods;
  };

  const createAllAreasDeathsAgeGroupaDataObject = (
    deathsFromAreasByPeriods
  ) => {
    const finalResult = {
      territorializationId: "Todas as áreas",
      territorializationName: "Todas as áreas",
      deathsAgeGroupsByPeriods: [],
    };

    deathsFromAreasByPeriods.forEach(({ deathsAgeGroupsByPeriods }) => {
      deathsAgeGroupsByPeriods.forEach(({ periodName, result }) => {
        let periodIndexOnFinalResult =
          finalResult.deathsAgeGroupsByPeriods.findIndex(
            (period) => period.periodName === periodName
          );

        if (periodIndexOnFinalResult === -1)
          finalResult.deathsAgeGroupsByPeriods.push(
            createDeathsAgeGroupPeriodResultDataObject(
              "Todas as áreas",
              periodName
            )
          );

        periodIndexOnFinalResult =
          finalResult.deathsAgeGroupsByPeriods.findIndex(
            (period) => period.periodName === periodName
          );

        const actualResult =
          finalResult.deathsAgeGroupsByPeriods[periodIndexOnFinalResult];

        if (actualResult) {
          actualResult.result.forEach(({ ageGroups }) => {
            ageGroups.forEach((_, index) => {
              actualResult.result[0].ageGroups[index].male +=
                result[0].ageGroups[index].male;
              actualResult.result[0].ageGroups[index].female +=
                result[0].ageGroups[index].female;
            });
          });
        }
      });
    });

    return finalResult;
  };

  const getSelectedPolygonsList = (filters) => {
    const polygonsList = filters.territorializations.map(
      ({ label, value }) => ({
        label: label,
        value: value,
      })
    );

    return [
      {
        label: "Todas as áreas",
        value: "Todas as áreas",
      },
      ...polygonsList,
    ];
  };

  const getAppliedDateFilterType = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") return "date";

    if (filters.datePeriodType === "week") return "week";

    if (filters.datePeriodType === "month") return "month";

    return "";
  };

  const getSelectedPeriodsList = (filters) => {
    const filterType = getAppliedDateFilterType(filters);

    if (filterType === "date") {
      const periodLabel = `${moment(filters.beginDate).format(
        "DD/MM/YYYY"
      )} à ${moment(filters.endDate).format("DD/MM/YYYY")}`;

      return [
        {
          label: "Todos os períodos",
          value: "Todos os períodos",
        },
        {
          label: periodLabel,
          value: periodLabel,
        },
      ];
    }

    if (filterType === "week") {
      const result = filters.epidemiologicalWeeks.map(({ label }) => ({
        label: label,
        value: label,
      }));

      return [
        {
          label: "Todos os períodos",
          value: "Todos os períodos",
        },
        ...result,
      ];
    }

    if (filterType === "month") {
      const result = filters.months.map(({ label }) => ({
        label: label,
        value: label,
      }));

      return [
        {
          label: "Todos os períodos",
          value: "Todos os períodos",
        },
        ...result,
      ];
    }

    return [];
  };

  const handleFiltersChange = (filterName, value) => {
    setFilters((previousValues) => ({
      ...previousValues,
      [filterName]: value,
    }));
  };

  return {
    formatedDeathsAgeGroupData,
    appliedFilters,
    dateFilterType,
    filters,
    filteredPeriodsList,
    filteredPolygonsList,
    handleFiltersChange,
    polygonsDataFromSamePeriod,
  };
};

export const DeathsAgeGroupGraphic = ({
  graphicType,
  graphicTypeOptions,
  handleGraphicTypeChange,
  notificationsList,
  periodLegend,
}) => {
  const {
    formatedDeathsAgeGroupData,
    appliedFilters,
    dateFilterType,
    filteredPeriodsList,
    filteredPolygonsList,
    filters,
    handleFiltersChange,
    polygonsDataFromSamePeriod,
  } = useDeathsAgeGroupGraphic(notificationsList);

  return (
    <DeathsAgeGroupPyramidGraphic
      appliedFilters={appliedFilters}
      data={formatedDeathsAgeGroupData}
      dateFilterType={dateFilterType}
      filters={filters}
      filteredPeriodsList={filteredPeriodsList}
      filteredPolygonsList={filteredPolygonsList}
      graphicType={graphicType}
      graphicTypeOptions={graphicTypeOptions}
      handleGraphicTypeChange={handleGraphicTypeChange}
      handleFiltersChange={handleFiltersChange}
      periodLegend={periodLegend}
      polygonsDataFromSamePeriod={polygonsDataFromSamePeriod}
    />
  );
};

const DeathsAgeGroupPyramidGraphic = ({
  appliedFilters,
  data,
  dateFilterType,
  filters,
  filteredPeriodsList,
  filteredPolygonsList,
  graphicType,
  graphicTypeOptions,
  handleGraphicTypeChange,
  handleFiltersChange,
  periodLegend,
  polygonsDataFromSamePeriod,
}) => {
  const [legend, setLegend] = React.useState("");
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (!filters.polygon && !filters.period) return;

    if (dateFilterType === "date") {
      setLegend(
        <span>
          Óbitos por Faixa Etária <br />
          {periodLegend}
          <br /> na área {filters.polygon.label}
        </span>
      );

      return;
    }

    if (dateFilterType === "week") {
      if (filters.period.label === "Todos os períodos") {
        setLegend(
          <span>
            Óbitos por Faixa Etária <br />
            {periodLegend}
            <br /> na área {filters.polygon.label}
          </span>
        );

        return;
      }

      setLegend(
        <span>
          Óbitos por Faixa Etária <br />
          na {filters.period.label} de {appliedFilters.year.value}
          <br /> na área {filters.polygon.label}
        </span>
      );
    }

    if (dateFilterType === "month") {
      if (filters.period.label === "Todos os períodos") {
        setLegend(
          <span>
            Óbitos por Faixa Etária <br />
            {periodLegend}
            <br /> na área {filters.polygon.label}
          </span>
        );

        return;
      }

      setLegend(
        <span>
          Óbitos por Faixa Etária <br />
          em {filters.period.label} de {appliedFilters.year.value}
          <br /> na área {filters.polygon.label}
        </span>
      );
    }
  }, [filters, dateFilterType]);

  const dataFromPolygon =
    data &&
    filters.polygon &&
    filters.period &&
    data.find(
      ({ territorializationId }) =>
        territorializationId === filters.polygon.value
    );

  const dataFromPolygonByPeriod =
    dataFromPolygon &&
    dataFromPolygon.deathsAgeGroupsByPeriods.find(
      ({ periodName }) => periodName === filters.period.value
    );

  console.log("dataFromPolygon", dataFromPolygon);
  console.log("dataFromPolygonByPeriod", dataFromPolygonByPeriod);

  return (
    <VitecCard>
      <VitecCardModal>
        <VitecCardModalHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Visualização em tela cheia
        </VitecCardModalHeader>
        <VitecCardModalContent style={{ height: undefined }}>
          <VitecCard>
            <VitecCardDefaultView>
              <VitecCardDefaultViewHeader
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="bold">{legend}</span>
                <div style={{ display: "flex", gap: ".3rem" }}>
                  <Button
                    style={{
                      marginRight: "0px",
                      marginLeft: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "58px",
                      height: "45px",
                    }}
                    color="primary"
                    id={`confirmed-ageGroup-filters-popover-modal`}
                  >
                    <i className="fa fa-filter"></i>
                  </Button>
                  <UncontrolledPopover
                    style={{ width: "250px" }}
                    id="filtersPopover"
                    placement="bottom"
                    target={`confirmed-ageGroup-filters-popover-modal`}
                  >
                    <PopoverHeader>Filtros</PopoverHeader>
                    <PopoverBody style={{ backgroundColor: "#ffffff" }}>
                      <div>
                        <div className="mb-2">
                          <label className="bold">Área:</label>
                          <Select
                            styles={selectComponentStyles}
                            placeholder={"Selecione"}
                            isMulti={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={filters.polygon}
                            options={filteredPolygonsList}
                            onChange={(e) => handleFiltersChange("polygon", e)}
                          />
                        </div>
                        <div className="mb-2">
                          <label className="bold">
                            Comparar período entre áreas:
                          </label>
                          <Select
                            styles={selectComponentStyles}
                            placeholder={"Selecione"}
                            isMulti={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={filters.compareSamePeriodFromDifferentAreas}
                            options={[
                              {
                                label: "Sim",
                                value: true,
                              },
                              {
                                label: "Não",
                                value: false,
                              },
                            ]}
                            onChange={(e) =>
                              handleFiltersChange(
                                "compareSamePeriodFromDifferentAreas",
                                e
                              )
                            }
                          />
                        </div>
                        <div className="mb-2">
                          <label className="bold">Período:</label>
                          <Select
                            styles={selectComponentStyles}
                            placeholder={"Selecione"}
                            isMulti={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={filters.period}
                            options={filteredPeriodsList}
                            onChange={(e) => handleFiltersChange("period", e)}
                            isDisabled={
                              filters.compareSamePeriodFromDifferentAreas &&
                              !filters.compareSamePeriodFromDifferentAreas.value
                            }
                          />
                        </div>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <VitecCardDownloadImageButton
                    imageFileName={`NOTIFICAÇÕES_FAIXA_ETARIA_SINAN_VITEC_${moment().format(
                      "DD/MM/YYYY HH:mm"
                    )}`}
                    color="primary"
                    style={{ width: "58px", height: "45px" }}
                    showTooltip={false}
                  />
                </div>
              </VitecCardDefaultViewHeader>
              <VitecCardDefaultViewContent
                style={{
                  minHeight: "80vh",
                  height: "auto",
                }}
              >
                {filters &&
                filters.compareSamePeriodFromDifferentAreas &&
                !filters.compareSamePeriodFromDifferentAreas.value &&
                dataFromPolygon &&
                dataFromPolygon.deathsAgeGroupsByPeriods ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        screenWidth > 1600
                          ? "repeat(5, 1fr)"
                          : "repeat(3, 1fr)",
                      gap: "10px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {dataFromPolygon.deathsAgeGroupsByPeriods.map((period) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "1rem",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="bold"
                              style={{ display: "block", lineHeight: 1 }}
                            >
                              {period.periodName}
                            </span>
                          </span>
                          <ResponsiveContainer
                            width="100%"
                            height="100%"
                            minHeight={250}
                          >
                            <BarChart
                              width={300}
                              height={250}
                              data={period.result[0].ageGroups.map((group) => ({
                                ...group,
                                male: group.male === 0 ? 0 : group.male * -1,
                                female: group.female * 1,
                              }))}
                              layout={"vertical"}
                              stackOffset="sign"
                              margin={{
                                top: 30,
                                right: 15,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                type={"number"}
                                style={{ fontSize: 12 }}
                                tickFormatter={(v) => (v < 0 ? -v : v)}
                              />
                              <YAxis
                                dataKey="ageRange"
                                type="category"
                                style={{ fontSize: 12 }}
                                interval={0}
                              />
                              <Tooltip formatter={(v) => (v < 0 ? -v : v)} />
                              <Legend />
                              <ReferenceLine x={0} />
                              <Bar
                                dataKey="male"
                                name="Masculino"
                                stackId="a"
                                fill={getColorByIndex(0)}
                                activeBar={
                                  <Rectangle fill="pink" stroke="blue" />
                                }
                              />
                              <Bar
                                dataKey="female"
                                name="Feminino"
                                stackId="a"
                                fill={getColorByIndex(1)}
                                activeBar={
                                  <Rectangle fill="pink" stroke="blue" />
                                }
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      );
                    })}
                  </div>
                ) : filters &&
                  filters.compareSamePeriodFromDifferentAreas &&
                  filters.compareSamePeriodFromDifferentAreas.value ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(5, 1fr)",
                      gap: "10px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {polygonsDataFromSamePeriod.map((period) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "1rem",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="bold"
                              style={{ display: "block", lineHeight: 1 }}
                            >
                              {period[0].area}
                            </span>
                          </span>
                          <ResponsiveContainer width="100%" minHeight={250}>
                            <BarChart
                              width={300}
                              height={250}
                              data={period[0].ageGroups.map((group) => ({
                                ...group,
                                male: group.male === 0 ? 0 : group.male * -1,
                                female: group.female * 1,
                              }))}
                              layout={"vertical"}
                              stackOffset="sign"
                              margin={{
                                top: 30,
                                right: 15,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                type={"number"}
                                style={{ fontSize: 12 }}
                                tickFormatter={(v) => (v < 0 ? -v : v)}
                              />
                              <YAxis
                                dataKey="ageRange"
                                type="category"
                                style={{ fontSize: 12 }}
                                interval={0}
                              />
                              <Tooltip formatter={(v) => (v < 0 ? -v : v)} />
                              <Legend />
                              <ReferenceLine x={0} />
                              <Bar
                                dataKey="male"
                                name="Masculino"
                                stackId="a"
                                fill={getColorByIndex(0)}
                                activeBar={
                                  <Rectangle fill="pink" stroke="blue" />
                                }
                              />
                              <Bar
                                dataKey="female"
                                name="Feminino"
                                stackId="a"
                                fill={getColorByIndex(1)}
                                activeBar={
                                  <Rectangle fill="pink" stroke="blue" />
                                }
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </VitecCardDefaultViewContent>
              <VitecCardDefaultViewFooter>
                <VitecCardGeneratedAt />
              </VitecCardDefaultViewFooter>
            </VitecCardDefaultView>
          </VitecCard>
        </VitecCardModalContent>
        <VitecCardModalFooter />
      </VitecCardModal>
      <VitecCardDefaultView>
        <VitecCardDefaultViewHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", color: "black" }}>{legend}</span>
          <div style={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
            <GraphicFiltersPopover
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              filteredPeriodsList={filteredPeriodsList}
              filteredPolygonsList={filteredPolygonsList}
            />
            <VitecCardToggleModalButton
              color="primary"
              style={{ width: "58px", height: "45px" }}
            />
            <VitecCardDownloadImageButton
              imageFileName={`CASOS_POR_SEXO_SINAN_VITEC_${moment().format(
                "DD/MM/YYYY HH:mm"
              )}`}
              color="primary"
              style={{ width: "58px", height: "45px" }}
            />
            <div style={{ width: "170px" }}>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={graphicType}
                options={graphicTypeOptions}
                onChange={(e) => handleGraphicTypeChange(e)}
              />
            </div>
          </div>
        </VitecCardDefaultViewHeader>
        <VitecCardDefaultViewContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "25rem",
          }}
        >
          {dataFromPolygonByPeriod && (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={dataFromPolygonByPeriod.result[0].ageGroups.map(
                  (group) => ({
                    ...group,
                    male: group.male === 0 ? 0 : group.male * -1,
                    female: group.female * 1,
                  })
                )}
                layout={"vertical"}
                stackOffset="sign"
                margin={{
                  top: 30,
                  right: 15,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  type={"number"}
                  style={{ fontSize: 12 }}
                  tickFormatter={(v) => (v < 0 ? -v : v)}
                />
                <YAxis
                  dataKey="ageRange"
                  type="category"
                  style={{ fontSize: 12 }}
                  interval={0}
                />
                <Tooltip formatter={(v) => (v < 0 ? -v : v)} />
                <Legend />
                <ReferenceLine x={0} />
                <Bar
                  dataKey="male"
                  name="Masculino"
                  stackId="a"
                  fill={getColorByIndex(0)}
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
                <Bar
                  dataKey="female"
                  name="Feminino"
                  stackId="a"
                  fill={getColorByIndex(1)}
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </VitecCardDefaultViewContent>
        <VitecCardDefaultViewFooter>
          <VitecCardGeneratedAt />
        </VitecCardDefaultViewFooter>
      </VitecCardDefaultView>
    </VitecCard>
  );
};

const GraphicFiltersPopover = ({
  filters,
  filteredPeriodsList,
  filteredPolygonsList,
  handleFiltersChange,
}) => {
  return (
    <>
      <ReactTooltip
        effect="solid"
        type="info"
        id={`confirmed-ageGroup-tooltip`}
      >
        Filtros
      </ReactTooltip>
      <Button
        data-tip
        data-for={`confirmed-ageGroup-tooltip`}
        style={{
          marginRight: "0px",
          marginLeft: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "58px",
          height: "45px",
        }}
        id={`confirmed-ageGroup-filters-popover`}
        color="primary"
        type="button"
      >
        <i className="fa fa-filter"></i>
      </Button>
      <UncontrolledPopover
        style={{ width: "300px" }}
        id="filtersPopover"
        placement="top"
        target={`confirmed-ageGroup-filters-popover`}
      >
        <PopoverHeader>Filtros</PopoverHeader>
        <PopoverBody style={{ backgroundColor: "#ffffff" }}>
          <div>
            <div className="mb-2">
              <label className="bold">Área:</label>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={filters.polygon}
                options={filteredPolygonsList}
                onChange={(e) => handleFiltersChange("polygon", e)}
              />
            </div>
            <div className="mb-2">
              <label className="bold">Período:</label>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isMulti={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                isClearable={false}
                value={filters.period}
                options={filteredPeriodsList}
                onChange={(e) => handleFiltersChange("period", e)}
              />
            </div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
