import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Select } from "../../../../components/Wrappers/SelectAll";

const ClassificationResult = ({
    sampleClassification,
    identificationsExams,
    data,
    selectedAnimalGenre,
    animalGenres,
    selectedAnimalGroup,
    animalSpecies,
    selectedAnimalSpecie,
    Laboratories,
    techniques,
    handleFowardedSample,
    handleChangeArrayData,
    selectedGenre,
    addClassificationExam,
    removeClassificationExam,
    addLabForward,
    removeLabForward,
    index
}) => {
    return (
        <Card style={{"marginTop": "2%", "marginBottom": "2%"}}>
            <CardHeader>
                <span className="h4"><i className='fa fa-vials'></i> Classificação</span>
            </CardHeader>
            <CardBody>
                <div className="row">
                    <div className={`col-6 responsive-form-field-result-full`}>
                    <span className="h5">Gênero</span>
                    <Select 
                        options={animalGenres} 
                        onChange={(e) => 
                        {
                            if(e !== null)
                            { 
                            selectedGenre(e.value, index)
                            }else{
                            selectedGenre("00000000-0000-0000-0000-000000000000",index)
                            }
                        }
                        }
                        isClearable={true}
                        value={selectedAnimalGenre !== '' ? animalGenres.filter(obj => obj.value === selectedAnimalGenre)[0] : null }
                        isDisabled={selectedAnimalGroup == "00000000-0000-0000-0000-000000000000"}
                    />
                    </div>

                    <div className={`col-6 responsive-form-field-result-full`}>
                    <span className="h5">Espécie</span>
                    <Select 
                        key={selectedAnimalSpecie}
                        options={animalSpecies} 
                        onChange={(e) => {if(e !== null) {handleChangeArrayData("selectedAnimalSpecie",e.value, index)} 
                        else{handleChangeArrayData("selectedAnimalSpecie","00000000-0000-0000-0000-000000000000", index)}}}
                        isClearable={true}
                        value={selectedAnimalSpecie !== '' ? animalSpecies.filter(obj => obj.value === selectedAnimalSpecie)[0] : null }
                        isDisabled={selectedAnimalGenre == '00000000-0000-0000-0000-000000000000'}
                    />
                    </div>                          
                </div>
                {sampleClassification.map((classification,indexClassification)=>{
                    return(
                    <>
                        <div className="row">
                        <div className="col-6 responsive-form-field-result-full">
                            <span className="h5">Nome do laboratório credenciado</span>
                            <Select 
                                key ={classification.LaboratoryId}
                                value={Laboratories.filter(l => l.value == classification.LaboratoryId)}
                                options={Laboratories} 
                                name={"LaboratoryId"}
                                onChange={(e) => handleFowardedSample(e, indexClassification, null, null, index)}
                            />
                        </div>

                        <div className="col-6  responsive-form-field-result-full">
                            <span className="h5">Número da amostra no laboratório externo</span>
                            <input type="text" className="form-control" placeholder="Número da amostra" name="externSampleNumber" value={classification.externSampleNumber} onChange={(e) => {handleFowardedSample(e, indexClassification, null, null,index)}}/>
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-12 responsive-form-field-result-full">
                            <span className="h5">Descrição de encaminhamento</span>
                            <textarea className="form-control" placeholder="Motivo de encaminhamento" name="description" value={classification.description} onChange={(e) => {handleFowardedSample(e, indexClassification, null, null, index)}}/>
                        </div>
                        </div>
                        {classification.exams.map((exam, examIndex) => {
                        return (
                            <>
                            <div className="row">
                            <div className="col-6 responsive-form-field-result-full">
                                <span className="h5">Exame realizado</span>
                                <select
                                className="form-control"
                                name="examId"
                                id=""
                                value={exam.examId}
                                onChange={(e) => handleFowardedSample(e, indexClassification, "exams", examIndex, index)}
                                >
                                <option value="">Selecione</option>
                                {identificationsExams.map((identExam, id) => {
                                    const isSelected = identExam.id == exam.examId;

                                    return (
                                    <option key={id + isSelected} value={identExam.id} selected={isSelected}>
                                        {identExam.name}
                                    </option>
                                    );
                                })}
                                </select>

                            </div>

                            <div className="col-6 responsive-form-field-result-full">
                                <span className="h5">Técnica Utilizada</span>
                                <select className="form-control" name="techniqueId" id="" defaultValue={exam.techniqueId} onChange={(e) =>handleFowardedSample(e, indexClassification, "exams", examIndex, index)}>
                                    <option value="">Selecione</option>
                                    {techniques.map((technique, id) =>{
                                    const isSelected = technique.id == exam.techniqueId;

                                    return (
                                        <option key={id} value={technique.id} selected={isSelected}>{technique.name}</option>
                                    )
                                    })}
                                </select>
                            </div>
                            </div>
                            
                            </>
                        )
                        
                        })}
                        
                        <div className="row mt-4 mb-4">
                        <div className={classification.exams.length > 1 ? "col-6  responsive-form-field-result-full" : "col-12  responsive-form-field-result-full"}>
                            <button className="btn btn-info responsive-button-result" onClick={() => addClassificationExam(indexClassification, index)}>+ Adicionar Exame</button>
                        </div>
                        {classification.exams.length > 1 ?
                            <div className="col-6 responsive-form-field-result-full">
                            <button className="btn btn-warning responsive-button-result" onClick={() => removeClassificationExam(indexClassification, index)}><i className='fa fa-trash'></i> Remover Exame</button>
                            </div>    
                        :null}
                        </div>
                    </>
                    )
                })}
                
                <div className="row">
                    <div className={sampleClassification.length > 1 ? "col-6  responsive-form-field-result-full" : "col-12  responsive-form-field-result-full"}>
                        <button className="btn btn-info responsive-button-result" onClick={() => addLabForward(index)}>
                        + Adicionar Encaminhamento
                        </button>
                    </div>                                          
                    {sampleClassification.length > 1 ?
                    <div className="col-6 responsive-form-field-full">
                        <button className="btn btn-warning responsive-button-result" onClick={() => removeLabForward(index)}><i className='fa fa-trash'></i> Remover Encaminhamento</button>
                    </div>    
                    :null}
                </div>
            </CardBody>  
        </Card>
    )
}

export default ClassificationResult;
