import { useContextSelector } from "use-context-selector";
import { SinanPageContext } from "../Contexts/SinanContext";

export const usePositiveTrapsFromLabList = () => {
  const positiveTrapsFromLabList = useContextSelector(
    SinanPageContext,
    (context) => context.positiveTrapsFromLabList
  );
  const setPositiveTrapsFromLabList = useContextSelector(
    SinanPageContext,
    (context) => context.setPositiveTrapsFromLabList
  );

  return {
    positiveTrapsFromLabList,
    setPositiveTrapsFromLabList,
  };
}