import { useContextSelector } from "use-context-selector";
import { SinanPageContext } from "../Contexts/SinanContext";

export const useAppliedFilters = () => {
  const appliedFilters = useContextSelector(
    SinanPageContext,
    (context) => context.appliedFilters
  );
  const setAppliedFilters = useContextSelector(
    SinanPageContext,
    (context) => context.setAppliedFilters
  );

  return {
    appliedFilters,
    setAppliedFilters,
  };
}