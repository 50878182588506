import { useContextSelector } from "use-context-selector";
import { SinanPageContext } from "../Contexts/SinanContext";

export const useCardNotificationsList = () => {
  const cardsNotificationsList = useContextSelector(
    SinanPageContext,
    (context) => context.cardsNotificationsList
  );
  const setCardsNotificationsList = useContextSelector(
    SinanPageContext,
    (context) => context.setCardsNotificationsList
  );

  return {
    cardsNotificationsList,
    setCardsNotificationsList,
  };
}