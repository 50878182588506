import React from "react";
import useTerritorializationsList from "../../../hooks/useTerritorializationsList";
import {
  Button,
  Col,
  Container,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { MapPin, Settings } from "lucide-react";

import styles from "./TrapsDistributionPage.module.css";
import {
  VitecCard,
  VitecCardDefaultView,
  VitecCardDefaultViewContent,
  VitecCardDefaultViewFooter,
  VitecCardDefaultViewHeader,
  VitecCardGeneratedAt,
} from "../../../components/Cards/VitecCard";
import { Map } from "../../../components/Maps/Map";
import {
  booleanPointInPolygon,
  distance,
  point,
  polygon,
  randomPoint,
  randomPosition,
} from "@turf/turf";
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from "react-leaflet";
import { ToastContainer } from "react-toastify";
import {
  fetchTerritorializationsData,
  selectComponentStyles,
} from "../../../services/utils/globalFunctions";
import MarkerClusterGroup from "react-leaflet-markercluster";
import useTypeLayersList from "../../../hooks/useTypeLayersList";
import { Select } from "../../../components/Wrappers/SelectAll";
import { generateRandomPointsInsidePolygon } from "../../../services/api/territorialization";
import leaflet from "leaflet";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { getUserData } from "../../../services/utils/auth";

const { BaseLayer, Overlay } = LayersControl;

const useTrapsDistributionPage = () => {
  const { setTerritorializationsList, territorializationsList } =
    useTerritorializationsList();
  const { setTypeLayersList, typeLayersList } = useTypeLayersList();

  if (territorializationsList.length === 0) {
    fetchTerritorializationsData(setTerritorializationsList, setTypeLayersList);
  }

  return {
    territorializationsList,
    typeLayersList,
  };
};

export const TrapsDistributionPage = () => {
  const [selectedPolygon, setSelectedPolygon] = React.useState(null);
  const [trapsQuantity, setTrapsQuantity] = React.useState(0);
  const [
    radiusMinimalDistanceBetweenTrapsInMeters,
    setRadiusMinimalDistanceBetweenTrapsInMeters,
  ] = React.useState(0);
  const [polygonToShowOnMap, setPolygonToShowOnMap] = React.useState(null);
  const [pointsToShowOnMap, setPointsToShowOnMap] = React.useState([]);
  const { territorializationsList } = useTrapsDistributionPage();

  React.useEffect(() => {
    if (!selectedPolygon && polygonToShowOnMap) {
      setPolygonToShowOnMap(null);

      return;
    }

    if (!selectedPolygon || territorializationsList.length === 0) return;

    const typeLayerThatIncludesSelectedPolygon = territorializationsList.find(
      ({ id }) => id === selectedPolygon.typeLayerId
    );

    if (!typeLayerThatIncludesSelectedPolygon) return;

    const selectedPolygonInformations =
      typeLayerThatIncludesSelectedPolygon.territorializations.find(
        ({ id }) => id === selectedPolygon.value
      );

    if (!selectedPolygonInformations) return;

    const turfPolygon = polygon([
      selectedPolygonInformations.coordinates.map(({ x, y }) => [x, y]),
    ]);

    const result = {
      id: selectedPolygonInformations.id,
      name: selectedPolygonInformations.name,
      typeLayerId: selectedPolygonInformations.typeLayerId,
      geometryType: selectedPolygonInformations.geometryType,
      polygon: turfPolygon,
      coordinates: turfPolygon.geometry.coordinates[0].map(
        ([latitude, longitude]) => [longitude, latitude]
      ),
    };

    setPolygonToShowOnMap(result);
  }, [selectedPolygon, territorializationsList]);

  const handleCalculatePointsButtonCllick = async () => {
    if (!polygonToShowOnMap) return;

    const points = await generateRandomPointsInsidePolygonData(
      polygonToShowOnMap.id
    );

    const formatedPoints = points.map(({ x, y }) => {
      return {
        latitude: y,
        longitude: x,
        icon: leaflet.icon({
          iconUrl:
            ARMADILHA_INFO["armadilhaDisseminadoraInseticida"].icons["green"],
          iconSize: [16, 16],
        }),
      };
    });

    setPointsToShowOnMap(formatedPoints);
  };

  const generateRandomPointsInsidePolygonData = async (polygonId) => {
    const requestFilters = {
      organizationId: getUserData("organizationId"),
      territorializationId: polygonId,
      numberOfPoints: trapsQuantity,
      radius: radiusMinimalDistanceBetweenTrapsInMeters,
    };

    try {
      const { data } = await generateRandomPointsInsidePolygon(requestFilters);

      return data;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important", color: "#6d6d6d" }}>
          &nbsp;
        </div>
        <section style={{ display: "flex", justifyContent: "end" }}>
          <Button
            id="mainFiltersPopover"
            style={{
              width: "170px",
              display: "flex",
              alignItems: "center",
              gap: ".3rem",
            }}
          >
            <Settings size={17} /> Configurações
          </Button>
          <UncontrolledPopover
            placement="left"
            target="mainFiltersPopover"
            id="filtersPopover"
            style={{ width: "250px" }}
          >
            <PopoverHeader>Configurações</PopoverHeader>
            <PopoverBody style={{ backgroundColor: "#ffffff" }}>
              <Row className="mb-2">
                <Col>
                  <label style={{ fontWeight: 600 }}>Polígono</label>
                  <Select
                    styles={selectComponentStyles}
                    placeholder={"Selecione"}
                    isClearable={true}
                    options={territorializationsList}
                    value={selectedPolygon}
                    isMulti={false}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    onChange={(e) => setSelectedPolygon(e)}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label style={{ fontWeight: 600 }}>
                    Quantidade de armadilhas
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Ex: 50"
                    value={trapsQuantity}
                    onChange={({ target: { value } }) =>
                      setTrapsQuantity(value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label style={{ fontWeight: 600 }}>
                    Distância mínima entre armadilhas (metros)
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Mínimo raio de distância (metros)"
                    value={radiusMinimalDistanceBetweenTrapsInMeters}
                    onChange={({ target: { value } }) =>
                      setRadiusMinimalDistanceBetweenTrapsInMeters(value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    onClick={() => handleCalculatePointsButtonCllick()}
                  >
                    Calcular
                  </Button>
                </Col>
              </Row>
            </PopoverBody>
          </UncontrolledPopover>
        </section>
      </Container>
      <Container fluid className="pb-2 mt-2">
        <VitecCard>
          <VitecCardDefaultView>
            <VitecCardDefaultViewHeader className={styles.cardHeader}>
              <span>Mapa de armadilhas distruibuidas</span>
              <div>
                <VitecCardGeneratedAt />
              </div>
            </VitecCardDefaultViewHeader>
            <VitecCardDefaultViewContent className={styles.cardContent}>
              <TrapsDistributionMap
                pointsToShowOnMap={pointsToShowOnMap}
                polygonToShowOnMap={polygonToShowOnMap}
                territorializationsList={territorializationsList}
              />
            </VitecCardDefaultViewContent>
            <VitecCardDefaultViewFooter />
          </VitecCardDefaultView>
        </VitecCard>
      </Container>
    </main>
  );
};

const TrapsDistributionMap = React.memo(
  ({ pointsToShowOnMap, polygonToShowOnMap, territorializationsList }) => {
    return (
      <Map id="traps-distribution-map" className={styles.mapContainer}>
        <MapPolygonsLayersControl data={territorializationsList} />

        {polygonToShowOnMap && (
          <Polygon
            key={`traps-distribution-polygon-${polygonToShowOnMap.id}`}
            color="#000000"
            fillColor="transparent"
            opacity={1}
            positions={polygonToShowOnMap.coordinates}
          />
        )}

        {pointsToShowOnMap.map(({ latitude, longitude, icon }) => {
          return (
            <DraggableMarker
              key={`${latitude}-${longitude}`}
              initialPosition={[latitude, longitude]}
              icon={icon}
            />
          );
        })}
      </Map>
    );
  }
);

const MapPolygonsLayersControl = React.memo(({ data }) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const typeLayersPolygonsGroup = [];

    data.forEach(({ name, territorializations, id }) => {
      const typeLayerData = {
        id,
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;
        turfPolygon.territorializationId = territorialization.id;
        turfPolygon.typeLayerId = id;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [data]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked name={"Delimitador de áreas"}>
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons, id }) => {
          return (
            <Overlay
              name={name}
              key={`${name}-traps-distribution-overlay-${id}`}
            >
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-traps-distribution-feature-${id}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map((polygon) => {
                    const { geometry, name, territorializationId } = polygon;

                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={`${name}-polygon-traps-distribution-${territorializationId}`}
                        color="#000000"
                        fillColor="transparent"
                        opacity={1}
                        positions={coordinates}
                      ></Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
});

const DraggableMarker = ({ initialPosition, icon }) => {
  const [position, setPosition] = React.useState(initialPosition);
  const markerRef = React.useRef(null);

  const eventHandlers = React.useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );
  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={icon}
    />
  );
};
