import React from "react";

import { createContext } from "use-context-selector";

export const SinanMapContext = createContext(null);

export const SinanMapContextProvider = ({ children }) => {
  const [removePointsCluster, setRemovePointsCluster] = React.useState(false);
  const [selectedPolygon, setSelectedPolygon] = React.useState(null);
  const [calculateCasesCloropletic, setCalculateCasesCloropletic] =
    React.useState(false);
  const [isLoadingCloropletic, setIsLoadingCloropletic] = React.useState(false);
  const [casesCloropleticByAreas, setCasesCloropleticByAreas] =
    React.useState(null);
  const [casesCloropleticLegends, setCasesCloropleticLegends] =
    React.useState(null);

  return (
    <SinanMapContext.Provider
      value={{
        selectedPolygon,
        removePointsCluster,
        calculateCasesCloropletic,
        isLoadingCloropletic,
        casesCloropleticByAreas,
        casesCloropleticLegends,
        setSelectedPolygon,
        setRemovePointsCluster,
        setCalculateCasesCloropletic,
        setIsLoadingCloropletic,
        setCasesCloropleticByAreas,
        setCasesCloropleticLegends,
      }}
    >
      {children}
    </SinanMapContext.Provider>
  );
};
