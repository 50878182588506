import React from "react";

import {
  VitecCard,
  VitecCardDefaultView,
  VitecCardDefaultViewContent,
  VitecCardDefaultViewFooter,
  VitecCardDefaultViewHeader,
  VitecCardDownloadImageButton,
  VitecCardGeneratedAt,
  VitecCardModal,
  VitecCardModalContent,
  VitecCardModalFooter,
  VitecCardModalHeader,
  VitecCardToggleModalButton,
} from "../../../../../components/Cards/VitecCard";
import { Button } from "reactstrap";
import moment from "moment";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Select } from "../../../../../components/Wrappers/SelectAll";
import { selectComponentStyles } from "../../../../../services/utils/globalFunctions";

const useCasesByNotifyingUnitsGraphic = (notificationsList) => {
  const [formatedNotiifyingUnitsData, setFormatedNotifyingUnitsData] =
    React.useState([]);
  const [graphicType, setGraphicType] = React.useState({
    label: "Casos",
    value: "cases",
  });

  React.useEffect(() => {
    if (notificationsList.length === 0) return;

    const notifyingUnitsMap = {};

    notificationsList.forEach(({ resultsByPeriods }) => {
      resultsByPeriods.forEach(({ result }) => {
        result.byNotifyingUnits.forEach(
          ({
            confirmed,
            notifications,
            id,
            name,
            georreferencedCases,
            notGeorreferencedCases,
          }) => {
            if (!notifyingUnitsMap[id])
              notifyingUnitsMap[id] = {
                id,
                name: !name || name === "" ? id : name,
                notifications: 0,
                confirmed: 0,
                georreferencedCases: 0,
                notGeorreferencedCases: 0,
              };

            notifyingUnitsMap[id].notifications += notifications;
            notifyingUnitsMap[id].confirmed += confirmed;
            notifyingUnitsMap[id].georreferencedCases += georreferencedCases;
            notifyingUnitsMap[id].notGeorreferencedCases +=
              notGeorreferencedCases;
          }
        );
      });
    });

    const result = Array.from(Object.values(notifyingUnitsMap)).sort(
      (a, b) => b.notifications + b.confirmed - (a.notifications + a.confirmed)
    );

    setFormatedNotifyingUnitsData(result);
  }, [notificationsList]);

  const handleGraphicTypeChange = (graphicType) => {
    setGraphicType(graphicType);
  };

  const graphicTypeOptions = [
    {
      label: "Casos",
      value: "cases",
    },
    {
      label: "Casos Geo/Não Georreferenciados",
      value: "georreferenced",
    },
  ];

  return {
    formatedNotiifyingUnitsData,
    graphicType,
    graphicTypeOptions,
    handleGraphicTypeChange,
  };
};

export const CasesByNotifyingUnitsGraphic = ({
  notificationsList,
  periodLegend,
}) => {
  const {
    formatedNotiifyingUnitsData,
    graphicType,
    graphicTypeOptions,
    handleGraphicTypeChange,
  } = useCasesByNotifyingUnitsGraphic(notificationsList);

  if (graphicType.value === "cases")
    return (
      <VitecCard>
        <VitecCardModal>
          <VitecCardModalHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Visualização em tela cheia
          </VitecCardModalHeader>
          <VitecCardModalContent>
            <VitecCard>
              <VitecCardDefaultView>
                <VitecCardDefaultViewHeader
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="bold">
                    Casos por Unidade Notificadora <br />
                    {periodLegend}
                  </span>
                  <div style={{ display: "flex", gap: ".3rem" }}>
                    <Button
                      style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "58px",
                        height: "45px",
                      }}
                      color="primary"
                      id={`evolution-filters-popover-modal`}
                    >
                      <i className="fa fa-filter"></i>
                    </Button>
                    <VitecCardDownloadImageButton
                      imageFileName={`CASOS_POR_UNIDADE_NOTIFICADORA_SINAN_VITEC_${moment().format(
                        "DD/MM/YYYY HH:mm"
                      )}`}
                      color="primary"
                      style={{ width: "58px", height: "45px" }}
                      showTooltip={false}
                    />
                  </div>
                </VitecCardDefaultViewHeader>
                <VitecCardDefaultViewContent style={{ minHeight: "60vh" }}>
                  <ResponsiveContainer width="100%" minHeight={400}>
                    <BarChart
                      data={formatedNotiifyingUnitsData}
                      layout="vertical"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      width={500}
                      minHeight={400}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis
                        type="category"
                        dataKey="name"
                        fontSize={10}
                        width={400}
                        height={200}
                        interval={0}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="notifications"
                        name="Notificações"
                        stackId="a"
                        fill="#57afe0"
                      />
                      <Bar
                        dataKey="confirmed"
                        name="Confirmado"
                        stackId="a"
                        fill="#000000"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </VitecCardDefaultViewContent>
                <VitecCardDefaultViewFooter>
                  <VitecCardGeneratedAt />
                </VitecCardDefaultViewFooter>
              </VitecCardDefaultView>
            </VitecCard>
          </VitecCardModalContent>
          <VitecCardModalFooter />
        </VitecCardModal>
        <VitecCardDefaultView>
          <VitecCardDefaultViewHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bold", color: "black" }}>
              Casos por Unidade Notificadora <br />
              {periodLegend}
            </span>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".3rem" }}
            >
              <VitecCardToggleModalButton
                color="primary"
                style={{ width: "58px", height: "45px" }}
              />
              <VitecCardDownloadImageButton
                imageFileName={`CASOS_POR_UNIDADE_NOTIFICADORA_SINAN_VITEC_${moment().format(
                  "DD/MM/YYYY HH:mm"
                )}`}
                color="primary"
                style={{ width: "58px", height: "45px" }}
              />
              <div style={{ width: "170px" }}>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isMulti={false}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  isClearable={false}
                  value={graphicType}
                  options={graphicTypeOptions}
                  onChange={(e) => handleGraphicTypeChange(e)}
                />
              </div>
            </div>
          </VitecCardDefaultViewHeader>
          <VitecCardDefaultViewContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25rem",
            }}
          >
            <ResponsiveContainer width="100%" minHeight={250}>
              <BarChart
                data={formatedNotiifyingUnitsData.slice(0, 20)}
                layout="vertical"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                width={500}
                height={250}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="name"
                  fontSize={10}
                  width={400}
                  height={200}
                  interval={0}
                />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="notifications"
                  name="Notificações"
                  stackId="a"
                  fill="#57afe0"
                />
                <Bar
                  dataKey="confirmed"
                  name="Confirmado"
                  stackId="a"
                  fill="#000000"
                />
              </BarChart>
            </ResponsiveContainer>
          </VitecCardDefaultViewContent>
          <VitecCardDefaultViewFooter>
            <VitecCardGeneratedAt />
          </VitecCardDefaultViewFooter>
        </VitecCardDefaultView>
      </VitecCard>
    );

  if (graphicType.value === "georreferenced")
    return (
      <VitecCard>
        <VitecCardModal>
          <VitecCardModalHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Visualização em tela cheia
          </VitecCardModalHeader>
          <VitecCardModalContent>
            <VitecCard>
              <VitecCardDefaultView>
                <VitecCardDefaultViewHeader
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="bold">
                    Casos Geo/Não Georreferrenciados por Unidade Notificadora{" "}
                    <br />
                    {periodLegend}
                  </span>
                  <div style={{ display: "flex", gap: ".3rem" }}>
                    <Button
                      style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "58px",
                        height: "45px",
                      }}
                      color="primary"
                      id={`evolution-filters-popover-modal`}
                    >
                      <i className="fa fa-filter"></i>
                    </Button>
                    <VitecCardDownloadImageButton
                      imageFileName={`CASOS_POR_UNIDADE_NOTIFICADORA_SINAN_VITEC_${moment().format(
                        "DD/MM/YYYY HH:mm"
                      )}`}
                      color="primary"
                      style={{ width: "58px", height: "45px" }}
                      showTooltip={false}
                    />
                  </div>
                </VitecCardDefaultViewHeader>
                <VitecCardDefaultViewContent style={{ minHeight: "60vh" }}>
                  <ResponsiveContainer width="100%" minHeight={400}>
                    <BarChart
                      data={formatedNotiifyingUnitsData}
                      layout="vertical"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      width={500}
                      minHeight={400}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis
                        type="category"
                        dataKey="name"
                        fontSize={10}
                        width={400}
                        height={200}
                        interval={0}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="notifications"
                        name="Notificações"
                        stackId="a"
                        fill="#57afe0"
                      />

                      <Bar
                        dataKey="confirmed"
                        name="Confirmado"
                        stackId="a"
                        fill="#000000"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </VitecCardDefaultViewContent>
                <VitecCardDefaultViewFooter>
                  <VitecCardGeneratedAt />
                </VitecCardDefaultViewFooter>
              </VitecCardDefaultView>
            </VitecCard>
          </VitecCardModalContent>
          <VitecCardModalFooter />
        </VitecCardModal>
        <VitecCardDefaultView>
          <VitecCardDefaultViewHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bold", color: "black" }}>
              Casos Geo/Não Georreferrenciados por Unidade Notificadora <br />
              {periodLegend}
            </span>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".3rem" }}
            >
              <VitecCardToggleModalButton
                color="primary"
                style={{ width: "58px", height: "45px" }}
              />
              <VitecCardDownloadImageButton
                imageFileName={`CASOS_POR_UNIDADE_NOTIFICADORA_SINAN_VITEC_${moment().format(
                  "DD/MM/YYYY HH:mm"
                )}`}
                color="primary"
                style={{ width: "58px", height: "45px" }}
              />
              <div style={{ width: "170px" }}>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isMulti={false}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  isClearable={false}
                  value={graphicType}
                  options={graphicTypeOptions}
                  onChange={(e) => handleGraphicTypeChange(e)}
                />
              </div>
            </div>
          </VitecCardDefaultViewHeader>
          <VitecCardDefaultViewContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25rem",
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={formatedNotiifyingUnitsData.slice(0, 20)}
                layout="vertical"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                width={500}
                height={300}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="name"
                  fontSize={10}
                  width={400}
                  height={200}
                  interval={0}
                />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="georreferencedCases"
                  name="Georreferenciado"
                  stackId="a"
                  fill="#57afe0"
                />
                <Bar
                  dataKey="notGeorreferencedCases"
                  name="Não Georreferenciado"
                  stackId="a"
                  fill="#000000"
                />
              </BarChart>
            </ResponsiveContainer>
          </VitecCardDefaultViewContent>
          <VitecCardDefaultViewFooter>
            <VitecCardGeneratedAt />
          </VitecCardDefaultViewFooter>
        </VitecCardDefaultView>
      </VitecCard>
    );

  return null;
};

const PositivityGraphicTooltip = ({
  active,
  payload,
  positivityIndexName,
  tooltipPositivityPropertyName,
  territorializationsOptions,
}) => {
  if (active && payload && payload.length) {
    const payloadData = payload[0].payload;

    console.log(payloadData);

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        {/* <p className="label">{payloadData["periodLabel"]}</p>
        {territorializationsOptions.map(({ label }) => (
          <p className="label" style={{ fontWeight: "bold" }}>
            {positivityIndexName} - {label}: {payloadData[`${label}${positivityIndexName}`]}
          </p>
        ))} */}
      </div>
    );
  }

  return null;
};
