import React from "react";

import {
  Button,
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { DateFilter } from "../../../../../components/Filters/DateFilter";
import YearsFilter from "../../../../../components/Filters/YearsFilter";
import { EpidemiologicWeekFilter } from "../../../../../components/Filters/EpidemiologicWeekFilter";
import ManualEpidemiologicWeeksFilter from "../../../../../components/Filters/ManualEpidemiologicWeeksFilter";
import TypeLayersFilter from "../../../../../components/Filters/TypeLayersFilter";
import TerritorializationsFilter from "../../../../../components/Filters/TerritorializationsFilter";
import { getAllWeeksFromYear } from "../../../../../services/utils/todayEpidemiologicalWeek";
import moment from "moment";
import { toast } from "react-toastify";
import useTerritorializationsList from "../../../../../hooks/useTerritorializationsList";
import useTypeLayersList from "../../../../../hooks/useTypeLayersList";
import useTrapTypesList from "../../../../../hooks/useTrapTypesList";
import { fetchFilteredTrapsByPeriods } from "../../../../../services/api/Trap";
import { useAppliedFilters } from "../../Hooks/useAppliedFilters";
import getWeeks from "../../../../../services/utils/epidemiologicalWeek";
import {
  fetchDemandsData,
  fetchEmployeesAndTeamsData,
  fetchTerritorializationsData,
  fetchTrapTypesData,
  monthsOptionsList,
} from "../../../../../services/utils/globalFunctions";
import { getUserData } from "../../../../../services/utils/auth";
import TrapTypesFilter from "../../../../../components/Filters/TrapTypesFilter";
import useDemandsList from "../../../../../hooks/useDemandsList";
import useEmployeesList from "../../../../../hooks/useEmployeesList";
import useTeamsList from "../../../../../hooks/useTeamsList";
import DemandsFilter from "../../../../../components/Filters/DemandsFilter";
import UsersFilter from "../../../../../components/Filters/UsersFilter";
import TeamsFilter from "../../../../../components/Filters/TeamsFilter";
import LoadingSpin from "react-loading-spin";
import { useTrapsByPeriodsList } from "../../Hooks/useTrapsByPeriodsList";
import jsonToCsvExport from "json-to-csv-export";
import { useDownloadableData } from "../../Hooks/useDownloadableData";
import styles from "./TerritorializationsReportFilters.module.css";
import ReactTooltip from "react-tooltip";
import "./styles.css";
import { MonthWeekFilter } from "../../../../../components/Filters/MonthWeekFilter";
import { ManualMonthsFilter } from "../../../../../components/Filters/ManualMonthsFilter";

export const DEFAULT_TERRITORIALIZATIONS_REPORT_FILTERS = {
  beginDate: "",
  demand: {
    label: "Selecione",
    value: "",
  },
  casesReferenceDate: {
    label: "1º Sintoma",
    value: 1,
  },
  datePeriodType: "week",
  disease: {
    label: "Dengue",
    value: 1,
  },
  epidemiologicalWeeks: [],
  epidemiologicalWeekBegin: {
    label: "Selecione",
    value: "",
  },
  epidemiologicalWeekEnd: {
    label: "Selecione",
    value: "",
  },
  months: [],
  monthBegin: {
    label: "Selecione",
    value: "",
  },
  monthEnd: {
    label: "Selecione",
    value: "",
  },
  endDate: "",
  filtersTypes: {
    usersIds: 1,
  },
  notifyingUnit: {
    label: "Selecione",
    value: "",
  },
  team: {
    label: "Selecione",
    value: "",
  },
  territorializations: [],
  trapType: {
    label: "Selecione",
    value: "",
  },
  typeLayers: [],
  users: [],
  year: {
    label: "Selecione",
    value: "",
  },
};

const useTerritorializationsReportFilters = () => {
  const [filters, setFilters] = React.useState(
    DEFAULT_TERRITORIALIZATIONS_REPORT_FILTERS
  );
  const { setAppliedFilters } = useAppliedFilters();
  const { territorializationsList, setTerritorializationsList } =
    useTerritorializationsList();
  const { typeLayersList, setTypeLayersList } = useTypeLayersList();
  const { trapTypesList, setTrapTypesList } = useTrapTypesList();
  const { demandsList, setDemandsList } = useDemandsList();
  const { employeesList, setEmployeesList } = useEmployeesList();
  const { teamsList, setTeamsList } = useTeamsList();
  const {
    setTrapsByPeriodsList,
    isTrapsByPeriodsListLoading,
    setIsTrapsByPeriodsListLoading,
  } = useTrapsByPeriodsList();

  const organizationId = getUserData("organizationId");

  React.useEffect(() => {
    if (demandsList.length === 0) fetchDemandsData(setDemandsList);

    if (territorializationsList.length === 0 || typeLayersList.length === 0)
      fetchTerritorializationsData(
        setTerritorializationsList,
        setTypeLayersList
      );

    if (trapTypesList.length === 0) fetchTrapTypesData(setTrapTypesList);

    if (teamsList.length === 0 || employeesList.length === 0)
      fetchEmployeesAndTeamsData(setEmployeesList, setTeamsList);
  }, []);

  const fetchAllComponentsData = async () => {
    setAppliedFilters(filters);
    setIsTrapsByPeriodsListLoading(true);

    try {
      const allPromises = await Promise.all([fetchTrapsListByPeriodsData()]);

      return allPromises;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error);
      }
    } finally {
      setIsTrapsByPeriodsListLoading(false);
    }
  };

  const fetchTrapsListByPeriodsData = async () => {
    if (filters.trapType.value === "") {
      toast.error("Selecione um tipo de armadilha");

      return;
    }

    if (filters.territorializations.length === 0) {
      toast.error("Selecione pelo menos uma territorialização");

      return;
    }

    const usersIdsToSend = [];

    if (filters.team.value !== "" && filters.team.members.length !== 0) {
      filters.team.members.forEach(({ userId }) => {
        if (
          userId &&
          userId !== "" &&
          userId !== "00000000-0000-0000-0000-000000000000"
        )
          usersIdsToSend.push(userId);
      });
    }

    filters.users.forEach(({ userId }) => usersIdsToSend.push(userId));

    const filtersToSend = {
      demandsIds: filters.demand.value !== "" ? [filters.demand.value] : [],
      organizationId,
      territorializationsIds: filters.territorializations.map(
        ({ value }) => value
      ),
      trapsNumbers: [],
      trapTypesIds: [filters.trapType.value],
      usersIds: usersIdsToSend,
      filtersTypes: {
        usersIds: filters.filtersTypes.usersIds,
      },
      periods: getPeriodsToSendOnRequest(filters),
    };

    try {
      const { data, status } = await fetchFilteredTrapsByPeriods(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelas armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      setTrapsByPeriodsList(data);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error);
      }
    }
  };

  const clearFilters = () => {
    setFilters(DEFAULT_TERRITORIALIZATIONS_REPORT_FILTERS);
  };

  const getPeriodsToSendOnRequest = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") {
      const filtersToSend = [
        {
          beginDate: moment
            .utc(filters.beginDate)
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          endDate: moment
            .utc(filters.endDate)
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          name: `${moment(filters.beginDate).format("DD/MM/YYYY")} a ${moment(
            filters.endDate
          ).format("DD/MM/YYYY")}`,
        },
      ];

      return filtersToSend;
    }

    if (filters.datePeriodType === "week") {
      if (
        filters.year.value !== "" &&
        filters.epidemiologicalWeeks.length === 0
      ) {
        const filtersToSend = getAllWeeksFromYear(
          String(filters.year.value)
        ).map(({ beginDate, endDate, label }) => {
          const formatedBeginDate = moment(beginDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString();

          const formatedEndDate = moment(endDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString();

          return {
            beginDate: formatedBeginDate,
            endDate: formatedEndDate,
            name: `${label}`,
          };
        });

        return filtersToSend;
      }

      if (filters.epidemiologicalWeeks.length > 0) {
        const filtersToSend = filters.epidemiologicalWeeks.map(
          ({ beginDate, endDate, label }) => ({
            beginDate: moment(beginDate, "DD/MM/YYYY")
              .utc()
              .set({ hour: 0, minute: 0, second: 0 })
              .toISOString(),
            endDate: moment(endDate, "DD/MM/YYYY")
              .utc()
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString(),
            name: `${label}`,
          })
        );

        return filtersToSend;
      }
    }

    if (filters.datePeriodType === "month") {
      if (filters.year.value !== "" && filters.months.length === 0) {
        const months = [];

        for (let month = 0; month < 12; month++) {
          const beginDate = moment([+filters.year.value, month])
            .startOf("month")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString();
          const endDate = moment([+filters.year.value, month])
            .endOf("month")
            .subtract(1, "day")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString();
          const name = monthsOptionsList.find(
            ({ value }) => value === month + 1
          ).label;

          const result = {
            beginDate,
            endDate,
            name,
          };

          months.push(result);
        }

        return months;
      }

      if (filters.months.length > 0) {
        const filtersToSend = filters.months.map(({ label, value }) => ({
          beginDate: moment([+filters.year.value, value - 1])
            .startOf("month")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 }),
          endDate: moment([+filters.year.value, value - 1])
            .endOf("month")
            .subtract(1, "day")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 }),
          name: `${label}`,
        }));

        return filtersToSend;
      }
    }

    return [];
  };

  const handleDatePeriodChange = () => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      const datePeriodTypeValue =
        newValues.datePeriodType === "week" ? "month" : "week";

      newValues.datePeriodType = datePeriodTypeValue;

      return newValues;
    });
  };

  const handleFiltersChange = React.useCallback((filterName, filterValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };
      newValues[filterName] = filterValue;

      return newValues;
    });
  }, []);

  const handleFiltersTypesChange = (filterTypeName) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      newValues.filtersTypes[filterTypeName] =
        newValues.filtersTypes[filterTypeName] === 1 ? 2 : 1;

      return newValues;
    });
  };

  const handleEpidemiologicWeeksFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if (
          filterName === "epidemiologicalWeekBegin" &&
          newValue.value === ""
        ) {
          newValues.epidemiologicalWeekBegin = newValue;
          newValues.epidemiologicalWeekEnd = newValue;

          return newValues;
        }

        if (
          filterName === "epidemiologicalWeekEnd" &&
          newValue.value !== "" &&
          newValues.epidemiologicalWeekBegin.value !== ""
        ) {
          const beginWeekNumber = +newValues.epidemiologicalWeekBegin.label;
          const endWeekNumber = +newValue.label;

          if (endWeekNumber < beginWeekNumber) {
            toast.error(
              "A semana epidemiológica final deve ser igual ou maior que a inicial."
            );

            return newValues;
          }

          const epidemiologicalWeeksFromYear = getWeeks().find(
            ({ year }) => year === filters.year.label
          ).weeks;

          const weeksInBetween = epidemiologicalWeeksFromYear
            .slice(beginWeekNumber - 1, endWeekNumber)
            .map(({ endDate, ...rest }) => ({
              ...rest,
              endDate,
              value: endDate,
            }));

          newValues.epidemiologicalWeeks = weeksInBetween;
          newValues[filterName] = newValue;

          return newValues;
        }

        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleMonthFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if (filterName === "monthBegin" && newValue.value === "") {
          newValues.monthBegin = newValue;
          newValues.monthEnd = newValue;

          return newValues;
        }

        if (
          filterName === "monthEnd" &&
          newValue.value !== "" &&
          newValues.monthBegin.value !== ""
        ) {
          const beginMonthNumber = newValues.monthBegin.value;
          const endMonthNumber = newValue.value;

          if (endMonthNumber < beginMonthNumber) {
            toast.error("o mês final deve ser igual ou maior que o inicial.");

            return newValues;
          }

          const monthsInBetween = monthsOptionsList.filter(
            ({ value }) => value >= beginMonthNumber && value <= endMonthNumber
          );

          newValues.months = monthsInBetween;
          newValues[filterName] = newValue;

          return newValues;
        }

        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleYearFilterChange = React.useCallback(
    (filterName, filterValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };
        newValues[filterName] = filterValue;

        newValues.epidemiologicalWeeks = [];
        newValues.epidemiologicalWeekBegin = {
          label: "Selecione",
          value: "",
        };
        newValues.epidemiologicalWeekEnd = {
          label: "Selecione",
          value: "",
        };
        newValues.beginDate = "";
        newValues.endDate = "";

        return newValues;
      });
    },
    []
  );

  const handleTypeLayerChange = (value) => {
    handleFiltersChange(
      "typeLayers",
      !value || value.length === 0 ? [] : value
    );

    if (!value || value.length === 0)
      handleFiltersChange("territorializations", []);
    else
      handleFiltersChange("territorializations", value[0].territorializations);
  };

  return {
    clearFilters,
    employeesList,
    demandsList,
    fetchAllComponentsData,
    filters,
    handleDatePeriodChange,
    handleEpidemiologicWeeksFilterChange,
    handleFiltersChange,
    handleFiltersTypesChange,
    handleMonthFilterChange,
    handleTypeLayerChange,
    handleYearFilterChange,
    isTrapsByPeriodsListLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  };
};

export const TerritorializationsReportFilters = () => {
  const { downloadableData } = useDownloadableData();

  return (
    <div className={styles.container}>
      <Button
        className={styles.actionsButton}
        id="mainFiltersPopover"
        type="button"
      >
        <i className="fa fa-filter"></i> Filtros
      </Button>
      <TerritorializationsReportFiltersPopover
        offset="-120, 8"
        placement="bottom"
        target="mainFiltersPopover"
      />
      <Button
        className={styles.downloadButton}
        onClick={() => {
          jsonToCsvExport({
            data: downloadableData.rawActivitiesByPeriodsFromPolygons,
            filename: "atividades_por_periodo_das_areas_vitec",
          });
          jsonToCsvExport({
            data: downloadableData.rawActivitiesByUsers,
            filename: "atividades_por_usuario_vitec",
          });
        }}
      >
        <i>
          <img
            src={require("../../../../../assets/img/icons/interface/download.png")}
            className={styles.downloadButtonIcon}
            alt="icone"
          />
        </i>{" "}
        Baixar dados brutos
      </Button>
    </div>
  );
};

const TerritorializationsReportFiltersPopover = ({
  target,
  offset,
  placement,
  ...props
}) => {
  const {
    clearFilters,
    employeesList,
    demandsList,
    fetchAllComponentsData,
    filters,
    handleDatePeriodChange,
    handleEpidemiologicWeeksFilterChange,
    handleFiltersChange,
    handleFiltersTypesChange,
    handleMonthFilterChange,
    handleTypeLayerChange,
    handleYearFilterChange,
    isTrapsByPeriodsListLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  } = useTerritorializationsReportFilters();

  return (
    <UncontrolledPopover
      style={{ width: "500px" }}
      placement={placement}
      target={target}
      offset={offset}
      id="filtersPopover"
      {...props}
    >
      <PopoverHeader>Filtros</PopoverHeader>
      <PopoverBody style={{ backgroundColor: "#ffffff" }}>
        <Row className="mb-2">
          <Col>
            <label htmlFor="beginDate" className={styles.label}>
              De (<span style={{ color: "red" }}>*</span>) :
            </label>
            <DateFilter
              filterName="beginDate"
              handleFiltersChange={handleFiltersChange}
              id="beginDate"
              value={filters.beginDate}
              disabled={filters.year.value !== ""}
            />
          </Col>
          <Col>
            <label htmlFor="endDate" className={styles.label}>
              Até (<span style={{ color: "red" }}>*</span>) :
            </label>
            <DateFilter
              filterName="endDate"
              handleFiltersChange={handleFiltersChange}
              id="endDate"
              value={filters.endDate}
              disabled={filters.year.value !== ""}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Ano (<span style={{ color: "red" }}>*</span>) :
            </label>
            <YearsFilter
              filterName="year"
              handleFiltersChange={handleYearFilterChange}
              value={filters.year}
              isDisabled={filters.beginDate !== "" || filters.endDate !== ""}
            />
          </Col>
          <Col>
            {filters.datePeriodType === "week" ? (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Semana</span>
                  inicio:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <EpidemiologicWeekFilter
                  filterName="epidemiologicalWeekBegin"
                  handleFiltersChange={handleEpidemiologicWeeksFilterChange}
                  value={filters.epidemiologicalWeekBegin}
                  year={String(filters.year.value)}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            ) : (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Mês</span>
                  inicio:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <MonthWeekFilter
                  filterName="monthBegin"
                  handleFiltersChange={handleMonthFilterChange}
                  value={filters.monthBegin}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            )}
          </Col>
          <Col>
            {filters.datePeriodType === "week" ? (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Semana</span>
                  fim:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <EpidemiologicWeekFilter
                  filterName="epidemiologicalWeekEnd"
                  handleFiltersChange={handleEpidemiologicWeeksFilterChange}
                  value={filters.epidemiologicalWeekEnd}
                  year={String(filters.year.value)}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            ) : (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Mês</span>
                  fim:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <MonthWeekFilter
                  filterName="monthEnd"
                  handleFiltersChange={handleMonthFilterChange}
                  value={filters.monthEnd}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {filters.datePeriodType === "week" ? (
              <div>
                <label htmlFor="" className={styles.label}>
                  Semanas (manual):
                </label>
                <ManualEpidemiologicWeeksFilter
                  filterName="epidemiologicalWeeks"
                  handleFiltersChange={handleFiltersChange}
                  value={filters.epidemiologicalWeeks}
                  year={filters.year.label}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            ) : (
              <div>
                <label htmlFor="" className={styles.label}>
                  Meses (manual):
                </label>
                <ManualMonthsFilter
                  filterName="months"
                  handleFiltersChange={handleFiltersChange}
                  value={filters.months}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Demanda:
            </label>
            <DemandsFilter
              filterName="demand"
              handleFiltersChange={handleFiltersChange}
              options={demandsList}
              value={filters.demand}
              isDisabled={demandsList.length === 0}
            />
          </Col>
          <Col>
            <label htmlFor="" className={styles.label}>
              Tipo de armadilha (<span style={{ color: "red" }}>*</span>) :
            </label>
            <TrapTypesFilter
              filterName="trapType"
              handleFiltersChange={handleFiltersChange}
              options={trapTypesList}
              value={filters.trapType}
              isDisabled={trapTypesList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Usuários: (
              {filters.filtersTypes.usersIds === 1 ? (
                <div>
                  <span
                    data-tip
                    data-for="inclusive1"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Inclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="inclusive1"
                  >
                    Filtra os resultados pelos usuários selecionados.
                  </ReactTooltip>
                </div>
              ) : (
                <div>
                  <span
                    data-tip
                    data-for="exclusive1"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Exclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="exclusive1"
                  >
                    Filtra os resultados exceto dos usuários selecionados.
                  </ReactTooltip>
                </div>
              )}
              ){" "}
              <i onClick={() => handleFiltersTypesChange("usersIds")}>
                <ExchangeIcon />
              </i>
            </label>
            <UsersFilter
              filterName="users"
              handleFiltersChange={handleFiltersChange}
              options={employeesList}
              value={filters.users}
              isDisabled={employeesList.length === 0}
            />
          </Col>
          <Col>
            <label htmlFor="" className={styles.label}>
              Equipe: (
              {filters.filtersTypes.usersIds === 1 ? (
                <div>
                  <span
                    data-tip
                    data-for="inclusive2"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Inclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="inclusive2"
                  >
                    Filtra os resultados pela equipe selecionada.
                  </ReactTooltip>
                </div>
              ) : (
                <div>
                  <span
                    data-tip
                    data-for="exclusive2"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Exclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="exclusive2"
                  >
                    Filtra os resultados exceto da equipe selecionada.
                  </ReactTooltip>
                </div>
              )}
              ){" "}
              <i onClick={() => handleFiltersTypesChange("usersIds")}>
                <ExchangeIcon />
              </i>
            </label>
            <TeamsFilter
              filterName="team"
              handleFiltersChange={handleFiltersChange}
              options={teamsList}
              value={filters.team}
              isDisabled={teamsList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Delimitador de área:
            </label>
            <TypeLayersFilter
              handleTypeLayerChange={handleTypeLayerChange}
              typeLayersList={typeLayersList}
              value={filters.typeLayers}
              isDisabled={typeLayersList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <label htmlFor="" className={styles.label}>
              Territorializações (<span style={{ color: "red" }}>*</span>) :
            </label>
            <TerritorializationsFilter
              handleFiltersChange={handleFiltersChange}
              territorializationsList={territorializationsList}
              value={filters.territorializations}
              isDisabled={territorializationsList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {isTrapsByPeriodsListLoading ? (
              <Button disabled={true} color="primary">
                <LoadingSpin color="#fff" size={17} />
              </Button>
            ) : (
              <Button onClick={fetchAllComponentsData} color="primary">
                Aplicar filtros
              </Button>
            )}
          </Col>
          <Col>
            <Button onClick={clearFilters}>Remover filtros</Button>
          </Col>
        </Row>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

const ExchangeIcon = () => {
  return (
    <img
      src={require("../../../../../assets/img/icons/interface/exchange-black.png")}
      style={{
        width: "12px",
        height: "12px",
        cursor: "pointer",
        marginLeft: "5px",
      }}
      alt="icone"
    />
  );
};
