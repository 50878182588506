import React, { useEffect, useState } from "react";

import { UserRound } from "lucide-react";
import { ToastContainer } from "react-toastify";
import { Col, Container, Row } from "reactstrap";

import styles from "./ConfigurationsPage.module.css";
import { useTranslation } from "react-i18next";
import { MyAccount } from "./Pages/MyAccount";

const useConfigurationsPage = () => {
  const [selectedModule, setSelectedModule] = useState(null);

  const { t } = useTranslation("configurationsPage");

  const myAccountModule = {
    name: "MyAccount",
    label: t("modules.account.pages.my_account.aside_title"),
    component: <MyAccount />,
    moduleIcon: <UserRound size={25} />,
  };

  useEffect(() => {
    setSelectedModule(myAccountModule);
  }, []);

  const handleModuleChange = (module) => {
    setSelectedModule(module);
  };

  const modules = {
    account: {
      sectionTitle: t("modules.account.section_title"),
      pages: [myAccountModule],
    },
  };

  return {
    modules,
    selectedModule,
    handleModuleChange,
    t,
  };
};

export const ConfigurationsPage = () => {
  const { handleModuleChange, modules, selectedModule, t } =
    useConfigurationsPage();

  if (!t) return null;

  const selectedModuleComponent = selectedModule && selectedModule.component;

  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important", color: "#6d6d6d" }}>
          &nbsp;
        </div>
      </Container>
      <Container fluid className="pb-2 mt-2">
        <section style={{ display: "flex", alignItems: "center" }}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <span className="h2 mb-0" style={{ color: "black" }}>
              {t("title")}
            </span>
          </span>
        </section>
        <hr style={{ margin: "1.5rem 0px" }} />
        <section>
          <Row>
            <Col xl="2">
              <aside>
                <span className={styles.asideSectionTitle}>
                  {modules.account.sectionTitle}
                </span>
                <ul className={styles.list}>
                  {modules.account.pages.map(
                    ({ component, moduleIcon, label, name }) => {
                      return (
                        <li
                          key={name}
                          className={
                            selectedModule && selectedModule.name === name
                              ? `${styles.asideListItem} ${styles.asideListItemActive}`
                              : styles.asideListItem
                          }
                          onClick={() =>
                            handleModuleChange({
                              component,
                              moduleIcon,
                              label,
                              name,
                            })
                          }
                        >
                          {moduleIcon}
                          <span>{label}</span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </aside>
            </Col>
            <Col>{selectedModuleComponent}</Col>
          </Row>
        </section>
        <footer style={{ marginTop: "5rem" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <img
              style={{ maxWidth: "100px", opacity: 0.5 }}
              src="/static/media/vitec.9bd71d52.png"
              alt="Logo do Vitec"
            />
          </div>
        </footer>
      </Container>
    </main>
  );
};
