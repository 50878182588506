import { useContextSelector } from "use-context-selector";
import { SinanPageContext } from "../Contexts/SinanContext";

export const useCasesByAreas = () => {
  const casesByAreas = useContextSelector(
    SinanPageContext,
    (context) => context.casesByAreas
  );
  const setCasesByAreas = useContextSelector(
    SinanPageContext,
    (context) => context.setCasesByAreas
  );

  return {
    casesByAreas,
    setCasesByAreas,
  };
};
