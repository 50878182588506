import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  CardText,
} from "reactstrap";
// core components
import "../../../assets/css/style.css";
import { fetchSamplesByNumber } from "../../../services/api/Sample";
import {getConservationTypes} from "../../../services/api/ConservationType";
import {getJustificationDisposalTypes} from "../../../services/api/JustificationDispositionType";
import { fetchPrograms } from "../../../services/api/Program";
import {getDiagnosticsExam,getIndentificationsExam } from "../../../services/api/Exam";
import {getTechniques} from "../../../services/api/Technique";
import { fetchIdentifications } from "../../../services/api/Identification";
import { getTrapById,getTrapsTypes } from "../../../services/api/Trap";
import { fetchDemand } from "../../../services/api/Demand";
import { toast, ToastContainer } from "react-toastify";
import { fetchAnimalTypeGroups, fetchAnimalGenres, fetchAnimalGroups, fetchAnimalSpecies, fetchGenreVirusType } from "../../../services/api/AnimalGroups";
import Select from "react-select";
import {getAllLaboratories} from "../../../services/api/Laboratory";
import { getUser } from "../../../services/api/Users";
import { getPerson } from "../../../services/api/Person";
import { checkPermissionComponent } from "../../../services/utils/permission";
import { getUserData } from '../../../services/utils/auth';
import {ARMADILHA_INFO} from '../../../constants/ArmadilhaConstant';
import "./Sample.css";
import moment from "moment";
import LoadingSpin from "react-loading-spin";
import { error } from "jquery";
import IdentificationResult from "./CardsResult/IdentificationResult";
import ClassificationResult from "./CardsResult/ClassificationResult";
import DiagnosisResult from "./CardsResult/DiagnosisResult";
import formatSampleData from "../../../services/utils/Sample/ShowSampleNumber";
import { SendSampleResult } from "../../../services/utils/Sample/SendSampleResult";
import { timeStampFormated } from "../../../services/utils/format";
const groupedOptions = [
  {
    label: "Invertebrados",
    options: [
      { name: "Stage", label: "Ovo", value: "Ovo" },
      { name: "Stage", label: "Larva", value: "Larva" },
      { name: "Stage", label: "Ninfa", value: "Ninfa" },
      { name: "Stage", label: "Pupa", value: "Pupa" },
      { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
    ]
  },
  { label: "Vertebrados", 
    options: [
      { name: "Stage", label: "Filhote", value: "Filhote" },
      { name: "Stage", label: "Jovem", value: "Jovem" },
      { name: "Stage", label: "Adulto", value: "Adulto" }
    ] 
  }
];
const groupedInvertebratesOptions = [
  {
    label: "Invertebrados",
    options: [
      { name: "Stage", label: "Ovo", value: "Ovo" },
      { name: "Stage", label: "Larva", value: "Larva" },
      { name: "Stage", label: "Pupa", value: "Pupa" },
      { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
    ]
  }
];
const options =[
  { name: "Stage", label: "Ovo", value: "Ovo" },
  { name: "Stage", label: "Larva", value: "Larva" },
  { name: "Stage", label: "Ninfa", value: "Ninfa" },
  { name: "Stage", label: "Pupa", value: "Pupa" },
  { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
  { name: "Stage", label: "Filhote", value: "Filhote" },
  { name: "Stage", label: "Jovem", value: "Jovem" },
  { name: "Stage", label: "Adulto", value: "Adulto" }
];
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};



class AddSampleResult extends React.Component {

    constructor (props) {
      super(props);
      if(!checkPermissionComponent("7d306cb1-0df5-4fdc-a658-8f7492292172", "create")){
        alert("Você não tem permissão para acessar essa funcionalidade!");
        props.history.goBack(); 
      }
      this.state = {
        isLoadingSamples: false,
        showResults: false,
        wasSaved: false,
        enableEditNumber: false,
        fractionatedSamples: [],
        isPossibleDiagnosis:true,
        sampleNumber: "",
        principalSampleData: "1",
        
        data: [this.createBaseSampleObject()]
      };
    }

  handleChange(event) {
    const {data} = this.state

    data[event.target.name] = event.target.value
    this.setState({data});
  };

  createBaseSampleObject = (fractionatedNumber = 0, post = false) => {
    let objData = {
      QuantitySoma: 0,
      QuantityAliveSoma: 0,
      QuantityDeadSoma: 0,
      sampleData: "1",
      sampleDataNumber: this.state ? this.state.sampleNumber : "",
      sampleResults: [],
      sampleConservationType:"00000000-0000-0000-0000-000000000000",
      sampleJustificationDisposalTypes:[],
      techniques:[],
      post: post,
      sampleClassification: [{
        sampleClassificationId:"00000000-0000-0000-0000-000000000000",
        LaboratoryId:"00000000-0000-0000-0000-000000000000",
        externSampleNumber:"",
        description:"",
        exams:[{
          examsHistoryId: "00000000-0000-0000-0000-000000000000",
          examId:"00000000-0000-0000-0000-000000000000",
          techniques:[],
          techniqueId:"00000000-0000-0000-0000-000000000000",
        }],
      }],
      sampleDiagnosis:[{
        SampleResultId:"00000000-0000-0000-0000-000000000000",
        LaboratoryId:"00000000-0000-0000-0000-000000000000",
        externSampleNumber:"",
        description:"",
        exams:[{
          examDiagnosticId: "00000000-0000-0000-0000-000000000000",
          examId:"00000000-0000-0000-0000-000000000000",
          techniques:[],
          techniqueId:"00000000-0000-0000-0000-000000000000",
        }],
        diagnostic:"00000000-0000-0000-0000-000000000000",
        result:null,
      }],
      sampleIdentifications:[{
        SampleIdentificationId:"00000000-0000-0000-0000-000000000000",
        Gender:"",
        Stage:"",
        Quantity:0,
        QuantityAlive:0,
        QuantityDead:0,
        Show: true
      }],
      animalTypeGroups:[],
      animalGroups: [],
      animalGenres:[],
      animalSpecies:[],
      selectedAnimalTypeGroup: '',
      selectedAnimalGroup:'00000000-0000-0000-0000-000000000000',
      selectedAnimalGenre:'00000000-0000-0000-0000-000000000000',
      selectedAnimalSpecie:'00000000-0000-0000-0000-000000000000',
      selectedVirusType: '00000000-0000-0000-0000-000000000000',
      LaboratoryId:'',
      programs: [],
      selectedProgram: "",
      exams:[],
      identificationsExams: [],
      diagnosticsExams: [],
      identifications: [],
      Demand:null,
      Trap:null,
      Laboratories:[],
      ConservationTypes:[],
      JustificationDisposalTypes:[],
      isDisableOrganization: false
    };
    return objData;
  }

  loadSampleData = async (sample, indexSample) => { 
    
    this.resetState(this.state.sampleNumber);
    var sampleNumber = this.state.sampleNumber;
    
    if(indexSample > 0) {
      let objData = await this.createBaseSampleObject(indexSample);

      const {data} = this.state;

      data.push(objData);

      this.setState({data})

      console.log("data teste before cbdtc 2", JSON.parse(JSON.stringify(data)))
      await this.createBaseDataToComponent(indexSample, true, sample);
    } else {
      const {data} = this.state;

      console.log("data teste before cbdtc", JSON.parse(JSON.stringify(data)))
      await this.createBaseDataToComponent(indexSample, false, sample);
    }


    if(sampleNumber !== ""){
        if(sample.status !== "Discarded" && sample.status !== "Diagnostic not possible"){
          if(sample.typeAnimalGroupIdentified !== undefined){
            await this.selectedTypeGroup(sample.typeAnimalGroupIdentified, indexSample);

            if(sample.animalIdentified !== undefined){
              await this.selectedGroup(sample.animalIdentified, indexSample);
            }

            if(sample.genderIdentified !== undefined){
              await this.selectedGenre(sample.genderIdentified, indexSample);
            }

            if(sample.speciesIdentified !== undefined ){
              this.state.data[indexSample].selectedAnimalSpecie = sample.speciesIdentified  
            }

            if(sample.virusTypeIdentified !== undefined){
              this.state.data[indexSample].selectedVirusType = sample.virusTypeIdentified
            }
          }

          //pegando os registros de classificação.
          if(sample.identificationSampleHistory != null && sample.identificationSampleHistory.length > 0){
            sample.identificationSampleHistory.forEach((classification, index) =>{
              
              let examsObj = [];
              classification.exams.forEach((exam, index) => {
                examsObj[index] = {
                  examsHistoryId: exam.examsHistoryId,
                  examId:exam.examId,
                  techniques:exam.techniques,
                  techniqueId:exam.techniqueId,
                }
              })
              let classificationObj = {
                sampleClassificationId:classification.sampleClassificationId,
                LaboratoryId:classification.laboratoryId,
                externSampleNumber:classification.externSampleNumber,
                description: classification.description,
                exams: examsObj,
              }
              this.state.data[indexSample].sampleClassification[index] = classificationObj;
            })
          }

          //pegando os registros de diagnóstico
          if(sample.sampleDiagnosticHistory != null && sample.sampleDiagnosticHistory.length > 0){
            sample.sampleDiagnosticHistory.forEach((diagnostic, index) =>{
              
              let examsObj = [];
              diagnostic.exams.forEach((exam, index) => {
                examsObj[index] = {
                  examDiagnosticId: exam.examDiagnosticId,
                  examId:exam.examId,
                  techniques:exam.techniques,
                  techniqueId:exam.techniqueId,
                }
              })
              let diagnosticObj = {
                SampleResultId:diagnostic.sampleResultId,
                LaboratoryId:diagnostic.laboratoryId,
                externSampleNumber:diagnostic.externSampleNumber,
                exams:examsObj,
                description: diagnostic.description,
                diagnostic:diagnostic.diagnostic,
                result:diagnostic.result,
              }
              this.state.data[indexSample].sampleDiagnosis[index] = diagnosticObj;
            })
          }

          this.state.data[indexSample].id = sample.id; 

          // pegando dados de informações extras (Fracionario, numero extra e etc)
          if(sample.sampleExtraInformations) {
            this.state.data[indexSample].sampleExtraInformations = sample.sampleExtraInformations;
            console.log("sample inside IF", sample)
            if(sample.sampleExtraInformations.isFractionated){
              this.state.data[indexSample].principalSampleOfFractionated = sample.principalSampleOfFractionated;
            }
            
          }

          
  


          //pegando os registros de identificação
          if(sample.sampleAnalysisIdentification != null && sample.sampleAnalysisIdentification.length > 0){
            sample.sampleAnalysisIdentification.forEach((analysisIdentification, index) => {
              let analysisIdentificationObj = {
                SampleAnalysisIdentificationId:analysisIdentification.sampleAnalysisIdentificationId,
                Gender:analysisIdentification.gender,
                Stage:analysisIdentification.stage,
                Quantity:analysisIdentification.quantity,
                QuantityAlive:analysisIdentification.quantityAlive,
                QuantityDead:analysisIdentification.quantityDead,
                Show: true,
              }
              this.state.data[indexSample].sampleIdentifications[index] = analysisIdentificationObj;
            })
          }

          console.log("sample & indexSample", sample, indexSample)
          // if(sample.sampleAnalysisIdentification.length > 0 || sample.sampleDiagnosticHistory.length > 0 || sample.identificationSampleHistory.length > 0){
          // } retirando para teste várias amostras
          this.setState({showResults: true})
          this.state.data[indexSample].sampleConservationType = sample.conservationTypeId;

          this.handleChangeArrayData("sampleData", sample, indexSample);
        }else{
          toast.error("Erro: A amostra está inviável ou foi descartada!");
        }
          
        var requestDemand = fetchDemand(this.state.data[0].sampleData.demandId);
        requestDemand.then(responseDemand =>{
          this.handleChangeArrayData("Demand", responseDemand.data)
        })

        var trapTypes;
        var traptyperequest = getTrapsTypes();
        traptyperequest.then(typeRequest=>{
          trapTypes = typeRequest.data;
          var requestTrap = getTrapById(this.state.data[0].sampleData.trapId);
          requestTrap.then(responseTrap =>{
            trapTypes.map(type=>{
              if(type.id == responseTrap.data.trapTypeId){
                responseTrap.data.trapTypeId = ARMADILHA_INFO[type.name].apelido;
                this.handleChangeArrayData("Trap", responseTrap.data)
              }
            })
          })
        })
        

        var requestUser = getUser(this.state.data[0].sampleData.collectedBy)
        requestUser.then(response => {
          if(response.data){
            let requestPerson = getPerson(response.data.personId);
            requestPerson.then(responsePerson => {
              if(responsePerson.data){
                this.state.data[0].sampleData.personName = responsePerson.data.name;
                this.forceUpdate();
              }
            })
          }
        })
      
    }else{
      toast.error("Erro: É necessário digitar um número de amostra");
    }
    this.setState({isLoadingSamples: false})
  }

  async createBaseDataToComponent(index = 0, copyPrincipalData = false, sampleInformations = null){
    const {data} = this.state;
    
    this.setState({isPossibleDiagnosis : true})
    var isDisableOrganization = getUserData("organizationId") == "37601ec4-d2ef-4f86-aeac-fd261e8fded4";
    this.setState({isDisableOrganization: isDisableOrganization});
    
    var requestLaboratories = getAllLaboratories();
    requestLaboratories.then(response =>{
      response.data.map(laboratory=>{
        data[index].Laboratories.push({
          target:{
            name:"LaboratoryId",
            value: laboratory.id,
          },
          label:laboratory.name,
          value:laboratory.id
        })
      });
    });
    
    var requestPrograms = fetchPrograms();
    requestPrograms.then(response => {
      this.handleChangeArrayData("programs", response.data,index)
    });

    var requestIdExams = getIndentificationsExam();
    requestIdExams.then(response => {
      this.handleChangeArrayData("identificationsExams", response.data, index)
    });
    var requestDiaExams = getDiagnosticsExam();
    requestDiaExams.then(response => {
      this.handleChangeArrayData("diagnosticsExams", response.data, index)
    });
    var requestIdentifications = fetchIdentifications();
    requestIdentifications.then(response =>{
      console.log("response.data identifications index", response.data, index)
      this.handleChangeArrayData("identifications", response.data, index)
    });
    var requestTechnique = getTechniques();
    requestTechnique.then(response => {
      this.handleChangeArrayData("techniques", response.data, index)
    });

    var sampleNumber = this.state.sampleNumber;

    if(this.props.location.data!= null){
      var sampleNumber = this.props.location.data.number;
    }

    if(sampleNumber !== ""){
      if(index == 0){
        var requestPrincipalSample = fetchSamplesByNumber(sampleNumber);

        requestPrincipalSample.then(response => {
          const {data} = this.state;

          let sample = response.data;

          data[index].sampleData = sample;

          data[index].sampleData.number = this.state.sampleNumber;
          
  
          this.setState({principalSampleData: sample, data})
        })
      } else if (copyPrincipalData) {
        var requestPrincipalSample = fetchSamplesByNumber(sampleNumber);

        requestPrincipalSample.then(response => {
          const {data} = this.state;

          let samples = response.data;
          
          console.log("sample inside copy", JSON.parse(JSON.stringify(samples)));

          if(sampleInformations) {
            console.log("sampleInformations", sampleInformations)
            if(sampleInformations.sampleExtraInformations && sampleInformations.sampleExtraInformations.isFractionated) {
              var indexOfPrincipalSample = response.data.findIndex(s => s.id == sampleInformations.sampleExtraInformations.fracionedBySampleId);
              
              data[index].indexOfPrincipalSample = indexOfPrincipalSample;
              data[index].principalSampleData = response.data[indexOfPrincipalSample];
            }
          }

          data[index].sampleData = samples[0];

          data[index].sampleData.number = this.state.sampleNumber + "###F." + index;
          
          this.setState({data})
        })
      }
    }

    var requestConservationType = getConservationTypes();
    requestConservationType.then(response => {
      let types = [];
      
      response.data.map(type => {
        types.push({
          label:type.name,
          value:type.id,
        });
      })
        this.handleChangeArrayData("ConservationTypes", types, index)
    });
    
    var requestJustificationDisposalType = getJustificationDisposalTypes();
    requestJustificationDisposalType.then(response => {
      let types = [];
      
      response.data.map(type => {
        types.push({
          label:type.name,
          value:type.id,
        });
      })
        this.handleChangeArrayData("JustificationDisposalTypes", types, index)
    });
    
    var requestAnimalTypeGroups = fetchAnimalTypeGroups();
    await requestAnimalTypeGroups.then(async response => {
      let typeGroups = [];
      await response.data.map(typeGroup => {
        typeGroups.push({
          label:typeGroup.name,
          value:typeGroup.id,
        });
      })
      this.handleChangeArrayData("animalTypeGroups", typeGroups, index)
    })
  }

  async componentDidMount (){
    this.setState({isLoadingSamples:true})
    var isDisableOrganization = getUserData("organizationId") == "37601ec4-d2ef-4f86-aeac-fd261e8fded4";
    this.handleChangeArrayData("isDisableOrganization", isDisableOrganization)    
    if(this.props.location.data!= null){
      var sampleNumber = this.props.location.data.number;
      var fractionatedSamples = this.props.location.data.fractionatedSamples;

      fractionatedSamples.sort(function(a, b) {
        var numeroA = parseFloat(a.number);
        var numeroB = parseFloat(b.number);
    
        if (numeroA < numeroB) {
            return -1;
        } else if (numeroA > numeroB) {
            return 1;
        } else {
            return 0;
        }
      });

      this.setState({sampleNumber: sampleNumber, fractionatedSamples: fractionatedSamples}, () => {
        try {
          var requestSample = fetchSamplesByNumber(sampleNumber);
          requestSample.then((samples) => {
            console.log("todas samples", samples)
            samples.data.map((sample, indexSample) => {
              this.loadSampleData(sample, indexSample);
            })
          })
        } catch(e) {
          console.log("error na amostra inexistente")
          console.log(e)
          toast.error("Erro: Amostra inexistente");
        };
      });
    } 

    
    if(isDisableOrganization){
      await this.selectedTypeGroup("84f3cd9c-9894-4021-9355-2d1b2ed15e0d");
      await this.selectedGroup("178886c4-a41c-4d15-ac3c-a58806bb8df9");

      this.handleChangeArrayData("selectedAnimalTypeGroup", "84f3cd9c-9894-4021-9355-2d1b2ed15e0d")
      this.handleChangeArrayData("selectedAnimalGroup", "178886c4-a41c-4d15-ac3c-a58806bb8df9")
    }
  }

  handleChangeArrayData(key, value, index = 0){
    console.log("index handleChangeArrayData", index)
    const {data} = this.state;
    
    var arrayCopy = [...data];

    console.log("data arrayCopy", arrayCopy);
    arrayCopy[index][key] = value;

    this.setState({data: arrayCopy});
  }
  

  handleChangeDiagnosis(index, element, array = null, arrayIndex = null, indexSample = 0){
    let sampleDiagnosis = this.state.data[indexSample].sampleDiagnosis;
    if(array !== null){
      sampleDiagnosis[index][array][arrayIndex][element.target.name] = element.target.value;

      if(element.target.name == 'examId'){
        let examSelected = this.state.data[0].exams.filter((exam) => exam.id == element.target.value);
        if(examSelected.length > 0){
          sampleDiagnosis[index][array][arrayIndex].techniques = examSelected[0].techniques;
          sampleDiagnosis[index][array][arrayIndex].techniqueId = "00000000-0000-0000-0000-000000000000";
        }
      }
    }else{        
      sampleDiagnosis[index][element.target.name] = element.target.value;
    }

    this.handleChangeArrayData("sampleDiagnosis", sampleDiagnosis,indexSample)
  }

  handleFowardedSample(classification,index, array = null, arrayIndex = null, indexSample = 0){

    const {data} = this.state;
    let sampleFowardClassification = data[indexSample].sampleClassification;
    if(array !== null){
      sampleFowardClassification[index][array][arrayIndex][classification.target.name] = classification.target.value;

      if(classification.target.name == 'examId'){
        let examSelected = data[0].exams.filter((exam) => exam.id == classification.target.value);
        if(examSelected.length > 0){
          sampleFowardClassification[index][array][arrayIndex].techniques = examSelected[0].techniques;
          sampleFowardClassification[index][array][arrayIndex].techniqueId = "00000000-0000-0000-0000-000000000000";
        }
      }

    }else{
      sampleFowardClassification[index][classification.target.name] = classification.target.value;
    }

    this.handleChangeArrayData("sampleClassification", sampleFowardClassification, indexSample)

  }

  handleChangeIdentification(identification, valueInput, index, indexSample){
    let sampleIdentifications = this.state.data[indexSample].sampleIdentifications;
    if(valueInput){
      if(identification == 'Quantity' || identification == 'QuantityAlive' || identification == 'QuantityDead'){
        let value = parseInt(valueInput);
        sampleIdentifications[index][identification] = value >= 0 || value < 0 ? value : 0; //prevenindo bug
        if(identification !== 'Quantity'){
          sampleIdentifications[index]["Quantity"] = sampleIdentifications[index]["QuantityAlive"] +  sampleIdentifications[index]["QuantityDead"];

          var QuantitySoma = 0;
          var QuantityAliveSoma = 0;
          var QuantityDeadSoma = 0;

          sampleIdentifications.map((sampleIdent, indexSI) => {
            QuantitySoma += sampleIdentifications[indexSI]["QuantityAlive"] +  sampleIdentifications[indexSI]["QuantityDead"];
            QuantityAliveSoma += sampleIdentifications[indexSI]["QuantityAlive"];
            QuantityDeadSoma += sampleIdentifications[indexSI]["QuantityDead"];
          })

          this.handleChangeArrayData("QuantitySoma", QuantitySoma, indexSample)
          this.handleChangeArrayData("QuantityAliveSoma", QuantityAliveSoma , indexSample)
          this.handleChangeArrayData("QuantityDeadSoma", QuantityDeadSoma, indexSample)
        } else {
          var QuantitySoma = 0;
          var QuantityAliveSoma = 0;
          var QuantityDeadSoma = 0;

          sampleIdentifications[index]["QuantityAlive"] = 0;
          sampleIdentifications[index]["QuantityDead"] = 0;

        sampleIdentifications.map((sampleIdent, indexSI) => {
            QuantitySoma += sampleIdentifications[indexSI]["Quantity"];
            QuantityAliveSoma += sampleIdentifications[indexSI]["QuantityAlive"];
            QuantityDeadSoma += sampleIdentifications[indexSI]["QuantityDead"];
          })

          this.handleChangeArrayData("QuantitySoma",QuantitySoma , indexSample)
          this.handleChangeArrayData("QuantityAliveSoma",QuantityAliveSoma, indexSample)
          this.handleChangeArrayData("QuantityDeadSoma",QuantityDeadSoma, indexSample)
        }
      }else{
        sampleIdentifications[index][identification] = valueInput;  
      }
    }else{
      sampleIdentifications[index][identification] = valueInput;
    }
    this.handleChangeArrayData("sampleIdentifications", sampleIdentifications, indexSample)
  }

  removeResult = (index) =>{
      var sampleResults = this.state.data[0].sampleResults;
      sampleResults.splice(index, 1);
      this.handleChangeArrayData("sampleResults", sampleResults)
      
  }

  handleSendSampleResult = async (saveAndContinue, index) => {
    await SendSampleResult(this.state, this.setState.bind(this), saveAndContinue, index);
  };

  selectedTypeGroup = async (typeGroup, index = 0) =>{
    const {data} = await fetchAnimalGroups(typeGroup);
    const groups = data.map(({name, id}) => ({label: name, value: id}))
    
    this.handleChangeArrayData("selectedAnimalTypeGroup", typeGroup, index)
    this.handleChangeArrayData("selectedAnimalGroup", '00000000-0000-0000-0000-000000000000', index)
    this.handleChangeArrayData("selectedAnimalGenre", '00000000-0000-0000-0000-000000000000', index)
    this.handleChangeArrayData("selectedVirusType", '00000000-0000-0000-0000-000000000000', index)
    this.handleChangeArrayData("selectedAnimalSpecie", '00000000-0000-0000-0000-000000000000', index)
    this.handleChangeArrayData("animalGroups", groups, index)
  }

  selectedGroup = async (group, index = 0) =>{
    const {data} = await fetchAnimalGenres(group);
    const genres = data.map(({name, id}) => ({ label: name, value: id }))
    console.log("group", "index")
    console.log(group, index)

    this.handleChangeArrayData("selectedAnimalGroup", group, index)
    this.handleChangeArrayData("selectedAnimalGenre", '00000000-0000-0000-0000-000000000000', index)
    this.handleChangeArrayData("selectedVirusType", '00000000-0000-0000-0000-000000000000', index)
    this.handleChangeArrayData("selectedAnimalSpecie", '00000000-0000-0000-0000-000000000000', index)
    this.handleChangeArrayData("animalGenres", genres, index)
  }

  selectedGenre = async (genre, index = 0) =>{
    try{
      const specieResponse = await fetchAnimalSpecies(genre);
      const species = specieResponse.data.map(({name, id}) => ({label: name, value: id}));
  
      const virusResponse = await fetchGenreVirusType(genre);
      const viruses = virusResponse.data.map(({name, id}) => ({label: name, value: id}));
  
      
      this.handleChangeArrayData("selectedAnimalGenre", genre, index)
      this.handleChangeArrayData("selectedAnimalSpecie", '00000000-0000-0000-0000-000000000000', index)
      this.handleChangeArrayData("selectedVirusType", '00000000-0000-0000-0000-000000000000', index)
      this.handleChangeArrayData("animalSpecies", species, index)
      this.handleChangeArrayData("virusTypes", viruses, index)
    }catch(error){

    }
  }

  resetState(sampleNumber, index = 0){
    this.state.data[index].sampleData = "1";
    this.state.data[index].sampleDataNumber = sampleNumber;
    this.state.data[index].sampleResults = [];
    this.state.data[index].animalGroups = [];
    this.state.data[index].animalGenres = [];
    this.state.data[index].animalSpecies = [];
    this.state.data[index].selectedAnimalTypeGroup = '';
    this.state.data[index].selectedAnimalGroup = '00000000-0000-0000-0000-000000000000';
    this.state.data[index].selectedAnimalGenre = '00000000-0000-0000-0000-000000000000';
    this.state.data[index].selectedAnimalSpecie ='00000000-0000-0000-0000-000000000000';
    this.state.data[index].selectedVirusType = '00000000-0000-0000-0000-000000000000';
    this.state.data[index].programs = [];
    this.state.data[index].selectedProgram = "";
    this.state.data[index].identifications = [];
    this.state.isPossibleDiagnosis = true;
    this.state.data[index].Demand = null;
    this.state.data[index].Trap = null;

    this.forceUpdate();
  }

  addDiagnosis(indexSample = 0){
    let diagnosis = this.state.data[indexSample].sampleDiagnosis;
    diagnosis.push({
      SampleResultId:"00000000-0000-0000-0000-000000000000",
      ExternLaboratoryId:"00000000-0000-0000-0000-000000000000",
      externSampleNumber:"",
      description:"",
      exams:[{
        examDiagnosticId: "00000000-0000-0000-0000-000000000000",
        examId:"00000000-0000-0000-0000-000000000000",
        techniques:[],
        techniqueId:"00000000-0000-0000-0000-000000000000",
      }],
      diagnostic:"",
      result:false,
    })

    this.handleChangeArrayData("sampleDiagnosis",diagnosis, indexSample)
  }

  removeDiagnosis = (indexSample = 0) =>{
    var sampleDiagnosis = this.state.data[indexSample].sampleDiagnosis;
    sampleDiagnosis.pop();
    this.handleChangeArrayData("sampleDiagnosis",sampleDiagnosis, indexSample)
  }

  addDiagnosisExam(index, indexSample){
    let sampleDiagnosis = this.state.data[indexSample].sampleDiagnosis;
    sampleDiagnosis[index].exams.push({
      examDiagnosticId: "00000000-0000-0000-0000-000000000000",
      examId:"00000000-0000-0000-0000-000000000000",
      techniques:[],
      techniqueId:"00000000-0000-0000-0000-000000000000",
    })
    
    this.handleChangeArrayData("sampleDiagnosis",sampleDiagnosis, indexSample)
  }

  removeDiagnosisExam(index, indexSample){
    let sampleDiagnosis = this.state.data[indexSample].sampleDiagnosis;
    sampleDiagnosis[index].exams.pop();
    this.handleChangeArrayData("sampleDiagnosis",sampleDiagnosis, indexSample)
  }

  addLabForward(index = 0){
    let classifications = this.state.data[index].sampleClassification;
    classifications.push({
      sampleClassificationId:"00000000-0000-0000-0000-000000000000",
      LaboratoryId:"00000000-0000-0000-0000-000000000000",
      externSampleNumber:"",
      description:"",
      exams:[{
        identificationSampleHistoryId: "00000000-0000-0000-0000-000000000000",
        examId:"00000000-0000-0000-0000-000000000000",
        techniques:[],
        techniqueId:"00000000-0000-0000-0000-000000000000",
      }],
    })

    this.handleChangeArrayData("sampleClassification",classifications, index)

  }

  removeLabForward = (index) =>{
    var sampleClassification = this.state.data[index].sampleClassification;
    sampleClassification.pop();
    this.handleChangeArrayData("sampleClassification",sampleClassification,index)
    
  }

  addClassificationExam(index, indexSample){
    let sampleClassification = this.state.data[indexSample].sampleClassification;
    sampleClassification[index].exams.push({
      identificationSampleHistoryId: "00000000-0000-0000-0000-000000000000",
      examId:"00000000-0000-0000-0000-000000000000",
      techniques:[],
      techniqueId:"00000000-0000-0000-0000-000000000000",
    })
    this.handleChangeArrayData("sampleClassification",sampleClassification, indexSample)
  }

  removeClassificationExam(index, indexSample){
    let sampleClassification = this.state.data[indexSample].sampleClassification ;
    sampleClassification[index].exams.pop();

    this.handleChangeArrayData("sampleClassification",sampleClassification, indexSample)
  }

  addIdentification(index = 0){
    var QuantitySoma = 0;
    var QuantityAliveSoma = 0;
    var QuantityDeadSoma = 0;

    let sampleIdentifications = this.state.data[index].sampleIdentifications;
    sampleIdentifications.push({
      SampleIdentificationId:"00000000-0000-0000-0000-000000000000",
      Gender:"",
      Stage:"",
      Quantity:0,
      QuantityAlive:0,
      QuantityDead:0,
      Show: true
    });

    sampleIdentifications.map((sampleIdent, indexSI) => {
      QuantitySoma += sampleIdentifications[indexSI]["Quantity"];
      QuantityAliveSoma += sampleIdentifications[indexSI]["QuantityAlive"];
      QuantityDeadSoma += sampleIdentifications[indexSI]["QuantityDead"];
    })

    this.handleChangeArrayData("QuantitySoma",QuantitySoma , index)
    this.handleChangeArrayData("QuantityAliveSoma",QuantityAliveSoma, index)
    this.handleChangeArrayData("QuantityDeadSoma",QuantityDeadSoma, index)

    this.handleChangeArrayData("sampleIdentifications",sampleIdentifications,index)
  }

  removeIdentification(indexIdentification, index){
    var QuantitySoma = 0;
    var QuantityAliveSoma = 0;
    var QuantityDeadSoma = 0;
    let sampleIdentifications = this.state.data[index].sampleIdentifications;
    sampleIdentifications[indexIdentification].Quantity = 0;
    sampleIdentifications[indexIdentification].QuantityAlive = 0;
    sampleIdentifications[indexIdentification].QuantityDead= 0;

    sampleIdentifications.map((sampleIdent, indexSI) => {
      QuantitySoma += sampleIdentifications[indexSI]["Quantity"];
      QuantityAliveSoma += sampleIdentifications[indexSI]["QuantityAlive"];
      QuantityDeadSoma += sampleIdentifications[indexSI]["QuantityDead"];
    })

    this.handleChangeArrayData("QuantitySoma",QuantitySoma , index)
    this.handleChangeArrayData("QuantityAliveSoma",QuantityAliveSoma, index)
    this.handleChangeArrayData("QuantityDeadSoma",QuantityDeadSoma, index)



    sampleIdentifications[indexIdentification].Show = false;
    this.handleChangeArrayData("sampleIdentifications",sampleIdentifications, index)
  }

  addJustificationType(e){
    var justifications = [];
    console.log('e', e)
    if(e !== null){
      e.map(justificationType=>{
        if(Object.keys(justificationType).length == 2) {
          justifications.push(justificationType);
        } else {
          justifications.push({"id":justificationType.value,"name":justificationType.label, "label":justificationType.label,"description":""});
        }
      })
    }
    this.handleChangeArrayData("sampleJustificationDisposalTypes",justifications)
  }

  async saveSamples () {
    const {data} = this.state;
                 
    // Se tem mais do que só a amostra principal
    if(data.length > 0){
      var listOfIndexSamplesResults = [];
      var listOfIndexSamplesFractionated = [];
      
      data.map((s, sIndex) => {
        if(s.isFractionated) {
          listOfIndexSamplesFractionated.push(sIndex) 
        } else {
          listOfIndexSamplesResults.push(sIndex) 
        }
      });

      var samplesResultResponse = Promise.all(listOfIndexSamplesResults.map(async (valueOfIndex) => {
        console.log("Fiz o resultado")
        await this.handleSendSampleResult(false, valueOfIndex);
      }))

      await samplesResultResponse;


      var samplesFractionatedResponse = Promise.all(listOfIndexSamplesFractionated.map(async (valueOfIndex) => {
        console.log("Fiz o fracionado")
        await this.handleSendSampleResult(false, valueOfIndex);
      }))
      
      await samplesFractionatedResponse;

      this.setState({wasSaved: true});
    } else {
      await this.handleSendSampleResult(false)

      this.setState({wasSaved: true});
    }                    
  }

  render() {
    var {sampleData} = this.state.data[0];
    var {sampleResults} = this.state.data[0];
    
    

    var showResult;
    //this.state.data[0].sampleNumber = this.props.sample!=null?this.props.sample.number:''
    return (
      <>
      <ToastContainer/>
        <Container className="container-flex-result" fluid>
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <Link to="/admin/amostrasColetadas">
                    <h3 className="mb-0">
                      <i className=" ni ni-bold-left bnt-voltar" />
                      Voltar
                    </h3>
                  </Link>
                </div>
              </Row>
            </CardHeader>


            <Container fluid>
              <div className="row linha-form">
                <div className="col-4 responsive-form-field-result-medium">
                  <span className="h5 text-black">Número da Amostra</span>
                  <input value={this.state.sampleNumber}  type="text" className="form-control" placeholder="Número da amostra" name="sampleNumber" onChange={ (e) => this.handleChange(e) } />
                </div>
                <div className="col-4 responsive-form-field-result-medium">
                <span className="h5 text-black">&nbsp;</span>
                  <button onClick={() => this.setState({wasSaved: false}, this.loadSampleData)} className="btn btn-primary responsive-button-result">Verificar</button>
                </div>
              </div>
              
              {this.state.data[0].sampleData !== '1'?
                <Card style={{"marginTop": "2%", "marginBottom": "2%"}}> 
                  <CardHeader style={{"border": "0.1px solid lightgrey"}}>
                    <span className="h5 text-black">Detalhes da amostra</span>
                  </CardHeader>
                  <CardBody style={{"border": "0.1px solid lightgrey"}}>
                  <div className="row"style={{"marginTop": "2%", "marginBottom": "2%"}} >
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">N. da Amostra: </span>
                      {!this.state.enableEditNumber ?
                      <>
                      <span className="h5 text-black">{this.state.sampleNumber} </span>
                        <i onClick={() => this.setState({enableEditNumber : true})} className="fa fa-edit"></i>                                        
                      </>
                      : 
                        <input className="form-control" value={this.state.sampleNumber} onChange={(e) => {this.setState({sampleNumber: e.target.value})}}></input>
                      }
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">N. da OS: {this.state.data[0].Demand == null ? "":this.state.data[0].Demand.serviceOrder}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Responsável: {this.state.data[0].sampleData.personName}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Depósito: {this.state.data[0].sampleData.deposit}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Animal: {this.state.data[0].sampleData.animal}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Material Coletado: {this.state.principalSampleData.typeMaterial}</span>
                    </div>
                    {
                      this.state.fractionatedSamples && this.state.fractionatedSamples.length > 0 
                      ?
                      this.state.fractionatedSamples.map((sampleF) => {
                        return (
                          <>
                          <div className="linha-form col-4 responsive-form-field-result-full">
                            <span className="h5 text-black">Quantidade {sampleF.typeMaterial}:</span>
                          </div>
                          </>
                        )
                      })
                      : 
                      <></>
                    }
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Quantidade: {this.state.data[0].sampleData.quantity}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Quantidade de Vivos: {this.state.data[0].sampleData.quantityLiveMaterial}</span>
                    </div>
                    <div className="linha-form col-4 responsive-form-field-result-full">
                      <span className="h5 text-black">Quantidade de Mortos: {this.state.data[0].sampleData.quantityDeadMaterial}</span>
                    </div>
                    
                  
                  {this.state.data[0].Trap != null?
                    <>
                      <div className="linha-form col-4 responsive-form-field-result-full">
                        <span className="h5 text-black">N. da Armadilha: {this.state.data[0].Trap.number}</span>
                      </div>
                      <div className="linha-form col-4 responsive-form-field-result-full">
                        <span className="h5 text-black">Tipo de armadilha: {this.state.data[0].Trap.trapTypeId}</span>
                      </div>
                    </>
                  :""}
                  </div>
                  </CardBody>
                </Card>
              :""}
              {/* formulario de preenchimendo dos resultados da amostra */}
              {sampleData !== '1' ? (
              <>
                <div className="row">
                  <div className="col-3 responsive-form-field-result-full">
                      <span className="h5 text-black">Amostra viável para análise?</span>
                      <Select 
                        onChange={(e) => {this.setState({isPossibleDiagnosis : e.value})}}    
                        defaultValue={{label:"Sim", value: true}}  
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        menuPlacement="auto"                                            
                        options={[
                          {label:"Sim", value: true},
                          {label:"Não", value: false}
                        ]} 
                      />
                  </div>
                  {this.state.isPossibleDiagnosis ? 
                    <>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="h5 text-black">Tipo de conservação</span>
                          <Select 
                            value = {this.state.data[0].ConservationTypes.filter(ct => ct.value == this.state.data[0].sampleConservationType)}
                            options={this.state.data[0].ConservationTypes}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            onChange={(e) => {this.handleChangeArrayData("sampleConservationType",e.value)} }
                          />
                      </div>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="h5 text-black">Grupo de animais envolvidos</span>
                          <Select 
                            key={this.state.data[0].selectedAnimalTypeGroup}
                            options={this.state.data[0].animalTypeGroups}
                            value={this.state.data[0].selectedAnimalTypeGroup !== '' ? this.state.data[0].animalTypeGroups.filter(obj => obj.value === this.state.data[0].selectedAnimalTypeGroup):null}
                            isClearable={true}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            isDisabled={this.state.data[0].isDisableOrganization}
                            onChange={(e) => {if(e !== null){ this.selectedTypeGroup(e.value)}else if(!this.state.data[0].isDisableOrganization){this.selectedTypeGroup("00000000-0000-0000-0000-000000000000")}}}  
                          />
                      </div>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="h5">Animal</span>
                          <Select 
                            key={this.state.data[0].selectedAnimalGroup}
                            options={this.state.data[0].animalGroups} 
                            onChange={(e) => {
                              if(e !== null){ 
                                this.selectedGroup(e.value, 0)
                              }
                              else{
                                this.selectedGroup("00000000-0000-0000-0000-000000000000", 0)
                              }
                            }}
                            isClearable={true}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            value={this.state.data[0].selectedAnimalGroup !== '' ? this.state.data[0].animalGroups.filter(obj => obj.value === this.state.data[0].selectedAnimalGroup)[0] : null}
                            isDisabled={this.state.data[0].isDisableOrganization || this.state.data[0].selectedAnimalTypeGroup == '00000000-0000-0000-0000-000000000000'}
                          />
                      </div>

                    </>
                  :
                    <>
                        <div className="col-3 responsive-form-field-result-full">
                          <span className="h5">Justificativa de inviabilidade</span>
                          <Select 
                            options={
                              this.state.data[0].JustificationDisposalTypes.filter(jdt => {
                                var response = true;
                                
                                this.state.data[0].sampleJustificationDisposalTypes.map((sjdt => {
                                  console.log("jdt")
                                  console.log(jdt)
                                  console.log("sjdt")
                                  console.log(sjdt)
                                  if(sjdt.id == jdt.value) {
                                    response = false
                                    console.log("jdt insd")
                                    console.log(jdt)
                                    console.log("sjdt insd")
                                    console.log(sjdt)
                                  }
                                }))
                                return response;
                              })
                            } 
                            onChange={(e) => this.addJustificationType(e)}
                            value={this.state.data[0].sampleJustificationDisposalTypes}
                            isMulti
                          />
                      </div>
                      {this.state.data[0].sampleJustificationDisposalTypes.map(selectedJustificationType =>{
                        if(selectedJustificationType.name == "Outro"){
                          return(
                            <>
                              <div className="col-3 responsive-form-field-result-full">
                                <span className="h5">Descrição de justificativa</span>
                                <input className="form-control"  onChange={ (e) => selectedJustificationType.description = e.target.value} type="text" name="OtherDescription" id=""placeholder="Descrição"/>
                              </div>
                            </>                                
                          )

                        }                           
                      })}
                    </>
                  }
                  
                </div>
                {
                this.state.isLoadingSamples
                ?
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', margin: 20 }}>
                  <LoadingSpin primaryColor="#fff" size={28} />
                </div>
                :
                this.state.data.map((sampleF, index) => {
                  const { showSampleNumber, principalSampleNumber, tooltip } = formatSampleData(sampleF, this.state.data);

                  return (
                    <Card style={{"marginTop": "2%", "marginBottom": "2%", 'display': this.state.showResults ? showResult = "block": showResult = "none"}}>
                      <div className="sticky-header p-4">
                        <span className="h2">
                          <i className='fa fa-vial'></i> Amostra Nº {showSampleNumber ? showSampleNumber : null} 
                          <i className='fa fa-info pl-4' data-tip data-for={"infoSampleFractionated" + index}></i>
                        </span>
                      </div>
                      <ReactTooltip effect="solid" type="info" id={"infoSampleFractionated" + index}>
                          {tooltip}
                      </ReactTooltip>
                      <CardBody className="responsive-card-result">
                        <IdentificationResult 
                          sampleIdentifications={this.state.data[index].sampleIdentifications} 
                          handleChangeIdentification={this.handleChangeIdentification.bind(this)} 
                          options={options} 
                          isDisableOrganization={this.state.data[0].isDisableOrganization}
                          groupedInvertebratesOptions={groupedInvertebratesOptions}
                          groupedOptions={groupedOptions}
                          indexSample={index}
                          QuantityDeadSoma={this.state.data[index].QuantityDeadSoma}
                          QuantityAliveSoma={this.state.data[index].QuantityAliveSoma}
                          QuantitySoma={this.state.data[index].QuantitySoma}
                          addIdentification={this.addIdentification.bind(this)}
                          removeIdentification={this.removeIdentification.bind(this)}
                        />
                        <ClassificationResult
                          sampleClassification={this.state.data[index].sampleClassification}
                          identificationsExams={this.state.data[index].identificationsExams}
                          data={this.state.data}
                          animalGenres={this.state.data[0].animalGenres}
                          selectedAnimalGenre={this.state.data[index].selectedAnimalGenre}
                          selectedAnimalGroup={this.state.data[0].selectedAnimalGroup}
                          animalSpecies={this.state.data[index].animalSpecies}
                          selectedAnimalSpecie={this.state.data[index].selectedAnimalSpecie}
                          Laboratories={this.state.data[0].Laboratories}
                          techniques={this.state.data[index].techniques}
                          handleFowardedSample={this.handleFowardedSample.bind(this)}
                          handleChangeArrayData={this.handleChangeArrayData.bind(this)}
                          selectedGenre={this.selectedGenre.bind(this)}
                          addClassificationExam={this.addClassificationExam.bind(this)}
                          removeClassificationExam={this.removeClassificationExam.bind(this)}
                          addLabForward={this.addLabForward.bind(this)}
                          removeLabForward={this.removeLabForward.bind(this)}
                          index={index}
                        />
                        {this.state.data[0].isDisableOrganization ? "" :
                          <DiagnosisResult
                            data={this.state.data}
                            index={index}
                            isPossibleDiagnosis={this.state.isPossibleDiagnosis}
                            handleChangeDiagnosis={this.handleChangeDiagnosis.bind(this)}
                            addDiagnosisExam={this.addDiagnosisExam.bind(this)}
                            removeDiagnosisExam={this.removeDiagnosisExam.bind(this)}
                            addDiagnosis={this.addDiagnosis.bind(this)}
                            removeDiagnosis={this.removeDiagnosis.bind(this)}
                            handleChangeArrayData={this.handleChangeArrayData.bind(this)}
                          />
                        }
                      </CardBody>
                    </Card>
                  )
                })
                }                                  
                <div className="row mt-4">
                  {
                    this.state.isPossibleDiagnosis && this.state.showResults
                    ?
                    <>
                      <div className="col-6  responsive-form-field-result-full mb-5">
                        <button className="btn btn-info responsive-button-result" disabled={!this.state.isPossibleDiagnosis} onClick={() => {
                          const {data} = this.state;

                          var indexPosterior = data.length;
                          var indexAtual = data.length - 1;

                          data.push(this.createBaseSampleObject(indexPosterior, true))

                          this.createBaseDataToComponent(indexPosterior, true)



                          var sampleResults = data.filter((sample, index) => {
                            if(indexAtual == 0) {
                              return !sample.isFractionated
                            } else {
                              return !sample.isFractionated && index != indexPosterior
                            }
                          }); 
                          
                          var lastSampleResult = sampleResults.length;

                          if(data[0].HasAnotherResult){
                            console.log("lastSampleResult", lastSampleResult)
                            data[indexPosterior].extraNumber = "R" + (lastSampleResult + 1);
                            data[indexPosterior].isAnotherResult = true;
                          } else {
                            data[indexPosterior].isAnotherResult = true;
                            data[indexPosterior].extraNumber = "R2";
                            data[0].HasAnotherResult = true;
                          }
                          
                          data[indexPosterior].indexOfPrincipalSample = 0;

                          console.log("Amostra Outro Resultado adicionado (1 anterior, 2 posterior): ", {
                            sampleDataNumber_1: data[indexAtual].sampleDataNumber,
                            extraNumber_1: data[indexAtual].extraNumber,
                            indexOfPrincipalSample_1: data[indexAtual].indexOfPrincipalSample,
                            HasAnotherResult: data[0].HasAnotherResult,
                            sampleDataNumber_2: data[indexPosterior].sampleDataNumber,
                            extraNumber_2: data[indexPosterior].extraNumber,
                            indexOfPrincipalSample_2: data[indexPosterior].indexOfPrincipalSample,
                            isAnotherResult: data[indexPosterior].isAnotherResult
                          })

                          this.setState({data})
                        }}>
                            + Adicionar resultado
                        </button>
                      </div>
                      <div className="col-6  responsive-form-field-result-full mb-5">
                        <button className="btn btn-info responsive-button-result" disabled={!this.state.isPossibleDiagnosis} onClick={() => {
                          const {data} = this.state;

                          var indexPosterior = data.length;
                          var indexAtual = data.length - 1;

                          data.push(this.createBaseSampleObject(indexPosterior, true))

                          this.createBaseDataToComponent(indexPosterior, true)  

                          if(data[indexAtual].isFractionated){
                            data[indexPosterior].extraNumber = (parseInt(data[indexAtual].extraNumber) + 1).toString();
                            if(data[indexAtual].indexOfPrincipalSample >= 0) {
                              data[indexPosterior].indexOfPrincipalSample = data[indexAtual].indexOfPrincipalSample;
                            }
                          } else {
                            data[indexPosterior].extraNumber = 1;
                            data[indexPosterior].indexOfPrincipalSample = indexAtual;
                            data[indexAtual].WasFractionated = true;
                          }
                          data[indexPosterior].isFractionated = true;   

                        
                          console.log("Amostra principal:")
                          console.log(data[indexAtual])
                          console.log("Amostra fracionada:")
                          console.log(data[indexPosterior])
                          console.log("Amostra Fracionada adicionada (1 anterior, 2 posterior): ", {
                            indexOfPrincipalSample_1: data[indexAtual].indexOfPrincipalSample,
                            indexOfPrincipalSample_2: data[indexPosterior].indexOfPrincipalSample,
                            isFractionated_1: data[indexAtual].isFractionated,
                            isFractionated_2: data[indexPosterior].isFractionated,
                            extraNumber_1: data[indexAtual].extraNumber,
                            extraNumber_2: data[indexPosterior].extraNumber,
                            WasFractionated_1: data[indexAtual].WasFractionated,
                            WasFractionated_2: data[indexPosterior].WasFractionated
                          })

                          this.setState({data})
                        }}>
                            + Adicionar alíquota
                        </button>
                      </div>
                    </>
                    :
                    <div className="col-4  responsive-form-field-result-full">
                      <button className="btn btn-info responsive-button-result" disabled={!this.state.isPossibleDiagnosis} onClick={() => {this.setState({showResults: true})}}>+ Adicionar resultado</button>
                    </div>
                  }
                  <div className="col-4 responsive-form-field-result-medium">
                      <Link to="/admin/amostrasColetadas">                                
                      <button className="form-control btn btn-warning responsive-button-result">Voltar</button>
                      </Link>
                  </div>

                  <div className="col-4 responsive-form-field-result-medium">
                      <button className="btn btn-primary responsive-button-result" disabled={!this.state.showResults && this.state.isPossibleDiagnosis} onClick={async () => await this.saveSamples()}>Salvar</button>
                  </div>

                  {this.state.isPossibleDiagnosis && this.state.showResults ? (
                    <div className="col-4 responsive-form-field-result-full">
                      <Link to={{pathname:"/admin/AddSampleCovid", data:this.state.data[0].sampleData}}>                                                        
                        <button onClick={() => {this.handleSendSampleResult(true);this.setState({wasSaved: true});}} className="btn btn-warning responsive-button-result">Salvar e cadastrar nova amostra</button>
                      </Link>
                    </div> 
                  ) : null}                
                </div>
              </>
            ): null}
            </Container>
          </Card>
        </Container>
      </>
    );
  }

}
export default AddSampleResult;