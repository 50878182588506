import React from "react";

import {
  FeatureGroup,
  LayersControl,
  Map,
  Polygon,
  TileLayer,
  Tooltip,
  ScaleControl,
} from "react-leaflet";
import Control from "react-leaflet-control";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Cookies from "js-cookie";
import { GOOGLE_MAPS_API_KEY } from "../../constants/WebFormConstants";
import { polygon } from "@turf/turf";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
import * as turf from "@turf/turf";
import { Accordion } from "react-bootstrap";
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from "../Accordion/Accordion";
import { Button } from "reactstrap";
import { getUserData } from "../../services/utils/auth";
import usePolygonsColorsPreferences from "../../hooks/usePolygonsColorsPreferences";
import ReactDOM from "react-dom";
import "./Map.css";

const { BaseLayer, Overlay } = LayersControl;

const MapWrapper = ({
  children,
  center,
  handleActiveDrawedPolygonsChange,
  handleUpdateDrawedPolygonsChange,
  handleDeleteDrawedPolygon,
  ...props
}) => {
  const [initalZoom, setInitalZoom] = React.useState(12);
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const [mapInitialCenterPosition, setMapInitialCenterPosition] =
    React.useState([-15.814951, -47.903183]);

  React.useEffect(() => {
    if (!Cookies.get("Admin-Organization")) return;

    const organizationCookies = JSON.parse(Cookies.get("Admin-Organization"));

    if (!organizationCookies.stateSub) return;

    const [organizationLatitude, organizationLongitude] =
      organizationCookies.stateSub.replace(" ", "").split(",");

    if (!validadeLatitudeLongitude(organizationLatitude, organizationLongitude))
      return;

    if (organizationCookies.countySub && organizationCookies.countySub !== "")
      setInitalZoom(Number(organizationCookies.countySub));

    setMapInitialCenterPosition([organizationLatitude, organizationLongitude]);
  }, []);

  const validadeLatitudeLongitude = (latitude, longitude) => {
    if (!latitude || !longitude) return false;

    const latitudeInNumber = +latitude;
    const longitudeInNumber = +longitude;

    if (!(latitudeInNumber >= -90 && latitudeInNumber <= 90)) return false;
    if (!(longitudeInNumber >= -180 && longitudeInNumber <= 180)) return false;

    return true;
  };

  return (
    <Map
      center={center ? center : mapInitialCenterPosition}
      zoom={center ? 18 : initalZoom}
      style={{ height: "47rem" }}
      whenReady={() => setIsMapLoaded(true)}
      {...props}
    >
      {isMapLoaded && (
        <>
          <WindRoseControl />
          <FullscreenControl
            position="topleft"
            title="Tela Cheia"
            titleCancel="Sair da Tela Cheia"
          />
          <MapDrawTools
            handleActiveDrawedPolygonsChange={handleActiveDrawedPolygonsChange}
            handleUpdateDrawedPolygonsChange={handleUpdateDrawedPolygonsChange}
            handleDeleteDrawedPolygon={handleDeleteDrawedPolygon}
          />
          <MapVisualizationLayersControl />
          {children}
          <ScaleControl
            position="bottomleft"
            imperial={false}
            updateWhenIdle={false}
          />
          <VitecLogoControl />
        </>
      )}
    </Map>
  );
};

const MapVisualizationLayersControl = () => {
  return (
    <LayersControl>
      <BaseLayer checked name="Normal">
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </BaseLayer>
      <BaseLayer name="Satélite">
        <ReactLeafletGoogleLayer
          googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_API_KEY }}
          type={"hybrid"}
        />
      </BaseLayer>
    </LayersControl>
  );
};

const MapPolygonsLayersControl = ({
  data,
  layerTitle,
  defaultSelectedTypeLayerName = "",
}) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const typeLayersPolygonsGroup = [];

    data.forEach(({ name, territorializations }) => {
      const typeLayerData = {
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [data]);

  return (
    <LayersControl position="topright">
      {layerTitle !== "" && (
        <BaseLayer checked name={layerTitle}>
          <TileLayer attribution="" url="" />
        </BaseLayer>
      )}
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons }, index) => {
          return (
            <Overlay
              checked={defaultSelectedTypeLayerName === name}
              name={name}
              key={`${name}-overlay-${index}`}
            >
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-featureGroup-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map(({ geometry, name }, index) => {
                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={index}
                        color={"#000000"}
                        fillColor={"#03a1fc"}
                        opacity={1}
                        positions={coordinates}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const MapDrawTools = ({
  handleActiveDrawedPolygonsChange,
  handleDeleteDrawedPolygon,
  handleUpdateDrawedPolygonsChange,
}) => {
  const onPolygonCreated = (e) => {
    const layer = e.layer;
    if (layer instanceof L.Polygon) {
      const geojson = layer.toGeoJSON();
      const polygonArea = turf.area(geojson);
      const polygonAreaInSquareMeteres = polygonArea;
      const polygonAreaInSquareKilometers = polygonArea / 1000000;
      const polygonAreaInEquit = polygonArea / 10000;

      const polygonData = {
        id: layer._leaflet_id,
        areaInSquareKilometers: polygonAreaInSquareKilometers,
        areaInSquareMeters: polygonAreaInSquareMeteres,
        areaInEquit: polygonAreaInEquit,
        color: "#000000",
        bgColor: "transparent",
      };

      handleActiveDrawedPolygonsChange(polygonData);

      const popupContainer = document.createElement("div");

      ReactDOM.render(
        <div>
          <p>Área do polígono:</p>
          <ul>
            <li>{polygonAreaInSquareKilometers.toFixed(2)} km²</li>
            <li>{polygonAreaInSquareMeteres.toFixed(2)} m²</li>
            <li>{polygonAreaInEquit.toFixed(2)} ha</li>
          </ul>
          <ColorsPreferenceSection
            layer={layer}
            handleUpdateDrawedPolygonsChange={handleUpdateDrawedPolygonsChange}
            idPolygon={layer._leaflet_id}
          />
        </div>,
        popupContainer
      );

      layer
        .bindPopup(popupContainer, {
          className: "custom-popup-mapdrawtools",
        })
        .openPopup();
    }
  };

  const onPolygonsDeleted = (e) => {
    e.layers.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        handleDeleteDrawedPolygon(layer._leaflet_id);
      }
    });
  };

  const onPolygonEdited = (e) => {
    e.layers.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        const geojson = layer.toGeoJSON();

        const polygonArea = turf.area(geojson);
        const polygonAreaInSquareMeteres = polygonArea;
        const polygonAreaInSquareKilometers = polygonArea / 1000000;
        const polygonAreaInEquit = polygonArea / 10000;

        handleUpdateDrawedPolygonsChange(
          {
            areaInSquareKilometers: polygonAreaInSquareKilometers,
            areaInSquareMeters: polygonAreaInSquareMeteres,
            areaInEquit: polygonAreaInEquit,
          },
          layer._leaflet_id
        );

        const popupContainer = document.createElement("div");

        ReactDOM.render(
          <div>
            <p>Área do polígono:</p>
            <ul>
              <li>{polygonAreaInSquareKilometers.toFixed(2)} km²</li>
              <li>{polygonAreaInSquareMeteres.toFixed(2)} m²</li>
              <li>{polygonAreaInEquit.toFixed(2)} ha</li>
            </ul>
            <ColorsPreferenceSection
              layer={layer}
              handleUpdateDrawedPolygonsChange={
                handleUpdateDrawedPolygonsChange
              }
              idPolygon={layer._leaflet_id}
            />
          </div>,
          popupContainer
        );

        layer
          .bindPopup(popupContainer, {
            className: "custom-popup-mapdrawtools",
          })
          .openPopup();
      }
    });
  };

  return (
    <FeatureGroup>
      <EditControl
        position="topleft"
        onCreated={onPolygonCreated}
        onDeleted={onPolygonsDeleted}
        onEdited={onPolygonEdited}
        draw={{
          polyline: {
            icon: new L.DivIcon({
              iconSize: new L.Point(8, 8),
              className: "leaflet-div-icon leaflet-editing-icon",
            }),
            shapeOptions: {
              guidelineDistance: 10,
              color: "navy",
              weight: 3,
            },
          },
          marker: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          polygon: {
            shapeOptions: {
              color: "black",
              weight: 7,
              fillColor: "transparent",
              fillOpacity: 0,
            },
          },
        }}
      />
    </FeatureGroup>
  );
};

const WindRoseControl = () => {
  return (
    <Control position="topleft">
      <div className="leaflet-control-scale leaflet-control">
        <img
          width={60}
          src={require("../../assets/img/control/compass_rose_2.png")}
          alt="Rosa dos Ventos"
        />
      </div>
    </Control>
  );
};

const VitecLogoControl = () => {
  return (
    <Control position="bottomleft">
      <div>
        <img
          style={{ maxWidth: "64px", opacity: 1 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </div>
    </Control>
  );
};

const ColorsPreferenceSection = ({
  handleUpdateDrawedPolygonsChange,
  idPolygon,
  layer,
}) => {
  const [isPolygonColorAccordionOpen, setIsPolygonColorAccordionOpen] =
    React.useState(true);

  const polygonPossibleColors = [
    "#000000",
    "#808080",
    "#0000FF",
    "#00FF00",
    "#D2691E",
    "#A020F0",
    "#fc03d2",
    "#ff0000",
    "#ffff00",
  ];

  const polygonColorsFirstRow = polygonPossibleColors.slice(0, 4);
  const polygonColorsSecondRow = polygonPossibleColors.slice(4);

  const styles = {
    colorPickerButton: {
      borderRadius: "100%",
      width: "0px",
      padding: "11px",
      boxShadow: "none",
      borderColor: "#646464",
    },
    accordionContent: {
      padding: ".5rem 1.5rem",
      borderBottom: "1px solid #f0f1f3",
    },
    sectionContainer: {
      padding: "0 1.3rem",
    },
  };

  return (
    <section className="mt-2 mb-4" style={styles.sectionContainer}>
      <span className="h4" style={{ display: "block" }}>
        Cores:
      </span>
      <Accordion>
        <AccordionItem style={{ padding: "0px" }}>
          <AccordionHeader
            isOpen={isPolygonColorAccordionOpen}
            onClick={() =>
              setIsPolygonColorAccordionOpen((previousValue) => !previousValue)
            }
            style={{ padding: ".8rem" }}
          >
            <div>
              <i className="fas fa-pen"></i>{" "}
              <span className="h4">Polígono</span>
            </div>
          </AccordionHeader>
          <AccordionContent
            isOpen={isPolygonColorAccordionOpen}
            style={styles.accordionContent}
          >
            <div>
              <span
                className="h4"
                style={{ display: "block", textAlign: "center" }}
              >
                Borda do polígono:
              </span>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                  marginBottom: ".3rem",
                }}
              >
                {polygonColorsFirstRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() => {
                        handleUpdateDrawedPolygonsChange(
                          {
                            color,
                          },
                          idPolygon
                        );
                        layer.setStyle({ color });
                      }}
                    ></Button>
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                }}
              >
                {polygonColorsSecondRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() => {
                        handleUpdateDrawedPolygonsChange(
                          {
                            color,
                          },
                          idPolygon
                        );
                        layer.setStyle({ color });
                      }}
                    ></Button>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <span
                className="h4"
                style={{ display: "block", textAlign: "center" }}
              >
                Interior do polígono:
              </span>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                  marginBottom: ".3rem",
                }}
              >
                {polygonColorsFirstRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() => {
                        handleUpdateDrawedPolygonsChange(
                          {
                            bgColor: color,
                          },
                          idPolygon
                        );
                        layer.setStyle({
                          fillColor: color,
                          fillOpacity: 0.5,
                        });
                      }}
                    ></Button>
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  display: "flex",
                  gap: ".1rem",
                  justifyContent: "center",
                }}
              >
                {polygonColorsSecondRow.map((color) => (
                  <li>
                    <Button
                      style={{
                        ...styles.colorPickerButton,
                        backgroundColor: color,
                      }}
                      onClick={() => {
                        handleUpdateDrawedPolygonsChange(
                          {
                            bgColor: color,
                          },
                          idPolygon
                        );
                        layer.setStyle({
                          fillColor: color,
                          fillOpacity: 0.5,
                        });
                      }}
                    ></Button>
                  </li>
                ))}
              </ul>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </section>
  );
};

export {
  MapWrapper as Map,
  MapVisualizationLayersControl,
  MapPolygonsLayersControl,
  MapDrawTools,
  WindRoseControl,
};
