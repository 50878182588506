import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Skeleton from "../Skeleton";

const MapLoadingSkeleton = ({heigth = "32.3rem"}) => {
  return (
    <Card>
      <CardHeader>
        <Skeleton variant="rectangle" height="1rem" width="200px" />
      </CardHeader>
      <CardBody className="pb-0 pt-0 pl-0 pr-0">
        <Skeleton variant="rectangle" height={heigth} width="100%" />
      </CardBody>
    </Card>
  );
};

export default MapLoadingSkeleton;
