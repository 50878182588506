import React from "react";

import {
  Button,
  Col,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";
import useDemandsList from "../../../../hooks/useDemandsList";
import useTeamsList from "../../../../hooks/useTeamsList";
import useEmployeesList from "../../../../hooks/useEmployeesList";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTypeLayersList from "../../../../hooks/useTypeLayersList";
import moment from "moment";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import { getTodayEpidemiologicalWeek } from "../../../../services/utils/todayEpidemiologicalWeek";
import DemandsFilter from "../../../../components/Filters/DemandsFilter";
import UsersFilter from "../../../../components/Filters/UsersFilter";
import TrapTypesFilter from "../../../../components/Filters/TrapTypesFilter";
import TerritorializationsFilter from "../../../../components/Filters/TerritorializationsFilter";
import TypeLayersFilter from "../../../../components/Filters/TypeLayersFilter";
import YearsFilter from "../../../../components/Filters/YearsFilter";
import ManualEpidemiologicWeeksFilter from "../../../../components/Filters/ManualEpidemiologicWeeksFilter";
import { getUserData } from "../../../../services/utils/auth";
import { getEntomologicFieldResultsByFilters } from "../../../../services/api/VigilanciaEntomologica";
import { toast } from "react-toastify";
import LoadingSpin from "react-loading-spin";
import useGraphicsData from "../Hooks/useGraphicsData";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import getWeeks from "../../../../services/utils/epidemiologicalWeek";
import TeamsFilter from "../../../../components/Filters/TeamsFilter";
import { DateFilter } from "../../../../components/Filters/DateFilter";
import { ManualMonthsFilter } from "../../../../components/Filters/ManualMonthsFilter";
import { MonthWeekFilter } from "../../../../components/Filters/MonthWeekFilter";
import { EpidemiologicWeekFilter } from "../../../../components/Filters/EpidemiologicWeekFilter";
import {
  ACTIVE_TRAP_STATUS,
  fetchTrapTypesData,
  getTerritorializationsIdsToSendOnRequest,
  GREEN_TRAP_STATUS,
  INACTIVE_TRAP_STATUS,
  monthsOptionsList,
  RED_TRAP_STATUS,
  YELLOW_TRAP_STATUS,
} from "../../../../services/utils/globalFunctions";
import styles from "./FieldResultsPageFilters.module.css";
import useLoading from "../Hooks/useLoading";
import { fetchFilteredTraps } from "../../../../services/api/Trap";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import useTrapsList from "../../../../hooks/useTrapsList";
import usePositivityIndexMap from "../Hooks/usePositivityIndexMap";
import xlsx from "json-as-xlsx";
import MultipleIndexisGraphicModal from "../../../../components/Graphics/Line/MultipleIndexisGraphicModal";
import usePositivityIndex from "../Hooks/usePositivityIndex";

const DEFAULT_FILTERS = {
  beginDate: "",
  demand: {
    label: "Selecione",
    value: "",
  },
  datePeriodType: "week",
  disease: {
    label: "Dengue",
    value: 1,
  },
  epidemiologicalWeeks: [],
  epidemiologicalWeekBegin: {
    label: "Selecione",
    value: "",
  },
  epidemiologicalWeekEnd: {
    label: "Selecione",
    value: "",
  },
  months: [],
  monthBegin: {
    label: "Selecione",
    value: "",
  },
  monthEnd: {
    label: "Selecione",
    value: "",
  },
  endDate: "",
  filtersTypes: {
    usersIds: 1,
  },
  team: {
    label: "Selecione",
    value: "",
  },
  territorializations: [],
  trapType: {
    label: "Selecione",
    value: "",
  },
  typeLayers: [],
  users: [],
  year: {
    label: "Selecione",
    value: "",
  },
};

const useFieldResulstPageFilters = () => {
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS);
  const { demandsList } = useDemandsList();
  const { employeesList } = useEmployeesList();
  const { setAppliedFilters } = useAppliedFilters();
  const { setFieldResultsList } = useGraphicsData();
  const { teamsList } = useTeamsList();
  const { territorializationsList } = useTerritorializationsList();
  const { trapTypesList } = useTrapTypesList();
  const { typeLayersList } = useTypeLayersList();
  const { isFieldResultsLoading, setIsFieldResultsLoading } = useLoading();
  const { setTrapsList } = useTrapsList();

  const fetchAllComponentsData = async (filters) => {
    if (
      filters.beginDate !== "" &&
      filters.endDate !== "" &&
      moment(filters.beginDate) > moment(filters.endDate)
    ) {
      toast.error("A data inicial deve ser menor que a data final.");

      return;
    }

    if (filters.trapType.value === "") {
      toast.error("Selecione um tipo de armadilha.");

      return;
    }

    if (
      filters.year.value === "" &&
      filters.epidemiologicalWeeks.length === 0 &&
      (filters.beginDate === "" || filters.endDate === "")
    ) {
      toast.error("Selecione um período de datas ou ano/semanas.");
      return;
    }

    if (
      filters.year.value !== "" &&
      filters.datePeriodType === "week" &&
      filters.epidemiologicalWeeks.length === 0
    ) {
      toast.error("Selecione ao menos uma semana epidemiológica.");
      return;
    }

    if (
      filters.year.value !== "" &&
      filters.datePeriodType === "month" &&
      filters.months.length === 0
    ) {
      toast.error("Selecione ao menos um mês.");
      return;
    }

    if (filters.territorializations.length === 0) {
      toast.error("Selecione ao menos uma territorialização.");
      return;
    }

    setIsFieldResultsLoading(true);
    setAppliedFilters(filters);

    const fieldResults = fetchFieldResultsData();
    const trapsList = fetchTrapsListData();

    const [fieldResultsResponse, trapsListResponse] = await Promise.all([
      fieldResults,
      trapsList,
    ]);

    setFieldResultsList(fieldResultsResponse);
    setTrapsList(trapsListResponse);

    setIsFieldResultsLoading(false);
  };

  const fetchFieldResultsData = async () => {
    setFieldResultsList([]);

    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao buscar pelos resultados de campo. Verifique sua conexão com a internet e tente novamente. Caso o problema persista, entre em contato com a nossa equipe.";

    const filtersToSend = {
      demandsIds: [],
      organizationId: getUserData("organizationId"),
      periods: generatePeriodsToSend(filters),
      territorializationsIds: filters.territorializations.map(
        ({ value }) => value
      ),
      trapTypeName: filters.trapType.trapTypeName,
      usersIds: [],
    };

    try {
      const { data, status } = await getEntomologicFieldResultsByFilters(
        filtersToSend
      );

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      return data;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  const fetchTrapsListData = async () => {
    const trapTypes = await fetchTrapTypesData();

    const trapsRangesToSend = trapTypes.map(({ trapTypeName, value }) => {
      const { quantityRange, data } = getTrapRange(trapTypeName);

      return {
        trapTypeId: value,
        quantity: quantityRange,
        begin: data.beggining,
        middle: !data.middle ? null : data.middle,
        end: data.end,
      };
    });

    const organizationId = getUserData("organizationId");
    /*  const usersIdsToSend = getUsersIdsToSendOnRequest(filters); */
    const territorializationsIdsToSend =
      getTerritorializationsIdsToSendOnRequest(filters);

    const filtersToSend = {
      colorStatuses: [GREEN_TRAP_STATUS, YELLOW_TRAP_STATUS, RED_TRAP_STATUS],
      demandsIds: [],
      finalDate: generateFinalDateToSend(filters),
      organizationId,
      propertyType: [],
      projectsIds: [],
      ranges: trapsRangesToSend,
      statuses: [ACTIVE_TRAP_STATUS, INACTIVE_TRAP_STATUS],
      territorializationsIds: territorializationsIdsToSend,
      trapsNumbers: [],
      trapTypesIds: [
        /*  trapTypes.find(({ value }) => value === filters.trapType.value).value, */
      ],
      usersIds: [],
    };

    try {
      const { data, status } = await fetchFilteredTraps(filtersToSend);

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelas armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      return data;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const generateFinalDateToSend = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") {
      const result = moment(filters.endDate)
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString();

      return result;
    }

    if (filters.datePeriodType === "week") {
      const result = moment(
        [...filters.epidemiologicalWeeks].sort((a, b) => {
          const dateA = moment(a.endDate, "DD/MM/YYYY");
          const dateB = moment(b.endDate, "DD/MM/YYYY");

          return dateB - dateA;
        })[0].endDate,
        "DD/MM/YYYY"
      )
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString();

      return result;
    }

    if (filters.datePeriodType === "month") {
      const result = moment({
        year: filters.year.value,
        month: filters.months.sort((a, b) => b.value - a.value)[0].value - 1,
      })
        .endOf("month")
        .subtract(1, "day")
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString();

      return result;
    }
  };

  const generatePeriodsToSend = (filters) => {
    if (filters.beginDate !== "" && filters.endDate !== "") {
      const result = [
        {
          beginDate: moment(filters.beginDate)
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          finalDate: moment(filters.endDate)
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          label: `${moment(filters.beginDate).format("DD/MM/YYYY")} A ${moment(
            filters.endDate
          ).format("DD/MM/YYYY")}`,
        },
      ];

      return result;
    }

    if (filters.datePeriodType === "week") {
      const result = filters.epidemiologicalWeeks.map(
        ({ beginDate, endDate, label }) => ({
          beginDate: moment(beginDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          finalDate: moment(endDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          label,
        })
      );

      return result;
    }

    if (filters.datePeriodType === "month") {
      const result = filters.months.map(({ label, value }) => ({
        beginDate: moment({ year: filters.year.value, month: value - 1 })
          .startOf("month")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString(),
        finalDate: moment({ year: filters.year.value, month: value - 1 })
          .endOf("month")
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString(),
        label,
      }));

      return result;
    }
  };

  const handleFiltersChange = React.useCallback((filterName, newValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };
      newValues[filterName] = newValue;

      return newValues;
    });
  }, []);

  const handleDatePeriodChange = () => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      const datePeriodTypeValue =
        newValues.datePeriodType === "week" ? "month" : "week";

      newValues.datePeriodType = datePeriodTypeValue;

      return newValues;
    });
  };

  const handleYearFilterChange = React.useCallback(
    (filterName, filterValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };
        newValues[filterName] = filterValue;

        newValues.epidemiologicalWeeks = [];
        newValues.epidemiologicalWeekBegin = {
          label: "Selecione",
          value: "",
        };
        newValues.epidemiologicalWeekEnd = {
          label: "Selecione",
          value: "",
        };
        newValues.beginDate = "";
        newValues.endDate = "";

        return newValues;
      });
    },
    []
  );

  const handleEpidemiologicWeeksFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if (
          filterName === "epidemiologicalWeekBegin" &&
          newValue.value === ""
        ) {
          newValues.epidemiologicalWeekBegin = newValue;
          newValues.epidemiologicalWeekEnd = newValue;

          return newValues;
        }

        if (
          filterName === "epidemiologicalWeekEnd" &&
          newValue.value !== "" &&
          newValues.epidemiologicalWeekBegin.value !== ""
        ) {
          const beginWeekNumber = +newValues.epidemiologicalWeekBegin.label;
          const endWeekNumber = +newValue.label;

          if (endWeekNumber < beginWeekNumber) {
            toast.error(
              "A semana epidemiológica final deve ser igual ou maior que a inicial."
            );

            return newValues;
          }

          const epidemiologicalWeeksFromYear = getWeeks().find(
            ({ year }) => year === filters.year.label
          ).weeks;

          const weeksInBetween = epidemiologicalWeeksFromYear
            .slice(beginWeekNumber - 1, endWeekNumber)
            .map(({ endDate, ...rest }) => ({
              ...rest,
              endDate,
              value: endDate,
            }));

          newValues.epidemiologicalWeeks = weeksInBetween;
          newValues[filterName] = newValue;

          return newValues;
        }

        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleMonthFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if (filterName === "monthBegin" && newValue.value === "") {
          newValues.monthBegin = newValue;
          newValues.monthEnd = newValue;

          return newValues;
        }

        if (
          filterName === "monthEnd" &&
          newValue.value !== "" &&
          newValues.monthBegin.value !== ""
        ) {
          const beginMonthNumber = newValues.monthBegin.value;
          const endMonthNumber = newValue.value;

          if (endMonthNumber < beginMonthNumber) {
            toast.error("o mês final deve ser igual ou maior que o inicial.");

            return newValues;
          }

          const monthsInBetween = monthsOptionsList.filter(
            ({ value }) => value >= beginMonthNumber && value <= endMonthNumber
          );

          newValues.months = monthsInBetween;
          newValues[filterName] = newValue;

          return newValues;
        }

        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleTypeLayerChange = React.useCallback((value) => {
    handleFiltersChange(
      "typeLayers",
      !value || value.length === 0 ? [] : value
    );

    if (!value || value.length === 0)
      handleFiltersChange("territorializations", []);
    else {
      const allTerritorializationsToSet = [];

      value.forEach(({ territorializations }) => {
        allTerritorializationsToSet.push(...territorializations);
      });

      handleFiltersChange("territorializations", allTerritorializationsToSet);
    }
  }, []);

  const handleFiltersTypesChange = (filterTypeName) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };

      newValues.filtersTypes[filterTypeName] =
        newValues.filtersTypes[filterTypeName] === 1 ? 2 : 1;

      return newValues;
    });
  };

  const clearFilters = () => {
    setFilters(DEFAULT_FILTERS);
  };

  return {
    clearFilters,
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleDatePeriodChange,
    handleEpidemiologicWeeksFilterChange,
    handleFiltersChange,
    handleFiltersTypesChange,
    handleMonthFilterChange,
    handleTypeLayerChange,
    handleYearFilterChange,
    isFieldResultsLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  };
};

const usePopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const togglePopover = () =>
    setIsPopoverOpen((previousValue) => !previousValue);

  return {
    isPopoverOpen,
    togglePopover,
  };
};

const useDownloadRawData = () => {
  const { positivityIndexGroupPointsList } = usePositivityIndexMap();

  const handleDownloadRawData = () => {
    if (
      !positivityIndexGroupPointsList ||
      Object.keys(positivityIndexGroupPointsList).length === 0
    )
      return;

    const result = [];

    positivityIndexGroupPointsList.aedesAegyptiPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            result.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Adultos",
            });
          });
        });
      }
    );

    positivityIndexGroupPointsList.eggPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            result.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Ovos",
            });
          });
        });
      }
    );

    positivityIndexGroupPointsList.exuviaPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            result.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Exúvia",
            });
          });
        });
      }
    );

    positivityIndexGroupPointsList.larvaOrPupaPointsEstimatesByWeek.forEach(
      ({ label, pointsGroups }) => {
        pointsGroups.forEach(({ name: pointsGroupName, points }) => {
          points.forEach((point) => {
            result.push({
              ...point,
              pointsGroupName,
              label,
              positiveFor: "Larva ou Pupa",
            });
          });
        });
      }
    );

    const xlsxFileSettings = {
      fileName: "Resultados_de_campo_VITEC",
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false,
    };

    xlsx(
      [
        {
          sheet: "Resultados de campo",
          columns: [
            { label: "Tipo da armadilha", value: "trapTypeName" },
            { label: "Número da armadilha", value: "number" },
            { label: "Tipo de imóvel", value: "propertyType" },
            { label: "Nome da área", value: "territorializationName" },
            { label: "Endereço", value: "address" },
            {
              label: "Responsável pela visita",
              value: "userResponsableForVisitName",
            },
            { label: "Positiva para", value: "positiveFor" },
            { label: "Estimativa", value: "estimate" },
            {
              label: "Data da visita que positivou a armadilha",
              value: "visitDate",
            },
            { label: "Latitude", value: "latitude" },
            { label: "Longitude", value: "longitude" },
          ],
          content: result,
        },
      ],
      xlsxFileSettings
    );
  };

  return {
    handleDownloadRawData,
  };
};

export const FieldResultsFilters = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { isPopoverOpen, togglePopover } = usePopover();
  const { handleDownloadRawData } = useDownloadRawData();
  const { positivityIndexData } = usePositivityIndex();
  const { appliedFilters } = useAppliedFilters();

  const toggleModal = () => setIsModalOpen((previousValue) => !previousValue);

  return (
    <>
      <MultipleIndexisGraphicModal
        appliedFilters={appliedFilters}
        isOpen={isModalOpen}
        toggle={toggleModal}
        data={positivityIndexData}
        hideDownloadButton={true}
      />
      <div className={styles.container}>
        <Button
          className={styles.actionsButton}
          id="mainFiltersPopover"
          type="button"
          onClick={togglePopover}
        >
          <i className="fa fa-filter"></i> Filtros
        </Button>
        <FieldResultsFiltersPopover
          offset="-120, 8"
          placement="bottom"
          target="mainFiltersPopover"
          isOpen={isPopoverOpen}
          toggle={togglePopover}
        />
        <Button style={{ width: "230px" }} onClick={() => toggleModal()}>
          Visualizar todos os índices
        </Button>
        <Button
          className={styles.downloadButton}
          onClick={() => handleDownloadRawData()}
        >
          <i>
            <img
              src={require("../../../../assets/img/icons/interface/download.png")}
              className={styles.downloadButtonIcon}
              alt="icone"
            />
          </i>{" "}
          Baixar dados brutos
        </Button>
      </div>
    </>
  );
};

const FieldResultsFiltersPopover = ({
  target,
  offset,
  placement,
  isOpen,
  toggle,
  ...props
}) => {
  const {
    clearFilters,
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleDatePeriodChange,
    handleEpidemiologicWeeksFilterChange,
    handleFiltersChange,
    /* handleFiltersTypesChange, */
    handleMonthFilterChange,
    handleTypeLayerChange,
    handleYearFilterChange,
    isFieldResultsLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  } = useFieldResulstPageFilters();

  return (
    <Popover
      style={{ width: "500px" }}
      placement={placement}
      target={target}
      offset={offset}
      isOpen={isOpen}
      toggle={toggle}
      id="filtersPopover"
      {...props}
    >
      <PopoverHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Filtros
          <span style={{ fontSize: "12px", fontWeight: 400 }}>
            Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
          </span>
          <i
            className="fa fa-times"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </PopoverHeader>
      <PopoverBody style={{ backgroundColor: "#ffffff" }}>
        <span
          style={{
            display: "block",
            textAlign: "right",
            fontSize: "11px",
          }}
          className={styles.label}
        >
          ( <span style={{ color: "red" }}>*</span> ) : Campo obrigatório
        </span>
        <Row className="mb-2">
          <Col>
            <label htmlFor="beginDate" className={styles.label}>
              De (<span style={{ color: "red" }}>*</span>) :
            </label>
            <DateFilter
              filterName="beginDate"
              handleFiltersChange={handleFiltersChange}
              id="beginDate"
              value={filters.beginDate}
              disabled={filters.year.value !== ""}
            />
          </Col>
          <Col>
            <label htmlFor="endDate" className={styles.label}>
              Até (<span style={{ color: "red" }}>*</span>) :
            </label>
            <DateFilter
              filterName="endDate"
              handleFiltersChange={handleFiltersChange}
              id="endDate"
              value={filters.endDate}
              disabled={filters.year.value !== ""}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Ano (<span style={{ color: "red" }}>*</span>) :
            </label>
            <YearsFilter
              filterName="year"
              handleFiltersChange={handleYearFilterChange}
              value={filters.year}
              isDisabled={filters.beginDate !== "" || filters.endDate !== ""}
            />
          </Col>
          <Col>
            {filters.datePeriodType === "week" ? (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Semana</span>
                  inicio:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <EpidemiologicWeekFilter
                  filterName="epidemiologicalWeekBegin"
                  handleFiltersChange={handleEpidemiologicWeeksFilterChange}
                  value={filters.epidemiologicalWeekBegin}
                  year={String(filters.year.value)}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            ) : (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Mês</span>
                  inicio:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <MonthWeekFilter
                  filterName="monthBegin"
                  handleFiltersChange={handleMonthFilterChange}
                  value={filters.monthBegin}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            )}
          </Col>
          <Col>
            {filters.datePeriodType === "week" ? (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Semana</span>
                  fim:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <EpidemiologicWeekFilter
                  filterName="epidemiologicalWeekEnd"
                  handleFiltersChange={handleEpidemiologicWeeksFilterChange}
                  value={filters.epidemiologicalWeekEnd}
                  year={String(filters.year.value)}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            ) : (
              <div>
                <label htmlFor="" className={styles.label}>
                  <span style={{ color: "#3b6bca" }}>Mês</span>
                  fim:{" "}
                  <i onClick={handleDatePeriodChange}>
                    <ExchangeIcon />
                  </i>
                </label>
                <MonthWeekFilter
                  filterName="monthEnd"
                  handleFiltersChange={handleMonthFilterChange}
                  value={filters.monthEnd}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {filters.datePeriodType === "week" ? (
              <div>
                <label htmlFor="" className={styles.label}>
                  Semanas (manual):
                </label>
                <ManualEpidemiologicWeeksFilter
                  filterName="epidemiologicalWeeks"
                  handleFiltersChange={handleFiltersChange}
                  value={filters.epidemiologicalWeeks}
                  year={filters.year.label}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            ) : (
              <div>
                <label htmlFor="" className={styles.label}>
                  Meses (manual):
                </label>
                <ManualMonthsFilter
                  filterName="months"
                  handleFiltersChange={handleFiltersChange}
                  value={filters.months}
                  isDisabled={filters.year.value === ""}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Demanda:
            </label>
            <DemandsFilter
              filterName="demand"
              handleFiltersChange={handleFiltersChange}
              options={demandsList}
              value={filters.demand}
              isDisabled={demandsList.length === 0}
            />
          </Col>
          <Col>
            <label htmlFor="" className={styles.label}>
              Tipo de armadilha (<span style={{ color: "red" }}>*</span>) :
            </label>
            <TrapTypesFilter
              filterName="trapType"
              handleFiltersChange={handleFiltersChange}
              options={trapTypesList}
              value={filters.trapType}
              isDisabled={trapTypesList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Usuários:{" "}
              {/* (
              {filters.filtersTypes.usersIds === 1 ? (
                <div>
                  <span
                    data-tip
                    data-for="inclusive1"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Inclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="inclusive1"
                  >
                    Filtra os resultados pelos usuários selecionados.
                  </ReactTooltip>
                </div>
              ) : (
                <div>
                  <span
                    data-tip
                    data-for="exclusive1"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Exclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="exclusive1"
                  >
                    Filtra os resultados exceto dos usuários selecionados.
                  </ReactTooltip>
                </div>
              )}
              ){" "}
              <i onClick={() => handleFiltersTypesChange("usersIds")}>
                <ExchangeIcon />
              </i> */}
            </label>
            <UsersFilter
              filterName="users"
              handleFiltersChange={handleFiltersChange}
              options={employeesList}
              value={filters.users}
              isDisabled={employeesList.length === 0}
            />
          </Col>
          <Col>
            <label htmlFor="" className={styles.label}>
              Equipe:{" "}
              {/* (
              {filters.filtersTypes.usersIds === 1 ? (
                <div>
                  <span
                    data-tip
                    data-for="inclusive2"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Inclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="inclusive2"
                  >
                    Filtra os resultados pela equipe selecionada.
                  </ReactTooltip>
                </div>
              ) : (
                <div>
                  <span
                    data-tip
                    data-for="exclusive2"
                    style={{ color: "#3b6bca", textDecoration: "underline" }}
                  >
                    Exclusivo
                  </span>
                  <ReactTooltip
                    place="top"
                    effect="solid"
                    type="info"
                    id="exclusive2"
                  >
                    Filtra os resultados exceto da equipe selecionada.
                  </ReactTooltip>
                </div>
              )}
              ){" "}
              <i onClick={() => handleFiltersTypesChange("usersIds")}>
                <ExchangeIcon />
              </i> */}
            </label>
            <TeamsFilter
              filterName="team"
              handleFiltersChange={handleFiltersChange}
              options={teamsList}
              value={filters.team}
              isDisabled={teamsList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label htmlFor="" className={styles.label}>
              Delimitador de área:
            </label>
            <TypeLayersFilter
              handleTypeLayerChange={handleTypeLayerChange}
              typeLayersList={typeLayersList}
              value={filters.typeLayers}
              isDisabled={typeLayersList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <label htmlFor="" className={styles.label}>
              Territorializações (<span style={{ color: "red" }}>*</span>) :
            </label>
            <TerritorializationsFilter
              handleFiltersChange={handleFiltersChange}
              territorializationsList={territorializationsList}
              value={filters.territorializations}
              isDisabled={territorializationsList.length === 0}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {isFieldResultsLoading ? (
              <Button disabled={true} color="primary">
                <LoadingSpin color="#fff" size={17} />
              </Button>
            ) : (
              <Button onClick={() => fetchAllComponentsData(filters)} color="primary">
                Aplicar filtros
              </Button>
            )}
          </Col>
          <Col>
            <Button onClick={clearFilters}>Remover filtros</Button>
          </Col>
        </Row>
      </PopoverBody>
    </Popover>
  );
};

const ExchangeIcon = () => {
  return (
    <img
      src={require("../../../../assets/img/icons/interface/exchange-black.png")}
      style={{
        width: "12px",
        height: "12px",
        cursor: "pointer",
        marginLeft: "5px",
      }}
      alt="icone"
    />
  );
};
