import request from "../utils/request";
//import request from "../utils/requestTests/requestTestOrganization";

export function fetchOrganizations() {
  return request({
    url: "/Organizations",
    method: "get",
  });
}

export function fetchOrganization(id) {
  return request({
    url: `/Organizations/${id}`,
    method: "get",
  });
}

export function fetchConfigurations(organizationId) {
  return request({
    url: `/Pgs/${organizationId}`,
    method: "GET",
  });
}

export function updateConfigurations(configurations) {
  return request({
    url: "/Pgs/Configuration",
    method: "PUT",
    data: configurations,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function insertConfigurations(configurations) {
  return request({
    url: "/Pgs/Configuration",
    method: "POST",
    data: configurations,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}
