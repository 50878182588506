import React from "react";
import Header from "components/Headers/Header.jsx";
import FiltroNovaTerritorializacao from "../Navbars/FiltroNovaTerritorializacao";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import {
    Map,
    TileLayer,
    FeatureGroup,
    LayersControl,
    withLeaflet,
    Polygon,
    Marker,
    Popup
  } from'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import PrintControlDefault  from 'react-leaflet-easyprint';
import 'react-leaflet-fullscreen/dist/styles.css'
import L from 'leaflet';
import { EditControl } from "react-leaflet-draw"
import { getAllTypeTerritorializations, getTypeLayer, postTerritorialization } from "../../services/api/territorialization";
import { toast, ToastContainer } from "react-toastify";
import RecoverTerritorializationModal from './RecoverTerritorializationModal.jsx';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { getActiveTrapsToMap, getTrapsTypes } from "../../services/api/Trap.js";
import { ARMADILHA_INFO } from "../../constants/ArmadilhaConstant.js";
import moment from "moment";
import { getUserData } from "../../services/utils/auth.js";
import { fetchOrganization } from "../../services/api/Organization.js";



  const { BaseLayer } = LayersControl;
  const PrintControl = withLeaflet(PrintControlDefault);
  const key = 'AIzaSyCdkHjfiiztTeKSSuvfauQERlAJGFONeKo';
class Mapa extends React.Component {
    state = {
        Center: null,
        camada: "Imóvel",
        tipoImovel: "",
        cep: "",
        numero: "",
        economia: "",
        rua: "",
        nome: "",
        sequencial:"",
        complemento:"",
        GeoJson: "",
        ActualLat: "",
        ActualLong: "",
        unsavedTerritorialization:false,
        baseTerritorialization:null,
        CityId: "00000000-0000-0000-0000-000000000000",
        CountryId: "00000000-0000-0000-0000-000000000000",
        NeighbourhoodId: "00000000-0000-0000-0000-000000000000",
        StateId:"00000000-0000-0000-0000-000000000000",
        BaseCoordinates:[],
        modal:false,
        reactFGref:null,
        Loading:false,
        activeTrapsToMap: null,
    }

    getTrapIcon = () => {
        const trapIcon = L.icon({
            iconUrl: "https://i.ibb.co/q09c9H7/g-int2care.png",
            iconSize: [20, 20],
        });

        return trapIcon;
    }

    SaveData = () => {
        //tratando o geoJson armazenado para que possa ser enviado
        //junto com o state
        var geoStorage = localStorage.getItem('geoJson');
        this.setState({GeoJson : geoStorage}, () => {
            localStorage.removeItem('geoJson');
            //finalmente post para salvar
            var postSave = postTerritorialization(this.state);
            postSave.then(response => {          
                if(response.data.id) {                    
                    toast.success("Territorialização cadastrada com sucesso!");
                }
            });
        });      
    }

    onChangeLocationId(e){
        let {name,value} = e;
        this.setState({[name]:value});
    }

    onChangeCamada(camadaNova, drawType) {
        localStorage.setItem('drawtype',drawType);
        this.setState({camada : camadaNova});
    }

    onChangeCep(cepNovo) {
        this.setState({cep : cepNovo});
    }

    onChangeNumero(numeroNovo) {
        this.setState({numero : numeroNovo});
    }

    onChangeRua(ruaNova) {
        this.setState({rua : ruaNova});
    }

    onChangeNome(nomeNova) {
        this.setState({nome : nomeNova});
    }

    onChangeComplemento(complementoNova) {
        this.setState({complemento : complementoNova});
    }

    onChangeSequencial(sequencialNova) {
        this.setState({sequencial : sequencialNova});
    }

    setBaseTerritorialization(territorialization){
        this.setState({baseTerritorialization:territorialization});
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.baseTerritorialization !== this.state.baseTerritorialization){
            this.renderMap();
        }
    }

    renderUpdateTerritorialization = ()=>{
        if(this.props.location.data.editTerritorialization!=null){
            
            var geoJson = JSON.parse(this.props.location.data.editTerritorialization.geo);
            let leafletGeoJSON = new L.GeoJSON(geoJson);
            let leafletFG = this.state.reactFGref.leafletElement;
            leafletGeoJSON.eachLayer( layer =>leafletFG.addLayer(layer));
        }
    }

    fetchTrapTypes = async () => {
        try {
          const {data, status} = await getTrapsTypes();
          if(status !== 200)
            throw new Error("Ocorreu algum erro ao buscar pelos tipos de armadilhas");
          
          const trapTypesFormated = data.map(({ id, name }) => {
            return {
              trapTypeId: id,
              trapTypeName: name,
              trapTypeSurname: name ? ARMADILHA_INFO[name].apelido : "Not found",
            }
          });
    
          return trapTypesFormated;
    
        } catch (error) {
          toast.error(error);
        }
    }

    fetchActiveTraps = async () => {
        const trapTypes = await this.fetchTrapTypes();
    
        const fiveDaysBehindDate = moment().subtract(5, "days").format("YYYY-MM-DD");
        const oneDayFowardDate = moment().add(1, "day").format("YYYY-MM-DD");
    
        const activeTrapsFilterPeriod = {
          beginDate: fiveDaysBehindDate,
          finalDate: oneDayFowardDate,
        }
    
        const activeTrapsPromises = trapTypes.map(async ({trapTypeId, trapTypeName, trapTypeSurname}) => {
          const activeTrapsFilter = {
            organizationId: getUserData("organizationId"),
            period: activeTrapsFilterPeriod,
            trapTypeId: trapTypeId,
            usersIds: [],
            demandId: "00000000-0000-0000-0000-000000000000",
            territorializations: [],
          }
    
          try {
            const {data, status} = await getActiveTrapsToMap(activeTrapsFilter);
            if(status !== 200) 
              throw new Error(`Ocorreu um erro ao buscar pelas armadilhas ativas do tipo ${trapTypeSurname}.`);
    
            return {trapTypeName, data: data.actives}
    
          } catch (error) {
            toast.error(error);
          }
        });
    
        const activeTraps = await Promise.all(activeTrapsPromises);

        this.setState({ activeTrapsToMap: activeTraps })

    }

    fetchOrganizationData = async () => {
        const organizationId = getUserData("organizationId");

        return await fetchOrganization(organizationId);
    }

    componentDidMount() {
        this.fetchOrganizationData().then(responseOrg => {
            const {data} = responseOrg;

            if(data.stateSub && data.stateSub.includes(",")) {
                const latLngArray = data.stateSub.split(", ");

                const latitude = latLngArray[0];
                const longitude = latLngArray[1];

                this.setState({Center: [latitude, longitude]});                
            } else {
                var geoStorage = localStorage.getItem('geoJson');
                if(geoStorage!=null){
                    this.setState({modal:true});
                }

                let latlng = this.state.latlng
                if("geolocation" in navigator){
                    navigator.geolocation.getCurrentPosition((position) => {
                        latlng = [position.coords.latitude, position.coords.longitude];    
                        this.setState({Center:latlng});
                    }, (error) => {
                        latlng = [-22.848046, -43.321953];    
                        this.setState({Center:latlng});    
                    })
                }else{
                    latlng = [-22.848046, -43.321953];    
                    this.setState({Center:latlng});
                }
            }
        });

        const typeLayersRequest = getAllTypeTerritorializations();
        typeLayersRequest.then((response) => {
            localStorage.setItem('drawtype', response.data[0].typeGeometry);
            this.setState({camada: response.data[0].id});

        })

        this.renderMap(()=>{
            this.renderUpdateTerritorialization();
        });

        

        this.setState({Loading:true});

        this.fetchActiveTraps();
    }
    
    toggle  (){
        this.setState({modal:!this.state.modal});
    }

    hasUnsavedTerritorialization =(has)=>{
        this.toggle();
        this.setState({unsavedTerritorialization:has});
        if(!has){
            localStorage.removeItem('geoJson');
        }
    }

    renderMap = async () => {
        if(this.state.baseTerritorialization){
            
            let baseCoordinates = [];
            for(const territorialization of this.state.baseTerritorialization){
                let coordinates = [];
                const TypeLayer = await getTypeLayer(territorialization.typeLayerId);
                
                for(const coordinate of territorialization.coordinates){
                    coordinates.push([coordinate.y, coordinate.x]);
                }

                    baseCoordinates.push(coordinates);
                
            }
                
            this.setState({BaseCoordinates:baseCoordinates});
        }
    }
 
    layerToGeoJson(layer, type) {
        // estruturando o geojson
        var geojson = {};
        geojson['type'] = 'FeatureCollection';

        var geometry = {};
        if (type === 'polygon' || type === 'rectangle') {
            geometry['type'] = "Polygon";

            // Pega as coordenadas do Layer desenhado
            var coordinates = [];
            var latlngs = layer._latlngs[0];
            for (var i = 0; i < latlngs.length; i++) {
                coordinates.push([latlngs[i].lng, latlngs[i].lat]);
            }
                // O Geojson obrigatoriamente precisa terminar com o mesmo ponto de partida
            var first_coord = coordinates[0];
            coordinates[coordinates.length] = first_coord;
            geometry['coordinates'] = [coordinates];
        }else if(type === 'marker'){
            geometry['type'] = "Point";
            // Pega as coordenadas do Layer desenhado
            var coordinates = [];
            var latlngs = layer._latlng;
            coordinates.push(latlngs.lng, latlngs.lat);
            // O Geojson obrigatoriamente precisa terminar com o mesmo ponto de partida
            var first_coord = coordinates[0];
            geometry['coordinates'] = coordinates;
        }
        

        //montando a feature
        geojson['features'] = [];

        var Feature = {
            "type": "Feature",
            "geometry": geometry,
            "properties":null
        };

        geojson['features'][0] = Feature;

        return geojson;
    }
    _onEdited = (e) => {
        var type = "";
        if(localStorage.getItem('drawtype') =="Polygon"){
            type = "polygon";
        }else{
            type = "marker";
        }
        e.layers.eachLayer( (layer) => {
            let geojson = this.layerToGeoJson(layer,type);
            localStorage.removeItem('geoJson');
            localStorage.setItem('geoJson', JSON.stringify(geojson));
            layer.editing.disable();
        });
        
      }

    _onCreated = (e) => {
        var type = e.layerType,
            layer = e.layer;
        this.setState({FeatureId:layer._leaflet_id})
        let geojson = this.layerToGeoJson(layer, type);

        localStorage.setItem('geoJson', JSON.stringify(geojson));
    }
    _onDeleted = (e) => {
        localStorage.removeItem('geoJson');
    }
    _onFeatureGroupReady = (ref) => {
        
        if(ref===null || (!this.state.unsavedTerritorialization )) {
            return;
        }
        this._editableFG = ref; 
        let leafletFG = this._editableFG.leafletElement;
        var geoStorage = localStorage.getItem('geoJson');

        //removendo os layers para depois adicionar e não ficar duplicado no mapa
        leafletFG.eachLayer((layer) => {
            leafletFG.removeLayer(layer)
        })

        // populate the leaflet FeatureGroup with the geoJson layers
        if(geoStorage!=null) {
            var geoJson = JSON.parse(geoStorage);
            let leafletGeoJSON = new L.GeoJSON(geoJson);
            leafletGeoJSON.eachLayer( layer =>leafletFG.addLayer(layer));
        }        
    }
    render() {
        return (
            <>
                <Header />

                <ToastContainer />
                <RecoverTerritorializationModal toggleCallback={()=>this.toggle()} 
                unsavedCallback={this.hasUnsavedTerritorialization} 
                OpenModal={this.state.modal}/>
                <FiltroNovaTerritorializacao
                    SetBaseTerritorialization={this.setBaseTerritorialization.bind(this)}
                    Save={this.SaveData}
                    changeNome={this.onChangeNome.bind(this)}
                    changeRua={this.onChangeRua.bind(this)} 
                    changeNumero={this.onChangeNumero.bind(this)}
                    changeCep={this.onChangeCep.bind(this)}
                    changeCamada={this.onChangeCamada.bind(this)}
                    changeComplemento={this.onChangeComplemento.bind(this)}
                    changeSequencial={this.onChangeSequencial.bind(this)}
                    changeIds={this.onChangeLocationId.bind(this)}
                    isEdit={true}
                />

                <div id="mapid">
                    {this.state.Center != null ? 
                        <Map center={this.state.Center} zoom={13}>
                            <FullscreenControl 
                                position="topleft"
                                title="Tela Cheia"
                                titleCancel="Sair da Tela Cheia"
                            />
                            <LayersControl position="topright">
                                <BaseLayer checked name="Normal">
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </BaseLayer>
                                <BaseLayer name="Preto e Branco">
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                    />
                                </BaseLayer>
                                <BaseLayer name="Satélite">
                                    <ReactLeafletGoogleLayer googleMapsLoaderConf={{KEY: key}} type={'hybrid'} />
                                </BaseLayer>
                            </LayersControl>
                            <LayersControl position="topright">
                                {this.state.activeTrapsToMap &&
                                    this.state.activeTrapsToMap.map(({trapTypeName, data}) => {
                                        const trapSurname = ARMADILHA_INFO[trapTypeName].apelido

                                        return (
                                            <LayersControl.Overlay name={trapSurname}>
                                                <MarkerClusterGroup>
                                                    {data && data.map(({ latitude, longitude, number }) => {
                                                        return (
                                                            <Marker
                                                                key={number}
                                                                icon={this.getTrapIcon()}
                                                                position={[latitude, longitude]}
                                                            />
                                                        )
                                                    })}
                                                </MarkerClusterGroup>
                                            </LayersControl.Overlay>
                                        )
                                    })
                                }
                            </LayersControl>
                            <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />
                            <FeatureGroup ref={ (reactFGref) => {this._onFeatureGroupReady(reactFGref)}}>
                                <EditControl
                                    position='topright'
                                    onEdited={this._onEdited}
                                    onCreated={this._onCreated}
                                    onDeleted={this._onDeleted}
                                    draw={{
                                        rectangle: localStorage.getItem('drawtype')=="Polygon"?true:false,
                                        circle:false,
                                        circlemarker:false,
                                        polyline:false,
                                        marker:localStorage.getItem('drawtype')=="Point"?true:false,
                                        polygon: localStorage.getItem('drawtype')=="Polygon"?true:false
                                    }}
                                /> 
                            </FeatureGroup>
                            {this.state.BaseCoordinates.map((baseCoordinates)=>{
                                if(baseCoordinates.length == 1){
                                    return (
                                        <Marker position={baseCoordinates[0]}/>
                                    )
                                }
                                return(
                                    <Polygon color="black" positions={baseCoordinates} />
                                )
                            })}
                            
                        </Map>
                    : null}
                </div>
            </>
        );
    };
}

export default Mapa;