import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Leaflet from "leaflet";
import { GOOGLE_MAPS_API_KEY } from "../../../../constants/WebFormConstants";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { toast } from "react-toastify";
import { updateVigilanciaEntomologica } from "../../../../services/api/VigilanciaEntomologica";

const EditVisitModal = ({ isOpen, selectedVisit, setVisitsList, toggle }) => {
  const [visitsData, setVisitsData] = React.useState({
    situation: { label: "Selecione", value: "" },
    propertyLocal: { label: "Selecione", value: "" },
    propertyType: { label: "Selecione", value: "" },
    publicArea: "",
    number: "",
    complement: "",
    block: "",
    activityDate: "",
    initialTime: "",
    finalTime: "",
    latitude: 0,
    longitude: 0,
    residentEmail: "",
    residentName: "",
    residentPhone: "",
  });

  React.useEffect(() => {
    if (!selectedVisit) return;

    setVisitsData({
      situation: situationsList.find(
        ({ value }) => value === selectedVisit.visitSituation
      ),
      propertyLocal: propertyLocalOptions.find(
        ({ value }) => value === selectedVisit.fullAddress.localDoImovel
      ),
      propertyType: propertyTypeOptions.find(
        ({ value }) => value === selectedVisit.fullAddress.tipoImovel
      ),
      publicArea: selectedVisit.fullAddress.logradouro,
      number: selectedVisit.fullAddress.numero,
      complement: selectedVisit.fullAddress.complemento,
      block: selectedVisit.fullAddress.quadra,
      latitude: selectedVisit.latitude,
      longitude: selectedVisit.longitude,
      activityDate: new Date(selectedVisit.originalVisitObject.activityDate).toISOString().slice(0, 16),
      initialTime: new Date(selectedVisit.originalVisitObject.initialTime).toISOString().slice(0, 16),
      finalTime: new Date(selectedVisit.originalVisitObject.finalTime).toISOString().slice(0, 16),
      residentEmail: selectedVisit.residentEmail,
      residentName: selectedVisit.residentName,
      residentPhone: selectedVisit.residentPhone,
    });
  }, [selectedVisit]);

  const handleUpdateVisit = async () => {
    if (!selectedVisit) return;

    if (
      visitsData.situation.value === "" ||
      !visitsData.propertyLocal ||visitsData.propertyLocal.value === "" ||
      !visitsData.propertyType || visitsData.propertyType.value === "" ||
      visitsData.publicArea === "" ||
      visitsData.number === "" ||
      visitsData.activityDate === ""
    ) {
      toast.error(
        "É necessário preencher os campos Situação da Visita, Local do Imóvel, Tipo do Imóvel, Logradouro e Número para prosseguir com a atualização."
      );

      return;
    }

    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao atualizar os dados da visita e algumas informações podem não ter sido salvas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe.";

    selectedVisit.originalVisitObject.activityDate =
      visitsData.activityDate;
    selectedVisit.originalVisitObject.activityDate =
      visitsData.initialTime;
    selectedVisit.originalVisitObject.activityDate =
      visitsData.finalTime;
    selectedVisit.originalVisitObject.visitSituation =
      visitsData.situation.value;
    selectedVisit.originalVisitObject.address.localDoImovel =
      visitsData.propertyLocal.value;
    selectedVisit.originalVisitObject.address.tipoImovel =
      visitsData.propertyType.value;
    selectedVisit.originalVisitObject.address.logradouro =
      visitsData.publicArea;
    selectedVisit.originalVisitObject.address.numero = visitsData.number;
    selectedVisit.originalVisitObject.address.complemento =
      visitsData.complement;
    selectedVisit.originalVisitObject.address.quadra = visitsData.block;
    selectedVisit.originalVisitObject.latitude =
      +visitsData.latitude.toFixed(7);
    selectedVisit.originalVisitObject.longitude =
      +visitsData.longitude.toFixed(7);
    selectedVisit.originalVisitObject.residents.dadosPrincipais.email =
      visitsData.residentEmail;
    selectedVisit.originalVisitObject.residents.dadosPrincipais.name =
      visitsData.residentName;
    selectedVisit.originalVisitObject.residents.dadosPrincipais.phone =
      visitsData.residentPhone;

    try {
      const { status } = await updateVigilanciaEntomologica(
        selectedVisit.originalVisitObject
      );

      if (status !== 200) throw new Error(DEFAULT_ERROR_MESSAGE);

      toast.success("Dados da visita atualizados com sucesso!");

      setVisitsList((previousValues) => {
        const newValues = [...previousValues];
        const visitIndex = newValues.findIndex(
          (visit) =>
            visit.vigilanciaId ===
            selectedVisit.originalVisitObject.vigilanciaId
        );

        if (visitIndex === -1) return newValues;

        newValues[visitIndex] = selectedVisit.originalVisitObject;

        return newValues;
      });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error.message);
      }
    }
  };

  const handleFormChange = (inputName, newValue) => {
    setVisitsData((previousValues) => {
      const newFormValues = { ...previousValues };
      newFormValues[inputName] = newValue;

      return newFormValues;
    });
  };

  const handleMarkerOnDragEnd = (event) => {
    const { lat, lng } = event.target.getLatLng();

    handleFormChange("latitude", lat);
    handleFormChange("longitude", lng);
  };

  const propertyLocalOptions = [
    { label: "Selecione", value: "" },
    { label: "Alameda", value: "Alameda" },
    { label: "Avenida", value: "Avenida" },
    { label: "Autoestrada", value: "Autoestrada" },
    { label: "Beco", value: "Beco" },
    { label: "Boulevard", value: "Boulevard" },
    { label: "Calçadão", value: "Calçadão" },
    { label: "Corredor", value: "Corredor" },
    { label: "Estrada", value: "Estrada" },
    { label: "Largo", value: "Largo" },
    { label: "Marginal", value: "Marginal" },
    { label: "Picada", value: "Picada" },
    { label: "Rua", value: "Rua" },
    { label: "Trilha", value: "Trilha" },
    { label: "Travessa", value: "Travessa" },
    { label: "Via", value: "Via" },
    { label: "Viela", value: "Viela" },
  ];

  const propertyTypeOptions = [
    { label: "Selecione", value: "" },
    { label: "Comércio", value: "Comércio" },
    { label: "Imóvel Especial", value: "Imóvel Especial" },
    { label: "Outros", value: "Outros" },
    { label: "Ponto Estratégico", value: "Ponto Estratégico" },
    { label: "Próprio Público", value: "Próprio Público" },
    { label: "Residência", value: "Residência" },
    { label: "Terreno Baldio", value: "Terreno Baldio" },
    { label: "Unidade de Saúde", value: "Unidade de Saúde" },
    { label: "Via Pública", value: "Via Pública" },
    { label: "Escola", value: "Escola" },
    { label: "Condomínios", value: "Condomínios" },
    { label: "Clube", value: "Clube" },
  ];

  const situationsList = [
    { label: "Selecione", value: "" },
    { label: "Aberto", value: "Aberto" },
    { label: "Endereço Não Encontrado", value: "Endereço Não Encontrado" },
    { label: "Fechado", value: "Fechado" },
    { label: "Orientação", value: "Orientação" },
    { label: "Recuperação", value: "Recuperação" },
    { label: "Recusa", value: "Recusa" },
  ];

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <Modal
      size="lg"
      fade
      isOpen={isOpen}
      toggle={() => toggle((previousValue) => !previousValue)}
    >
      <ModalHeader>
        <span>Editar visita</span>
      </ModalHeader>
      <ModalBody>
        {isOpen && selectedVisit ? (
          <>
            <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
              <Col className="mb-12 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader>
                    <CardText
                      className="mb-0 mt-0"
                      style={style.cardHeaderText}
                    >
                      <span>
                        <span>Local da Visita</span>
                      </span>
                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ fontWeight: "normal", fontSize: "12px" }}
                        >
                          Clique e arraste o ícone ao centro para mudar a
                          posição da visita
                        </span>
                      </span>
                    </CardText>
                  </CardHeader>
                  <CardBody style={style.cardBody}>
                    <Map
                      center={[visitsData.latitude, visitsData.longitude]}
                      zoom={16}
                    >
                      <LayersControl>
                        <LayersControl.BaseLayer checked name="Normal">
                          <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Satélite">
                          <ReactLeafletGoogleLayer
                            googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_API_KEY }}
                            type={"hybrid"}
                          />
                        </LayersControl.BaseLayer>
                      </LayersControl>
                      <Marker
                        draggable={true}
                        position={[visitsData.latitude, visitsData.longitude]}
                        icon={Leaflet.icon({
                          iconUrl: require("../../../../assets/img/icons/visita.png"),
                          iconSize: [20, 20],
                        })}
                        onDragend={handleMarkerOnDragEnd}
                      />
                    </Map>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <span className="h2 text-black">Endereço:</span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Situação da Visita</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  value={visitsData.situation}
                  options={situationsList}
                  onChange={(e) => {
                    !e
                      ? handleFormChange("situation", {
                          label: "Selecione",
                          value: "",
                        })
                      : handleFormChange("situation", e);
                  }}
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Data da Atividade</span>
                <input
                  value={visitsData.activityDate}
                  type="datetime-local"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("activityDate", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Data de início da visita</span>
                <input
                  value={visitsData.initialTime}
                  type="datetime-local"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("initialTime", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Data de finalização da visita</span>
                <input
                  value={visitsData.finalTime}
                  type="datetime-local"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("finalTime", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Local do Imóvel</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  value={visitsData.propertyLocal}
                  options={propertyLocalOptions}
                  onChange={(e) => {
                    !e
                      ? handleFormChange("propertyLocal", {
                          label: "Selecione",
                          value: "",
                        })
                      : handleFormChange("propertyLocal", e);
                  }}
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Tipo do Imóvel</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  value={visitsData.propertyType}
                  options={propertyTypeOptions}
                  onChange={(e) => {
                    !e
                      ? handleFormChange("propertyType", {
                          label: "Selecione",
                          value: "",
                        })
                      : handleFormChange("propertyType", e);
                  }}
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Logradouro</span>
                <input
                  value={visitsData.publicArea}
                  type="text"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("publicArea", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Número</span>
                <input
                  value={visitsData.number}
                  type="text"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("number", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Complemento</span>
                <input
                  value={visitsData.complement}
                  type="text"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("complement", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Quadra</span>
                <input
                  value={visitsData.block}
                  type="text"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("block", target.value)
                  }
                />
              </Col>
            </Row>
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <span className="h2 text-black">Residente:</span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Nome</span>

                <input
                  value={visitsData.residentName}
                  type="text"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("residentName", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">Telefone</span>
                <input
                  value={visitsData.residentPhone}
                  type="text"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("residentPhone", target.value)
                  }
                />
              </Col>
              <Col className="col-md-6 visit-responsive-filter mb-2">
                <span className="h4 text-black">E-mail</span>
                <input
                  value={visitsData.residentEmail}
                  type="text"
                  className="form-control"
                  onChange={({ target }) =>
                    handleFormChange("residentEmail", target.value)
                  }
                />
              </Col>
            </Row>
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <div className="row">
          <div className="col-6" style={{ maxWidth: "100%", display: "flex" }}>
            <Button color="primary" onClick={() => handleUpdateVisit()}>
              Salvar
            </Button>
            <Button
              color="secondary"
              onClick={() => toggle((previousValue) => !previousValue)}
            >
              Fechar
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditVisitModal;
