import React from "react";

export default function formatSampleData (sampleF, data) {
    let showSampleNumber = data[0].sampleDataNumber;
    
    if (sampleF.sampleExtraInformations) {
      sampleF.isFractionated = sampleF.sampleExtraInformations.isFractionated;
      if (sampleF.isFractionated && sampleF.principalSampleOfFractionated) {
        sampleF.indexOfPrincipalSample = data.findIndex(f => f.id == sampleF.principalSampleOfFractionated.id);
      }
  
      sampleF.isAnotherResult = sampleF.sampleExtraInformations.isAnotherResult;
      sampleF.extraNumber = sampleF.sampleExtraInformations.extraNumber;
    }
  
    console.log("sampleF, index", JSON.parse(JSON.stringify(sampleF)));
  
    let principalSampleNumber = showSampleNumber;
    let tooltip = <span>Essa amostra é a amostra principal</span>;
  
    if (sampleF.isFractionated) {
      if (sampleF.indexOfPrincipalSample && data[sampleF.indexOfPrincipalSample] && data[sampleF.indexOfPrincipalSample].extraNumber) {
        showSampleNumber += "." + data[sampleF.indexOfPrincipalSample].extraNumber;
        principalSampleNumber = showSampleNumber;
      } 
      tooltip = <span>Essa amostra é fracionada da amostra: {showSampleNumber}</span>;
      showSampleNumber += "." + sampleF.extraNumber;
    } else if (sampleF.isAnotherResult) {
      tooltip = <span>Essa amostra é outro resultado da amostra: {principalSampleNumber}</span>;
      showSampleNumber += "." + sampleF.extraNumber;
      principalSampleNumber = showSampleNumber;
    }
  
    return { showSampleNumber, principalSampleNumber, tooltip };
  };