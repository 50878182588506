import React from "react";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import { Button, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import TrapsPageTableFilters from "../Filters/TrapsPageTableFilters";
import TrapHistoriesModal from "../Modals/TrapHistoriesModal";
import UninstallTrapModal from "../Modals/UninstallTrapModal";
import EditTrapModal from "../Modals/EditTrapModal";
import popOverStyles from "../../../../components/Tables/DashboardTrapsTable.module.css";
import useTrapsPage from "../Hooks/useTrapsPage";
import {
  generateTrapLatitudeAndLongitude,
  getTrapColorSituationByDaysSinceLastActivity,
} from "../../../../services/utils/globalFunctions";
import useEmployeesList from "../../../../hooks/useEmployeesList";
import useTrapsList from "../../../../hooks/useTrapsList";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import useLoading from "../Hooks/useLoading";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import xlsx from "json-as-xlsx";
import { getUserData } from "../../../../services/utils/auth";
import {
  generateTableColumnsByOrganization,
  ORGANIZATIONS_IDS_TO_CONSIDER_LAST_READ_HISTORY_LAT_LONG_ON_MAPS,
} from "../trapsPageConstants";

const DEFAULT_FILTERS = {
  number: "",
  address: "",
  status: {
    label: "Instalada",
    value: "instaled",
  },
  statusByColor: {
    label: "Todos",
    value: "all",
  },
  type: {
    label: "Todos",
    value: "all",
  },
  typistId: {
    label: "Todos",
    value: "all",
  },
  beginTypingDate: "",
  endTypingDate: "",
  installerId: {
    label: "Todos",
    value: "all",
  },
  readerId: {
    label: "Todos",
    value: "all",
  },
  beginInstallDate: "",
  endInstallDate: "",
  beginReadDate: "",
  endReadDate: "",
  beginUninstallDate: "",
  endUninstallDate: "",
};

const useTrapsPageTable = () => {
  const [activeTableColumns, setActiveTableColumns] = React.useState({
    "Tipo de armadilha": true,
    Número: true,
    Endereço: true,
    "Funcionário que instalou (campo)": true,
    "Data de instalação (campo)": true,
    "Hora de instalação (campo)": true,
    "Funcionário que realizou a manutenção (campo)": true,
    "Data da manutenção (campo)": true,
    "Hora da manutenção (campo)": true,
    "Funcionário que realizou a desinstalação (campo)": false,
    "Data da desinstalação (campo)": false,
    "Hora da desinstalação (campo)": false,
    "Funcionário que digitou a instalação (Web)": true,
    "Data de digitação da instalação (Web)": true,
    "Hora de digitação da instalação (Web)": true,
    "Funcionário que digitou a manutenção (Web)": true,
    "Data de digitação da manutenção (Web)": true,
    "Hora de digitação da manutenção (Web)": true,
    "Situação da armadilha": true,
  });
  const { appliedFilters } = useAppliedFilters();
  const { employeesList } = useEmployeesList();
  const [filteredTrapsForTable, setFilteredTrapsForTable] = React.useState([]);
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS);
  const [isTrapHistoriesModalOpen, setIsTrapHistoriesModalOpen] =
    React.useState(false);
  const [isUninstallTrapModalOpen, setIsUninstallTrapModalOpen] =
    React.useState(false);
  const { trapTypesList } = useTrapTypesList();
  const { trapsList } = useTrapsList();
  const { selectedTrapNumber, setSelectedTrapNumber, toggleEditTrapModal } =
    useTrapsPage();
  const { isTrapsListLoading } = useLoading();

  React.useEffect(() => {
    if (appliedFilters.trapType.value === "") return;

    handleFiltersChange("type", {
      label: appliedFilters.trapType.label,
      value: appliedFilters.trapType.value,
    });
  }, [appliedFilters]);

  React.useEffect(() => {
    const userPreferences = localStorage.getItem("userPreferences");

    if (!userPreferences) return;

    const { tableColumns } = JSON.parse(userPreferences);

    const preferencesToSet = {};

    for (const [key, value] of Object.entries(tableColumns.dashboardTrapsTable))
      preferencesToSet[key] = value;

    setActiveTableColumns(preferencesToSet);
  }, []);

  React.useEffect(() => {
    const filteredTrapsForTable = filterTraps();

    setFilteredTrapsForTable(filteredTrapsForTable);
  }, [filters, trapsList]);

  const filterTraps = () => {
    const TRAP_NOT_GEOREFERENCED_LATITUDE = -15.798784;
    const TRAP_NOT_GEOREFERENCED_LONGITUDE = -47.8638;

    const beginInstallDate = moment(filters.beginInstallDate);
    const endInstallDate = moment(filters.endInstallDate).add(1, "day");
    const beginReadDate = moment(filters.beginReadDate);
    const endReadDate = moment(filters.endReadDate).add(1, "day");
    const beginUninstallDate = moment(filters.beginUninstallDate);
    const endUninstallDate = moment(filters.endUninstallDate).add(1, "day");

    const { insideTerritorialization } = filters;

    const trapsFiltered = trapsList.filter((trap) => {
      if (filters.number !== "" && !trap.number.includes(filters.number))
        return false;

      if (
        insideTerritorialization &&
        (!insideTerritorialization.includes(trap.insideTerritorialization) ||
          !trap.insideTerritorialization)
      ) {
        return false;
      }

      if (
        filters.statusByColor.value !== "all" &&
        getTrapColorSituationByDaysSinceLastActivity(
          trap.trapType.name,
          trap.daysSinceLastActivity
        ) !== filters.statusByColor.value
      )
        return false;

      if (
        filters.type.value !== "all" &&
        trap.trapType.id !== filters.type.value
      )
        return false;

      if (
        filters.beginInstallDate !== "" &&
        filters.endInstallDate !== "" &&
        !moment(trap.lastInstallHistoryByStatusDate.date).isBetween(
          beginInstallDate,
          endInstallDate,
          null,
          "[]"
        )
      )
        return false;

      if (
        filters.beginReadDate !== "" &&
        filters.endReadDate !== "" &&
        !moment(
          !trap.lastReadHistoryByStatusDate ||
            !trap.lastReadHistoryByStatusDate.date
            ? null
            : trap.lastReadHistoryByStatusDate.date
        ).isBetween(beginReadDate, endReadDate, null, "[]")
      )
        return false;

      if (
        filters.beginUninstallDate !== "" &&
        filters.endUninstallDate !== "" &&
        !moment(
          !trap.lastUninstallHistoryByStatusDate ||
            !trap.lastUninstallHistoryByStatusDate.date
            ? null
            : trap.lastUninstallHistoryByStatusDate.date
        ).isBetween(beginUninstallDate, endUninstallDate, null, "[]")
      )
        return false;

      if (
        filters.installerId.label !== "Todos" &&
        getTrapInstallerOrTyperName(
          trap.lastInstallHistoryByStatusDate.userId,
          "Não encontrado"
        ) !== filters.installerId.label
      )
        return false;

      if (
        filters.readerId.label !== "Todos" &&
        getTrapInstallerOrTyperName(
          !trap.lastReadHistoryByStatusDate ||
            !trap.lastReadHistoryByStatusDate.userId
            ? null
            : trap.lastReadHistoryByStatusDate.userId,
          "Não encontrado"
        ) !== filters.readerId.label
      )
        return false;

      if (
        filters.address !== "" &&
        !trap.address
          .toLowerCase()
          .includes(filters.address.toLocaleLowerCase())
      )
        return false;

      if (filters.status.value === "instaled" && trap.status !== 1)
        return false;

      if (filters.status.value === "removed" && trap.status !== 2) return false;

      if (
        filters.status.value === "notGeoreferenced" &&
        trap.lastInstallHistoryByStatusDate.latitude !==
          TRAP_NOT_GEOREFERENCED_LATITUDE &&
        trap.lastInstallHistoryByStatusDate.longitude !==
          TRAP_NOT_GEOREFERENCED_LONGITUDE
      )
        return false;

      if (
        filters.status.value === "georeferencedByAddress" &&
        !trap.georeferencedByAddress
      )
        return false;

      return true;
    });

    return trapsFiltered;
  };

  const formatTrapsToExport = (trapsToExport) => {
    if (!trapsToExport || trapsToExport.length === 0) return [];

    const ORGANIZATION_ID = getUserData("organizationId");
    const CONSIDER_LAST_READ_DATE =
      ORGANIZATIONS_IDS_TO_CONSIDER_LAST_READ_HISTORY_LAT_LONG_ON_MAPS.includes(
        ORGANIZATION_ID
      );

    const formatedTrapsToExport = trapsToExport.map((trap) => {
      const { latitude, longitude, reference } =
        generateTrapLatitudeAndLongitude(
          trap.lastInstallHistoryByStatusDate,
          trap.lastReadHistoryByStatusDate,
          CONSIDER_LAST_READ_DATE
        );

      return {
        id: trap.id,
        trapNumber: trap.number,
        trapTypeName: ARMADILHA_INFO[trap.trapType.name].apelido,
        propertyType: trap.propertyType,
        establishment: trap.establishment,
        projects:
          !trap.lastInstallHistoryByStatusDate ||
          !trap.lastInstallHistoryByStatusDate.projects ||
          trap.lastInstallHistoryByStatusDate.projects.length === 0
            ? ""
            : trap.lastInstallHistoryByStatusDate.projects
                .map((project) => project.name)
                .join(", "),
        situation: trap.status === 1 ? "Instalada" : "Desinstalada",
        insideTerritorialization: trap.insideTerritorialization,
        insideTerritorialization2: trap.insideTerritorialization2,
        address: trap.address,
        latitude,
        longitude,
        howManyTimesWasReaded: trap.howManyTimesWasReaded,
        /* latitude: trap.lastInstallHistoryByStatusDate.latitude,
        longitude: trap.lastInstallHistoryByStatusDate.longitude, */
        latitudeLongitudeReference:
          reference === "installation"
            ? "Última instalação"
            : "Última manutenção",
        userWhoLastInstalled: getTrapInstallerOrTyperName(
          trap.lastInstallHistoryByStatusDate.userId,
          "Não encontrado"
        ),
        userWhoLastInstalledArea:
          !trap.lastInstallHistoryByStatusDate ||
          !trap.lastInstallHistoryByStatusDate.user
            ? null
            : trap.lastInstallHistoryByStatusDate.user.vinculatedRegion,
        lastInstallDate: moment(trap.lastInstallHistoryByStatusDate.date)
          .subtract(3, "hours")
          .format("DD/MM/YYYY HH:mm"),
        userWhoLastReaded: getTrapInstallerOrTyperName(
          !trap.lastReadHistoryByStatusDate ||
            !trap.lastReadHistoryByStatusDate.userId
            ? null
            : trap.lastReadHistoryByStatusDate.userId,
          "Não encontrado"
        ),
        userWhoLastReadedArea:
          !trap.lastReadHistoryByStatusDate ||
          !trap.lastReadHistoryByStatusDate.user
            ? ""
            : trap.lastReadHistoryByStatusDate.user.vinculatedRegion,
        lastReadDate:
          !trap.lastReadHistoryByStatusDate ||
          !trap.lastReadHistoryByStatusDate.date
            ? "-"
            : moment(trap.lastReadHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY HH:mm"),
        lastUninstallDate:
          !trap.lastUninstallHistoryByStatusDate ||
          !trap.lastUninstallHistoryByStatusDate.date
            ? "-"
            : moment(trap.lastUninstallHistoryByStatusDate.date)
                .subtract(3, "hours")
                .format("DD/MM/YYYY HH:mm"),
        userWhoLastUninstalled: getTrapInstallerOrTyperName(
          !trap.lastUninstallHistoryByStatusDate ||
            !trap.lastUninstallHistoryByStatusDate.userId
            ? null
            : trap.lastUninstallHistoryByStatusDate.userId,
          "Não encontrado"
        ),
        userWhoLastUninstalledArea:
          !trap.lastUninstallHistoryByStatusDate ||
          !trap.lastUninstallHistoryByStatusDate.user
            ? ""
            : trap.lastUninstallHistoryByStatusDate.user.vinculatedRegion,
        daysSinceLastActivity: trap.daysSinceLastActivity,
      };
    });

    return formatedTrapsToExport;
  };

  const generateTrapActionsButtons = ({ number, status }) => {
    const INACTIVE_TRAP_STATUS = 2;

    const buttonStyles = {
      width: "40px",
      margin: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <Row>
        <Col style={{ display: "flex" }}>
          <div>
            <Button
              color="primary"
              onClick={() => {
                setSelectedTrapNumber(number);
                toggleTrapHistoriesModal();
              }}
              style={buttonStyles}
              data-tip
              data-for="trapHistory"
            >
              <i className="fa fa-search"></i>
            </Button>
            <ReactTooltip effect="solid" type="info" id="trapHistory">
              Histórico
            </ReactTooltip>
          </div>
          <div>
            <Button
              color="primary"
              style={buttonStyles}
              data-tip
              data-for="editTrap"
              onClick={() => {
                setSelectedTrapNumber(number);
                toggleEditTrapModal();
              }}
            >
              <i className="fa fa-pen"></i>
            </Button>
            <ReactTooltip effect="solid" type="info" id="editTrap">
              Editar
            </ReactTooltip>
          </div>
          {status !== INACTIVE_TRAP_STATUS && (
            <div>
              <Button
                color="warning"
                data-tip
                data-for="uninstallTrap"
                onClick={() => {
                  setSelectedTrapNumber(number);
                  toggleUninstallTrapModal();
                }}
                style={buttonStyles}
              >
                <i className="fa fa-trash"></i>
              </Button>
              <ReactTooltip effect="solid" type="info" id="uninstallTrap">
                Desinstalar
              </ReactTooltip>
            </div>
          )}
        </Col>
      </Row>
    );
  };

  const generateTrapSituationButton = (row) => {
    const INACTIVE_TRAP_STATUS = 2;

    if (row.status === INACTIVE_TRAP_STATUS) return null;

    const trapTypeRange = getTrapRange(row.trapType.name);

    let trapStatusColor = "white";

    if (trapTypeRange.quantityRange === 2) {
      if (row.daysSinceLastActivity <= trapTypeRange.data.end)
        trapStatusColor = "#76ea79";
      else trapStatusColor = "#ff0000";
    }

    if (trapTypeRange.quantityRange === 3) {
      if (row.daysSinceLastActivity <= trapTypeRange.data.middle)
        trapStatusColor = "#76ea79";
      else if (row.daysSinceLastActivity <= trapTypeRange.data.end)
        trapStatusColor = "#fff200";
      else trapStatusColor = "#ff0000";
    }

    const statusStyles = {
      width: "60px",
      margin: "5px",
      backgroundColor: trapStatusColor,
    };

    return (
      <button className="btn" style={statusStyles}>
        {row.daysSinceLastActivity}
      </button>
    );
  };

  const getTrapInstallerOrTyperName = (
    installerId,
    invalidInstallerIdMessage
  ) => {
    if (
      installerId === "00000000-0000-0000-0000-000000000000" ||
      installerId === null ||
      installerId === undefined
    )
      return invalidInstallerIdMessage;

    let employeeObject;

    employeeObject = employeesList.filter(
      (employee) => employee.userId === installerId
    )[0];
    if (employeeObject) return employeeObject.label;

    employeeObject = employeesList.filter(
      (employee) => employee.employeeId === installerId
    )[0];
    if (employeeObject) return employeeObject.label;

    return invalidInstallerIdMessage;
  };

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...filters };

    newFiltersValues[filterName] = newValue;

    setFilters(newFiltersValues);
  };

  const handleActiveColumnsChange = (columnName) => {
    setActiveTableColumns((previousValues) => {
      const newValues = { ...previousValues };

      newValues[columnName] = !newValues[columnName];

      const preferences = {
        tableColumns: {
          dashboardTrapsTable: { ...newValues },
        },
      };

      localStorage.setItem("userPreferences", JSON.stringify(preferences));

      return newValues;
    });
  };

  const toggleTrapHistoriesModal = React.useCallback(() => {
    setIsTrapHistoriesModalOpen((previousValue) => {
      if (previousValue) setSelectedTrapNumber("");

      return !previousValue;
    });
  }, []);

  const toggleUninstallTrapModal = React.useCallback(() => {
    setIsUninstallTrapModalOpen((previousValue) => !previousValue);
  }, []);

  const handleDownloadTrapsList = () => {
    const formatedTrapsListToExport = formatTrapsToExport(
      filteredTrapsForTable
    );

    const baseColumns = [
      /* { label: "Identificador", value: "id" }, */
      { label: "Tipo da armadilha", value: "trapTypeName" },
      { label: "Número da armadilha", value: "trapNumber" },
      { label: "Tipo de imóvel", value: "propertyType" },
      { label: "Estabelecimento", value: "establishment" },
      { label: "Situação", value: "situation" },
      { label: "Região 1", value: "insideTerritorialization" },
      { label: "Região 2", value: "insideTerritorialization2" },
      { label: "Endereço", value: "address" },
      {
        label: "Quantas vezes passou por manutenção",
        value: "howManyTimesWasReaded",
      },
      /* { label: "Referência da Lat/Long", value: "latitudeLongitudeReference" }, */
      { label: "Latitude", value: "latitude" },
      { label: "Longitude", value: "longitude" },
      {
        label: "Responsável pela última instalação em campo",
        value: "userWhoLastInstalled",
      },
      {
        label: "Lotação do responsável pela última instalação em campo",
        value: "userWhoLastInstalledArea",
      },
      {
        label: "Data da última instalação em campo",
        value: "lastInstallDate",
      },
      {
        label: "Responsável pela última manutenção em campo",
        value: "userWhoLastReaded",
      },
      {
        label: "Lotação do responsável pela última manutenção em campo",
        value: "userWhoLastReadedArea",
      },
      {
        label: "Data da última manutenção em campo",
        value: "lastReadDate",
      },
      {
        label: "Responsável pela última desinstalação em campo",
        value: "userWhoLastUninstalled",
      },
      {
        label: "Lotação do responsável pela última desinstalação em campo",
        value: "userWhoLastUninstalledArea",
      },
      {
        label: "Data da última desinstalação em campo",
        value: "lastUninstallDate",
      },
      {
        label: "Dias desde a última instalação/manutenção até a data fim",
        value: "daysSinceLastActivity",
      },
    ];

    const xlsxFileSettings = {
      fileName: `armadilhas_VITEC_${moment().format("DD/MM/YYYY HH:mm")}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
      RTL: false,
    };

    xlsx(
      [
        {
          sheet: "Armadilhas",
          columns:
            appliedFilters.projects.length > 0
              ? [...baseColumns, { label: "Projetos", value: "projects" }]
              : baseColumns,
          content: formatedTrapsListToExport,
        },
      ],
      xlsxFileSettings
    );
  };

  const tableColumns = [
    ...generateTableColumnsByOrganization(
      getUserData("organizationId"),
      activeTableColumns,
      getTrapInstallerOrTyperName
    ),
    {
      name: (
        <span>
          Dias desde a última atividade
          <i className="fa fa-info" data-tip data-for="trapsDays"></i>
          <ReactTooltip effect="solid" type="info" id="trapsDays">
            Representa a diferença de dias entre a instalação ou manutenção mais
            recente até hoje
          </ReactTooltip>
        </span>
      ),
      sortable: true,
      sortFunction: (a, b) => {
        return a.daysSinceLastActivity - b.daysSinceLastActivity;
      },
      selector: "daysSinceLastActivity",
      width: "100px",
      cell: (row) => generateTrapSituationButton(row),
      omit: !activeTableColumns["Situação da armadilha"] ? true : false,
    },
    {
      cell: (row) => generateTrapActionsButtons(row),
      ignoreRowClick: true,
    },
  ];

  return {
    activeTableColumns,
    employeesList,
    filteredTrapsForTable,
    filters,
    handleActiveColumnsChange,
    handleDownloadTrapsList,
    handleFiltersChange,
    isTrapsListLoading,
    isTrapHistoriesModalOpen,
    isUninstallTrapModalOpen,
    selectedTrapNumber,
    tableColumns,
    toggleTrapHistoriesModal,
    toggleUninstallTrapModal,
    trapTypesList,
  };
};

const TrapsPageTable = () => {
  const {
    activeTableColumns,
    employeesList,
    filteredTrapsForTable,
    filters,
    handleActiveColumnsChange,
    handleDownloadTrapsList,
    handleFiltersChange,
    isTrapsListLoading,
    isTrapHistoriesModalOpen,
    isUninstallTrapModalOpen,
    selectedTrapNumber,
    tableColumns,
    toggleTrapHistoriesModal,
    toggleUninstallTrapModal,
    trapTypesList,
  } = useTrapsPageTable();

  const styles = {
    col: {
      marginTop: "0.7rem",
    },
  };

  if (isTrapsListLoading) return <TableLoadingSkeleton />;

  return (
    <>
      <UninstallTrapModal
        isOpen={isUninstallTrapModalOpen}
        toggle={toggleUninstallTrapModal}
        trapNumber={selectedTrapNumber}
      />
      <TrapHistoriesModal
        isOpen={isTrapHistoriesModalOpen}
        toggle={toggleTrapHistoriesModal}
        trapNumber={selectedTrapNumber}
      />
      <EditTrapModal
        employeesList={employeesList}
        trapTypesList={trapTypesList}
      />
      <Card>
        <CardHeader
          headerText={"Tabela de armadilhas"}
          showExpandButton={false}
          showButtonsTooltips={true}
          showDownloadDataButton={false}
        >
          <TrapsPageTableFilters
            employeesList={employeesList}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
            trapTypesList={trapTypesList}
          />
          <Button
            id="Popover1"
            color="primary"
            type="button"
            style={{ width: "55px", marginRight: "0px" }}
            data-tip
            data-for="columns"
          >
            <i className="fa fa-columns"></i>
          </Button>
          <ReactTooltip effect="solid" type="info" id="columns">
            Habilitar/ocultar colunas
          </ReactTooltip>
          <UncontrolledPopover flip placement="left" target="Popover1">
            <PopoverBody>
              <div>
                {Object.keys(activeTableColumns).map((columnName, index) => {
                  return (
                    <button
                      className={popOverStyles.tableButtonPopover}
                      key={index}
                      onClick={() => handleActiveColumnsChange(columnName)}
                    >
                      {activeTableColumns[columnName] ? (
                        <i class="fa fa-check"></i>
                      ) : (
                        <div
                          style={{
                            width: "13.28px",
                            height: "13.28px",
                          }}
                        ></div>
                      )}
                      <span style={{ textAlign: "left" }}>{columnName}</span>
                    </button>
                  );
                })}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
          <Button
            onClick={() => handleDownloadTrapsList()}
            color="primary"
            data-tip
            data-for="dataDownload"
            style={{ width: "55px", marginRight: "0px" }}
          >
            <i className="fa fa-download" />
          </Button>
          <ReactTooltip effect="solid" type="info" id="dataDownload">
            Baixar dados
          </ReactTooltip>
        </CardHeader>
        <CardContent>
          <Row>
            <Col style={styles.col}>
              <DataTable
                noHeader
                defaultSortField="name"
                defaultSortAsc={true}
                pagination
                highlightOnHover
                responsive
                columns={tableColumns}
                data={filteredTrapsForTable}
                noDataComponent={"Nenhum registro encontrado."}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

export default TrapsPageTable;