import React from "react";

import { Container } from "reactstrap";
import { FieldResultsFilters } from "./Filters/FieldResulstPageFilters";
import { ToastContainer } from "react-toastify";
import FieldResultsPageGraphicsWrapper from "./FieldResultsPageGraphicsWrapper";

const FieldResultsPage = () => {
  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important", color: "#6d6d6d" }}>
          &nbsp;
        </div>
        <FieldResultsFilters />
      </Container>
      <Container fluid className="pb-2 mt-2">
        <section>
          <FieldResultsPageGraphicsWrapper />
        </section>
      </Container>
    </main>
  );
};

export default FieldResultsPage;
