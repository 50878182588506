import React from "react";

import { Card, CardBody, CardText, Col, Row } from "reactstrap";
import { getUserData } from "../../../../services/utils/auth";
import useTrapsList from "../../../../hooks/useTrapsList";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useLoading from "../Hooks/useLoading";
import CardLoadingSkeleton from "../../../../components/ui/Loading/CardLoadingSkeleton";

const styles = {
  cardBody: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardText: { fontWeight: "bold", marginBottom: "0px" },
};

const useTrapsOverviewCardsWrapper = () => {
  const { appliedFilters } = useAppliedFilters();
  const { trapsList } = useTrapsList();
  const { isTrapsListLoading } = useLoading();

  return { appliedFilters, isTrapsListLoading, trapsList };
};

const TrapsOverviewCardsWrapper = () => {
  const [activeTrapsNumber, setActiveTrapsNumber] = React.useState(0);
  const { appliedFilters, isTrapsListLoading, trapsList } =
    useTrapsOverviewCardsWrapper();

  const organizationId = getUserData("organizationId");

  React.useEffect(() => {
    if (
      appliedFilters.demand.value !== "" ||
      appliedFilters.employees.length > 0 ||
      appliedFilters.team.value !== "" ||
      appliedFilters.territorializations.length > 0
    )
      return;

    if (
      !trapsList ||
      trapsList.length === 0 ||
      !appliedFilters ||
      !appliedFilters.trapType ||
      appliedFilters.trapType.value === ""
    )
      return;

    const ACTIVE_TRAP_STATUS = 1;

    const activeTrapsFromTrapType = trapsList.filter(
      (trap) =>
        trap.trapType.name === appliedFilters.trapType.trapTypeName &&
        trap.status === ACTIVE_TRAP_STATUS
    );

    setActiveTrapsNumber(activeTrapsFromTrapType.length);
  }, [trapsList, appliedFilters]);

  if (
    appliedFilters.demand.value !== "" ||
    appliedFilters.employees.length > 0 ||
    appliedFilters.team.value !== "" ||
    appliedFilters.territorializations.length > 0
  )
    return null;

  if (isTrapsListLoading || activeTrapsNumber === 0)
    return (
      <Row className="mb-4">
        <Col className="col-md-4 visit-responsive-filter">
          <CardLoadingSkeleton middleBarWidth="250px" />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <CardLoadingSkeleton middleBarWidth="250px" />
        </Col>
        <Col className="col-md-4 visit-responsive-filter">
          <CardLoadingSkeleton middleBarWidth="250px" />
        </Col>
      </Row>
    );

  return (
    <Row className="mb-4">
      <Col className="col-md-4 visit-responsive-filter">
        <TotalActiveTrapsCard
          organizationId={organizationId}
          trapType={appliedFilters.trapType}
          value={activeTrapsNumber}
        />
      </Col>
      <Col className="col-md-4 visit-responsive-filter">
        <TotalBeingInstalledTrapsCard
          activeTrapsNumber={activeTrapsNumber}
          organizationId={organizationId}
          trapType={appliedFilters.trapType}
        />
      </Col>
      <Col className="col-md-4 visit-responsive-filter">
        <TotalAvailableTrapsToInstallCard
          activeTrapsNumber={activeTrapsNumber}
          organizationId={organizationId}
          trapType={appliedFilters.trapType}
        />
      </Col>
    </Row>
  );
};

const TotalActiveTrapsCard = ({ organizationId, trapType, value }) => {
  const getCustomTextByOrganization = {
    //São Paulo
  };

  const defaultText = (
    <span>
      Total de {trapType.label === "Selecione" ? "armadilhas" : trapType.label}{" "}
      instaladas
    </span>
  );

  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>{value}</CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          {!getCustomTextByOrganization[organizationId]
            ? defaultText
            : getCustomTextByOrganization[organizationId]}
        </CardText>
      </CardBody>
    </Card>
  );
};

const TotalBeingInstalledTrapsCard = ({
  activeTrapsNumber,
  organizationId,
  trapType,
}) => {
  const getCustomTextByOrganization = {
    //São Paulo
  };

  const getCustomValueByOrganization = {
    //São Paulo
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": {
      armadilhaDisseminadoraInseticida: 40000 - activeTrapsNumber,
    },
    //Cotia
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": {
      armadilhaDisseminadoraInseticida: 1000 - activeTrapsNumber,
    },
    //Goiânia
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": {
      armadilhaDisseminadoraInseticida: 10800 - activeTrapsNumber,
    },
    //Foz.adm
    "42a4ea68-1e69-428c-9498-e4727d30dd20": {
      armadilhaDisseminadoraInseticida: 100 - activeTrapsNumber,
    },
    //Itapira
    "4c40364b-c94e-4baf-82f8-b504bf399393": {
    armadilhaDisseminadoraInseticida: 1440 - activeTrapsNumber,
    }
  };

  const defaultText = (
    <span>
      Total de {trapType.label === "Selecione" ? "armadilhas" : trapType.label}{" "}
      em processo de instalação
    </span>
  );

  const defaultValue = <span>Não definido</span>;

  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>
          {!getCustomValueByOrganization[organizationId] ||
          !getCustomValueByOrganization[organizationId][trapType.trapTypeName]
            ? defaultValue
            : getCustomValueByOrganization[organizationId][
                trapType.trapTypeName
              ]}
        </CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          {!getCustomTextByOrganization[organizationId]
            ? defaultText
            : getCustomTextByOrganization[organizationId]}
        </CardText>
      </CardBody>
    </Card>
  );
};

const TotalAvailableTrapsToInstallCard = ({ organizationId, trapType }) => {
  const getCustomTextByOrganization = {
    //São Paulo
  };

  const getCustomValueByOrganization = {
    //São Paulo
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": {
      armadilhaDisseminadoraInseticida: 40000,
    },
    //Cotia
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": {
      armadilhaDisseminadoraInseticida: 1000,
    },
    //Goiânia
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": {
      armadilhaDisseminadoraInseticida: 10800,
    },
    //Foz.adm
    "42a4ea68-1e69-428c-9498-e4727d30dd20": {
      armadilhaDisseminadoraInseticida: 100,
    },
    //Itapira
    "4c40364b-c94e-4baf-82f8-b504bf399393": {
      armadilhaDisseminadoraInseticida: 1440,
    },
  };

  const defaultText = (
    <span>
      Total de {trapType.label === "Selecione" ? "armadilhas" : trapType.label}{" "}
      disponíveis para instalação
    </span>
  );

  const defaultValue = <span>Não definido</span>;

  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>
          {!getCustomValueByOrganization[organizationId] ||
          !getCustomValueByOrganization[organizationId][trapType.trapTypeName]
            ? defaultValue
            : getCustomValueByOrganization[organizationId][
                trapType.trapTypeName
              ]}
        </CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          {!getCustomTextByOrganization[organizationId]
            ? defaultText
            : getCustomTextByOrganization[organizationId]}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default TrapsOverviewCardsWrapper;