import moment from "moment";
import getWeeks from "../../services/utils/epidemiologicalWeek";

export function getTodayEpidemiologicalWeek() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const epidemiologicalWeek = getWeeks().filter((ew) => ew.year == currentYear);

  let semanaEncontrada = -1;
  const today = new Date();

  for (let i = 0; i < epidemiologicalWeek[0].weeks.length; i++) {
    const beginDateObj = parseDate(epidemiologicalWeek[0].weeks[i].beginDate);
    const endDateObj = parseDate(epidemiologicalWeek[0].weeks[i].endDate);

    //adicionando um dia a mais
    endDateObj.setDate(endDateObj.getDate() + 1);
    beginDateObj.setDate(beginDateObj.getDate() + 1);

    const dataSemHoras = (data) =>
      new Date(data.getFullYear(), data.getMonth(), data.getDate());

    if (
      dataSemHoras(today) >= dataSemHoras(beginDateObj) &&
      dataSemHoras(today) <= dataSemHoras(endDateObj)
    ) {
      return i + 1;
    }
  }
}

//Calculo para Semana epidemiológica atual
function parseDate(dateString) {
  const [day, month, year] = dateString.split("/");
  return new Date(`${year}-${month}-${day}`);
}

export const getAllWeeksFromYear = (year) => {
  const epidemiologicalWeeks = getWeeks().find((ew) => ew.year === year);

  if (!epidemiologicalWeeks) return -1;

  return epidemiologicalWeeks.weeks;
};

export const getActualEpidemiologicalWeek = () => {
  const actualDate = moment();
  const year = String(actualDate.year());

  const epidemiologicalWeeks = getWeeks().find((ew) => ew.year === year);

  if (!epidemiologicalWeeks) return -1;

  const actualEpidemiologicalWeekIndex = epidemiologicalWeeks.weeks.findIndex(
    (ew) => {
      const beginDate = moment(ew.beginDate, "DD/MM/YYYY");
      const endDate = moment(ew.endDate, "DD/MM/YYYY");

      return actualDate >= beginDate && actualDate <= endDate;
    }
  );

  if (!actualEpidemiologicalWeekIndex || actualEpidemiologicalWeekIndex === -1)
    return -1;

  const result = {
    ...epidemiologicalWeeks.weeks[actualEpidemiologicalWeekIndex],
    weeksArrayIndex: actualEpidemiologicalWeekIndex,
    weekNumber: actualEpidemiologicalWeekIndex + 1,
  };

  return result;
};
