import { useContextSelector } from "use-context-selector";
import { SinanMapContext } from "../Contexts/SinanMapContext";

export const useCasesCloropletic = () => {
  const casesCloropleticByAreas = useContextSelector(
    SinanMapContext,
    (context) => context.casesCloropleticByAreas
  );
  const casesCloropleticLegends = useContextSelector(
    SinanMapContext,
    (context) => context.casesCloropleticLegends
  );
  const setCasesCloropleticByAreas = useContextSelector(
    SinanMapContext,
    (context) => context.setCasesCloropleticByAreas
  );
  const setCasesCloropleticLegends = useContextSelector(
    SinanMapContext,
    (context) => context.setCasesCloropleticLegends
  );

  return {
    casesCloropleticByAreas,
    casesCloropleticLegends,
    setCasesCloropleticByAreas,
    setCasesCloropleticLegends,
  };
};
