import React from "react";

import { Button, Col, Row } from "reactstrap";
import { toast } from "react-toastify";

import styles from "./ImportTrapHistoriesPage.module.css";
import { importTrapHistories } from "../../../services/api/Trap";
import LoadingSpin from "react-loading-spin";

const ImportTrapHistoriesPage = () => {
  const [isDraggingFile, setIsDraggingFile] = React.useState(false);
  const [filesToImportData, setFilesToImportData] = React.useState([]);
  const [isFilesLoading, setIsFilesLoading] = React.useState(false);

  const validateFileType = (file) => {
    const fileExtension = file.name.split(".").pop();

    return allowedFileExstensions.includes(`.${fileExtension.toLowerCase()}`);
  };

  const handleDeleteFileToImport = (fileIndex) => {
    setFilesToImportData((previousValues) => {
      const newValues = [...previousValues];

      newValues.splice(fileIndex, 1);

      return newValues;
    });
  };

  const handleAddManualFile = (target) => {
    const file = target.files[0];

    if (!file || !validateFileType(file)) {
      toast.error(
        `Tipo de arquivo não suportado. Apenas arquivos com a extensão ${allowedFileExstensions.join(
          ", "
        )} são permitidos.`
      );

      return;
    }

    setFilesToImportData([file]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingFile(false);

    const file = event.dataTransfer.files[0];

    if (!file || !validateFileType(file)) {
      toast.error(
        `Tipo de arquivo não suportado. Apenas arquivos com a extensão ${allowedFileExstensions.join(
          ", "
        )} são permitidos.`
      );

      return;
    }

    setFilesToImportData([file]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingFile(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingFile(false);
  };

  const handleSubmitFile = async () => {
    if (!filesToImportData || filesToImportData.length === 0) return;

    const formData = new FormData();
    formData.append("file", filesToImportData[0]);

    setIsFilesLoading(true);

    try {
      const { data, status } = await importTrapHistories(formData);

      if (status !== 200)
        throw new Error("Ocorreu um erro no processamento do arquivo.");

      toast.success("Arquivo processado com sucesso");

    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    } finally {
      setIsFilesLoading(false);
    }
  };

  function formatFileSize(bytes) {
    const kb = 1024;
    const mb = kb * 1024;

    if (bytes < kb) {
      return `${bytes} bytes`;
    } else if (bytes < mb) {
      return `${(bytes / kb).toFixed(2)}KB`;
    } else {
      return `${(bytes / mb).toFixed(2)}MB`;
    }
  }

  const filesIconsByType = {
    "text/csv": require("../../../assets/img/icons/interface/file-csv.png"),
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": require("../../../assets/img/icons/interface/file-excel.png"),
  };
  const allowedFileExstensions = [".csv", ".xlsx"];

  return (
    <>
      <Row className="mb-2">
        <Col className="col-md-12 visit-responsive-filter">
          <input
            id="uploadFileInput"
            style={{ display: "none" }}
            type="file"
            accept={allowedFileExstensions.join(", ")}
            onChange={({ target }) => handleAddManualFile(target)}
          />
          <button
            className={styles.uploadButton}
            style={isDraggingFile ? { border: "3px solid #3277F8" } : null}
            onDrop={(event) => handleDrop(event)}
            onDragOver={(event) => handleDragOver(event)}
            onDragLeave={(event) => handleDragLeave(event)}
          >
            <div style={{ marginBottom: "1rem" }}>
              <i style={{ display: "block" }}>
                <img
                  onClick={() =>
                    document.getElementById("uploadFileInput").click()
                  }
                  src={require("../../../assets/img/icons/interface/upload.png")}
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  alt="icone"
                />
              </i>
            </div>
            <div style={{ marginBottom: ".9rem" }}>
              {isDraggingFile ? (
                <span
                  className={styles.chooseFileText}
                  style={{ fontWeight: "600" }}
                >
                  Solte o arquivo aqui
                </span>
              ) : (
                <span style={{ fontWeight: "600" }}>
                  Arraste e solte ou{" "}
                  <span
                    className={styles.chooseFileText}
                    onClick={() =>
                      document.getElementById("uploadFileInput").click()
                    }
                  >
                    escolha um arquivo
                  </span>{" "}
                  para subir
                </span>
              )}
            </div>
            <div>
              <span style={{ color: "#525151" }}>CSV ou XLSX</span>
            </div>
          </button>
          {filesToImportData &&
            filesToImportData.length > 0 &&
            filesToImportData.map(({ name, size, type }, index) => {
              return (
                <section
                  style={{
                    borderRadius: "7px",
                    backgroundColor: "#F0F3F5",
                    padding: ".7rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <div>
                      <i style={{ display: "block" }}>
                        <img
                          src={filesIconsByType[type]}
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                          alt="icone"
                        />
                      </i>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: ".3rem",
                        }}
                      >
                        <span style={{ color: "#1c1d1d", fontSize: "14px" }}>
                          {name}
                        </span>
                        <i
                          className="fa fa-times"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteFileToImport(index)}
                        ></i>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ fontSize: "12px" }}>
                          {formatFileSize(size)}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              );
            })}
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ display: "flex", marginBottom: "2rem", gap: "2rem" }}>
            <div
              style={{ borderBottom: "2px solid #C4C5C5", width: "100%" }}
            ></div>
            <span style={{ fontSize: "12px", color: "#7c7c7c" }}>OU</span>
            <div
              style={{ borderBottom: "2px solid #C4C5C5", width: "100%" }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary"
            style={{
              margin: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            disabled={
              filesToImportData.length === 0 || isFilesLoading ? true : false
            }
            onClick={() => handleSubmitFile()}
          >
            {isFilesLoading ? (
              <LoadingSpin primaryColor="#ffffff" size={16} />
            ) : (
              <span>Importar</span>
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ImportTrapHistoriesPage;
