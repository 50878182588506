import { useContextSelector } from "use-context-selector";
import { SinanPageContext } from "../Contexts/SinanContext";

export const useFieldResultsList = () => {
  const fieldResultsList = useContextSelector(
    SinanPageContext,
    (context) => context.fieldResultsList
  );
  const setFieldResultsList = useContextSelector(
    SinanPageContext,
    (context) => context.setFieldResultsList
  );

  return {
    fieldResultsList,
    setFieldResultsList,
  };
}