import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";
import {
  getAllTypeTerritorializations,
  getTypeLayer,
} from "../../services/api/territorialization";
import { fetchTerritorializations } from "../../services/api/territorialization";
import TerritorializationModalComponent from "../../views/pages/Territorialization/TerritorializationModalComponent";
// reactstrap components
import { Container } from "reactstrap";
import { findCep } from "../../services/api/cep";
import "./FiltroTerritorializacao.css";

class FiltroNovaTerrializacao extends React.Component {
  state = {
    toogleFields: true,
    camadaTemp: "Imóvel",
    BairroData: null,
    CidadeData: null,
    PaisData: null,
    modal: false,
    territorializations: [],
    typeOptions: [],
    previousTerritorialization: "Territorializations",
    territorializationId: [],
    territoText: "",
    selectedBaseTerritorializations: [],
    realtyData: {
      number: "",
      economy: "",
      postalCode: "",
      subpremisse: "",
    },
    lotData: {
      number: "",
      postalCode: "",
      subpremisse: "",
      sequence: "",
      complement: "",
    },
    lotsTerritorializationId: "",
    isSaving: false,
  };
  componentDidMount = async () => {
    const typeTerritorializationRequest = await getAllTypeTerritorializations();
    let typeOptions = typeTerritorializationRequest.data;

    this.setState({ typeOptions: typeOptions }, () => {
      if (typeTerritorializationRequest.data.length > 0) {
        this.setState({
          camadaTemp: typeTerritorializationRequest.data[0].id,
          toogleFields:
            typeTerritorializationRequest.data[0].typeGeometry === "Point",
        });
      }
    });
    if (this.props.SelectedTerritorialization != null) {
      const selectedTypeTerritorializationRequest = await getTypeLayer(
        this.props.TerritorializationType
      );
      const selectedTerritorialization = this.props.SelectedTerritorialization;
      let geometryType =
        selectedTypeTerritorializationRequest.data.typeGeometry;
      if (geometryType != "Point") {
        this.setState({ toogleFields: false });
      } else {
        var realtyData = {
          number: selectedTerritorialization.number,
          economy: selectedTerritorialization.economy,
          postalCode: selectedTerritorialization.postalCode,
          subpremisse: selectedTerritorialization.subpremisse,
        };
        this.setState({ realtyData: realtyData });
      }
      if (
        selectedTerritorialization.typeLayerId ==
        this.state.lotsTerritorializationId
      ) {
        var lotData = {
          number: selectedTerritorialization.number,
          postalCode: selectedTerritorialization.postalCode,
          subpremisse: selectedTerritorialization.subpremisse,
          sequence: selectedTerritorialization.sequence,
          complement: selectedTerritorialization.complement,
        };
        this.setState({ lotData: lotData });
      }
    }
  };
  handleChangeCamada(e) {
    var camada = this.state.typeOptions.filter((to) => to.id == e.target.value);

    this.props.changeCamada(e.target.value, camada[0].typeGeometry);
    this.state.camadaTemp = e.target.value;
    camada[0].name !== "Imóvel"
      ? this.setState({ toogleFields: false })
      : this.setState({ toogleFields: true });
  }

  handleChangeCep(e) {
    if (this.state.camadaTemp == this.state.lotsTerritorializationId) {
      let lotData = this.state.lotData;
      lotData.postalCode = e.target.value;
      this.setState({ lotData: lotData });
    } else {
      let realtyData = this.state.realtyData;
      realtyData.postalCode = e.target.value;
      this.setState({ realtyData: realtyData });
    }

    this.props.changeCep(e.target.value);
  }

  handleChangeNumero(e) {
    if (this.state.camadaTemp == this.state.lotsTerritorializationId) {
      let lotData = this.state.lotData;
      lotData.number = e.target.value;
      this.setState({ lotData: lotData });
    } else {
      let realtyData = this.state.realtyData;
      realtyData.number = e.target.value;
      this.setState({ realtyData: realtyData });
    }
    this.props.changeNumero(e.target.value);
  }
  handleChangeComplemento(e) {
    let lotData = this.state.lotData;
    lotData.complement = e.target.value;
    this.setState({ lotData: lotData });
    this.props.changeComplemento(e.target.value);
  }
  handleChangeSequencial(e) {
    let lotData = this.state.lotData;
    lotData.sequence = e.target.value;
    this.setState({ lotData: lotData });
    this.props.changeSequencial(e.target.value);
  }
  handleChangeRua(e) {
    if (this.state.camadaTemp == this.state.lotsTerritorializationId) {
      let lotData = this.state.lotData;
      lotData.subpremisse = e.target.value;
      this.setState({ lotData: lotData });
    } else {
      let realtyData = this.state.realtyData;
      realtyData.subpremisse = e.target.value;
      this.setState({ realtyData: realtyData });
    }
    this.props.changeRua(e.target.value);
  }

  handleChangeEconomia(e) {
    let realtyData = this.state.realtyData;
    realtyData.economy = e.target.value;
    this.setState({ realtyData: realtyData });
    this.props.changeEconomia(e.target.value);
  }

  handleChangeNome(e) {
    this.props.changeNome(e.target.value);
  }
  changeId(e) {
    this.props.changeIds(e.target);
  }
  save = async () => {
    this.setState({ isSaving: true }); // Disable the button
    try {
      await this.props.Save(); // Wait for the save operation
    } finally {
      this.setState({ isSaving: false });
      window.location.reload(); // Re-enable the button
    }
  };

  findCep = () => {
    if (this.state.camadaTemp == this.state.lotsTerritorializationId) {
      const request = findCep(this.state.lotData.postalCode);
      request.then((data) => {
        var end = data;

        let a = this.state.lotData;
        a["subpremisse"] = end.logradouro;
        this.setState({ lotData: a });
      });
    } else {
      const request = findCep(this.state.realtyData.postalCode);
      request.then((data) => {
        var end = data;

        let a = this.state.realtyData;
        a["subpremisse"] = end.logradouro;
        this.setState({ realtyData: a });
      });
    }
  };
  toggle = async (type, changeTerritorialization) => {
    if (!changeTerritorialization) {
      this.setState({
        modal: !this.state.modal,
      });
    }

    const typeLayerRequest =
      type !== null
        ? (await getTypeLayer(type)).data
        : (await getAllTypeTerritorializations()).data[0];
    this.setState({
      TerritorializationGeometry: typeLayerRequest.typeGeometry,
    });
    if (
      !this.state.territorializations.length > 0 ||
      this.state.previousTerritorialization != typeLayerRequest.id
    ) {
      this.setState({ previousTerritorialization: typeLayerRequest.id });
      let territorializationsRequest = fetchTerritorializations(
        typeLayerRequest.id
      );
      territorializationsRequest.then((response) => {
        this.setState({ territorializations: response.data });
      });
    }
  };

  checkOpt = (selecteds) => {
    const territoText = selecteds.map(({ name }) => name).join(", ");
    this.setState(
      { territoText },
      this.props.SetBaseTerritorialization([...selecteds])
    );
  };
  render() {
    var isEdit = this.props.SelectedTerritorialization != null ? true : false;
    var isLot = isEdit
      ? this.props.TerritorializationType == this.state.lotsTerritorializationId
      : this.state.camadaTemp == this.state.lotsTerritorializationId;
    return (
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <TerritorializationModalComponent
          territorializations={this.state.territorializations}
          openModal={this.state.modal}
          toggleCallback={this.toggle}
          checkOpt={this.checkOpt}
        />

        <Container fluid>
          <div className="row linha-form">
            <div className="col-6 responsive-field-filter-territorializacao-full">
              <select
                disabled={isEdit}
                className="form-control"
                onChange={(e) => {
                  this.handleChangeCamada(e);
                }}
                id="type"
              >
                {this.state.typeOptions.map((opt) => {
                  return (
                    <option
                      selected={
                        isEdit
                          ? this.props.TerritorializationType == opt.id
                          : this.state.camadaTemp === opt.id
                      }
                      value={opt.id}
                    >
                      {opt.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {this.state.toogleFields ? null : (
              <div className="col-6 responsive-field-filter-territorializacao-full">
                <input
                  type="text"
                  disabled={!this.props.isEdit}
                  value={
                    isEdit ? this.props.SelectedTerritorialization.name : null
                  }
                  className="form-control"
                  onChange={this.handleChangeNome.bind(this)}
                  placeholder="Nome"
                />
              </div>
            )}
            {this.state.toogleFields ||
            this.state.camadaTemp == "Rua" ||
            isLot ? (
              <div className="col-4 responsive-field-filter-territorializacao-full">
                <input
                  type="text"
                  disabled={!this.props.isEdit}
                  onChange={this.handleChangeCep.bind(this)}
                  onBlur={() => this.findCep()}
                  className="form-control"
                  placeholder="CEP"
                  value={
                    isLot
                      ? this.state.lotData.postalCode
                      : this.state.realtyData.postalCode
                  }
                />
              </div>
            ) : null}

            {this.state.toogleFields || isLot ? (
              <div className="col-4 responsive-field-filter-territorializacao-full">
                <input
                  type="text"
                  disabled={!this.props.isEdit}
                  className="form-control"
                  onChange={this.handleChangeRua.bind(this)}
                  placeholder="Rua"
                  value={
                    isLot
                      ? this.state.lotData.subpremisse
                      : this.state.realtyData.subpremisse
                  }
                />
              </div>
            ) : null}
          </div>

          <div className="row linha-form">
            {this.state.toogleFields || isLot ? (
              <div className="col-4 responsive-field-filter-territorializacao-full">
                <input
                  type="text"
                  disabled={!this.props.isEdit}
                  onChange={this.handleChangeNumero.bind(this)}
                  className="form-control"
                  placeholder="Número"
                  value={
                    isLot
                      ? this.state.lotData.number
                      : this.state.realtyData.number
                  }
                />
              </div>
            ) : null}
            {isLot ? (
              <div className="col-4 responsive-field-filter-territorializacao-full">
                <input
                  type="text"
                  disabled={!this.props.isEdit}
                  onChange={this.handleChangeComplemento.bind(this)}
                  className="form-control"
                  placeholder="Complemento"
                  value={this.state.lotData.complement}
                />
              </div>
            ) : null}
            {isLot ? (
              <div className="col-4 responsive-field-filter-territorializacao-full">
                <input
                  type="text"
                  disabled={!this.props.isEdit}
                  onChange={this.handleChangeSequencial.bind(this)}
                  className="form-control"
                  placeholder="Sequencial"
                  value={this.state.lotData.sequence}
                />
              </div>
            ) : null}
            {this.state.toogleFields ? (
              <div className="col-4 responsive-field-filter-territorializacao-full">
                <input
                  type="text"
                  disabled={!this.props.isEdit}
                  className="form-control"
                  placeholder="Economia"
                  value={this.state.realtyData.economy}
                  onChange={this.handleChangeEconomia.bind(this)}
                />
              </div>
            ) : null}

            {this.state.toogleFields ? (
              <div className="col-4 responsive-field-filter-territorializacao-full">
                <select disabled={!this.props.isEdit} className="form-control">
                  <option>Tipo de imóvel</option>
                  <option>Residência</option>
                  <option>Comércio</option>
                  <option>Órgão público</option>
                  <option>Terreno baldío</option>
                  <option>Via pública</option>
                  <option>Ponto estratégico</option>
                  <option>Outros</option>
                </select>
              </div>
            ) : null}
          </div>
          {this.state.toogleFields ? null : (
            <>
              <div className="row linha-form">
                <div className="col-6 responsive-field-filter-territorializacao-full">
                  <button
                    className="btn btn-primary responsive-button-territorializacao"
                    onClick={() => this.toggle(null, false)}
                  >
                    Selecionar territorialização base
                  </button>
                </div>
                {this.props.SelectedTerritorialization !== null ? (
                  <div className="col-6 responsive-field-filter-territorializacao-full">
                    <button
                      className="float-right btn btn-primary"
                      onClick={this.props.exportTerritorializations}
                    >
                      <i
                        class="fa fa-download"
                        aria-hidden="true"
                        style={{ paddingRight: 10 }}
                      />
                      Exportar Shapes
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="row linha-form">
                <div className="col-12 responsive-field-filter-territorializacao-full">
                  <span style={{ color: "white" }}>
                    Territorialização selecionada: {this.state.territoText}
                  </span>
                </div>
              </div>
            </>
          )}
          {this.props.SelectedTerritorialization == null ? (
            <>
              <div className="row linha-form">
                <div className="col-6 responsive-field-filter-territorializacao-medium">
                  <Link to="/admin/territorializacao">
                    <button className="form-control">Cancelar</button>
                  </Link>
                </div>

                <div className="col-6 responsive-field-filter-territorializacao-medium">
                  <button
                    className="btn btn-primary"
                    onClick={this.save}
                    disabled={this.state.isSaving} // Disable the button based on isSaving state
                  >
                    Salvar
                  </button>
                </div>

                {/* <div className="col-4 responsive-field-filter-territorializacao-full">
                            <Link to="/admin/NovaTerritorializacao">
                                <button className="float-right btn btn-default">Atualizar página</button>
                            </Link>
                        </div>                          */}
              </div>
            </>
          ) : (
            ""
          )}
        </Container>
      </div>
    );
  }
}

export default FiltroNovaTerrializacao;
