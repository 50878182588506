import React from "react";
import { Link } from "react-router-dom";
import "../../../../assets/css/navbar.css";

// reactstrap components
import {
    Container,
} from "reactstrap";
import { checkPermissionComponent } from "../../../../services/utils/permission";
import "./InventoryMovement.css";
import { getInventoryPlaces } from "../../../../services/api/Itens";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { getUserData } from "../../../../services/utils/auth";
import {checkShowBiovec} from "../../../../services/utils/Inventory/checkShowBiovec";
import { filterInventoryPlaces } from "../../../../services/utils/Inventory/checkShowBiovec";
import { sortInventoryPlaces } from "../../../../services/utils/Inventory/checkShowBiovec";

class FilterInventoryMovementRecord extends React.Component {
    state ={
        filterName:"",
        filterModule:"",
        filterModifierName:"",
        filterEmployeeName:"",
        filterDateStart:"",
        filterDateEnd:"",
        showBiovec: false
    }

    componentDidMount() {
        var showBiovec =checkShowBiovec();
        
        this.setState({showBiovec})
        
        getInventoryPlaces().then((response) => {
            this.setState({placesOptions: response.data})
        })
    }

    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
      };
    render() {
        let filteredMovementRecord2 = this.props.Movement;
        let filteredMovementRecord = filteredMovementRecord2.filter(
            (Record)=>{
                var hasStocable = false;
                Record.stocables.map(stocable=>{
                    if(stocable.name.toLowerCase().indexOf(this.state.filterName.toLowerCase()) !== -1){
                            hasStocable = true;
                    }
                })

                return hasStocable && 
                            Record.WhoModified.toLowerCase().indexOf(
                                this.state.filterModifierName.toLowerCase()) !== -1 
                        &&
                            Record.WhoMoved.toLowerCase().indexOf(
                                this.state.filterEmployeeName.toLowerCase()) !== -1 && ((this.state.filterDateStart <= Record.returnDate && this.state.filterDateEnd === "")||
                                (this.state.filterDateStart === "" && this.state.filterDateEnd >= Record.returnDate)||
                                (this.state.filterDateStart <= Record.returnDate && this.state.filterDateEnd >= Record.returnDate)) 
                        && 
                        (
                            (
                                this.state.inventoryPlace === "" || 
                                Record.place &&
                                this.state.inventoryPlace == Record.place.id
                            )
                            ||
                            (
                                Record.originPlace &&
                                this.state.inventoryPlace == Record.originPlace.id
                            )
                        )
                            
            }
        );

        // Data
        // Tipo de movimentação
        // Local de armazenamento
        // Local de destino
        // Nome
        // Lote
        // Quantidade
        // Ordem de fornecimento
        // Registrador
        // Funcionário
        // Pendência   

        const optionsColumns = [
            {
                "label": "Data",
                "value": "Data"
            },
            {
                "label": "Tipo de movimentação",
                "value": "Tipo de movimentação"
            },
            {
                "label": "Local de armazenamento",
                "value": "Local de armazenamento"
            },
            {
                "label": "Local de destino",
                "value": "Local de destino"
            },
            {
                "label": "Nome",
                "value": "Nome"
            },
            {
                "label": "Lote",
                "value": "Lote"
            },
            {
                "label": "Tipo",
                "value": "Tipo"
            },
            {
                "label": "Quantidade",
                "value": "Quantidade"
            },
            {
                "label": "Ordem de fornecimento",
                "value": "Ordem de fornecimento"
            },
            {
                "label": "Registrador",
                "value": "Registrador"
            },
            {
                "label": "Funcionário",
                "value": "Funcionário"
            },
            {
                "label": "Pendência",
                "value": "Pendência"
            },
        ]
        
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">

                        <div className="row linha-form">
                         
                            <div className="col-4 responsive-filter-field-inventoryMovement-full">
                                <span className="h5 text-white">Nome do estocável</span>
                                <input type="text" name="filterName" onChange={this.handleChange} className="form-control" placeholder="Nome" />
                            </div>
                       
                        
                            <div className="col-4 responsive-filter-field-inventoryMovement-full">
                                <span className="h5 text-white">Nome do registrador</span> 
                                <input type="text" name="filterModifierName" onChange={this.handleChange} className="form-control" placeholder="Nome" />
                            </div>
                            <div className="col-4 responsive-filter-field-inventoryMovement-full">
                                <span className="h5 text-white">Nome do funcionário</span> 
                                <input type="text" name="filterEmployeeName" onChange={this.handleChange} className="form-control" placeholder="Nome" />
                            </div>
                        </div>
                        <div className="row linha-form">
                         
                            <div className="col-4 responsive-filter-field-inventoryMovement-medium">
                                <span className="h5 text-white">Período inicial</span>
                                <input type="date" name="filterDateStart" onChange={this.handleChange} className="form-control" />
                            </div>
                        
                            <div className="col-4 responsive-filter-field-inventoryMovement-medium">
                                <span className="h5 text-white">Período final</span> 
                                <input type="date" name="filterDateEnd" onChange={this.handleChange} className="form-control" />
                            </div>
                            <div className="col-4 responsive-filter-field-inventoryMovement-medium">
                                <span className="h5 text-white">Local de armazenagem</span> 
                                <select className="form-control"  name="inventoryPlace"  onChange={this.handleChange}  id="">
                                    <option value="">Selecione</option>
                                    {this.state.placesOptions && filterInventoryPlaces(sortInventoryPlaces(this.state.placesOptions), this.state.showBiovec)}
                                </select>
                            </div>
                            <div className="col-12 responsive-filter-field-inventoryMovement-medium">
                                <span className="h5 text-white">Colunas</span> 
                                <Select
                                    styles={{
                                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                                        menuPortal: styles => ({ ...styles, zIndex: 9999 })
                                    }}
                                    value={this.props.columsCondicional ? this.props.columsCondicional : optionsColumns}
                                    isMulti={true}
                                    placeholder={"Selecione"}
                                    isClearable={true}
                                    blurInputOnSelect={false}
                                    closeMenuOnSelect={false}
                                    allowSelectAll={true}
                                    allOption={{ label: "Todas", value: "*" }}
                                    onChange={this.props.handleChangeMultiSelect}
                                    options={optionsColumns}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 responsive-filter-field-inventoryMovement-full">
                                <button className="btn btn-primary" onClick={()=> this.props.parentCallback(filteredMovementRecord)}>Pesquisar</button>
                            </div>
                            {checkPermissionComponent("2af11e8b-f884-40c8-b3a2-360d1f1bb95d", "create") ?
                            <div className="col-4 responsive-filter-field-inventoryMovement-full">
                                <Link to="/admin/AddInventoryMovementRecord">
                                    <button className="float-right btn btn-default">Nova Movimentação</button>
                                </Link>
                            </div>
                            : null}
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FilterInventoryMovementRecord;