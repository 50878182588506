import React, { useState } from "react";

const returnFormula = (pl, type, terr_year = null) => {
    console.log("pl returnFormula", pl)
    var positives = pl.payload.positives;
    var eggs = pl.payload.eggs;
    var examinated = pl.payload.examinated;
    var vistories = pl.payload.vistories;
    var actives = pl.payload.actives;

    if(terr_year) {
        var positives = pl.payload["positives " + terr_year];
        var eggs = pl.payload["eggs " + terr_year];
        var examinated = pl.payload["examinated " + terr_year];
        var vistories = pl.payload["vistories " + terr_year];
        var actives = pl.payload["actives " + terr_year];
    }

    if(type == "IPO" || type == "IPL") {
        return `${positives}/${examinated} x 100`;
    } else if(type == "IDO" || type == "IDL") {
        return `${eggs}/${positives}`;
    } else if(type == "IMO" || type == "IML") {
        return `${eggs}/${examinated}`;
    } else if(type == "IMA") {
        return `${eggs}/${examinated}`;
    } else if(type == "IPA") {
        return `${positives}/${vistories} x 100`;
    } else if(type == "IDA") {
        return `${eggs}/${positives}`;
    }  else if (type == "Pendents") {
        return `(${vistories}/${actives}) x 100`;
    }

    
}

const CustomTooltip = ({ active, payload, label, type}) => {
    var formula = "";

    if(type == "IPO") {
        formula = "Armadilhas diagnosticadas positivas / Armadilhas diagnosticadas x 100";
    } else if (type == "IDO") {
        formula = "Número de ovos / Armadilhas diagnosticadas positivas";
    } else if (type == "IMO") {
        formula = "Ovos / Armadilhas diagnosticadas";
    } else if(type == "IPL") {
        formula = "Armadilhas diagnosticadas positivas / Armadilhas diagnosticadas x 100";
    } else if (type == "IDL") {
        formula = "Número de larvas / Armadilhas diagnosticadas positivas";
    } else if (type == "IML") {
        formula = "Larvas / Armadilhas diagnosticadas";
    } else if (type == "IMA") {
        formula = "Mosquitos / Armadilhas diagnosticadas";
    } else if (type == "IPA") {
        formula = "Armadilhas diagnosticadas positivas / Armadilhas vistoriadas x 100";
    } else if (type == "IDA") {
        formula = "Número de mosquitos / Armadilhas diagnosticadas positivas";
    } else if (type == "Pendents") {
        formula = "(Armadilhas vistoriadas / Armadilhas instaladas) x 100";
    }

    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: "#ffffff", border: "1px solid black", padding: "5px" }}>
                <p className="label">{`${label}`}</p>
                <p>
                    {formula}
                </p>
                <p>
                    {payload.map((pl, index) => {
                        var parameter = pl.name;
                        var parametersArray = pl.name ? pl.name.split(" ") : null;
                        var terr_year = parametersArray && parametersArray[1];
                        var value = pl.value;

                        if (type == "IDO" || type == "IDA") {
                            value = Math.round(pl.value);
                        } 

                        return (
                            <>
                                {
                                    index > 0 
                                    ? 
                                    <br/>
                                    :
                                    <></>
                                }
                                <span className="intro" style={{ color: "red", fontWeight: "600" }}>
                                    {`${parameter}: ${value}`} ({returnFormula(pl, type, terr_year)})
                                </span> 
                            </>
                        )
                    })}
                </p>
            </div>
        )
    }
  
    return null;
};

export default CustomTooltip;