import React from "react";
import { Accordion, AccordionContent, AccordionHeader, AccordionItem } from "../../../../components/Accordion/Accordion";
import './AddSampleResultBody.css';
import IdentificationResultNew from "../CardsResult/IdentificationResultNew";
import ClassificationResultNew from "../CardsResult/ClassificationResultNew";
import DiagnosisResultNew from "../CardsResult/DiagnosisResultNew";
import LoadingSpin from "react-loading-spin";
import { fetchAnimalGenres, fetchAnimalGroups, fetchAnimalSpecies, fetchGenreVirusType } from "../../../../services/api/AnimalGroups";

export const AddSampleResultBody = ({
  samplesGroup, 
  setSamplesData,
  wasFilteredSamplesData,
  setWasFilteredSamplesData,
  isLoadingSampleInformations,
  setIsLoadingSampleInformations,
  handleChangeArrayData, 
  isDisableOrganization,
  sampleAnalysisInformations,
  isPossibleDiagnosis,
  sampleNumber
}) => {
    const [firstSample, setFirstSample] = React.useState();
    const options =[
      { name: "Stage", label: "Ovo", value: "Ovo" },
      { name: "Stage", label: "Larva", value: "Larva" },
      { name: "Stage", label: "Ninfa", value: "Ninfa" },
      { name: "Stage", label: "Pupa", value: "Pupa" },
      { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
      { name: "Stage", label: "Filhote", value: "Filhote" },
      { name: "Stage", label: "Jovem", value: "Jovem" },
      { name: "Stage", label: "Adulto", value: "Adulto" }
    ];
    const groupedOptions = [
      {
        label: "Invertebrados",
        options: [
          { name: "Stage", label: "Ovo", value: "Ovo" },
          { name: "Stage", label: "Larva", value: "Larva" },
          { name: "Stage", label: "Ninfa", value: "Ninfa" },
          { name: "Stage", label: "Pupa", value: "Pupa" },
          { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
        ]
      },
      { label: "Vertebrados", 
        options: [
          { name: "Stage", label: "Filhote", value: "Filhote" },
          { name: "Stage", label: "Jovem", value: "Jovem" },
          { name: "Stage", label: "Adulto", value: "Adulto" }
        ] 
      }
    ];
    const groupedInvertebratesOptions = [
      {
        label: "Invertebrados",
        options: [
          { name: "Stage", label: "Ovo", value: "Ovo" },
          { name: "Stage", label: "Larva", value: "Larva" },
          { name: "Stage", label: "Pupa", value: "Pupa" },
          { name: "Stage", label: "Adulto", value: "Mosquito Adulto" },
        ]
      }
    ];
    const sampleClassification =  [{
      sampleClassificationId:"00000000-0000-0000-0000-000000000000",
      LaboratoryId:"00000000-0000-0000-0000-000000000000",
      externSampleNumber:"",
      description:"",
      exams:[{
        examsHistoryId: "00000000-0000-0000-0000-000000000000",
        examId:"00000000-0000-0000-0000-000000000000",
        techniques:[],
        techniqueId:"00000000-0000-0000-0000-000000000000",
      }],
    }];
    const sampleDiagnosis = [{
      SampleResultId:"00000000-0000-0000-0000-000000000000",
      LaboratoryId:"00000000-0000-0000-0000-000000000000",
      externSampleNumber:"",
      description:"",
      exams:[{
        examDiagnosticId: "00000000-0000-0000-0000-000000000000",
        examId:"00000000-0000-0000-0000-000000000000",
        techniques:[],
        techniqueId:"00000000-0000-0000-0000-000000000000",
      }],
      diagnostic:"00000000-0000-0000-0000-000000000000",
      result:null,
    }];
    const sampleIdentifications = [{
      SampleIdentificationId:"00000000-0000-0000-0000-000000000000",
      Gender:"",
      Stage:"",
      Quantity:0,
      QuantityAlive:0,
      QuantityDead:0,
      Show: true
    }];

    const isPick = firstSample && firstSample.typeMaterial === "Palhetas";

    React.useEffect(() => {
      fetchSampleInformations();
    }, [wasFilteredSamplesData])

    React.useEffect(() => {
      if(isPick) {
        handleChangeArrayData("isAccordionOpen", true, 0, 0)
      } 
    }, [firstSample])

    const styles = {
      accordionContent: {
        padding: "1rem 1.5rem",
        fontSize: ".9rem",
        borderBottom: "1px solid #f0f1f3",
      }
    };

    const createSampleGroup = () => {
      const samplesGroupAux = [...samplesGroup]

      var sampleInformations = {
        "isMainSampleResult": true,
        "mainSampleResultId": "00000000-0000-0000-0000-000000000000",
        "principalSampleId": "00000000-0000-0000-0000-000000000000",
        "isSecondarySampleResult": false,
        "isSplitIntoAliquots": true,
        "aliquotNumber": JSON.stringify(samplesGroup.length + 1),
        "containsOtherResults": false,
        "resultNumber": "",
        "associatedSampleResultId": "00000000-0000-0000-0000-000000000000"
      }

      var sampleResults = [createBaseSampleObject(true, 999)];

      var firstSample = samplesGroupAux[0].sampleResults[0];

      firstSample.sampleExtraInformations.aliquotNumber = "1";
      firstSample.sampleExtraInformations.isSplitIntoAliquots = true;

      samplesGroupAux.push({
        sampleInformations,
        sampleResults
      })

      setSamplesData(samplesGroupAux);
    }

    const createBaseSampleObject = (post = false, sampleGroupIndex = 999) => {
      var sampleExtraInformations;

      if(sampleGroupIndex != 999) { //Não é para criação de um novo grupo/alíquota
        if(!samplesGroup[sampleGroupIndex].sampleResults[0].sampleExtraInformations) {
          var mainSampleExtraInformations = {
            "isMainSampleResult": true,
            "mainSampleResultId": "00000000-0000-0000-0000-000000000000",
            "principalSampleId": "00000000-0000-0000-0000-000000000000",
            "isSecondarySampleResult": false,
            "isSplitIntoAliquots": samplesGroup.length > 0 ? true : false,
            "aliquotNumber": samplesGroup.length > 0 ?
                        JSON.stringify(sampleGroupIndex + 1) : "",
            "containsOtherResults": true,
            "resultNumber": "1"
          }
  
          samplesGroup[sampleGroupIndex].sampleInformations = mainSampleExtraInformations;
          samplesGroup[sampleGroupIndex].sampleResults[0].sampleExtraInformations = mainSampleExtraInformations;
        } 
  
        sampleExtraInformations = {
          "isMainSampleResult": false,
          "mainSampleResultId": samplesGroup[sampleGroupIndex].sampleResults[0].id,
          "principalSampleId": samplesGroup[0].sampleResults[0].id,
          "isSecondarySampleResult": true,
          "isSplitIntoAliquots": samplesGroup.length > 0 ? true : false,
          "aliquotNumber": JSON.stringify(samplesGroup[sampleGroupIndex].sampleResults[0].sampleExtraInformations.aliquotNumber),
          "containsOtherResults": true,
          "resultNumber": JSON.stringify(samplesGroup[sampleGroupIndex].sampleResults.length + 1),
          "associatedSampleResultId": samplesGroup[sampleGroupIndex].sampleResults[0].id
        }
  
        if(samplesGroup[sampleGroupIndex].sampleResults.length == 1) {
          samplesGroup[sampleGroupIndex].sampleResults[0].sampleExtraInformations.resultNumber = "1";
          samplesGroup[sampleGroupIndex].sampleResults[0].sampleExtraInformations.containsOtherResults = true;
        }
      } else { // Criação de uma nova alíquota
        sampleExtraInformations = {
          "isMainSampleResult": true,
          "mainSampleResultId": "00000000-0000-0000-0000-000000000000",
          "principalSampleId": "00000000-0000-0000-0000-000000000000",
          "isSecondarySampleResult": false,
          "isSplitIntoAliquots": true,
          "aliquotNumber": JSON.stringify(samplesGroup.length + 1),
          "containsOtherResults": false,
          "resultNumber": "",
          "associatedSampleResultId": "00000000-0000-0000-0000-000000000000"
        }
      }

      let objData = {
        quantitySoma: 0,
        quantityAliveSoma: 0,
        quantityDeadSoma: 0,
        quantity: firstSample.quantity,
        quantityLiveMaterial: firstSample.quantityLiveMaterial,
        quantityDeadMaterial: firstSample.quantityDeadMaterial,
        sampleDataNumber: sampleNumber,
        number: sampleNumber,
        sampleResults: [],
        sampleConservationType:firstSample.sampleConservationType,
        organizationId: firstSample.organizationId,
        sampleJustificationDisposalTypes:[],
        techniques:[],
        post: post,
        sampleExtraInformations,
        sampleClassification,
        sampleDiagnosis,
        sampleIdentifications,
        animalTypeGroups:[],
        animalGroups: [],
        collectedBy: firstSample.collectedBy,
        dateCollected: firstSample.dateCollected,
        demandId: firstSample.demandId,
        animalGenres:[],
        animalSpecies:[],
        selectedAnimalTypeGroup: firstSample.selectedAnimalTypeGroup,
        selectedAnimalGroup:firstSample.selectedAnimalGroup,
        selectedAnimalGenre:'00000000-0000-0000-0000-000000000000',
        selectedAnimalSpecie:'00000000-0000-0000-0000-000000000000',
        selectedVirusType: '00000000-0000-0000-0000-000000000000',
        LaboratoryId:'',
        programs: [],
        selectedProgram: firstSample.selectedAnimalGroup,
        exams:[],
        identificationsExams: [],
        diagnosticsExams: [],
        identifications: [],
        Demand:null,
        Trap:null,
        trapId:firstSample.trapId,
        trapHistoryId:firstSample.trapHistoryId,
        Laboratories:[],
        ConservationTypes:[],
        JustificationDisposalTypes:[],
        isDisableOrganization: false
      };
      return objData;
    }

    const loadSampleCreatedInformations = async (firstSample) => {
      let copyOfFirstSample = JSON.parse(JSON.stringify(firstSample))

      const sampleExtraInformations = {
        isMainSampleResult: true,
        mainSampleResultId: copyOfFirstSample.id,
        principalSampleId: copyOfFirstSample.id,
        isSecondarySampleResult: false,
        isSplitIntoAliquots: false,
        aliquotNumber: "",
        containsOtherResults: false,
        resultNumber: ""
      };
    
      let updatedSample = {
        ...copyOfFirstSample,
        sampleExtraInformations,
        sampleIdentifications: copyOfFirstSample.sampleIdentifications || [],
        sampleDiagnosis,
        sampleClassification,
        isAccordionOpen: true,
        update: true
      };
    
      const materialConfigs = {
        "Palhetas": {
          conservationType: "ecfa0aa7-e7e6-409d-8c94-d94c06b909bc", // in natura
          typeGroup: "84f3cd9c-9894-4021-9355-2d1b2ed15e0d", // Vetores
          animalGroup: "178886c4-a41c-4d15-ac3c-a58806bb8df9", // Mosquitos
          genre: "f316f957-8348-46ce-b215-13ed1d2f299d", // Aedes
          stage: "Ovo"
        },
        "Mosquito Adulto": {
          conservationType: "ecfa0aa7-e7e6-409d-8c94-d94c06b909bc", // in natura
          typeGroup: "84f3cd9c-9894-4021-9355-2d1b2ed15e0d", // Vetores
          animalGroup: "178886c4-a41c-4d15-ac3c-a58806bb8df9", // Mosquitos
          genre: "00000000-0000-0000-0000-000000000000", // A pessoa terá que definir
          stage: "Mosquito Adulto"
        }
      };
    
      const config = materialConfigs[copyOfFirstSample.typeMaterial];

      if (config) {
        updatedSample = {
          ...updatedSample,
          sampleIdentifications: [
            {
              ...updatedSample.sampleIdentifications[0],
              stage: config.stage
            }
          ],
          sampleConservationType: config.conservationType,
          selectedAnimalTypeGroup: config.typeGroup,
          selectedAnimalGroup: config.animalGroup,
          selectedAnimalGenre: config.genre
        };

        const [groupsResponse, genresResponse] = await Promise.all([
          fetchAnimalGroups(config.typeGroup),
          fetchAnimalGenres(config.animalGroup)
        ]);

        updatedSample.animalGroups = groupsResponse.data.map(({ name, id }) => ({ label: name, value: id }));
        updatedSample.animalGenres = genresResponse.data.map(({ name, id }) => ({ label: name, value: id }));

        if (config.shouldFetchSpeciesAndViruses) {
          updatedSample.selectedAnimalGenre = config.genre;

          const [speciesResponse, virusesResponse] = await Promise.all([
            fetchAnimalSpecies(config.genre),
            fetchGenreVirusType(config.genre)
          ]);

          updatedSample.animalSpecies = speciesResponse.data.map(({ name, id }) => ({ label: name, value: id }));
          updatedSample.virusTypes = virusesResponse.data.map(({ name, id }) => ({ label: name, value: id }));
        }
      }

      return updatedSample;
    };

    const fetchSampleInformations = async () => {
      if (
        wasFilteredSamplesData &&
        samplesGroup &&
        samplesGroup.length > 0 &&
        samplesGroup[0].sampleResults &&
        samplesGroup[0].sampleResults.length > 0
      ) {
        let samplesGroupAux = [...samplesGroup];
        let firstSampleAux = samplesGroupAux[0].sampleResults[0];
  
        if (firstSampleAux.status === "Created") {
          const firstSampleCreated = await loadSampleCreatedInformations(firstSampleAux);
          samplesGroupAux[0].sampleResults[0] = firstSampleCreated;

          setFirstSample(firstSampleCreated);
          setSamplesData(samplesGroupAux);
        } else {
          setFirstSample(firstSampleAux);
          setSamplesData(samplesGroupAux);
        }
  
        setWasFilteredSamplesData(false);
        setIsLoadingSampleInformations(false);
      }
    };

    const handleAccordionChange = (sampleGroupIndex, sampleResultIndex, value) => {
      handleChangeArrayData("isAccordionOpen", value, sampleGroupIndex, sampleResultIndex)
    }

    return (
      isLoadingSampleInformations
      ?
      <div className="container-loading-sample-result-body">
        <LoadingSpin primaryColor="#fff" size={17} /> Carregando
      </div>
      : samplesGroup && samplesGroup.length > 0
      ?
      <>
        {samplesGroup && firstSample && samplesGroup.map(
          (
            {
              sampleResults,
              sampleInformations
            },
            sampleGroupIndex
          ) => {
            return (
              <div className="container-sample-group bg-gradient-light">
                <div className="header-sample-group">
                  <span className="header-text-sample-group">
                    <i className='fa fa-vial'></i> Amostra Nº {firstSample.number}
                    {
                      sampleInformations && sampleInformations.isSplitIntoAliquots
                      ?
                      ` Alíquota ${sampleInformations.aliquotNumber}`
                      :
                      null
                    }
                  </span>
                </div>
                <div className="content-sample-group">
                  <Accordion>
                    {sampleResults.map((
                      {
                        number, 
                        isAccordionOpen, 
                        sampleExtraInformations, 
                        sampleIdentifications,
                        sampleClassification,
                        selectedAnimalGenre,
                        selectedAnimalGroup,
                        animalSpecies,
                        selectedAnimalSpecie,
                        sampleDiagnosis,
                        selectedVirusType,
                        virusTypes
                      }, 
                      sampleResultIndex
                    ) => (
                      <AccordionItem style={{ padding: "0px" }}>
                        <AccordionHeader
                          isOpen={isAccordionOpen}
                          onClick={() =>
                            handleAccordionChange(
                              sampleGroupIndex,
                              sampleResultIndex,
                              !isAccordionOpen
                            )
                          }
                        >
                          <span className="h3 mb-0">
                            <i class="fa fa-flask"></i> Resultado 
                            {
                              sampleExtraInformations && sampleExtraInformations.resultNumber
                              ?
                              ` ${sampleExtraInformations.resultNumber}`
                              :
                              " Principal"
                            }
                          </span>
                        </AccordionHeader>
                        <AccordionContent
                          isOpen={isAccordionOpen}
                          style={styles.accordionContent}
                        >
                          <IdentificationResultNew
                            sampleIdentifications={sampleIdentifications} 
                            options={options} 
                            isDisableOrganization={isDisableOrganization}
                            groupedInvertebratesOptions={groupedInvertebratesOptions}
                            groupedOptions={groupedOptions}
                            sampleGroupIndex={sampleGroupIndex}
                            sampleResultIndex={sampleResultIndex}
                            handleChangeArrayData={handleChangeArrayData.bind(this)}
                          />
                          <ClassificationResultNew
                            sampleClassification={sampleClassification}
                            identificationsExams={sampleAnalysisInformations.identificationsExams}
                            diagnosticsExams={sampleAnalysisInformations.diagnosticsExams}
                            firstSample={firstSample}
                            selectedAnimalGenre={selectedAnimalGenre}
                            selectedAnimalGroup={selectedAnimalGroup}
                            animalSpecies={animalSpecies}
                            selectedAnimalSpecie={selectedAnimalSpecie}
                            Laboratories={sampleAnalysisInformations.allLaboratories}
                            techniques={sampleAnalysisInformations.techniques}
                            handleChangeArrayData={handleChangeArrayData.bind(this)}
                            sampleGroupIndex={sampleGroupIndex}
                            sampleResultIndex={sampleResultIndex}
                          />
                          {isDisableOrganization 
                          ? 
                            <></> 
                          :
                            <DiagnosisResultNew
                              sampleDiagnosis={sampleDiagnosis}
                              sampleGroupIndex={sampleGroupIndex}
                              sampleResultIndex={sampleResultIndex}
                              Laboratories={sampleAnalysisInformations.allLaboratories}
                              isPossibleDiagnosis={isPossibleDiagnosis}
                              diagnosticsExams={sampleAnalysisInformations.diagnosticsExams}
                              selectedVirusType={selectedVirusType}
                              virusTypes={virusTypes}
                              selectedAnimalGenre={selectedAnimalGenre}
                              techniques={sampleAnalysisInformations.techniques}
                              handleChangeArrayData={handleChangeArrayData.bind(this)}
                            />
                          }
                        </AccordionContent>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
                {
                  !isPick
                  ?
                  <div>
                    <div className="col-12  responsive-form-field-result-full p-3">
                      <button className="btn btn-secondary responsive-button-result" disabled={!isPossibleDiagnosis} onClick={() => {
                        const samplesGroupAux = [...samplesGroup]

                        samplesGroupAux[sampleGroupIndex].sampleResults.push(createBaseSampleObject(true, sampleGroupIndex))

                        setSamplesData(samplesGroupAux)
                      }}>
                          + Adicionar resultado
                      </button>
                    </div>
                  </div>
                  :
                  <></>
                }
              </div>
            )
          }
        )}

        {
          !isPick
          ?
          <button className="btn btn-info responsive-button-result mt-4" disabled={!isPossibleDiagnosis} onClick={() => {
            createSampleGroup();
          }}>
              <i className='fa fa-sitemap'></i> Adicionar alíquota
          </button>
          :
          <></>
        }
        
      </>
      : 
      <></>
    );
}