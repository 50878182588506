import React from "react";
import './AddSampleResultNew.css';
import { getUserData } from "../../../../services/utils/auth";
import { fetchAllSamples, postSampleGroups } from "../../../../services/api/Sample";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { getBaseSampleIdentifications, getSampleObj } from "./sampleObj";
import { getAllLaboratories } from "../../../../services/api/Laboratory";
import { getDiagnosticsExam, getIndentificationsExam } from "../../../../services/api/Exam";
import { fetchPrograms } from "../../../../services/api/Program";
import { getConservationTypes } from "../../../../services/api/ConservationType";
import { fetchAnimalGenres, fetchAnimalGroups, fetchAnimalSpecies, fetchAnimalTypeGroups, fetchGenreVirusType } from "../../../../services/api/AnimalGroups";
import { getJustificationDisposalTypes } from "../../../../services/api/JustificationDispositionType";
import { fetchIdentifications } from "../../../../services/api/Identification";
import { getTechniques } from "../../../../services/api/Technique";
import { fetchDemand } from "../../../../services/api/Demand";
import { getTrapById, getTrapsTypes } from "../../../../services/api/Trap";
import { getUser } from "../../../../services/api/Users";
import { getPerson } from "../../../../services/api/Person";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { InformationBlockAddSampleResult } from "./InformationBlockAddSampleResult";
import { AddSampleResultBody } from "./AddSampleResultBody";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { postContaOvos } from "../../../../services/api/ContaOvos";
import { DownloadCloud, Globe } from "lucide-react";

/*
  location: dados que vem da tela que fez o <Link />
*/
export const AddSampleResultNew = ({location}) => {
  const [sampleNumber, setSampleNumber] = React.useState();
  const [sampleNumberInput, setSampleNumberInput] = React.useState();
  const [sampleId, setSampleId] = React.useState();
  const [samplesData, setSamplesData] = React.useState();
  const [sampleAnalysisInformations, setSampleAnalysisInformations] = React.useState({
    allLaboratories: null,
    programs: null,
    identificationsExams: null,
    diagnosticsExams: null,
    identifications: null,
    techniques: null,
    conservationTypes: null,
    justificationDisposalTypes: null,
    animalTypeGroups: null
  });
  const [isDisableOrganization, setIsDisableOrganization] = React.useState();
  const [showResults, setShowResults] = React.useState();
  const [firstSample, setFirstSample] = React.useState();
  const [enableEditNumber, setEnableEditNumber] = React.useState();
  const [isPossibleDiagnosis, setIsPossibleDiagnosis] = React.useState(true);
  const [wasFilteredSamplesData, setWasFilteredSamplesData] = React.useState(false);
  const [isLoadingSampleInformations, setIsLoadingSampleInformations] = React.useState(false);
  const [sampleRelatedData, setSampleRelatedData] = React.useState({
    demand: null,
    trapType: null,
    trap: null,
    personName: null
  });

  React.useEffect(() => {
    findSample();
  }, [sampleNumber])

  React.useEffect(() => {
    if(samplesData  && samplesData.length > 0 && samplesData[0].sampleResults && samplesData[0].sampleResults) {
        setFirstSample(samplesData[0].sampleResults[0]);
    }
  }, [samplesData])
 
  React.useEffect(() => {
    fetchData();
    
    if(location.data != null) {
      var sampleNumber = location.data.number;
      var sampleId = location.data.id;

      setSampleNumber(sampleNumber);
      setSampleId(sampleId);
    }
  }, [])

  const fetchData = async () => {
    try {
      const [
        laboratoriesRes,
        programsRes,
        identificationsExamRes,
        diagnosticsExamRes,
        identificationsRes,
        techniquesRes,
        conservationTypesRes,
        justificationDisposalTypesRes,
        animalTypeGroupsRes
      ] = await Promise.all([
        getAllLaboratories(),
        fetchPrograms(),
        getIndentificationsExam(),
        getDiagnosticsExam(),
        fetchIdentifications(),
        getTechniques(),
        getConservationTypes(),
        getJustificationDisposalTypes(),
        fetchAnimalTypeGroups()
      ]);

      const laboratoriesData = laboratoriesRes.data.map(lab => ({
        target: { name: "laboratoryId", value: lab.id },
        label: lab.name,
        value: lab.id
      }));

      const conservationTypes = conservationTypesRes.data.map(type => ({
        label: type.name,
        value: type.id
      }));

      const justificationDisposalTypes = justificationDisposalTypesRes.data.map(type => ({
        label: type.name,
        value: type.id
      }));

      const animalTypeGroups = animalTypeGroupsRes.data.map(typeGroup => ({
        label: typeGroup.name,
        value: typeGroup.id
      }));

      setSampleAnalysisInformations(prevState => ({
        ...prevState,
        allLaboratories: laboratoriesData,
        programs: programsRes.data,
        identificationsExams: identificationsExamRes.data,
        diagnosticsExams: diagnosticsExamRes.data,
        identifications: identificationsRes.data,
        techniques: techniquesRes.data,
        conservationTypes,
        justificationDisposalTypes,
        animalTypeGroups
      }));

    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  const findSample = async () => {
    if(sampleNumber) {
      setSampleNumberInput(sampleNumber);
      setIsLoadingSampleInformations(true);
      setSamplesData();

      try {
        const samples = await fetchAllSamples(sampleNumber, sampleId)

        if(samples.data && samples.data.length > 0) {
          setSamplesData(samples.data);
          
          await loadSampleData(samples.data);
        } else {
          throw new Error("Amostra não encontrada");
        }
      } catch(e) {
          toast.error("Erro: Amostra inexistente");
          setIsLoadingSampleInformations(false);
          console.error(e)
      } finally {
        setWasFilteredSamplesData(true);
      } 
    }
  }

  const handleChangeArrayData = (key, value, SampleGroupIndex = 0, SampleResultIndex = 0) => {
    let samplesDataAux = [...samplesData];

    samplesDataAux[SampleGroupIndex]["sampleResults"][SampleResultIndex]["update"] = true;
    samplesDataAux[SampleGroupIndex]["sampleResults"][SampleResultIndex][key] = value;


    setSamplesData(samplesDataAux);
  }

  const selectedTypeGroup = async (typeGroup, index = 0, sampleGroupIndex, sampleResultIndex) =>{
    const {data} = await fetchAnimalGroups(typeGroup);
    const groups = data.map(({name, id}) => ({label: name, value: id}))
    
    handleChangeArrayData("selectedAnimalTypeGroup", typeGroup, sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("selectedAnimalGroup", '00000000-0000-0000-0000-000000000000', sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("selectedAnimalGenre", '00000000-0000-0000-0000-000000000000', sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("selectedVirusType", '00000000-0000-0000-0000-000000000000', sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("selectedAnimalSpecie", '00000000-0000-0000-0000-000000000000', sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("animalGroups", groups, sampleGroupIndex, sampleResultIndex)
  }

  const selectedGroup = async (group, index = 0, sampleGroupIndex, sampleResultIndex) =>{
    const {data} = await fetchAnimalGenres(group);
    const genres = data.map(({name, id}) => ({ label: name, value: id }))

    handleChangeArrayData("selectedAnimalGroup", group, sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("selectedAnimalGenre", '00000000-0000-0000-0000-000000000000', sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("selectedVirusType", '00000000-0000-0000-0000-000000000000', sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("selectedAnimalSpecie", '00000000-0000-0000-0000-000000000000', sampleGroupIndex, sampleResultIndex)
    handleChangeArrayData("animalGenres", genres, sampleGroupIndex, sampleResultIndex)
  }

  const loadSampleData = async (samples) => { 
      var firstSampleAux = samples && samples[0]["sampleResults"] ? samples[0]["sampleResults"][0] : null;

      var sampleRelatedDataAux = {
        demand: null,
        trapTypes: null,
        trap: null,
        personName: null
      }

      if(firstSampleAux.status !== "Discarded" && firstSampleAux.status !== "Diagnostic not possible"){
        setShowResults(true);
        var responseDemand = await fetchDemand(firstSampleAux.demandId);
        sampleRelatedDataAux["demand"] = responseDemand.data;

        var trapTypesResponse = await getTrapsTypes();
        var trapTypes = trapTypesResponse.data;

        var responseTrap = await getTrapById(firstSampleAux.trapId);

        trapTypes.map(type=>{
          if(type.id == responseTrap.data.trapTypeId){
            responseTrap.data.trapTypeName = ARMADILHA_INFO[type.name].apelido;
            sampleRelatedDataAux["trap"] = responseTrap.data;
          }
        })
        
        var responseUser = await getUser(firstSampleAux.collectedBy)

        if(responseUser.data){
          let responsePerson = await getPerson(responseUser.data.personId);


          if(responsePerson.data){
            sampleRelatedDataAux["personName"] = responsePerson.data.name;
          }
        }
      }else{
        toast.error("Erro: A amostra está inviável ou foi descartada!");
      }

      setSampleRelatedData(sampleRelatedDataAux);
  }

  const addJustificationType = (e) => {
    var justifications = [];

    if(e !== null){
      e.map(justificationType=>{
        if(Object.keys(justificationType).length == 2) {
          justifications.push(justificationType);
        } else {
          justifications.push({"id":justificationType.value,"name":justificationType.label, "label":justificationType.label,"description":""});
        }
      })
    }

    handleChangeArrayData("sampleJustificationDisposalTypes",justifications)
  }

  const saveSamples = async () => {
    try  {
      await postSampleGroups(samplesData);
  
      toast.success("Amostra(s) inserida(s) com sucesso");
    } catch (e) {
      toast.error("Houve um erro ao enviar a amostra");
    }
      
    const samplesDataResults = samplesData[0].sampleResults;
    const hasOnlyOneSample = samplesDataResults[0] && samplesDataResults.length === 1;
    var organizationId = getUserData("organizationId");

    if(hasOnlyOneSample && samplesDataResults[0].typeMaterial === "Palhetas" && organizationId === "8e34767e-3673-4606-9e47-dd67edd2677d") {
      try {
        await postContaOvos(samplesDataResults[0], sampleRelatedData.trap.number)
        toast.success("Contagem inserida no Conta Ovos");
      } catch (e) {
        toast.error("Houve um erro ao enviar contagem ao Conta Ovos")
      }
    }
  }

  const findSamples = async () => {
    setSampleId();
    setSampleNumber(sampleNumberInput);
  }


  return (
    <>
      <div className="header bg-gradient-info pt-7">
        
      </div>
      <Container className="container-flex-result" fluid>
        <ToastContainer/>
        <div className="mt-6">
          <div className="row">
            <div className="col-4">
              <span className="sample-result-text">Número da Amostra</span>
              <input value={sampleNumberInput}  type="text" className="form-control" placeholder="Número da amostra" name="sampleNumber" onChange={ (e) => setSampleNumberInput(e.target.value) } />
            </div>
            <div className="col-4" style={{alignContent: "flex-end"}}>
              <button className="btn btn-info responsive-button-result" onClick={async () => await findSamples()}>Filtrar</button>
            </div>
          </div>

          

          {
            !isLoadingSampleInformations && !samplesData && wasFilteredSamplesData
            ?
            <div style={{display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", fontSize: 30, margin: 25}}>
              <i className='fa fa-vial'></i>
              <h2>Não foi possível encontrar os dados da amostra</h2>
            </div>
            :
            <>
              {
                firstSample
                ?
                  <>
                    {
                      firstSample.analysisByAPI
                      ?
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", fontSize: 30, margin: 25, gap: 10}}>
                          <DownloadCloud size={30}/>
                          <h2>O resultado desta amostra foi recebido pela integração com o Contador de Ovos</h2>
                        </div>
                      :
                        <></>
                    }
                    
                    <InformationBlockAddSampleResult
                      sampleRelatedData={sampleRelatedData}
                      sampleNumber={sampleNumber}
                      firstSample={firstSample}
                      enableEditNumber={enableEditNumber}
                      setSampleNumber={setSampleNumber}
                      setEnableEditNumber={setEnableEditNumber}
                      isLoadingSampleInformations={isLoadingSampleInformations}
                    />
                  </>
                :
                <></>
              }
              
              {
                  firstSample && 
                  sampleAnalysisInformations.conservationTypes &&
                  sampleAnalysisInformations.animalTypeGroups &&
                  sampleAnalysisInformations.justificationDisposalTypes
                ?
                <div className="row">
                  <div className="col-3 responsive-form-field-result-full">
                      <span className="sample-result-text">Amostra viável para análise?</span>
                      <Select
                        onChange={(e) => {setIsPossibleDiagnosis(e.value)}}    
                        defaultValue={{label:"Sim", value: true}}  
                        menuPortalTarget={document.body}
                        menuPlacement="auto"                                            
                        options={[
                          {label:"Sim", value: true},
                          {label:"Não", value: false}
                        ]} 
                      />
                  </div>
                  {isPossibleDiagnosis ? 
                    <>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="sample-result-text">Tipo de conservação</span>
                          <Select 
                            value = {sampleAnalysisInformations.conservationTypes.filter(ct => ct.value == firstSample.sampleConservationType)}
                            options={sampleAnalysisInformations.conservationTypes}
                            
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            onChange={(e) => {handleChangeArrayData("sampleConservationType", e.value)} }
                          />
                      </div>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="sample-result-text">Grupo de animais envolvidos</span>
                          <Select 
                            key={firstSample.selectedAnimalTypeGroup}
                            options={sampleAnalysisInformations.animalTypeGroups}
                            value={firstSample.selectedAnimalTypeGroup !== '' ? sampleAnalysisInformations.animalTypeGroups.filter(obj => obj.value === firstSample.selectedAnimalTypeGroup):null}
                            isClearable={true}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            isDisabled={firstSample.isDisableOrganization}
                            onChange={(e) => {
                              if(e !== null){ 
                                selectedTypeGroup(e.value)
                              } else if(!firstSample.isDisableOrganization){
                                selectedTypeGroup("00000000-0000-0000-0000-000000000000")
                              }
                            }}  
                          />
                      </div>
                      <div className="col-3 responsive-form-field-result-full">
                          <span className="sample-result-text">Animal</span>
                          <Select 
                            key={firstSample.selectedAnimalGroup}
                            options={firstSample.animalGroups} 
                            onChange={(e) => {
                              if(e !== null){ 
                                selectedGroup(e.value)
                              }
                              else{
                                selectedGroup("00000000-0000-0000-0000-000000000000")
                              }
                            }}
                            isClearable={true}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            value={firstSample.selectedAnimalGroup !== '' ? firstSample.animalGroups.filter(obj => obj.value === firstSample.selectedAnimalGroup)[0] : null}
                            isDisabled={isDisableOrganization || firstSample.selectedAnimalTypeGroup == '00000000-0000-0000-0000-000000000000'}
                          />
                      </div>

                    </>
                  :
                    <>
                        <div className="col-3 responsive-form-field-result-full">
                          <span className="h5">Justificativa de inviabilidade</span>
                          <Select 
                            options={
                              sampleAnalysisInformations.justificationDisposalTypes && sampleAnalysisInformations.justificationDisposalTypes.filter(jdt => {
                                var response = true;
                                
                                firstSample.listJustificationsDisposalTypes.map((sjdt => {
                                  if(sjdt.id == jdt.value) {
                                    response = false
                                  }
                                }))
                                return response;
                              })
                            } 
                            onChange={(e) => addJustificationType(e)}
                            value={firstSample.listJustificationsDisposalTypes}
                            isMulti
                          />
                        </div>
                      {firstSample.listJustificationsDisposalTypes.map(selectedJustificationType =>{
                        if(selectedJustificationType.name == "Outro"){
                          return(
                            <>
                              <div className="col-3 responsive-form-field-result-full">
                                <span className="h5">Descrição de justificativa</span>
                                <input className="form-control"  onChange={ (e) => selectedJustificationType.description = e.target.value} type="text" name="OtherDescription" id=""placeholder="Descrição"/>
                              </div>
                            </>                                
                          )

                        }                           
                      })}
                    </>
                  }
                  
                </div>
                :
                <></>
              }
            </>
          }

          {
            firstSample 
            ?
            <AddSampleResultBody
              firstSample={firstSample} 
              samplesGroup={samplesData}
              setSamplesData={setSamplesData}
              wasFilteredSamplesData={wasFilteredSamplesData}
              setWasFilteredSamplesData={setWasFilteredSamplesData}
              isLoadingSampleInformations={isLoadingSampleInformations}
              setIsLoadingSampleInformations={setIsLoadingSampleInformations}
              isDisableOrganization={isDisableOrganization}
              handleChangeArrayData={handleChangeArrayData.bind(this)}
              sampleAnalysisInformations={sampleAnalysisInformations}
              isPossibleDiagnosis={isPossibleDiagnosis}
              sampleNumber={sampleNumber}
            />
            :
            <>
            </>
          }

          {
            samplesData && samplesData.length > 0
            ?
            <div className="row mt-4">
              <div className="col-4 responsive-form-field-result-medium">
                  <Link to="/admin/amostrasColetadas">                                
                    <button className="form-control btn btn-warning responsive-button-result">Voltar</button>
                  </Link>
              </div>
              <div className="col-4 responsive-form-field-result-medium" >
                  <button className="btn btn-primary responsive-button-result" disabled={!showResults && isPossibleDiagnosis} onClick={async () => await saveSamples()}><i className='fa fa-save'></i> Salvar</button>
              </div>
            </div>
            :
            <></>
          }

          
        </div>
      </Container>
    </>
  );
}