import React from "react";

import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { ArrowLeftRight, Expand } from "lucide-react";

const VitecCardContext = React.createContext();

export const VitecCard = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  const toggleModal = () => setIsModalOpen((previousValue) => !previousValue);

  return (
    <VitecCardContext.Provider
      value={{
        isModalOpen,
        toggleModal,
        styles,
        screenHeight,
        contentToPrintRef,
      }}
    >
      <div ref={contentToPrintRef}>{children}</div>
    </VitecCardContext.Provider>
  );
};

export const VitecCardModal = ({ children, style, ...props }) => {
  const { isModalOpen, toggleModal, styles } =
    React.useContext(VitecCardContext);

  return (
    <Modal
      isOpen={isModalOpen}
      style={{ ...styles.fullScreenStyle, ...style }}
      toggle={toggleModal}
      {...props}
    >
      {children}
    </Modal>
  );
};

export const VitecCardModalHeader = ({ children, ...props }) => {
  const { toggleModal } = React.useContext(VitecCardContext);

  return (
    <ModalHeader toggle={toggleModal} {...props}>
      {children}
    </ModalHeader>
  );
};

export const VitecCardModalContent = ({ children, style, ...props }) => {
  const { screenHeight } = React.useContext(VitecCardContext);

  return (
    <ModalBody
      style={{ height: `${screenHeight - 150}px`, ...style }}
      {...props}
    >
      {children}
    </ModalBody>
  );
};

export const VitecCardModalFooter = ({ children, style, ...props }) => {
  const { styles } = React.useContext(VitecCardContext);

  return (
    <ModalFooter style={{ ...styles.cardFooter, ...style }} {...props}>
      {children}
    </ModalFooter>
  );
};

export const VitecCardDefaultView = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>;
};

export const VitecCardDefaultViewHeader = ({ children, ...props }) => {
  return <CardHeader {...props}>{children}</CardHeader>;
};

export const VitecCardDefaultViewContent = ({ children, ...props }) => {
  return <CardBody {...props}>{children}</CardBody>;
};

export const VitecCardDefaultViewFooter = ({ children, style, ...props }) => {
  return (
    <CardFooter
      {...props}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "16px",

        ...style,
      }}
    >
      <div>{children}</div>
      <img
        style={{ maxWidth: "100px", opacity: 0.5 }}
        src="/static/media/vitec.9bd71d52.png"
        alt="Logo do Vitec"
      />
    </CardFooter>
  );
};

export const VitecCardToggleModalButton = ({ children, style, ...props }) => {
  const { toggleModal } = React.useContext(VitecCardContext);

  const dateTimeNow = Date.now();
  const buttonId = `expanded-view-modal-${dateTimeNow}`;

  return (
    <>
      <ReactTooltip effect="solid" type="info" id={buttonId}>
        Expandir
      </ReactTooltip>
      <Button
        data-tip
        data-for={buttonId}
        style={{
          marginRight: "0px",
          marginLeft: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
        onClick={toggleModal}
        {...props}
      >
        <Expand size={17} />
      </Button>
    </>
  );
};

export const VitecCardGeneratedAt = ({ children, style, ...props }) => {
  return (
    <span
      style={{ fontSize: "13px", fontWeight: "normal", ...style }}
      {...props}
    >
      Gerado em: <br />
      {moment().format("DD/MM/YYYY HH:mm")}
    </span>
  );
};

export const VitecCardDownloadImageButton = ({
  children,
  imageFileName,
  style,
  showTooltip = true,
  ...props
}) => {
  const { contentToPrintRef } = React.useContext(VitecCardContext);

  const handleDownloadGraphicImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download =
        !imageFileName || imageFileName === ""
          ? `vitecImage.png`
          : `${imageFileName}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const dateTimeNow = Date.now();
  const buttonId = `download-view-${dateTimeNow}`;

  return (
    <>
      {showTooltip && (
        <ReactTooltip effect="solid" type="info" id={buttonId}>
          Baixar visualização
        </ReactTooltip>
      )}
      <Button
        data-tip
        data-for={buttonId}
        onClick={handleDownloadGraphicImage}
        style={{
          marginRight: "0px",
          marginLeft: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
        {...props}
      >
        <i className="fa fa-image" />
      </Button>
    </>
  );
};

export const VitecCardDownloadDataButton = ({
  children,
  onClick,
  style,
  ...props
}) => {
  const dateTimeNow = Date.now();
  const buttonId = `download-data-${dateTimeNow}`;

  return (
    <>
      <ReactTooltip effect="solid" type="info" id={buttonId}>
        Baixar informações
      </ReactTooltip>
      <Button
        style={{
          marginRight: "0px",
          marginLeft: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
        data-tip
        data-for={buttonId}
        onClick={onClick}
        {...props}
      >
        <i className="fa fa-download" />
      </Button>
    </>
  );
};

export const VitecCardSwitchButton = ({
  children,
  onClick,
  style,
  tooltipText,
  ...props
}) => {
  const dateTimeNow = Date.now();
  const buttonId = `switch-button-${dateTimeNow}`;

  return (
    <>
      <ReactTooltip effect="solid" type="info" id={buttonId}>
        {tooltipText}
      </ReactTooltip>
      <Button
        data-tip
        data-for={buttonId}
        style={{
          marginRight: "0px",
          marginLeft: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
        onClick={onClick}
        {...props}
      >
        <ArrowLeftRight size={17} />
      </Button>
    </>
  );
};
