import React, { useState, useEffect } from "react";
import "./../../../assets/css/navbar.css";
// reactstrap components
import {
    Container,
} from "reactstrap";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "../../../assets/css/style.css";
import DashboardEntomologico from "../../DashboardEntomologico";
import LoadingSpin from "react-loading-spin";
import DashboardDepositos from "./DashboardDepositos";
import { fetchEmployees } from "../../../services/api/Employee";
import { getUserByEmployeeId, getUserEmployeeDataByOrganization } from "../../../services/api/Users";
import { fetchDemands } from "../../../services/api/Demand";
import { getTeam } from "../../../services/api/Team";
import { fetchTerritorializations, getAllTypeTerritorializations } from "../../../services/api/territorialization";
import "./MainDashboard.css";

const MainDashboard = () => {
    const [demandList, setDemandList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [territorializationList, setTerritorializationList] = useState([]);


    useEffect(() => {
        const reqUserEmployees = getUserEmployeeDataByOrganization();
        
        reqUserEmployees.then(async response => {
            const employeeListAux = new Array({
                value: "00000000-0000-0000-0000-000000000000",
                label: "Selecione"
            });

            var userEmployeeData = response.data;

            userEmployeeData.map((userEmployee) => {
                employeeListAux.push({
                    value: userEmployee.userId,
                    label: userEmployee.employeeName
                })
            })

            setEmployeeList(employeeListAux);

            const reqTeams = getTeam();
            reqTeams.then(async responseTeams => {
                let teamList = [
                    {
                        value: "00000000-0000-0000-0000-000000000000",
                        label: "Selecione"
                    }
                ]

                for (const team of responseTeams.data) {
                    for (const teamMember of team.members) {
                        var employeeByUserId = userEmployeeData.find(ue => ue.employeeId == teamMember.employeeId);
                        
                        if (employeeByUserId) {
                            teamMember.userId = employeeByUserId.userId
                        }
                    }
                    teamList = teamList.concat({ value: team.id, label: team.name, members: team.members });
                }

                setTeamList(teamList);
            });
        });

        const reqDemands = fetchDemands();
        reqDemands.then(response => {
            const demandList = [{ value: "00000000-0000-0000-0000-000000000000", label: "Selecione" }].concat(response.data.map(demand => { return { value: demand.id, label: demand.serviceOrder } }));
            setDemandList(demandList);
        });

        const reqTypeTerritorializations = getAllTypeTerritorializations();
        reqTypeTerritorializations.then(async typeResponse => {
            const listTerritorialization = new Array();
            for (const type of typeResponse.data) {
                try {
                    if (type.typeGeometry === "Polygon") {
                        const territorializationRequest = await fetchTerritorializations(type.id);
                        if(!type.name.includes("Grid")){
                            listTerritorialization.push({
                                name: type.name,
                                label: type.name,
                                options: territorializationRequest.data.map(territorialization => { return { label: territorialization.name, value: territorialization.id } }),
                                territorializationList: territorializationRequest.data
                            })
                        }
                    }
                } catch (error) {
                }
            }
            setTerritorializationList(listTerritorialization)
        });

    }, []);

    return (
        <Tabs >
            <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8">
                <TabList className="tab-container">
                    <Tab className='btn btn-primary tab-item'>
                        Armadilhas
                    </Tab>

                    <Tab className='btn btn-primary tab-item'>
                        Depósitos
                    </Tab>

                    {/* 
                    <Tab className='btn btn-primary' style={{'width' : '200px'}}>
                        Operacional  
                    </Tab> */}

                </TabList>
            </Container>
            <div className="mt--7">
                <TabPanel >
                    <DashboardEntomologico
                        demandList={demandList}
                        teamList={teamList}
                        employeeList={employeeList}
                        territorializationList={territorializationList}
                    />
                </TabPanel>

                <TabPanel>
                    <DashboardDepositos
                        demandList={demandList}
                        teamList={teamList}
                        employeeList={employeeList}
                        territorializationList={territorializationList}
                    />
                </TabPanel>

            </div>
        </Tabs>
    );
}

export default MainDashboard;
